import { BusinessNumberResource } from '../../../api/types';
import { NumberListItem } from './number-list-item/number-list-item';
import styles from './number-list.module.scss';

export const NumberList = ({ numbers }: NumberListProps) => {
  return (
    <ul className={styles.list}>
      {
        numbers ? numbers.map(number => <NumberListItem key={number.number} businessNumber={number} />) : <></>
      }
    </ul>
  );
}

export interface NumberListProps {
  numbers: BusinessNumberResource[]
}