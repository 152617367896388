import { useState, useContext } from "react";
import * as Yup from "yup";
import { isValidNumber } from "libphonenumber-js";
import { Dialog } from "../../design/dialog/dialog";
import { Input } from "../../design/form/input/input";
import { Warning } from "../../design/warning/warning";
import { activeGroupContext } from "../active-group-context";
import { useGroups } from "../../api/use-groups";
import { useMe } from "../../api/use-me";
import { API } from "aws-amplify";
import mixpanel from "mixpanel-browser";
import { Field } from "../../design/form/field";
import { useRedirectCalls } from "../../api/useRedirectCalls";
import { isRedirectableNumber } from "../../utils/groups/isRedirectableNumber";

export const GroupForwardDialog = (props: any) => {
  const [activeGroup, setActiveGroup] = useContext(activeGroupContext);
  const [loading, setLoading] = useState(false);
  const user = useMe();
  const { mutate } = useGroups(user.data?.domain.id);
  const { data: redirectableCalls } = useRedirectCalls(user.data?.domain.id);

  const handleSubmit = async (values: { number: string }) => {
    try {
      setLoading(true);
      const body = {
        number: activeGroup?.number,
        forwardDestination: values.number,
        forwardEnabled: true,
      };
      const isGroupEdited = activeGroup?.forwardEnabled;
      await API.patch(
        process.env.REACT_APP_PHP_SERVICES_NAME!,
        `/crud/dom/${user?.data?.domain.id}/RingGroup/${activeGroup?.number}`,
        { body }
      );

      const trackType = isGroupEdited ? "Edit" : "Create";
      mixpanel.track(`${trackType} Forwarding Group`, {
        "Destination Type": activeGroup?.name,
      });

      await mutate();
      props.dialog.hide();
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleNumberValidation = (value: string | undefined) => {
    const enteredValue = value ? value.trim() : "";
    const validNumber = isValidNumber(enteredValue, "IL");
    const redirectableNumber = isRedirectableNumber(
      enteredValue,
      redirectableCalls,
      activeGroup?.number
    );
    return validNumber || redirectableNumber;
  };

  const forwardCallsFormSchema = Yup.object().shape({
    number: Yup.string()
      .test(
        "valid-il-number",
        "המספר שהכנסת לא תקין. אפשר להעביר שיחות למספר חוץ או למספר פנימי חוקי",
        handleNumberValidation
      )
      .test(
        "circular-forwarding",
        `שיחות לקבוצה ${activeGroup?.forwardDestination} כבר מופנות למספר זה`,
        (value) => {
          return !(
            activeGroup?.forwardEnabled &&
            value?.replaceAll("-", "") === activeGroup?.forwardDestination
          );
        }
      ),
  });

  const handleClose = () => {
    setActiveGroup(undefined);
  };

  return (
    <Dialog
      {...props.dialog}
      variant='form'
      header={`(קבוצה ${activeGroup?.number} ${activeGroup?.name}) העברת שיחות `}
      initialValues={{
        number: !activeGroup?.forwardEnabled ? "" : activeGroup?.forwardDestination || "",
      }}
      onSubmit={handleSubmit}
      submitLabel='להעביר שיחות'
      onClose={handleClose}
      validationSchema={forwardCallsFormSchema}
      loading={loading}
      aria-label='הפנית שיחה'
    >
      <Field name='number' label='מספר טלפון אליו תרצה/י להעביר את השיחות'>
        <Input type='tel' placeholder='מספר קווי או נייד' />
      </Field>
      <Warning
        message={"שים/י לב, העברת שיחות למספר חיצוני תגרום לתוספת עלויות דקות שיחה"}
        iconSize={20}
      />
    </Dialog>
  );
};
