import { getDigitTimeDiff } from '../../../utils/time/time';
import { DialerAttempt } from '../../types';
import { FormattedDate } from '../../../utils/time/formatted-date';
import styles from './attempt-info.module.scss';
import { mobileQuery } from '../../../theme/media';
import { useMediaQuery } from '../../../layouts/app-layout/app-layout';
import { getCallbackAttemptStatusString } from '../../../utils/strings/string-translator';

const AttemptInfo = (props: AttemptInfoProps) => {
  const { attempt, requestWaitTime, attempts, attemptNum } = props;
  const attemptAnswered = (attempt.attempt_status === "answered" && attempt.answer_date);
  const attemptStatus = getCallbackAttemptStatusString(attempt);
  const attemptDirection = getAttemptDirection(attempt);

  // If attempt was answered, dial time will be the time between dial and answer
  // If attempt failed, dial time will be the time between dial and fail
  // Otherwise, attempt is dialing and  dial time will be the time between dial and now
  const dialTime = getDigitTimeDiff(attempt.start_date, attempt.answer_date ?? attempt.end_date ?? new Date());

  // If attempt was answered, call duration will be the time between answer and call end, if indeed ended, or now, if call is still ongoing
  // Otherwise, call duration is undefined
  // using the ! operator is ok. Refer to the definition of attemptAnswered
  const callDuration = attemptAnswered ? getDigitTimeDiff(attempt.answer_date!, attempt.end_date ?? new Date()) : undefined;
  const attemptTime = attempt.start_date.toISOString().split('T')[1].substring(0, 5);
  const isMobile = useMediaQuery(mobileQuery);
  return (

    <div className={attemptNum !== 1 ? styles.progress_step : styles.progress_step__no_step}>
      <div className={styles.progress_step_marker} />
      <div className={styles.date}>
        <span className={styles.simple_label}>
          <FormattedDate dateStr={attempt.start_date.toDateString()} />
        </span>
        {!isMobile && <span className={styles.values}>{requestWaitTime} מהזמנת שיחה חוזרת</span>}
      </div>
      < div className={attemptNum !== 1 ? styles.attempt_details : styles.first_attempt} >
        {isMobile && <div className={styles.waitTime}>{requestWaitTime} מהזמנת שיחה חוזרת</div>}
        {
          attemptDirection === 'customer'
            ? <div className={styles.dial_attempt}>נסיון חיוג ללקוח {attemptNum} מתוך {attempts}</div>
            : <div className={styles.dial_attempt}>נסיון חיוג לנציג {attemptNum} מתוך {attempts}</div>
        }
        <span className={attemptAnswered ? styles.answer_label : getAttemptCssClass(attempt)}>
          {attemptStatus}
        </span>
        <span className={styles.agent_details}>{attempt.agent_name} {attempt.agent_number} </span>

        <span className={styles.simple_label}>שעת שיחה</span>
        <span className={styles.values}>{attemptTime}</span>
        <span className={styles.simple_label}>זמן המתנה</span>
        <span className={styles.values}>{dialTime}</span>
        {attempt.sms_text &&
          <>
            <span className={styles.simple_label}>הודעת sms</span>
            <span className={styles.values}>{attempt.sms_text}</span>
          </>
        }
        {callDuration &&
          <>
            <span className={styles.simple_label}>משך שיחה</span>
            <span className={styles.values}>{callDuration}</span>
          </>}
      </div >
    </div>
  )
}

const getAttemptCssClass = (attempt: DialerAttempt) => {
  if (attempt.completion_code?.startsWith('agent')) return styles.agent_fail_label;
  return styles.no_answer_label;
}

const getAttemptDirection = (attempt: DialerAttempt) => {
  if (attempt.completion_code?.startsWith('agent')) return 'agent';
  return 'customer';
}

interface AttemptInfoProps {
  attempt: DialerAttempt,
  requestWaitTime: string,
  attempts: number
  attemptNum: number
}

export default AttemptInfo;