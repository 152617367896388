import { Icon, IconProps } from "./icon";

export const PlayButton = (props: IconProps) => (
  <Icon
    viewBox="0 0 13 14"
    fill="currentColor"
    {...props}
  >
    <path d="M11.5593 5.12293C12.8927 5.89273 12.8927 7.81723 11.5593 8.58703L3.35986 13.321C2.02653 14.0908 0.359863 13.1285 0.359863 11.5889L0.359863 2.12102C0.359863 0.581419 2.02653 -0.380833 3.35986 0.388967L11.5593 5.12293Z" />
  </Icon>
);