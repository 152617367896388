import { API } from 'aws-amplify';

import { FetcherArgs, FetcherFileArgs, FileField, RequestMethod } from '../globalTypes/types';

export async function fetcher({ apiName, endpointURL, method, data }: FetcherArgs) {
  const options = data ? { body: data } : {};
  let result;
  switch (method) {
    case RequestMethod.GET:
      result = await API.get(apiName, endpointURL, options);
      break;
    case RequestMethod.POST:
      result = await API.post(apiName, endpointURL, options);
      break;
    case RequestMethod.PATCH:
      result = await API.patch(apiName, endpointURL, options);
      break;
    case RequestMethod.PUT:
      result = await API.put(apiName, endpointURL, options);
      break;
    case RequestMethod.DELETE:
      result = await API.del(apiName, endpointURL, options);
      break;
    default:
      throw new Error('Invalid method');
  }
  return result.data;
}

export async function fileFetcher({ apiName, endpointURL, method, options }: FetcherFileArgs) {
  let result;
  try {
    switch (method) {
      case RequestMethod.POST:
        result = await API.post(apiName, endpointURL, options);
        break;
      default:
        throw new Error('Invalid method');
    }
  } catch (error: any) {
    result = error.response;
    throw error;
  } finally {
    return result;
  }
}

export const fetchAttachmentURL = async (fileField: FileField, botId: string) => {
  const { file } = fileField;
  const formData = new FormData();
  file && formData.append('file', file);
  file && formData.append('bot_uuid', botId);
  const fetcherArgs: FetcherFileArgs = {
    apiName: process.env.REACT_APP_WHATSAPP_BOT_API_NAME!,
    endpointURL: '/upload',
    method: RequestMethod.POST,
    options: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      body: formData,
    },
  };
  const response = await fileFetcher({ ...fetcherArgs });
  if (response?.data?.success === false) {
    return null;
  }
  return response;
};
