import { Formik, Form } from "formik";
import { Auth } from "aws-amplify";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";
import { Button } from "../../design/button";
import { GeneralError } from "../../design/general-error/general-error";
import { Field } from "../../design/form/field";
import { Input } from "../../design/form/input/input";
import { Page } from "../../page";
import { AuthLayout } from "../../layouts/auth-layout/auth-layout";
import errorDictionary from "../error-dictionary";
import styles from "../auth.module.scss";

export function ResetPassword() {
  const [loadingResend, setLoadingResend] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const username = location.state?.username?.toLowerCase() || "";

  useEffect(() => {
    if (completed) {
      setTimeout(() => {
        navigate("/auth/login", { replace: true });
      }, 5000);
    }
  }, [completed, navigate]);

  async function handlePasswordReset(values: {
    username: string;
    code: string;
    new_password: string;
  }) {
    try {
      setLoading(true);
      mixpanel.track("Password Reset");
      await Auth.forgotPasswordSubmit(values.username, values.code, values.new_password);
      setCompleted(true);
      setError(undefined);
    } catch (e: any) {
      setLoading(false);
      setError(e.message);
    }
  }
  async function handleResendCode() {
    try {
      setLoadingResend(true);
      await Auth.forgotPassword(username);
      setError("Resent verification code");
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoadingResend(false);
    }
  }

  if (!username) {
    return <Navigate to='/auth/login' />;
  }

  if (completed) {
    return (
      <Page name='Reset Success Message'>
        <AuthLayout>
          <header className={styles.header}>
            <h3 className={styles.title}>יש לך סיסמה חדשה!</h3>
            <p className={styles.description}>אנחנו מעבירים אותך בחזרה למסך ההתחברות</p>
          </header>
          <footer className={styles.footer}>
            <Link className={styles.link} to='/'>
              למסך ההתחברות
            </Link>
          </footer>
        </AuthLayout>
      </Page>
    );
  }

  return (
    <Page name='Reset Password'>
      <AuthLayout>
        <Formik
          initialValues={{
            code: "",
            new_password: "",
            username,
          }}
          onSubmit={handlePasswordReset}
        >
          <Form>
            <header className={styles.header}>
              <h3 className={styles.title}>איפוס סיסמה</h3>
              <p className={styles.description}>
                שלחנו קוד אימות באורך 6 ספרות לאימייל שציינת. לא קיבלת? {` `}
                <Button
                  type='button'
                  variant='link'
                  loading={loadingResend}
                  onClick={() => handleResendCode()}
                >
                  לשלוח קוד חדש
                </Button>
              </p>
              <p className={styles.description}></p>
            </header>
            <Field name='code' label='קוד אימות'>
              <Input autoComplete='off' type='text' />
            </Field>
            <Field name='new_password' label='סיסמה חדשה'>
              <Input type='password' />
            </Field>
            {error && <GeneralError error={errorDictionary[error] || error} />}
            <div className={styles.actions}>
              <Button type='submit' variant='primary' loading={loading}>
                סיימתי
              </Button>
            </div>
            <footer className={styles.footer}>
              <Link to='/auth/login' className={styles.link}>
                חזרה להתחברות
              </Link>
            </footer>
          </Form>
        </Formik>
      </AuthLayout>
    </Page>
  );
}
