import { useNavigate } from "react-router-dom";
import { DialGroup, DialerEvent } from "../../api/dialers";
import {
  AppLayout,
  AppLayoutContainer,
  AppLayoutHeader,
  AppLayoutTitle,
} from "../../layouts/app-layout/app-layout";
import { Page } from "../../page";
import styles from "./dial-groups-page.module.scss";
import ListItem from "./list-item/list-item";
import { useDialGroups } from "../use-dial-groups";
import { useDialerEvents } from "../use-dialer-event";
import { Button } from "../../design/button";
import { useDialogState } from "reakit";
import CreateDialGroupDialog from "./create-dial-group-dialog";
import { Spinner } from "../../design/spinner/spinner";

export default function DialGroupsPage() {
  const { data: dialGroups, loading: loadingDialGroups, mutate: mutateGroups } = useDialGroups();
  const { data: allEvents, loading: loadingEvents, mutate: mutateEvents } = useDialerEvents();

  const createDialGroupDialog = useDialogState();

  if (loadingDialGroups || loadingEvents) {
    return (
      <Page name='Dial Groups Page'>
        <AppLayout>
          <AppLayoutContainer>
            <AppLayoutHeader className={styles.layoutHeader}>
              <AppLayoutTitle>ניהול קבוצות קריאה</AppLayoutTitle>
            </AppLayoutHeader>
            <Spinner />
          </AppLayoutContainer>
        </AppLayout>
      </Page>
    );
  }

  return (
    <Page name='Dial Groups Page'>
      <AppLayout>
        <AppLayoutContainer>
          <AppLayoutHeader className={styles.layoutHeader}>
            <AppLayoutTitle>ניהול קבוצות קריאה</AppLayoutTitle>
            <Button
              variant='primary'
              className={styles.createDialGroupBtn}
              onClick={() => createDialGroupDialog.show()}
            >
              יצירת קבוצת קריאה
            </Button>
          </AppLayoutHeader>
          <DialGroupsList
            dialGroups={dialGroups?.sort((a: DialGroup, b: DialGroup) =>
              sortByActiveDialGroups(a, b, allEvents)
            )}
            events={allEvents}
            mutateEvents={mutateEvents}
            mutateGroups={mutateGroups}
          />
          <CreateDialGroupDialog dialog={createDialGroupDialog} />
        </AppLayoutContainer>
      </AppLayout>
    </Page>
  );
}

const DialGroupsList = ({
  dialGroups,
  events,
  mutateEvents,
  mutateGroups,
}: DialGroupsListProps) => {
  const navigate = useNavigate();
  return (
    <ul className={styles.dialGroupsList}>
      {dialGroups ? (
        dialGroups.map((dialGroup) => {
          const activeEvent = events?.find(
            (event) => dialGroup.dialer_id === event.dialer_id && event.event_status === "active"
          );
          return (
            <ListItem
              key={dialGroup.dialer_id}
              onListItemClick={() => navigate(`/emergency-dialer/dialers/${dialGroup.dialer_id}`)}
              data={{
                dialer_id: dialGroup.dialer_id,
                name: dialGroup.name,
                activeEvent,
              }}
              mutateEvents={mutateEvents}
              mutateGroups={mutateGroups}
            />
          );
        })
      ) : (
        <Spinner />
      )}
    </ul>
  );
};

interface DialGroupsListProps {
  dialGroups: DialGroup[] | undefined;
  events: DialerEvent[] | undefined;
  mutateEvents: () => void;
  mutateGroups: () => void;
}

const sortByActiveDialGroups = (
  a: DialGroup,
  b: DialGroup,
  events: DialerEvent[] | undefined
): number => {
  const isActiveA = events?.some(
    (event) => event.dialer_id === a.dialer_id && event.event_status === "active"
  );
  const isActiveB = events?.some(
    (event) => event.dialer_id === b.dialer_id && event.event_status === "active"
  );
  return isActiveA && isActiveB ? 0 : isActiveA ? -1 : isActiveB ? 1 : 0;
};
