import { useContext } from "react";
import { Dialog } from '../../design/dialog/dialog';
import { Warning } from "../../design/warning/warning";
import { activeExtensionContext } from '../active-extensions-context';

export function DisabledForwardDialog(props: any) {
  const [activeExtension, setActiveExtension] = useContext(activeExtensionContext);

  const handleClose = () => {
    setActiveExtension(undefined);
  };

  return (
    <Dialog {...props.dialog}
      variant="message"
      header={`(שלוחה ${activeExtension?.destinationNumber} ${activeExtension?.description || ""}) העברת שיחות`}
      onClose={handleClose}
      aria-label="הפנית שיחה">
      <Warning message={"שלוחה זו מוצמדת למספר נייד ולכן לא ניתן להפנות אותה ליעד חיצוני"} iconSize={20} />
    </Dialog >
  );
};
