import { API } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDialogState } from 'reakit';
import { Dialog } from '../../design/dialog/dialog';
import { useBusinessNumberEventCollection, useBusinessNumberResource } from '../../api/use-business-number';
import { EventForm, EventFormValues } from './event-form/event-form';
import moment from 'moment';
import { useMe } from '../../api/use-me';


export function CreateEvent() {

  const user = useMe();
  const { number } = useParams<{ number: string }>();
  const businessNumber = useBusinessNumberResource(number!);
  const eventCollection = useBusinessNumberEventCollection(number!);
  const navigate = useNavigate();
  const dialog = useDialogState({
    visible: true,
  })
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  
  const handleSubmit = async (values: EventFormValues) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('date', `${values.startDate}~${values.endDate}`);
      formData.append('status', values.closed ? 'closed' : 'open');
      formData.append('openAt', values.openAt);
      formData.append('closeAt', values.closeAt);
      if (values.file) {
        formData.append('file', values.file);
      }
      if (values.recording.length > 0) {
        formData.append('recording', values.recording)
      }
      await API.post(process.env.REACT_APP_WEB_API_NAME!, `/domains/${user?.data?.domain.id}/business-numbers/${number}/events`, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        body: formData
      });
      mixpanel.track('Create Scheduled Event', {
        'Has Custom Recording': values.file !== null,
        'Closed All Day': values.openAt.length === 0 && values.closeAt.length === 0,
        'Event Start Date': moment(values.startDate).utc().toString(),
        'Event End Date': moment(values.endDate).utc().toString(),
      });
      mixpanel.people.increment('Events Created');
      await eventCollection.mutate();
      await businessNumber.mutate();
      navigate(`/my-numbers/${number}`);
    } catch (error: any) {
      if (typeof error.response.data === "string") {
        setError(error.response.data);
      } else {
        const errorMessage = error.response.data[0].message ? error.response.data[0].message : JSON.stringify(error.response.data[0]);
        setError(errorMessage);
      }
      setLoading(false);
    }
  }

  const handleCancel = () => {
    navigate(`/my-numbers/${number}`);
  }

  return (
    <Dialog {...dialog}
      variant="form"
      header="יצירת אירוע חדש"
      onSubmit={(values: EventFormValues) => handleSubmit(values)}
      onClose={handleCancel}
      initialValues={{
        name: '',
        startDate: '',
        endDate: '',
        closed: false,
        openAt: '',
        closeAt: '',
        file: null,
        recording: '',
      }}
      loading={loading}
      hideOnClickOutside={false}
    >
      <EventForm
        error={error}
      />
    </Dialog>
  )
}
