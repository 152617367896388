import { useState } from "react";
import * as Yup from "yup";
import mixpanel from "mixpanel-browser";
import { API } from "aws-amplify";
import { isValidNumber } from "libphonenumber-js";
import { Dialog } from "../../design/dialog/dialog";
import { Input } from "../../design/form/input/input";
import { Field } from "../../design/form/field";
import { Warning } from "../../design/warning/warning";
import { useBusinessNumberResource } from "../../api/use-business-number";
import { useMe } from "../../api/use-me";
import { safeParsePhoneNumber } from "../../utils/strings/parse-phone";

export function CallForwardDialog(props: any) {
  const user = useMe();
  const number = useBusinessNumberResource(props.number);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      const body = {
        toNumber: values.number.replaceAll("-", ""),
      };
      const trackEventName = number.data?.forwardIncomingCallsTo
        ? "Update Call Forwarding"
        : "Set Call Forwarding";

      await API.put(
        process.env.REACT_APP_WEB_API_NAME!,
        `/domains/${user?.data?.domain.id}/business-numbers/${number.data?.number}/call-forwarding`,
        { body }
      );
      mixpanel.track(trackEventName, {
        "Destination Type": "Business Phone",
      });
      await number.mutate();
      props.dialog.hide();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const forwardCallsFormSchema = Yup.object().shape({
    number: Yup.string()
      .test(
        "valid-il-number",
        "המספר שהכנסת לא תקין. אפשר להעביר שיחות למספר חוץ או למספר פנימי חוקי",
        (value) => isValidNumber(value || "", "IL")
      )
      .test("circular-forwarding", "אי אפשר להעביר שיחות למספר ממנו את/ה מפנה", (value) =>
        value && number.data ? value.replaceAll("-", "") !== number.data.number : false
      ),
  });

  const forwardNumber = number.data?.forwardIncomingCallsTo;
  const initialValues = {
    number:
      forwardNumber && forwardNumber !== null ? safeParsePhoneNumber(forwardNumber, "IL") : "",
  };
  return (
    <Dialog
      {...props.dialog}
      variant='form'
      header={number.data && `העברת שיחות (${safeParsePhoneNumber(number.data.number, "IL")})`}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      submitLabel='ביצוע הפניה'
      validationSchema={forwardCallsFormSchema}
      loading={loading}
      aria-label='הפנית שיחה'
    >
      <Field name='number' label='מספר טלפון אליו תרצה/י להעביר שיחות'>
        <Input type='tel' placeholder='מספר קווי או נייד' />
      </Field>
      <Warning message={"שים/י לב, העברת שיחות למספר חיצוני תגרום לתוספת עלויות דקות שיחה"} />
    </Dialog>
  );
}
