import { GroupResource } from '../../api/types';
import { SelectOption } from '../../formConfig/types';
import { getSelectOptions } from './createGroupOptions';

export const initSelectedGroups = (queueUuids: string[], groups: GroupResource[]): SelectOption[] => {
  const allGroupOptions: SelectOption[] = getSelectOptions(groups);
  const selectedGroups: SelectOption[] = [];
  queueUuids.forEach((queueId) => {
    const selectedOption = allGroupOptions.find((groupOption) => groupOption.value === queueId);
    if (selectedOption) {
      selectedGroups.push(selectedOption);
    }
  });
  return selectedGroups;
};
