import { useCallback } from 'react';
import useSWR, { SWRConfiguration } from 'swr';

import { useMe } from '../api/use-me';
import { fetcher } from '../utils/apiService';
import { getDataType } from '../callbacks/utils/getDataType';
import { getOptimisticData } from '../callbacks/utils/getOptimisticData';
import { DataType, RequestData, RequestMetaData, RequestMethod } from '../globalTypes/types';

const DEFAULT_SWR_CONFIG: SWRConfiguration = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
  dedupingInterval: 5 * 60 * 1000, // 5 minutes
  refreshInterval: 5 * 60 * 1000, // 5 minutes
  shouldRetryOnError: true,
  errorRetryCount: 3,
  errorRetryInterval: 2 * 1000, // 2 seconds
};

export const useData = ({
  apiName,
  endpointURL,
  shouldFetch = true,
  swrConfig = DEFAULT_SWR_CONFIG,
}: RequestMetaData) => {
  const user = useMe();
  const shouldFetchData = user?.data && shouldFetch;
  const swrKey = shouldFetchData ? endpointURL : null;
  const handleFetch = (url: string) => fetcher({ apiName, endpointURL: url, method: RequestMethod.GET });
  const { data, error, isLoading, mutate, isValidating } = useSWR(swrKey, handleFetch, swrConfig);

  const apiDataHandler = useCallback(
    async ({ method, newData, shouldUseOptimistic }: RequestData) => {
      const dataType: DataType = getDataType(data);
      const optimisticData = getOptimisticData(data, newData, dataType);

      const mutateOptions = {
        optimisticData: shouldUseOptimistic ? optimisticData : undefined,
        populateCache: shouldUseOptimistic,
        revalidate: !shouldUseOptimistic,
        rollbackOnError: shouldUseOptimistic,
      };

      await mutate(optimisticData, mutateOptions);
      try {
        // Returning the response for displaying the snackbar (toast) based on response
        return await fetcher({ apiName, endpointURL, method, data: newData });
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error(error.message);
          return error.message;
        } else {
          console.error('An unknown error occurred', error);
        }
      }
    },
    [apiName, data, endpointURL, mutate]
  );

  return {
    data,
    error,
    mutate,
    isLoading,
    isValidating,
    apiDataHandler,
  };
};
