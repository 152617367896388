import { memo } from 'react';
import { Typography } from '@mui/material';

import { FormSection } from '../types';
import { TypographyVariant } from '../../theme/muiTypes';
import { NO_REPLAY_SETTINGS_TEXTS } from '../utils/texts';
import { useFormFieldRenderer } from '../../hooks/useFormFieldRenderer';
import { CustomCard } from '../../components/common/customCard/CustomCard';
import { useCallbackFieldsConfig } from '../hooks/useCallbackFieldsConfig';
import { CustomDivider } from '../../components/common/customDivider/CustomDivider';
import { StyledCardsContainer, StyledFormContainer } from './StyledCallbackDialerSettings';

const { NO_REPLAY_TITLE, NO_REPLAY_SUB_TITLE } = NO_REPLAY_SETTINGS_TEXTS;

export const NoReplaySettings = memo(({ section, apiConfig }: FormSection) => {
  const {
    min_time_for_answer,
    cust_request_created,
    min_time_for_abandon,
    approve_request_created,
    delete_request_on_answer,
    create_request_on_abandon,
    allow_change_mobile_number,
    allow_change_landline_number,
  } = useCallbackFieldsConfig();
  const renderFormField = useFormFieldRenderer(apiConfig);

  return (
    <StyledFormContainer id={section.id}>
      <Typography variant={TypographyVariant.h1}>{NO_REPLAY_TITLE}</Typography>
      <Typography variant={TypographyVariant.regularText2}>{NO_REPLAY_SUB_TITLE}</Typography>
      <StyledCardsContainer className='general-settings'>
        <CustomCard className='column'>
          {renderFormField(create_request_on_abandon)}
          {renderFormField(min_time_for_abandon)}
          {renderFormField(delete_request_on_answer)}
          {renderFormField(min_time_for_answer)}
          <CustomDivider hidden={!min_time_for_answer.uiConfig.hasPermission} />
          {renderFormField(allow_change_mobile_number)}
          <CustomDivider hidden={!allow_change_mobile_number.uiConfig.hasPermission} />
          {renderFormField(allow_change_landline_number)}
          <CustomDivider hidden={!allow_change_landline_number.uiConfig.hasPermission} />
          {renderFormField(approve_request_created)}
          {renderFormField(cust_request_created)}
        </CustomCard>
      </StyledCardsContainer>
    </StyledFormContainer>
  );
});

NoReplaySettings.displayName = 'NoReplaySettings';
