import { Form, Formik } from 'formik';
import { Button } from '../../design/button/button';
import { Field } from '../../design/form/field/field';
import { Input } from '../../design/form/input/input';
import { Select } from '../../design/form/select/select';
import { GeneralError } from '../../design/general-error/general-error';
import styles from './customer-registration-form.module.scss';

export function CustomerRegistrationForm({ error, loading, ...props }: CustomerRegistrationFormProps) {

  return (
    <Formik
      {...props}
    >
      <Form>
        <Field name="gender" label="איך לפנות אליך?">
          <Select>
            <option value="male">לשון זכר</option>
            <option value="female">לשון נקבה</option>
          </Select>
        </Field>
        <Field
          name="username"
          label="אימייל"
          description="ישמש אותך להתחברות למערכת והודעות חשובות (בלי ספאם)"
        >
          <Input
            autoComplete="off"
            type="email"
            className={styles.emailInput}
            required
          />
        </Field>
        <Field name="password" label="סיסמה חדשה">
          <Input
            type="password"
            autoComplete="new-password"
            required
          />
        </Field>
        {error && <GeneralError error={error} />}
        <div className={styles.actions}>
          <Button
            type="submit"
            variant="primary"
            loading={loading}
          >
            סיימתי
          </Button>
        </div>
      </Form>
    </Formik>
  )
}

export interface CustomerRegistrationFormProps {
  initialValues: CustomerRegistrationFormValues
  onSubmit: (values: CustomerRegistrationFormValues) => void;
  error?: string;
  loading?: boolean;
}

export interface CustomerRegistrationFormValues {
  gender: 'male' | 'female' | '',
  username: string,
  password: string,
}