import { parsePhoneNumber } from "libphonenumber-js";
import { useContext } from "react";
import { useDialogState } from "reakit";
import { GroupResource } from "../../api/types";
import { ButtonWithDropdown } from "../../design/button-with-dropdown/button-with-dropdown";
import { ChevronDown } from "../../design/icons/chevron-down";
import { ChevronUp } from "../../design/icons/chevron-up";
import { activeGroupContext } from "../active-group-context";
import { GroupCloseDialog } from "../groups-dialog/group-close-dialog";
import { GroupForwardDialog } from "../groups-dialog/group-forward-dialog";
import { GroupNameDialog } from "../groups-dialog/group-name-dialog";
import { GroupMenu } from "./group-menu/group-menu";
import styles from "./group.module.scss"
import { safeParsePhoneNumber } from "../../utils/strings/parse-phone";

export const Group = ({ group }: { group: GroupResource }) => {
  const callForwardDialog = useDialogState();
  const closedModeDialog = useDialogState();
  const nameEditDialog = useDialogState();

  const dialogs = [
    <GroupForwardDialog dialog={callForwardDialog} />,
    <GroupCloseDialog dialog={closedModeDialog} />,
    <GroupNameDialog dialog={nameEditDialog} />
  ];

  const [, setActiveGroup] = useContext(activeGroupContext)

  const handleCallForwardClick = (group: GroupResource) => {
    setActiveGroup(group);
    callForwardDialog.show();
  }

  const handleCancelForward = (group: GroupResource) => {
    setActiveGroup(group);
    closedModeDialog.show()
  }

  const handleNameEditClick = (group: GroupResource) => {
    setActiveGroup(group);
    nameEditDialog.show();
  }

  return (
    <>
      <div className={styles.root}>
        <span className={styles.party}>
          {group.number}
        </span>
        <span className={styles.description}>
          {group.name}
        </span>
        {
          group.forwardEnabled && group.forwardDestination &&
          <span className={styles.forwardNumber}>
            {
              (`שיחות מועברות ל-${safeParsePhoneNumber(group.forwardDestination, "IL")}`)
            }
          </span>
        }
        <span className={styles.actions}>
          <ButtonWithDropdown
            label="פעולות"
            collapsedBadge={<ChevronDown size={24} />}
            expandedBadge={<ChevronUp size={24} />}
            buttonClassName={styles.button}
          >
            <GroupMenu
              isForwarded={group.forwardEnabled}
              onForwardClick={() => handleCallForwardClick(group)}
              onCancelForwardClick={() => handleCancelForward(group)}
              onEditNameClick={() => handleNameEditClick(group)}
            />
          </ButtonWithDropdown>
        </span>
      </div>
      {dialogs.map((dialog, index) => <div key={index}>{dialog}</div>)}
    </>
  )
}