import { UserResource } from '../api/types';
import { useMediaQuery } from '../layouts/app-layout/app-layout';
import { mobileQuery } from '../theme/media';
import { DomainSelectionMobile } from './mobile/domain-selection-mobile';
import { DomainSelectionDesktop } from './desktop/domain-selection-desktop';

export const DomainSelectionRouter = ({ user }: DomainSelectionProps) => {
  const isMobileView = useMediaQuery(mobileQuery);
  if (isMobileView) {
    return <DomainSelectionMobile user={user} />;
  }

  return <DomainSelectionDesktop user={user} />;
};

interface DomainSelectionProps {
  user: UserResource | undefined;
}
