import { CallbackFieldName } from '../types';
import { PermissionLevel } from '../../api/types';
import { CallbackDialer } from '../../api/dialers';
import { getFileFieldValue } from './getFileFieldValue';

export const getUIFieldValue = (
  fieldName: CallbackFieldName,
  userPermissionLevel: PermissionLevel,
  data: CallbackDialer
) => {
  switch (fieldName) {
    case 'selectedPermission':
      return { selectedPermission: userPermissionLevel };
    case 'sms_content_type':
      if (data.sms_text_at_start) {
        return { sms_content_type: 'sms_text_at_start' };
      }
      if (data.sms_text_between_attempts) {
        return { sms_content_type: 'sms_text_between_attempts' };
      }
      if (data.sms_text_at_failure) {
        return { sms_content_type: 'sms_text_at_failure' };
      }
      return { sms_content_type: '' };
    case 'report_type':
      if (data.interact_by_sms && data.interact_by_mail) {
        return { report_type: 'sms_mail' };
      }
      if (data.interact_by_sms && !data.interact_by_mail) {
        return { report_type: 'sms' };
      }
      if (!data.interact_by_sms && data.interact_by_mail) {
        return { report_type: 'mail' };
      }
      return { report_type: undefined };
    case 'cust_prompt':
    case 'agent_greeting':
    case 'cust_request_created':
      return { [fieldName]: getFileFieldValue(fieldName, data) };
    default:
      return {};
  }
};
