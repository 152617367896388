import { memo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import { SmsSettings } from './SmsSettings';
import { ICallbackDialerForm } from '../types';
import { ReportSettings } from './ReportsSettings';
import { ApiConfig } from '../../globalTypes/types';
import { GeneralSettings } from './GeneralSettings';
import { NoReplaySettings } from './NoReplaySettings';
import { FormPermissions } from './PermissionSettings';
import { AgentDialingSettings } from './AgentDialingSettings';
import { updateFieldUIConfig } from '../utils/updateFieldUIConfig';
import { CustomerDialingSettings } from './CustomerDialingSettings';
import { CallBackDialerWrapper } from './StyledCallbackDialerSettings';
import { FORM_SECTIONS } from '../formConfig/callbackDialerFormConfig';
import { callbackValidationSchema } from '../../formConfig/formValidationSchemas/callbackDialerSchema';

const [general, agent, customer, sms, noReplay, report] = FORM_SECTIONS;

interface CallbackDialerFormProps {
  apiConfig: ApiConfig;
  dialerFormValues: ICallbackDialerForm;
  handleScroll: () => void;
}

export const CallbackDialerForm = memo(({ handleScroll, apiConfig, dialerFormValues }: CallbackDialerFormProps) => {
  const methods = useForm<ICallbackDialerForm>({
    defaultValues: dialerFormValues,
    values: dialerFormValues,
    mode: 'onBlur',
    resolver: yupResolver(callbackValidationSchema),
    resetOptions: {
      keepDirtyValues: true,
      keepValues: true,
      keepErrors: true,
    },
  });

  const formValues = methods.getValues();
  const selectedPermissionValue = methods.watch('selectedPermission');
  updateFieldUIConfig(formValues, selectedPermissionValue);

  return (
    <FormProvider {...methods}>
      <CallBackDialerWrapper onScroll={handleScroll}>
        <FormPermissions apiConfig={apiConfig} />
        <GeneralSettings section={general} apiConfig={apiConfig} />
        <AgentDialingSettings section={agent} apiConfig={apiConfig} />
        <CustomerDialingSettings section={customer} apiConfig={apiConfig} />
        <SmsSettings section={sms} apiConfig={apiConfig} />
        <NoReplaySettings section={noReplay} apiConfig={apiConfig} />
        <ReportSettings section={report} apiConfig={apiConfig} />
      </CallBackDialerWrapper>
    </FormProvider>
  );
});
