import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import styles from './invitation-list-item.module.scss';

export function InvitationListItem({
  className,
  ...props
}: InvitationListItemProps) {
  const finalClassName = classNames(styles.root, className);
  return <li className={finalClassName}>{props.children}</li>;
}

interface InvitationListItemProps extends HTMLAttributes<HTMLLIElement> {}
