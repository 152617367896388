import React, { ReactNode } from 'react';
import styles from './update.module.scss';

export function Update({ children, date, title, visual }: UpdateProps) {

  return (
    <article className={styles.update}>
      <div className={styles.date}>
        {date}
      </div>
      <h2 className={styles.title}>
        {title}
      </h2>
      <div className={styles.content}>
        {children}
      </div>
      <img
        src={visual}
        alt={title}
        className={styles.visual}
      />
    </article>
  );
}

export interface UpdateProps {
  id: string;
  date: string;
  title: string;
  visual: string;
  children: ReactNode;
}