import classNames from "classnames";
import { useMediaQuery } from "../../layouts/app-layout/app-layout";
import { mobileQuery } from "../../theme/media";
import { Input } from "../form/input/input";
import { Reset } from "../icons";
import { DateRange } from "./date-range";
import styles from './date-range.module.scss';
import { ChangeEvent, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useURLParams } from "../../app/use-url-params";
import mixpanel from 'mixpanel-browser';
import moment from "moment";

const DEFAULT_NUM_OF_MONTHS = 2;
export type Date = moment.Moment | null;

export type Dates = {
  startDate: Date;
  endDate: Date;
};

// This function allows us to support a new date format
// in url: yyyy-mm-dd => yyyy-mm-dd+hh:MM:ss
// As this DateRange component does not handle the hh:MM:ss format - we will just ignore it in this component
const cleanDate = (date?: string | undefined) => {
  if (!date) return '';
  return date.split('+')[0];
}

export default function NewDateRange(props: NewDateRangeProps) {
  const isMobile = useMediaQuery(mobileQuery);
  const params = useParams<any>()['*']?.replace(/^\/|\/$/g, '');
  const url = useURLParams({ path: props.path, sortList: props?.urlParamsSortList, params });
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null);
  const pathName = props.path.replaceAll('/', '');
  const [dateRange, setDateRange] = useState(url.urlParams.from && url.urlParams.to ?
    { from: url.urlParams.from, to: url.urlParams.to } :
    DEFAULT_DATE_RANGE
  );
  const [timeRange, setTimeRange] = useState({
    fromTime: url.urlParams.fromTime,
    toTime: url.urlParams.toTime
  });
  
  const handleTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setTimeRange((prevTimeRange) => ({ ...prevTimeRange, [name]: value }));
  };

  const handleDatesChange = ({ startDate, endDate }: Dates) => {
    const from = startDate?.format(DATE_FORMAT).toString() || '';
    const to = endDate?.format(DATE_FORMAT).toString() || '';

    setDateRange({
      from: from,
      to: to || from
    });
  }

  const handleClick = () => {
    setFocusedInput(null);
    mixpanel.track('Change Date Range', {
      Days: moment(cleanDate(dateRange.to)).diff(
        moment(cleanDate(dateRange.from)),
        'days'
      ),
    });
    props.onDateChange(dateRange, timeRange);
  }

  const renderCalendarInfo = () => (
    <div className={styles.dateRangeFooter}>
      <div className={styles.timeFilters}>
        <span className={styles.fromTimeSelector}>
          <Input type='time' name='fromTime' value={timeRange.fromTime} onChange={handleTimeChange} />
        </span>
        <span className={styles.toTimeSelector}>
          <Input type='time' name='toTime' value={timeRange.toTime} onChange={handleTimeChange} />
        </span>
      </div>
      <button
        type="button"
        disabled={!dateRange.from || !dateRange.to}
        className={classNames(styles.filterFormButton, styles.primary, {mobile: isMobile})}
        onClick={handleClick}
      >
        {TEXTS.UPDATE}
      </button>
    </div>
  );

  return (
    <DateRange
      keepFocusOnInput
      customArrowIcon={<Reset size={16} />}
      displayFormat="DD MMMM"
      numberOfMonths={isMobile ? 1 : DEFAULT_NUM_OF_MONTHS}
      keepOpenOnDateSelect
      noBorder
      renderCalendarInfo={renderCalendarInfo}
      showDefaultInputIcon
      minimumNights={0}
      startDate={moment(cleanDate(dateRange.from))}
      startDatePlaceholderText={TEXTS.START_DATE_PLACEHOLDER}
      startDateId={`${pathName}-StartDate`}
      endDate={moment(cleanDate(dateRange.to))}
      endDatePlaceholderText={TEXTS.END_DATE_PLACEHOLDER}
      endDateId={`${pathName}-EndDate`}
      focusedInput={focusedInput || null}
      onFocusChange={(input) => setFocusedInput(input)}
      isOutsideRange={(day) => day.startOf('day').isAfter(moment())}
      onDatesChange={handleDatesChange}
    />
  )
}

interface NewDateRangeProps {
  urlParamsSortList?: string[];
  path: string;
  onDateChange: (
    dates: DateRange,
    times: TimeRange
  ) => void;
}

export interface DateRange {
  from: string;
  to: string;
}
export interface TimeRange {
  fromTime: string;
  toTime: string;
}

export const DATE_FORMAT = 'YYYY-MM-DD';

export const TEXTS = {
	ERROR_TITLE: 'לא הצלחנו לטעון את העמוד מסיבה לא ידועה',
	ERROR_CONTENT_TEXT: 'נסה/י לטעון מחדש. אם הבעיה חוזרת, אנא פנה/י לתמיכה הטכנית',
	ERROR_CONTENT_SUPPORT_PHONE: '03-657-6576',
	ERROR_CONTENT_SUPPORT_EMAIL: 'support@ip-com.co.il',
	ERROR_CONTENT_SUPPORT_TEXT: ' או	בטלפון: ',
	ERROR_ACTION: 'טען/י מחדש',
	TITLE: 'היסטוריית שיחות',
	EXPORT: 'ייצוא לאקסל',
	SEARCH_PLACEHOLDER: 'חיפוש מספר טלפון...',
	UPDATE: 'עדכון',
	START_DATE_PLACEHOLDER: 'מתאריך',
	END_DATE_PLACEHOLDER: 'עד תאריך',
	STATUS: 'סטטוס',
	ANSWERED: 'נענתה',
	NOT_ANSWER: 'לא נענתה',
	VOICE_MAIL: 'תא קולי',
	WAITING_FOR_ANSWER: 'המתנה למענה',
	AT_LEAST: 'לפחות',
	UNTIL: 'עד',
	MILLISECONDS: 'שניות',
	MINUTES: 'דקות',
	CALL_DURATION: 'משך שיחה',
	BUSINESS_PHONES: 'קווי טלפון של העסק',
	EXTENSIONS: 'שלוחות',
	GROUPS: 'קבוצות',
	ORGANIZATIONS: 'ארגונים',
	RESET: 'איפוס',
}

export const DEFAULT_DATE_RANGE = {
  from: moment().subtract(1, 'week').format(DATE_FORMAT).toString(),
  to: moment().format(DATE_FORMAT).toString(),
};