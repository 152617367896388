import { Auth } from "aws-amplify";
import { Form, Formik } from "formik";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import { useReducer, useState } from "react";
import { Link } from "react-router-dom";
import { Dialog, DialogBackdrop, useDialogState } from "reakit";
import { Button } from "../../design/button";
import { Field } from "../../design/form/field";
import { Input } from "../../design/form/input/input";
import { Select } from "../../design/form/select";
import { GeneralError } from "../../design/general-error/general-error";
import { CloseIcon } from "../../design/icons";
import { AuthLayout } from "../../layouts/auth-layout/auth-layout";
import { Page } from "../../page";
import styles from "../auth.module.scss";

import * as Yup from "yup";
import errorDictionary from "../error-dictionary";

const initialState = {
  type: "login",
  payload: null,
};

export function Login() {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const confirmDialog = useDialogState();
  const [loadingResend, setLoadingResend] = useState(false);
  const [authState, dispatch] = useReducer((state: any, action: any) => {
    return {
      type: action.type,
      ...action.payload,
    };
  }, initialState);
  const signIn = async (values: any) => {
    try {
      dispatch({
        type: "login",
        payload: {
          loading: true,
          error: null,
        },
      });
      const user = await Auth.signIn(values.username.toLowerCase(), values.password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        dispatch({
          type: "new_password_required",
          payload: {
            user: user,
            loading: false,
            error: null,
          },
        });
      }
    } catch (e: any) {
      if (e.code === "UserNotConfirmedException") {
        try {
          dispatch({
            type: "login",
            payload: {
              loading: false,
              error: null,
              credentials: values,
              userNotConfirmed: true,
            },
          });
          await Auth.resendSignUp(values.username);
          confirmDialog.show();
        } catch (e: any) {
          dispatch({
            type: "login",
            payload: {
              loading: false,
              error: e.message,
            },
          });
        }
      } else {
        dispatch({
          type: "login",
          payload: {
            loading: false,
            error: e.message,
          },
        });
      }
    }
  };

  async function handleResendCode(username: string) {
    try {
      setLoadingResend(true);
      await Auth.resendSignUp(authState.credentials.username);
      alert("שלחנו לך את קוד האימות למייל");
    } catch (e) {
      alert("לא הצלחנו לשלוח לך את קוד האימות. אפשר ליצור קשר עם התמיכה");
    } finally {
      setTimeout(() => {
        setLoadingResend(false);
      }, 1000);
    }
  }

  async function handleConfirmUser({ code, username, password }: any) {
    try {
      setConfirmLoading(true);
      await Auth.confirmSignUp(username, code);
      await Auth.signIn(username, password);
    } catch (e: any) {
      switch (e.code) {
        case "CodeMismatchException":
          dispatch({
            type: "login",
            payload: {
              credentials: { username, password },
              error: e.code,
              loading: false,
              userNotConfirmed: true,
            },
          });
          break;
        default:
          dispatch({
            type: "login",
            payload: {
              error: e.code,
              loading: false,
            },
          });
      }
    } finally {
      setConfirmLoading(false);
    }
  }

  const completeAuthChallenge = async (user: any, values: any) => {
    try {
      mixpanel.people.set_once("Activated At", moment().toISOString());
      mixpanel.people.set("Preffered Gender", values.gender);

      const { password, ...attributes } = values;
      dispatch({
        type: "new_password_required",
        payload: {
          user: user,
          loading: true,
          error: null,
        },
      });
      await Auth.completeNewPassword(user, password, attributes);
    } catch (e: any) {
      confirmDialog.show();
      dispatch({
        type: "new_password_required",
        payload: {
          user: user,
          loading: false,
          error: e.message,
        },
      });
    }
  };

  if (authState.type === "new_password_required") {
    return (
      <Page name='Account Setup'>
        <AuthLayout>
          <Formik
            key='form_new_password_required'
            initialValues={{
              password: "",
              gender: "male",
            }}
            onSubmit={(values) => completeAuthChallenge(authState.user, values)}
          >
            <Form>
              <header className={styles.header}>
                <h3 className={styles.title}>שמחים לראות אותך איתנו!</h3>
              </header>
              <Field name='gender' label='איך לפנות אליך?'>
                <Select>
                  <option value='male'>לשון זכר</option>
                  <option value='female'>לשון נקבה</option>
                </Select>
              </Field>
              <Field name='password' label='סיסמה חדשה, במקום הסיסמה הזמנית'>
                <Input type='password' required />
              </Field>
              {!authState.loading && authState.error && (
                <GeneralError error={errorDictionary[authState.error] || authState.error} />
              )}
              <div className={styles.actions}>
                <Button variant='primary' type='submit' loading={authState.loading}>
                  סיימתי
                </Button>
              </div>
            </Form>
          </Formik>
        </AuthLayout>
      </Page>
    );
  }

  return (
    <Page name='Login'>
      <div className={styles.pageLayout}>
        <AuthLayout
          className={styles.loginSection}
          headerClassName={styles.loginHeader}
          mainClassName={styles.loginForm}
          contentClassName={styles.loginContent}
        >
          <Formik
            key='form_login'
            initialValues={{
              username: "",
              password: "",
            }}
            onSubmit={signIn}
          >
            <Form>
              <header className={styles.header}>
                <h3 className={styles.title}>כניסה למערכת</h3>
              </header>
              <Field name='username' label='כתובת מייל'>
                <Input type='email' className={styles.emailInput} />
              </Field>
              <Field name='password' label='סיסמה'>
                <Input type='password' />
              </Field>
              {!authState.loading && authState.error && (
                <GeneralError error={errorDictionary[authState.error] || authState.error} />
              )}
              <div className={styles.actions}>
                <Button variant='primary' type='submit' loading={authState.loading}>
                  התחברות
                </Button>
                <Link className={styles.link} to='/auth/forgot-password'>
                  שכחתי סיסמה
                </Link>
              </div>
            </Form>
          </Formik>
          {authState?.userNotConfirmed && (
            <Page name='Email Verification'>
              <DialogBackdrop {...confirmDialog} className={styles.backdrop}>
                <Dialog {...confirmDialog} className={styles.dialog} aria-label='פרטי שיחה'>
                  <CloseIcon
                    className={styles.dialogCloseButton}
                    onClick={confirmDialog.hide}
                    size={24}
                  />
                  <h2 className={styles.dialogTitle}>אימות כתובת המייל שלך</h2>
                  <p className={styles.dialogDescription}>
                    שלחנו לך קוד אימות באורך 6 ספרות למייל - יש להזין אותו בשדה למטה. לא קיבלת?{" "}
                    <Button
                      type='button'
                      variant='link'
                      loading={loadingResend}
                      onClick={() => handleResendCode(authState.credentials.username)}
                    >
                      שלחו שוב
                    </Button>
                  </p>
                  <Formik
                    initialValues={{
                      username: authState.credentials.username,
                      password: authState.credentials.password,
                      code: "",
                    }}
                    onSubmit={handleConfirmUser}
                    validationSchema={confirmationFormSchema}
                    validateOnBlur={false}
                    validateOnChange={false}
                  >
                    <Form>
                      <Field name='code' label='קוד אימות (6 ספרות)'>
                        <Input type='text' />
                      </Field>
                      {authState.error && <GeneralError error={errorDictionary[authState.error]} />}
                      <div className={styles.actions}>
                        <Button variant='primary' type='submit' loading={confirmLoading}>
                          אימות
                        </Button>
                      </div>
                    </Form>
                  </Formik>
                </Dialog>
              </DialogBackdrop>
            </Page>
          )}
        </AuthLayout>
        <img src='/login-image.png' className={styles.imageSection} />
      </div>
    </Page>
  );
}

const confirmationFormSchema = Yup.object().shape({
  username: Yup.string().required(),
  password: Yup.string().required(),
  code: Yup.string().length(6, "על קוד האימות להיות באורך 6 ספרות").required("שכחת למלא את הקוד"),
});
