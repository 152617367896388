import { useEffect, useReducer } from "react";
import { useRecoilValue } from "recoil";
import { hadshanutUserState } from "../../state";
import { getRelevantTimeParams } from "../../utils/calls/timeParams";
import { agentsReducer } from "../context/agents-reducer";
import { callsReducer } from "../context/calls-reducer";
import { dialerRequestReducer } from "../context/dialer-requests-reducer";
import { ActionType, AgentMessage, CallAttributes, CallMessage, DialerRequestMessage, Message } from "../interfaces";
import { MonitorConfiguration } from "../use-monitor-config";
import events, { EventType } from "../web-socket/pub-sub/event-bus";

type uuid = string;

export const useStore = (monitorConfig?: MonitorConfiguration) => {
  const user = useRecoilValue(hadshanutUserState);
  const selfManagedGroupUuids = user?.domain.config.selfManagedGroups || [];

  const [rawAgents, agentsDispatch] = useReducer(agentsReducer, []);
  const [rawCalls, callDispatch] = useReducer(callsReducer, []);
  const [rawRequests, dialerRequestDispatch] = useReducer(dialerRequestReducer, []);
  
  const valid_unanswered_threshold = monitorConfig?.valid_unanswered_threshold || 5;

  /* Agents event listener */
  useEffect(() => {
    events.on(EventType.AGENT, (message) => {
      try {
        const agentMessage = message as AgentMessage;
        const rawAgent = agentMessage.data;
        if (agentMessage.action === ActionType.DELETE) {
          // Just remove current agent
          agentsDispatch({ type: agentMessage.action, agent: rawAgent });
        } else {
          agentsDispatch({ type: agentMessage.action, agent: rawAgent });
        }
      } catch (err) {
        console.error(err);
      }
    });
  }, []);

  /* Calls event listener */
  useEffect(() => {
    events.on(EventType.CALL, (message: Message) => {
      try {
        const callMessage = message as CallMessage;
        const rawCall = callMessage.data;

        // validCallsFilter() decides if the call should appear in the monitor. this may change along the call's life
        switch(message.action) {
          case ActionType.POST:
            if (!validCallsFilter(rawCall, valid_unanswered_threshold, selfManagedGroupUuids)) {
              return;                               // call is create and not valid - just ignore it
            }
            break;
          case ActionType.PUT:
            if (!validCallsFilter(rawCall, valid_unanswered_threshold, selfManagedGroupUuids)) {
              message.action = ActionType.DELETE;   // call is updated and becomes not valid - remove from calls list
            }
            break;
        }

        callDispatch({ type: callMessage.action, call: rawCall });
      } catch (err) {
        console.error(err);
      }
    });
  }, []);

  /* Dialer Requests event listener */
  useEffect(() => {
    events.on(EventType.DIALER_REQUEST, (message: Message) => {
      try {
        const dialerRequestMessage = message as DialerRequestMessage;
        const dialerRequest = dialerRequestMessage.data;
        dialerRequestDispatch({ type: dialerRequestMessage.action, dialerRequest });
      } catch (err) {
        console.error(err);
      }
    });
  }, []);

  // Transform Call objects
  const calls = rawCalls
    .filter(call => validCallsFilter(call, valid_unanswered_threshold, selfManagedGroupUuids))
    .map(call => {
      const callTimes = getRelevantTimeParams(call, selfManagedGroupUuids);
      return call.callDirection === "inbound"
      // inbound calls
      ? {
          ...call,
          groupUuid: callTimes?.groupUuid,
          startTime: callTimes?.groupEnterTime!,
          answerTime: callTimes?.memberAnswerTime,
          endTime: callTimes?.groupExitTime ?? (call.endTime ? new Date(call.endTime) : undefined),
          originalStartTime: new Date(call.startTime),
        }
        // outbound calls. also unknown calls
      : {
          ...call,
          groupUuid: callTimes?.groupUuid,
          startTime: new Date(call.startTime),
          answerTime: call.answerTime ? new Date(call.answerTime) : undefined,
          endTime: call.endTime ? new Date(call.endTime) : undefined,
          originalStartTime: new Date(call.startTime),
        };
    });

  const dialerRequests = rawRequests.map((rawRequest) => ({
    ...rawRequest,
    start_date: new Date(rawRequest.start_date),
    end_date: rawRequest.end_date ? new Date(rawRequest.end_date) : undefined,
  }));

  // Transform Agent objects
  const agents = rawAgents
    .filter(
      (rawAgent) =>
        rawAgent.agentStatus !== "Logged Out" &&
        rawAgent.queueUuids.some((id) => selfManagedGroupUuids?.includes(id))
    )
    .map((rawAgent) => {
      return {
        ...rawAgent,
        ccqs: rawAgent.queueUuids.map(ccqUuid => 'TEST') // @@@ Itay: Change this mapping
          // (ccqUuid) => rawGroups.find((group) => group.id === ccqUuid)?.name!
        , // Assuming group will always be present before agent
      };
    })
    .sort((a, b) => a.number.localeCompare(b.number));

  return {
    agents,
    calls,
    dialerRequests
  };
};

const validCallsFilter = (call: CallAttributes, valid_unanswered_threshold: number, selfManagedGroupUuids: uuid[]) => {
  // shouldn't happen
  if (!call) {
    return false;
  }

  // discard local calls
  if (call.callDirection === 'local') { // callDirection "unknown" may change to inbound/outbound/local, so not discarded
    return false;
  }

  // discard calls we don't know their timing. should never happen
  const callTimes = getRelevantTimeParams(call, selfManagedGroupUuids);

  if (!callTimes) {
    return true;
  }

  // accept calls that are not abandoned, that is: they are still in group or were answered
  const callIsAbandoned = callTimes.groupExitTime && !callTimes.memberAnswerTime;

  if (!callIsAbandoned) {
    return true;
  }

  // discard abandoned calls that are shorted than valid_unanswered_threshold seconds
  const abandonedTooShort = callTimes.groupExitTime!.getTime() - callTimes.groupEnterTime.getTime() <= valid_unanswered_threshold * 1000;

  if (abandonedTooShort) {
    return false;
  }

  // accept any call that reached here
  return true;
};
