import { DialogStateReturn } from "reakit/ts";
import { Dialog } from "../../../../design/dialog/dialog";

export const ResetPasswordDialog = ({ dialog }: { dialog: DialogStateReturn }) => (
  <Dialog {...dialog}
    variant="message"
    header="בקשה לאיפוס סיסמה"
    aria-label="איפוס סיסמה"
  >
    קוד חד פעמי לאיפוס הסיסמה נשלח למייל של המשתמש
  </Dialog>
)
