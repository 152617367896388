import { FC, ReactNode } from 'react';
import { StyledDropdown } from './StyledDropdown';
import { HorizontalPosition, VerticalPosition } from '../../../theme/muiTypes';

export interface DropdownProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  children: ReactNode;
  menuArrow?: boolean;
  verticalPosition?: VerticalPosition;
  horizontalPosition?: HorizontalPosition;
  onClose: () => void;
}

export const Dropdown: FC<DropdownProps> = (props) => {
  const {
    anchorEl,
    children,
    menuArrow = false,
    verticalPosition = VerticalPosition.Bottom,
    horizontalPosition = HorizontalPosition.Right,
    ...rest
  } = props;
  const anchorOrigin = { vertical: verticalPosition, horizontal: horizontalPosition };
  const transformOrigin = { vertical: VerticalPosition.Top, horizontal: HorizontalPosition.Right };
  const dropdownProps = { anchorOrigin, transformOrigin, menuArrow, ...rest };

  return (
    <StyledDropdown anchorEl={anchorEl} {...dropdownProps}>
      {children}
    </StyledDropdown>
  );
};
