import { Route, Routes, useLocation } from 'react-router-dom';
import { CreateEvent } from './create-event';
import { DeleteEvent } from './delete-event';
import { EditEvent } from './edit-event';
import { EventList } from './event-list/event-list';

export function Events() {
  return (
    <Routes>
      <Route
        path={`/events/new`}
        Component={CreateEvent}
      />
      <Route
        path={`/events/:id/edit`}
        Component={EditEvent}
      />
      <Route
        path={`/events/:id/delete`}
        Component={DeleteEvent}
      />
      <Route
        path="/"
        Component={EventList}
      />
    </Routes>
  )
};