import { ComponentType } from '../../formConfig/types';
import { CALLBACK_FORM_FIELDS_CONFIG } from '../formConfig/callbackDialerFormConfig';
import { CallbackFieldConfig, CallbackFieldName, FieldName } from '../types';

// React-hook-form field array structure: fieldName.[index].fieldKey
export const isDynamicField = (fieldName: FieldName) => {
  const splitFieldName = fieldName.split('.');
  return splitFieldName.length >= 3;
};

export const getFieldName = (fieldName: FieldName) => {
  const splitFieldName = fieldName.split('.');
  return splitFieldName.length >= 3;
};

export const getFieldKey = (fieldName: FieldName) => {
  const splitFieldName = fieldName.split('.');
  return splitFieldName.length >= 3;
};

export const isFileField = (fieldName: CallbackFieldName) => {
  const fieldValueList: CallbackFieldConfig[] = Object.values(CALLBACK_FORM_FIELDS_CONFIG);
  const fileFieldValueList = fieldValueList.filter(
    (fieldValue) => fieldValue.componentType === ComponentType.FileInput
  );
  const fileFieldNameList = fileFieldValueList.map((fieldValue) => fieldValue.name);

  return fileFieldNameList.includes(fieldName);
};
