import { Checkbox } from "../../checkbox/checkbox";
import { Column, ColumnSortConfig, SortOrder } from "../types";
import styles from "../table.module.scss";
import { ArrowUp } from "../../icons/arrow-up";
import { ArrowDown } from "../../icons";

interface TableHeaderProps<T, K extends keyof T> {
  columns: Column<T, K>[];
  onClick?: (column: Column<T, K>) => void;
  sortConfig: ColumnSortConfig;
  checked?: Set<string>;
  onCheck?: (id: string) => void;
  onMasterCheck?: () => void;
  checkboxes?: boolean;
}

export const TableHeader = <T, K extends keyof T>({ checkboxes, onMasterCheck, onCheck, checked, columns, onClick, sortConfig }: TableHeaderProps<T, K>) => {

  const renderCheckbox = Boolean(checkboxes && onMasterCheck && onCheck && checked);
  const handleClick = (column: Column<T, K>) => {
    if (onClick) onClick(column);
  }

  const headers = columns.map(column => {
    return (
      <th
        key={String(column.key)}
        onClick={() => handleClick(column)}
        className={column.className}
      >
        <div className={onClick ? styles.headerCellClickable : styles.headerCell}>
          <span>{column.header}</span>
          {
            sortConfig.sortOrder !== SortOrder.NotSorted && sortConfig.columnName === column.key && <span>{sortConfig.sortOrder === SortOrder.Ascending ? <ArrowUp size={20} /> : <ArrowDown size={20} />}</span>
          }
        </div>
      </th>
    )
  });

  if (renderCheckbox) {
    headers.unshift(<th key='master-checkbox'>
      <Checkbox checked={checked?.has('master')} onChange={onMasterCheck} />
    </th>)
  }
  return (
    <thead>
      <tr>{headers}</tr>
    </thead>
  )
};
