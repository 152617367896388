import React from "react";
import { useDestinations } from "../../api/use-destinations";
import { useMe } from "../../api/use-me";
import { CallflowNode, CallflowNodeContent, CallflowNodeHeader, CallflowNodeOverline, CallflowNodeTitle } from "./callflow-node/callflow-node";
import { DestinationNodeProps } from "./destination-node";

export function FallbackNode(props: DestinationNodeProps) {

  const user = useMe();
  const destinations = useDestinations(user?.data);
  const destination = destinations.data ? destinations.data[props.destination] : null;

  const { overline, title } = getDestinationProps(destination);

  return destination ? (
    <CallflowNode>
      <CallflowNodeHeader>
        <CallflowNodeOverline>{overline}</CallflowNodeOverline>
        <CallflowNodeTitle>{title}</CallflowNodeTitle>
      </CallflowNodeHeader>
      <CallflowNodeContent>
        מנוהל על-ידי iPracticom. פנה/י לתמיכה לפירוט או שינויים
      </CallflowNodeContent>
    </CallflowNode>
  ) : null;
}

function getDestinationProps(props: { type: string, number: string, name?: string } | null) {
  const title = props?.name
    ? `${props.name} (${props.number})`
    : props?.number;

  switch (props?.type) {
    case 'Extension':
      return {
        overline: 'שלוחה',
        title,
      };
    case 'RingGroup':
      return {
        overline: 'קבוצה',
        title,
      }
    default:
      return {
        overline: 'יעד לא מזוהה',
        title,
      }
  }
}