import { NavLink } from 'react-router-dom';
import { CallAttributes, call_groupUuids } from '../../../interfaces';
import { useMe } from '../../../../api/use-me';
import { Table } from '../../../../design/table/table';
import { calculateSlaCounts } from '../../charts/calculateSlaCounts';
import styles from './inbound-calls-list.module.scss';
import { Column } from '../../../../design/table/types';
import { isCallInboundAnswered } from '../../../../utils/calls/filter';
import { GroupResource } from '../../../../api/types';

interface InboundCallsListProps {
  calls: CallAttributes[];
  slaThreshold: number;
  tableName: string | undefined;
  validCallThreshold: number;
  groups: GroupResource[];
}

type GroupedCalls = Record<string, CallAttributes[]>;

export const InboundCallsList = (props: InboundCallsListProps) => {
  const user = useMe();
  const selfManagedGroups = props.groups?.filter(group => user?.data?.domain.config.selfManagedGroups.includes(group.id));
  // group call by group uuid
  const groupedInboundCalls = props.calls
    .reduce((grouped, call) => {
      const targetGroupId = call_groupUuids(call).find(group => user?.data?.domain.config.selfManagedGroups.includes(group)); // first self managed group that the call visited
      if (!targetGroupId) return grouped; // this call is faulty and should not count

      if (!grouped[targetGroupId]) {
        grouped[targetGroupId] = []
      }

      grouped[targetGroupId].push(call);
      return grouped;
    }, {} as GroupedCalls);
  
  const refinedData = Object.entries(groupedInboundCalls).map(([groupId, calls]) => {
    const groupName = props.groups?.find(group => group.id === groupId)?.name;
    const incomingCount = calls.length;
    const slaCounts = calculateSlaCounts(calls, props.slaThreshold, [groupId], [groupId], props.validCallThreshold); // sla of calls in the specific group
    const localSla = slaCounts.sla;
    const answeredCalls = calls.filter(call => isCallInboundAnswered(call, props.validCallThreshold));
    const answeredCount = answeredCalls.length;

    return {
      id: groupId,
      groupName,
      incomingCount,
      answeredCount,
      localSla: `${localSla}%`
    }
  });

  const columns: Array<Column<typeof refinedData[0], keyof typeof refinedData[0]>> = [
    {
      key: 'groupName',
      header: "מחלקה",
      className: styles.column
    },
    {
      key: 'incomingCount',
      header: 'נכנסות',
      className: styles.column,
      render: (row) => {
        const group = selfManagedGroups?.find(group => group.id === row.id);
        
        if (!group || !user.data) return (
          <span>{row.incomingCount}</span>
        )

        const todayDate = new Date();
        const dateFilterValue = `${todayDate.getFullYear()}-${todayDate.getMonth()+1}-${todayDate.getDate()}`
        const dateQuery = `from/${dateFilterValue}/to/${dateFilterValue}`
        const groupQuery = `groupsPerDomain/${user.data.domain.name.substring(0, 4)}:${group.number}`;
        const url = `/calls-history/type/inbound/${dateQuery}/${groupQuery}`;
        return (
          <NavLink to={url} style={{borderBlockEnd: '1px solid #181D24'}}>{row.incomingCount}</NavLink>
          )
      }
    },
    {
      key: 'answeredCount',
      header: 'נענו',
      className: styles.column,
      render: (row) => {
        const group = selfManagedGroups?.find(group => group.id === row.id);

        if (!group || !user.data) return (
          <span>{row.incomingCount}</span>
        )

        const todayDate = new Date();
        const dateFilterValue = `${todayDate.getFullYear()}-${todayDate.getMonth()+1}-${todayDate.getDate()}`
        const dateQuery = `from/${dateFilterValue}/to/${dateFilterValue}`
        const groupQuery = `groupsPerDomain/${user.data.domain.name.substring(0, 4)}:${group.number}`;
        const url = `/calls-history/type/inbound/status/answered/${dateQuery}/${groupQuery}`;
        return (
          <NavLink to={url} style={{borderBlockEnd: '1px solid #181D24'}}>{row.answeredCount}</NavLink>
        )
      }
    },
    {
      key: 'localSla',
      header: 'רמת שירות',
      className: styles.column
    }
  ];

  return (
    <Table columns={columns} data={refinedData} showHeaders tableName={props?.tableName} />
  )
}
