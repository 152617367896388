import { CallbackFieldName } from '../types';
import { PermissionLevel } from '../../api/types';
import { CALLBACK_FORM_FIELDS_CONFIG } from '../formConfig/callbackDialerFormConfig';

export const setFieldUIPermission = (fieldName: CallbackFieldName, selectedPermissionLevel: PermissionLevel) => {
  const fieldPermission = CALLBACK_FORM_FIELDS_CONFIG[fieldName].permission;
  if (fieldName === 'selectedPermission') {
    CALLBACK_FORM_FIELDS_CONFIG[fieldName].permission = selectedPermissionLevel;
  } else {
    const hasPermission = selectedPermissionLevel >= fieldPermission;
    CALLBACK_FORM_FIELDS_CONFIG[fieldName].uiConfig.hasPermission = hasPermission;
  }
};
