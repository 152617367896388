import { API } from "aws-amplify";
import moment from "moment";
import useSWR from "swr";
import { CallShape } from "./types";

export function useCall(id: string) {

  const { data, error } = useSWR(id || null, callFetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    data,
    error,
    loading: !error && !data,
  }
}

export function useCalls(filters?: {[key: string]: string}) {

  const paramsWhitelist = [
    "from",
    "to",
    "fromTime",
    "toTime",
    "phone",
    "type",
    'domains',
    "extensionsPerDomain",
    "groupsPerDomain",
    "maxDuration",
    "maxTta",
    "minDuration",
    "minTta",
    "status",
    "page",
    "businessNumbersPerDomain",
    "groupBy",
    "groupKey"
  ];
  
  const queryString = Object.entries(filters || {})
    .filter(([key]) => paramsWhitelist.indexOf(key) !== -1)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
  
  const { data, error } = useSWR(() => filters?.from && filters?.to ? `?${queryString}` : null, callsFetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    calls: data,
    error: error,
    loading: !error && !data,
  }
}

const callFetcher = async (callId: string) => {
  return API.get(
    process.env.REACT_APP_CALLS_HISTORY_API_NAME!,
    '/' + callId,
    {}
  ).then(r => {
    return ({
      call: mapCalls([r.data.call])[0],
      sms: r.data.sms,
    });
  })
}
const callsFetcher = async (path: string) => {
  return API.get(
    process.env.REACT_APP_CALLS_HISTORY_API_NAME!,
    path,
    {},
  ).then(r => {
    return ({
      data: mapCalls(r.data),
      meta: r.meta
    });
  })
}

function mapCalls(calls: CallShape[]): CallShape[] {
  return calls.map(call => {
    return {
      ...call,
      answer_time: call.answer_time ? moment(call.answer_time) : null,
      end_time: moment(call.end_time),
      start_time: moment(call.start_time),
    };
  })
}