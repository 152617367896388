import { GLOBAL_TEXTS } from '../../utils/globalTexts';

export const GENERAL_SETTINGS_TEXTS = {
  GENERAL_TITLE: 'כללי',
};

export const AGENT_DIALING_SETTINGS_TEXTS = {
  AGENT_DIALING_TITLE: 'חיוג נציג',
  AGENT_DIALING_SUB_TITLE: 'כאן ניתן להגדיר את פעולות החייגן',
  UPLOAD_IVR_FILE: 'טעינת קובץ קולי להשמעה לנציג לפני קבלת שיחה',
  UPLOAD_NEW_IVR_FILE: 'העלאת קובץ הקלטה חדש',
  FILE_SIZE_LIMIT: 'ניתן להעלות קובץ עד 5MB',
};

export const CUSTOMER_DIALING_SETTINGS_TEXTS = {
  CUSTOMER_DIALING_TITLE: 'חיוג לקוח',
  CUSTOMER_DIALING_SUB_TITLE: 'כאן ניתן להגדיר את פעולות החייגן',
};

export const SMS_SETTINGS_TEXTS = {
  SMS_TITLE: 'סמס לקוח',
  SMS_SUB_TITLE: 'כאן ניתן להגדיר את פעולות החייגן',
};

export const NO_REPLAY_SETTINGS_TEXTS = {
  NO_REPLAY_TITLE: 'אין מענה',
  NO_REPLAY_SUB_TITLE: 'מה קורה כאשר הלקוח לא מקבל מענה לפני הפעלת שירות שיחה חוזרת',
};

export const REPORT_SETTINGS_TEXTS = {
  REPORT_TITLE: 'דיווחים',
  REPORT_SUB_TITLE: 'כאן ניתן להגדיר את פעולות החייגן',
  NUMBER_TO_SEND: 'מספר לשליחה',
  MAIL_TO_SEND: 'מייל לשליחה',
  SENDER_NAME: 'שם שולח',
};

export const DIALOG_TEXTS = {
  DELETE_CALLBACK_BUTTON_TEXT: 'מחיקה',
  DELETE_CALLBACK_TITLE: 'מחיקת חייגן שיחה',
  CANCEL_BUTTON_TEXT: 'ביטול',
  DELETE_CALLBACK_MESSAGE_1: 'האם ברצונך למחוק את',
  DELETE_CALLBACK_MESSAGE_2: 'מרשימת חייגני שיחה?',
};

export const fileBtnTexts = {
  selectFileBtnText: GLOBAL_TEXTS.SELECT_FILE,
  changeFileBtnText: GLOBAL_TEXTS.UPLOAD_ANOTHER_FILE,
  deleteFileBtnText: GLOBAL_TEXTS.DELETE_FILE,
};
