import { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { useDialogState } from 'reakit';
import { Spinner } from '../../design/spinner/spinner';
import { Page } from '../../page';
import { useInvitation } from '../../api/use-invitation';
import { Dialog } from '../../design/dialog/dialog';
import { Tag } from '../../design/tag/tag';
import { InvitationStatus } from '../invitation-status';
import { Button } from '../../design/button';
import styles from './invitation-page.module.scss';

export function InvitationPage() {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const invitation = useInvitation(id!);
  const [clipLinkButtonText, setClipLinkButtonText] = useState('העתק קישור');

  const domainName = invitation.data?.user.domainName.split('.')[0];
  const invitationLink = `${window.location.origin}/invitation/${invitation.data?.id}`;

  const dialog = useDialogState({
    visible: true,
  })

  useEffect(() => {
    if (dialog.visible === false) {
      navigate('/invitations');
    }
  }, [dialog.visible, navigate]);

  const clipInvitationLink = () => {
    navigator.clipboard.writeText(invitationLink).then(function () {
      setClipLinkButtonText('הקישור הועתק');

      setTimeout(() => {
        setClipLinkButtonText('העתק קישור');
      }, 3000)
    });
  }


  return (
    <Page
      name="Invitation Page"
    >
      <Dialog {...dialog}
        className={styles.dialog}
        variant="info"
        header="הזמנה"
        aria-label='הזמנה'>
        {
          invitation.data ? (
            <div>
              <div className={styles.organization}>
                <div className={styles.label}>
                  לקוח
                </div>
                <span>
                  <span className={styles.organizationName}>
                    {invitation.data.user.organizationName}
                  </span>
                  {domainName && <Tag label={domainName} variant="filled" />}
                </span>
              </div>
              <div className={styles.invitationDetails}>
                <div className={styles.name}>
                  <div className={styles.label}>
                    מקבל ההזמנה
                  </div>
                  {invitation.data.user.name}
                </div>
                <div className={styles.status}>
                  <div className={styles.label}>
                    סטטוס
                  </div>

                  <InvitationStatus invitation={invitation.data} />
                </div>
                <div className={styles.date}>
                  <div className={styles.label}>
                    תאריך יצירה
                  </div>
                  {moment(invitation.data.createdAt).format('יום dddd, DD/MM/YYYY')}
                </div>
              </div>
              <div className={styles.invitationLinkSection}>
                <div className={styles.label}>
                  קישור להרשמה
                </div>
                <span className={invitation.data.status === 'PENDING' ? '' : styles.invitationLink}>
                  {invitationLink}
                </span>
                {
                  invitation.data.status === 'PENDING' && (
                    <Button className={styles.clipButton} onClick={clipInvitationLink}>
                      {clipLinkButtonText}
                    </Button>
                  )
                }
              </div>
            </div>
          ) : <Spinner />
        }
      </Dialog>
    </Page>
  );
}



export interface InvitationPageParams {
  id: string;
}