import { MonitorProps } from '../..';
import { HorizontalPartition } from '../../../design/partition/horizontal-partition';
import { AgentsStatusList, AgentsStatusSums, Card } from '../../common/components';
import { getAgentsStatusSums } from '../../utils/getAgentsStatusSums';
import { PageHeader } from '../mobile-monitor';
import styles from './agents-page.module.scss';

interface AgentsPageProps extends MonitorProps { onBackBtnClick: () => void; }

export const AgentsPage: React.FC<AgentsPageProps> = ({ data, breakTypes, onBackBtnClick }) => {
  const statusSums = getAgentsStatusSums(data.agents, breakTypes);
  
  return (
    <div className={styles.layout}>
      <PageHeader name='מצב סוכנים' onBackBtnClick={onBackBtnClick} />

      <Card>
        
        <div className={styles.wrapper}>
          <AgentsStatusSums agentsStatus={statusSums.map(status => ({ label: status.status.name, count: status.value, color: status.status.color }))} />
        </div>
        <HorizontalPartition />
        
        <AgentsStatusList agents={data.agents} breakTypes={breakTypes} />
      </Card>
    </div>
  );
};
