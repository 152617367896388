import React from 'react';
import { Icon, IconProps } from './icon';

export const ArrowHorizontal = ({...props}: IconProps) => (
  <Icon 
    viewBox="0 0 96 96"
    fill="currentColor"
    {...props}
  >
    <polygon fillRule="evenodd" points="20 51 34.121 63.879 29.879 68.121 9.757 48 29.879 27.879 34.121 32.121 20 45 76 45 61.879 32.121 66.121 27.879 86.243 48 66.121 68.121 61.879 63.879 76 51"/>
  </Icon>
);


