import styles from "./user-management.module.scss";
import { useState } from "react";
import { UserListItem } from "./user-list-item";
import Autosuggest, { ChangeEvent } from "react-autosuggest";
import { AutoSuggestInput } from "../../../design/form/input/auto-suggest-input";
import { useDomains, useQuery, User } from "../../../api/use-users";
import { useMe } from "../../../api/use-me";
import { API } from "aws-amplify";

const UserSuggestion = (user: { user_name: string; user_email: string }) => (
  <div className={styles.suggestionContent}>
    {user.user_name} {user.user_email}
  </div>
);

export const UserManagement = () => {
  const activeUser = useMe();
  const [searchValue, setSearchValue] = useState<string>("");
  const users = useQuery({ user: searchValue });
  const domains = useDomains(activeUser.data?.domain.id);
  const domainUsers = domains.domains?.[0]?.users;
  const suggestions = users?.data?.sort((userA: any, userB: any) => {
    return userA.user_name >= userB.user_name ? 1 : -1;
  });

  const handleAddUserToDomain = async (
    e: React.FormEvent<HTMLElement>,
    autosuggestParams: ChangeEvent
  ) => {
    if (autosuggestParams.method !== "click" && autosuggestParams.method !== "enter") {
      return;
    }

    const user = users.data.find((user: any) => user.user_email === autosuggestParams.newValue);
    const apiName = process.env.REACT_APP_MULTI_TENANT_API_NAME!;
    const path = `/user/${user?.user_uuid}/domain`;
    const myInit = { body: { domain_uuid: activeUser.data?.domain.id } };

    try {
      await API.post(apiName, path, myInit);
    } catch (error) {
      console.error(error);
    } finally {
      domains.mutate();
    }
  };
  const autosuggestInputProps = {
    onChange: handleAddUserToDomain,
    placeholder: "כתובת מייל או שם",
    value: searchValue,
  };

  return (
    <div>
      <div className={styles.heading}>ניהול משתמשים</div>
      <div className={styles.title}>הוספת משתמשים</div>
      <div style={{ marginBlockEnd: "32px" }}>
        <div className={styles.suggestionsWrapper}>
          <Autosuggest
            id='users'
            suggestions={searchValue.length > 0 && suggestions ? suggestions : []}
            renderSuggestion={UserSuggestion}
            getSuggestionValue={(user) => user.user_email}
            onSuggestionsFetchRequested={({ value }) => setSearchValue(value)}
            onSuggestionsClearRequested={() => setSearchValue("")}
            renderInputComponent={AutoSuggestInput}
            inputProps={autosuggestInputProps}
            theme={styles}
          />
        </div>
      </div>
      <div className={styles.title}>משתמשים</div>
      {domainUsers?.map((user: Omit<User, "domains">) => (
        <UserListItem
          key={user.user_email}
          user={user}
          onRemove={() => domains.mutate()}
          onEdit={() => domains.mutate()}
        />
      ))}
    </div>
  );
};
