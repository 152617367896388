import { API, Auth } from "aws-amplify";
import { useState } from "react";
import Autosuggest, { ChangeEvent } from "react-autosuggest";
import useDomains, { DomainAttributes } from "../../../api/use-domains";
import { useMe } from "../../../api/use-me";
import { AutoSuggestInput } from "../../../design/form/input/auto-suggest-input";
import { ArrowLeft } from "../../../design/icons/arrow-left";
import { Spinner } from "../../../design/spinner/spinner";
import styles from "./change-customer-form.module.scss";

const DomainSuggestion = (domain: DomainAttributes) => {
  const shortDomainName = domain.name.split(".")[0];
  return (
    <div className={styles.suggestionContent}>
      <div>
        {shortDomainName} - {domain.ownerName}
      </div>
      <ArrowLeft size={24} className={styles.suggestionContentArrow} />
    </div>
  );
};

export function ChangeCustomerForm({ onSubmit }: { onSubmit?: () => void }) {
  const user = useMe();
  const [searchValue, setSearchValue] = useState<string>("");
  const { domains, error, loading } = useDomains(searchValue);

  const suggestions = domains.sort();
  const noResults = !error && suggestions.length === 0 && searchValue.length > 0;

  const autosuggestInputProps = {
    onChange: (e: React.FormEvent<HTMLElement>, autosuggestParams: ChangeEvent) => {
      if (["click", "enter"].includes(autosuggestParams.method)) {
        const domain = domains.find(
          (domain) => domain.id === autosuggestParams.newValue
        ) as DomainAttributes;
        updateUserDomain(domain);
      }
    },
    placeholder: "שם לקוח או דומיין...",
    value: searchValue,
  };

  const updateUserDomain = async (domain: DomainAttributes) => {
    if (onSubmit) onSubmit();
    const authUser = await Auth.currentAuthenticatedUser();
    await Promise.all([
      Auth.updateUserAttributes(authUser, {
        "custom:domain_uuid": domain.id,
      }),
      API.patch(process.env.REACT_APP_WEB_API_NAME!, `/users/${user?.data?.id}/domain`, {
        body: domain,
      }),
    ]);
    window.location.reload();
  };
  const shortDomainName = user.data?.domain.name.split(".")[0];
  return (
    <div className={styles.form}>
      <div className={styles.formContent}>
        <label className={styles.label}>לקוח</label>

        <span className={styles.clientNameHeading}>
          <label className={styles.title}>{user?.data?.organization.name}</label>

          <div className={styles.organizationBadge}>{shortDomainName}</div>
        </span>

        <label className={styles.label}>
          <span>החלפת לקוח</span>
          <Autosuggest
            id='domains'
            suggestions={searchValue.length > 0 ? suggestions : []}
            renderSuggestion={DomainSuggestion}
            getSuggestionValue={(domain) => domain.id}
            onSuggestionsFetchRequested={({ value }) => setSearchValue(value)}
            onSuggestionsClearRequested={() => setSearchValue("")}
            renderInputComponent={AutoSuggestInput}
            inputProps={autosuggestInputProps}
            theme={styles}
          />
        </label>

        {searchValue.length > 0 && loading && (
          <div style={{ padding: "1rem .25rem" }}>
            <Spinner />
          </div>
        )}
        {!loading && noResults && <div style={{ padding: "1rem .5rem" }}>אין תוצאות</div>}
      </div>
    </div>
  );
}
