import { Link } from 'react-router-dom';
import styles from './number-list-item.module.scss';
import { safeParsePhoneNumber } from '../../../../utils/strings/parse-phone';

export const NumberListItem = (props: any) => {
  return (
    <Link
      to={`/my-numbers/${props.businessNumber.number}`}
      key={`publicNumberList.item.${props.businessNumber.number}`}
    >
      <li className={styles.item}>
        {safeParsePhoneNumber(props.businessNumber.number, "IL")} {props.businessNumber.label ? ` - ${props.businessNumber.label}` : ``}
      </li >
    </Link>
  )
}
