import { CallAttributes, call_groupEnterTimes, call_groupUuids } from "../interfaces";
import { waitingCallsFilter } from "./callFilters";

interface WaitingCallsStats {
  count: number;
  oldestCallEnterTime: number;
  callId: string;
}

// reduce all waiting calls from the `calls` list to { count: number, oldestCallEnterTime: number, callId: uuid }
export function reduceWaitingCalls(calls: CallAttributes[], groupUuids: string[]): WaitingCallsStats {

  // Reduce calls list to find number of waiting calls and longest waiting call time
  const waitingCallsInfo = calls.filter(waitingCallsFilter).reduce((waitingCallsStats, call) => {
    const groupIndex = call_groupUuids(call).findIndex((groupUuid: string) => groupUuids?.includes(groupUuid));

    if (groupIndex === -1) {
      return waitingCallsStats; // shouldn't happen
    }

    const enterDateStr = call_groupEnterTimes(call)[groupIndex];
    const enterTime = enterDateStr ? new Date(enterDateStr).getTime() : undefined;
    if (!enterTime) {
      return waitingCallsStats; // shouldn't happen
    }
    const count = !call.endTime && !call.answerTime ? waitingCallsStats.count + 1 : waitingCallsStats.count;
    const oldestCallEnterTime = Math.min(waitingCallsStats.oldestCallEnterTime, enterTime);
    const callId = enterTime < waitingCallsStats.oldestCallEnterTime ? call.id : waitingCallsStats.callId;
    return { count, oldestCallEnterTime, callId };
  }, { count: 0, oldestCallEnterTime: Number.MAX_VALUE, callId: "" });

  return waitingCallsInfo;
}