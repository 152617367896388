import classNames from 'classnames';
import { MonitorProps } from '../..';
import { HorizontalPartition } from '../../../design/partition/horizontal-partition';
import { getDigitTimeFromMillis, safeParsePhoneNumber } from '../../../utils';
import { Card } from '../../common/components';
import { TextElement } from '../TextElement/text-element';
import { UnansweredGroupCaller, createUnansweredCallsList } from '../../common/components/unanswered-table/unanswered-calls-list-data';
import styles from './UnansweredCallsPage.module.scss';
import { PageHeader } from '../mobile-monitor';

interface UnansweredCallsPageProps extends MonitorProps { onBackBtnClick: () => void; }

export const UnansweredCallsPage: React.FC<UnansweredCallsPageProps> = ({ data, monitorConfig, onBackBtnClick }) => {
  
  const unansweredCallsList = createUnansweredCallsList(data.calls, monitorConfig, data.groups);
  
  unansweredCallsList.sort(sortByStatus);

  return (
    <div className={styles.layout}>
      
      <PageHeader name='שיחות שלא נענו' onBackBtnClick={onBackBtnClick} />

      <div className={styles.cardsList}>
        {
          unansweredCallsList.map(call => {

            return (
              <UnansweredCallsCard
                key={call.id}
                wait_time_threshold={monitorConfig.waiting_alert_threshold}
                {...call}
              />
            )
          })
        }
      </div>
    </div>
  );
};


interface UnansweredCallsCardProps extends UnansweredGroupCaller {
  wait_time_threshold: number;
}

const UnansweredCallsCard: React.FC<UnansweredCallsCardProps> = ({ wait_time_threshold, ...props }) => {
  const isResolved = props.status !== 'טרם טופל';
  const averageWaitTimeMs = Math.ceil(props.totalRingTimeMs / props.callsCount);
  
  return (
     <Card className={classNames(styles.card, { [styles.resolved]: isResolved })}>

      <TextElement
        label={"מתקשר"}
        value={safeParsePhoneNumber(props.callerNumber)}
      />

      <HorizontalPartition />

      <div className={styles.flexRowSpaceBetween}>

        <TextElement
          label={'תור'}
          value={props.queueName}
        />
        
        <span className={styles.verticalPartition} />

        <TextElement
          label={'שיחות'}
          value={`${props.callsCount}`}
        />

      </div>

      <HorizontalPartition />

      <div className={styles.flexRowSpaceBetween}>

        <div className={styles.labelAndValueContainer}>
          <span className={styles.label}>{ 'זמן המתנה' }</span>
          <span className={classNames(styles.value, { [styles.exceedTimeLimit]: averageWaitTimeMs > wait_time_threshold * 1000 })}>
            {getDigitTimeFromMillis(averageWaitTimeMs, 'MM:SS')}
          </span>
        </div>

        {
          isResolved && (
            <div style={{alignSelf: 'center'}}>{props.status}</div>
          )
        }
      </div>
    </Card>
  )
}

function sortByStatus(a: any, b: any): number {
  if (a.status === 'טרם טופל'  && b.status !== 'טרם טופל') {
      return -1;
  } else if (a.status !== 'טרם טופל'  && b.status === 'טרם טופל') {
      return 1;
  } else {
      return 0;
  }
};