import { Auth } from 'aws-amplify';
import { Form, Formik } from 'formik';
import mixpanel from 'mixpanel-browser';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { UserResource } from '../api/types';
import { useMe } from '../api/use-me';
import { Button } from '../design/button';
import { Field } from '../design/form/field/field';
import { Input } from '../design/form/input/input';
import { Select } from '../design/form/select/select';
import { AppLayout, AppLayoutContainer, AppLayoutHeader, AppLayoutTitle } from '../layouts/app-layout/app-layout';
import { Page } from '../page';
import { ISRAELI_PHONE_REGEXP } from '../utils';
import { safeParsePhoneNumber } from '../utils/strings/parse-phone';
import styles from './profile.module.scss';

export function ProfilePage() {
  const user = useMe(true);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (values: ProfileFormValues) => {
    try {
      setLoading(true);
      const attributes = {
        ...values,
        ...(values.phone_number.length > 0
          ? { phone_number: formatPhoneNumber(values.phone_number.replaceAll('-', '').replaceAll(' ', '')) }
          : {}),
      };

      if (user?.data?.gender !== values.gender) {
        mixpanel.people.set('Preferred Gender', values.gender);
      }

      const authenticatedUser = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(authenticatedUser, attributes);
      await user.mutate();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Page name='Profile'>
      <AppLayout>
        <AppLayoutContainer>
          <AppLayoutHeader>
            <AppLayoutTitle>הפרופיל שלי</AppLayoutTitle>
          </AppLayoutHeader>
          {user?.data && <ProfileForm loading={loading} user={user?.data} onSubmit={handleSubmit} />}
          <Link to='/toc.pdf' target='blank' className={styles.tocLink}>
            תנאי שימוש
          </Link>
        </AppLayoutContainer>
      </AppLayout>
    </Page>
  );
}

function ProfileForm({ onSubmit, user, loading }: ProfileFormProps) {
  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: user?.name,
        phone_number: user?.phone_number ? safeParsePhoneNumber(user?.phone_number, 'IL') : '',
        gender: user?.gender,
      }}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({ isValid }) => (
        <Form className={styles.form}>
          <Field label='השם שלך' name='name'>
            <Input />
          </Field>
          <Field label='מספר טלפון' name='phone_number'>
            <Input />
          </Field>
          <Field label='לשון פנייה' name='gender'>
            <Select>
              <option value='male'>זכר</option>
              <option value='female'>נקבה</option>
            </Select>
          </Field>
          <Button type='submit' loading={loading} variant='primary' className={styles.submit} disabled={!isValid}>
            עדכון
          </Button>
        </Form>
      )}
    </Formik>
  );
}

const REQUIRED_FIELD_TEXT = 'שדה זה הינו חובה';

const schema = yup.object().shape({
  name: yup.string().required(REQUIRED_FIELD_TEXT),
  phone_number: yup
    .string()
    .required(REQUIRED_FIELD_TEXT)
    .test('is-valid-phone', 'מספר טלפון לא תקין', (value) => ISRAELI_PHONE_REGEXP.test(value)),
  gender: yup.string().oneOf(['male', 'female']).required(REQUIRED_FIELD_TEXT),
});

interface ProfileFormProps {
  user: UserResource;
  onSubmit: (values: ProfileFormValues) => void;
  loading: boolean;
}

interface ProfileFormValues {
  name: string;
  phone_number: string;
  gender: 'male' | 'female';
}

// Cognito accepts phone number only in the format:
// +{country-code}{phone_number}
function formatPhoneNumber(phoneNumber: string) {
  const countryCodeIL = '+972';
  if (phoneNumber.startsWith('+972') || phoneNumber.startsWith('972')) {
    return phoneNumber;
  } else if (phoneNumber.startsWith('0')) {
    const cognitoPhoneNumberFormat = countryCodeIL + phoneNumber.substring(1);
    return cognitoPhoneNumberFormat;
  } else {
    return countryCodeIL + phoneNumber;
  }
}
