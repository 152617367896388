import { ActionType, AgentAttributes } from "../interfaces";

interface AgentAction {
  type: ActionType;
  agent: AgentAttributes;
}

export function agentsReducer(state: AgentAttributes[], action: AgentAction) {
  const { type, agent } = action;
  switch (type) {
    case ActionType.POST: {
      if (state.find(a => a.id === agent.id)) {
        // console.error('Agent already exists');
        return state;
      }
      return [...state, agent]; // Add a new Agent
    }
    case ActionType.DELETE: {
      return state.filter(agnt => agnt.id !== agent.id); // Delete agent with given id
    }
    case ActionType.PUT: {
      const oldAgent = state.find(a => a.id === agent.id);
      if (!oldAgent) {
        console.error(`Trying to update an agent which does not exist ${agent}`);
        return state;
      }
      const newState = state
        .filter(agent => agent.id !== oldAgent.id)   // Remove agent which should be updated
        .concat([{ ...agent }]);   // Append the agent after overriding it's fields
      return newState
    }
    case ActionType.CLEAR: return []
    default:
      return state;
  }
}