import { ChangeEvent, FC, memo, MouseEvent, ReactNode, useCallback, useMemo, useState } from 'react';

import { ItemList } from '../itemList/ItemList';
import { TEXTS } from '../../../monitor/utils/texts';
import { CustomChip } from '../customChip/CustomChip';
import { ButtonVariant } from '../../../theme/muiTypes';
import { SelectOption } from '../../../formConfig/types';
import { CustomButton } from '../customButton/CustomButton';
import { CustomDivider } from '../customDivider/CustomDivider';
import { SearchDropdown } from '../searchDropdown/SearchDropdown';
import { ReactComponent as PlusIcon } from '../../../design/icons/filled-plus.svg';
import { StyledBtnWrapper, StyledEditableDropdown } from './StyledEditableDropdown';
import { ReactComponent as CloseIcon } from '../../../design/icons/chip-close-icon.svg';
import { getFilteredItems, getSearchableItems } from '../../../utils/filterItemOptions';

const { AGENT_GROUP_ASSOCIATION_LABEL } = TEXTS;
export interface EditableDropdownProps {
  items: SelectOption[];
  editMode: boolean;
  className?: string;
  inputIcon?: ReactNode;
  placeholder?: string;
  selectedItems: SelectOption[];
  onlyUnselected: boolean;
  onAddItem: (event: MouseEvent<Element>, newItem: SelectOption) => void;
  onChangeMode: () => void;
  onRemoveItem: (itemToRemove: SelectOption) => void;
}

export const EditableDropdown: FC<EditableDropdownProps> = memo((props) => {
  const {
    items,
    editMode,
    inputIcon,
    className,
    placeholder,
    selectedItems,
    onlyUnselected,
    onAddItem,
    onChangeMode,
    onRemoveItem,
  } = props;

  const [searchTerm, setSearchTerm] = useState('');
  const filteredItems = useMemo(
    () => (!onlyUnselected ? items : getFilteredItems(items, selectedItems)),
    [items, onlyUnselected, selectedItems]
  );
  const searchableItems = useMemo(() => getSearchableItems(filteredItems, searchTerm), [filteredItems, searchTerm]);

  const isDeletable = editMode && selectedItems.length > 1;
  const searchDisabled = items.length === selectedItems.length;
  const visibleSearchDropdown = items.length > selectedItems.length;

  const handleChangeSearchTerm = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.trim();
      if (value !== searchTerm) {
        setSearchTerm(value);
      }
    },
    [searchTerm]
  );

  return (
    <StyledEditableDropdown>
      <ItemList
        className={className}
        items={selectedItems}
        ItemComponent={CustomChip}
        icon={isDeletable ? CloseIcon : undefined}
        onDelete={isDeletable ? onRemoveItem : undefined}
      />
      {(!editMode || visibleSearchDropdown) && <CustomDivider hidden={false} />}
      <div>
        {!editMode && (
          <StyledBtnWrapper>
            <CustomButton
              className='noSpace'
              variant={ButtonVariant.text}
              IconComponent={PlusIcon}
              onClick={onChangeMode}
            >
              <span>{AGENT_GROUP_ASSOCIATION_LABEL}</span>
            </CustomButton>
          </StyledBtnWrapper>
        )}
        {editMode && visibleSearchDropdown && (
          <SearchDropdown
            placeholder={placeholder}
            inputIcon={inputIcon}
            searchDisabled={searchDisabled}
            searchTerm={searchDisabled ? '' : searchTerm}
            items={searchableItems}
            onClick={onAddItem}
            onChange={handleChangeSearchTerm}
          />
        )}
      </div>
    </StyledEditableDropdown>
  );
});
