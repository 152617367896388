import React, { useState } from "react";
import mixpanel from "mixpanel-browser";
import {
  CallflowNode,
  CallflowNodeActions,
  CallflowNodeContent,
  CallflowNodeHeader,
  CallflowNodeTitle,
  CallflowNodeOverline,
} from "../callflow-node/callflow-node";
import { DestinationNodeProps } from "../destination-node";
import styles from "./message-node.module.scss";
import { useDestinations } from "../../../api/use-destinations";
import { Dialog, DialogTitle } from "../../../design/dialog/dialog";
import { FormikHelpers } from "formik";
import { DialogStateReturn, useDialogState } from "reakit";
import { API } from "aws-amplify";
import { Button } from "../../../design/button";
import { useMe } from "../../../api/use-me";
import { Field } from "../../../design/form/field";
import { Input } from "../../../design/form/input/input";
import { AudioFileField } from "../../../design/form/audio-file-field/audio-file-field";
import { AudioFileInput } from "../../../design/form/audio-file-input/audio-file-input";
import { GeneralError } from "../../../design/general-error/general-error";

export function MessageNode(props: DestinationNodeProps) {
  const user = useMe();
  const destinations = useDestinations(user?.data);
  const destination = destinations.data ? destinations.data[props.destination] : null;
  const editDialog = useDialogState();

  return destination ? (
    <>
      <CallflowNode>
        <CallflowNodeHeader>
          <CallflowNodeOverline>הודעה</CallflowNodeOverline>
          <CallflowNodeTitle>{destination.name}</CallflowNodeTitle>
        </CallflowNodeHeader>
        <CallflowNodeContent>
          <div className={styles.message}>
            <audio
              key={destination.greetingLongRecordingId}
              src={destination.messageUrl}
              controls
            />
          </div>
        </CallflowNodeContent>
        <CallflowNodeActions>
          <Button variant='link' onClick={() => editDialog.show()}>
            עריכה
          </Button>
        </CallflowNodeActions>
      </CallflowNode>
      {editDialog.visible && <EditDialog dialog={editDialog} destination={destination.number} />}
    </>
  ) : null;
}

function EditDialog(props: EditMenuDialogProps) {
  const user = useMe();
  const destinations = useDestinations(user?.data);
  const destination = destinations.data ? destinations.data[props.destination] : null;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async (values: { name: string; file: File | null }) => {
    try {
      if (values.file) {
        setLoading(true);
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("file", values.file);

        await API.post(
          process.env.REACT_APP_WEB_API_NAME!,
          `/domains/${user?.data?.domain.id}/ivrs/${props.destination}/recording`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            body: formData,
          }
        );
        mixpanel.track("Change Message Recording", {
          Context: destination?.number,
        });
        await destinations.mutate();
        setLoading(false);
        props.dialog.hide();
      }
    } catch (error) {
      setError(true);
      setLoading(false);
    } finally {
    }
  };

  return (
    <Dialog
      {...props.dialog}
      variant='form'
      header={`העלאת הקלטה - ${destination?.name}`}
      hideOnClickOutside={false}
      hideOnEsc={true}
      onSubmit={handleSubmit}
      submitLabel='העלאה'
      initialValues={{ name: "", file: null }}
      loading={loading}
      aria-label='העלאת הקלטה'
    >
      <DialogTitle>העלאת הקלטה - {destination?.name}</DialogTitle>
      <Field name='name' label='שם ההקלטה'>
        <Input type='text' />
      </Field>
      <AudioFileField name='file'>
        <AudioFileInput
          label={"העלאת קובץ הקלטה חדש"}
          description={"ניתן להעלות קבצי אודיו עד 5MB"}
        />
      </AudioFileField>
      {!loading && error && <GeneralError error='לא הצלחנו להעלות את הקובץ' />}
    </Dialog>
  );
}

interface EditMenuDialogProps {
  dialog: DialogStateReturn;
  destination: string;
}
