import { useState, useEffect, useRef } from 'react';
import { parsePhoneNumber } from 'libphonenumber-js';
import { Remove } from '../../../../../design/icons/remove';
import { Edit } from '../../../../../design/icons/edit';
import { MoreHorizontalIcon } from '../../../../../design/icons/more-horizontal-icon';
import styles from './call-list-item.module.scss';
import { useDialogState } from 'reakit';
import { Checkbox } from '../../../../../design/checkbox/checkbox';
import { Button } from '../../../../../design/button';
import { FormattedDate } from '../../../../../utils/time/formatted-date';
import { Header } from './header';
import { RenderTags } from '../../../filter-tag/render-tags';
import { buttonTextStrings, getCallbackRequestStatusString } from '../../../../../utils/strings/string-translator';
import { DialerRequest } from '../../../../types';
import { CancelDialog } from '../../../call-list-dialog/cancel-dialog';
import { useURLParams } from '../../../../../app/use-url-params';
import { useParams } from 'react-router-dom';
import { getHebTimeDiff } from '../../../../../utils/time/time';
import { safeParsePhoneNumber } from '../../../../../utils/strings/parse-phone';

enum TagLabels {
  open = 1,
  completed,
  canceled,
  failed
}

/**
 * This function calculates which call status tag should be displayed as clicked
 * @param statusList An array of strings formatted as YYYY-MM-DD:TagLabel
 * @param date The date in question, also a string formatted as YYYY-MM-DD
 * @returns A number representing the index of the selected tag
 */
const getDateStatus = (statusList: string[], date: string) => {
  const relevantEntry = statusList.find(entry => {
    const indexOfColon = entry.indexOf(':');
    return entry.substring(0, indexOfColon) === date
  });

  if (!relevantEntry) {
    return 0;
  }
  const status = relevantEntry.substring(relevantEntry.indexOf(':') + 1);
  return TagLabels[status as keyof typeof TagLabels];
}

const DisplayButtons = ({ viewState, finishButtonHandler, cancelButtonHandler, editButtonHandler }:
  { viewState: boolean, finishButtonHandler: () => void, cancelButtonHandler: () => void, editButtonHandler: () => void }) => {
  if (viewState) {
    return (
      <span className={styles.editButton}>
        <Button
          variant="ghost"
          onClick={cancelButtonHandler}>
          {buttonTextStrings.get("cancel-dial-request")}
        </Button>
        <Button
          onClick={finishButtonHandler}
          variant="primary"
        >
          {buttonTextStrings.get("finish")}
        </Button>
      </span>
    )
  }
  return (
    <Button
      className={styles.editButton}
      onClick={editButtonHandler}
    >
      {buttonTextStrings.get("edit")}
    </Button>
  )
}

const EPSILON = 0.5;

export const CallListItem = (item: any) => {
  const params = useParams<any>()['*']?.replace(/^\/|\/$/g, '')!;
  const [options, setOptions] = useState(false);
  const [editState, setEditState] = useState(false);
  const [innerBox, setInnerBox] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [calls, setCalls] = useState<Array<DialerRequest>>(item.props.data);
  const [checked, setChecked] = useState<Set<string>>(new Set());
  const [isOnTop, setOnTop] = useState<boolean>(false);
  const stickyHeader = useRef<HTMLDivElement>(null!);
  const cancelDialog = useDialogState();
  const { urlParams } = useURLParams({
    path: 'callbacks',
    params
  });

  const defaultTag = urlParams.status_per_day ? getDateStatus(urlParams.status_per_day.split(','), item.props.key) : 0;
  const [clickedTag, setClickedTag] = useState(defaultTag);

  const onTagClick = (index: number) => {
    setClickedTag(index);
    item.onStatusSelection(item.props.key, TagLabels[index]);
  }

  const onScroll = () => {
    const rect = stickyHeader.current?.getBoundingClientRect();
    if (null !== rect) {
      if (Math.abs(rect?.top) < EPSILON) {
        setOnTop(true);
      } else {
        setOnTop(false);
      }
    }
  }

  const openList = () => {
    return (
      <ul className={styles.detailsList} >
        <li className={styles.detailsOpen}>פרטי בקשה <Edit size={24} className={styles.openIcon} /></li>
        <li className={styles.detailsDelete}>ביטול בקשת שיחה חוזרת <Remove size={24} className={styles.deleteIcon} /></li>
      </ul>
    );
  }

  useEffect(() => {
    setCalls(item?.props.data)
  }, [item?.props.data]);

  useEffect(() => {
    setInnerBox(editState)
  }, [editState]);

  useEffect(() => window.addEventListener('scroll', onScroll), []);

  if (calls) {

    const handleCancelReq = () => {

      // Dialer requests that their boxes are checked
      const candidates = calls.filter(request => checked.has(request.request_uuid));

      if (candidates.length > 0) {
        cancelDialog.show();
      }
    }

    const handleCancelConfirm = () => {
      item.onCancel();
      setChecked(new Set());
    }

    const handleCheckAll = (checked: boolean) => {
      setAllChecked(checked);
      const newCheckedState = new Set<string>();
      if (checked) {
        calls.forEach(request => {
          const requestOpen = request.request_status === "active" || request.request_status === "waiting";
          if (requestOpen) { newCheckedState.add(request.request_uuid); }
        });
      }
      setChecked(newCheckedState);
    }

    const handleOnChange = (key: string) => {
      // copy the original state and delete the request if it is present and add it if not
      const newCheckedState = new Set(checked);
      if (!newCheckedState.delete(key)) {
        newCheckedState.add(key);
      }
      setChecked(newCheckedState);

      // if this change made all of the boxes checked (or vice versa), change the allChecked state
      const newAllChecked = newCheckedState.size === calls.length;
      setAllChecked(newAllChecked);
    }

    return (
      <div>
        <div className={styles.stickyHeader} ref={stickyHeader} style={isOnTop ? { borderBottom: "1px solid #e1e6ec" } : {}}>
          <div>
            <span className={styles.headTitle}>
              <FormattedDate dateStr={item?.props?.key} />
            </span>
            <div className={styles.tags}>
              <RenderTags clickedTag={clickedTag} onTagClick={onTagClick} counters={item.props.counts} />
            </div>
          </div>
          <DisplayButtons viewState={editState} finishButtonHandler={() => setEditState(false)} cancelButtonHandler={handleCancelReq} editButtonHandler={() => setEditState(true)} />
        </div>
        <div className={styles.container}>
          <Header editState={editState} allChecked={allChecked} onChange={handleCheckAll} />
          {
            calls.map(call => {
              if (null === call.request_uuid) {
                return (
                  <div className={styles.emptyMessage} key={0}>
                    <div className={styles.title}>לא מצאנו בקשות בסטטוס המבוקש</div>
                    <div className={styles.content}>
                      שווה לוודא שיש בקשות בסטטוס זה ביום הנבחר
                    </div>
                  </div>
                )
              }
              const return_number: string = call.return_number ;
              const caller_number: string | undefined = call.caller_number;
              const group_name: string | undefined = call?.agent_group_name;
              const start_time = call?.start_date;
              const formatted_start_time = start_time ?? new Date();
              const end_time = call?.end_date;
              const formatted_end_time = end_time ?? new Date();
              const waitingTime = getHebTimeDiff(formatted_start_time, formatted_end_time);
              const requestOpen = call.request_status === "active" || call.request_status === "waiting";
              return (
                <div className={styles.root} key={call.request_uuid}>
                  <span className={editState && requestOpen ? styles.editSectionDisplay : styles.editSectionHidden} >
                    <Checkbox checked={checked.has(call.request_uuid)} onChange={() => handleOnChange(call.request_uuid)} />
                  </span>
                  <span className={styles.received}>
                    {formatted_start_time?.toLocaleTimeString("he-IL", { hour: "2-digit", minute: "2-digit" })}
                  </span>
                  <span className={styles.phoneBack}>
                    {return_number ? safeParsePhoneNumber(return_number, 'IL') : ''}
                  </span>
                  <span className={styles.status}>
                    {getCallbackRequestStatusString(call)}
                  </span>
                  <span className={styles.waiting}>
                    {waitingTime}
                  </span>
                  <span className={styles.source}>
                    {caller_number ? safeParsePhoneNumber(caller_number, 'IL') : ''}
                  </span >
                  <span className={styles.group}>
                    {group_name}
                  </span>
                  <span className={styles.details}>
                    {
                      item.openCard ?
                        (
                          <Button
                            variant="link"
                            className={styles.callDetailsButton}
                            onClick={() => {
                              if (item.openCard) {
                                item.openCard(call);
                              }
                            }}
                          >
                            <MoreHorizontalIcon size={24} className={styles.moreIcon} />
                          </Button>
                        )
                        :
                        ''
                    }
                    <span className={styles.optionMenu}>{options ? openList() : null}</span>
                  </span>
                </div>
              )
            })
          }

        </div>
        <CancelDialog
          dialog={cancelDialog}
          requests={calls.filter((request: DialerRequest, index: number) => checked.has(request.request_uuid))}
          onConfirm={handleCancelConfirm}
        />
      </div>
    )

  }
  return (
    <div>no results</div>
  )
}


