import { ButtonBaseProps } from '@mui/material';
import { ElementType, ReactNode, createElement, forwardRef } from 'react';

import { LoadingButtonProps } from '../../types/types';
import { StyledLoadingButton } from './StyledCustomButton';
import { ButtonVariant, Color, IconPosition, IconSize } from '../../../theme/muiTypes';

export interface CustomButtonProps extends ButtonBaseProps {
  loading?: boolean;
  children?: ReactNode;
  chipButton?: boolean;
  component?: ElementType;
  IconComponent?: ElementType;
  color?: Color;
  iconSize?: IconSize;
  iconPosition?: IconPosition;
  variant?: ButtonVariant;
}

// TODO: Check if the forward ref can be removed
export const CustomButton = forwardRef<HTMLInputElement, CustomButtonProps>(
  (
    {
      children,
      IconComponent,
      loading = false,
      color = Color.primary,
      chipButton = false,
      component = 'button',
      variant = ButtonVariant.contained,
      iconSize: size = IconSize.medium,
      iconPosition: loadingPosition = IconPosition.start,
      ...rest
    },
    ref
  ) => {
    const iconElement = IconComponent ? createElement(IconComponent) : null;
    let props: LoadingButtonProps = {
      size,
      color,
      variant,
      loading,
      component,
      chipButton,
      ...rest,
    };

    if (iconElement) {
      const isIconText = ButtonVariant.text ? true : false;
      if (loadingPosition === IconPosition.start) {
        props = {
          ...props,
          isIconText,
          loadingPosition,
          startIcon: iconElement,
        };
      } else if (loadingPosition === IconPosition.end) {
        props = {
          ...props,
          isIconText,
          loadingPosition,
          endIcon: iconElement,
        };
      } else {
        props = {
          ...props,
          isIconText,
          loadingPosition,
        };
      }
    }

    return <StyledLoadingButton {...props}>{children}</StyledLoadingButton>;
  }
);
