import { createContext, useState } from "react";
import { DialerRequest } from "../../api/dialers";


export const DialerRequestContext = createContext<[DialerRequest | undefined, React.Dispatch<React.SetStateAction<DialerRequest | undefined>>]>([
  undefined, () => undefined 
]);

export const DialerRequestContextProvider = ({ children }: { children: React.ReactNode }) => {
  const ctx = useState<DialerRequest | undefined>(undefined);
  return (
    <DialerRequestContext.Provider value={ctx}>
      {children}
    </DialerRequestContext.Provider>
  )
}