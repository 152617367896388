import { SelectOption } from '../formConfig/types';

export const getFilteredItems = (items: SelectOption[], selectedItems: SelectOption[]): SelectOption[] => {
  if (selectedItems.length === 0 || items.length === 0) {
    return items;
  }

  const filteredOptions = items.filter(
    (item) => !selectedItems.some((selectedItem) => item.value === selectedItem.value)
  );

  return filteredOptions;
};

export const getSearchableItems = (items: SelectOption[], searchTerm: string): SelectOption[] => {
  if (searchTerm === '') {
    return items;
  }
  const lowercaseSearchTerm = searchTerm.toLowerCase();
  const filteredOptions = items.filter((item) => {
    const itemLabel = String(item.label).toLocaleLowerCase();
    return itemLabel.includes(lowercaseSearchTerm);
  });

  return filteredOptions;
};
