import { v4 as uuidv4 } from 'uuid';
import { DialogStateReturn, useDialogState } from 'reakit';
import { useEffect, useCallback, useRef, useState } from 'react';
import ReactFlow, {
  Node,
  Edge,
  addEdge,
  MiniMap,
  Controls,
  Position,
  Connection,
  updateEdge,
  useEdgesState,
  useNodesState,
  ReactFlowProvider,
} from 'reactflow';

import 'reactflow/dist/style.css';
import { BotButtonBar } from './button-bar';
import { NodeData, Option } from '../utils/types';
import { AttachmentDialog } from '../dialogs/AttachmentDialog';
import { AttachmentType, MenuItem, WhatsAppBot } from '../utils/types';
import { ErrorDialog } from '../../design/dialog/error-dialog/error-dialog';
import {
  nodeTypes,
  edgeTypes,
  ROOT_NODE,
  ZOOM_FACTOR,
  edgeMarkerEnd,
  CONNECTION_RADIUS,
  connectionLineStyle,
} from '../utils/constants';

interface BotEditorProps {
  bot?: WhatsAppBot;
  botName: string;
  botId: string;
  requestTemplateDialog: DialogStateReturn;
  playButtonDialog: DialogStateReturn;
  refresh: () => Promise<WhatsAppBot | undefined>;
  onSave: () => void;
}

export const BotEditor = ({ bot, botName, botId, refresh, onSave, ...props }: BotEditorProps) => {
  const [selectedAttachmentType, setSelectedAttachmentType] = useState<AttachmentType | null>(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const attachmentDialog = useDialogState();
  const attachmentErrorDialog = useDialogState();
  const showAttachmentErrorDialog = useRef(false);
  const edgeConnecting = useRef(false);
  const edgeUpdateSuccessful = useRef(true);
  const showAttachmentDialogRef = useRef(false);

  const selectedNode = nodes.find((n) => n.selected);

  useEffect(() => {
    if (bot?.nodes?.length) {
      setNodes(
        bot.nodes.map((node: any) => ({
          ...node,
          data: {
            ...node.data,
            onRemoveOption: handleRemoveOption,
            onDuplicateNodeClick: handleDuplicateNode,
            onRemoveNodeClick: handleRemoveNode,
            onSelectAttachmentMenuItem: handleSelectAttachmentMenuItem,
          },
        }))
      );
      setEdges(
        bot.edges.map((edge: any) => ({
          ...edge,
          style: connectionLineStyle,
          markerEnd: edgeMarkerEnd,
          data: { ...edge.data, onRemoveOption: handleRemoveOption },
        }))
      );
    } else {
      setNodes([
        {
          ...ROOT_NODE,
          data: {
            ...ROOT_NODE.data,
            onRemoveOption: handleRemoveOption,
            onDuplicateNodeClick: handleDuplicateNode,
            onRemoveNodeClick: handleRemoveNode,
            onSelectAttachmentMenuItem: handleSelectAttachmentMenuItem,
          },
        },
      ]);
    }
  }, [bot]);

  const onConnect = useCallback((params: Edge<any> | Connection) => {
    setEdges((eds) => {
      if (eds.some((edge) => edge.sourceHandle === params.sourceHandle)) {
        return eds;
      }
      if (params.source === params.target) {
        return eds;
      }
      return addEdge(
        {
          ...params,
          id: `${params.sourceHandle}_${params.targetHandle}`,
          style: connectionLineStyle,
          type: 'customEdge',
          markerEnd: edgeMarkerEnd,
          data: { onRemoveOption: handleRemoveOption },
        },
        eds
      );
    });
  }, []);

  const onConnectEnd = (e: MouseEvent | TouchEvent) => {
    if (edgeConnecting.current) {
      edgeConnecting.current = false;
      setNodes((nds) =>
        nds.map((node) => {
          return {
            ...node,
            style: { backgroundColor: 'transparent' },
          };
        })
      );
    }
  };

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback((oldEdge: Edge<any>, newConnection: any) => {
    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  }, []);

  const onEdgeUpdateEnd = useCallback((_: any, edge: { id: string }) => {
    if (!edgeUpdateSuccessful.current) {
      // Remove edge
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    setEdges((eds) =>
      eds.map((edge) => ({
        ...edge,
        style: connectionLineStyle,
        markerEnd: edgeMarkerEnd,
        data: { ...edge.data, expanded: false, onRemoveOption: handleRemoveOption },
      }))
    );
    edgeUpdateSuccessful.current = true;
  }, []);

  const handleAddNode = (x: number, y: number) => {
    const id = uuidv4();
    setNodes((nodes) => [
      ...nodes,
      {
        id,
        type: 'customNode',
        position: { x, y },
        data: {
          onRemoveOption: handleRemoveOption,
          onDuplicateNodeClick: handleDuplicateNode,
          onRemoveNodeClick: handleRemoveNode,
          onSelectAttachmentMenuItem: handleSelectAttachmentMenuItem,
        },
        targetPosition: Position.Right,
      },
    ]);
  };

  const handleRemoveNode = (id: string) => {
    setNodes((nodes) => nodes.filter((n) => n.id !== id));
    setEdges((eds) => eds.filter((e) => e.id.split('_')[1] !== `${id}-input`));
  };

  const handleDuplicateNode = (nodeId: string) => {
    setNodes((nodes) => {
      const newNode = nodes.find((n) => n.id === nodeId);
      const lastXY = newNode?.['position'];
      return [
        ...nodes,
        {
          ...newNode,
          id: uuidv4(),
          data: {
            ...newNode?.data,
            options: newNode?.data?.options?.map((option: Option) => ({
              portId: uuidv4(),
              data: option.data,
              userInput: option.userInput,
            })),
          },
          position: { x: (lastXY?.x || 0) - 300, y: (lastXY?.y || 0) + 100 },
          selected: false,
        },
      ];
    });
  };

  const handleSelectAttachmentMenuItem = (menuItem: MenuItem) => {
    setSelectedAttachmentType(menuItem.value);
    showAttachmentDialogRef.current = true;
    attachmentDialog.show();
  };

  const handleCloseAttachmentDialog = () => {
    setSelectedAttachmentType(null);
    showAttachmentDialogRef.current = false;
    attachmentDialog.hide();
  };

  const handleCloseErrorDialog = () => {
    showAttachmentErrorDialog.current = false;
    attachmentErrorDialog.hide();
  };

  const handleSubmitAttachment = (updatedNode: Node<NodeData>, isError: boolean) => {
    if (isError) {
      showAttachmentErrorDialog.current = true;
      attachmentErrorDialog.show();
    } else {
      const updatedNodes = nodes.map((node) => (node.id === updatedNode.id ? updatedNode : node));
      setNodes(updatedNodes);
    }
    attachmentDialog.hide();
    showAttachmentDialogRef.current = false;
  };

  const handleRemoveOption = (id: string) => {
    setEdges((eds) => eds.filter((edge) => !edge.id.startsWith(id)));
  };

  const onEdgeMouseEnter = (event: React.MouseEvent, edge: Edge) => {
    setEdges((eds) =>
      eds.map((e) => {
        if (edge.id === e.id) {
          return {
            ...edge,
            style: { ...connectionLineStyle, stroke: '#0075DB' },
            markerEnd: { ...edgeMarkerEnd, color: '#0075DB' },
            data: { ...edge.data, expanded: true },
          };
        }
        return e;
      })
    );
  };

  const onEdgeMouseLeave = (event: React.MouseEvent, edge: Edge) => {
    setEdges((eds) =>
      eds.map((e) => {
        if (edge.id === e.id) {
          return {
            ...edge,
            style: connectionLineStyle,
            markerEnd: edgeMarkerEnd,
            data: { ...edge.data, expanded: false },
          };
        }
        return e;
      })
    );
  };

  return (
    <ReactFlowProvider>
      <BotButtonBar
        onAddNode={handleAddNode}
        playButtonDialog={props.playButtonDialog}
        requestTemplateDialog={props.requestTemplateDialog}
        botId={botId}
        botName={botName}
        botNumber={bot?.business_number}
        refresh={refresh}
        isNew={!bot}
        onSave={onSave}
      />
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onEdgeMouseEnter={onEdgeMouseEnter}
        onEdgeMouseLeave={onEdgeMouseLeave}
        onConnectStart={(e, n) => {
          edgeConnecting.current = true;
        }}
        onConnect={onConnect}
        onConnectEnd={onConnectEnd}
        onEdgeUpdate={onEdgeUpdate}
        onEdgeUpdateStart={onEdgeUpdateStart}
        onEdgeUpdateEnd={onEdgeUpdateEnd}
        style={{ background: '#F2F2F2', direction: 'ltr' }}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        connectionLineStyle={connectionLineStyle}
        connectionRadius={CONNECTION_RADIUS}
        fitViewOptions={{ maxZoom: ZOOM_FACTOR }}
        fitView
        proOptions={{ hideAttribution: true }}
        deleteKeyCode={null}
      >
        <Controls />
        <MiniMap nodeColor={'dark'} nodeBorderRadius={16} />
      </ReactFlow>
      {showAttachmentDialogRef.current && selectedNode && (
        <AttachmentDialog
          botId={botId}
          key={selectedNode.id}
          dialog={attachmentDialog}
          selectedNode={selectedNode as Node<NodeData>}
          selectedAttachmentType={selectedAttachmentType}
          onClose={handleCloseAttachmentDialog}
          onSubmit={handleSubmitAttachment}
        />
      )}
      {showAttachmentErrorDialog.current && selectedNode && (
        <ErrorDialog dialog={attachmentErrorDialog} onClose={handleCloseErrorDialog} />
      )}
    </ReactFlowProvider>
  );
};
