import { useEffect } from "react";

export default function useGenderFontFeature(gender?: Gender) {
  useEffect(() => {
    if(gender) {
      document.documentElement.setAttribute('style', `--gender: "${genderCodes[gender]}"`);
    } else {
      document.documentElement.setAttribute('style', '');
    }
  }, [gender])
}

type Gender = 'male'|'female';

const genderCodes = {
  male: 'ss12',
  female: 'ss11',
};
