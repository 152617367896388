import classNames from "classnames"
import { useState } from "react";
import { UserResource } from "../../api/types";
import { ArrowDropDown } from "../../design/icons/arrow-drop-down"
import { DomainSelection } from "../domain-selection";
import styles from "./domain-selection-mobile.module.scss"


export const DomainSelectionMobile = ({ user }: DomainSelectionProps) => {
  const [domainManagementVisible, setDomainManagementVisible] = useState(false);
  return (
    <div className={
      classNames(
        {[styles.expanded]: domainManagementVisible},
        styles.userAreaMobile)}>
      <div
      onClick={() => {setDomainManagementVisible(!domainManagementVisible)}}>
        <div className={styles.container}>
          {domainManagementVisible && <ArrowDropDown size={24} /> }
          {!domainManagementVisible && 
          <>
            <ArrowDropDown size={24} className={styles.expandedBadge} />
            <div className={styles.organization}> {user?.organization.name} </div>
            <div className={styles.userName}> {user?.name} </div>
          </>
          }
        </div>

        {domainManagementVisible && <DomainSelection user={user} />}
      </div>
    </div>
  )
}

interface DomainSelectionProps {
  user: UserResource | undefined
}
