import classNames from 'classnames';
import { useSetRecoilState } from 'recoil';
import { Link, LinkProps, NavLink, useLocation } from 'react-router-dom';
import { useState, useEffect, HTMLAttributes, RefAttributes } from 'react';

import { useMe } from '../../api/use-me';
import { Button } from '../../design/button';
import styles from './app-layout.module.scss';
import { BetaLogo } from '../../design/logo/logo';
import { NavClose, NavOpen } from '../../design/icons';
import { unreadCustomerUpdatesState } from '../../state';
import { desktopQuery, mobileQuery } from '../../theme/media';
import { PermissionGroup, UserResource } from '../../api/types';
import { DomainSelectionRouter } from '../../user-domain-management';

export function AppLayout({ children, className, sectionClassName, isFullScreen }: AppLayoutProps) {
  const { data: user } = useMe();
  const { pathname } = useLocation();
  const isMobileDown = useMediaQuery(mobileQuery);
  const isDesktopDown = useMediaQuery(desktopQuery);
  const setUnreadUpdates = useSetRecoilState(unreadCustomerUpdatesState);

  const [navigationVisible, setNavigationVisible] = useState<boolean>(!isDesktopDown);

  const userGroups = user?.groups;
  const isSupport = userGroups?.includes(PermissionGroup.Support);

  // Check which features are available for the user's current domain
  const domainFeaturesAvailable = user?.domain.config.domainFeatures;

  const hasNoFeatures =
    domainFeaturesAvailable !== undefined && Object.values(domainFeaturesAvailable).every((feature) => !feature);

  const isMerkaziaEnabled = hasNoFeatures ? true : domainFeaturesAvailable?.merkazia; // If no features - defaults to true
  const isCallbackDialerEnabled = hasNoFeatures ? true : domainFeaturesAvailable?.callbackDialer; // If no features - defaults to true

  const {
    monitor: isMonitorEnabled,
    emergencyDialer: isEmergencyDialerEnabled,
    whatsapp: isWhatsappEnabled,
    networkMonitor: isNetworkMonitorEnabled,
    digitalAgent: isDigitalAgentEnabled,
    billing: isBillingEnabled,
  } = domainFeaturesAvailable || {};

  useEffect(() => {
    setNavigationVisible(!isDesktopDown);
  }, [isDesktopDown, isMobileDown]);

  return (
    <div className={classNames(styles.appLayout, className)}>
      <div className={styles.navigationContainer}>
        <aside
          className={classNames(styles.navigation, {
            [styles.visible]: navigationVisible,
            [styles.hidden]: !navigationVisible,
          })}
        >
          <div>
            <BetaLogo title='iPracticom' className={styles.logo} />
          </div>
          <nav className={styles.menu}>
            {isMerkaziaEnabled && (
              <>
                <NavLink
                  to='/calls-history'
                  className={({ isActive }) => {
                    return classNames(styles.menuItem, { [styles.active]: isActive });
                  }}
                >
                  היסטוריית שיחות
                </NavLink>

                <NavLink
                  to='/calls-summary'
                  className={({ isActive }) => {
                    return classNames(styles.menuItem, { [styles.active]: isActive });
                  }}
                >
                  סיכום היסטוריית שיחות
                </NavLink>
              </>
            )}

            {isCallbackDialerEnabled && (
              <>
                <NavLink
                  to='/callback-dialer/history'
                  className={({ isActive }) => {
                    return classNames(styles.menuItem, { [styles.active]: isActive });
                  }}
                >
                  היסטוריית שיחות חוזרות
                </NavLink>
                <NavLink
                  to='/callback-dialer/dialers'
                  className={({ isActive }) => {
                    return classNames(styles.menuItem, { [styles.active]: isActive });
                  }}
                >
                  חייגני שיחה חוזרת
                </NavLink>
              </>
            )}

            {isMerkaziaEnabled && (
              <>
                <NavLink
                  to='/my-numbers'
                  className={({ isActive, isPending }) => {
                    return classNames(styles.menuItem, { [styles.active]: isActive });
                  }}
                >
                  המספרים שלי
                </NavLink>
                <NavLink
                  to='/extensions'
                  className={({ isActive, isPending }) => {
                    return classNames(styles.menuItem, { [styles.active]: isActive });
                  }}
                >
                  שלוחות
                </NavLink>
                <NavLink
                  to='/groups'
                  className={({ isActive, isPending }) => {
                    return classNames(styles.menuItem, { [styles.active]: isActive });
                  }}
                >
                  קבוצות
                </NavLink>
              </>
            )}

            {isDigitalAgentEnabled && (
              <NavLink
                to='/digital-agent'
                className={({ isActive }) => {
                  return classNames(styles.menuItem, { [styles.active]: isActive });
                }}
              >
                נציג דיגיטלי
              </NavLink>
            )}

            {isNetworkMonitorEnabled && (
              <NavLink
                to='/network-monitor'
                className={({ isActive, isPending }) => {
                  return classNames(styles.menuItem, { [styles.active]: isActive });
                }}
              >
                ניטור רשת
              </NavLink>
            )}

            {isMonitorEnabled && (
              <NavLink
                to='/monitor'
                className={({ isActive }) => {
                  return classNames(styles.menuItem, { [styles.active]: isActive });
                }}
              >
                מוניטור
              </NavLink>
            )}

            {isEmergencyDialerEnabled && (
              <>
                <NavLink
                  to='/emergency-dialer/events-log'
                  className={({ isActive }) => {
                    return classNames(styles.menuItem, {
                      [styles.active]: isActive || pathname.startsWith('/emergency-dialer/event'),
                    });
                  }}
                >
                  יומן אירועים
                </NavLink>
                <NavLink
                  to='/emergency-dialer/callees'
                  className={({ isActive }) => {
                    return classNames(styles.menuItem, { [styles.active]: isActive });
                  }}
                >
                  כוננים
                </NavLink>
                <NavLink
                  to='/emergency-dialer/launchers'
                  className={({ isActive }) => {
                    return classNames(styles.menuItem, { [styles.active]: isActive });
                  }}
                >
                  מפעילים
                </NavLink>
                <NavLink
                  to='/emergency-dialer/dialers'
                  className={({ isActive }) => {
                    return classNames(styles.menuItem, { [styles.active]: isActive });
                  }}
                >
                  קבוצות קריאה
                </NavLink>
              </>
            )}

            {isWhatsappEnabled && (
              <NavLink
                to='/whatsapp-bots'
                className={({ isActive }) => {
                  return classNames(styles.menuItem, { [styles.active]: isActive });
                }}
              >
                שירות WhatsApp
              </NavLink>
            )}

            {isBillingEnabled && (
              <NavLink
                to='/sms-billing'
                className={({ isActive }) => {
                  return classNames(styles.menuItem, { [styles.active]: isActive });
                }}
              >
                פירוט חיוב סמס
              </NavLink>
            )}

            {isSupport && (
              <>
                <Partition text='ניהול לקוחות'></Partition>
                <NavLink
                  to='/client-settings'
                  className={({ isActive }) => {
                    return classNames(styles.menuItem, { [styles.active]: isActive });
                  }}
                >
                  הגדרות לקוח
                </NavLink>

                <NavLink
                  to='/user-management'
                  className={({ isActive }) => {
                    return classNames(styles.menuItem, { [styles.active]: isActive });
                  }}
                >
                  ניהול משתמשים
                </NavLink>

                {isEmergencyDialerEnabled && (
                  <NavLink
                    to='/emergency-dialer/settings'
                    className={({ isActive }) => {
                      return classNames(styles.menuItem, { [styles.active]: isActive });
                    }}
                  >
                    הגדרות חייגנים
                  </NavLink>
                )}

                <NavLink
                  to='/invitations'
                  className={({ isActive }) => {
                    return classNames(styles.menuItem, { [styles.active]: isActive });
                  }}
                >
                  הזמנות
                </NavLink>
              </>
            )}
            {<BottomArea isCellularView={isMobileDown || false} user={user} setUnreadUpdates={setUnreadUpdates} />}
          </nav>
        </aside>
        <button
          type='button'
          onClick={() => setNavigationVisible((visible) => !visible)}
          className={classNames(styles.navigationButton, {
            [styles.navigationVisible]: navigationVisible,
          })}
        >
          {navigationVisible ? <NavClose size={24} /> : <NavOpen size={24} />}
        </button>
      </div>
      <section className={classNames(sectionClassName, styles.section, { [styles.fullScreen]: isFullScreen })}>
        <div className={classNames(sectionClassName, styles.sectionContent, { [styles.fullScreen]: isFullScreen })}>
          {children}
        </div>
      </section>
    </div>
  );
}

export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState<boolean>(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };

    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, [matches, query]);

  return matches;
}

export interface AppLayoutProps extends HTMLAttributes<HTMLDivElement> {
  isFullScreen?: boolean;
  sectionClassName?: string;
}

export function AppLayoutContainer({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return <div className={classNames(className, styles.container)} {...props} />;
}

export function AppLayoutHeader({ className, children, ...props }: HTMLAttributes<HTMLHeadElement>) {
  return (
    <header className={classNames(className, styles.header)} {...props}>
      {children}
    </header>
  );
}

const BottomArea = ({ isCellularView, user, setUnreadUpdates }: BottomAreaProps) => {
  return isCellularView ? ( // Mobile (Cellular) View
    <div style={{ width: '100%' }}>
      <div className={styles.partitionHorizontal}></div>
      <Button variant='link' className={styles.whatsNew} onClick={() => setUnreadUpdates(true)}>
        מה חדש
      </Button>

      <DomainSelectionRouter user={user} />
    </div>
  ) : (
    // Screen is larger than cellular (Tablet or Desktop)
    <section className={styles.bottomArea}>
      <DomainSelectionRouter user={user} />

      <Button variant='link' className={styles.whatsNew} onClick={() => setUnreadUpdates(true)}>
        מה חדש
      </Button>
    </section>
  );
};

export function AppLayoutTitle({ children, className, ...props }: HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h1 className={classNames(className, styles.title)} {...props}>
      {children}
    </h1>
  );
}

export function AppLayoutBreadcrumbs({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return <nav className={classNames(styles.breadcrumbs, className)} {...props} />;
}

export function Partition({ text }: PartitionProps) {
  return (
    <div className={styles.partition}>
      <span className={styles.partitionContent}>{text}</span>
    </div>
  );
}
interface PartitionProps {
  text: string;
}

interface BottomAreaProps {
  isCellularView: boolean;
  user: UserResource | undefined;
  setUnreadUpdates: (x: boolean) => void;
}

export function AppLayoutBreadcrumbLink({ className, ...props }: LinkProps & RefAttributes<HTMLAnchorElement>) {
  return (
    <span className={classNames(className, styles.breadcrumb)}>
      <Link {...props} />
    </span>
  );
}
