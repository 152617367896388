import * as yup from 'yup';
import { PermissionLevel } from '../../api/types';
import { ICallbackDialerForm } from '../../callbacks/types';
import { FileFormat, FORMAT_TYPE_MAPPING } from '../../globalTypes/types';
import { getOptionValueList } from '../../callbacks/utils/getOptionValueList';
import {
  FILE_TYPE,
  FILE_MODE,
  FIELDS_OPTIONS,
  CALLBACK_FORM_FIELDS_CONFIG,
} from '../../callbacks/formConfig/callbackDialerFormConfig';

// TODO: refactor the logic of getting the option value list.
const languageOptions = getOptionValueList(FIELDS_OPTIONS.languageOptions) as string[];
const speakerOptions = getOptionValueList(FIELDS_OPTIONS.speakerOptions) as string[];
const callbackLimitOptions = getOptionValueList(FIELDS_OPTIONS.callbackLimitOptions) as number[];
const maxTimeOptions = getOptionValueList(FIELDS_OPTIONS.maxTimeOptions) as number[];
const scaleOptions = getOptionValueList(FIELDS_OPTIONS.scaleOptions) as number[];
const approvalKeyOptions = getOptionValueList(FIELDS_OPTIONS.approvalKeyOptions) as string[];
const smsContentOptions = getOptionValueList(FIELDS_OPTIONS.smsContentOptions) as string[];
const reportTypeOptions = getOptionValueList(FIELDS_OPTIONS.reportTypeOptions) as string[];
const permissionLevelOptions = getOptionValueList(FIELDS_OPTIONS.permissionLevelOptions) as PermissionLevel[];
const businessNumberList = CALLBACK_FORM_FIELDS_CONFIG.business_number.options?.map((item) => item.value);

const phoneNumberRegex = /^(0[23489]|07)\d{7}|05\d{8}|1(599|700|800)\d{6}|19\d{8}|15(1|3)\d{9}$/ || '';
const onlyDigitsRegex = /^\d+$/;
const fileTypeList = Object.values(FILE_TYPE);
const fileModeList = Object.values(FILE_MODE);
const MAX_AUDIO_SIZE = 5242880;

const fileSchema = yup
  .mixed<File>()
  .nullable()
  .required()
  .test((value) => value && value.size <= MAX_AUDIO_SIZE)
  .test(
    'fileType',
    'File must be a valid audio file or null',
    (value) => value === null || value === undefined || (value instanceof File && value.type.startsWith('audio/'))
  );

const fileFormatKeys = Object.keys(FORMAT_TYPE_MAPPING) as FileFormat[];

const fileFieldSchema = yup.object({
  file: fileSchema,
  file_name: yup.string().required('file name not correct'),
  file_url: yup.string().required('file url not correct'),
  file_mode: yup.string().oneOf(fileModeList).required('file mode not correct'),
  file_type: yup.string().oneOf(fileTypeList).required('file type not correct'),
  file_format: yup.string().required('file not selected').oneOf(fileFormatKeys),
  supported_formats: yup.mixed<Array<FileFormat>>().required(),
});

const blacklistSchema = yup.object({
  blacklist_number: yup.string().matches(phoneNumberRegex, 'מספר טלפון אינו תקין'),
});

const incomingCallPermitterSchema = yup.object({
  incoming_call_number: yup.string().matches(phoneNumberRegex, 'מספר טלפון אינו תקין').required(),
});

const smsReportNumbersSchema = yup.object({
  sms_report_destination_number: yup.string().matches(phoneNumberRegex, 'מספר טלפון אינו תקין').required(),
});

const emailSchema = yup.object({
  mail_report_destination_address: yup.string().email('המייל לא תקין').required(),
});

const businessNumberForSmsSchema = yup
  .string()
  .required('זיהוי מספר לסמס הינו שדה חובה')
  .test('validate-business-number-for-sms', 'הערך שהזנת אינו תקין', (value: string) => {
    if (!value.trim()) {
      return false;
    }

    if (onlyDigitsRegex.test(value)) {
      return businessNumberList?.includes(value);
    }

    return /[a-zA-Z]/.test(value);
  });

export const callbackValidationSchema: yup.ObjectSchema<ICallbackDialerForm> = yup
  .object({
    selectedPermission: yup.number().oneOf(permissionLevelOptions).required(),
    dialer_id: yup.number().required(),
    enabled: yup.boolean().required(),
    language: yup.string().oneOf(languageOptions).required(),
    speaker: yup.string().oneOf(speakerOptions).required(),
    calls_recorded: yup.boolean().required(),
    international_calls_permitted: yup.boolean().required(),
    blacklist: yup.array().of(blacklistSchema),
    priority: yup.number().oneOf(scaleOptions).required(),
    max_active: yup.number().oneOf(callbackLimitOptions).required(),
    max_request_age: yup.number().oneOf(maxTimeOptions).required(),
    business_number: yup.string().required(),
    business_number_for_sms: businessNumberForSmsSchema,
    active_now: yup.boolean().required(),
    agent_max_attempts: yup.number().required(),
    agent_dial_interval: yup.number().required(),
    agent_max_ringing: yup.number().required(),
    agent_approval_key: yup.string().oneOf(approvalKeyOptions),
    agent_greeting: fileFieldSchema,
    agent_abort_call_key: yup.string(),
    agent_complete_call_key: yup.string(),
    agent_continuous_call: yup.boolean().required(),
    agent_play_cust_number: yup.boolean().required(),
    agent_play_num_requests: yup.boolean().required(),
    incoming_call_permitted: yup.array().of(incomingCallPermitterSchema),
    cust_dial_prefix: yup.string(),
    cust_max_attempts: yup.number().oneOf(scaleOptions).required(),
    cust_dial_interval: yup.number().required(),
    cust_max_ringing: yup.number().required(),
    cust_min_duration: yup.number().required(),
    cust_approval_key: yup.string().oneOf(approvalKeyOptions),
    cust_prompt: fileFieldSchema,
    sms_content_type: yup.string().oneOf(smsContentOptions).required(),
    sms_text_at_start: yup.string(),
    sms_text_at_failure: yup.string(),
    sms_text_between_attempts: yup.string(),
    create_request_on_abandon: yup.boolean().required(),
    min_time_for_abandon: yup.number(),
    min_time_for_answer: yup.number(),
    delete_request_on_answer: yup.boolean().required(),
    allow_change_mobile_number: yup.boolean().required(),
    allow_change_landline_number: yup.boolean().required(),
    approve_request_created: yup.boolean().required(),
    cust_request_created: fileFieldSchema,
    report_type: yup.string().oneOf(reportTypeOptions).required(),
    sms_report_sender_name: yup.string(),
    mail_report_sender_name: yup.string(),
    sms_report_text: yup.string(),
    sms_report_destination_numbers: yup.array().of(smsReportNumbersSchema),
    mail_report_title: yup.string(),
    mail_report_text: yup.string(),
    mail_report_destination_addresses: yup.array().of(emailSchema),
  })
  .required();
