import { ActionType, DumpMessage, Message, PingMessage, PongMessage } from '../interfaces';
import { getIpracticomAccessToken, secureFetch } from '../../auth/server-auth';
import publisher, { EventType } from './pub-sub/event-bus';

const RELOAD_THRESHOLD = 1;
const KEEP_ALIVE_INTERVAL_SEC = 15;
const WS_URL = process.env.REACT_APP_CCM_SERVER_WS_URL!;
const LOCAL_STORAGE_RELOAD_COUNT = 'reloadCount';
const LOCAL_STORAGE_RELOAD_TIME = 'reloadTime';
const RELOAD_DELAY_AFTER_FAILURE_MS = 15 * 1000;

type uuid = string;
export type WsArgs = { domainUuid: uuid; userUuid: uuid; groupUuids: uuid[] };

const niceDate = () => new Date().toISOString() + ' Websocket';

const reloadCount = Number(localStorage.getItem(LOCAL_STORAGE_RELOAD_COUNT) ?? 0); // failing reload count since last success
const reloadTime = Number(localStorage.getItem(LOCAL_STORAGE_RELOAD_TIME) ?? 0); // ms epoch time of reload attempt

const WS_CLOSE_CODES = {
  1000: 'normal',
  1001: 'going away',
  1002: 'protocol error',
  1003: 'received bad message',
  1005: 'no specific status',
  1006: 'closed abnormaly',
  1007: 'inonsistent message',
  1008: 'mesageviolated policy',
  1009: 'message too big',
  1010: "server doesn't support extension",
  1011: 'server encountered unexpected condition',
  1015: 'tls failure',
};

export class IPracticomWebSocket {
  private readonly args: WsArgs;

  private token: string | undefined;
  private socket: WebSocket | undefined;
  private lastPingTime: number;
  private lastPongTime: number;
  private insideKeepAlive: boolean;
  private keepAlivePromise: Promise<void> | undefined;

  private initializing = false;
  private initializingSocket = false;
  private terminating = false;
  private terminateAfterInitialization: boolean = false;

  // constructor
  public constructor(args: WsArgs) {
    this.args = args;
    this.lastPingTime = Date.now();
    this.lastPongTime = Date.now();
    this.insideKeepAlive = false;
  }

  // initialize. called once for every web-socket instance
  public async initialize(): Promise<boolean> {
    const method = 'initialize:';

    // console.debug(niceDate(), method, `Started`);

    if (this.initializingSocket || this.terminating) {
      console.error(niceDate(), method, `Trying to run while another action is running, doing nothing`);
      return false;
    }

    this.initializing = true;

    try {
      // launch periodic keepALive
      if (this.keepAlivePromise) {
        console.error(niceDate(), method, `Trying to start periodic keep alive while already started`);
      } else {
        this.keepAlivePromise = this.periodicKeepAlive(); // periodicKeepAlive runs forever
      }

      // initialize socket
      const ok = await this.initializeSocket('create');
      if (ok) {
        console.debug(niceDate(), method, `Ended ${ok === true ? 'successfully' : 'badly'}`)
      }
      else {
        console.error(niceDate(), method, `Failed to establish WebSocket connection`);
      }
      return ok;
    } finally {
      if (this.terminateAfterInitialization) {
        // Someone tried to call terminate() before initialize() was done
        this.initializing = false;
        this.terminate('terminate was called during initialize() -> so initialize() calls terminate() when finished');
        this.terminateAfterInitialization = false;
      }
      // turn off `initializing` flag either way
      this.initializing = false;
    }
  }

  // initializeSocket. called at start and also after disconnect
  private async initializeSocket(reason: string): Promise<boolean> {
    const method = 'initializeSocket:';

    // console.debugug(niceDate(), method, `Invoked, reason: ${reason}`);

    if (this.initializingSocket || this.terminating) {
      console.error(niceDate(), method, `Trying to run while another action is running, doing nothing`);
      return false;
    }

    this.initializingSocket = true;

    try {
      const { domainUuid, userUuid } = this.args;

      this.lastPingTime = Date.now();
      this.lastPongTime = Date.now();

      // a promise resolved after connection is established
      return await new Promise<boolean>(async (resolve) => {
        // get token from ccm
        this.token = await this.registerInCcm();

        if (!this.token) {
          // error already logged above
          resolve(false);
          return;
        }

        // create websocket
        this.socket = new WebSocket(WS_URL, ['IPC', domainUuid, userUuid, this.token]);

        // onopen
        this.socket.onopen = async (event) => {
          // ask ccm to do dump data
          const url = `${process.env
            .REACT_APP_CCM_SERVER_API_URL!}/registry/dom/${domainUuid}/user/${userUuid}?action=dump`;
          const { res } = await secureFetch(url);
          this.reloadIfError(method, res.status);

          // we're connected
          // console.debug(niceDate(), method, `Connected to server`);

          // send initial ping
          this.sendPing();
          resolve(true);
        };

        // ommessage: one of dump, pong, object
        this.socket.onmessage = (event) => {
          const method = 'socket.onmessage:';

          try {
            const message = JSON.parse(event.data) as Message;

            switch (message.action) {
              case 'dump':
                publisher.onDump(message as DumpMessage);
                break;

              case 'pong':
                this.lastPongTime = (message as PongMessage).data?.time || 0;
                break;
              
              default:
                // Publish event for specific object type (EventType should be renamed to ObjectType or something similar)
                publisher.emit(message.objectType as EventType, message);
                break;
            }
          } catch (error: any) {
            console.error(niceDate(), method, 'ws.onmessage:', error.message || error);
          }
        };

        // onclose
        this.socket.onclose = (ev: CloseEvent) => {
          const method = 'socket.onclose:';

          if (ev.code !== 1005) {
            // 1005 is usually reult of us closing the socket when changing group selection
            const msg = `Socket closed, code: ${ev.code} ${WS_CLOSE_CODES[ev.code as keyof typeof WS_CLOSE_CODES] || ''}`;
            console.error(niceDate(), method, msg);
          }

          this.socket = undefined;

          try {
            this.reloadIfError(method, -1);
          } catch (error) {
            // error was printed in the called function
          }
        };

        // onerror
        this.socket.onerror = (error) => {
          console.error(niceDate(), method, 'ws.onerror:', error);
        };
      });
    } finally {
      this.initializingSocket = false;
    }
  }

  // terminate
  public async terminate(reason: string): Promise<boolean> {
    const method = 'terminate:';
    // console.debugug(niceDate(), method, `Started, reason: ${reason}`);

    if (this.terminating) {
      console.error(niceDate(), method, `Trying to run terminate() while another action is running, doing nothing.`);
      return false;
    }
    
    if (this.initializing || this.initializingSocket) {
      console.error(niceDate(), method, `Trying to terminate web-socket during initialization.`);
      this.terminateAfterInitialization = true;
      return false;
    }

    this.terminating = true;

    try {
      try {
        await this.keepAlivePromise;
      } catch {
        console.warn(niceDate(), `${method} unexpectedly cought an exception while trying to resolve a promise`);
      }

      try {
        this.closeSocket();
      } catch {
        console.warn(niceDate(), `${method} unexpectedly cought an exception while trying to close web-socket`);
      }
    } finally {
      this.terminating = false;
      this.keepAlivePromise = undefined;
    }

    console.debug(niceDate(), method, 'Ended');
    return true;
  }

  // registerInCcm
  private async registerInCcm(): Promise<string | undefined> {
    const method = 'registerInCcm:';

    const { domainUuid, userUuid, groupUuids } = this.args;
    const gatekeeperToken = await getIpracticomAccessToken();

    try {
      // console.debugug(niceDate(), `${method}:`, groupUuids);
      const url = `${process.env.REACT_APP_CCM_SERVER_API_URL!}/registry/dom/${domainUuid}/user/${userUuid}`;
      const options = {
        method: 'POST',
        headers: {
          'x-token': gatekeeperToken || 'no_token',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ groupUuids }),
      };

      const res = await fetch(url, options);
      this.reloadIfError(method, res.status);
      const resObject = await res.json();

      const token = resObject?.data;
      return token;
    } catch (error: any) {
      console.error(niceDate(), method, 'registerInCcm:', error.message || error);
      return undefined;
    }
  }

  // periodicKeepAlive: invoke keepAlive periodically
  private async periodicKeepAlive() {
    const method = 'periodicKeepAlive:';

    // console.debugug(niceDate(), method, `Started`);

    while (true) {
      // sleep KEEP_ALIVE_INTERVAL_SEC seconds
      for (let i = 0; i < KEEP_ALIVE_INTERVAL_SEC; i++) {
        // sleep in 1-sec loop so can honor terminate request
        if (this.terminating) {
          break;
        }
        await new Promise<void>((resolve) => setTimeout(() => resolve(), 1000)); // 1000 = 1 sec
      }

      if (this.terminating) {
        break;
      }

      // assert
      if (this.insideKeepAlive) {
        console.warn(niceDate(), method, 'Already inside keepAlive, weird');
        continue;
      }

      // invoke keepAlive
      try {
        this.insideKeepAlive = true;
        await this.keepAlive();
      } catch (error: any) {
        console.error(niceDate(), method, 'keepAlive error:', error.message || error);
      } finally {
        this.insideKeepAlive = false;
      }
    }

    // console.debug(niceDate(), method, `Ended`);
  }

  // keepAlive: send ping, ensure got pong, if not then close & re-initialize the websocket
  private async keepAlive(): Promise<void> {
    const method = 'keepAlive:';

    // send ping
    this.sendPing();

    // check pong, if good then return
    const lastPongAgeSec = (this.lastPongTime - this.lastPingTime) / 1000;
    const maxAllowedAgeSec = 10;

    if (this.socket && lastPongAgeSec <= maxAllowedAgeSec) {
      return;
    }

    // NO GOOD PONG or NO SOCKET, handle disconnection
    if (this.socket) {
      const lastPingTime = new Date(this.lastPongTime).toISOString();
      const lastPongTime = new Date(this.lastPongTime).toISOString();
      const message = `Closing socket because didn't get PONG on time, last PING: ${lastPingTime}, last PONG: ${lastPongTime}`;
      console.error(niceDate(), method, message);
      this.closeSocket();
    } else {
      // console.debugug(niceDate(), method, `WebSocket is closed`);
    }

    // socket is closed, wait a sec and re-initialize
    await new Promise<void>((resolve) => setTimeout(() => resolve(), 1 * 1000));

    // console.debugug(niceDate(), method, 'Invoking initializeSocket()');
    await this.initializeSocket('keepAlive');
  }

  // sendPing
  private sendPing(): void {
    //const method = "sendPing:";

    if (!this.socket || this.socket?.readyState !== WebSocket.OPEN) return;

    const ping: PingMessage = { action: ActionType.PING, objectType: undefined, data: { time: this.lastPingTime } };
    this.lastPingTime = Date.now();
    this.socket.send(JSON.stringify(ping));
  }

  // closeSocket
  private closeSocket(): void {
    const method = 'closeSocket:';

    if (this.socket) {
      switch (this.socket.readyState) {
        case WebSocket.CLOSED:
        case WebSocket.CLOSING:
          if (1 < 0) {
            const msg = `Not closing websocket because it's already closed or closing, state: ${this.socket.readyState}`;
            console.debug(niceDate(), method, msg);
          }
          break;

        case WebSocket.CONNECTING:
        case WebSocket.OPEN:
          // console.debug(niceDate(), method, `Closing websocket, state (before closing): ${this.socket.readyState}`);
          this.socket.close();
          break;
      }
    }
  }

  // reloadIfError
  private reloadIfError(method: string, status: number) {
    // upon receiving error from CCM service or websocket, reload the app
    // mainly for error 401 (token has expired), works as well for any error

    const statusText = status === -1 ? 'socket_closed' : `http_${status}`;

    switch (status) {
      case 200:
        localStorage.setItem(LOCAL_STORAGE_RELOAD_COUNT, String('0'));
        localStorage.setItem(LOCAL_STORAGE_RELOAD_TIME, String('0'));
        break;

      case -1: // websocket closed
        break;
      case 401:
        const now = Date.now();
        const nextReloadTime = reloadTime + RELOAD_DELAY_AFTER_FAILURE_MS;

        localStorage.setItem(LOCAL_STORAGE_RELOAD_TIME, String(now));

        // either (1) not reached yet reload threshold, or (2) reload delay ended
        if (reloadCount < RELOAD_THRESHOLD || nextReloadTime < now) {
          console.warn(niceDate(), method, `Error ${statusText}, reloading window, attempt ${reloadCount}`);
          localStorage.setItem(LOCAL_STORAGE_RELOAD_COUNT, String(reloadCount + 1));
          window.location.reload();
        }

        // reached reload threshold and within reload delay, will do nothing until end of reload delay
        const msg1 = `Error ${statusText}, will attempt reload in ${RELOAD_DELAY_AFTER_FAILURE_MS / 1000} seconds`;
        console.error(niceDate(), method, msg1);
        throw Error(msg1);

      default:
        const msg2 = `Error ${statusText}, will attempt reload in ${RELOAD_DELAY_AFTER_FAILURE_MS / 1000} seconds`;
        console.error(niceDate(), method, msg2);
        throw Error(msg2);
    }
  }
}
