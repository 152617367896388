// monitor-agent interfaces and functions

type uuid = string;

export enum AgentOutboundState {
  OutboundRinging = "Outbound Ringing",
  OutboundTalking = "In an Outbound Call",
}

// cooked agent status as prepared by getStatus (function) in Monitor.tsx
// used only in STATUSES_FOR_PIE_CHARS 
export enum StatusType {
  Available       = "Available",
  Busy            = "Busy",
  BusyOutbound    = "BusyOutbound",
  Receiving       = "Receiving",  // incoming call is ringing
  Break           = "Break",
  Mission         = "Mission",
};

// hebrew translation of StatusType
export enum HebStatusType {
  Available       = "בזמינות",
  Busy            = "בשיחה",
  BusyOutbound    = "בשיחה יוצאת",
  Receiving       = "מצלצל",
  Break           = "בהפסקה",
  Mission         = "במשימה",
};

// native AgentStatus as arrives from the server
export enum AgentStatus {
  LoggedOut         = "Logged Out",             // Cannot receive queue calls
  Available         = "Available",              // Ready to receive queue calls
  AvailableOnDemand = "Available (On Demand)",  // State will be set to 'Idle' once the call ends (not automatically set to 'Waiting')
  OnBreak           = "On Break",               // Still Logged in, but will not receive queue calls
  Unknown           = "Unknown"                 // no info yet
};

// native AgentState as arrives from the server
export enum AgentState {
  Idle              = "Idle",                   // Does nothing, no calls are given
  Waiting           = "Waiting",                // Ready to receive calls
  Receiving         = "Receiving",              // A queue call is currently being offered to the agent
  InQueueCall       = "In a queue call",        // Currently on a queue call
  Unknown           = "Unknown"                 // no info yet
}

interface dao_ModelObject {
  id: string;
  createDate?: Date;
}

interface cti_DItem extends dao_ModelObject {
  // nothing here
}

interface cti_DItemCti extends cti_DItem {
  domainUuid: uuid;
  number: string;
  name?: string | undefined;
  description?: string | undefined;
}

export interface cti_DAgent extends cti_DItemCti {
  // agent
  contact: string;
  contactRaw: string;   // the extension (or other number) the agent sits on
  agentDefaultStatus: AgentStatus | "NO_DEFAULT";
  agentStatus: AgentStatus;
  agentState: AgentState;
  breakType?: string | undefined;   // as passed from dialplan variable BREAK_TYPE when changing status using *43 etc.
  // uuids
  queueUuids: uuid[];
  extensionUuid?: uuid | undefined;
  // per call
  callUuid?: uuid | undefined;      // populate by agent events
}

export interface MonitorAgent extends cti_DAgent {
  // count call answered
  inboundAnswered: number;
  outboundAnswered: number;
  localAnswered: number;  // not used
  // count calls unanswered
  inboundUnanswered: number;
  outboundUnanswered: number;
  localUnanswered: number;  // not used
  // duration
  totalTalkDuration: number;
  totalRingDuration: number;
  // status
  statusStartTime: Date;
  // outbound
  isOutboundCall?: boolean | undefined;  // set false as call's default, set true when deciding it's an outbound call, set undefined after call-end calculations are donw
  agentOutboundState?: AgentOutboundState | undefined;
  outboundCallUuid?: uuid | undefined;
  // last call data, built by Agent events (rather than Call events)
  lastCallReceivingTime?: Date | undefined;
  lastCallAnswerTime?: Date | undefined;
  lastCallEndTime?: Date | undefined;
}

export interface AgentAttributes extends MonitorAgent {
  // nothing here, this interface is for naming compatability with previous version of the Agent interface
}

/*
// this is the agent before Ziv's changes of mid May 2024.

export interface AgentAttributes {
  id: string;
  domainUuid: uuid;
  name?: string | undefined;
  number: string;
  description?: string | undefined;
  createTime: Date;

  contact: string;
  contactRaw: string;                 // the extension (or other number) the agent sits on
  agentDefaultStatus: AgentStatus | "NO_DEFAULT";
  agentStatus: AgentStatus;
  agentState: AgentState;
  breakType?: string | undefined;
  // outgoing
  outboundConnectionUuids: uuid[];
  outboundCallUuids: uuid[];
  // uuids
  callUuid?: uuid | undefined;
  queueUuids: uuid[];
  extensionUuid?: uuid | undefined;
  //inbound
  inboundAnswered: number;
  inboundUnanswered: number;
  //outbound
  outboundAnswered: number;
  outboundUnanswered: number;
  // time
  totalCallsDuration: number;
  totalRingDuration: number;
  currStatusStartTime: number;
  // last call data - these fields address the call when it comes to the agent and not the call itself
  lastCallUuid?: string | undefined;
  lastCallReceiveTime?: Date | undefined;
  lastCallAnswerTime?: Date | undefined;
  lastCallEndTime?: Date | undefined;
}
*/
