import classNames from 'classnames';
import React, { HTMLAttributes } from 'react';
import styles from './tag.module.scss';

export interface TagProps extends HTMLAttributes<HTMLSpanElement> {
  variant?: TagVariantType;
  color?: TagColor;
  label: string;
}

export type TagVariantType = 'filled' | 'outlined';
export type TagColor = 'accent' | 'success' | 'warning' | 'caution' | 'neutral';

export const Tag = ({
  label,
  color = 'neutral',
  variant = 'outlined',
  children,
  className,
  ...props
}: TagProps) => {
  const finalClassName = classNames(
    styles.root,
    styles[variant],
    styles[color],
    className
  );

  return (
    <span {...props} className={finalClassName}>
      {label}
      {children}
    </span>
  );
};
