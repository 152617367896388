import React from 'react';
import { Spinner } from '../../design/spinner/spinner';
import { AppLayoutContainer, AppLayoutHeader, AppLayout, AppLayoutTitle } from '../../layouts/app-layout/app-layout';
import { Page } from '../../page';
import { useBusinessNumberCollection } from '../../api/use-business-number';
import { NumberList } from './number-list/number-list';
import styles from './number-list-page.module.scss';
import { useMe } from '../../api/use-me';

export function NumberListPage() {
  const user = useMe();
  const businessNumberCollection = useBusinessNumberCollection(true);
  const loading = user?.loading || businessNumberCollection?.loading;
  const numberList = businessNumberCollection?.data && businessNumberCollection?.data
    .filter(businessNumber => user?.data?.domain.config.selfManagedNumbers.includes(businessNumber.id));


  if (loading) {
    return (
      <Page name="My Numbers">
        <AppLayout>
          <AppLayoutContainer>
            <AppLayoutHeader>
              <AppLayoutTitle>המספרים שלי</AppLayoutTitle>
            </AppLayoutHeader>
            <Spinner />
          </AppLayoutContainer>
        </AppLayout>
      </Page>
    )
  }

  if (!numberList || numberList.length === 0) {
    return (
      <Page name="My Numbers">
        <AppLayout>
          <AppLayoutContainer>
            <AppLayoutHeader>
              <AppLayoutTitle>המספרים שלי</AppLayoutTitle>
            </AppLayoutHeader>
            <div className={styles.content}>
              בעמוד הזה את/ה יכול/ה לנהל את המענה הטלפוני במספרים של העסק שלך, למשל:
              <ul>
                <li>התאמת שעות הפעילות לחגים</li>
                <li>פתיחה וסגירה ידנית של המענה הטלפוני</li>
                <li>העברת כל השיחות הנכנסות למספר אחר</li>
              </ul>
            </div>
            <p className={styles.content}>
            כדי להפעיל את היכולת לנהל בעצמך את מספרי הטלפון שלך, פנה/י לתמיכה: <a href="mailto:support@ip-com.co.il">support@ip-com.co.il</a>
            </p>
          </AppLayoutContainer>
        </AppLayout>
      </Page>
    )
  }



  return (
    <Page name="My Numbers">
      <AppLayout>
        <AppLayoutContainer>
          <AppLayoutHeader>
            <AppLayoutTitle>המספרים שלי</AppLayoutTitle>
          </AppLayoutHeader>
          {numberList && <NumberList numbers={numberList} />}
        </AppLayoutContainer>
      </AppLayout>
    </Page>
  )
};