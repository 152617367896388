import { API } from 'aws-amplify';
import { parsePhoneNumber } from 'libphonenumber-js';
import mixpanel from 'mixpanel-browser';
import { useState } from 'react';
import { Dialog } from '../../design/dialog/dialog';
import { useBusinessNumberResource } from '../../api/use-business-number';
import { useMe } from '../../api/use-me';
import { safeParsePhoneNumber } from '../../utils/strings/parse-phone';

export function ClosedModeDialog(props: any) {
  const user = useMe();
  const number = useBusinessNumberResource(props.number);
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    try {
      setLoading(true);
      const body = {
        destinationNumber: user?.data?.domain.config.offDestination
      };
      await API.put(process.env.REACT_APP_WEB_API_NAME!, `/domains/${user?.data?.domain.id}/business-numbers/${number.data?.number}/root-destination`, { body });
      mixpanel.track('Toggle Answering', {
        'Receive Calls?': false
      })
      await number.mutate();
      props.dialog.hide();
    } catch (error) {

    } finally {
      setLoading(false);
    }

  }

  return (
    <Dialog {...props.dialog}
      variant="caution"
      header={
        <div>
          סגירת מענה
          <span>{' '}{number.data &&
            `(${safeParsePhoneNumber(number.data.number, 'IL')})`}
          </span>
        </div>
      }
      onSubmit={handleConfirm}
      submitLabel="אישור וסגירת מענה"
      loading={loading}
      aria-label="סגירת מענה">

      <p>
        סגירת המענה תיכנס לתוקף באופן מיידי. לקוחות שיתקשרו למספר ישמעו את הודעת
        הסגור שמוגדרת. ניתן לפתוח את המענה מחדש בכל רגע.
      </p>
      <p style={{ fontWeight: "bold" }}>האם את/ה בטוח/ה שברצונך לסגור את המענה?</p>
    </Dialog>
  );
}
