import { memo } from 'react';
import classNames from 'classnames';
import { useRecoilValue } from 'recoil';

import { SelectedButton } from '../../icons';
import styles from './tables-container.module.scss';
import { hadshanutUserState } from '../../../../state';
import { CallsTable } from '../calls-table/calls-table';
import { AgentsList } from '../agents-table/agents-list';
import { waitingCallsFilter } from '../../../utils/callFilters';
import ButtonBar from '../../../../design/button-bar/button-bar';
import { MonitorConfiguration } from '../../../use-monitor-config';
import { GroupResource, PermissionGroup } from '../../../../api/types';
import { InboundCallsList } from '../inbound-calls-table/inbound-calls-list';
import { WaitingCallsList } from '../waiting-calls-table/waiting-calls-list';
import { usePersistentStorageValue } from '../../../../hooks/useLocalStorage';
import { OutboundCallsList } from '../outbound-calls-table/outbound-calls-list';
import { UnansweredCallsList } from '../unanswered-table/unanswered-calls-list';
import { DialerRequestsList } from '../callback-requests-table/callback-requests-list';
import { createUnansweredCallsList } from '../unanswered-table/unanswered-calls-list-data';
import { AgentAttributes, BreakType, CallAttributes, DialerRequestAttributes } from '../../../interfaces';

export const TablesContainer = memo(
  ({
    calls,
    agents,
    groups,
    breakTypes,
    monitorConfig,
    dialerRequests,
    selectedGroups,
    onTabClick,
  }: TablesContainerProps) => {
    const user = useRecoilValue(hadshanutUserState);
    const { value: activeTab, setValue: setActiveTabInStorage } = usePersistentStorageValue<TabTitle | 'main'>(
      'active-tab',
      'agents',
      'monitor'
    );

    const selfManagedGroups = groups.filter((group) => user?.domain.config.selfManagedGroups.includes(group.id));
    const unansweredCallers = createUnansweredCallsList(calls, monitorConfig, selfManagedGroups); // it counts group & caller entries despite of the anachronistic name
    const waitingCalls = calls.filter(waitingCallsFilter);
    const inboundCalls = calls.filter((call) => call.callDirection === 'inbound');
    const outboundCalls = calls.filter((call) => call.callDirection === 'outbound');

    // 'tableName' is a property responsible for saving the user's sort configuration of tabs in the monitor page (The table component responsible for saving config in local storage).
    // If this property isn't provided this sort config won't be saved for the next monitor page visit.
    const tabs: TabButton[] = [
      {
        id: 'agents',
        label: 'סוכנים',
        count: agents.length,
        tableComponent: (
          <AgentsList
            groups={groups}
            agents={agents}
            calls={calls}
            selectedGroups={selectedGroups}
            breakTypes={breakTypes}
            tableName='monitor-agents'
          />
        ),
      },
      {
        id: 'unanswered-calls',
        label: 'מתקשרים שלא נענו',
        count: unansweredCallers.length,
        tableComponent: (
          <UnansweredCallsList
            calls={calls}
            dialerRequests={dialerRequests}
            monitorConfig={monitorConfig}
            tableName='monitor-unanswered-calls'
          />
        ),
      },
      {
        id: 'waiting-calls',
        label: 'שיחות ממתינות',
        count: waitingCalls.length,
        tableComponent: (
          <WaitingCallsList
            calls={waitingCalls}
            wait_time_threshold={monitorConfig.waiting_alert_threshold}
            tableName='monitor-waiting-calls'
          />
        ),
      },
      {
        id: 'inbound-calls',
        label: 'שיחות נכנסות',
        count: inboundCalls.length,
        tableComponent: (
          <InboundCallsList
            groups={groups}
            calls={inboundCalls}
            validCallThreshold={monitorConfig.valid_call_threshold}
            slaThreshold={monitorConfig.ideal_answer_time}
            tableName='monitor-inbound-calls'
          />
        ),
      },
      {
        id: 'outbound-calls',
        label: 'שיחות יוצאות',
        count: outboundCalls.length,
        tableComponent: (
          <OutboundCallsList
            calls={outboundCalls}
            slaThreshold={monitorConfig.ideal_answer_time}
            tableName='monitor-outbound-calls'
          />
        ),
      },
      {
        id: 'callback-requests',
        label: 'בקשות לחיוג חוזר',
        count: dialerRequests.length,
        tableComponent: (
          <DialerRequestsList
            dialerRequests={dialerRequests}
            callback_alert_threshold={monitorConfig.callback_alert_threshold}
            tableName='callback-requests'
          />
        ),
      },
    ];

    if (user?.groups.includes(PermissionGroup.Support)) {
      // hide `all calls` tab from regular users
      tabs.push({
        id: 'calls',
        label: 'כל השיחות',
        count: calls.length,
        tableComponent: <CallsTable calls={calls} agents={agents} />,
      });
    }

    const isSelected = (id: string) => {
      return id === activeTab;
    };

    const buttons = tabs.map((btn) => {
      const selected = isSelected(btn.id);
      return {
        id: btn.id,
        component: (
          <div className={styles.buttonContainer}>
            <div className={classNames(styles.button, { [styles.selected]: selected })}>
              {`${btn.label}${btn.count !== undefined ? ` (${btn.count})` : ``}`}
            </div>
            {selected ? <SelectedButton width={60} /> : undefined}
          </div>
        ),
      };
    });

    return (
      <div>
        <div className={styles.myButtonBar}>
          <ButtonBar
            toggleBar
            className={styles.buttonBarStyle}
            onToggle={(id) => {
              setActiveTabInStorage(id as TabTitle);
              onTabClick(id as TabTitle);
            }}
            buttons={buttons}
            selectedButtonId={activeTab || tabs[0].id}
          />
          <HorizontalPartition />
        </div>

        {tabs.map((tab) => {
          if (tab.id === activeTab) {
            return (
              <VisibilityWrapper key={tab.id} visible={isSelected(tab.id)}>
                {tab.tableComponent}
              </VisibilityWrapper>
            );
          }
        })}
      </div>
    );
  }
);

interface TablesContainerProps {
  agents: AgentAttributes[];
  calls: CallAttributes[];
  dialerRequests: DialerRequestAttributes[];
  monitorConfig: MonitorConfiguration;
  breakTypes: BreakType[];
  selectedGroups: string[];
  groups: GroupResource[];
  onTabClick: (tab: TabTitle | 'main') => void;
}

interface TabButton {
  id: TabTitle;
  label: string;
  count?: number;
  tableComponent: JSX.Element;
}

export type TabTitle =
  | 'agents'
  | 'unanswered-calls'
  | 'waiting-calls'
  | 'inbound-calls'
  | 'outbound-calls'
  | 'callback-requests'
  | 'calls';

const HorizontalPartition = () => <div className={styles.partitionHorizontal} />;

// A JSX element wrapper to conditionally display its children;
// This is used to bypass components which are conditionally mounted and un-mounted from the UI and losing their data.
export const VisibilityWrapper = ({ children, visible }: { children: React.ReactNode; visible: boolean }) => {
  return <div style={{ visibility: visible ? 'visible' : 'collapse' }}> {children} </div>;
};
