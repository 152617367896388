import { fileFetcher } from '../../utils/apiService';
import { CallbackFieldName, FormFieldValue } from '../types';
import { FetcherFileArgs, FileField, RequestData, RequestMethod } from '../../globalTypes/types';

interface HandleFileFieldChange {
  fieldName: CallbackFieldName;
  fieldValue: FormFieldValue;
  availableToUpdate: boolean;
  apiDataHandler: ({ method, newData, shouldUseOptimistic }: RequestData) => Promise<any>;
  handleFieldSavingStatus: (fieldName: CallbackFieldName, availableToUpdate: boolean, response: any) => void;
}

export const handleFileFieldChange = async ({
  fieldName,
  fieldValue,
  availableToUpdate,
  apiDataHandler,
  handleFieldSavingStatus,
}: HandleFileFieldChange) => {
  const { file } = fieldValue as FileField;
  if (availableToUpdate) {
    const formData = new FormData();
    file && formData.append(fieldName, file);
    const fetcherArgs: FetcherFileArgs = {
      apiName: 'emergencyDialerApi',
      endpointURL: `/dialer/recording?file_name=${file?.name}`,
      method: RequestMethod.POST,
      options: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        body: formData,
      },
    };
    const recordResponse = await fileFetcher({ ...fetcherArgs });
    if (recordResponse.success) {
      const dataToUpdate = Object.fromEntries([
        [`${fieldName}_file_name`, recordResponse.data.recordingName],
        [`${fieldName}_file_url`, recordResponse.data.recordingUrl],
      ]);
      const response = await apiDataHandler({
        method: RequestMethod.PATCH,
        newData: dataToUpdate,
        shouldUseOptimistic: true,
      });
      handleFieldSavingStatus(fieldName, availableToUpdate, response);
    } else {
      handleFieldSavingStatus(fieldName, availableToUpdate, recordResponse);
    }
  }
};
