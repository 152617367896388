import { DataType } from '../../globalTypes/types';

export const getDataType = (data: any) => {
  const dataType = typeof data;

  if (data instanceof Date) return DataType.date;

  if (data instanceof Map) return DataType.map;

  if (dataType === DataType.string || dataType === DataType.number || dataType === DataType.boolean)
    return DataType.primitive;

  if (dataType === DataType.function) return DataType.function;

  if (dataType === DataType.symbol) return DataType.symbol;

  if (dataType === DataType.undefined) return DataType.undefined;

  if (dataType === DataType.object) {
    if (Array.isArray(data)) return DataType.array;

    if (data === null) return DataType.null;

    return DataType.object;
  }

  return DataType.undefined;
};
