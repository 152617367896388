import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { CallDetailsDialog } from './call-details-dialog/call-details-dialog';
import { CallsLog } from './calls-log';
import { Player } from './player';
import { playerContext } from './player-state';
import { selectedCallContext } from './selected-call';
import { CallShape } from './types';

export const CallsHistory = () => {
  const selectedCall = useState<CallShape>();
  const playerVisibility = useState<boolean>(false);

  return (
    <selectedCallContext.Provider value={selectedCall}>
      <playerContext.Provider value={playerVisibility}>
        <Routes>
          <Route Component={CallDetailsDialog} path='/call/:id/*' />
          <Route Component={CallsLog} path='/*' />
        </Routes>
        {playerVisibility[0] && selectedCall[0] && <Player />}
      </playerContext.Provider>
    </selectedCallContext.Provider>
  );
};
