import { useRecoilValue } from 'recoil';
import { MonitorProps } from '../..';
import { IconProps } from '../../../design/icons/icon';
import { HorizontalPartition } from '../../../design/partition/horizontal-partition';
import { Spinner } from '../../../design/spinner/spinner';
import { hadshanutUserState } from '../../../state';
import { calculateSlaCounts } from '../../common/charts/calculateSlaCounts';
import { Card } from '../../common/components';
import { Average, Bad, Excellent, Good } from '../../common/icons/service-level';
import { CallAttributes } from '../../interfaces';
import { useMonitorConfig } from '../../use-monitor-config';
import { groupCallsByCcq } from '../../utils/groupCallsByCcq';
import styles from './inbound-calls-page.module.scss';
import { TextElement } from '../TextElement/text-element';
import { getAnsweredCalls } from '../../utils/getAnsweredCalls';
import { PageHeader } from '../mobile-monitor';

interface InboundCallsPageProps extends MonitorProps { onBackBtnClick: () => void; }

// display a list of cards describing each ccq's inbound calls
export const InboundCallsPage: React.FC<InboundCallsPageProps> = ({ data, selectedGroups, onBackBtnClick }) => {
  const user = useRecoilValue(hadshanutUserState);
  const { data: conf, isLoading: loading } = useMonitorConfig();
  
  const selfManagedGroupUuids = user?.domain.config.selfManagedGroups || [];

  if (loading || !conf) {
    return <Spinner />;
  }
  const inboundCallFilter = (call: CallAttributes) => call.callDirection === 'inbound';
  const callsGroupedByCcq = groupCallsByCcq(data.calls, selfManagedGroupUuids, inboundCallFilter);

  return (
    <div className={styles.layout}>
      <PageHeader name='שיחות נכנסות' onBackBtnClick={onBackBtnClick} />
      <div className={styles.cardsList}>
        {
          Object.entries(callsGroupedByCcq).map(
            ([groupUuid, groupCalls]) => {

              const calculatedSla = calculateSlaCounts(
                groupCalls,
                conf.ideal_answer_time,
                data.groups.map(group => group.id),
                selectedGroups,
                conf.valid_call_threshold
              );

              return (
                <GroupedCallsCard
                  key={groupUuid}
                  groupName={data.groups.find(group => group.id === groupUuid)?.name || ''}
                  calls={groupCalls}
                  selfManagedGroupUuids={selfManagedGroupUuids}
                  sla={calculatedSla.sla || 0}
                />
              )
            }
          )
        }
      </div>
    </div>
  );
};


interface InboundCallCardProps {
  calls: CallAttributes[];
  groupName: string;
  selfManagedGroupUuids: string[];
  sla: number;
}


// display ccq data on a Card
const GroupedCallsCard: React.FC<InboundCallCardProps> = ({ calls, groupName, sla }) => {

  const SlaIcon = renderSlaIcon(sla);
  const answeredCalls = getAnsweredCalls(calls);
  
  return (
    <Card className={styles.card}>
      <TextElement
        label={"מחלקה"}
        value={groupName}
      />

      <HorizontalPartition />

      <div className={styles.flexRowSpaceBetween}>

        <TextElement
          label={'נכנסות'}
          value={`${calls.length}`}
        />
        
        <span className={styles.verticalPartition} />

        <TextElement
          label={'נענו'}
          value={`${answeredCalls.length}`}
        />

      </div>

      <HorizontalPartition />

      <div className={styles.flexRowSpaceBetween}>

        <TextElement
          label={'רמת שרות'}
          value={`${sla}%`}
        />
        
        <SlaIcon style={{alignSelf: 'center'}} />

      </div>
    </Card>
  )
}

function renderSlaIcon(sla: number): (props: IconProps) => JSX.Element {
  if (isNaN(sla) || sla < 0 || sla > 100) {
    return () => <></>;
  }

  if (sla === 100) {
    return Excellent;
  }
  else if (sla >= 80) {
    return Good;
  }
  else if (sla >= 50) {
    return Average;
  }
  else {
    return Bad;
  }
}