import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { Table } from "../design/table/table";
import { Column } from "../design/table/types";
import { useMediaQuery } from "../layouts/app-layout/app-layout";
import { mobileQuery } from "../theme/media";
import { getDigitTimeFromMillis } from "../utils/time/time";
import styles from './grouped-calls-table.module.scss';
import { GroupedCalls } from "./use-grouped-calls";
import { emptyCallsGroup, formatGroupKey, getFromToTimes, getGroupedByHeader } from "./util";

export const GroupedCallsTable = (props: GroupedCallsTableProps) => {
  const { data, urlParams } = props;
  const { groupBy: groupedBy,
    from,
    to,
    membersPerDomain,
    groupsPerDomain,
    domains,
    fromTime: currentFromTime,
    toTime: currentToTime
  } = urlParams;

  const isMobile = useMediaQuery(mobileQuery);

  const tableEntries = data
  .sort((a, b) => a.groupKey > b.groupKey ? 1 : -1)
  .map(callsGroup => ({
    ...callsGroup,
    id: formatGroupKey(callsGroup.groupKey.toString(), groupedBy),
    avgTta: getDigitTimeFromMillis(callsGroup.avgTta * 1000, 'MM:SS'),
    avgDuration: getDigitTimeFromMillis(callsGroup.avgDuration * 1000, 'MM:SS'),
  }));

  // Count all inbound, outbound and callback calls
  const summationData = data.reduce((acc, curr) => ({
    ...acc,
    inboundCount: acc.inboundCount + curr.inboundCount,
    inboundAnsweredCount: acc.inboundAnsweredCount + curr.inboundAnsweredCount,
    inboundUnansweredCount: acc.inboundUnansweredCount + curr.inboundUnansweredCount,

    outboundCount: acc.outboundCount + curr.outboundCount,
    outboundUnansweredCount: acc.outboundUnansweredCount + curr.outboundUnansweredCount,
    outboundAnsweredCount: acc.outboundAnsweredCount + curr.outboundAnsweredCount,

    callbackAttemptsCount: acc.callbackAttemptsCount + curr.callbackAttemptsCount
  }), emptyCallsGroup);

  const urlFilters = [`from`, from, 'to', to];
  if (membersPerDomain) {
    // `membersPerDomain` refers to extension/agents - but calls-history's filters know only `extensions` so far
    urlFilters.push(...['extensionsPerDomain', membersPerDomain.replaceAll('x', '')]);
  }
  if (groupsPerDomain) {
    urlFilters.push(...['groupsPerDomain', groupsPerDomain]);
  }
  if (domains) {
    urlFilters.push(...['domains', domains]);
  }

  const url = '/calls-history/' + urlFilters.join('/');

  const CustomLink = ({ text, ...props }: CustomLinkProps) => {
    if (complicatedGroupBys.includes(groupedBy) && props.groupKey !== TOTAL_SUM) {
      props.url = props.url + `/groupBy/${groupedBy}/groupKey/${props.groupKey}`;
    }
    if (text === undefined || text === null || text === 0 || text === '0') {
      return <>{'-'}</>;
    }
    return (
      <NavLink
        target="_blank"
        to={props.url}
        className={styles.link}
      >
        { text }
      </NavLink>
    )
  };

  // Prepare a single row to feed the summation table (Array with single element)
  const summationRow = [summationData].map(item => ({
    ...item,
    groupKey: TOTAL_SUM, // To distinguish between real data table and summation table (with 1 row only)
    id: '',
    avgTta: '-',
    avgDuration: '-',
  }));


  const columns: Array<Column<typeof tableEntries[0], keyof typeof tableEntries[0]>> = [
    {
      key: 'groupKey',
      header: getGroupedByHeader(groupedBy),
      className: styles.groupKey,
      render: (entry) => (<>{formatGroupKey(entry.groupKey.toString(), groupedBy)}</>),
    },
    {
      key: 'inboundCount',
      header: 'נכנסות',
      className: styles.inboundCount,
      render: (entry) => {
        const { fromTime, toTime } = getFromToTimes(groupedBy, entry.groupKey, currentFromTime, currentToTime);

        return (
          <CustomLink 
            url={url + '/type/inbound' + `/fromTime/${fromTime}/toTime/${toTime}`}
            text={entry.inboundCount}
            groupKey={entry.groupKey}
          />
        )
      }
    },
    {
      key: 'inboundAnsweredCount',
      header: 'נענו',
      className: styles.inboundAnsweredCount,
      render: (entry) => {
        const { fromTime, toTime } = getFromToTimes(groupedBy, entry.groupKey, currentFromTime, currentToTime);

        return (
          <CustomLink
            url={url + '/type/inbound/status/answered' + `/fromTime/${fromTime}/toTime/${toTime}`}
            text={entry.inboundAnsweredCount}
            groupKey={entry.groupKey}
          />
        );
      }
    },
    {
      key: 'inboundUnansweredCount',
      header: 'לא נענו',
      className: styles.inboundUnansweredCount,
      render: (entry) => {
        const { fromTime, toTime } = getFromToTimes(groupedBy, entry.groupKey, currentFromTime, currentToTime);

        return (
          <CustomLink
            url={url + '/type/inbound/status/failed' + `/fromTime/${fromTime}/toTime/${toTime}`}
            text={entry.inboundUnansweredCount}
            groupKey={entry.groupKey}
          /> 
        )
      }
    },
    {
      key: 'callbackAttemptsCount',
      header: 'שיחה חוזרת',
      className: styles.callbackAttemptsCount,
      render: (entry) => <>{entry.callbackAttemptsCount || '-'}</>
    },
    {
      key: 'outboundCount',
      header: 'יוצאות',
      className: styles.outboundCount,
      render: (entry) => {
        const { fromTime, toTime } = getFromToTimes(groupedBy, entry.groupKey, currentFromTime, currentToTime);

        return (
          <CustomLink
            url={url + '/type/outbound' + `/fromTime/${fromTime}/toTime/${toTime}`}
            text={entry.outboundCount}
            groupKey={entry.groupKey}
          />
        )
      }
    },
    {
      key: 'outboundAnsweredCount',
      header: 'נענו',
      className: styles.outboundAnsweredCount,
      render: (entry) => {
        const { fromTime, toTime } = getFromToTimes(groupedBy, entry.groupKey, currentFromTime, currentToTime);

        return (
          <CustomLink
            url={url + '/type/outbound/status/answered' + `/fromTime/${fromTime}/toTime/${toTime}`}
            text={entry.outboundAnsweredCount}
            groupKey={entry.groupKey}
          />
        )
      }
    },
    {
      key: 'avgTta',
      header: 'המתנה ממוצעת',
      className: styles.avgTta,
    },
    {
      key: 'avgDuration',
      header: 'זמן שיחה ממוצע',
      className: styles.avgDuration,
    },
    {
      key: 'answerRate',
      header: 'אחוז מענה',
      className: styles.answerRate,
      render: (entry) => <>{entry.answerRate ? `${entry.answerRate}%` : '-'}</>
    },
    {
      key: 'sla',
      header: 'רמת שירות',
      className: styles.sla,
      render: (entry) => <>{entry.sla ? `${entry.sla}%` : '-'}</>
    },
  ];

  // Feed all columns with an extra css class
  const summationColumns = columns.map(column => {
      return { ...column, className: classNames(column.className, styles.sumRow) }
  });

  if (isMobile) {
    return (
      <div className={styles.customTable}>
        {
          data.map(groupedCalls => {
            const { fromTime, toTime } = getFromToTimes(groupedBy, groupedCalls.groupKey, currentFromTime, currentToTime);
            return(
              <div className={styles.container} key={groupedCalls.groupKey}>

                <span className={styles.groupKeyHeader}>{toTime} - {fromTime}</span>

                <section className={styles.rowContent}>

                  <div className={styles.right}>

                    <div className={styles.inboundCalls}>
                      <div className={styles.bold}>נכנסות {groupedCalls.inboundCount}</div>
                      <div className={styles.silent}>נענו {groupedCalls.inboundAnsweredCount}</div>
                      <div className={styles.silent}>לא נענו {groupedCalls.inboundUnansweredCount}</div>
                      <div className={styles.silent}>חוזרות {groupedCalls.callbackAttemptsCount || 0}</div>
                    </div>

                    <div className={styles.outboundCalls}>
                      <div className={styles.bold}>יוצאות {groupedCalls.outboundCount}</div>
                      <div className={styles.silent}>נענו {groupedCalls.outboundAnsweredCount}</div>
                    </div>

                  </div>

                  <div className={styles.left}>
                    <div className={styles.outboundCalls}>
                      <div className={styles.bold}>ממוצע המתנה <span className={styles.silent}>{getDigitTimeFromMillis(groupedCalls.avgTta * 1000, 'MM:SS')}</span></div>
                      <div className={styles.bold}>ממוצע זמן שיחה <span className={styles.silent}>{getDigitTimeFromMillis(groupedCalls.avgDuration * 1000, 'MM:SS')}</span></div>
                      <div className={styles.bold}>אחוז מענה <span className={styles.silent}>{groupedCalls.answerRate}%</span></div>
                      <div className={styles.bold}>רמת שירות <span className={styles.silent}>{groupedCalls.sla}%</span></div>
                    </div>
                  </div>
                </section>

              </div>
            )
          })
        }
      </div>
    )
  }
  else {
    return (
      <div>
        <Table data={tableEntries} columns={columns} />
        <div className={styles.summationTable}>
          <Table data={summationRow} columns={summationColumns} showHeaders={false} />
        </div>
      </div>
    )
  }

}
interface GroupedCallsTableProps {
  data: GroupedCalls[];
  urlParams: any;
}

interface CustomLinkProps {
  url: string;
  text: string | number;
  groupKey: number | string;
}

const complicatedGroupBys = ['dayOfWeek', 'week', 'dayOfMonth', 'month'];
const TOTAL_SUM = 'summation';