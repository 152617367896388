import { Form, Formik } from "formik";
import { useMe } from "../../api/use-me";
import { Button, Spinner } from "../../design/button/button";
import { Card } from "../../design/card/card";
import { AppLayout, AppLayoutContainer } from "../../layouts/app-layout/app-layout";
import { Page } from "../../page";
import * as Yup from "yup";
import { Field } from "../../design/form/field";
import { Input } from "../../design/form/input/input";
import { API } from "aws-amplify";
import { MonitorConfiguration, useMonitorConfig } from "../use-monitor-config";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import styles from "./monitor-config.module.scss";
import { Select } from "../../design/form/select";
import { BreakTypesTable } from "./break-types";
import { useBreakTypes } from "../use-break-types";

export const MonitorConfig = () => {
  const user = useMe(true);
  const { data: monitorConfig, error: configError, isLoading: configLoading } = useMonitorConfig();
  const { data: breakTypes, isLoading: breakTypesLoading } = useBreakTypes();
  const loading = user?.loading || configLoading || breakTypesLoading;
  const [configValues, setConfigValues] = useState<MonitorConfiguration | undefined>(monitorConfig);

  useEffect(() => {
    if (!monitorConfig) return;
    setConfigValues(monitorConfig);
  }, [monitorConfig]);

  useEffect(() => {
    const postConfig = async () => {
      if (configError?.response?.status && configError.response.status === 404) {
        try {
          await API.post(process.env.REACT_APP_CCM_API_NAME!, "/ccm-configuration", {
            body: DEFAULT_CCM_CONFIG,
          });
        } catch (error: any) {
          console.error("could not post default config");
          console.error(error);
        }
      }
    };

    postConfig().then();
  }, [configError]);

  if (loading) {
    return (
      <AppLayout>
        <AppLayoutContainer>
          <Spinner />
        </AppLayoutContainer>
      </AppLayout>
    );
  }

  const handleSubmit = async (values: Partial<MonitorConfiguration>) => {
    const entries = Object.entries(values);

    if (entries.length !== 1) {
      console.error("onBlur passed more than one field! fields: ", values);
      return;
    }
    if (!configValues) {
      console.error('No configuration values');
      return;
    }

    for (const [k, v] of entries) {
      const newValue = Number(v);
      const oldValue = configValues[k as keyof MonitorConfiguration];
      if (newValue === oldValue || (newValue <= 0 && k !== "unanswered_attempts_threshold")) return;
      setConfigValues({ ...configValues, [k]: newValue });
      try {
        await API.patch(process.env.REACT_APP_CCM_API_NAME!, "/ccm-configuration", {
          body: values,
        });
      } catch (error: any) {
        if (error.response?.status && error.response.status === 404) {
          await API.post(process.env.REACT_APP_CCM_API_NAME!, "/ccm-configuration", {
            body: Object.assign(DEFAULT_CCM_CONFIG, values),
          });
        }
      }
    }
  };

  return (
    <Page name='Monitor Config Page'>
      <AppLayout className={styles.layout} isFullScreen>
        <div className={styles.formContainer}>
          <Formik
            initialValues={{ ...monitorConfig, break_types: breakTypes }}
            onSubmit={() => {}}
            validationSchema={monitorConfigSchema}
          >
            <Form
              onBlur={(e) => {
                handleSubmit({ [e.target.name]: e.target.value });
              }}
            >
              <div className={styles.header}>
                <h2>הגדרות מוניטור</h2>
                <Link to={`/monitor`}>
                  <Button>חזרה למוניטור</Button>
                </Link>
              </div>
              <Card className={styles.card}>
                <div className={styles.cardHeader}>
                  <div className={styles.cardTitle}>רמת שירות</div>
                  <div className={styles.cardDetails}>על פי מדד זה יקבעו כל מדדי השירות במערכת</div>
                </div>
                <Field name='ideal_answer_time' label=' הגדרת זמן מענה אידיאלי בשניות'>
                  <Input type='number' />
                </Field>
                <Field
                  name='unanswered_attempts_threshold'
                  label='הגדרת מספר ניסיונות חזרה ללקוח נוטש. החל מסף זה, שיחה ננטשת תיחשב טופלה.'
                >
                  <Select
                    onChange={(e) => {
                      handleSubmit({ [e.target.name]: e.target.value });
                    }}
                  >
                    <option value={-1}>נדרש מענה הלקוח</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                  </Select>
                </Field>
              </Card>

              <Card className={styles.card}>
                <div className={styles.cardHeader}>
                  <div className={styles.cardTitle}>שיחות ננטשות</div>
                  <div className={styles.cardDetails}>
                    שיחות שנמשכו פחות מזמן זה לא ילקחו בחשבון
                  </div>
                </div>
                <Field
                  name='valid_unanswered_threshold'
                  label='הגדירו סף בשניות לאורך ההמתנה המינימלי. מתחתיו, שיחות לא יוגדרו כננטשות'
                >
                  <Input type='number' />
                </Field>
                <Field
                  name='valid_call_threshold'
                  label='הגדירו סף בשניות לאורך השיחה מינימלי. מתחתיו, שיחות לא יוגדרו כנענו'
                >
                  <Input type='number' />
                </Field>
              </Card>

              <Card className={styles.card}>
                <div className={styles.cardHeader}>
                  <div className={styles.cardTitle}>הגדרת סף להצגת התראות</div>
                  <div className={styles.cardDetails}>
                    כאשר הנתונים יעברו את הערכים הנ"ל, תוצג התראה במדד המערכת
                  </div>
                </div>
                <Field name='unanswered_alert_threshold' label='סף לשיחות שלא נענו'>
                  <Input type='number' />
                </Field>
                <Field name='callback_alert_threshold' label='סף לבקשות שיחה חוזרת'>
                  <Input type='number' />
                </Field>
                <Field name='waiting_alert_threshold' label='סף לזמן המתנה בתור'>
                  <Input type='number' />
                </Field>
              </Card>
            </Form>
          </Formik>
          {breakTypes?.length && (
            <Card className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={styles.cardTitle}>הגדרת סוגי הפסקות</div>
                <div className={styles.cardDetails}>מאפשר לנציגים לבחור את הסוג ההפסקה</div>
              </div>
              <BreakTypesTable breakTypes={breakTypes} domainUuid={user.data!.domain.id} />
              {/* The if(loading) statement above takes care of that */}
            </Card>
          )}
        </div>
      </AppLayout>
    </Page>
  );
};

export const DEFAULT_CCM_CONFIG: MonitorConfiguration = {
  ideal_answer_time: 30,
  valid_unanswered_threshold: 5,
  valid_call_threshold: 5,
  unanswered_alert_threshold: 5,
  unanswered_attempts_threshold: -1,
  callback_alert_threshold: 5,
  waiting_alert_threshold: 30,
};

const monitorConfigSchema = Yup.object().shape({
  ideal_answer_time: Yup.number().test("valid-time", "יש להשתמש בערכים חיוביים בלבד", (value) =>
    Boolean(value && value > 0)
  ),
  valid_unanswered_threshold: Yup.number().test(
    "valid-time",
    "יש להשתמש בערכים חיוביים בלבד",
    (value) => Boolean(value && value > 0)
  ),
  valid_call_threshold: Yup.number().test("valid-time", "יש להשתמש בערכים חיוביים בלבד", (value) =>
    Boolean(value && value > 0)
  ),
  unanswered_alert_threshold: Yup.number().test(
    "valid-time",
    "יש להשתמש בערכים חיוביים בלבד",
    (value) => Boolean(value && value > 0)
  ),
  unanswered_attempts_threshold: Yup.number(),
  callback_alert_threshold: Yup.number().test(
    "valid-time",
    "יש להשתמש בערכים חיוביים בלבד",
    (value) => Boolean(value && value > 0)
  ),
  waiting_alert_threshold: Yup.number().test(
    "valid-time",
    "יש להשתמש בערכים חיוביים בלבד",
    (value) => Boolean(value && value > 0)
  ),
});
