import { styled } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

interface StyledLoadingButtonProps {
  chipButton?: boolean;
  isIconText?: boolean;
}

const loadingButtonProps: PropertyKey[] = ['chipButton', 'hasPermission', 'isIconText'];

export const StyledLoadingButton = styled(LoadingButton, {
  shouldForwardProp: (prop) => !loadingButtonProps.includes(prop),
})<StyledLoadingButtonProps>(({ theme, chipButton, isIconText }) => {
  return {
    padding: chipButton ? `${theme.spacing(0)} ${theme.spacing(2)}` : undefined,
    fontSize: chipButton ? theme.fontSizes.medium : theme.fontSizes.main,
    lineHeight: chipButton ? theme.lineHeights.xlarge : theme.lineHeights.main,
    columnGap: isIconText ? theme.spacing(2) : undefined,

    '&.MuiLoadingButton-root': {
      textTransform: 'none',
    },

    '& .MuiButton-icon': {
      margin: isIconText ? `${theme.spacing(0)}` : '',
    },

    '&.noSpace': {
      padding: 0,
      justifyContent: 'flex-start',

      '&:hover': {
        background: 'transparent',
      },
    },

    '&.label-badge': {
      columnGap: theme.spacing(2),
    },
  };
});
