import { Typography } from '@mui/material';
import { ChangeEvent, FocusEvent, MouseEvent, forwardRef } from 'react';

import { AddFileField } from './AddFileField';
import { EditFileField } from './EditFileField';
import { StyledFieldWrapper } from './StyledFileField';
import { TypographyVariant } from '../../../theme/muiTypes';
import { FileField, InputFileMode } from '../../../globalTypes/types';

interface CustomFileFieldProps {
  name?: string;
  label?: string;
  value: FileField;
  className?: string;
  selectFileBtnText: string;
  changeFileBtnText: string;
  deleteFileBtnText: string;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onRemove?: (event: MouseEvent) => void;
}

export const CustomFileField = forwardRef<HTMLInputElement, CustomFileFieldProps>(({ label, ...props }, ref) => {
  const { value, onRemove, selectFileBtnText, changeFileBtnText, deleteFileBtnText, ...rest } = props;
  const { file_type, file_mode, supported_formats, file_format } = value;

  return (
    <StyledFieldWrapper>
      <Typography variant={TypographyVariant.boldCaption1}>{label}</Typography>
      {file_mode === InputFileMode.ADD && (
        <AddFileField
          file_type={file_type}
          file_format={file_format}
          supported_formats={supported_formats}
          selectFileBtnText={selectFileBtnText}
          {...rest}
        />
      )}
      {file_mode === InputFileMode.EDIT && (
        <EditFileField
          value={value}
          onRemove={onRemove}
          deleteFileBtnText={deleteFileBtnText}
          changeFileBtnText={changeFileBtnText}
          {...rest}
        />
      )}
    </StyledFieldWrapper>
  );
});
