import { useLocation, useParams } from "react-router-dom";
import { useURLParams } from "../../../app/use-url-params";
import {
  AppLayout,
  AppLayoutBreadcrumbLink,
  AppLayoutBreadcrumbs,
  AppLayoutContainer,
  AppLayoutHeader,
  AppLayoutTitle,
} from "../../../layouts/app-layout/app-layout";
import { Page } from "../../../page";
import styles from "./event-details-page.module.scss";
import classNames from "classnames";
import { Table } from "../../../design/table/table";
import { Filter } from "../../../design/filter/filter";
import {
  Callee,
  DialerEvent,
  DialerRequest,
  DialerRequestStatus,
  JobTitle,
  TranslateDialRequestStatus,
  TranslateEventStatus,
} from "../../../api/dialers";
import { Spinner } from "../../../design/spinner/spinner";
import { Button } from "../../../design/button";
import { ArrowRight, MoreHorizontalIcon } from "../../../design/icons";
import { CallFlowDialog } from "./dialog/request-info-dialog";
import { useDialogState } from "reakit";
import { useDialerEvent } from "../../use-dialer-event";
import { useDialerRequests } from "../../use-dialer-requests";
import moment from "moment";
import { Field } from "formik";
import { Checkbox } from "../../../design/checkbox/checkbox";
import { useJobTitles } from "../../use-job-titles";
import { useContext, useState } from "react";
import { exportEventRequestsReport } from "../../../api/export-report";
import { DialerRequestContext } from "../../context/dialer-request-context";
import { useDialGroup } from "../../use-dial-groups";
import { Column, SearchLocation } from "../../../design/table/types";

const filterKeys = ["job_titles", "status", "dial_attempts", "response_type"];
const DIAL_STATUS: DialerRequestStatus[] = ["active", "canceled", "completed", "failed", "waiting"];
const MAX_CALLEES_PHONE_NUMBERS = 3;

export default function EventDescriptionPage() {
  const { eventNumber } = useParams<any>();
  const eventId = parseInt(eventNumber!);
  const params = useParams<any>()["*"];
  const url = useURLParams({ path: `/emergency-dialer`, params });
  const { data: event } = useDialerEvent(eventId);
  const { data: dialerRequests, loading: dialerRequestsLoading } = useDialerRequests(eventId);
  const { data: dialGroup } = useDialGroup(event?.dialer_id);
  const callFlowDialog = useDialogState();
  const [, setSelectedRequest] = useContext(DialerRequestContext);
  const [downloadingReport, setDownloadingReport] = useState<boolean>(false);

  const handleExportReport = async () => {
    try {
      setDownloadingReport(true);
      await exportEventRequestsReport(
        { ...url.urlParams, event_number: eventId },
        refinedData.map((rawData) => {
          const { drilldown, ...data } = rawData;
          return data as Partial<Callee>;
        })
      );
    } catch (err) {
      console.error(err);
    } finally {
      setDownloadingReport(false);
    }
  };
  const prevUrlParams = useLocation().state.urlParams;
  const eventUrl = useURLParams({ path: `/emergency-dialer/event/${eventNumber}`, params });
  const { data: jobTitles, loading: jobTitlesLoading } = useJobTitles();

  if (jobTitlesLoading) {
    return <Spinner />;
  }

  const activeFilteredJobTitles = eventUrl.urlParams.job_titles?.split(",");
  const activeFilteredStatus = eventUrl.urlParams.status?.split(",");
  const activeFilteredDialAttempts = eventUrl.urlParams.dial_attempts
    ?.split(",")
    ?.map((attempt: string) => parseInt(attempt));
  const activeFilteredResponseType = eventUrl.urlParams.response_type?.split(",");

  const allJobTitles = dialerRequests?.map((request) => request.job_title_id);
  const activeJobTitles = allJobTitles
    ?.filter((id, index) => allJobTitles.indexOf(id) === index)
    ?.map((id) => jobTitles?.find((job) => job.job_title_id === id));

  const filterJobTitles = (request: DialerRequest): boolean => {
    return activeFilteredJobTitles?.some((jobId: string) => {
      return String(request.job_title_id) === jobId;
    });
  };

  const filterStatus = (request: DialerRequest): boolean => {
    return activeFilteredStatus.some((status: DialerRequestStatus) => {
      return request.request_status === status;
    });
  };

  const filterDialAttempts = (request: DialerRequest): boolean => {
    return activeFilteredDialAttempts.some((attempts: number) => {
      return request.num_attempts === attempts;
    });
  };

  const filterResponseType = (request: DialerRequest): boolean => {
    return activeFilteredResponseType.some((responseType: string) => {
      return (
        (responseType === "no_response" && !request.response_type) ||
        request.response_type === responseType
      );
    });
  };

  const filteredRequests = dialerRequests?.filter((request) => {
    let isFiltered = true;
    if (eventUrl.urlParams.status) {
      isFiltered &&= filterStatus(request);
    }
    if (eventUrl.urlParams.job_titles) {
      isFiltered &&= filterJobTitles(request);
    }
    if (eventUrl.urlParams.dial_attempts) {
      isFiltered &&= filterDialAttempts(request);
    }
    if (eventUrl.urlParams.response_type) {
      isFiltered &&= filterResponseType(request);
    }
    return isFiltered;
  });

  const refinedData = (filteredRequests || []).map((request) => ({
    id: `${request.request_uuid}`,
    name: request.full_name,
    job_title: jobTitles?.find((jobTitle) => jobTitle.job_title_id === request.job_title_id)
      ?.description,
    phone_numbers: [request.phone_number1, request.phone_number2, request.phone_number3],
    status: request.request_status,
    dial_attempts: request.num_attempts,
    response_type: request.response_type,
    enabled: request.enabled,
    drilldown: (
      <Button
        className={styles.menuButton}
        variant='link'
        onClick={() => {
          setSelectedRequest(request);
          callFlowDialog.show();
        }}
      >
        <MoreHorizontalIcon size={24} className={styles.moreIcon} />
      </Button>
    ),
  }));
  // ---------------------------------------------------------------------------------
  return (
    <Page name='Event Description Page'>
      <AppLayout>
        <AppLayoutContainer>
          <AppLayoutHeader>
            <div className={styles.firstBreadcrumb}>
              <ArrowRight color='gray' />
              <AppLayoutBreadcrumbs>
                <AppLayoutBreadcrumbLink to={`/emergency-dialer/events-log/${prevUrlParams || ""}`}>
                  יומן אירועים
                </AppLayoutBreadcrumbLink>
                <AppLayoutBreadcrumbLink to={`/emergency-dialer/event/${eventId}`}>
                  אירוע {eventId}
                </AppLayoutBreadcrumbLink>
              </AppLayoutBreadcrumbs>
            </div>
            <div className={styles.layoutHeader}>
              <AppLayoutTitle>
                {event?.dialer_name} {event?.event_id}
              </AppLayoutTitle>
              <Button
                variant='link'
                loading={downloadingReport}
                className={styles.exportExcelButton}
                onClick={handleExportReport}
              >
                ייצוא לאקסל
              </Button>
            </div>
          </AppLayoutHeader>

          {dialerRequestsLoading || jobTitlesLoading ? (
            <Spinner />
          ) : filteredRequests && activeJobTitles ? (
            <main>
              <EventStatus event={event} />

              <EventDetails
                filteredRequests={filteredRequests}
                jobTitles={activeJobTitles as JobTitle[]}
                tableData={refinedData}
              />

              <CallFlowDialog
                dialog={callFlowDialog}
                dialGroupName={event?.dialer_name}
                sms_interaction={dialGroup?.interact_by_sms || false}
              />
            </main>
          ) : (
            <div>ארעה שגיאה בקריאת הנתונים (אין בקשות חיוג)</div>
          )}
        </AppLayoutContainer>
      </AppLayout>
    </Page>
  );
}

interface EventStatusProps {
  event: DialerEvent | undefined;
}
const EventStatus = ({ event }: EventStatusProps) => {
  if (!event) {
    return <Spinner />;
  }
  const launchMediumString = event.launch_medium === "app" ? "מתוך אפליקציה" : "בחיוג טלפוני";
  return (
    <div
      className={classNames(styles.eventStatus, event.event_status === "active" && styles.active)}
    >
      <div>
        <div className={styles.statusDate}>
          <div>
            <span>
              {moment(event.start_date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                ? "היום"
                : moment(event.start_date).isBetween(moment().subtract(7, "d"), moment())
                ? moment(event.start_date).format("dddd")
                : moment(event.start_date).format("DD.MM.YYYY")}
            </span>{" "}
            &nbsp;
            <span>{moment(event.start_date).format("HH:mm")}</span>
          </div>
        </div>
        <div
          className={classNames(
            styles.statusDescription,
            event.event_status === "active" ? styles.active : styles.done
          )}
        >
          אירוע {TranslateEventStatus[event.event_status]}
        </div>
      </div>
      <span>
        הופעל על ידי <span className={styles.strong}>{event.launcher_name}</span>{" "}
        {launchMediumString}
      </span>
    </div>
  );
};

interface EventDetailsProps {
  filteredRequests: DialerRequest[];
  jobTitles: JobTitle[];
  tableData: any[];
}
const EventDetails = ({ filteredRequests, jobTitles, tableData }: EventDetailsProps) => {
  const dialAttempts = Array.from(
    { length: filteredRequests[0].max_attempts * MAX_CALLEES_PHONE_NUMBERS },
    (_, index) => index + 1
  );

  const columns: Array<Column<typeof tableData[0], keyof typeof tableData[0]>> = [
    {
      key: "name",
      className: styles.name,
      header: "שם כונן",
    },
    {
      key: "job_title",
      className: styles.job_title,
      header: "תפקיד",
    },
    {
      key: "phone_numbers",
      className: styles.phone_numbers,
      header: "מספרי יעד",
      render(row) {
        const additionalNumbers = row.phone_numbers
          .map((phone: any) => phone?.trim())
          .filter(
            (phone: any, index: number) =>
              index > 0 && phone !== null && phone !== undefined && phone !== ""
          );
        return (
          <div title={additionalNumbers.join(" ,")} className={styles.allPhoneNumbers}>
            <span>{row.phone_numbers[0]}</span>
            {additionalNumbers.length > 1 ? (
              <span className={styles.moreContentLabel}>{`${additionalNumbers.length - 1}+`}</span>
            ) : undefined}
          </div>
        );
      },
    },
    {
      key: "status",
      className: styles.status,
      header: "סטטוס",
      render(row) {
        return <div>{TranslateDialRequestStatus[row.status as DialerRequestStatus]}</div>;
      },
    },
    {
      key: "dial_attempts",
      className: styles.dial_attempts,
      header: "מספר נסיונות חיוג",
    },
    {
      key: "response_type",
      className: styles.response_type,
      header: "תגובה",
      render(row) {
        return (
          <div className={row.response_type ? styles.hasResponse : ""}>
            {row.response_type === "approve"
              ? "הגעה"
              : row.response_type === "decline"
              ? "אי - הגעה"
              : row.dial_attempts > 0
              ? "אין תגובה"
              : "-"}
          </div>
        );
      },
    },
    {
      key: "drilldown",
      className: styles.drilldown,
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        data={tableData}
        search
        searchPlaceholder=' חפש שם כונן, מספר יעד...'
        searchLocation={SearchLocation.RIGHT}
      >
        <Filters jobTitles={jobTitles} dialAttempts={dialAttempts} />
      </Table>
    </div>
  );
};

interface FiltersProps {
  dialAttempts: number[] | undefined;
  jobTitles: (JobTitle | undefined)[] | undefined;
}
const Filters = ({ dialAttempts, jobTitles }: FiltersProps) => {
  const params = useParams<any>()["*"];
  const { eventNumber } = useParams<any>();
  const url = useURLParams({ path: `/emergency-dialer/event/${eventNumber}`, params });

  const numFilteredJobTitles = url.urlParamsMultiValue.job_titles?.length;
  const numFilteredStatuses = url.urlParamsMultiValue.status?.length;
  const numFilteredDialAttempts = url.urlParamsMultiValue.dial_attempts?.length;
  const numFilteredResponses = url.urlParamsMultiValue.response_type?.length;

  const areFiltersActive =
    Object.keys(url.urlParams).filter((param) => filterKeys.includes(param)).length > 0;

  return (
    <div className={styles.filtersContainer}>
      <Filter /* Job titles filter */
        active={url.urlParams.job_titles}
        badge={
          numFilteredJobTitles ? (
            <div className={styles.filterBadge}>{numFilteredJobTitles}</div>
          ) : undefined
        }
        initialValues={{ job_titles: url.urlParamsMultiValue.job_titles }}
        label='תפקיד'
        onReset={() => {
          url.removeURLParam("job_titles");
        }}
        onSubmit={(values) => {
          const value = values.job_titles.join(",");
          url.setURLParam("job_titles", value);
        }}
      >
        <div>
          {jobTitles?.map((job) => (
            <Field
              key={job?.job_title_id}
              component={(props: any) => (
                <Checkbox
                  title={job?.description}
                  {...props.field}
                  style={{ marginBlockEnd: "8px" }}
                />
              )}
              name='job_titles'
              type='checkbox'
              value={`${job?.job_title_id}`}
            />
          ))}
        </div>
      </Filter>

      <Filter /* Dial status filter */
        active={url.urlParams.status}
        badge={
          numFilteredStatuses ? (
            <div className={styles.filterBadge}>{numFilteredStatuses}</div>
          ) : undefined
        }
        initialValues={{ status: url.urlParamsMultiValue.status }}
        label='סטטוס חיוג'
        onReset={() => {
          url.removeURLParam("status");
        }}
        onSubmit={(values) => {
          const value = values.status.join(",");
          url.setURLParam("status", value);
        }}
      >
        <div>
          {DIAL_STATUS?.map((status) => (
            <Field
              key={status}
              component={(props: any) => (
                <Checkbox
                  title={TranslateDialRequestStatus[status]}
                  {...props.field}
                  style={{ marginBlockEnd: "8px" }}
                />
              )}
              name='status'
              type='checkbox'
              value={status}
            />
          ))}
        </div>
      </Filter>

      <Filter /* Dial attempts filter */
        active={url.urlParams.dial_attempts}
        badge={
          numFilteredDialAttempts ? (
            <div className={styles.filterBadge}>{numFilteredDialAttempts}</div>
          ) : undefined
        }
        initialValues={{ dial_attempts: url.urlParamsMultiValue.dial_attempts }}
        label='מספר נסיונות'
        onReset={() => {
          url.removeURLParam("dial_attempts");
        }}
        onSubmit={(values) => {
          const value = values.dial_attempts.join(",");
          url.setURLParam("dial_attempts", value);
        }}
      >
        <div>
          {dialAttempts?.map((numAttempts) => (
            <Field
              key={numAttempts}
              component={(props: any) => (
                <Checkbox title={numAttempts} {...props.field} style={{ marginBlockEnd: "8px" }} />
              )}
              name='dial_attempts'
              type='checkbox'
              value={`${numAttempts}`}
            />
          ))}
        </div>
      </Filter>

      <Filter /* Response type filter */
        active={url.urlParams.response_type}
        badge={
          numFilteredResponses ? (
            <div className={styles.filterBadge}>{numFilteredResponses}</div>
          ) : undefined
        }
        initialValues={{ response_type: url.urlParamsMultiValue.response_type }}
        label='תגובה שהתקבלה'
        onReset={() => {
          url.removeURLParam("response_type");
        }}
        onSubmit={(values) => {
          const value = values.response_type.join(",");
          url.setURLParam("response_type", value);
        }}
      >
        <div>
          <Field
            key={"approve"}
            component={(props: any) => (
              <Checkbox title={"הגעה"} {...props.field} style={{ marginBlockEnd: "8px" }} />
            )}
            name='response_type'
            type='checkbox'
            value={`approve`}
          />
          <Field
            key={"reject"}
            component={(props: any) => (
              <Checkbox title={"אי - הגעה"} {...props.field} style={{ marginBlockEnd: "8px" }} />
            )}
            name='response_type'
            type='checkbox'
            value={`reject`}
          />
          <Field
            key={"no_response"}
            component={(props: any) => (
              <Checkbox title={"אין תגובה"} {...props.field} style={{ marginBlockEnd: "8px" }} />
            )}
            name='response_type'
            type='checkbox'
            value={`no_response`}
          />
        </div>
      </Filter>
      {areFiltersActive && (
        <button
          className={styles.resetFiltersButton}
          onClick={() => url.removeURLParams(filterKeys)}
        >
          איפוס
        </button>
      )}
    </div>
  );
};
