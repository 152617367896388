import { useMe } from '../api/use-me';
import useGenderFontFeature from '../app/use-gender-font-feature';
import './_reset.scss';

export default function Theme(props: ThemeProps) {
  
  const user = useMe();
  useGenderFontFeature(user?.data?.gender);
  
  return props.children;
}

export interface ThemeProps {
  children: any
}