import { Snackbar, styled } from '@mui/material';

import { SnackbarVariant } from '../../../theme/muiTypes';

export const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
  '&.MuiSnackbar-root': {
    boxShadow: theme.shadows[1],
    borderRadius: theme.borderRadius.default,

    '& .MuiSnackbarContent-root': {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
      borderRadius: theme.borderRadius.default,
      background: theme.backgrounds.bg_main,

      '& .MuiSnackbarContent-message': {
        padding: `${theme.spacing(4)}`,
        lineHeight: theme.lineHeights.xlarge,
      },

      '& .MuiSnackbarContent-action': {
        width: '100%',
        paddingLeft: 0,
        marginRight: 0,
      },
    },
  },
}));

interface StyledHighlightedMessageProps {
  variant: SnackbarVariant;
}

const spanProps: PropertyKey[] = ['variant'];

export const StyledHighlightedMessage = styled('span', {
  shouldForwardProp: (prop) => !spanProps.includes(prop),
})<StyledHighlightedMessageProps>(({ theme, variant }) => {
  let color: string;
  switch (variant) {
    case SnackbarVariant.success:
      color = theme.palette.primary.main;
      break;
    case SnackbarVariant.warning:
      color = theme.palette.warning.main;
      break;
    case SnackbarVariant.error:
      color = theme.palette.error.main;
      break;
    default:
      color = theme.palette.secondary.dark;
  }
  return {
    fontSize: theme.fontSizes.main,
    fontWeight: theme.fontWeights.regular,
    color,
  };
});
