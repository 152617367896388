interface DialogTexts {
  ROLE_TEXT: string;
  CANCEL_LABEL: string;
  CONFIRM_LABEL: string;
  CODE_ROLE_TEXT: string;
  CREATE_ROLE_TEXT: string;
  UPDATE_ROLE_TEXT: string;
  ADD_NEW_ROLE_TEXT: string;
  ROLE_DIALOG_TITLE: string;
  CREATE_CALLEE_TEXT: string;
  UPDATE_CALLEE_TEXT: string;
  DELETE_GROUP_TITLE: string;
  FAILED_TO_SAVE_MESSAGE: string;
  DELETE_GROUP_MESSAGE_1: string;
  DELETE_GROUP_MESSAGE_2: string;
  UNAUTHORIZED_MESSAGE_1: string;
  UNAUTHORIZED_MESSAGE_2: string;
  EDIT_EXISTING_ROLE_TEXT: string;
  ROLE_EMPTY_ERROR_MESSAGE: string;
  DELETE_GROUP_BUTTON_TEXT: string;
  BAD_REQUEST_MAIN_MESSAGE: string;
  EMPTY_FIELD_ERROR_MESSAGE: string;
  ROLE_DELETE_ERROR_MESSAGE: string;
  DIAL_GROUP_RELATED_ENTITIES: string;
  EDIT_CALLEE_FIELDS_ERROR_TEXT: string;
  DELETE_GROUP_CANCEL_BUTTON_TEXT: string;
  DUPLICATED_PERSONAL_ID_ERROR_TEXT: string;
  ROLE_ALREADY_EXISTS_ERROR_MESSAGE: string;
  DELETE_GROUP_BUTTON_UNAUTHORIZED_TEXT: string;
}

export const dialogTexts: DialogTexts = {
  ROLE_TEXT: 'תפקיד',
  CANCEL_LABEL: 'ביטול',
  CONFIRM_LABEL: 'סיימתי',
  CODE_ROLE_TEXT: 'קוד תפקיד',
  ROLE_DIALOG_TITLE: 'תפקידים',
  CREATE_ROLE_TEXT: 'יצירת תפקיד',
  UPDATE_ROLE_TEXT: 'עדכון תפקיד',
  CREATE_CALLEE_TEXT: 'יצירת כונן',
  UPDATE_CALLEE_TEXT: 'עדכון כונן',
  DELETE_GROUP_BUTTON_TEXT: 'מחיקה',
  ADD_NEW_ROLE_TEXT: 'הוספת תפקיד חדש',
  EDIT_EXISTING_ROLE_TEXT: 'עריכת תפקיד',
  BAD_REQUEST_MAIN_MESSAGE: 'שדה לא תקין',
  DELETE_GROUP_TITLE: 'מחיקת קבוצת קריאה',
  DELETE_GROUP_CANCEL_BUTTON_TEXT: 'ביטול',
  DELETE_GROUP_BUTTON_UNAUTHORIZED_TEXT: 'חזרה',
  UNAUTHORIZED_MESSAGE_1: 'אינך מורשה למחוק את',
  UNAUTHORIZED_MESSAGE_2: 'מרשימת קבוצות קריאה',
  DELETE_GROUP_MESSAGE_1: 'האם ברצונך למחוק את',
  DELETE_GROUP_MESSAGE_2: 'מרשימת קבוצות קריאה?',
  ROLE_EMPTY_ERROR_MESSAGE: 'נא לוודא ששדה תפקיד מלא',
  ROLE_DELETE_ERROR_MESSAGE: 'התפקיד בשימוש, לא ניתן להסרה',
  EMPTY_FIELD_ERROR_MESSAGE: 'שדה התפקיד ריק, נא להזין תפקיד',
  ROLE_ALREADY_EXISTS_ERROR_MESSAGE: 'תפקיד זה כבר מוגדר במערכת',
  DUPLICATED_PERSONAL_ID_ERROR_TEXT: 'תעודת זהות זו כבר מוגדרת במערכת.',
  FAILED_TO_SAVE_MESSAGE: 'לא הצלחנו לשמור את השינויים מסיבה לא ידועה.',
  EDIT_CALLEE_FIELDS_ERROR_TEXT: 'ודא/י ת"ז תקינה וכי מספרי הטלפון לא בשימוש קודם.',
  DIAL_GROUP_RELATED_ENTITIES: 'נראה כי נמצאו רשומות כוננים/מפעילים הקשורים לקבוצת הקריאה',
};
