import React from 'react';
import classNames from 'classnames'
import { AnswerRestricted, CallForwarding, PhoneLeft } from '../../design/icons';
import { NumberResourceMode } from '../../api/types';

import styles from './number-status.module.scss';

export function NumberStatus({ mode, className }: NumberStatusProps) {

  const status = {
    open: {
      icon: <PhoneLeft size={24} />,
      className: styles.open
    },
    closed: {
      icon: <AnswerRestricted size={24} />,
      className: styles.closed,
    },
    forwarding: {
      icon: <CallForwarding size={24} />,
      className: styles.forwarding
    }
  }

  return (
    <div className={classNames(styles.status, mode ? status[mode].className : styles.loading, className)}>
      {mode && status[mode].icon}
    </div>
  )
}

export interface NumberStatusProps {
  mode: NumberResourceMode | null,
  className?: string
}