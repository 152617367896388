import useSWR, { KeyedMutator } from 'swr';
import { API } from "aws-amplify";

export interface MonitorConfigurationReturnType {
  data: MonitorConfiguration | undefined,
  error: any,
  isLoading: boolean,
  mutate: KeyedMutator<any>
}

export const useMonitorConfig = (): MonitorConfigurationReturnType => {
  const { data, error, isLoading, mutate } = useSWR('/ccm-configuration', monitorConfigFetcher, SWR_CONFIG);

  return {
    data: data ? data.data as MonitorConfiguration : undefined,
    error,
    isLoading,
    mutate
  }
}

const monitorConfigFetcher = async (endpoint: string) => {

  const urlName = process.env.REACT_APP_CCM_API_NAME!;
  try {
    const res = await API.get(urlName, endpoint, {});
    return res;
  } catch (error: any) {
    if (error.response?.status && error.response.status === 404) {
      throw error;
    }
    console.error('Could not fetch monitor configuration ');
    console.error(error);
  }
}

export interface MonitorConfiguration {
  ideal_answer_time: number;
  valid_unanswered_threshold: number;
  valid_call_threshold: number;
  unanswered_alert_threshold: number;
  unanswered_attempts_threshold?: number | undefined;
  callback_alert_threshold: number;
  waiting_alert_threshold: number;
}

const SWR_CONFIG = {
  revalidateOnFocus: false,
  dedupingInterval: 5 * 60 * 1000, // 5 minutes 
  refreshInterval: 5 * 60 * 1000, // 5 minutes
}
