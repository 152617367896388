import classNames from "classnames";
import { Icon, IconProps } from "../../../design/icons/icon";
import styles from './tags.module.scss';

export const Ringing = () => (
  <Status text={'מצלצל'} color='blue' />
);

export const Busy = ({ outgoing = false }: { outgoing?: boolean }) => (
  <Status text={outgoing ? 'בשיחה יוצאת' : 'בשיחה'} color='red' />
);

export const Available = () => (
  <Status text={'בזמינות'} color='green' />
);

export const Receiving = () => (
  <Status text={'מצלצל'} color='blue' />
);

export const Break = ({ text, color }: { text?: string, color?: string }) => (
  <Status text={text || 'הפסקה'} color={color || 'grey'} />
);

export const Mission = ({ description }: { description?: string }) => (
  <Status text={description || 'משימה'} color='yellow' />
);

const Status = ({ text, color }: { text: string, color: string }) => (
  <div className={classNames(styles.tooltip, styles.statusTag, styles[color])}>
    { shortenText(text) }
    { text.length > TEXT_LIMIT ? <span className={styles.tooltiptext}>{text}</span> : undefined }
  </div>
);

export const SelectedButton = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 61 4"
    width={props.width}
    height={props.height}
    {...props}
  >
    <svg width="61" height="4" viewBox="0 0 61 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.772705" y="0.3125" width="60.2271" height="3.68709" rx="1.84355" fill="#0075DB" />
    </svg>
  </Icon>
);

const TEXT_LIMIT = 12;
const shortenText = (text: string | undefined): string => {
  if (!text) return '';
  return text.length > TEXT_LIMIT ? `${text.slice(0, TEXT_LIMIT)}...` : text;
}