import { DialogStateReturn } from "reakit";
import { Dialog } from "../../../design/dialog/dialog";
import styles from "./dialogs.module.scss";
import { useState } from "react";
import { ErrorDialog } from "../../../design/dialog/error-dialog/error-dialog";

const DIALOG_HEADER = "שינוי סטטוס ";
const DIALOG_SINGLE_CALLEE = "כונן";
const DIALOG_MULTIPLE_CALLEES = "כוננים";
const DIALOG_MESSAGE = "האם ברצונך לשנות את הסטטוס של";
const CONFIRM_LABEL = "שינוי סטטוס";
const CANCEL_LABEL = "ביטול";
const FAILED_TO_SAVE_MESSAGE = "לא הצלחנו לשמור את הנתונים מסיבה לא ידועה";

export const CalleeStatusDialog = ({
  dialog,
  calleeNames,
  handleStatusChange,
  statusType,
}: CalleeStatusDialogProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const dialogHeaderText =
    calleeNames?.length === 1
      ? `${DIALOG_HEADER} ${DIALOG_SINGLE_CALLEE}`
      : `${DIALOG_HEADER} ${DIALOG_MULTIPLE_CALLEES}`;
  const dialogMessageText =
    calleeNames?.length === 1
      ? `${DIALOG_MESSAGE} ${DIALOG_SINGLE_CALLEE}`
      : `${DIALOG_MESSAGE} ${DIALOG_MULTIPLE_CALLEES}`;

  const handleClose = () => {
    setError(false);
    dialog.hide();
  };

  if (error) {
    return (
      <ErrorDialog dialog={dialog} mainMessage={FAILED_TO_SAVE_MESSAGE} onClose={handleClose} />
    );
  }

  return (
    <Dialog
      {...dialog}
      variant='caution'
      header={dialogHeaderText}
      onSubmit={async () => {
        try {
          setLoading(true);
          await handleStatusChange();
        } catch (err) {
          setError(true);
          console.error(err);
        } finally {
          setLoading(false);
        }
      }}
      submitLabel={CONFIRM_LABEL}
      cancelLabel={CANCEL_LABEL}
      hideOnClickOutside={false}
      loading={loading}
      onClose={handleClose}
      aria-label={dialogHeaderText}
    >
      {`${dialogMessageText}:`}
      <br />
      <div className={styles.strong}>{calleeNames.join(", ")}</div>
      לסטטוס {statusType === "active" ? "פעיל" : "לא פעיל"}?
      {statusType === "inactive" && (
        <div className={styles.strong}>
          כוננים בסטטוס לא פעיל לא יקבלו קריאה / הודעת סמס בעת פעילות קבוצת קריאה.
        </div>
      )}
    </Dialog>
  );
};

interface CalleeStatusDialogProps {
  dialog: DialogStateReturn;
  calleeNames: (string | undefined)[];
  handleStatusChange: () => Promise<void>;
  statusType: "active" | "inactive";
}
