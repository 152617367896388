import { FieldValues, UseFormSetValue } from 'react-hook-form';

import { getFileFormat } from '../../whatsapp-bot/utils/attachmentUtils';
import { FieldName, CallbackFieldName, ICallbackDialerForm } from '../types';
import { FileField, FileType, InputFileMode } from '../../globalTypes/types';
import { CALLBACK_FORM_FIELDS_CONFIG } from '../formConfig/callbackDialerFormConfig';
import { DynamicField, FieldKey, FieldValue, FormEvent } from '../../formConfig/types';

const { report_type } = CALLBACK_FORM_FIELDS_CONFIG;

interface ProcessFieldValueArg {
  fieldName: CallbackFieldName;
  fieldKey: FieldKey | null;
  target: HTMLInputElement;
  formValues: ICallbackDialerForm;
  setValue: UseFormSetValue<FieldValues>;
}

export const processFieldValue = ({ fieldName, fieldKey, target, formValues, setValue }: ProcessFieldValueArg) => {
  const targetValue = target.value;
  const targetType = target.type;
  let fieldValue: FieldValue | FileField | { interact_by_sms: boolean; interact_by_mail: boolean } = targetValue;

  if (targetType === 'file') {
    const file = target.files ? target.files[0] : null;
    const file_url = file ? URL.createObjectURL(file) : '';
    const file_name = file ? file.name : '';
    const file_format = getFileFormat(file_url);
    fieldValue = {
      file,
      file_name,
      file_url,
      file_mode: InputFileMode.EDIT,
      file_type: FileType.audio,
      file_format,
      supported_formats: [],
    } as FileField;
    setValue(fieldName, fieldValue);
  } else if (fieldKey) {
    const fieldArrayValues = formValues[fieldName] as DynamicField[];
    fieldValue = fieldArrayValues.length === 0 ? null : fieldArrayValues?.map((field) => field[fieldKey]);
  } else if (targetValue === 'true' || targetValue === 'false') {
    fieldValue = Boolean(JSON.parse(targetValue));
  } else if (fieldName === report_type.name) {
    fieldValue = {
      interact_by_sms: fieldValue === 'mail' ? false : true,
      interact_by_mail: fieldValue === 'sms' ? false : true,
    };
  }

  return fieldValue;
};

export const processFieldKey = (event: FormEvent) => {
  const target = event.target as HTMLInputElement;
  const targetName = target.name as FieldName;
  const splitTargetName = targetName.split('.');
  const fieldName = (splitTargetName.length >= 3 ? splitTargetName[0] : targetName) as CallbackFieldName;
  const fieldKey = splitTargetName.length >= 3 ? (splitTargetName[2] as FieldKey) : null;

  return { fieldName, fieldKey };
};

export const getDynamicFieldValues = (fieldName: CallbackFieldName, fieldValueList: DynamicField[]) => {
  const fieldKeyName = CALLBACK_FORM_FIELDS_CONFIG[fieldName].fieldKey as FieldKey | null;
  if (fieldKeyName) {
    const dynamicFieldValues = fieldValueList?.map((field) => field[fieldKeyName]);
    return dynamicFieldValues.length === 0 ? null : dynamicFieldValues;
  }

  return null;
};

// TODO: Alexg - Refactor the function to support all types and edge cases.
export const isSameFieldValue = (
  currValue: FieldValue | FileField | { interact_by_sms: boolean; interact_by_mail: boolean },
  prevValue: FieldValue
) => {
  if (Array.isArray(currValue) && Array.isArray(prevValue)) {
    return currValue.toString() === prevValue.toString();
  }
  return currValue === prevValue;
};
