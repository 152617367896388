import { DialogStateReturn } from "reakit";
import styles from "./request-info-dialog.module.scss";
import { useContext } from "react";
import { Dialog } from "../../../../design/dialog/dialog";
import { parsePhoneNumber } from "libphonenumber-js";
import { getHebTimeDiff } from "../../../../utils/time/time";
import { DialerRequest } from "../../../../api/dialers";
import { DialerRequestContext } from "../../../context/dialer-request-context";
import AttemptInfo, { SmsInfo } from "./attempt-info";
import { useDialerAttempts } from "../../../use-dialer-requests";
import moment from "moment";
import { safeParsePhoneNumber } from "../../../../utils/strings/parse-phone";

export const CallFlowDialog = ({
  dialog,
  dialGroupName,
  sms_interaction,
}: {
  dialog: DialogStateReturn;
  dialGroupName: string | undefined;
  sms_interaction: boolean;
}) => {
  const [request, setSelectedRequest] = useContext(DialerRequestContext);
  const { data: attempts } = useDialerAttempts(request?.request_uuid);
  const requestDate = moment(request?.start_date);

  if (!request) {
    return <></>;
  }

  return (
    <Dialog
      {...dialog}
      variant='info'
      header={<div>פרטי פעילות כונן {request.full_name}</div>}
      onClose={() => setSelectedRequest(undefined)}
      aria-label='פרטי בקשה'
    >
      <div>
        <div className={styles.parties}>
          <div className={styles.party}>
            <div className={styles.title}>קבוצת קריאה</div>
            <span className={styles.active_label}>{dialGroupName}</span>
            <div>
              <span>
                {moment(requestDate).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                  ? "היום"
                  : moment(requestDate).isBetween(moment().subtract(7, "d"), moment())
                  ? moment(requestDate).format("dddd")
                  : moment(requestDate).format("DD.MM.YYYY")}
              </span>{" "}
              &nbsp;
              <span>{moment(requestDate).format("HH:mm")}</span>
            </div>
          </div>
          <div className={styles.party}>
            <div className={styles.title}>אירוע</div>
            <span className={styles.active_label}>{request?.event_id}</span>
          </div>
        </div>
        <div>
          <CalleePhoneNumbers request={request} />

          {request ? (
            <section className={styles.attempt_list}>
              {attempts
                ?.sort(
                  (a, b) => new Date(b.start_date).getTime() - new Date(a.start_date).getTime()
                )
                ?.map((attempt: any, index: number) => (
                  <AttemptInfo
                    key={attempt.attempt_uuid}
                    request={request}
                    attempt={attempt}
                    requestWaitTime={getHebTimeDiff(
                      new Date(request?.start_date),
                      new Date(attempt.start_date)
                    )}
                    attempts={attempts.length}
                    attemptNum={attempts.length - index}
                    sms_interaction={sms_interaction}
                  />
                ))}
              {sms_interaction && <SmsInfo request={request} />}
            </section>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Dialog>
  );
};

const CalleePhoneNumbers = ({ request }: { request: DialerRequest }) => {
  // One of those numbers has to be present in order to prerform a callback
  const phoneNumbers = [request.phone_number1, request.phone_number2, request.phone_number3]
    .filter((num) => num !== undefined && num !== null && num !== "")
    .map((num) => safeParsePhoneNumber(num!, "IL"));

  return (
    <section className={styles.details}>
      {phoneNumbers.map((num, index) => (
        <div key={num}>
          <div className={styles.detail}>
            <span className={styles.label}>מספר טלפון {index + 1}</span>
            <span className={styles.value}>{num}</span>
          </div>
        </div>
      ))}
    </section>
  );
};
