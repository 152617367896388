import classNames from "classnames";
import { TextareaHTMLAttributes, useEffect, useRef, useState } from "react";
import styles from "./textarea.module.scss";

const LINE_HEIGHT = 24;
export const TextArea = ({ minRows = 3, maxRows = 10, ltr = false, className, ...props }: TextAreaProps) => {
  
  const [dimensions, setDimensions] = useState({ rows: minRows, minRows: minRows, maxRows: maxRows });
  const textareaRef = useRef<HTMLTextAreaElement>(null!);
  
  useEffect(() => {
    const { minRows, maxRows } = dimensions;

    const previousRows = textareaRef.current.rows;
    textareaRef.current.rows = minRows;

    const currentRows = Math.floor(textareaRef.current.scrollHeight / LINE_HEIGHT);

    if (currentRows === previousRows) {
      textareaRef.current.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      textareaRef.current.rows = maxRows;
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }

    setDimensions({ ...dimensions, rows: currentRows < maxRows ? currentRows : maxRows });
  }, [props.value])

  return (
    <textarea
      ref={textareaRef}
      rows={dimensions.rows}
      className={classNames(styles.textArea, className)}
      {...props}
    />
  )

}

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  ltr?: boolean,
  minRows?: number,
  maxRows?: number
}