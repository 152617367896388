import classNames from 'classnames';
import React from 'react';
import BaseCalendar, { CalendarProps } from 'react-calendar';
import { ArrowLeft, ArrowRight } from '../icons';
import './calendar.scss';

export function Calendar({ className, ...props }: CalendarProps) {
  return (
    <BaseCalendar
      calendarType='Hebrew'
      {...props}
      locale='he-IL'
      className={classNames(className, 'calendar')}
      prevLabel={<ArrowRight size={24} />}
      nextLabel={<ArrowLeft size={24} />}
      next2Label={null}
      prev2Label={null}
      formatShortWeekday={(locale, date) => formatDay(date)}
      minDate={new Date(Date.now())}
    />
  );
}

function formatDay(date: Date) {
  switch (date.getDay()) {
    case 0:
      return 'ראשון';
    case 1:
      return 'שני';
    case 2:
      return 'שלישי';
    case 3:
      return 'רביעי';
    case 4:
      return 'חמישי';
    case 5:
      return 'שישי';
    case 6:
      return 'שבת';
    default:
      return '';
  }
}
