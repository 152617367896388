import { parsePhoneNumber } from "libphonenumber-js";
import { ReactNode, useState } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "../../design/spinner/spinner";
import {
  AppLayoutBreadcrumbLink,
  AppLayoutBreadcrumbs,
  AppLayoutContainer,
  AppLayoutHeader,
  AppLayout,
  AppLayoutTitle,
  useMediaQuery,
} from "../../layouts/app-layout/app-layout";
import { Page } from "../../page";
import { BusinessNumberResource } from "../../api/types";
import { useBusinessNumberResource } from "../../api/use-business-number";
import { Callflow } from "../callflow/callflow";
import { Events } from "../events/events";
import { NumberActionBar } from "../number-action-bar/number-action-bar";
import { NumberStatus } from "../number-status/number-status";
import { useDestinations } from "../../api/use-destinations";
import { useMe } from "../../api/use-me";
import { Edit } from "../../design/icons/edit";
import { Check } from "../../design/icons/check";
import { Input } from "../../design/form/input/input";
import { API } from "aws-amplify";
import mixpanel from "mixpanel-browser";
import { KeyedMutator } from "swr";
import styles from "./number-page.module.scss";
import { mobileQuery } from "../../theme/media";
import { safeParsePhoneNumber } from "../../utils/strings/parse-phone";

export function NumberPage() {
  const user = useMe();
  const params = useParams<{ number: string }>();
  const number = useBusinessNumberResource(params.number!);
  const destinations = useDestinations(user?.data);
  const activeRootDestination = number.data?.RootDestinationCollection.find(
    (rootDestination) =>
      rootDestination.id === number.data?.activeRootDestinationId || number.data?.destinationNumber
  );

  const destination =
    destinations.data && activeRootDestination
      ? destinations.data[activeRootDestination.destinationNumber]
      : null;

  const mode = getMode(number.data);
  const loading = user?.loading || number.loading || destinations.loading;

  const pageTitle = `${safeParsePhoneNumber(params.number!, "IL")} ${
    number.data?.label ? number.data?.label : ``
  }`;

  if (number.data && !destination && !activeRootDestination?.id) {
    console.error(`No active destination for this number`);
    return (
      <PageWrapper pageTitle={pageTitle}>
        <div>
          המספר אינו מוגדר במרכזייה באופן תקין. <br />
          <div>
            נסה/י לטעון מחדש. אם הבעיה חוזרת, אנא פנה/י לתמיכה הטכנית <br />
            <a href='mailto:support@ip-com.co.il'>support@ip-com.co.il</a> או בטלפון:{" "}
            <a href='tel:03-657-6576'>03-657-6576</a>
          </div>
        </div>
      </PageWrapper>
    );
  }
  // @TODO authorization should happen in the backend
  const authorized =
    user?.data &&
    number.data &&
    user?.data.domain.config.selfManagedNumbers.includes(number.data.id);

  if (!loading && !authorized) {
    <PageWrapper pageTitle={pageTitle}>
      המספר מנוהל על-ידי iPracticom. למידע נוסף פנה/י לתמיכה
    </PageWrapper>;
  }

  if (number.data && destination) {
    return (
      <Page name='Number Page'>
        <PageWrapper
          pageTitle={
            <Header number={number.data} domainId={user.data?.domain.id} mutate={number.mutate} />
          }
          titleClassName={styles.appLayoutTitle}
        >
          <NumberActionBar />
          <main className={styles.main}>
            <div className={styles.callflow}>
              <Callflow number={number.data} rootDestination={destination} />
            </div>
            <div className={styles.events}>
              <Events />
            </div>
          </main>
        </PageWrapper>
      </Page>
    );
  }

  return (
    <PageWrapper
      pageTitle={
        <div className={styles.title}>
          <NumberStatus mode={mode} />
        </div>
      }
    >
      <Spinner />
    </PageWrapper>
  );
}

const Header = ({
  number,
  domainId,
  mutate,
}: {
  number: BusinessNumberResource;
  domainId?: string;
  mutate: KeyedMutator<BusinessNumberResource>;
}) => {
  const [editState, setEditState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState<string>(number.label || "");
  const phoneNumber = safeParsePhoneNumber(number.number, "IL");
  const label = number.label;
  const mode = getMode(number);
  const isMobile = useMediaQuery(mobileQuery);

  const handleNameChange = async () => {
    try {
      setLoading(true);
      const body = {
        labelId: number.labelId,
        label: name,
      };
      await API.put(
        process.env.REACT_APP_WEB_API_NAME!,
        `/domains/${domainId}/business-numbers/${number.number}/name`,
        { body }
      );

      const trackEventName = `Edit Business Number`;
      mixpanel.track(trackEventName, {
        "Destination Type": name,
      });
      await mutate();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setEditState(false);
    }
  };

  const renderEditArea = () => {
    if (isMobile) {
      return label ? (
        <span className={styles.numberLabel}>
          {label}
          <Edit style={{ cursor: "pointer" }} onClick={() => setEditState(true)} />
        </span>
      ) : (
        <Input
          className={styles.numberLabel}
          value={name}
          placeholder={"הגדר שם למספר"}
          onChange={(event) => setName(event.target.value)}
          onBlur={handleNameChange}
          disabled={loading}
        />
      );
    }

    return (
      <span className={styles.numberLabel}>
        {label}
        <Edit style={{ cursor: "pointer" }} onClick={() => setEditState(true)} />
      </span>
    );
  };

  return (
    <div className={label ? styles.titleWithLabel : styles.title}>
      <span className={label ? styles.modeIconWithLabel : styles.modeIcon}>
        <NumberStatus mode={mode} className={styles.smallIcon} />
      </span>
      <span className={label ? styles.phoneNumberWithLabel : styles.phoneNumber}>
        {phoneNumber}
      </span>
      {editState ? (
        <span className={styles.numberLabel}>
          <Input
            className={styles.numberLabel}
            value={name}
            placeholder={"הגדר שם למספר"}
            onChange={(event) => setName(event.target.value)}
            onBlur={handleNameChange}
            disabled={loading}
          />
          {!isMobile && <Check style={{ cursor: "pointer" }} onClick={handleNameChange} />}
        </span>
      ) : (
        <>{renderEditArea()}</>
      )}
    </div>
  );
};

// @TODO move to backend, this should be part of number resource
function getMode(businessNumber?: BusinessNumberResource) {
  if (!businessNumber) {
    return null;
  }
  if (businessNumber.forwardIncomingCallsTo) {
    return "forwarding";
  }

  const activeRootDestination = businessNumber.RootDestinationCollection.find(
    (rootDestination) => rootDestination.id === businessNumber.activeRootDestinationId
  );

  if (!activeRootDestination) {
    return null;
  }

  if (activeRootDestination.type === "closed") {
    return "closed";
  }

  return "open";
}

const PageWrapper = ({
  pageTitle,
  titleClassName,
  children,
}: {
  pageTitle: ReactNode;
  titleClassName?: string;
  children?: ReactNode;
}) => {
  return (
    <AppLayout>
      <AppLayoutContainer>
        <AppLayoutHeader>
          <AppLayoutBreadcrumbs>
            <AppLayoutBreadcrumbLink to='/my-numbers'>המספרים שלי</AppLayoutBreadcrumbLink>
          </AppLayoutBreadcrumbs>
          <AppLayoutTitle className={titleClassName}>{pageTitle}</AppLayoutTitle>
        </AppLayoutHeader>
        {children}
      </AppLayoutContainer>
    </AppLayout>
  );
};
