import React from 'react';
import { Icon, IconProps } from './icon';

export const PhoneRight = ({...props}: IconProps) => (
  <Icon 
    viewBox="0 0 96 96"
    fill="currentColor"
    {...props}
  >
    <path d="M16,16 C16,50.9927617 44.0835659,79.4262552 78.941643,79.9914258 L80,80 L80,64 L62,58 L54,66 C43.9492546,60.9746273 35.7382418,52.9257233 30.5125733,42.9988997 L30,42 L38,34 L32,16 L16,16 Z"></path>
  </Icon>
);
