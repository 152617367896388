import { API } from 'aws-amplify';
import { useEffect } from 'react';
import useSWR from 'swr';

export function useApi<Data = any, Error = any>(path: string | null, params?: any, force = false) {
  const swrConfig = {
    revalidateOnFocus: false,
    dedupingInterval: 5 * 60 * 1000, // 5 minutes
    refreshInterval: 5 * 60 * 1000, // 5 minutes
  };

  const resource = useSWR<Data, Error>(path ? [path, params] : null, fetcher, swrConfig);
  const loading = path ? !resource.data && !resource.error : false;

  useEffect(() => {
    if (!loading && force) {
      resource.mutate();
    }
  }, []);

  return {
    ...resource,
    loading,
  };
}

async function fetcher(key: string | string[], params: any = {}) {
  const path = Array.isArray(key) ? `/${key.join('/')}` : `/${key}`;
  return await API.get('customerAppApi', path, params);
}
