import { Auth } from "aws-amplify";
import { useContext } from "react";
import { DialogStateReturn, useDialogState } from "reakit";
import { User } from "../../../api/use-users";
import { ButtonWithDropdown } from "../../../design/button-with-dropdown/button-with-dropdown";
import { MoreHorizontalIcon } from "../../../design/icons";
import { activeUserContext } from "../../active-user-context";
import { EditUserDialog } from "./dialogs/edit-user-dialog";
import { RemoveUserDialog } from "./dialogs/remove-user-dialog";
import { ResetPasswordDialog } from "./dialogs/reset-password-dialog";
import { UserActionsMenu } from "./user-actions-menu";
import styles from "./user-list-item.module.scss"

export const UserListItem = ({ user, onRemove, onEdit }: UserListItemProps) => {
  const [, setActiveUser] = useContext(activeUserContext);
  const removeUserDialog = useDialogState();
  const editUserDialog = useDialogState();
  const resetPasswordDialog = useDialogState();
  
  const dialogs = [
    <RemoveUserDialog dialog={removeUserDialog} onRemove={onRemove} />,
    <EditUserDialog dialog={editUserDialog} onEdit={onEdit} />,
    <ResetPasswordDialog dialog={resetPasswordDialog} />
  ];
  
  const handleForgotPassword = async () => {
    try {
      await Auth.forgotPassword(user.user_email.toLowerCase());
      openDialog(resetPasswordDialog);
    } catch (error) {
      console.error(error);
    }
  }

  const openDialog = (dialog: DialogStateReturn) => {
    setActiveUser(user);
    dialog.show();
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.userDetails}>
          {`${user.user_name}\t${user.user_email}`}
        </div>
        <ButtonWithDropdown
          menuClassName={styles.dropdownMenu}
          className={styles.dropDownWrapper}
          label={<MoreHorizontalIcon size={24} />}
          buttonClassName={styles.userActions}
        >
          <UserActionsMenu
            onEditNameEmailClick={() => openDialog(editUserDialog)}
            onResetPasswordClick={handleForgotPassword}
            onRemoveUserClick={() => openDialog(removeUserDialog)}
          />
        </ButtonWithDropdown>
      </div>
      {dialogs.map((dialog, index) => <div key={index}>{dialog}</div>)}
    </>
  )
}

interface UserListItemProps {
  user: Omit<User, "domains">,
  onRemove: () => void;
  onEdit: () => void;
}