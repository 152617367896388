import { API } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useWhatsAppBot } from '../../api/use-whatsapp';
import { Input } from '../../design/form/input/input';
import { Check } from '../../design/icons/check';
import { Edit } from '../../design/icons/edit';
import { Spinner } from '../../design/spinner/spinner';
import {
  AppLayout,
  AppLayoutBreadcrumbLink,
  AppLayoutBreadcrumbs,
  AppLayoutHeader,
  AppLayoutTitle,
} from '../../layouts/app-layout/app-layout';
import { Page } from '../../page';
import { BotEditor } from '../components/custom-flow';
import styles from './edit-bot-page.module.scss';
import { useDialogState } from 'reakit';
import { PlayBotDialog } from '../dialogs/play-bot-dialog';
import { ApproveTemplateDialog } from '../dialogs/approve-template-dialog';
import { useMe } from '../../api/use-me';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../theme/muiTheme';

export default function EditBotPage() {
  const { id } = useParams<{ id: string }>();
  const [nameEditState, setNameEditState] = useState(false);
  const [nameLoading, setNameLoading] = useState(false);
  const { data: user } = useMe();
  // Maybe this is not the correct way to pass the `published` prop:
  const location = useLocation();
  const [published, setPublished] = useState(location.state?.published);
  const { data: savedBot, loading, mutate } = useWhatsAppBot(id!, published);
  const [name, setName] = useState<string>(savedBot?.name || 'בוט חדש');
  const playButtonDialog = useDialogState();
  const requestTemplateDialog = useDialogState();

  useEffect(() => {
    setName(savedBot?.name || 'בוט חדש');
  }, [savedBot]);

  const handleSubmitName = async () => {
    try {
      setNameLoading(true);
      const apiName = process.env.REACT_APP_WHATSAPP_BOT_API_NAME!;
      const endpoint = '/update-ivr';
      const options = { body: { ...savedBot, name } };
      await API.put(apiName, endpoint, options);
    } catch (error) {
      console.error(error);
    } finally {
      setNameLoading(false);
      setNameEditState(false);
    }
  };

  if (loading || !user) {
    return (
      <Page name='Edit Bot'>
        <AppLayout>
          <Spinner />
        </AppLayout>
      </Page>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Page name='Edit Bot'>
        <AppLayout isFullScreen={true}>
          <AppLayoutHeader className={styles.header}>
            <AppLayoutBreadcrumbs>
              <AppLayoutBreadcrumbLink to='/whatsapp-bots'>ראשי</AppLayoutBreadcrumbLink>
            </AppLayoutBreadcrumbs>
            <AppLayoutTitle>
              {nameEditState ? (
                <div className={styles.botName}>
                  <Input value={name} onChange={(e) => setName(e.target.value)} disabled={nameLoading} />
                  <span onClick={handleSubmitName} style={{ cursor: 'pointer' }}>
                    <Check />
                  </span>
                </div>
              ) : (
                <div className={styles.botName}>
                  <span>{name}</span>
                  <span onClick={() => setNameEditState(true)} style={{ cursor: 'pointer' }}>
                    <Edit />
                  </span>
                </div>
              )}
            </AppLayoutTitle>
          </AppLayoutHeader>
          <div className={styles.container}>
            <BotEditor
              bot={savedBot}
              botName={name}
              botId={id!}
              onSave={() => {
                setPublished(false);
              }}
              refresh={mutate}
              requestTemplateDialog={requestTemplateDialog}
              playButtonDialog={playButtonDialog}
            />
          </div>
          <PlayBotDialog dialog={playButtonDialog} bot={savedBot} domainUuid={user.domain.id} />
          <ApproveTemplateDialog
            dialog={requestTemplateDialog}
            bot={savedBot}
            email={user.email}
            domainUuid={user.domain.id}
          />
        </AppLayout>
      </Page>
    </ThemeProvider>
  );
}
