import React from 'react';
import { Icon, IconProps } from './icon';

export const AnswerRestricted = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96"
    fill="currentColor"
    {...props}
  >
    {/* <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">

    </g> */}
    <g fill="currentColor" transform="translate(48.000000, 48.000000) scale(-1, 1) translate(-48.000000, -48.000000) ">
      <path d="M24.6161877,15.7516606 L75.4352135,77.41548 L70.8190258,81.2483394 L20,19.58452 L24.6161877,15.7516606 Z M34,58 L42,66 C44.5237979,64.7381011 46.9315933,63.2855564 49.2052475,61.6605047 L54.0113724,67.4943099 C43.6462266,75.1582301 30.8852463,79.7672439 17.058357,79.9914258 L16,80 L16,64 L34,58 Z M80,16 C80,31.8984729 74.2029555,46.4429735 64.6073279,57.6350405 L59.7403448,51.7305192 C61.9249252,49.0272362 63.8523176,46.1049978 65.4874267,42.9988997 L66,42 L58,34 L64,16 L80,16 Z"></path>
    </g>
  </Icon>
);

