import React from 'react';
import { Icon, IconProps } from './icon';

export const ArrowLeft = ({...props}: IconProps) => (
  <Icon 
    viewBox="0 0 96 96"
    fill="currentColor"
    {...props}
  >
    <polygon fillRule="evenodd" points="29.879 25.879 34.121 30.121 18 45 86 45 86 51 18 51 34.121 65.879 29.879 70.121 7.757 48"/>
  </Icon>
);


