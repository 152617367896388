import { SWRConfiguration } from 'swr';
import { Control, FieldValues } from 'react-hook-form';

export enum DataType {
  map = 'map',
  date = 'date',
  null = 'null',
  array = 'array',
  symbol = 'symbol',
  bigint = 'bigint',
  number = 'number',
  string = 'string',
  object = 'object',
  boolean = 'boolean',
  function = 'function',
  primitive = 'primitive',
  undefined = 'undefined',
}

export type Date = moment.Moment | null;

export interface SavingIndicator {
  info: {
    highlightedText: string;
    regularText: string;
  };
  success: {
    highlightedText: string;
    regularText: string;
  };
  warning: {
    highlightedText: string;
    regularText: string;
  };
  error: {
    highlightedText: string;
    regularText: string;
  };
}

export type Dates = {
  startDate: Date;
  endDate: Date;
};

export enum RequestMethod {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export type RequestApiName = string;
export type RequestKey = string;
export type RecordId = number | null;
export type RecordData = any;

export interface RequestMetaData {
  apiName: RequestApiName;
  endpointURL: RequestKey;
  swrConfig?: SWRConfiguration;
  shouldFetch?: boolean;
}

export interface FormFieldRendererProps extends RequestMetaData {
  control: Control<FieldValues, any>;
}

export interface ApiConfig {
  apiName: string;
  endpointURL: string;
  shouldFetch: boolean;
}
export interface RequestData {
  method: RequestMethod;
  newData: any;
  shouldUseOptimistic: boolean;
}

export type ApiDataHandler = ({ method, newData, shouldUseOptimistic }: RequestData) => Promise<any>;

export interface FetcherArgs {
  apiName: RequestApiName;
  endpointURL: RequestKey;
  method: RequestMethod;
  data?: any;
}

export interface FetcherFileArgs {
  apiName: RequestApiName;
  endpointURL: RequestKey;
  method: RequestMethod;
  options: {
    headers: {
      'Content-Type': string;
    };
    body: FormData;
  };
}

export enum InputFileMode {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

export type FileFormat = keyof typeof FORMAT_TYPE_MAPPING;

export type FileField = {
  file: File | null;
  file_name: string;
  file_url: string;
  file_mode: InputFileMode;
  file_type: FileType;
  file_format: FileFormat | null;
  supported_formats: FileFormat[];
};

export enum FileType {
  application = 'application',
  audio = 'audio',
  video = 'video',
  image = 'image',
  text = 'text',
}

export const FORMAT_TYPE_MAPPING = {
  css: FileType.text,
  htm: FileType.text,
  csv: FileType.text,
  ics: FileType.text,
  mjs: FileType.text,
  html: FileType.text,
  bmp: FileType.image,
  tif: FileType.image,
  svg: FileType.image,
  png: FileType.image,
  ico: FileType.image,
  gif: FileType.image,
  jpg: FileType.image,
  avif: FileType.image,
  jpeg: FileType.image,
  jpe: FileType.image,
  tiff: FileType.image,
  apng: FileType.image,
  webp: FileType.image,
  au: FileType.audio,
  amr: FileType.audio,
  ogg: FileType.audio,
  cda: FileType.audio,
  mid: FileType.audio,
  mp3: FileType.audio,
  m3u: FileType.audio,
  wav: FileType.audio,
  wma: FileType.audio,
  oga: FileType.audio,
  aac: FileType.audio,
  weba: FileType.audio,
  midi: FileType.audio,
  opus: FileType.audio,
  ts: FileType.video,
  ogv: FileType.video,
  avi: FileType.video,
  flv: FileType.video,
  mp4: FileType.video,
  m4a: FileType.video,
  m4b: FileType.video,
  m4p: FileType.video,
  m4v: FileType.video,
  mov: FileType.video,
  mkv: FileType.video,
  mpeg: FileType.video,
  webm: FileType.video,
  '3gp': FileType.video,
  '3g2': FileType.video,
  '3gpp': FileType.video,
  '': FileType.application,
  js: FileType.application,
  gz: FileType.application,
  bz: FileType.application,
  sh: FileType.application,
  ogx: FileType.application,
  arc: FileType.application,
  arj: FileType.application,
  azw: FileType.application,
  bz2: FileType.application,
  abw: FileType.application,
  bin: FileType.application,
  csh: FileType.application,
  doc: FileType.application,
  eot: FileType.application,
  jar: FileType.application,
  odp: FileType.application,
  ods: FileType.application,
  odt: FileType.application,
  pdf: FileType.application,
  php: FileType.application,
  ppt: FileType.application,
  rar: FileType.application,
  rtf: FileType.application,
  tbz: FileType.application,
  tgz: FileType.application,
  txz: FileType.application,
  txt: FileType.application,
  vsd: FileType.application,
  tar: FileType.application,
  xls: FileType.application,
  xml: FileType.application,
  xul: FileType.application,
  zip: FileType.application,
  ppts: FileType.application,
  '7z': FileType.application,
  docx: FileType.application,
  epub: FileType.application,
  json: FileType.application,
  mpkg: FileType.application,
  pptx: FileType.application,
  xlsx: FileType.application,
  xhtml: FileType.application,
  jsonld: FileType.application,
};
