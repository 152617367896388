import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NumberListPage } from './number-list-page/number-list-page';
import { NumberPage } from './number-page/number-page';
import { businessNumberLabelContext } from './business-number-label-context';
import { BusinessNumberLabelResource } from '../api/types';

export function MyNumbers() {
  const businessNumberState = useState<BusinessNumberLabelResource>();

  return (
    <businessNumberLabelContext.Provider value={businessNumberState}>
      <Routes>
        <Route
          path="/:number/*"
          Component={NumberPage}
        />
        <Route
          path="/*"
          Component={NumberListPage}
        />
      </Routes>
    </businessNumberLabelContext.Provider>

  )
}

