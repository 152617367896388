import { Icon, IconProps } from "./icon";


export const ErrorIcon = ({ ...props }: IconProps) => (
  <Icon
    viewBox={"0 0 47 42"}
    fill="currentColor"
    {...props}
  >
    <svg width="47" height="42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_202_6418)">
        <path d="M23.0125 29.5182C21.7264 29.5182 20.6484 30.5949 20.6484 31.8793C20.6484 33.1638 21.7264 34.2405 23.0125 34.2405C24.2985 34.2405 25.3765 33.1638 25.3197 31.936C25.3765 30.5854 24.3079 29.5182 23.0125 29.5182Z" fill="white" />
        <path d="M44.894 37.8861C46.3786 35.3266 46.388 32.276 44.9129 29.726L30.1047 4.11229C28.639 1.53393 25.9913 0.00390625 23.0221 0.00390625C20.0529 0.00390625 17.4052 1.54337 15.9395 4.10285L1.11237 29.7449C-0.36278 32.3232 -0.353324 35.3927 1.14074 37.9522C2.61588 40.4833 5.25413 42.0039 8.20442 42.0039H37.783C40.7428 42.0039 43.3999 40.4644 44.894 37.8861ZM41.6789 36.0349C40.8562 37.4516 39.4 38.2922 37.7736 38.2922H8.19496C6.58743 38.2922 5.14065 37.4705 4.33689 36.0822C3.52367 34.6749 3.51421 32.9938 4.32743 31.5771L19.1546 5.94454C19.9583 4.5373 21.3956 3.70618 23.0221 3.70618C24.6485 3.70618 26.0859 4.54674 26.8896 5.95398L41.7073 31.5865C42.5016 32.9655 42.4921 34.6277 41.6789 36.0349Z" fill="white" />
        <path d="M22.4262 12.943C21.3009 13.2641 20.6012 14.2841 20.6012 15.5214C20.6579 16.2675 20.7052 17.0231 20.7619 17.7692C20.9227 20.612 21.0834 23.3981 21.2442 26.241C21.3009 27.2043 22.0479 27.9032 23.0125 27.9032C23.977 27.9032 24.7335 27.1571 24.7807 26.1843C24.7807 25.5987 24.7807 25.0604 24.8375 24.4654C24.9415 22.6426 25.055 20.8198 25.159 18.997C25.2157 17.8164 25.3197 16.6358 25.3765 15.4553C25.3765 15.0303 25.3197 14.6525 25.159 14.2747C24.6767 13.2169 23.5514 12.6786 22.4262 12.943Z" fill="white" />
      </g>
    </svg>
  </Icon>
)

{/* <svg width="47" height="42" viewBox="0 0 47 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_202_6418)">
<path d="M23.0125 29.5182C21.7264 29.5182 20.6484 30.5949 20.6484 31.8793C20.6484 33.1638 21.7264 34.2405 23.0125 34.2405C24.2985 34.2405 25.3765 33.1638 25.3197 31.936C25.3765 30.5854 24.3079 29.5182 23.0125 29.5182Z" fill="white"/>
<path d="M44.894 37.8861C46.3786 35.3266 46.388 32.276 44.9129 29.726L30.1047 4.11229C28.639 1.53393 25.9913 0.00390625 23.0221 0.00390625C20.0529 0.00390625 17.4052 1.54337 15.9395 4.10285L1.11237 29.7449C-0.36278 32.3232 -0.353324 35.3927 1.14074 37.9522C2.61588 40.4833 5.25413 42.0039 8.20442 42.0039H37.783C40.7428 42.0039 43.3999 40.4644 44.894 37.8861ZM41.6789 36.0349C40.8562 37.4516 39.4 38.2922 37.7736 38.2922H8.19496C6.58743 38.2922 5.14065 37.4705 4.33689 36.0822C3.52367 34.6749 3.51421 32.9938 4.32743 31.5771L19.1546 5.94454C19.9583 4.5373 21.3956 3.70618 23.0221 3.70618C24.6485 3.70618 26.0859 4.54674 26.8896 5.95398L41.7073 31.5865C42.5016 32.9655 42.4921 34.6277 41.6789 36.0349Z" fill="white"/>
<path d="M22.4262 12.943C21.3009 13.2641 20.6012 14.2841 20.6012 15.5214C20.6579 16.2675 20.7052 17.0231 20.7619 17.7692C20.9227 20.612 21.0834 23.3981 21.2442 26.241C21.3009 27.2043 22.0479 27.9032 23.0125 27.9032C23.977 27.9032 24.7335 27.1571 24.7807 26.1843C24.7807 25.5987 24.7807 25.0604 24.8375 24.4654C24.9415 22.6426 25.055 20.8198 25.159 18.997C25.2157 17.8164 25.3197 16.6358 25.3765 15.4553C25.3765 15.0303 25.3197 14.6525 25.159 14.2747C24.6767 13.2169 23.5514 12.6786 22.4262 12.943Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_202_6418">
<rect width="46" height="42" fill="white" transform="translate(0.0124512 0.00390625)"/>
</clipPath>
</defs>
</svg>
 */}
