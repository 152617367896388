import { Node } from 'reactflow';
import { DialogStateReturn } from 'reakit';
import { ChangeEvent, FC, useRef, useState } from 'react';

import styles from './dialog.module.scss';
import { Dialog } from '../../design/dialog/dialog';
import { FileField } from '../../globalTypes/types';
import { GLOBAL_TEXTS } from '../../utils/globalTexts';
import { DEFAULT_FILE_FIELD } from '../utils/constants';
import { AttachmentType, NodeData } from '../utils/types';
import { CustomInput } from '../../components/common/customInput/CustomInput';
import { CustomDivider } from '../../components/common/customDivider/CustomDivider';
import { CustomFileField } from '../../components/common/customFileField/CustomFileField';
import {
  updateAttachmentData,
  getAttachmentDialogTexts,
  getFileValues,
  isValidInput,
  setInitialNodeAttachment,
} from '../utils/attachmentUtils';

const { SELECT_FILE, UPLOAD_ANOTHER_FILE, DELETE_FILE } = GLOBAL_TEXTS;

interface AttachmentDialogProps {
  botId: string;
  dialog: DialogStateReturn;
  selectedNode: Node<NodeData>;
  selectedAttachmentType: AttachmentType | null;
  onClose: () => void;
  onSubmit: (node: Node<NodeData>, isError: boolean) => void;
}

export const AttachmentDialog: FC<AttachmentDialogProps> = ({
  botId,
  dialog,
  selectedNode,
  selectedAttachmentType,
  onClose,
  onSubmit,
}) => {
  const [attachmentStatus, setAttachmentStatus] = useState({ loading: false, error: false });
  const [nodeAttachment, setNodeAttachment] = useState<FileField | string>(() =>
    setInitialNodeAttachment(selectedNode)
  );
  const showAttachmentErrorDialog = useRef(false);

  const { dialogTitle, inputLabel, inputPlaceholder, inputName } = getAttachmentDialogTexts(selectedAttachmentType);
  const isStringAttachment = typeof nodeAttachment === 'string';
  const validInput = isValidInput(inputName, nodeAttachment);
  const attachmentStrValue = isStringAttachment ? nodeAttachment : '';
  const fileFieldValue = isStringAttachment ? DEFAULT_FILE_FIELD : nodeAttachment;
  const isFileAttachment = selectedAttachmentType === AttachmentType.File;
  const direction = attachmentStrValue.length > 0 ? 'ltr' : 'rtl';

  const handleRemoveFile = () => {
    setNodeAttachment('');
  };

  const handleSubmit = async () => {
    setAttachmentStatus((prevStatus) => ({ ...prevStatus, loading: true }));
    const updatedAttachment = await updateAttachmentData(nodeAttachment, selectedAttachmentType, botId);
    const isError = !updatedAttachment ? true : false;
    setAttachmentStatus({ error: isError, loading: false });
    showAttachmentErrorDialog.current = true;
    const updatedNode = {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        ...updatedAttachment,
      },
    };
    onSubmit(updatedNode, isError);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const targetType = target.type;

    if (targetType === 'text') {
      setNodeAttachment(target.value);
    } else if (targetType === 'file') {
      const file = target.files ? target.files[0] : null;
      const fileValues = getFileValues(file);
      if (fileValues) {
        setNodeAttachment(fileValues);
      }
    }
  };

  return (
    <Dialog
      {...dialog}
      loading={attachmentStatus.loading}
      variant='form'
      onClose={onClose}
      onSubmit={handleSubmit}
      hideOnClickOutside={false}
      header={dialogTitle}
      submitBtnDisabled={!validInput}
    >
      {isFileAttachment && (
        <CustomFileField
          value={fileFieldValue}
          selectFileBtnText={SELECT_FILE}
          changeFileBtnText={UPLOAD_ANOTHER_FILE}
          deleteFileBtnText={DELETE_FILE}
          onChange={handleChange}
          onRemove={handleRemoveFile}
        />
      )}
      {isFileAttachment && (
        <div className={styles.separator}>
          <CustomDivider hidden={false} />
          <span className={styles.text}>או</span>
          <CustomDivider hidden={false} />
        </div>
      )}
      <section className={styles.dialogBody}>
        <div className={styles.formField}>
          <label className={styles.inputLabel}>{inputLabel}</label>
          <CustomInput
            sx={{ direction }}
            name={inputName}
            value={attachmentStrValue}
            onChange={handleChange}
            placeholder={inputPlaceholder}
            fullWidth
          />
        </div>
      </section>
    </Dialog>
  );
};
