import React from 'react';
import { useLocation } from 'react-router-dom';
import { Page } from '../../page';
import { AuthLayout } from '../../layouts/auth-layout/auth-layout';
import { ConfirmEmailForm } from '../components/confirm-email-form';
import { CustomerRegistrationFormValues } from '../components/customer-registration-form';

export function ConfirmEmailPage() {

  const location = useLocation();
  const user = location.state;

  return (
    <Page name="Invitation">
      <AuthLayout>
        <ConfirmEmailForm
          user={user}
        />
      </AuthLayout>
    </Page>
  );
}

export interface ConfirmEmailPageLocationState extends CustomerRegistrationFormValues {

}