import { GroupedCalls } from "./use-grouped-calls"
import ApexChart from 'react-apexcharts';
import { formatGroupKey, useTickAmount } from "./util";
import styles from './grouped-calls-graph.module.scss';
import { useRef } from "react";
import { COLORS } from "./constants";

interface GroupedCallsAreaChart {
  metadata: {
    label: string;
    color: string;
    value: string | number;
  }[];
  data: GroupedCalls[];
  groupedBy: string;
  expanded: boolean;
}

export const GroupedCallsAreaChart: React.FC<GroupedCallsAreaChart> = ({ metadata, data, groupedBy, expanded }) => {
  const timeLine = data.map(groupedCalls => formatGroupKey(groupedCalls.groupKey.toString(), groupedBy));
  const { tickAmount, rotate, offsetY } = useTickAmount(timeLine, groupedBy);
  const chartRef = useRef<HTMLDivElement>(null);
  
  const series = [
    {
      name: 'נכנסות נענו',
      data: data.map(item => item.inboundAnsweredCount),
      color: COLORS.GREEN
    },
    {
      name: 'נכנסות לא נענו',
      data: data.map(item => item.inboundUnansweredCount),
      color: COLORS.RED
    },
    {
      name: 'יוצאות',
      data: data.map(item => item.outboundCount),
      color: COLORS.BLUE
    }
  ];


  return (
    <div className={styles.areaChartContainer}>
      {
        <aside className={styles.areaChartMetadataContainer}>
          {
            metadata?.map(item =>
              <div className={styles.metadataItem} key={item.label}>
                <div className={styles.areaChartMetadataLabel}>
                  <div className={styles.metadataMarker} style={{backgroundColor: item.color}}/>
                  <div className={styles.metadataLabel}>{item.label}</div>
                </div>
                <div className={styles.metadataValue}>{item.value}</div>
              </div>
            )
          }
        </aside>
      }
      <div className={styles.areaChartWrapper} ref={chartRef}>
        <ApexChart 
          type='area'
          options={{
            chart: {
              toolbar: {
                show: true,
                // offsetX: 240,
                // offsetY: -4,
                tools: {
                  customIcons: [
                    {
                      icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10h-2a8 8 0 11-8-8v3l4-4-4-4v3z" fill="currentColor"/>
                            </svg>`,
                      title: 'Reset Zoom',
                      class: true ? styles.customResetIcon : '',
                      index: -1,
                      click: function(chart, options, e) {
                        // Custom click action to reset the zoom
                        // Merge the existing xaxis options with the new ones to reset zoom
                        const newXaxis = {
                          ...chart.w.globals.initialConfig.xaxis,
                          min: undefined,
                          max: undefined
                        };
                        chart.updateOptions({
                          xaxis: newXaxis
                        }, false, true);
                      }
                    }
                  ],
                  reset: false,
                  download: false,
                  zoom: true,
                  pan: undefined,
                  zoomin: undefined,
                  zoomout: undefined
                },
              },
            },
            dataLabels: {
              enabled: false
            },
            grid: {
              show: false,
              padding: {
                left: 16,
                bottom: 0
              }
            },
            legend: {
              show: false       
            },
            stroke: {
              width: [1, 1.5, 1], // linesProps?.map(props => props?.lineWidth || 1.5),
              dashArray: [0, 0, 0] // linesProps?.map(props => props?.dashWidth || 0)
            },
            fill: {
              type: "gradient",
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100]
              }
            },
            colors: [COLORS.RED, COLORS.BLUE, COLORS.GREEN], // linesProps?.map(props => props?.color),
            xaxis: {
              categories: timeLine,
              axisTicks: {
                show: false
              },
              tickAmount: tickAmount, // Math.floor(((timeStep * (isDesktop || isWideDesktop ? 4 : 2)) / timeLine.length)),
              labels: {
                rotate: rotate,
                offsetY: offsetY,
                style: {
                  fontWeight: 500,
                  fontSize: '12px',
                  colors: 'grey'
                },
              }
            },
            yaxis: {
              show: false,
              labels: {
                offsetX: -30,
                style: {
                  fontSize: '16px'
                }
              }
            }
          }}
          series={series}
          height={`${expanded ? 250 : 150}%`}
          
        />
      </div>
    </div>
  )
}