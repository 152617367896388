import { useState } from "react";
import { Dialog } from "../../design/dialog/dialog";
import { DialogStateReturn } from "reakit";
import { Input } from "../../design/form/input/input";
import { WhatsAppBot } from "../utils/types";
import { ErrorDialog } from "../../design/dialog/error-dialog/error-dialog";
import { isValidPhoneNumber } from "libphonenumber-js";
import { usePersistentStorageValue } from "../../hooks/useLocalStorage";
import { secureFetch } from "../../auth/server-auth";

interface PlayBotDialogProps {
  dialog: DialogStateReturn;
  bot: WhatsAppBot | undefined;
  domainUuid: string;
}

export const PlayBotDialog: React.FC<PlayBotDialogProps> = ({ dialog, bot, domainUuid }) => {
  const { value: targetPhone, setValue: setTargetPhone } = usePersistentStorageValue('target-phone', '', 'whatsapp');

  const [error, setError] = useState<MyError | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const handleCloseErrorDialog = () => setError(undefined);

  if (!bot) {
    return (
      <ErrorDialog
      dialog={dialog}
      onClose={handleCloseErrorDialog}
      mainMessage={error?.message}
      secondaryMessage={''}
      contactSupport={error?.code === ErrorCode.SERVER_ERROR}
      />
      )
    }

  if (!bot.business_number) {
    return (
      <ErrorDialog
        dialog={dialog}
        onClose={handleCloseErrorDialog}
        mainMessage="עליך לשייך לבוט מספר עסקי מרשימת המספרים"
        secondaryMessage=""
        contactSupport
      />
    )
  }

  if (error) {
    return (
      <ErrorDialog
        dialog={dialog}
        onClose={handleCloseErrorDialog}
        mainMessage={error.message}
        secondaryMessage=""
        contactSupport
      />
    )
  }

  const handleSubmit = async () => {
    
    try {
      if (!isValidPhoneNumber(targetPhone || '', 'IL')) {
        setError({ code: ErrorCode.INVALID_NUMBER, message: 'מספר הטלפון שהזנת אינו חוקי' });
        return;
      }
      setLoading(true);
      const body = {
        botUuid: bot.id,
        botPhone: bot.business_number,
        userPhone: targetPhone
      }
      
      const { res } = await secureFetch(
        `${process.env.REACT_APP_WHATSAPP_BOT_SERVER_URL}/dom/${domainUuid}/launch?stage=draft`,
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        }
      );

      if (!res.ok) {
        const resJson = await res.json();
        const errMessage = resJson.message;
        setError({ ...SERVER_ERROR, message: errMessage || SERVER_ERROR.message });
      }
      dialog.hide();
    }
    catch (error: any) {
      setError(SERVER_ERROR);
    }
    finally {
      setLoading(false);
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTargetPhone(e.target.value);
  }

  return (
    <Dialog
      {...dialog}
      aria-label="play-bot-dialog"
      variant={"form"}
      header={'הרצת בוט'}
      onSubmit={handleSubmit}
      submitLabel="הרצה"
      loading={loading}
    >
      <Input
        name="target_phone"
        value={targetPhone}
        onChange={handleChange}
        placeholder="מספר היעד איתו תיווצר שיחת ווצאפ"
      />
    </Dialog>
  )
}

enum ErrorCode {
  INVALID_NUMBER,
  SERVER_ERROR
}
interface MyError {
  code: ErrorCode;
  message: string;
}
const SERVER_ERROR = { code: ErrorCode.SERVER_ERROR, message: 'אירעה שגיאה בשרת' };