export interface ColumnSortConfig {
  columnName: string,
  sortOrder: SortOrder,
}

export type SortConfigType<T, K extends keyof T> = { ascending: boolean, sortBy?: K }

export type CompareFunction<T> = (a: T, b: T) => number;

export interface ColumnKey<Column> {
  a: Column,
  b: Column,
}

export type Column<T, K extends keyof T> = {
  key: K,
  header?: string,
  sort?: CompareFunction<T>
  className?: string,
  render?: (row: T, column: K) => React.ReactNode,
}

export enum SortOrder {
  Ascending = 'ascending',
  Descending= 'descending',
  NotSorted = 'notSorted'
};

export enum SearchLocation {
  RIGHT,
  LEFT,
}