import styles from "./admin-settings.module.scss";
import { DialerConfigurationForm, DialerSettingsForm, Section } from "../components";
import { Card } from "../../../design/card/card";
import { Field } from "../../../design/form/field";
import { Select } from "../../../design/form/select";
import { Input } from "../../../design/form/input/input";
import { Form, Formik } from "formik";
import { DialGroup, DialerEventCancelationTypes, EmergencyDialer } from "../../../api/dialers";

export const DefaultSettingsForm = ({ handleSave, initialValues }: DefaultSettingsFormProps) => {
  const sections: Section[] = [
    { id: "1", name: "כללי", description: "" },
    { id: "2", name: "חייגן", description: "כאן ניתן להגדיר את פעולות קבוצת הקריאה" },
    { id: "3", name: "סוג הפעלה", description: "" },
    { id: "4", name: "ביטול קריאה", description: "" },
  ];

  return (
    <Formik initialValues={initialValues} onSubmit={(values) => {}}>
      <Form
        onChange={(e: any) => {
          const fieldType = e.target.type;
          if (fieldType !== "text") {
            handleSave(e.target.name, e.target.files?.[0] || e.target.value);
          }
        }}
        onBlur={(e: any) => {
          const fieldType = e.target.type;
          if (fieldType === "text") {
            handleSave(e.target.name, e.target.files?.[0] || e.target.value);
          }
        }}
      >
        <div className={styles.pageContent}>
          <Section section={sections[0]}>
            <Card className={styles.card}>
              <GeneralSettingsSection />
            </Card>
          </Section>

          <Section section={sections[1]}>
            <Card className={styles.card}>
              <DialerSettingsForm isAdmin={true} />
            </Card>
          </Section>

          <Section section={sections[2]}>
            <Card className={styles.card}>
              <DialerConfigurationForm isAdmin={true} save={handleSave} write={false} />
            </Card>
          </Section>

          <Section section={sections[3]}>
            <Card className={styles.card}>
              <CancelDialerSection />
            </Card>
          </Section>
        </div>
      </Form>
    </Formik>
  );
};

const GeneralSettingsSection = () => {
  const NUM_REMINDERS_OPTS = [200, 250, 300, 350];
  const NUM_SIMULTANIOUS_OPTS = [10, 20, 30, 40, 50];

  return (
    <div className={styles.dialerSettingsBody}>
      <div>
        <label className={styles.strong}>{"כמות תזכורים בחבילה"}</label>
        <Field name={"package_cust_calls"}>
          <Select name={"package_cust_calls"} multiple={false}>
            {NUM_REMINDERS_OPTS.map((option) => (
              <option key={option} value={option}>
                {" "}
                {option}{" "}
              </option>
            ))}
          </Select>
        </Field>
      </div>
      <div>
        <label className={styles.strong}>{"כמות שיחות בו זמנית"}</label>
        <Field name={"max_active"}>
          <Select name={"max_active"} multiple={false}>
            {NUM_SIMULTANIOUS_OPTS.map((option) => (
              <option key={option} value={option}>
                {" "}
                {option}{" "}
              </option>
            ))}
          </Select>
        </Field>
      </div>
      <div>
        <label className={styles.strong}>{"כמות סמס בחבילה"}</label>
        <Field name={"package_cust_sms"}>
          <Input placeholder='#' />
        </Field>
      </div>
    </div>
  );
};

export const CancelDialerSection = () => {
  const options = Object.values(DialerEventCancelationTypes);

  return (
    <div className={styles.dialerSettingsBody}>
      <div>
        <label className={styles.strong}>אופן ביטול אירוע</label>
        <Field name={"default_event_cancel_type"}>
          <Select name={"default_event_cancel_type"} multiple={false}>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {" "}
                {option.label}{" "}
              </option>
            ))}
          </Select>
        </Field>
      </div>
    </div>
  );
};

interface DefaultSettingsFormProps {
  handleSave: (fieldName: string, value: any) => void;
  initialValues: DefaultSettingsFormValues;
}

type DefaultSettingsFormValues = DialGroup & { dialer_config: DialConfig };
export type DialConfig = "phone_sms" | "phone" | "sms";
