import { ComponentType, ElementType, FC, memo, MouseEvent } from 'react';

import { CustomChipProps } from '../customChip/CustomChip';
import { StyledItemList, StyledMenuItem } from './StyledItemList';
import { SelectOption } from '../../../formConfig/types';

export interface ItemListProps {
  icon?: ElementType;
  items: SelectOption[];
  className?: string;
  isBlockElement?: boolean;
  ItemComponent: ComponentType<CustomChipProps>;
  onClick?: (event: MouseEvent<Element>, item: SelectOption) => void;
  onDelete?: (value: SelectOption) => void;
}

export const ItemList: FC<ItemListProps> = memo(
  ({ items, className, ItemComponent, isBlockElement = false, onClick, ...rest }) => {
    const handleClick = (event: MouseEvent, item: SelectOption) => {
      if (onClick) {
        event.preventDefault();
        onClick(event, item);
      }
    };

    const renderItemElement = (item: SelectOption) => {
      if (!isBlockElement) {
        return <ItemComponent onClick={onClick} key={item.label} value={item} {...rest} />;
      }
      return (
        <StyledMenuItem key={item.label} onClick={(event) => handleClick(event, item)}>
          <ItemComponent key={item.label} value={item} {...rest} />
        </StyledMenuItem>
      );
    };

    return <StyledItemList className={className}>{items.map((item) => renderItemElement(item))}</StyledItemList>;
  }
);
