import { Auth } from "aws-amplify";
import { Formik, Form } from "formik";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../design/button";
import { Field } from "../../design/form/field";
import { Input } from "../../design/form/input/input";
import { GeneralError } from "../../design/general-error/general-error";
import { Page } from "../../page";
import { AuthLayout } from "../../layouts/auth-layout/auth-layout";
import styles from "../auth.module.scss";
import errorDictionary from "../error-dictionary";

export function ForgotPassword() {
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  async function handleForgotPassword(values: { username: string }) {
    try {
      setLoading(true);
      const forgot = await Auth.forgotPassword(values.username.toLowerCase());
      navigate("/auth/reset-password", {
        state: {
          username: values.username.toLowerCase(),
          ...forgot,
        },
      });
    } catch (e: any) {
      setError(e.message);
      setLoading(false);
    }
  }

  return (
    <Page name='Forgot Password'>
      <AuthLayout>
        <Formik
          key='form_forgot_password'
          initialValues={{
            username: "",
          }}
          onSubmit={handleForgotPassword}
        >
          <Form>
            <header className={styles.header}>
              <h3 className={styles.title}>איפוס סיסמה</h3>
              <p className={styles.description}>
                כדי לאמת את זהותך, נשלח לך קוד אימות לכתובת המייל שלך
              </p>
            </header>
            <Field name='username' label='כתובת המייל איתה נרשמת למערכת'>
              <Input type='email' className={styles.emailInput} />
            </Field>
            {error && <GeneralError error={errorDictionary[error] || error} />}
            <div className={styles.actions}>
              <Button variant='primary' loading={loading}>
                שליחת קוד חד פעמי
              </Button>
            </div>
            <footer className={styles.footer}>
              <Link to='/auth' className={styles.link}>
                חזרה להתחברות
              </Link>
            </footer>
          </Form>
        </Formik>
      </AuthLayout>
    </Page>
  );
}
