import { useWhatsAppBots } from "../../api/use-whatsapp"
import { ButtonWithDropdown } from "../../design/button-with-dropdown/button-with-dropdown";
import { Spinner } from "../../design/spinner/spinner";
import { WhatsAppBot } from "../utils/types";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { Edit } from "../../design/icons/edit";
import { Duplicate } from "../../design/icons/duplicate";
import { Remove } from "../../design/icons/remove";
import { v4 as uuidv4 } from "uuid";
import { useContext, useState } from "react";
import styles from "./bot-list.module.scss"
import { Table } from "../../design/table/table";
import { Phone } from "../../design/icons/phone";
import { activeBotContext } from "./bot-context";
import { EditNumberDialog } from "../edit-number-dialog";
import { useDialogState } from "reakit";
import { EditWarningDialog } from "../edit-warning-dialog";
import { useBusinessNumberCollection } from "../../api/use-business-number";
import classNames from "classnames";
import { useMe } from "../../api/use-me";
import { safeParsePhoneNumber } from "../../utils/strings/parse-phone";
import { Column } from "../../design/table/types";
import { LoadingPage } from "../../app/loading-page";

const formatPhoneNumber = (number?: string) => {
  return number ? safeParsePhoneNumber(number, 'IL') : '';
}
const options: Intl.DateTimeFormatOptions = {
  year: '2-digit', month: '2-digit', day: '2-digit', hour: "2-digit", minute: "2-digit"
}
const formatDate = (date?: Date) => {
  return date ? new Date(date).toLocaleTimeString("he-IL", options).replace(', ', ' - ') : ''
}
const DRAFT_LABEL = 'טיוטה';
const PUBLISHED_LABEL = 'פעיל';
const EDITING_LABEL = 'עריכה';
const COPY_POSTFIX = ' (עותק) ';

export const BotList = () => {
  const user = useMe().data;
  const { data: bots, loading, mutate } = useWhatsAppBots();
  const [, setActiveBot] = useContext(activeBotContext);
  const [mutating, setMutating] = useState(false);

  const businessNumberCollection = useBusinessNumberCollection(true)?.data;
  const isNumberSelfManaged = (numberId: string) => user?.domain?.config.selfManagedNumbers.includes(numberId);

  const validNumbers = businessNumberCollection?.filter(number => 
    isNumberSelfManaged(number.id) && !bots?.some(bot => {
    return bot.business_number === number.number;
  }));
  
  const editNumberDialog = useDialogState();
  const warningDialog = useDialogState();

  if (!user) {
    return <LoadingPage />; // temporary
  }

  const handleDuplicateClick = async (bot: WhatsAppBot) => {
    try {
      setMutating(true);
      const body = { 
        nodes: [...bot.nodes],
        edges: [...bot.edges],
        ...bot.viewport,
        enabled: true,
        id: uuidv4(),
        name: bot.name + COPY_POSTFIX
      }
      await API.post(process.env.REACT_APP_WHATSAPP_BOT_API_NAME!, "/create-ivr", { body: body });
      await mutate();
    } catch (error) {
      console.error(error);
    } finally {
      setMutating(false);
    }
  }

  const handleDeleteClick = async (bot: WhatsAppBot) => {
    try {
      setMutating(true);
      await API.del(process.env.REACT_APP_WHATSAPP_BOT_API_NAME!, `/delete-ivr/${bot.id}`, {})
      await mutate();
    } catch (error) {
      console.error(error);
    } finally {
      setMutating(false);
    }
  }

  const handleEditNumberClick = async (bot: WhatsAppBot) => {
    setActiveBot(bot);
    editNumberDialog.show();
  }

  const handleEditPublishedBot = (bot: WhatsAppBot) => {
    setActiveBot(bot);
    warningDialog.show();
  }

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spinner />
      </div>
    )
  }

  if (!bots) {
    return (
      <div>אין בוטים תרקדו</div>
    )
  }

  const tableData = bots.map((bot: WhatsAppBot) => {
    const isDraft = !bot.publish_date;
    const isEditing = bot.publish_date && (new Date(bot.publish_date) < new Date(bot.draft_date));
    const publishDateFormatted = formatDate(bot.publish_date);
    
    return {
      id: bot.id,
      draft: isDraft ? 
        <div className={styles.draftIcon}>{DRAFT_LABEL}</div> : 
        isEditing ? 
          <div className={styles.editingIcon}>{EDITING_LABEL}</div> :
          <div className={styles.publishedIcon}>{PUBLISHED_LABEL}</div>,
      name: bot.name,
      number: formatPhoneNumber(bot.business_number),
      publish_date: (
        <div className={classNames(styles.publishDate, { [styles.publishDateClickable]: isEditing })} onClick={() => isEditing ? handleEditPublishedBot(bot) : undefined}>
          {
            bot.publish_date ? 
              publishDateFormatted :
              undefined
          }
        </div>
      ),
      menu: (
        <ButtonWithDropdown
          label="..."
          buttonClassName={styles.button}
        >
          <div>
            <Link
              className={mutating ? styles.actionDisabled : styles.menuAction}
              to={mutating ? "/whatsapp-bots" : `/whatsapp-bots/${bot.id}/edit`}
            >
              <span> עריכת בוט </span>
              <Edit size={24} />
            </Link>
            <div
              className={mutating ? styles.actionDisabled : styles.menuAction}
              onClick={() => handleEditNumberClick(bot)}>
              קשר את הבוט למספר עסקי
              <Phone size={24} />
            </div>
            <div
              className={mutating ? styles.actionDisabled : styles.menuAction}
              onClick={() => handleDuplicateClick(bot)}>
              שכפול
              <Duplicate size={24} />
            </div>
            <div
              className={mutating ? styles.actionDisabled : styles.menuAction}
              onClick={() => handleDeleteClick(bot)}>
              <span className={styles.cancelAction}>
                מחיקה
              </span>
              <Remove size={24} />
            </div>
          </div >
        </ButtonWithDropdown>
      )
    }
  });

  const columns: Array<Column<typeof tableData[0], keyof typeof tableData[0]>> = [
    {
      key: "draft",
      className: styles.draft
    },
    {
      key: "name",
      className: styles.botName,
      header: 'בוט'
    },
    {
      key: "number",
      className: styles.businessNumber,
      header: 'מקושר למספר'
    },
    
    {
      key: "publish_date",
      className: styles.publishDateContainer,
      header: 'תאריך פרסום'
    },
    {
      key: "menu",
      className: styles.menuContainer
    },
  ]

  return (
    <>
      <Table showHeaders={true} sort={false} data={tableData} columns={columns} />
      <EditNumberDialog dialog={editNumberDialog} domainUuid={user?.domain.id} numbers={validNumbers} mutate={mutate} />
      <EditWarningDialog dialog={warningDialog} />
    </>
  )
}
