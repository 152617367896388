import { PinDropOutlined as LocationIcon } from '@mui/icons-material';
import { FileUploadOutlined as FileUploadIcon } from '@mui/icons-material';

import styles from '../components/custom-node.module.scss';
import { AttachmentType, MenuItem, MenuItemLabel } from './types';
import { Color } from '../../theme/muiTypes';

export const attachmentMenuConfig: MenuItem[] = [
  {
    label: MenuItemLabel.File,
    value: AttachmentType.File,
    disabled: false,
    icon: <FileUploadIcon color={Color.primary} className={styles.mediaIcon} />,
  },
  {
    label: MenuItemLabel.Location,
    value: AttachmentType.Location,
    disabled: false,
    icon: <LocationIcon color={Color.primary} className={styles.mediaIcon} />,
  },
];
