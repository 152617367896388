import { API } from "aws-amplify";
import { DialGroup, DialerEvent, DialerRequest } from "../api/dialers";
import * as crc32 from 'crc-32';
import useSWR from 'swr';

const SWR_CONFIG = { revalidateOnFocus: false, revalidateOnReconnect: false };
const CRC_MAX_VALUE = 999;

export const useGuestParams = (params: Record<string, any>) => {
  const queryStr = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
  const endpoint = `/guest-parameters?${queryStr}`;
  const { data, error, mutate, isLoading } = useSWR(endpoint, fetcher, SWR_CONFIG);

  return {
    data: isLoading ? undefined : { dialer: data?.data?.dialer, event: data?.data?.event, dialerRequest: data?.data?.dialerRequest } as GuestParamsReturnType,
    error: error,
    loading: isLoading,
    mutate: mutate
  }
}

async function fetcher(url: string) {
  const res = await API.get(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, url, {});
  return res;
}

interface GuestParamsReturnType {
  dialer: DialGroup;
  event: DialerEvent;
  dialerRequest: DialerRequest;
}

export function validate(params: Record<string, any>) {
  const { x, ...queryStr } = params;
  const { calcedX } = calculateCrc(queryStr);

  if (calcedX !== Number(x)) {
    console.error("X check failed! calcedX: ", calcedX);
    return false;
  }
  return true;
}

export const calculateCrc = (queryStr: Record<string, any>) => {
  const urlPath = Object.entries(queryStr).map(([key, value]) => `${key}=${value}`).join('&');
  const calcedX = Math.abs(crc32.str(urlPath)) % (CRC_MAX_VALUE + 1);
  return { calcedX, urlPath };
}