import { SavingIndicator } from '../globalTypes/types';

// Global texts are used across the app. Before changing the text check all references.
export const GLOBAL_TEXTS = {
  ACTIVE: 'פעיל',
  INACTIVE: 'לא פעיל',
  HEBREW: 'עברית',
  ENGLISH: 'אנגלית',
  MOSHIKO: 'מושיקו',
  EFRAT: 'אפרת',
  CONFIRMATION_SMS: 'סמס מאשר',
  SMS_AFTER_LAST_ATTEMPT: 'סמס לאחר נסיון אחרון',
  SMS_BETWEEN_DIALING_ATTEMPTS: 'סמס בין נסיונות חיוג',
  SMS_REPORT_TYPE: 'סמס',
  EMAIL_REPORT_TYPE: 'מייל',
  SMS_AND_EMAIL_REPORT_TYPE: 'סמס + מייל',
  UPLOAD_IVR_FILE: 'טעינת קובץ קולי להשמעה לנציג לפני קבלת שיחה',
  UPLOAD_NEW_IVR_FILE: 'העלאת קובץ הקלטה חדש',
  UPLOAD_ANOTHER_FILE: 'העלאת קובץ אחר',
  DELETE_FILE: 'מחק קובץ',
  FILE_SIZE_LIMIT: 'ניתן להעלות קובץ עד 5MB',
  SELECT_FILE: 'בחר קובץ...',
  ADD_NEW: '+ הוספת חדש',
  GLOBAL_ERROR: 'אירעה שגיאה',
};

export const savingIndicator: SavingIndicator = {
  info: {
    highlightedText: '',
    regularText: '',
  },
  success: {
    highlightedText: 'השיינויים שלך נשמרו',
    regularText: 'ניתן לערוך בכל עת',
  },
  warning: {
    highlightedText: 'שמירה לא הושלמה',
    regularText: 'כדאי לוודא שכל השדות מוזנים באופן תקין',
  },
  error: {
    highlightedText: 'לא ניתן לשמור את השינויים שלך כרגע',
    regularText: 'נסה שוב מאוחר יותר',
  },
};
