import { FieldValues } from 'react-hook-form';

import { CallbackFieldName } from '../types';
import { PermissionLevel } from '../../api/types';
import { setFieldUIPermission } from './setFieldUIPermission';
import { CALLBACK_FORM_FIELDS_CONFIG } from '../formConfig/callbackDialerFormConfig';

export const updateFieldUIConfig = (formValues: FieldValues, selectedPermissionValue: PermissionLevel): void => {
  // Field names of the callback dialer form
  const fieldNameList = Object.keys(CALLBACK_FORM_FIELDS_CONFIG) as CallbackFieldName[];

  fieldNameList.forEach((fieldName) => {
    setFieldUIPermission(fieldName, selectedPermissionValue);
  });

  CALLBACK_FORM_FIELDS_CONFIG.min_time_for_abandon.uiConfig = {
    ...CALLBACK_FORM_FIELDS_CONFIG.min_time_for_abandon.uiConfig,
    disabled: !Boolean(JSON.parse(formValues.create_request_on_abandon)),
  };

  CALLBACK_FORM_FIELDS_CONFIG.min_time_for_answer.uiConfig = {
    ...CALLBACK_FORM_FIELDS_CONFIG.min_time_for_answer.uiConfig,
    disabled: !Boolean(JSON.parse(formValues.delete_request_on_answer)),
  };
};
