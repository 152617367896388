import { Auth } from 'aws-amplify';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export async function iPracticomServicesLogin(userUuid: string, domainUuid: string, token: string): Promise<string> {
  try {
    const url = `${process.env.REACT_APP_GATEKEEPER_URL}/authenticator/dom/${domainUuid}/user/${userUuid}`;
    const rawResult = await fetch(url, {
      method: 'POST',
      headers: {
        'x-token': token,
      },
    });
    const result = await rawResult.json();
    return result.data.token;
  } catch (error: any) {
    console.error(error);
    return '';
  }
}

export async function getIpracticomAccessToken(): Promise<string> {
  const [user, session] = await Promise.all([Auth.currentAuthenticatedUser(), Auth.currentSession()]);
  const userToken = session.getAccessToken().getJwtToken();
  const newToken = await iPracticomServicesLogin(user.username, user.attributes['custom:domain_uuid'], userToken);
  return newToken;
}

let gatekeeperToken = '';
export async function secureFetch(url: string, options?: RequestOptions): Promise<{ res: Response }> {
  let res;
  if (!gatekeeperToken) {
    gatekeeperToken = await getIpracticomAccessToken();
  }
  const { headers, method = 'GET', body } = options || {};
  res = await fetch(url, {
    method,
    headers: { ...headers, 'x-token': gatekeeperToken },
    body,
  });

  if (res.status === 200) return { res };

  if (res.status === 401) {
    gatekeeperToken = await getIpracticomAccessToken();
    res = await fetch(url, { method, body, headers: { ...headers, 'x-token': gatekeeperToken } });
  }

  return { res };
}

export interface RequestOptions {
  headers?: Record<string, string>;
  method?: HttpMethod;
  body?: string;
}
