import { DialogStateReturn } from "reakit";
import { Dialog } from "../../../design/dialog/dialog";
import styles from "./dialogs.module.scss";
import { ErrorDialog } from "../../../design/dialog/error-dialog/error-dialog";
import { API } from "aws-amplify";
import { useState } from "react";

const DIALOG_LABEL_SINGLE = "מחיקת כונן";
const DIALOG_LABEL_MULTIPLE = "מחיקת כוננים";
const CONFIRM_LABEL = "מחיקה";
const CANCEL_LABEL = "ביטול";
const failedToDeleteCallMessage = "לא הצלחנו למחוק את הכונן מסיבה לא ידועה";

interface DeleteCalleeDialogProps {
  dialog: DialogStateReturn;
  calleeNames: (string | undefined)[];
  selectedValues: Set<string>;
  onClose: () => void;
  calleesMutate: () => void;
}

export const DeleteCalleeDialog = ({
  dialog,
  calleeNames,
  selectedValues,
  onClose,
  calleesMutate,
}: DeleteCalleeDialogProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const handleDeleteCallee = async () => {
    const selectedIds = [...selectedValues];
    if (selectedIds.length === 0) {
      onClose();
      dialog.hide();
      return;
    }
    setLoading(true);
    try {
      await Promise.all(
        selectedIds.map((id) =>
          API.del(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, `/callee?uuid=${id}`, {
            body: {
              uuid: id,
            },
          })
        )
      );
      dialog.hide();
      calleesMutate();
    } catch (err) {
      setError(true);
      console.error(err);
    } finally {
      onClose();
    }
  };

  const dialogHeaderText = calleeNames?.length === 1 ? DIALOG_LABEL_SINGLE : DIALOG_LABEL_MULTIPLE;

  if (error) {
    return (
      <ErrorDialog
        dialog={dialog}
        mainMessage={failedToDeleteCallMessage}
        onClose={() => setError(false)}
      />
    );
  }

  return (
    <Dialog
      {...dialog}
      variant='caution'
      header={dialogHeaderText}
      loading={loading}
      onSubmit={handleDeleteCallee}
      submitLabel={CONFIRM_LABEL}
      cancelLabel={CANCEL_LABEL}
      hideOnClickOutside={false}
      onClose={onClose}
      aria-label={dialogHeaderText}
    >
      האם ברצונך למחוק את
      {calleeNames.length === 1 ? (
        <span>
          &nbsp;
          <span className={styles.strong}>{calleeNames[0]}</span> מרשימת הכוננים?
        </span>
      ) : (
        " הכוננים הבאים:"
      )}
      <br />
      {calleeNames.length > 1 ? (
        <div className={styles.strong}>{calleeNames.join(", ")}</div>
      ) : undefined}
      {calleeNames.length > 1 ? <div>מרשימת הכוננים?</div> : undefined}
    </Dialog>
  );
};
