import { FC } from 'react';
import { Typography } from '@mui/material';

import { TypographyVariant } from '../../../theme/muiTypes';

interface CustomInfoFieldProps {
  label: string;
  value: string | number;
  labelVariant?: TypographyVariant;
  valueVariant?: TypographyVariant;
}

export const CustomInfoField: FC<CustomInfoFieldProps> = ({ label, value, labelVariant, valueVariant }) => {
  const infoLabelVariant = labelVariant || TypographyVariant.regularText2;
  const infoValueVariant = valueVariant || TypographyVariant.h3;

  return (
    <div>
      <Typography variant={infoLabelVariant}>{label}</Typography>
      <Typography variant={infoValueVariant}>{value}</Typography>
    </div>
  );
};
