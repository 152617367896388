import { Button, ButtonProps } from "../button/button";
import { useState } from "react";
import styles from "./button-bar.module.scss";

const ButtonBar = ({ buttons, ...props }: ButtonBarProps) => {


  const [selectedButton, setSelectedButton] = useState<string>(props.selectedButtonId || buttons[0].id);

  if (!props.toggleBar) {
    return (
      <div style={{ gap: props.gap }} className={props.className || styles.container}>
        {buttons.map(button => (button.component || <Button  key={button.id}{...button}>{button.label}</Button>))}
      </div>
    )
  }

  const toggleHandler = (button: Button) => {
    props.onToggle(button.id);
    setSelectedButton(button.id);
  }

  return (
    <div style={{ gap: props.gap }} className={props.className || styles.container} >
      {
        buttons.map(button => {
          return button.component ? (
            < div key={button.id} onClick={() => toggleHandler(button)}>
              {button.component}
            </div>)
            : (
              <Button
                {...button}
                key={button.id}
                onClick={(event) => { toggleHandler(button); button.onClick?.(event) }}
                variant={selectedButton === button.id ? "primary" : "regular"}
              >
                {button.label}
              </Button>
            )
        })
      }
    </div >
  )
}

/**
 * This type enforces the following behavior.
 * if toggleBar is true, an onToggle function MUST be provided.
 * if toggleBar is false (or not provided), an onToggle function CAN'T be provided.
 */
type ButtonBarProps = {
  buttons: Button[],
  gap?: string,
  className?: string
} & ({
  toggleBar: true,
  onToggle: (id: string) => void,
  selectedButtonId: string
} | {
  toggleBar?: false,
  onToggle?: never,
  selectedButtonId?: never
});

export interface Button extends ButtonProps {
  id: string
  label?: React.ReactNode,

  /** If provided, it is the users responsibility to handle any custom onClick and conditional styling */
  component?: React.ReactNode,
}

export default ButtonBar;
