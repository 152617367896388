import { ReactNode, FC } from 'react';

import { StyledCustomCard } from './StyledCustomCard';

interface CardProps {
  children: ReactNode;
  className?: string;
}

export const CustomCard: FC<CardProps> = ({ children, className }) => {
  return <StyledCustomCard className={className}>{children}</StyledCustomCard>;
};
