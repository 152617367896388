import { ColumnSortConfig, SortOrder } from "../types";

export const getNewSortConfig = (selectedColumnName: string, columnSortConfig: ColumnSortConfig): ColumnSortConfig => {
  const { columnName, sortOrder } = columnSortConfig;
  
  if (columnName === selectedColumnName) {
    switch (sortOrder) {
      case SortOrder.NotSorted:
        return { columnName, sortOrder: SortOrder.Ascending }
      case SortOrder.Ascending:
        return { columnName, sortOrder: SortOrder.Descending }
      case SortOrder.Descending:
        return { columnName, sortOrder: SortOrder.NotSorted }
    }
  }

  return ({
    columnName: selectedColumnName,
    sortOrder: SortOrder.Ascending
  });
};
