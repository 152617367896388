import { useContext, useState } from "react";
import { Dialog } from '../../../../design/dialog/dialog';
import { activeUserContext } from '../../../active-user-context';
import { useMe } from "../../../../api/use-me";
import { DialogStateReturn } from "reakit/ts";
import { API } from "aws-amplify";
import { GeneralError } from "../../../../design/general-error/general-error";

export function RemoveUserDialog({ dialog, onRemove }: { dialog: DialogStateReturn, onRemove: () => void }) {

  const user = useMe();
  const [loading, setLoading] = useState(false);
  const [activeUser, setActiveUser] = useContext(activeUserContext);
  const [error, setError] = useState<any>();

  const handleConfirm = async () => {
    const apiName = process.env.REACT_APP_MULTI_TENANT_API_NAME!;
    const path = `/user/${activeUser?.user_uuid}/domain/${user.data?.domain.id}`;
    const myInit = {};

    try {
      setLoading(true);
      await API.del(apiName, path, myInit);
      onRemove();
      dialog.hide();
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  const handleClose = () => {
    setActiveUser(undefined);
  }
  return (
    <Dialog {...dialog}
      variant="caution"
      header={`הסרת ${activeUser?.user_name || ""} מניהול ${user?.data?.organization.name || ""}`}
      onSubmit={handleConfirm}
      submitLabel="הסרת משתמש"
      onClose={handleClose}
      loading={loading}
      aria-label="הסרת משתמש">
      <div>
        <p>
          {`
              הגישה של משתמש זה לניהול ${user?.data?.organization.name || ""} - תבוטל.
              יהיה ניתן לצרף לניהול זה באפשרות החיפוש.
            `}
        </p>
        <p style={{ fontWeight: "bold" }}>
          {`
              האם
              ${user?.data?.gender === "female" ? "את בטוחה שאת" : "אתה בטוח שאתה"}
              רוצה להסיר את 
              ${activeUser?.user_name}?
            `}
        </p>
        <GeneralError error={error} />
      </div>
    </Dialog >
  )
}
