import { memo, useCallback, useEffect, useState } from "react";
import { getDigitTimeFromMillis } from "../../../../utils";
import { Card } from "..";
import { CallAttributes } from "../../../interfaces";
import { reduceWaitingCalls } from "../../../utils/reduceWaitingCalls";
import styles from './waiting-calls.module.scss';
import classNames from "classnames";

export const WaitingCalls = memo((props: { calls: CallAttributes[], groupUuids: string[], waitingThreshold: number }) => {
  const [longestWaitTime, setLongestWaitTime] = useState<string>('00:00');

  const waitingCallsInfo = reduceWaitingCalls(props.calls, props.groupUuids);

  useEffect(() => {
    const interval: NodeJS.Timer = setInterval(() => {
      const oldestCallWaitTime = Date.now() - waitingCallsInfo.oldestCallEnterTime;
      if (waitingCallsInfo.count === 0) {
        setLongestWaitTime('00:00');
      } else {
        setLongestWaitTime(getDigitTimeFromMillis(oldestCallWaitTime, 'MM:SS'));
      }
    }, 1000);

    if (waitingCallsInfo.count === 0) {
      clearInterval(interval);
      setLongestWaitTime('00:00');
    }
    return () => {
      clearInterval(interval);
    }
  }, [waitingCallsInfo.callId]);

  const isWaitTimeOverThreshold = useCallback(() => {
    const oldestCallWaitTime = Date.now() - waitingCallsInfo.oldestCallEnterTime;
    return !(oldestCallWaitTime < props.waitingThreshold * 1000);
  }, []);

  return (
    <div>

      <Card className={isWaitTimeOverThreshold() ? styles.waitingCallsCountCardSevere : styles.waitingCallsCountCard}>
        ממתינות
        <div className={classNames(styles.dataContent, isWaitTimeOverThreshold() ? styles.waitingCallsCountSevere : styles.waitingCallsCount)}>
          {waitingCallsInfo.count}
        </div>
      </Card>

      <div className={styles.LWT}>
        <div>זמן הממתין הארוך ביותר</div>
        <span className={styles.dataContent}>{longestWaitTime}</span>
      </div>
    </div>
  )
});