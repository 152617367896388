import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from '../design/button';
import { DomainList } from './domain-list';
import { API, Auth } from 'aws-amplify';
import { UserResource } from '../api/types';
import { Domain, useUsers } from '../api/use-users';
import { useState } from 'react';
import { Spinner } from '../design/spinner/spinner';
import { useURLParams } from '../app/use-url-params';
import styles from './domain-selection.module.scss';

export function DomainSelection(props: UserDomainManagementProps) {
  const [loading, setLoading] = useState(false);
  const currentDomainUuid = props.user?.domain.id;
  const domains = useUsers(props.user?.id).users?.[0]?.domains.filter(
    (domain: Omit<Domain, 'users'>) => domain.domain_uuid !== currentDomainUuid
  );
  const params = useParams<string>()?.[0]?.replace(/^\/|\/$/g, '') || '';
  const pathName = window.location.pathname?.split('/')?.[1] || '';
  const { removeURLParam } = useURLParams({ path: '/' + pathName, params: params });
  const navigate = useNavigate();

  const updateUserDomain = async (domain: Omit<Domain, 'users'>) => {
    const urlName = process.env.REACT_APP_WEB_API_NAME!;
    const endpoint = `/users/${props.user?.id}/domain`;
    const myInit = { body: { id: domain.domain_uuid, name: domain.domain_name, ownerName: domain.domain_owner_name } };
    try {
      setLoading(true);
      const authUser = await Auth.currentAuthenticatedUser();
      await Promise.all([
        Auth.updateUserAttributes(authUser, { 'custom:domain_uuid': domain.domain_uuid }),
        API.patch(urlName, endpoint, myInit),
      ]);

      if (pathName === 'calls-history') {
        removeURLParam('businessNum');
      } else if (pathName === 'my-numbers') {
        navigate('/my-numbers'); // No params should be removed - navigate back to parent page
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  return (
    <div className={styles.userArea}>
      <section className={styles.userInfo}>
        <div className={styles.organization}> {props.user?.organization.name} </div>
        <div className={styles.userName}> {props.user?.name} </div>
      </section>

      <div className={styles.partitionHorizontal}></div>

      <Button variant='regular' onClick={() => navigate('/profile')}>
        הפרופיל שלי
      </Button>

      <div className={styles.partitionHorizontal}></div>

      {domains && domains?.length > 0 && (
        <section className={styles.userDomains}>
          {loading ? (
            <div className={styles.spinner}>
              <Spinner />
            </div>
          ) : (
            <DomainList domains={domains} onClick={updateUserDomain} />
          )}
        </section>
      )}
      <div className={styles.partitionHorizontal}></div>
      <section className={styles.userActions}>
        <Button variant='link' onClick={() => Auth.signOut()}>
          התנתק
        </Button>
        <div className={styles.partitionVertical}></div>
        <Button variant='link'>
          <Link to='/toc.pdf' target='blank'>
            תנאי שימוש
          </Link>
        </Button>
      </section>
    </div>
  );
}

interface UserDomainManagementProps {
  user: UserResource | undefined;
}
