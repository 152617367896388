export const FormattedDate = ({dateStr} : {dateStr: string}) => {
  
    const dateKey = new Date(dateStr);
    const now = new Date();
    const yesterday = new Date(now.getTime() - 24*60*60*1000);
  
    const isToday = dateKey.toDateString() === now.toDateString()
    const isYesterday = dateKey.toDateString() === yesterday.toDateString();
  
    const month: "long" = "long";
    const numeric: "numeric" = "numeric";
    const options = { month: month, day: numeric };
    const date = dateKey.toLocaleDateString('he', options);
    
    return (
      <span>
        {isToday ? `היום, ${date}` : isYesterday ? `אתמול, ${date}` : date}
      </span>
    )
  }
  