const pattern = /^(\d{4})\.ip-com\.co\.il$/;

// get domain id numbers: domainName: 9505.ip-com.co.il => 9505
export function getDomainShortName(domainName: string | undefined): string | undefined {
  if (!domainName) return undefined;

  const match = domainName.match(pattern);
  if (match && match[1]) {
    return match[1]; // This is the captured group of four digits
  }
  return undefined; // Return null if the input does not match the pattern
}