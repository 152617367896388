import { PermissionLevel } from '../../api/types';
import { ComponentType } from '../../formConfig/types';
import { FileType, InputFileMode } from '../../globalTypes/types';
import { TypographyVariant } from '../../theme/muiTypes';
import { CallbackFieldsConfig, FieldsOptions, DynamicFieldVariant, Section } from '../types';

export const FIELDS_OPTIONS: FieldsOptions = {
  languageOptions: [
    { value: 'he', label: 'עברית' },
    { value: 'en', label: 'אנגלית' },
  ],
  callbackLimitOptions: [
    { value: 10, label: 10 },
    { value: 20, label: 20 },
    { value: 30, label: 30 },
    { value: 40, label: 40 },
    { value: 50, label: 50 },
  ],
  scaleOptions: [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
  ],
  maxTimeOptions: [
    { value: 6, label: '6 שעות' },
    { value: 12, label: '12 שעות' },
    { value: 24, label: '24 שעות' },
    { value: 48, label: 'יומיים' },
    { value: 168, label: 'שבוע' },
  ],
  radioOptions: [
    { value: true, label: 'פעיל' },
    { value: false, label: 'לא פעיל' },
  ],
  speakerOptions: [
    { value: 'efrat', label: 'אפרת' },
    { value: 'moshiko', label: 'מושיקו' },
  ],
  smsContentOptions: [
    { value: 'sms_text_at_start', label: 'סמס מאשר' },
    { value: 'sms_text_at_failure', label: 'סמס לאחר נסיון אחרון' },
    { value: 'sms_text_between_attempts', label: 'סמס בין נסיונות חיוג' },
  ],
  reportTypeOptions: [
    { value: 'sms', label: 'סמס' },
    { value: 'mail', label: 'מייל' },
    { value: 'sms_mail', label: 'סמס + מייל' },
  ],
  permissionLevelOptions: [
    { value: PermissionLevel.Customer, label: 'לקוח' },
    { value: PermissionLevel.Support, label: 'תמיכה' },
    { value: PermissionLevel.Admin, label: 'מנהל מערכת' },
  ],
  approvalKeyOptions: [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '*', label: '*' },
    { value: '#', label: '#' },
  ],
};

export const FORM_SECTIONS: Section[] = [
  { id: '1', name: 'כללי' },
  { id: '2', name: 'חיוג נציג' },
  { id: '3', name: 'חיוג לקוח' },
  { id: '4', name: 'סמס' },
  { id: '5', name: 'אין מענה' },
  { id: '6', name: 'דיווחים' },
];

// Used in CallbackDialerValidation Schema
export const FILE_TYPE = {
  text: FileType.text,
  audio: FileType.audio,
  video: FileType.video,
  image: FileType.image,
  application: FileType.application,
};

// Used in CallbackDialerValidation Schema
export const FILE_MODE = {
  ADD: InputFileMode.ADD,
  EDIT: InputFileMode.EDIT,
};

const INITIAL_PERMISSION = {
  hasPermission: false,
};

const COMMON_INPUT_UI_CONFIG = {
  fullWidth: true,
};

const COMMON_SELECT_UI_CONFIG = {
  fullWidth: true,
};

const COMMON_DYNAMIC_FIELD_UI_CONFIG = {
  dynamicFieldVariant: DynamicFieldVariant.chips,
};

// ATTENTION!
// Every change in this form config should be done CAREFULLY since it affects the whole form behavior and styling
export const CALLBACK_FORM_FIELDS_CONFIG: CallbackFieldsConfig = {
  selectedPermission: {
    name: 'selectedPermission',
    label: '',
    options: FIELDS_OPTIONS.permissionLevelOptions,
    componentType: ComponentType.Select,
    uiConfig: { ...INITIAL_PERMISSION, ...COMMON_SELECT_UI_CONFIG, className: 'PlainTextSelect' },
    permission: PermissionLevel.Admin,
  },
  // General
  dialer_id: {
    name: 'dialer_id',
    label: 'ID',
    componentType: ComponentType.Info,
    uiConfig: {
      ...INITIAL_PERMISSION,
      valueVariant: TypographyVariant.h3,
      labelVariant: TypographyVariant.regularText2,
    },
    permission: PermissionLevel.Support,
  },
  enabled: {
    name: 'enabled',
    label: 'סטטוס שירות',
    options: FIELDS_OPTIONS.radioOptions,
    componentType: ComponentType.RadioInput,
    uiConfig: { ...INITIAL_PERMISSION },
    permission: PermissionLevel.Customer,
  },
  language: {
    name: 'language',
    label: 'בחירת שפה',
    options: FIELDS_OPTIONS.languageOptions,
    componentType: ComponentType.Select,
    uiConfig: { ...INITIAL_PERMISSION, ...COMMON_SELECT_UI_CONFIG },
    permission: PermissionLevel.Customer,
  },
  speaker: {
    name: 'speaker',
    label: 'בחירת דובר',
    options: FIELDS_OPTIONS.speakerOptions,
    componentType: ComponentType.Select,
    uiConfig: { ...INITIAL_PERMISSION, ...COMMON_SELECT_UI_CONFIG },
    permission: PermissionLevel.Customer,
  },
  calls_recorded: {
    name: 'calls_recorded',
    label: 'הקלטת שיחה',
    options: FIELDS_OPTIONS.radioOptions,
    componentType: ComponentType.RadioInput,
    uiConfig: { ...INITIAL_PERMISSION },
    permission: PermissionLevel.Customer,
  },
  international_calls_permitted: {
    name: 'international_calls_permitted',
    label: 'שיחה חוזרת למספרים בינלאומיים',
    options: FIELDS_OPTIONS.radioOptions,
    componentType: ComponentType.RadioInput,
    uiConfig: { ...INITIAL_PERMISSION },
    permission: PermissionLevel.Customer,
  },
  blacklist: {
    name: 'blacklist',
    label: 'רשימה שחורה',
    fieldKey: 'blacklist_number',
    componentType: ComponentType.DynamicField,
    uiConfig: { ...INITIAL_PERMISSION, ...COMMON_DYNAMIC_FIELD_UI_CONFIG },
    permission: PermissionLevel.Customer,
  },
  priority: {
    name: 'priority',
    label: 'עדיפות',
    options: FIELDS_OPTIONS.scaleOptions,
    componentType: ComponentType.Select,
    uiConfig: { ...INITIAL_PERMISSION, ...COMMON_SELECT_UI_CONFIG },
    permission: PermissionLevel.Admin,
  },
  max_active: {
    name: 'max_active',
    label: 'הגבלת כמות שיחות חוזרות בו זמנית',
    options: FIELDS_OPTIONS.callbackLimitOptions,
    componentType: ComponentType.Select,
    uiConfig: { ...INITIAL_PERMISSION, ...COMMON_SELECT_UI_CONFIG },
    permission: PermissionLevel.Admin,
  },
  max_request_age: {
    name: 'max_request_age',
    label: 'מקסימום זמן לשיחה חוזרת לפני ביטול אוטומטי',
    options: FIELDS_OPTIONS.maxTimeOptions,
    componentType: ComponentType.Select,
    uiConfig: { ...INITIAL_PERMISSION, ...COMMON_SELECT_UI_CONFIG },
    permission: PermissionLevel.Customer,
  },
  business_number: {
    name: 'business_number',
    label: 'זיהוי מספר לשיחה חוזרת',
    componentType: ComponentType.Select,
    options: [],
    uiConfig: { ...INITIAL_PERMISSION, ...COMMON_SELECT_UI_CONFIG },
    permission: PermissionLevel.Support,
  },
  business_number_for_sms: {
    name: 'business_number_for_sms',
    label: 'זיהוי מספר לסמס',
    componentType: ComponentType.TextInput,
    uiConfig: { ...COMMON_INPUT_UI_CONFIG, ...INITIAL_PERMISSION },
    permission: PermissionLevel.Support,
  },
  // Agent
  active_now: {
    name: 'active_now',
    label: 'עצירת פעילות חיוג לאיתור נציג',
    options: FIELDS_OPTIONS.radioOptions,
    componentType: ComponentType.RadioInput,
    uiConfig: { ...INITIAL_PERMISSION },
    permission: PermissionLevel.Customer,
  },
  agent_max_attempts: {
    name: 'agent_max_attempts',
    label: 'כמות נסיונות חיוג לנציג',
    componentType: ComponentType.TextInput,
    uiConfig: { ...COMMON_INPUT_UI_CONFIG, ...INITIAL_PERMISSION },
    permission: PermissionLevel.Admin,
  },
  agent_dial_interval: {
    name: 'agent_dial_interval',
    label: 'זמן לאיתור נציג בין נסיונות חיוג (דקות)',
    componentType: ComponentType.TextInput,
    uiConfig: { ...COMMON_INPUT_UI_CONFIG, ...INITIAL_PERMISSION },
    permission: PermissionLevel.Support,
  },
  agent_max_ringing: {
    name: 'agent_max_ringing',
    label: 'זמן מירבי שהשיחה מצלצלת אצל הנציג בנסיון חיוג בודד (שניות)',
    componentType: ComponentType.TextInput,
    uiConfig: { ...COMMON_INPUT_UI_CONFIG, ...INITIAL_PERMISSION },
    permission: PermissionLevel.Support,
  },
  agent_approval_key: {
    name: 'agent_approval_key',
    label: 'בחירת ספרה להקשה לקבלת השיחה לאחר הודעה מקדימה',
    options: FIELDS_OPTIONS.approvalKeyOptions,
    componentType: ComponentType.Select,
    uiConfig: { ...INITIAL_PERMISSION, ...COMMON_SELECT_UI_CONFIG },
    permission: PermissionLevel.Support,
  },
  agent_greeting: {
    name: 'agent_greeting',
    label: 'טעינת קובץ קולי להשמעה לנציג לפני קבלת שיחה',
    componentType: ComponentType.FileInput,
    uiConfig: { ...INITIAL_PERMISSION },
    fileType: FileType.audio,
    permission: PermissionLevel.Support,
  },
  agent_abort_call_key: {
    name: 'agent_abort_call_key',
    label: 'הקשה לדחיית השיחה לניסיון החיוג הבא',
    componentType: ComponentType.TextInput,
    uiConfig: { ...COMMON_INPUT_UI_CONFIG, ...INITIAL_PERMISSION },
    permission: PermissionLevel.Admin,
  },
  agent_complete_call_key: {
    name: 'agent_complete_call_key',
    label: 'סיום שיחה חוזרת ומעבר לשיחה הבאה',
    componentType: ComponentType.TextInput,
    uiConfig: { ...COMMON_INPUT_UI_CONFIG, ...INITIAL_PERMISSION },
    permission: PermissionLevel.Admin,
  },
  agent_continuous_call: {
    name: 'agent_continuous_call',
    label: 'חייגן שיחה מתמשכת',
    options: FIELDS_OPTIONS.radioOptions,
    componentType: ComponentType.RadioInput,
    uiConfig: { ...INITIAL_PERMISSION },
    permission: PermissionLevel.Admin,
  },
  agent_play_cust_number: {
    name: 'agent_play_cust_number',
    label: 'השמעת מספר טלפון לקוח',
    options: FIELDS_OPTIONS.radioOptions,
    componentType: ComponentType.RadioInput,
    uiConfig: { ...INITIAL_PERMISSION },
    permission: PermissionLevel.Support,
  },
  agent_play_num_requests: {
    name: 'agent_play_num_requests',
    label: 'השמעת כמות שיחות חוזרות לטיפול',
    options: FIELDS_OPTIONS.radioOptions,
    componentType: ComponentType.RadioInput,
    uiConfig: { ...INITIAL_PERMISSION },
    permission: PermissionLevel.Support,
  },
  incoming_call_permitted: {
    name: 'incoming_call_permitted',
    label: 'חייגן שיחה נכנסת',
    fieldKey: 'incoming_call_number',
    componentType: ComponentType.DynamicField,
    uiConfig: { ...INITIAL_PERMISSION, ...COMMON_DYNAMIC_FIELD_UI_CONFIG },
    permission: PermissionLevel.Admin,
  },

  // Customer - dialing
  cust_dial_prefix: {
    name: 'cust_dial_prefix',
    label: 'customer dial prefix',
    componentType: ComponentType.TextInput,
    uiConfig: { ...COMMON_INPUT_UI_CONFIG, ...INITIAL_PERMISSION },
    permission: PermissionLevel.Admin,
  },
  cust_max_attempts: {
    name: 'cust_max_attempts',
    label: 'כמות נסיונות חיוג ללקוח',
    options: FIELDS_OPTIONS.scaleOptions,
    componentType: ComponentType.Select,
    uiConfig: { ...INITIAL_PERMISSION, ...COMMON_SELECT_UI_CONFIG },
    permission: PermissionLevel.Support,
  },
  cust_dial_interval: {
    name: 'cust_dial_interval',
    label: 'זמן לאיתור לקוח בין נסיונות חיוג (דקות)',
    componentType: ComponentType.TextInput,
    uiConfig: { ...COMMON_INPUT_UI_CONFIG, ...INITIAL_PERMISSION },
    permission: PermissionLevel.Support,
  },
  cust_max_ringing: {
    name: 'cust_max_ringing',
    label: 'זמן חיוג מירבי ללקוח (שניות)',
    componentType: ComponentType.TextInput,
    uiConfig: { ...COMMON_INPUT_UI_CONFIG, ...INITIAL_PERMISSION },
    permission: PermissionLevel.Admin,
  },
  cust_min_duration: {
    name: 'cust_min_duration',
    label: 'מינימום זמן לשיחה אמיתית (שניות)',
    componentType: ComponentType.TextInput,
    uiConfig: { ...COMMON_INPUT_UI_CONFIG, ...INITIAL_PERMISSION },
    permission: PermissionLevel.Admin,
  },
  cust_approval_key: {
    name: 'cust_approval_key',
    label: 'ספרה לקבלת שיחה',
    options: FIELDS_OPTIONS.approvalKeyOptions,
    componentType: ComponentType.Select,
    uiConfig: { ...COMMON_SELECT_UI_CONFIG, ...INITIAL_PERMISSION },
    permission: PermissionLevel.Admin,
  },
  cust_prompt: {
    name: 'cust_prompt',
    label: 'טעינת קובץ קולי להשמעה ללקוח לפני קבלת שיחה',
    componentType: ComponentType.FileInput,
    uiConfig: { ...INITIAL_PERMISSION },
    fileType: FileType.audio,
    permission: PermissionLevel.Support,
  },

  // Customer - sms
  sms_content_type: {
    name: 'sms_content_type',
    label: 'בחירת תוכן סמס',
    options: FIELDS_OPTIONS.smsContentOptions,
    componentType: ComponentType.Select,
    uiConfig: { ...INITIAL_PERMISSION, ...COMMON_SELECT_UI_CONFIG },
    permission: PermissionLevel.Customer,
  },
  sms_text_at_start: {
    name: 'sms_text_at_start',
    label: 'תוכן סמס מאשר',
    componentType: ComponentType.TextInput,
    uiConfig: { ...COMMON_INPUT_UI_CONFIG, ...INITIAL_PERMISSION, multiline: true },
    permission: PermissionLevel.Customer,
  },
  sms_text_between_attempts: {
    name: 'sms_text_between_attempts',
    label: 'תוכן סמס בין נסיונות חיוג',
    componentType: ComponentType.TextInput,
    uiConfig: { ...COMMON_INPUT_UI_CONFIG, ...INITIAL_PERMISSION, multiline: true },
    permission: PermissionLevel.Customer,
  },
  sms_text_at_failure: {
    name: 'sms_text_at_failure',
    label: 'תוכן סמס לאחר נסיון אחרון',
    componentType: ComponentType.TextInput,
    uiConfig: { ...COMMON_INPUT_UI_CONFIG, ...INITIAL_PERMISSION, multiline: true },
    permission: PermissionLevel.Customer,
  },

  // No replay
  create_request_on_abandon: {
    name: 'create_request_on_abandon',
    label: 'הפעלת שיחה חוזרת אוטומטית ללקוח נוטש',
    options: FIELDS_OPTIONS.radioOptions,
    componentType: ComponentType.RadioInput,
    uiConfig: { ...INITIAL_PERMISSION },
    permission: PermissionLevel.Support,
  },
  min_time_for_abandon: {
    name: 'min_time_for_abandon',
    label: 'זמן מינימלי להפעלת שיחה חוזרת בנטישה',
    componentType: ComponentType.TextInput,
    uiConfig: { ...COMMON_INPUT_UI_CONFIG, ...INITIAL_PERMISSION },
    permission: PermissionLevel.Support,
  },
  min_time_for_answer: {
    name: 'min_time_for_answer',
    label: 'זמן מינימלי לביטול שיחה חוזרת במענה',
    componentType: ComponentType.TextInput,
    uiConfig: { ...COMMON_INPUT_UI_CONFIG, ...INITIAL_PERMISSION },
    permission: PermissionLevel.Support,
  },
  delete_request_on_answer: {
    name: 'delete_request_on_answer',
    label: 'ביטול שיחה חוזרת במענה לשיחה נכנסת מלקוח',
    options: FIELDS_OPTIONS.radioOptions,
    componentType: ComponentType.RadioInput,
    uiConfig: { ...INITIAL_PERMISSION },
    permission: PermissionLevel.Support,
  },
  allow_change_mobile_number: {
    name: 'allow_change_mobile_number',
    label: 'חזרה על מספר נייד מזוהה ללקוח לאפשרות שינוי מספר',
    options: FIELDS_OPTIONS.radioOptions,
    componentType: ComponentType.RadioInput,
    uiConfig: { ...INITIAL_PERMISSION },
    permission: PermissionLevel.Support,
  },
  allow_change_landline_number: {
    name: 'allow_change_landline_number',
    label: 'חזרה על מספר נייח מזוהה ללקוח לאפשרות שינוי מספר',
    options: FIELDS_OPTIONS.radioOptions,
    componentType: ComponentType.RadioInput,
    uiConfig: { ...INITIAL_PERMISSION },
    permission: PermissionLevel.Support,
  },
  approve_request_created: {
    name: 'approve_request_created',
    label: 'השמעת הודעת "בקשתך נקלטה בהצלחה" ללקוח לאחר בקשת שיחה חוזרת',
    options: FIELDS_OPTIONS.radioOptions,
    componentType: ComponentType.RadioInput,
    uiConfig: { ...INITIAL_PERMISSION },
    permission: PermissionLevel.Customer,
  },
  cust_request_created: {
    name: 'cust_request_created',
    label: '"טעינת קובץ קולי להשמעה ללקוח "בקשתך נקלטה בהצלחה',
    componentType: ComponentType.FileInput,
    uiConfig: { ...INITIAL_PERMISSION },
    fileType: FileType.audio,
    permission: PermissionLevel.Support,
  },

  // Reports
  // UI field name - depending on selected option, the data will be saved in different backend field: sms_report_sender_name, mail_report_sender_name
  report_type: {
    name: 'report_type',
    label: 'בחירת סוג דיווח',
    options: FIELDS_OPTIONS.reportTypeOptions,
    componentType: ComponentType.Select,
    uiConfig: { ...INITIAL_PERMISSION, ...COMMON_SELECT_UI_CONFIG },
    permission: PermissionLevel.Customer,
  },
  sms_report_sender_name: {
    name: 'sms_report_sender_name',
    label: 'שם שולח סמס',
    componentType: ComponentType.TextInput,
    uiConfig: { ...INITIAL_PERMISSION, ...COMMON_INPUT_UI_CONFIG, className: 'PlainText', disabled: true },
    permission: PermissionLevel.Customer,
  },
  mail_report_sender_name: {
    name: 'mail_report_sender_name',
    label: 'שם שולח מייל',
    componentType: ComponentType.TextInput,
    uiConfig: { ...INITIAL_PERMISSION, ...COMMON_INPUT_UI_CONFIG, className: 'PlainText', disabled: true },
    permission: PermissionLevel.Customer,
  },
  sms_report_destination_numbers: {
    name: 'sms_report_destination_numbers',
    label: 'מספר לשליחה',
    fieldKey: 'sms_report_destination_number',
    componentType: ComponentType.DynamicField,
    uiConfig: { ...INITIAL_PERMISSION, ...COMMON_DYNAMIC_FIELD_UI_CONFIG },
    permission: PermissionLevel.Customer,
  },
  sms_report_text: {
    name: 'sms_report_text',
    label: 'תוכן סמס',
    componentType: ComponentType.TextInput,
    uiConfig: { ...COMMON_INPUT_UI_CONFIG, ...INITIAL_PERMISSION },
    permission: PermissionLevel.Customer,
  },
  mail_report_destination_addresses: {
    name: 'mail_report_destination_addresses',
    label: 'מייל לשליחה',
    fieldKey: 'mail_report_destination_address',
    componentType: ComponentType.DynamicField,
    uiConfig: { ...INITIAL_PERMISSION, ...COMMON_DYNAMIC_FIELD_UI_CONFIG },
    permission: PermissionLevel.Customer,
  },
  mail_report_title: {
    name: 'mail_report_title',
    label: 'כותרת מייל',
    componentType: ComponentType.TextInput,
    uiConfig: { ...COMMON_INPUT_UI_CONFIG, ...INITIAL_PERMISSION },
    permission: PermissionLevel.Customer,
  },
  mail_report_text: {
    name: 'mail_report_text',
    label: 'תוכן מייל',
    componentType: ComponentType.TextInput,
    uiConfig: { ...COMMON_INPUT_UI_CONFIG, ...INITIAL_PERMISSION },
    permission: PermissionLevel.Customer,
  },
};
