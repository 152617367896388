import moment from "moment";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { Button } from "../../../design/button";
import { Spinner } from "../../../design/spinner/spinner";
import { useBusinessNumberEventCollection } from "../../../api/use-business-number";
import styles from "./event-list.module.scss";

export function EventList() {
  const location = useLocation();
  const { number } = useParams<{ number: string }>();
  const navigate = useNavigate();
  const eventCollection = useBusinessNumberEventCollection(number!);

  // An event's date is currently a ~ seperated string. In order to sort and filter events according to their start date,
  // we've split the start date and end date and only address the former.
  const eventList = !eventCollection.data
    ? []
    : eventCollection.data
      .sort((a, b) => new Date(a.date.split('~')[0]).getTime() - new Date(b.date).getTime())
      .filter(event => moment(event.date.split('~')[0]).isSameOrAfter(moment(), 'day'));

  const handleCreateEvent = () => {
    navigate(`${location.pathname}/events/new`);
  };

  return (
    <div className={styles.events}>
      <h2 className={styles.title}>חגים ואירועים</h2>
      {eventCollection.loading ? (
        <Spinner />
      ) : (
        <>
          {eventList.length > 0 ? (
            <ul className={styles.list}>
              {eventCollection.data &&
                eventList.map((event) => {
                  const [startDate, endDate] = event.date.split('~');
                  const isClosedAllDay = !event.openAt && !event.closeAt;
                  return (
                    <li className={styles.item} key={event.id}>
                      <div className={styles.details}>
                        <span className={styles.eventName}>{event.name}</span>
                        <span className={styles.date}>
                          {
                            startDate === endDate ?
                              <>{moment(startDate).format("יום dddd, DD MMMM")} - </>
                              :
                              <>{moment(startDate).format("DD MMMM")} - {moment(endDate).format("DD MMMM")} - </>
                          }
                          {!isClosedAllDay
                            ? `${event.openAt}-${event.closeAt}`
                            : "סגור"}
                        </span>
                      </div>
                      <div className={styles.actionArea}>
                        <Link
                          to={`${location.pathname}/events/${event.id}/edit`}
                          className={styles.link}
                        >
                          עריכה
                        </Link>
                        <Link
                          to={`${location.pathname}/events/${event.id}/delete`}
                          className={styles.link}
                        >
                          מחיקה
                        </Link>
                      </div>
                    </li>
                  );
                })}
            </ul>
          ) : (
            <div className={styles.zeroState}>
              כאן אפשר להגדיר שעות הפעילות מיוחדות לתקופת החגים ולאירועים אחרים. לאחר סיום האירוע המענה הטלפוני יחזור למצב הרגיל.
            </div>
          )}
          <Button onClick={handleCreateEvent}>אירוע חדש</Button>
        </>
      )}
    </div>
  );
}
