
import { DialogStateReturn } from 'reakit';
import { Dialog } from '../../design/dialog/dialog';
import { DialerEventType } from '../../api/dialers';
import { API } from 'aws-amplify';
import { useMe } from '../../api/use-me';
import { useLaunchers } from '../use-launchers';
import { useState } from 'react';



export default function EventDialog({ dialog, eventType, dialerId, dialerName, mutateEvents }: EventDialogProps) {
  const [error, setError] = useState<boolean>(false);
  const { data: user, loading: userLoading } = useMe();
  const isMale = user?.gender === 'male';

  const dialogTitle = `אירוע ${eventType === 'emergency' ? 'אמת' : 'תרגיל'}`;
  const DIALOG_CONTENT = eventType === 'emergency' ?
    `הפעלת אירוע אמת תיכנס לתוקף באופן מיידי.
  הכוננים יקבלו התראת אמת מ${dialerName}. ניתן לבטל אירוע אמת בעת הקשה על “ביטול אירוע” במסך הגדרות קבוצת הקריאה.
  
  האם ${isMale ? 'אתה' : 'את'} ${isMale ? 'בטוח' : 'בטוחה'} שברצונך להפעיל אירוע אמת?`
    : `הפעלת אירוע תרגיל תיכנס לתוקף באופן מיידי.
  הכוננים יקבלו התראת תרגיל מ${dialerName}. ניתן לבטל אירוע תרגיל בעת הקשה על “ביטול אירוע” במסך הגדרות קבוצת הקריאה.
  
  האם ${isMale ? 'אתה' : 'את'} ${isMale ? 'בטוח' : 'בטוחה'} שברצונך להפעיל תרגיל?`;
  const UNAUTHORIZED_TEXT = `אינך מורשה להקפיץ את קבוצת הכוננות ${dialerName}`;
  const { data: launchers, loading: launchersLoading } = useLaunchers();
  const [loading, setLoading] = useState<boolean>(false);
  const currentLauncher = launchers?.find(launcher => launcher.user_uuid === user?.id && launcher.dial_groups.includes(dialerId));
  const authorizedToLaunchEvent = currentLauncher !== undefined;

  const handleClose = () => {
    setError(false);
    dialog.hide();
  }

  const handleLaunchEvent = async () => {
    try {
      setLoading(true);
      await API.post('emergencyDialerApi',
        `/launch-event?domain_name=${user?.domain.name}&event_type=${eventType}&dialer_id=${dialerId}&launcher_uuid=${currentLauncher?.launcher_uuid || ''}`, {
        body: {}
      });
      await new Promise(resolve => setTimeout(() => resolve(mutateEvents()), 1500));
      handleClose();
    } catch (err) {
      console.error(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Dialog {...dialog}
      variant={eventType === 'emergency' ? 'caution' : 'form'}
      loading={userLoading || launchersLoading || loading}
      header={dialogTitle}
      submitLabel={authorizedToLaunchEvent ? 'אישור והפעלת אירוע' : 'חזרה'}
      cancelLabel={'ביטול'}
      hideOnClickOutside={false}
      onSubmit={() => authorizedToLaunchEvent ? handleLaunchEvent() : dialog.hide()}
      onClose={handleClose}
      aria-label={dialogTitle}
    >
      <section>
        {
          authorizedToLaunchEvent ? DIALOG_CONTENT : UNAUTHORIZED_TEXT
        }
      </section>
    </Dialog>
  )
}
interface EventDialogProps {
  dialog: DialogStateReturn;
  eventType: DialerEventType;
  dialerId: number;
  dialerName: string;
  mutateEvents: () => void;
}