import moment from 'moment';
import { Tag, TagColor } from '../design/tag/tag';
import { InvitationResource } from "../api/types";
import { WarningIcon } from '../design/icons/warning-icon';
import styles from './invitation-status.module.scss';

export function InvitationStatus({ invitation }: InvitationStatusProps) {

  return (
    <div>
      <Tag label={statusDictionary[invitation.status].label} variant="filled" color={statusDictionary[invitation.status].color} />
      <div className={styles.statusDescription}>
        {statusDictionary[invitation.status].toDate(invitation)}
        {
          invitation.status === "PENDING" && <ExpirationWarning expiresAt={invitation.expiresAt} />
        }
      </div>
    </div>
  );
}

function ExpirationWarning({ expiresAt }: ExpirationWarningProps) {
  const timeLeftHours = moment(expiresAt).diff(Date.now(), 'hours');
  const text = timeLeftHours > 24
    ? ` נשארו ${Math.round(timeLeftHours / 24)} ימים`
    : ` נשארו ${timeLeftHours} שעות`

  return (
    <span className={styles.remainsSection}>&nbsp;<span>{text}</span> <WarningIcon size={20} className={styles.warningIcon} /></span>
  )
}

interface StatusDictionaryInterface {
  [key: string]: {
    label: string,
    color: TagColor,
    toDate: (invitation: InvitationResource) => string
  }
}

const statusDictionary: StatusDictionaryInterface = {
  PENDING: {
    label: 'ממתינה',
    color: 'neutral',
    toDate: (invitation: InvitationResource) => moment(invitation.expiresAt).format('תוקף ההזמנה יפוג ביום dddd, DD/MM/YYYY בשעה HH:mm')
  },
  ACCEPTED: {
    label: 'אושרה',
    color: 'accent',
    toDate: (invitation: InvitationResource) => moment(invitation.acceptedAt).format(`אושרה ביום dddd, DD/MM/YYYY בשעה HH:mm`)
  },
  EXPIRED: {
    label: 'לא תקפה',
    color: 'caution',
    toDate: (invitation: InvitationResource) => moment(invitation.expiresAt).format('תוקף ההזמנה פג ביום dddd, DD/MM/YYYY בשעה HH:mm')
  }
}

interface InvitationStatusProps {
  invitation: InvitationResource
}

interface ExpirationWarningProps {
  expiresAt: number;
}