import { Field, FieldProps } from "formik";
import { DialogStateReturn } from "reakit";
import { Dialog } from "../../../../design/dialog/dialog";
import { Select } from "../../../../design/form/select";
import { useState } from "react";
import { useMe } from "../../../../api/use-me";
import { API } from "aws-amplify";
import { SelfManagedResource } from "../self-managed-resources";
import { SelfManagedResourceConfig } from "./self-managed-resource-config";
import styles from "./config-resource-dialog.module.scss";
import { useBusinessNumberCollection } from "../../../../api/use-business-number";
import { useExtensions } from "../../../../api/use-extensions";
import { useGroups } from "../../../../api/use-groups";
import { Checkbox } from "../../../../design/checkbox/checkbox";
import { DOMAIN_FEATURES_LIST, DomainFeatures, DomainFeature, DOMAIN_FEATURES_MAP } from "../../../../api/types";

export const ConfigResourceDialog = ({ dialog, activeConfig, onActiveConfigChange, allResources, activeResources }: ConfigResourceDialogProps) => {
  const [loading, setLoading] = useState(false);
  const user = useMe();
  const { mutate: numbersMutate } = useBusinessNumberCollection();
  const { mutate: extensionMutate } = useExtensions();
  const { mutate: groupsMutate } = useGroups(user.data?.domain.id);

  const handleSubmit = async (values: { destination?: string[], numbers?: string[], extensions?: string[], groups?: string[], domainFeatures: string[] }) => {
    
    const hasFeature = (featureName: string): boolean => 
      values?.domainFeatures.find(feature => feature === featureName) !== undefined || false;

    const newDomainFeatures: DomainFeatures = 
    Object.fromEntries(
      DOMAIN_FEATURES_LIST.map(
        feature => [feature, hasFeature(feature)])
    ) as DomainFeatures;

    const urlName = process.env.REACT_APP_WEB_API_NAME!;
    const endpoint = `/users/${user?.data?.id}/domain`;
    const body = {
      id: user.data?.domain.id,
      offDestination: values.destination,
      selfManagedNumbers: values.numbers,
      selfManagedExtensions: values.extensions,
      selfManagedGroups: values.groups,
      domainFeatures: newDomainFeatures
    }
    try {
      setLoading(true);
      await API.patch(urlName, endpoint, { body: body });
      user?.mutate();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      dialog.hide();
    }
  }

  const handleSelectorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onActiveConfigChange(event.target.value as ConfigResourceType);
  }

  const renderForm = () => {
    switch (activeConfig) {
      case "numbers":
        return (
          <>
            <label className={styles.closedDestination}>יעד סגור</label>
            <Field name="destination">
              {({ field }: FieldProps) => (
                <Select {...field}>
                  <option key="blank_option" value=""></option>
                  {allResources.destinations?.map(destination => (
                    <option key={`${destination.id}`} value={destination.id}>
                      {destination.number} {destination.label}
                    </option>
                  ))}
                </Select>
              )}
            </Field>
            <SelfManagedResourceConfig
              heading="מספרי טלפון בניהול הלקוח"
              resource={allResources.numbers || []}
              resourceName="numbers"
              mutate={numbersMutate} />
          </>
        )
      case "extensions":
        return <SelfManagedResourceConfig
          heading="שלוחות בניהול הלקוח"
          resource={allResources.extensions || []}
          resourceName="extensions"
          mutate={extensionMutate} />
      case "groups":
        return <SelfManagedResourceConfig
          heading="קבוצות בניהול הלקוח"
          resource={allResources.groups || []}
          resourceName="groups"
          mutate={groupsMutate} />
      case "domainFeatures":
        return (
          <>
            <h3>בחירת יכולות עבור הלקוחות בדומיין</h3>
            <ul className={styles.domainFeaturesList}>
            {
              Object.entries(DOMAIN_FEATURES_MAP).map(([id, label]) => <li key={id}>
              <Field
                type="checkbox"
                name={"domainFeatures"}
                value={id}
              >
                {({ field }: FieldProps) => (<Checkbox {...field} title={label} />)}
              </Field>
            </li>)
            }
            </ul>
          </>
        )
    }
  }
  const domainFeatures = Object.entries(activeResources.domainFeatures || {}).filter(([_, fValue]) => fValue).map(([fName, _]) => fName);

  return (
    <Dialog {...dialog}
      variant="form"
      header="הגדרות ניהול עצמי"
      onSubmit={handleSubmit}
      loading={loading}
      aria-label='הגדרות ניהול עצמי'
      initialValues={{
        destination: activeResources.destinations?.map(destination => destination.id),
        numbers: activeResources.numbers?.map(number => number.id),
        extensions: activeResources.extensions?.map(extension => extension.id),
        groups: activeResources.groups?.map(group => group.id),
        domainFeatures
      }}
    >
      <div className={styles.resourceSelector}>
        <Select onChange={handleSelectorChange} value={activeConfig}>
          <option value="numbers">מספרים</option>
          <option value="extensions">שלוחות</option>
          <option value="groups">קבוצות</option>
          <option value="domainFeatures">יכולות לקוח</option>
        </Select>
      </div>
      <div>
        {renderForm()}
      </div>
    </Dialog >
  )
}

export interface ConfigResourceDialogProps {
  dialog: DialogStateReturn;
  activeConfig: ConfigResourceType;
  onActiveConfigChange: (activeConfig: ConfigResourceType) => void;
  allResources: {
    numbers?: SelfManagedResource[];
    destinations?: SelfManagedResource[];
    extensions?: SelfManagedResource[];
    groups?: SelfManagedResource[];
    domainFeatures?: DomainFeatures;
  };
  activeResources: {
    numbers?: SelfManagedResource[];
    destinations?: SelfManagedResource[];
    extensions?: SelfManagedResource[];
    groups?: SelfManagedResource[];
    domainFeatures?: DomainFeatures;
  };
}

export type ConfigResourceType = "numbers" | "extensions" | "groups" | "domainFeatures";