import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "../../design/button";
import { Table } from "../../design/table/table";
import {
  AppLayout,
  AppLayoutContainer,
  AppLayoutHeader,
  AppLayoutTitle,
  useMediaQuery,
} from "../../layouts/app-layout/app-layout";
import { Page } from "../../page";

import styles from "./events-log.module.scss";
import { useURLParams } from "../../app/use-url-params";
import { Filter } from "../../design/filter/filter";
import {
  DialGroup,
  DialerEvent,
  DialerEventStatus,
  DialerEventType,
  TranslateEventStatus,
} from "../../api/dialers";
import { Field } from "formik";
import { Checkbox } from "../../design/checkbox/checkbox";
import { DateRange } from "../../design/date-range/date-range";
import { Reset } from "../../design/icons";
import { mobileQuery } from "../../theme/media";
import { useEffect, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import { Tag } from "../../design/tag/tag";
import { ButtonProps } from "../../design/button/button";
import ButtonBar from "../../design/button-bar/button-bar";
import { useDialerEvents } from "../use-dialer-event";
import { useDialGroups } from "../use-dial-groups";
import { Spinner } from "../../design/spinner/spinner";
import { exportEventsHistoryReport } from "../../api/export-report";
import { Search } from "../../design/search/search";
import { Column } from "../../design/table/types";

const DEFAULT_NUM_OF_MONTHS = 2;
const filterKeys = ["dial_groups", "type", "launchers", "status"];

export default function EventsLogPage() {
  const [downloadingReport, setDownloadingReport] = useState<boolean>(false);
  const params = useParams<any>()["*"]?.replace(/^\/|\/$/g, "");
  const url = useURLParams({ path: `/emergency-dialer/events-log`, params });
  const handleExportReport = async () => {
    try {
      setDownloadingReport(true);
      await exportEventsHistoryReport(url.urlParams);
    } catch (err) {
      console.error(err);
    } finally {
      setDownloadingReport(false);
    }
  };

  return (
    <Page name='Events Log Page'>
      <AppLayout>
        <AppLayoutContainer>
          <AppLayoutHeader className={styles.appLayoutHeader}>
            <AppLayoutTitle>יומן אירועים</AppLayoutTitle>
            <Button
              variant='link'
              loading={downloadingReport}
              className={styles.exportExcelButton}
              onClick={handleExportReport}
            >
              ייצוא לאקסל
            </Button>
          </AppLayoutHeader>
          <main>
            <EventsList />
          </main>
        </AppLayoutContainer>
      </AppLayout>
    </Page>
  );
}

const EventsList = () => {
  const navigate = useNavigate();
  const params = useParams<any>()["*"]?.replace(/^\/|\/$/g, "");
  const url = useURLParams({ path: `/emergency-dialer/events-log`, params });
  const { data: allEvents, loading: eventsLoading } = useDialerEvents(
    url.urlParams.from,
    url.urlParams.to
  );
  const [searchString, setSearchString] = useState("");
  const { data: dialGroups, loading: dialGroupsLoading } = useDialGroups();
  const activeFilteredDialGroups = url.urlParams.dial_groups?.split(",");
  const activeFilteredStatus = url.urlParams.status?.split(",");
  const activeFilteredTypes = url.urlParams.type?.split(",");
  const activeFilteredLaunchers = url.urlParams.launchers?.split(",");

  if (eventsLoading || dialGroupsLoading) {
    return <Spinner />;
  }

  const filterDialGroups = (event: DialerEvent): boolean => {
    return activeFilteredDialGroups?.some((dialGroupId: string) => {
      return String(event.dialer_id) === dialGroupId;
    });
  };

  const filterStatus = (event: DialerEvent): boolean => {
    return activeFilteredStatus.some((status: DialerEventStatus) => {
      return event.event_status === status;
    });
  };

  const filterType = (event: DialerEvent): boolean => {
    return activeFilteredTypes.some((type: DialerEventType) => {
      return event.event_type && type === event.event_type;
    });
  };

  const filterLaunchers = (event: DialerEvent): boolean => {
    return activeFilteredLaunchers.some((launcherUuid: string) => {
      return event.launcher_uuid === launcherUuid;
    });
  };

  const filteredEvents = allEvents?.filter((event) => {
    let isFiltered = true;
    if (url.urlParams.status) {
      isFiltered &&= filterStatus(event);
    }
    if (url.urlParams.type) {
      isFiltered &&= filterType(event);
    }
    if (url.urlParams.launchers) {
      isFiltered &&= filterLaunchers(event);
    }
    if (url.urlParams.dial_groups) {
      isFiltered &&= filterDialGroups(event);
    }
    return isFiltered;
  });

  const refinedData = (filteredEvents || [])
    ?.sort((a, b) => new Date(b.start_date).getTime() - new Date(a.start_date).getTime())
    ?.map((event) => ({
      id: `${event.event_id}`,
      date: `${event.start_date}`,
      event_id: event.event_id,
      name: event.dialer_name,
      type: event.event_type === "emergency" ? "אמת" : "תרגיל",
      status: TranslateEventStatus[event.event_status as DialerEventStatus],
      num_of_callees: event.num_of_callees,
      num_of_approvals: event.num_of_approvals,
      launcher_name: event.launcher_name,
      menu: (
        <Button
          variant='link'
          onClick={() => {
            navigate(`/emergency-dialer/event/${event.event_id}`, { state: { urlParams: params } });
          }}
        >
          לפרטי אירוע
        </Button>
      ),
    }))
    ?.filter((row) => {
      return (
        row.id.includes(searchString) ||
        row.date.includes(searchString) ||
        row.event_id.toString().includes(searchString) ||
        row.name.includes(searchString) ||
        row.type.includes(searchString) ||
        row.status.includes(searchString) ||
        row.num_of_callees.toString().includes(searchString) ||
        row.num_of_approvals.toString().includes(searchString) ||
        row.launcher_name.includes(searchString)
      );
    });
  const columns: Array<Column<typeof refinedData[0], keyof typeof refinedData[0]>> = [
    {
      key: "date",
      className: styles.date,
      header: "תאריך",
      render(row) {
        return (
          <div>
            <span>
              {moment(row.date).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                ? "היום"
                : moment(row.date).isBetween(moment().subtract(7, "d"), moment())
                ? moment(row.date).format("dddd")
                : moment(row.date).format("DD.MM.YYYY")}
            </span>{" "}
            &nbsp;
            <span>{moment(row.date).format("HH:mm")}</span>
          </div>
        );
      },
    },
    {
      key: "event_id",
      className: styles.number,
      header: "אירוע",
    },
    {
      key: "name",
      className: styles.name,
      header: "שם קבוצת קריאה",
    },
    {
      key: "type",
      className: styles.type,
      header: "סוג אירוע",
    },
    {
      key: "status",
      className: styles.status,
      header: "סטטוס אירוע",
      render(row) {
        if (row.status === TranslateEventStatus["active"]) {
          return <div className={styles.active}>פעיל</div>;
        }
        return <div>{row.status}</div>;
      },
    },
    {
      key: "num_of_callees",
      className: styles.calleesCount,
      header: "מספר כוננים",
    },
    {
      key: "num_of_approvals",
      className: styles.rsvpCount,
      header: "מספר אישורי הגעה",
    },
    {
      key: "launcher_name",
      className: styles.launcher,
      header: "מפעיל",
    },
    {
      key: "menu",
      className: styles.tableMenu,
    },
  ];

  return (
    <div>
      <Table columns={columns} data={refinedData}>
        <Filters setSearchString={setSearchString} dialGroups={dialGroups} events={allEvents} />
      </Table>
    </div>
  );
};

const ALL_STATUSES: DialerEventStatus[] = ["active", "canceled", "expired", "completed"];
interface FiltersProps {
  dialGroups: DialGroup[] | undefined;
  events: DialerEvent[] | undefined;
  setSearchString: (searchString: string) => void;
}
const Filters = ({ dialGroups, events, setSearchString }: FiltersProps) => {
  const params = useParams<any>()["*"]?.replace(/^\/|\/$/g, "");
  const url = useURLParams({ path: `/emergency-dialer/events-log`, params });
  const isMobile = useMediaQuery(mobileQuery);
  const { pathname } = useLocation();
  const isExact = pathname === "/emergency-dialer/events-log";

  // if no date is selected - set last week by default
  useEffect(() => {
    if (isExact || !url.urlParams.from || !url.urlParams.to) {
      const range = {
        from: moment().subtract(1, "week").format("YYYY-MM-DD").toString(),
        to: moment().format("YYYY-MM-DD").toString(),
      };

      setDateRange(range);
      url.setURLParams(range);
    }
  }, [isExact, url.urlParams.from, url.urlParams.to, url.setURLParams]);

  const [dateRange, setDateRange] = useState({ from: url.urlParams.from, to: url.urlParams.to });
  const [focusedInput, setFocusedInput] = useState<"startDate" | "endDate" | null>(null);

  const initialSelectedStatuses = url.urlParams.status;
  const [filteredStatuses, setFilteredStatuses] = useState<string[]>(
    initialSelectedStatuses?.split(",") || []
  );

  const isTagSelected = (status: DialerEventStatus) => filteredStatuses.some((s) => s === status);

  const handleTagClick = (status: DialerEventStatus) => {
    if (isTagSelected(status)) {
      const newEventStatuses = filteredStatuses.filter((s) => s !== status);
      setFilteredStatuses(newEventStatuses);
      url.setURLParam("status", newEventStatuses.join(","));
    } else {
      const newEventStatuses = [...filteredStatuses, status];
      setFilteredStatuses(newEventStatuses);
      url.setURLParam("status", newEventStatuses.join(","));
    }
  };

  const statuses: { name: DialerEventStatus; count: number }[] = ALL_STATUSES.map((status) => ({
    name: status,
    count:
      events?.reduce((acc, curr) => {
        return curr.event_status === status ? acc + 1 : acc;
      }, 0) || 0,
  }));

  const tagButtons = statuses.map((status) => ({
    id: status.name,
    label: TranslateEventStatus[status.name],
    component: (
      <Tag
        key={status.name}
        label={`${TranslateEventStatus[status.name]} ${status.count}`}
        variant='filled'
        color='neutral'
        className={classNames(styles.tag, { [styles.selectedTag]: isTagSelected(status.name) })}
        onClick={() => handleTagClick(status.name)}
      />
    ),
  }));

  const buttons = [
    {
      id: "all",
      label: `סה"כ ${events?.length || 0}`,
      component: (
        <Tag
          key={"all"}
          label={`סה"כ ${events?.length || 0}`}
          variant='filled'
          color='neutral'
          className={classNames(styles.tag, {
            [styles.selectedTag]: filteredStatuses?.length >= 4 || filteredStatuses.length === 0,
          })}
          onClick={() => {
            url.removeURLParam("status");
            setFilteredStatuses([]);
          }}
        />
      ),
    },
    ...tagButtons,
  ];

  const launchers = events?.map((event) => ({
    launcher_uuid: event.launcher_uuid,
    launcher_name: event.launcher_name,
  }));
  const distinctLauncherNames = launchers?.filter(
    (launcher, index) =>
      launchers.findIndex((obj) => obj.launcher_uuid === launcher.launcher_uuid) === index
  );

  const numFilteredDialGroups = url.urlParamsMultiValue.dial_groups?.filter((dialGroupId: string) =>
    dialGroups?.find((dg) => dg.dialer_id === parseInt(dialGroupId))
  )?.length;
  const numFilteredLaunchers = url.urlParamsMultiValue.launchers?.filter((launcherUuid: string) =>
    distinctLauncherNames?.find((l) => l.launcher_uuid === launcherUuid)
  )?.length;

  const areFiltersActive =
    Object.keys(url.urlParams).filter((param) => filterKeys.includes(param)).length > 0;

  return (
    <div>
      <div className={styles.topFilters}>
        <Search
          className={styles.search}
          placeholder='חפש שם או מספר אירוע, מפעיל...'
          onChange={(e) => setSearchString(e.target.value)}
        />
        <div className={styles.dateRange}>
          <DateRange
            customArrowIcon={<Reset size={16} />}
            displayFormat='DD MMMM'
            numberOfMonths={isMobile ? 1 : DEFAULT_NUM_OF_MONTHS}
            keepOpenOnDateSelect
            noBorder
            renderCalendarInfo={() => (
              <button
                type='button'
                disabled={!dateRange.from || !dateRange.to}
                className={classNames(styles.filterFormButton, styles.primary)}
                onClick={() => {
                  setFocusedInput(null);
                  url.setURLParams(dateRange);
                }}
                style={{
                  marginBlockEnd: "1rem",
                  marginInlineEnd: isMobile ? "" : "1.5rem",
                  fontSize: isMobile ? "0.875rem" : "",
                }}
              >
                עדכון
              </button>
            )}
            showDefaultInputIcon
            minimumNights={0}
            startDate={moment(dateRange.from)}
            startDatePlaceholderText='מתאריך'
            startDateId='emergencyDialerEventsLogStartDate'
            endDate={moment(dateRange.to)}
            endDatePlaceholderText='עד תאריך'
            endDateId='emergencyDialerEventsLogEndDate'
            focusedInput={focusedInput || null}
            onFocusChange={(input) => setFocusedInput(input)}
            isOutsideRange={(day) => {
              return day.startOf("day").isAfter(moment());
            }}
            onDatesChange={({ startDate, endDate }) => {
              setDateRange({
                from: startDate?.format("YYYY-MM-DD").toString(),
                to:
                  endDate?.format("YYYY-MM-DD").toString() ||
                  startDate?.format("YYYY-MM-DD").toString(),
              });
            }}
          />
        </div>
      </div>
      <div className={styles.filtersContainer}>
        <div className={styles.filters}>
          <div className={styles.formFilters}>
            <Filter /* Dial Groups filter */
              active={url.urlParams.dial_groups}
              badge={
                numFilteredDialGroups ? (
                  <div className={styles.filterBadge}>{numFilteredDialGroups}</div>
                ) : undefined
              }
              initialValues={{ dial_groups: url.urlParamsMultiValue.dial_groups }}
              label='קבוצת קריאה'
              onReset={() => url.removeURLParam("dial_groups")}
              onSubmit={(values) => {
                const value = values.dial_groups.join(",");
                url.setURLParam("dial_groups", value);
              }}
            >
              <div>
                {dialGroups?.map((dialGroup) => (
                  <Field
                    key={dialGroup.dialer_id}
                    component={(props: any) => (
                      <Checkbox
                        title={dialGroup.dialer_id + " - " + dialGroup.name}
                        {...props.field}
                        style={{ marginBlockEnd: "8px" }}
                      />
                    )}
                    name='dial_groups'
                    type='checkbox'
                    value={`${dialGroup.dialer_id}`}
                  />
                ))}
              </div>
            </Filter>

            <Filter /* Event type filter */
              active={url.urlParams.type}
              badge={
                url.urlParamsMultiValue.type && url.urlParamsMultiValue.type?.length ? (
                  <div className={styles.filterBadge}>{url.urlParamsMultiValue.type.length}</div>
                ) : undefined
              }
              initialValues={{ type: url.urlParamsMultiValue.type }}
              label='סוג אירוע'
              onReset={() => url.removeURLParam("type")}
              onSubmit={(values) => {
                const value = values.type.join(",");
                url.setURLParam("type", value);
              }}
            >
              <div>
                <Field
                  component={(props: any) => (
                    <Checkbox title={"אמת"} {...props.field} style={{ marginBlockEnd: "8px" }} />
                  )}
                  name='type'
                  type='checkbox'
                  value={"emergency"}
                />
                <Field
                  component={(props: any) => (
                    <Checkbox title={"תרגיל"} {...props.field} style={{ marginBlockEnd: "8px" }} />
                  )}
                  name='type'
                  type='checkbox'
                  value={"drill"}
                />
              </div>
            </Filter>

            <Filter /* Launcher filter */
              active={url.urlParams.launchers}
              badge={
                url.urlParamsMultiValue.launchers && numFilteredLaunchers ? (
                  <div className={styles.filterBadge}>{numFilteredLaunchers}</div>
                ) : undefined
              }
              initialValues={{ launchers: url.urlParamsMultiValue.launchers }}
              label='מפעיל'
              onReset={() => url.removeURLParam("launchers")}
              onSubmit={(values) => {
                const value = values.launchers.join(",");
                url.setURLParam("launchers", value);
              }}
            >
              <div>
                {distinctLauncherNames?.map((launcher) => (
                  <Field
                    key={launcher.launcher_uuid}
                    component={(props: any) => (
                      <Checkbox
                        title={launcher.launcher_name}
                        {...props.field}
                        style={{ marginBlockEnd: "8px" }}
                      />
                    )}
                    name='launchers'
                    type='checkbox'
                    value={`${launcher.launcher_uuid}`}
                  />
                ))}
              </div>
            </Filter>
            {areFiltersActive && (
              <button
                className={styles.resetFiltersButton}
                onClick={() => {
                  url.removeURLParams(filterKeys);
                  setFilteredStatuses([]);
                }}
              >
                איפוס
              </button>
            )}
          </div>

          <div className={styles.tagFiltersContainer}>
            <ButtonBar buttons={buttons} />
          </div>
        </div>
      </div>
    </div>
  );
};

interface Button extends ButtonProps {
  id: string;
  label: string;
}
