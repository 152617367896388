import React from 'react'
import { Spinner } from '../design/spinner'
import { AppLayoutContainer, AppLayoutHeader, AppLayoutTitle } from '../layouts/app-layout/app-layout'
import { Page } from '../page'
import styles from './loading-page.module.scss';

export const LoadingPage: React.FC<{ title?: string, text?: string }> = ({ title, text }) => {
  return (
    <Page name='Loading'>
      <div>
        <AppLayoutContainer>
          {
            title && (
              <AppLayoutHeader>
                <AppLayoutTitle>{ title }</AppLayoutTitle>
              </AppLayoutHeader>
            )
          }
        </AppLayoutContainer>
        <div className={styles.wrapper}>
          { text }
          <Spinner />
        </div>
      </div>
    </Page>
  )
}
