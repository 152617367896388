import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './date-range.scss';
import { DateRangePicker, DateRangePickerShape } from 'react-dates';



export const DateRange = (props: DateRangePickerShape) => (
  <DateRangePicker
    {...props}
    isRTL
    readOnly={true} // to prevent keyboard popup on mobile
  />
);
