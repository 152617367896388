import { Icon, IconProps } from "./icon";


export const Emergency = ({...props}: IconProps) => (
  <Icon 
    viewBox="0 0 21 16"
    fill="currentColor"
    {...props}
  >
    <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.93182 15.5V14.1875H6.3875L8.39205 8.12812C8.4875 7.85104 8.65852 7.625 8.90511 7.45C9.1517 7.275 9.43409 7.1875 9.75227 7.1875H12.9977C13.3159 7.1875 13.5983 7.275 13.8449 7.45C14.0915 7.625 14.2625 7.85104 14.358 8.12812L16.3625 14.1875H17.8182V15.5H4.93182ZM7.91477 14.1875H14.8352L12.9739 8.5H9.77614L7.91477 14.1875ZM10.6591 4.5625V0.625H12.0909V4.5625H10.6591ZM16.267 6.70625L15.2409 5.76562L18.2955 2.9875L19.2977 3.90625L16.267 6.70625ZM17.5795 10.9062V9.59375H21.875V10.9062H17.5795ZM6.48295 6.70625L3.45227 3.90625L4.45455 2.9875L7.50909 5.76562L6.48295 6.70625ZM0.875 10.9062V9.59375H5.17045V10.9062H0.875Z" fill="#C94236"/>
    </svg>
  </Icon>
)