import { PermissionLevel } from '../api/types';
import { CallbackDialer } from '../api/dialers';
import { ApiConfig, FileField, FileType } from '../globalTypes/types';
import { SnackbarVariant, TypographyVariant } from '../theme/muiTypes';
import { ControllerFieldState, ControllerRenderProps, FieldValues, UseFormStateReturn } from 'react-hook-form';
import { ComponentType, RadioOption, SelectOption } from '../formConfig/types';

type uuid = string;

export type RequestStatus = 'waiting' | 'active' | 'failed' | 'completed' | 'canceled';

export type RequestCompletionCode =
  | 'success'
  | 'expired'
  | 'too_many_attempts'
  | 'duplicate'
  | 'already_talked'
  | 'manually';

export type ResponseType = 'approve' | 'decline';

export type AttemptStatus =
  | 'dialing' // attempt is active
  | 'answered' // attempt ended successfully
  | 'failed'; // attempt failed with error

export type AttemptCompletionCode =
  // customer
  | 'customer_cannot_dial'
  | 'customer_no_answer'
  | 'customer_rejected'
  | 'customer_busy'
  | 'customer_ivr'
  | 'customer_fax'
  | 'customer_hangup'
  // agent
  | 'agent_cannot_dial'
  | 'agent_no_answer'
  | 'agent_rejected'
  | 'agent_busy'
  | 'agent_ivr'
  | 'agent_fax'
  | 'agent_hangup'
  // other
  | 'admin_success'
  | 'admin_error'
  | 'success'
  | 'failed';

export interface DialerAttempt {
  // general
  domain_uuid: uuid;
  attempt_uuid: uuid;
  request_uuid: uuid;
  call_uuid: uuid;
  attempt_status: AttemptStatus;
  completion_code?: AttemptCompletionCode | undefined;
  // dates
  start_date: Date;
  answer_date?: Date | undefined; // by customer
  end_date?: Date | undefined;
  // agent
  agent_number?: string | undefined;
  agent_name?: string | undefined;
  // other
  sms_text?: string | undefined;
  has_recording: boolean;
}

export interface DialerRequest {
  // general
  request_uuid: string;
  domain_uuid: string;
  domain_name?: string | undefined;
  dialer_profile_id: number;
  dialer_id: number;
  dialer_sms_profile_id: number;
  enabled: boolean;
  request_priority: number;
  business_number: string;
  // request transient state
  request_status: RequestStatus;
  completion_code?: RequestCompletionCode | undefined;
  in_call: boolean;
  locker?: string | undefined;
  // dates
  start_date: Date;
  start_date_no_time: string;
  last_attempt_date: Date;
  end_date?: Date | undefined;
  expire_date: Date;
  // customer
  max_attempts: number;
  num_attempts: number;
  dial_interval: number;
  caller_number?: string | undefined;
  return_number: string;
  return_number2?: string | undefined;
  return_number3?: string | undefined;
  // agent
  agent_max_attempts: number;
  agent_num_attempts: number;
  agent_dial_interval: number;
  agent_group_number?: string | undefined;
  agent_group_name?: string | undefined;
  // event & callee; for Emergency dialer
  event_id?: number | undefined;
  callee_uuid?: uuid | undefined;
  response_key?: number | undefined;
  response_type?: ResponseType | undefined;
  response_text?: string | undefined;
}

export interface RequestsFetcherReturnType {
  data: DialerRequest[];
  meta: {
    counts: {
      date: string;
      count: {
        total: number;
        waiting: number;
        active: number;
        completed: number;
        failed: number;
        canceled: number;
      };
    }[];
    pages: number;
  };
}

export interface Section {
  id: string;
  name: string;
}

/* ------------------------ Callback Dialer ---------------------- */

export type FieldName = keyof CallbackDialer;

export interface ControllerState<TFieldValues extends FieldValues> {
  field: ControllerRenderProps<FieldValues, CallbackFieldName>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<TFieldValues>;
}

export type HandleRemoveDynamicValue = (name: CallbackFieldName, fieldValueList: Record<'id', string>[]) => void;
export type HandleRemoveFile = (name: CallbackFieldName) => void;

export interface FieldSavingStatus {
  key: string;
  isOpen: boolean;
  variant: SnackbarVariant;
}

export interface FormSection {
  section: Section;
  apiConfig: ApiConfig;
}

export type PermissionLevelOption = {
  value: PermissionLevel;
  label: string;
};

export type FieldsOptions = {
  languageOptions: SelectOption[];
  callbackLimitOptions: SelectOption[];
  scaleOptions: SelectOption[];
  maxTimeOptions: SelectOption[];
  radioOptions: RadioOption[];
  speakerOptions: SelectOption[];
  smsContentOptions: SelectOption[];
  reportTypeOptions: SelectOption[];
  approvalKeyOptions: SelectOption[];
  permissionLevelOptions: PermissionLevelOption[];
};

export interface UIConfig {
  disabled?: boolean;
  className?: string;
  fullWidth?: boolean;
  multiline?: boolean;
  hasPermission: boolean;
  labelVariant?: TypographyVariant;
  valueVariant?: TypographyVariant;
}

export enum DynamicFieldVariant {
  chips = 'chips',
}

export type DynamicFieldUIConfig = UIConfig & { dynamicFieldVariant: DynamicFieldVariant };

export type FormFieldValue =
  | string
  | boolean
  | FileField
  | string[]
  | null
  | {
      interact_by_sms: boolean;
      interact_by_mail: boolean;
    };

export type CallbackFieldKey =
  | 'incoming_call_number'
  | 'blacklist_number'
  | 'sms_report_destination_number'
  | 'mail_report_destination_address';

export type CallbackFieldConfigBase = {
  name: CallbackFieldName;
  label: string;
  componentType: ComponentType;
  options?: SelectOption[] | RadioOption[];
  placeholder?: string;
  uiConfig: UIConfig;
  permission: PermissionLevel;
} & (
  | {
      componentType: ComponentType.FileInput;
      fileType: FileType;
    }
  | {
      componentType: Exclude<ComponentType, ComponentType.FileInput>;
      fileType?: never;
    }
);

export type CallbackFieldConfigWithFieldKey = CallbackFieldConfigBase & {
  componentType: ComponentType.DynamicField;
  fieldKey: CallbackFieldKey;
  uiConfig: DynamicFieldUIConfig;
};

export type CallbackFieldConfigWithoutFieldKey = CallbackFieldConfigBase & {
  componentType: Exclude<ComponentType, ComponentType.DynamicField>;
  fieldKey?: never;
  uiConfig: UIConfig;
};

export type CallbackFieldConfig = CallbackFieldConfigWithFieldKey | CallbackFieldConfigWithoutFieldKey;
export type CallbackFieldsConfig = Record<CallbackFieldName, CallbackFieldConfig>;

export interface ICallbackDialerForm {
  // UI - Filtering fields
  selectedPermission: PermissionLevel;
  // General
  dialer_id: number;
  enabled: boolean;
  language: string;
  speaker: string;
  calls_recorded: boolean;
  international_calls_permitted: boolean;
  blacklist?: Array<{ blacklist_number?: string | undefined }> | undefined;
  priority: number;
  max_active: number;
  max_request_age: number;
  business_number: string;
  business_number_for_sms: string;

  // Agent
  active_now: boolean;
  agent_max_attempts: number;
  agent_dial_interval: number;
  agent_max_ringing: number;
  agent_approval_key?: string | undefined;
  agent_greeting: FileField;
  agent_abort_call_key?: string | undefined;
  agent_complete_call_key?: string | undefined;
  agent_continuous_call: boolean;
  agent_play_cust_number: boolean;
  agent_play_num_requests: boolean;
  incoming_call_permitted?: Array<{ incoming_call_number: string }> | undefined;

  // Customer - call
  cust_dial_prefix?: string | undefined;
  cust_max_attempts: number;
  cust_dial_interval: number;
  cust_max_ringing: number;
  cust_min_duration: number;
  cust_approval_key?: string | undefined;
  cust_prompt: FileField;

  // Customer - sms
  sms_content_type: string; // UI field - three options. The selected value will effect where to save the value and what field label will be displayed
  sms_text_at_start?: string | undefined;
  sms_text_at_failure?: string | undefined;
  sms_text_between_attempts?: string | undefined;

  // No replay
  create_request_on_abandon: boolean;
  min_time_for_abandon?: number | undefined;
  min_time_for_answer?: number | undefined;
  delete_request_on_answer: boolean;
  allow_change_mobile_number: boolean;
  allow_change_landline_number: boolean;
  approve_request_created: boolean;
  cust_request_created: FileField; // new

  report_type: string; // UI field - three options. The selected value will effect where to save the value and what field label will be displayed
  sms_report_sender_name?: string | undefined;
  mail_report_sender_name?: string | undefined;

  sms_report_text?: string | undefined;
  sms_report_destination_numbers?: Array<{ sms_report_destination_number: string }> | undefined;

  mail_report_title?: string | undefined;
  mail_report_text?: string | undefined;
  mail_report_destination_addresses?: Array<{ mail_report_destination_address: string }> | undefined;
}

export type CallbackFieldName =
  | 'selectedPermission'
  | 'dialer_id'
  | 'enabled'
  | 'language'
  | 'speaker'
  | 'calls_recorded'
  | 'international_calls_permitted'
  | 'blacklist'
  | 'priority'
  | 'max_active'
  | 'max_request_age'
  | 'business_number'
  | 'business_number_for_sms'
  | 'active_now'
  | 'agent_max_attempts'
  | 'agent_dial_interval'
  | 'agent_max_ringing'
  | 'agent_approval_key'
  | 'agent_greeting'
  | 'agent_abort_call_key'
  | 'agent_complete_call_key'
  | 'agent_continuous_call'
  | 'agent_play_cust_number'
  | 'agent_play_num_requests'
  | 'incoming_call_permitted'
  | 'cust_dial_prefix'
  | 'cust_max_attempts'
  | 'cust_dial_interval'
  | 'cust_max_ringing'
  | 'cust_min_duration'
  | 'cust_approval_key'
  | 'cust_prompt'
  | 'sms_content_type'
  | 'sms_text_at_start'
  | 'sms_text_at_failure'
  | 'sms_text_between_attempts'
  | 'create_request_on_abandon'
  | 'min_time_for_abandon'
  | 'min_time_for_answer'
  | 'delete_request_on_answer'
  | 'allow_change_mobile_number'
  | 'allow_change_landline_number'
  | 'approve_request_created'
  | 'cust_request_created'
  | 'report_type'
  | 'sms_report_sender_name'
  | 'mail_report_sender_name'
  | 'sms_report_text'
  | 'sms_report_destination_numbers'
  | 'mail_report_title'
  | 'mail_report_text'
  | 'mail_report_destination_addresses';

export interface DefaultDialerValidation {
  isValid: boolean;
  hasDomain: boolean;
  hasBusinessNumber: boolean;
  hasAgentGroupNumber: boolean;
}
