import { AgentAttributes, CallAttributes } from '../../../interfaces';
import { Button } from '../../../../design/button';
import { Table } from '../../../../design/table/table';
import { Column } from '../../../../design/table/types';
import styles from './calls-table.module.scss';

export const CallsTable = ({ calls, agents }: { calls: CallAttributes[]; agents: AgentAttributes[] }) => {
  const data = [
    ...calls.map((call) => ({
      id: call.id,
      objectType: 'call',
      json: JSON.stringify(call),
    })),
    ...agents.map((agent) => ({
      id: agent.id,
      objectType: 'agent',
      json: JSON.stringify(agent),
    })),
  ];

  const columns: Array<Column<(typeof data)[0], keyof (typeof data)[0]>> = [
    {
      key: 'objectType',
      header: 'objectType',
      className: styles.column,
    },
    {
      key: 'json',
      header: 'json',
      className: styles.column,
    },
  ];

  const copyData = () => {
    navigator.clipboard.writeText(dataToString(data)).then(() => {});
  };

  return (
    <>
      <Button onClick={copyData}>העתק טבלה</Button>
      <Table columns={columns} data={data} showHeaders />
    </>
  );
};

interface Data {
  id: string;
  objectType: string;
  json: string;
}

interface ParsedData extends Data {
  parsedJson: any;
  startTime: Date;
}

function dataToString(data: Data[]): string {
  // Parse the JSON and extract the startTime field for sorting
  const parsedData: ParsedData[] = data.map((obj) => {
    const parsedJson = JSON.parse(obj.json);
    const startTime = new Date(parsedJson.startTime); // Assuming startTime is a valid date string
    return { ...obj, parsedJson, startTime };
  });

  // Sort the parsedData array by startTime
  parsedData.sort((a, b) => b.startTime.getTime() - a.startTime.getTime());

  // Build the result object
  const result: { [key: string]: any[] } = {};

  parsedData.forEach((obj) => {
    if (!result[obj.objectType]) {
      result[obj.objectType] = [];
    }
    result[obj.objectType].push(obj.parsedJson);
  });

  // Stringify the result object
  return JSON.stringify(result, null, 2);
}
