import { useGroups } from "../../api/use-groups"
import { useMe } from "../../api/use-me";
import { Spinner } from "../../design/spinner/spinner";
import styles from "./group-list.module.scss"
import { Table } from "../../design/table/table";
import { GroupResource } from "../../api/types";
import { ButtonWithDropdown } from "../../design/button-with-dropdown/button-with-dropdown";
import { ChevronDown } from "../../design/icons/chevron-down";
import { ChevronUp } from "../../design/icons/chevron-up";
import { GroupMenu } from "./group-menu/group-menu";
import { GroupForwardDialog } from "../groups-dialog/group-forward-dialog";
import { GroupCloseDialog } from "../groups-dialog/group-close-dialog";
import { GroupNameDialog } from "../groups-dialog/group-name-dialog";
import { activeGroupContext } from "../active-group-context";
import { useDialogState } from "reakit";
import { useContext } from "react";
import { useMediaQuery } from "../../layouts/app-layout/app-layout";
import { mobileQuery } from "../../theme/media";
import { Group } from "./group";
import { safeParsePhoneNumber } from "../../utils/strings/parse-phone";
import { Column } from "../../design/table/types";

export const GroupsList = () => {
  const user = useMe();
  const domainUuid = user.data?.domain.id;
  const { data, loading } = useGroups(domainUuid);
  const selfManagedGroups = data?.filter(group => user.data?.domain.config.selfManagedGroups.includes(group.id)) || [];
  const isMobile = useMediaQuery(mobileQuery);
  const callForwardDialog = useDialogState();
  const closedModeDialog = useDialogState();
  const nameEditDialog = useDialogState();

  const dialogs = [
    <GroupForwardDialog dialog={callForwardDialog} />,
    <GroupCloseDialog dialog={closedModeDialog} />,
    <GroupNameDialog dialog={nameEditDialog} />
  ];

  const [, setActiveGroup] = useContext(activeGroupContext)

  const handleCallForwardClick = (group: GroupResource) => {
    setActiveGroup(group);
    callForwardDialog.show();
  }

  const handleCancelForward = (group: GroupResource) => {
    setActiveGroup(group);
    closedModeDialog.show()
  }

  const handleNameEditClick = (group: GroupResource) => {
    setActiveGroup(group);
    nameEditDialog.show();
  }

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spinner />
      </div>
    )
  }

  if (!selfManagedGroups || selfManagedGroups.length === 0) {
    return (
      <div>
        <div className={styles.content}>
          בעמוד הזה את/ה יכול/ה לנהל את קבוצות החיוג של העסק שלך, למשל:
          <ul>
            <li>עריכת שם לקבוצת קריאה בעסק</li>
            <li>העברת כל השיחות הנכנסות למספר אחר</li>
          </ul>
        </div>
        <p className={styles.content}>
          כדי להפעיל את היכולת לנהל בעצמך את קבוצות החיוג שלך, פנה/י לתמיכה: <a href="mailto:support@ip-com.co.il">support@ip-com.co.il</a>
        </p>
      </div>
    )
  }

  const tableData = selfManagedGroups.map(group => {
    const renderForwardDest = group.forwardDestination && group.forwardEnabled;
    const forwardDest = group.forwardDestination as string;
    // typescript is not smart enough to realize group.forwardDestination is true if renderForwardDest is true

    return {
      id: group.id,
      number: group.number,
      name: group.name,
      forward: renderForwardDest ?
        <span className={styles.forwardNumber}>{(`שיחות מועברות ל-${safeParsePhoneNumber(forwardDest, "IL")}`)}</span> :
        undefined,
      menu: (
        <ButtonWithDropdown
          label="פעולות"
          collapsedBadge={<ChevronDown size={24} />}
          expandedBadge={<ChevronUp size={24} />}
          buttonClassName={styles.button}
        >
          <GroupMenu
            isForwarded={group.forwardEnabled}
            onForwardClick={() => handleCallForwardClick(group)}
            onCancelForwardClick={() => handleCancelForward(group)}
            onEditNameClick={() => handleNameEditClick(group)}
          />
        </ButtonWithDropdown>
      )
    }
  })

  const columns: Array<Column<typeof tableData[0], keyof typeof tableData[0]>> = [
    {
      key: "number",
      className: styles.number
    },
    {
      key: "name",
      className: styles.name
      
    },
    {
      key: "forward",
      className: styles.forwardNumberContainer
    },
    {
      key: "menu",
    }
  ]

  return isMobile ? (
    <>
    {
      selfManagedGroups.map(group => <Group group={group}/>)
    }
    </>
  )
  :(
    <div>
      <Table data={tableData} columns={columns} sort={false} showHeaders={false} />
      {dialogs.map((dialog, index) => <div key={index}>{dialog}</div>)}
    </div>
  )
}
