import { useDialogState } from 'reakit';
import { useNavigate } from 'react-router-dom';

import { Page } from '../../page';
import { Button } from '../../design/button';
import { CallbackDialer } from '../../api/dialers';
import { Spinner } from '../../design/spinner/spinner';
import CallbackDialerListItem from './CallbackDialerList';
import { useCallbackDialers } from '../hooks/useCallbackDialers';
import { useDefaultCallbackDialer } from '../hooks/useDefaultCallbackDialer';
import CreateCallbackDialerDialog from '../dialerDialog/CreateCallbackDialerDialog';
import styles from '../../emergency-dialer/dial-groups/dial-groups-page.module.scss';
import { AppLayout, AppLayoutContainer, AppLayoutHeader, AppLayoutTitle } from '../../layouts/app-layout/app-layout';
import { DefaultDialerErrorDialog } from '../dialerDialog/DefaultDialerErrorDialog';

export default function CallbackDialersPage() {
  const {
    error,
    isLoading,
    dialerData: callbackDialers,
    defaultDialerData,
    mutate: mutateCallbackDialers,
    apiDataHandler,
  } = useCallbackDialers();
  const { defaultDialerValidation, createDefaultCallbackDialer } = useDefaultCallbackDialer(mutateCallbackDialers);

  const createCallbackDialersDialog = useDialogState();
  const errorCallbackDialerDialog = useDialogState();

  const handleCreateCallbackDialer = () => {
    if (!defaultDialerData) {
      if (defaultDialerValidation.isValid) {
        createDefaultCallbackDialer(apiDataHandler);
        createCallbackDialersDialog.show();
      } else {
        errorCallbackDialerDialog.show();
      }
    } else {
      createCallbackDialersDialog.show();
    }
  };

  if (isLoading && !callbackDialers) {
    return (
      <Page name='Callback Dialers Page'>
        <AppLayout>
          <AppLayoutContainer>
            <AppLayoutHeader className={styles.layoutHeader}>
              <AppLayoutTitle>ניהול חייגני שיחה</AppLayoutTitle>
            </AppLayoutHeader>
            <Spinner />
          </AppLayoutContainer>
        </AppLayout>
      </Page>
    );
  }

  return (
    <Page name='Callback Dialers Page'>
      <AppLayout>
        <AppLayoutContainer>
          <AppLayoutHeader className={styles.layoutHeader}>
            <AppLayoutTitle>ניהול חייגני שיחה</AppLayoutTitle>
            <Button variant='primary' className={styles.createDialGroupBtn} onClick={handleCreateCallbackDialer}>
              יצירת חייגן שיחה
            </Button>
          </AppLayoutHeader>
          <CallbackDialersList
            error={error}
            isLoading={isLoading}
            callbackDialers={callbackDialers}
            mutateCallbackDialers={mutateCallbackDialers}
          />
          <CreateCallbackDialerDialog
            dialog={createCallbackDialersDialog}
            defaultDialerData={defaultDialerData}
            apiDataHandler={apiDataHandler}
            mutateCallbackDialers={mutateCallbackDialers}
          />
          <DefaultDialerErrorDialog
            dialog={errorCallbackDialerDialog}
            defaultDialerValidation={defaultDialerValidation}
          />
        </AppLayoutContainer>
      </AppLayout>
    </Page>
  );
}

const CallbackDialersList = ({
  callbackDialers,
  error,
  isLoading,
  mutateCallbackDialers,
}: CallbackDialersListProps) => {
  const navigate = useNavigate();

  if (!callbackDialers && isLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <>
        <h3>שגיאת מערכת</h3>
        <div>לא הצלחנו לפתוח רשימת חייגנים</div>
      </>
    );
  }

  if (callbackDialers) {
    return (
      <ul className={styles.dialGroupsList}>
        {callbackDialers.map((callbackDialer) => (
          <CallbackDialerListItem
            key={callbackDialer.dialer_id}
            onListItemClick={() => navigate(`/callback-dialer/dialers/${callbackDialer.dialer_id}`)}
            data={{
              dialer_id: callbackDialer.dialer_id,
              name: callbackDialer.name,
            }}
            mutateCallbackDialers={mutateCallbackDialers}
          />
        ))}
      </ul>
    );
  }

  return null;
};

interface CallbackDialersListProps {
  error: string;
  isLoading: boolean;
  callbackDialers: CallbackDialer[] | undefined;
  mutateCallbackDialers: () => void;
}
