import { LinearProgressProps } from '@mui/material';
import { FC, memo, useEffect, useState } from 'react';

import { progressbarDefaultConfig } from '../../constants';
import { StyledLinearProgressBar } from './StyledLinearProgressBar';
import { LinearProgressVariant, SnackbarVariant } from '../../../theme/muiTypes';

const { TIME_INTERVAL, SAFETY_BUFFER_TIME } = progressbarDefaultConfig;

export interface LinearProgressBarProps extends LinearProgressProps {
  duration: number;
  severity: SnackbarVariant;
  variant?: LinearProgressVariant;
  timeInterval?: number;
}

const LinearProgressBar: FC<LinearProgressBarProps> = ({
  variant = LinearProgressVariant.determinate,
  duration,
  severity,
  timeInterval = TIME_INTERVAL,
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (progress === 100) return;
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 100) {
          return 100;
        }
        const diff = (timeInterval / (duration - SAFETY_BUFFER_TIME)) * 100;
        return Math.min(oldProgress + diff, 100);
      });
    }, timeInterval);

    return () => {
      clearInterval(timer);
    };
  }, [duration, progress, timeInterval]);

  return <StyledLinearProgressBar severity={severity} variant={variant} value={progress} />;
};

export default memo(LinearProgressBar);
