import { API } from 'aws-amplify';
import useSWR from 'swr';
import { CallbackDialer } from '../api/dialers';

const useDialers = () => {
  const endpoint = '/callback-dialer';
  const { data, error } = useSWR(endpoint, dialersFetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    dialers: data?.data as CallbackDialer[],
    error: error,
    loading: !error && !data,
  };
};

const dialersFetcher = async (path: string) => {
  return await API.get(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, path, {});
};

export default useDialers;
