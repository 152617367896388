import { useEffect } from 'react';
import { useDialogState } from 'reakit';
import { Dialog } from '../design/dialog/dialog';
import { Update } from './update/update';
import styles from './news.module.scss';
import { unreadCustomerUpdatesState } from '../state';
import { SetterOrUpdater, useRecoilState } from 'recoil';
import { Button } from '../design/button';
import { useNavigate } from 'react-router-dom';
import { useMe } from '../api/use-me';


export function News() {
  const [unreadUpdates, setUnreadUpdates] = useUpdates();
  const dialog = useDialogState({
    visible: unreadUpdates,
  })

  const navigate = useNavigate();

  useEffect(() => {
    if (unreadUpdates) {
      dialog.show();
    }
  }, [unreadUpdates, dialog]);


  const handleClose = () => {
    setUnreadUpdates(false);
  }


  return unreadUpdates ? (
    <Dialog {...dialog}
      variant="info"
      header="מה חדש"
      onClose={handleClose}
      className={styles.dialog}
    >
      <Update
        id="7"
        date="25 בינואר 2022"
        title="קביעת שמות למספרים שלי"
        visual="/updates/ext-numbers.jpg"
      >
        <p>
          הוספנו אפשרות עריכה חדשה: מעכשיו אפשר לתת שם לכל אחד ממספרי הטלפון שלך. כך תוכלי לזהות עבור כל מספר את הפעילות שלו במערכת. השם יופיע ליד המספר, למשל בסינון היסטוריית שיחות.
        </p>
        <p>
          אפשר למצוא את העריכה בעמוד “המספרים שלי”, בלחיצה על כפתור העריכה.
        </p>
        <Button
          variant="link"
          onClick={() => {
            handleClose();
            navigate('/my-numbers')
          }}
        >
          לעמוד המספרים שלי
        </Button>
      </Update>
      <Update
        id="6"
        date="30 בנובמבר 2021"
        title="הפנית שיחות נכנסות משלוחה"
        visual="/updates/ext.jpg"
      >
        <p>
          יכולת חדשה בניהול המרכזיה שלך: מעכשיו תוכלי להפנות שיחות מהשלוחה שלך למספר נייד או לכל מספר אחר.
        </p>
        <p>
          אפשר לערוך את ההפניה או לבטל את הפעולה מכל מקום ובכל זמן.
        </p>
        <Button
          variant="link"
          onClick={() => {
            handleClose();
            navigate('/extensions')
          }}
        >
          לעמוד השלוחות
        </Button>
      </Update>
      <Update
        id="5"
        date="24 באוגוסט 2021"
        title="התאמת המענה הטלפוני לחגים"
        visual="/updates/events.jpg"
      >
        <p>
          מעכשיו אפשר להתאים מראש את המענה הטלפוני לקראת החגים: להגדיר את שעות הפעילות או לסגור את המענה, ולהעלות הקלטה מיוחדת לחג. מיד בסיום החג המענה הטלפוני יחזור למצבו הרגיל באופן אוטומטי.
          חג שמח!
        </p>
        <Button
          variant="link"
          onClick={() => {
            handleClose();
            navigate('/my-numbers');
          }}
        >
          לעמוד המספרים שלי
        </Button>

      </Update>
      <Update
        id="4"
        date="29 ביוני 2021"
        title="שליטה במרכזייה בלי לחכות לנציג"
        visual="/updates/my-numbers.jpg"
      >
        השנה האחרונה לימדה אותנו לצפות לבלתי-צפוי, ולהגיב במהירות. אנחנו שמחים להציג יכולת חדשה: המספרים שלי. נהל/י את המענה הטלפוני שלך:
        <ul>
          <li>פתיחה וסגירה ידנית של המענה הטלפוני</li>
          <li>העברת כל השיחות הנכנסות למספר אחר</li>
          <li>שינוי הקלטה בתפריטים קוליים</li>
        </ul>
      </Update>
      <Update
        id="3"
        date="22 ביוני 2021"
        title="נגן שיחות חדש"
        visual="/updates/player.gif"
      >
        שדרגנו את נגן השיחות! לחץ/י על כפתור play בהיסטוריית השיחות כדי להשמיע או להוריד הקלטה של שיחה
      </Update>
      <Update
        id="2"
        date="8 ביוני 2021"
        title="חיפוש שיחות"
        visual="/updates/search.gif"
      >
        כדי למצוא שיחה עם לקוח בקלות ובמהירות - הקלד/י את מספר הטלפון של הלקוח, וקבל/י רשימה של כל השיחות שהתקיימו עם אותו מספר.
        בנוסף, אפשר לסנן שיחות לפי מאפייני שיחה שונים:
        <ul>
          <li>טווח תאריכים</li>
          <li>שיחות נכנסות, יוצאות ופנימיות</li>
          <li>סטטוס: שיחות שנענו, שיחות שלא נענו ושיחות שהגיעו לתא הקולי</li>
          <li>משך השיחה וזמן המתנה למענה</li>
        </ul>
      </Update>
      <Update
        id="1"
        date="25 במאי 2021"
        title="היסטוריית שיחות: לעקוב בקלות אחרי השיחות בעסק"
        visual="/updates/history.gif"
      >
        תכננו מחדש את תצוגת השיחות של העסק שלך, ושיפרנו את האופן שבו אנחנו מציגים מידע על שיחות - כדי שתוכל לאתר שיחות ולהבין מה קרה בהן בקלות ובמהירות. תתחדש/י!
      </Update>
    </Dialog>
  ) : null;
}


const updateVersion = 5;
const key = 'ipcom.updates.version';

function useUpdates(): [boolean, SetterOrUpdater<boolean>] {
  const user = useMe();
  const [unreadUpdates, setUnreadUpdates] = useRecoilState(unreadCustomerUpdatesState);

  useEffect(() => {
    if (user?.data) {
      setUnreadUpdates(getUpdateVersion() < updateVersion)
    }
  }, [user?.data, setUnreadUpdates]);

  useEffect(() => {
    if (unreadUpdates) {
      setLastReadUpdateVersion();
    }
  }, [unreadUpdates]);

  return [unreadUpdates, setUnreadUpdates];
}



function getUpdateVersion() {
  const lastUpdateId = localStorage.getItem(key);
  if (lastUpdateId) {
    return parseInt(lastUpdateId);
  }
  return 0;
}

function setLastReadUpdateVersion() {
  localStorage.setItem(key, `${updateVersion}`);
}
