import { useEffect, useState } from 'react';
import { useDialogState } from 'reakit/Dialog';
import { useNavigate, useLocation } from 'react-router-dom';

import { Page } from '../../../page';
import AttemptInfo from './attempt-info';
import useAttempts from '../../useAttempts';
import { useMe } from '../../../api/use-me';
import styles from './request-info.module.scss';
import { mobileQuery } from '../../../theme/media';
import { useCallbackRequest } from '../../use-calls';
import { Dialog } from '../../../design/dialog/dialog';
import { AudioPlayer } from '../../../sound/audio-player';
import { getHebTimeDiff } from '../../../utils/time/time';
import { Spinner } from '../../../design/spinner/spinner';
import { useURLParams } from '../../../app/use-url-params';
import { useCall } from '../../../calls-history/use-calls';
import { useMediaQuery } from '../../../layouts/app-layout/app-layout';
import { safeParsePhoneNumber } from '../../../utils/strings/parse-phone';
import { getCallbackRequestStatusString } from '../../../utils/strings/string-translator';

export function RequestInfoDialog() {
  const user = useMe();
  const navigate = useNavigate();
  const { urlParams, removeURLParam } = useURLParams({
    path: '/callbacks',
    params: useLocation()
      .pathname.replace(/^\/|\/$/g, '')
      .replace('callbacks/', ''),
  });
  const { request } = useCallbackRequest(urlParams?.call, user.data?.domain.id);
  const { attempts } = useAttempts(request?.request_uuid);
  const [finalCallId, setFinalCallId] = useState('');
  const { data } = useCall(finalCallId);

  const dialog = useDialogState({
    visible: urlParams !== undefined,
  });

  useEffect(() => {
    if (attempts && attempts.length) {
      setFinalCallId(attempts[0].call_uuid);
    }
  }, [attempts]);

  useEffect(() => {
    if (!dialog.visible) {
      removeURLParam('call');
    }
  }, [dialog.visible, navigate, removeURLParam]);

  const isMobile = useMediaQuery(mobileQuery);

  if (!request) {
    return <Spinner />;
  }
  const returnNumber = String(request.return_number);
  const formattedReturnNumber = safeParsePhoneNumber(returnNumber, 'IL');
  return (
    <Page name='Call Details'>
      <Dialog
        {...dialog}
        variant='info'
        header={
          <div>
            פרטי בקשת שיחה חוזרת למספר <br /> {formattedReturnNumber}
            <p style={{ fontSize: '13px' }}>זיהוי בקשה פנימי: {request.request_uuid}</p>
          </div>
        }
        onClose={() => navigate(-1)}
        aria-label='פרטי בקשה'
      >
        <div>
          <div className={styles.parties}>
            <div className={styles.party}>
              <div className={styles.title}>שיחה נכנסת קבוצת קריאה</div>
              <span
                className={
                  request.request_status === 'canceled' || request.request_status === 'failed'
                    ? styles.inactive_label
                    : styles.active_label
                }
              >
                {getCallbackRequestStatusString(request)}
              </span>
            </div>
            <div className={styles.party}>
              <div className={styles.title}>קבוצה</div>
              <span className={styles.active_label}>{request?.agent_group_name}</span>
            </div>
            {data?.call?.record_url && request.request_status === 'completed' ? (
              <AudioPlayer className={styles.player} src={data.call.record_url} />
            ) : (
              <></>
            )}
          </div>
          <div>
            <section className={styles.details}>
              <div className={styles.detail}>
                <span className={styles.label}>התקבלה ממספר</span>
                <span className={styles.value}>{request?.caller_number}</span>
              </div>
              {!isMobile && (
                <div className={styles.detail}>
                  <span className={styles.label}>נסיונות חיוג ללקוח</span>
                  <span className={styles.value}>
                    {request.num_attempts}/{request.max_attempts}
                  </span>
                </div>
              )}
              <div
                className={styles.detail}
                style={isMobile ? { borderBottom: '1px solid #e1e6ec', marginBlockEnd: '6px' } : {}}
              >
                <span className={styles.label}>מספר שחוייג</span>
                <span className={styles.value}>{formattedReturnNumber}</span>
              </div>
              {isMobile && (
                <div className={styles.detail}>
                  <span className={styles.label}>נסיונות חיוג שבוצעו</span>
                  <span className={styles.value}>
                    {request.num_attempts}/{request.max_attempts}
                  </span>
                </div>
              )}
            </section>
            {request && attempts && attempts.length > 0 ? (
              <section className={styles.attempt_list}>
                {attempts?.map((attempt: any, index: number) => (
                  <AttemptInfo
                    key={attempt.attempt_uuid}
                    attempt={attempt}
                    requestWaitTime={getHebTimeDiff(request?.start_date, attempt.start_date)}
                    attempts={attempts.length}
                    attemptNum={attempts.length - index}
                  />
                ))}{' '}
              </section>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Dialog>
    </Page>
  );
}
