import { ChangeEvent, forwardRef } from 'react';

import { CustomButton } from '../customButton/CustomButton';
import { ButtonVariant, Color } from '../../../theme/muiTypes';
import { FileFormat, FileType } from '../../../globalTypes/types';
import { getAcceptableFormats } from '../../../whatsapp-bot/utils/attachmentUtils';

interface UploadFileProps {
  file_type: FileType | null;
  file_format: FileFormat | null;
  supported_formats?: FileFormat[];
  btnColor: Color;
  btnVariant: ButtonVariant;
  selectFileBtnText: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const UploadFile = forwardRef<HTMLInputElement, UploadFileProps>(
  ({ selectFileBtnText, file_type, btnVariant, btnColor, onChange, supported_formats, ...props }, ref) => {
    const acceptableFormats = getAcceptableFormats(supported_formats, file_type);

    return (
      <CustomButton component='label' variant={btnVariant} color={btnColor} {...props}>
        <span>{selectFileBtnText}</span>
        <input hidden type='file' accept={acceptableFormats} onChange={onChange} {...props} />
      </CustomButton>
    );
  }
);
