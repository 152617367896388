import { PermissionLevelOption } from '../types';
import { PermissionGroup, PermissionLevel, UserResource } from '../../api/types';
import { CALLBACK_FORM_FIELDS_CONFIG } from '../formConfig/callbackDialerFormConfig';

const permissionGroupMap: Record<PermissionGroup, number> = {
  customer: 1,
  support: 2,
  admin: 3,
};

export const getUserPermissionLevel = (userData: UserResource | undefined) => {
  if (!userData || !userData?.groups || userData.groups.length === 0) {
    return;
  }

  let maxPermissionLevel = 1;

  userData.groups.forEach((group) => {
    const permissionLevel = permissionGroupMap[group];
    if (permissionLevel > maxPermissionLevel) {
      maxPermissionLevel = permissionLevel;
    }
  });

  return maxPermissionLevel;
};

export const getAvailablePermissionOptions = (permissionLevel: PermissionLevel) => {
  const permissionOptions = CALLBACK_FORM_FIELDS_CONFIG.selectedPermission.options as PermissionLevelOption[];
  const availablePermissionOptions = permissionOptions.filter((option) => option.value <= permissionLevel);
  return availablePermissionOptions;
};
