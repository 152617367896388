import React from 'react';
import { Icon, IconProps } from './icon';

export const CallForwarding = ({ ...props }: IconProps) => (
  <Icon
    viewBox="0 0 96 96"
    fill="currentColor"
    {...props}
  >
    {/* <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">

    </g> */}
    <g fill="currentColor" transform="translate(48.000000, 48.000000) scale(-1, 1) translate(-48.000000, -48.000000) ">
      <path d="M41.3969696,6 L51.5219696,16.125 L44.0219696,32.25 L33.5219696,32.25 L33.2028179,33.2419041 C30.1176849,43.1857271 30.2240688,53.8562975 33.5219696,63.75 L33.5219696,63.75 L44.0219696,63.75 L51.5219696,79.875 L41.3969696,90 L40.7080496,89.2998264 C18.2033297,66.0533201 18.4329697,28.9639999 41.3969696,6 L41.3969696,6 Z M65,28 L85,48 L65,68 L65,54 L45,54 L45,42 L65,42 L65,28 Z"></path>
    </g>
  </Icon>
);