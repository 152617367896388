import { InputAdornment } from '@mui/material';
import { ElementType, FocusEventHandler, forwardRef } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { FieldKey } from '../../../formConfig/types';
import { StyledChipInput } from './StyledDynamicField';
import { DynamicFieldVariant } from '../../../callbacks/types';

interface DynamicInputProps {
  value: any;
  index: number;
  error?: boolean;
  fieldKey: FieldKey;
  className?: string;
  Icon?: ElementType;
  field: Record<'id', string>;
  disabled?: boolean | undefined;
  dynamicFieldVariant: DynamicFieldVariant;
  onChange: (...event: any[]) => void;
  onRemove: (index: number, id: string) => void;
  onBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
}
export const DynamicInput = forwardRef<HTMLInputElement, DynamicInputProps>((props, ref) => {
  const { field, fieldKey, index, dynamicFieldVariant, className, Icon = CloseIcon, onBlur, onRemove, ...rest } = props;

  const renderDynamicInput = () => {
    switch (dynamicFieldVariant) {
      case DynamicFieldVariant.chips:
        return (
          <StyledChipInput
            ref={ref}
            className={className}
            disabled={!!field[fieldKey]}
            autoFocus={!field[fieldKey]}
            onBlur={onBlur}
            endAdornment={
              <InputAdornment position='end'>
                <Icon onClick={() => onRemove(index, field.id)} edge='start' />
              </InputAdornment>
            }
            {...rest}
          />
        );
      default:
        return null;
    }
  };

  return renderDynamicInput();
});
