import { CallAttributes, GroupType, call_getThe1stAnswerOrLastGroupMember, call_getThe1stGroup } from "../../monitor/interfaces";

type uuid = string;

interface RelevantTimeParams {
  groupType       : GroupType;
  groupUuid       : uuid;
  groupEnterTime  : Date;
  groupExitTime   : Date | undefined;
  memberEnterTime : Date | undefined;
  memberAnswerTime: Date | undefined;
  memberExitTime  : Date | undefined;
}

// "the relevant group" is the one returned by call_getTheGroupMember(), which may be the answering group instance if answered, the first group nstance if non-answered
// groupEnteTime is the enter time for first instance of the relevant group
// look for the first group that answered the call and is self managed
export function getRelevantTimeParams(call: CallAttributes, selfManagedGroupUuids: uuid[]): RelevantTimeParams | undefined{
  const group0 = call_getThe1stGroup(call, selfManagedGroupUuids);
  if (!group0) return undefined;

  const relevantGroupAndMember = call_getThe1stAnswerOrLastGroupMember(call, selfManagedGroupUuids);
  if (!relevantGroupAndMember) return undefined;

  const { group, member } = relevantGroupAndMember;

  const result: RelevantTimeParams = {
    groupType       : group.groupType,
    groupUuid       : group.groupUuid,
    groupEnterTime  : group0.enterTime   && new Date(group0.enterTime),
    groupExitTime   : group.exitTime     && new Date(group.exitTime),
    memberEnterTime : member?.enterTime  && new Date(member.enterTime),
    memberAnswerTime: member?.answerTime && new Date(member.answerTime),
    memberExitTime  : member?.exitTime   && new Date(member.exitTime),
  }

  return result;
}
