import { BusinessNumberResource } from "../../api/types";
import { User } from "../../api/use-users";

export const getSelfManagedNumbers = (groupedData?: Record<string, BusinessNumberResource[]>, multiTenantUser?: User): Record<string, BusinessNumberResource[]> => {

  if (!groupedData || !multiTenantUser || Object.keys(groupedData).length === 0) {
    return ({});
  }
  const domainsSelfManagedNumbers = Object.fromEntries(multiTenantUser.domains.map(domain => [domain.domain_uuid, domain.domain_self_managed_numbers || []]));
  const entries = Object.entries(groupedData);
  const filteredEntries = entries.map(([domain, numbers]) => [domain, numbers.filter(number => domainsSelfManagedNumbers[domain].includes(number.id))])
  return Object.fromEntries(filteredEntries);
}
