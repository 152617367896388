import styles from "./callee-response-table.module.scss";
import { CalleeResponse } from "../../../../api/dialers";
import { FieldArray, useField } from "formik";
import { Input } from "../../../../design/form/input/input";
import { Select } from "../../../../design/form/select";
import { Button } from "../../../../design/button";
import { Plus } from "../../../../design/icons/plus";
import { Remove } from "../../../../design/icons/remove";
import { Field } from "../../../../design/form/field";
import useDebounce from "react-use/lib/useDebounce";
import { KeyboardEvent } from "react";
export const DEFAULT_CALLEE_RESPONSE_OPTS: CalleeResponse[] = [
  {
    response_key: 1,
    response_type: "approve",
    response_text: "מגיע",
  },
  {
    response_key: 2,
    response_type: "decline",
    response_text: "לא מגיע",
  },
];
const MAX_RESPONSES_COUNT = 5;
const MIN_RESPONSES_COUNT = 2;
const deepCopyArray = (arr: any[]): any[] => JSON.parse(JSON.stringify(arr));

export const CalleeResponseTable = ({ save }: CalleeResponseTableProps) => {
  const field = useField("callee_responses");
  const responses: CalleeResponse[] = field[1].value;
  const handleSave = () => {
    save?.("callee_responses", responses);
  };

  useDebounce(
    () => {
      handleSave();
    },
    2000,
    [responses]
  );

  if (!responses) {
    console.error("Callee response table has no response options");
  }

  return (
    <FieldArray
      name='callee_responses'
      render={(arrayHelpers) => {
        const responsesCount = responses.reduce(
          (acc, curr) => {
            return curr.response_type === "approve"
              ? { ...acc, approvals: acc.approvals + 1 }
              : { ...acc, rejections: acc.rejections + 1 };
          },
          { approvals: 0, rejections: 0 }
        );

        const handleAddResponse = () => {
          if (responses?.length >= MAX_RESPONSES_COUNT) {
            return;
          }
          responses.forEach((res, idx) => (res.response_key = idx + 1));
          const newResponses = deepCopyArray(responses);
          newResponses.forEach((_) => arrayHelpers.pop());
          newResponses.push({
            response_key: responses.length + 1,
            response_type: "approve",
            response_text: "",
          });
          newResponses.forEach((response) => arrayHelpers.push(response));
        };

        const handleRemoveResponse = (index: number) => {
          if (responses?.length <= MIN_RESPONSES_COUNT) {
            return;
          }
          if (responsesCount.approvals === 1 && responses[index].response_type === "approve") {
            alert("חובה להשאיר לפחות אפשרות אישור אחת");
            return;
          }
          if (responsesCount.rejections === 1 && responses[index].response_type === "decline") {
            alert("חובה להשאיר לפחות אפשרות סירוב אחת");
            return;
          }
          responses.forEach((y, idx) => {
            if (idx >= index) {
              y.response_key = idx;
            }
          });
          arrayHelpers.remove(index);
        };

        return (
          <div className={styles.sectionContainer}>
            <div className={styles.strong}>טקסט לתגובת כוננים בחיוג או בסמס</div>
            <div className={styles.description}>
              כאן מזינים את נוסח הטקסט שהכונן יקבל ובוחרים את סוג התגובה - אישור / סירוב הגעה.
            </div>
            <div className={styles.listContainer}>
              <ul className={styles.optionsList}>
                {responses?.map((option: CalleeResponse, index: number) => {
                  return (
                    <Option
                      key={option.response_key}
                      data={option}
                      rowIndex={index}
                      handleRemove={() => handleRemoveResponse(index)}
                      handleMouseEnter={(index) =>
                        responsesCount.approvals + responsesCount.rejections > 2
                          ? handleMouseEnter(index)
                          : null
                      }
                      handleMouseLeave={(index) => handleMouseLeave(index)}
                    />
                  );
                })}
              </ul>
              {responses?.length < MAX_RESPONSES_COUNT ? (
                <span className={styles.addOptionsSection}>
                  <Button type='button' className={styles.plusButton} onClick={handleAddResponse}>
                    <Plus size={16} />
                  </Button>
                  <Button type='button' variant='link' onClick={handleAddResponse}>
                    הוספת תגובה
                  </Button>
                </span>
              ) : undefined}
            </div>
          </div>
        );
      }}
    />
  );
};

const Option = ({
  data,
  rowIndex,
  handleRemove,
  handleMouseEnter,
  handleMouseLeave,
}: OptionProps) => {
  const RESPONSE_TYPES = ["approve", "decline"];

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <div
      className={styles.optionContainer}
      onMouseEnter={() => handleMouseEnter(`${rowIndex}`)}
      onMouseLeave={() => handleMouseLeave(`${rowIndex}`)}
    >
      <div className={styles.rowIndex}>
        <span className={styles.optionIndex}>{rowIndex + 1}.</span>
        <Button
          type='button'
          onClick={() => handleRemove(rowIndex)}
          id={`rm-btn-${rowIndex}`}
          className={styles.deleteButton}
        >
          <Remove size={24} className={styles.deleteIcon} />
        </Button>
      </div>
      <div className={styles.option}>
        <Field name={`callee_responses.${rowIndex}.response_text`}>
          <Input
            onClick={() => handleMouseLeave(`${rowIndex}`)}
            placeholder={
              data.response_type === "approve"
                ? "לדוגמה: מגיע תוך 20 דקות"
                : "לדוגמה: אין באפשרותי להגיע"
            }
            id={`${rowIndex}-input`}
            onKeyDown={handleKeyPress}
          />
        </Field>

        <Field name={`callee_responses.${rowIndex}.response_type`}>
          <Select multiple={false} name={`callee_responses.${rowIndex}.response_type`}>
            {RESPONSE_TYPES.map((responseType) => {
              return (
                <option key={`${data.response_key}-${responseType}`} value={responseType}>
                  {" "}
                  {responseType === "approve" ? "אישור הגעה" : "סירוב הגעה"}{" "}
                </option>
              );
            })}
          </Select>
        </Field>
      </div>
    </div>
  );
};
interface OptionProps {
  data: CalleeResponse;
  rowIndex: number;
  handleRemove: (index: number) => void;
  handleMouseEnter: (id: string) => void;
  handleMouseLeave: (id: string) => void;
}

interface CalleeResponseTableProps {
  save?: (fieldName: string, fieldValue: any) => void;
}

const handleMouseEnter = (id: string) => {
  const optionBtn = document.getElementById(`rm-btn-${id}`);
  const optionInput = document.getElementById(`${id}-input`);

  optionBtn?.classList.add(styles.expanded);
  optionInput?.classList.add(styles.expanded);
};

const handleMouseLeave = (id: string) => {
  const optionBtn = document.getElementById(`rm-btn-${id}`);
  const optionInput = document.getElementById(`${id}-input`);

  optionBtn?.classList.remove(styles.expanded);
  optionInput?.classList.remove(styles.expanded);
};
