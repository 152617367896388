import { useCallback } from 'react';
import { SnackbarCloseReason } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { RequestMetaData } from '../globalTypes/types';
import { savingIndicator } from '../utils/globalTexts';
import { fileBtnTexts } from '../callbacks/utils/texts';
import { useFormFieldHandler } from './useFormFieldHandler';
import { SnackbarVariant, TypographyVariant } from '../theme/muiTypes';
import { CustomInput } from '../components/common/customInput/CustomInput';
import { CustomRadio } from '../components/common/customRadio/CustomRadio';
import { CustomSelect } from '../components/common/customSelect/CustomSelect';
import { CustomSnackbar } from '../components/common/customSnackbar/CustomSnackbar';
import { CustomFileField } from '../components/common/customFileField/CustomFileField';
import { CustomInfoField } from '../components/common/customInfoField/CustomInfoField';
import { ComponentType, FieldKey, RadioOption, SelectOption } from '../formConfig/types';
import { CustomDynamicField } from '../components/formFields/dynamicField/CustomDynamicField';
import { CALLBACK_FORM_FIELDS_CONFIG } from '../callbacks/formConfig/callbackDialerFormConfig';
import { CallbackFieldConfig, DynamicFieldUIConfig } from '../callbacks/types';

const { selectedPermission } = CALLBACK_FORM_FIELDS_CONFIG;

export const useFormFieldRenderer = (props: RequestMetaData) => {
  const { fieldSavingStatus, setFieldSavingStatus, handleFieldEvent, handleRemoveFile, handleRemoveDynamicValue } =
    useFormFieldHandler(props);
  const { key: fieldName, isOpen, variant } = fieldSavingStatus;
  const { control, getValues } = useFormContext();
  const selectedPermissionLevel = getValues(selectedPermission.name);

  const handleCloseSnackbar = useCallback(
    (event: Event | React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
      if (reason !== 'clickaway') {
        setFieldSavingStatus({
          key: '',
          isOpen: false,
          variant: SnackbarVariant.info,
        });
      }
    },
    [setFieldSavingStatus]
  );

  const renderFormField = useCallback(
    (fieldConfig: CallbackFieldConfig) => {
      const { name, label, fieldKey, options, componentType, uiConfig, permission: fieldPermission } = fieldConfig;
      const { hasPermission, ...uiProps } = uiConfig;

      let fieldComponent: JSX.Element | null = null;
      const showSnackbar = isOpen && fieldName === name;

      // Don't display components if user permission level is less than field permission level
      if (fieldPermission > selectedPermissionLevel) return fieldComponent;

      switch (componentType) {
        case ComponentType.Info:
          fieldComponent = (
            <Controller
              name={name}
              control={control}
              render={({ field: { value } }) => {
                return <CustomInfoField label={label} value={value} />;
              }}
            />
          );
          break;
        case ComponentType.TextInput:
          fieldComponent = (
            <Controller
              name={name}
              control={control}
              render={(controllerState) => {
                const {
                  field: { onBlur, ...rest },
                  fieldState: { invalid: error },
                } = controllerState;
                const props = { ...rest, ...uiProps, error };
                return (
                  <CustomInput
                    {...props}
                    label={label}
                    onBlur={(event) => handleFieldEvent(event, controllerState)}
                    variant={TypographyVariant.boldCaption1}
                  />
                );
              }}
            />
          );
          break;
        case ComponentType.RadioInput:
          fieldComponent = (
            <Controller
              name={name}
              control={control}
              render={(controllerState) => {
                const {
                  field: { onChange, ...rest },
                } = controllerState;
                const props = { ...rest, ...uiProps };
                return (
                  <CustomRadio
                    {...props}
                    label={label}
                    options={options as RadioOption[]}
                    onChange={(event) => handleFieldEvent(event, controllerState)}
                  />
                );
              }}
            />
          );
          break;
        case ComponentType.Select:
          fieldComponent = (
            <Controller
              name={name}
              control={control}
              render={(controllerState) => {
                const {
                  field: { onChange, ...rest },
                  fieldState: { invalid: error },
                } = controllerState;
                const props = { ...rest, ...uiProps, error };
                return (
                  <CustomSelect
                    {...props}
                    label={label}
                    options={options as SelectOption[]}
                    onChange={(event) => handleFieldEvent(event, controllerState)}
                  />
                );
              }}
            />
          );
          break;
        case ComponentType.FileInput:
          fieldComponent = (
            <Controller
              name={name}
              control={control}
              render={(controllerState) => {
                const {
                  field: { onChange, ...rest },
                } = controllerState;
                const props = { ...fileBtnTexts, ...rest, ...uiProps };
                return (
                  <CustomFileField
                    {...props}
                    label={label}
                    onChange={(event) => handleFieldEvent(event, controllerState)}
                    onRemove={() => handleRemoveFile(name)}
                  />
                );
              }}
            />
          );
          break;
        case ComponentType.DynamicField:
          const { dynamicFieldVariant, ...props } = uiConfig as DynamicFieldUIConfig;
          fieldComponent = (
            <CustomDynamicField
              name={name}
              label={label}
              fieldKey={fieldKey as FieldKey}
              dynamicFieldVariant={dynamicFieldVariant}
              onRemove={(fieldValueList) => handleRemoveDynamicValue(name, fieldValueList)}
              onInputBlur={(event, controllerState) => handleFieldEvent(event, controllerState)}
              {...props}
            />
          );
          break;
        default:
          fieldComponent = null;
      }

      return (
        <>
          {fieldComponent}
          {showSnackbar && (
            <CustomSnackbar
              keyId={name}
              isOpen={isOpen}
              variant={variant}
              timeInterval={100}
              autoHideDuration={3000}
              onClose={handleCloseSnackbar}
              regularText={savingIndicator[variant].regularText}
              highlightedText={savingIndicator[variant].highlightedText}
            />
          )}
        </>
      );
    },
    [
      isOpen,
      control,
      variant,
      fieldName,
      selectedPermissionLevel,
      handleFieldEvent,
      handleRemoveFile,
      handleCloseSnackbar,
      handleRemoveDynamicValue,
    ]
  );

  return renderFormField;
};
