import { MouseEvent } from "react";
import { Edit } from "../../design/icons/edit";
import { Remove } from "../../design/icons/remove";
import styles from "./jobTitleActionsMenu.module.scss";

interface JobTitleActionsMenuProps {
  onEditJobTitle: () => void;
  onDeleteJobTitle: () => void;
}

export const JobTitleActionsMenu = ({
  onEditJobTitle,
  onDeleteJobTitle,
}: JobTitleActionsMenuProps) => (
  <>
    <div title='edit-job-title' onClick={onEditJobTitle} className={styles.menuAction}>
      עריכת תפקיד
      <Edit size={24} />
    </div>
    <div title='remove-job-title' onClick={onDeleteJobTitle} className={styles.menuAction}>
      <span className={styles.cancelAction}>הסרת תפקיד</span>
      <Remove size={24} />
    </div>
  </>
);
