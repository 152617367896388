import moment from 'moment';
import { Field } from 'formik';
import classNames from 'classnames';
import useDebounce from 'react-use/lib/useDebounce';
import { useEffect, useState, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { Page } from '../../../page';
import useDialers from '../../use-dialers';
import { useMe } from '../../../api/use-me';
import styles from './calls-log.module.scss';
import { Button } from '../../../design/button';
import { useGroups } from '../../../api/use-groups';
import { RequestList } from './calls-list/call-list';
import { useCallbackRequests } from '../../use-calls';
import { Search } from '../../../design/search/search';
import { Filter } from '../../../design/filter/filter';
import { Spinner } from '../../../design/spinner/spinner';
import { useURLParams } from '../../../app/use-url-params';
import { DialerRequest, RequestStatus } from '../../types';
import { createDateList } from '../../../utils/time/dates';
import { Checkbox } from '../../../design/checkbox/checkbox';
import { AttemptCardContext } from '../../attempt-card-context';
import { OptionsHorizontal, Reset } from '../../../design/icons';
import { DateRange } from '../../../design/date-range/date-range';
import { exportCallbackReport } from '../../../api/export-report';
import { Pagination } from '../../../design/pagination/pagination';
import { SegmentedControl, SegmentedControlOption } from '../../../design/segmented-control/segmented-control';
import { AppLayout, AppLayoutContainer, AppLayoutHeader, AppLayoutTitle } from '../../../layouts/app-layout/app-layout';

const urlParamsSortList = [
  'call', // call param must be first, so call details dialog route will function properly (see calls-history file)
  'page',
  'return_number',
  'from_date',
  'to_date',
  'from_age',
  'to_age',
  'agent_group_numbers',
  'status_per_day',
  'attempts',
];

/**
 * This component is the desktop version of the callback requests report.
 * It is seperated from the mobile version due to reasoning you can find in the CallbacksRouter component.
 *
 * @returns A JSX element constructing the callback requests report for the desktop version.
 */
export const CallbacksReportDesktop = () => {
  const meObject = useMe();
  const { pathname } = useLocation();
  const isExact = pathname === '/callback-dialer/history';
  const [, setRequest] = useContext(AttemptCardContext);
  const agentGroups = useGroups(meObject.data?.domain.id);
  const selfManagedGroups = agentGroups?.data?.filter((group) =>
    meObject.data?.domain.config.selfManagedGroups.includes(group.id)
  );
  const params = useParams<string>()['*']?.replace(/^\/|\/$/g, '')!;

  const { dialers } = useDialers();
  const [downloading, setDownloading] = useState<boolean>(false);
  const url = useURLParams({ path: '/callback-dialer/history', sortList: urlParamsSortList, params });
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null);
  const [searchString, setSearchString] = useState<string>(url.urlParams.return_number);
  const [debouncedSearchString, setDebouncedSearchString] = useState<string>(searchString);

  useDebounce(
    () => {
      setDebouncedSearchString(searchString);
    },
    2000,
    [searchString]
  );

  const { requests, meta, error, loading, mutate } = useCallbackRequests(url.urlParams);
  const [dateRange, setDateRange] = useState({ from_date: url.urlParams.from_date, to_date: url.urlParams.to_date });
  const maxAttempts = dialers ? Math.max(...dialers.map((dialer) => dialer.cust_max_attempts)) : 0;
  const numOfAttempts = Array.from({ length: maxAttempts + 1 }, (_, i) => i);

  /*
   * This function gets the date of the element that was last filtered and calculates
   * the appropriate page to include in the request
   *
   * @param date the date that was last filtered in YYYY-MM-DD string format
   * @returns the page that should be requested from the server
   */
  const getPage = (date: string) => {
    if (!meta?.counts) return;

    const dateList = createDateList(date, dateRange.to_date);

    // creating an object we could query for number of requests in a specific status
    const statusList: string[] = url.urlParams.status_per_day?.split(',') || [];

    const statusObject = statusList.reduce((prev, curr) => {
      const [date, status] = curr.split(':');
      return { ...prev, [date]: status as RequestStatus };
    }, {} as Record<string, RequestStatus>);

    // count the amount of viewable requests from the days that follow the recently filtered day.
    // that amount should tell what page contains the first request of the recently filtered day.
    const requestCount = dateList.reduce((prev, curr) => {
      const dateStr = curr.toISOString().substring(0, 10);

      if (dateStr === date) {
        return 0;
      }

      const statusCounters = meta.counts.find((entry) => entry.date === dateStr)?.count;
      const dailyStatus = statusObject[dateStr];
      const tail = dailyStatus ? statusCounters?.[dailyStatus] : statusCounters?.['total'];
      return prev + (tail || 0);
    }, 0);

    return Math.floor(requestCount / 100) + 1; // every page contains 100 requests
  };

  useEffect(() => {
    return () => {
      window.sessionStorage.removeItem('lastFiltered');
    };
  }, []);

  useEffect(() => {
    if (debouncedSearchString && debouncedSearchString.length > 0) {
      url.setURLParams({ return_number: debouncedSearchString, page: null });
    } else {
      url.removeURLParams(['return_number', 'page']);
    }
    window.sessionStorage.removeItem('lastFiltered');
  }, [debouncedSearchString]);

  useEffect(() => {
    if (isExact || !url.urlParams.from_date || !url.urlParams.to_date) {
      const range = {
        from_date: moment().subtract(1, 'week').format('YYYY-MM-DD').toString(),
        to_date: moment().format('YYYY-MM-DD').toString(),
      };
      setDateRange(range);
      url.setURLParams(range);
    }
  }, [url.urlParams.from_date, url.urlParams.to_date, url.setURLParams]);

  const handleSearch = (value: string) => {
    setSearchString(value);
  };

  const handleSearchReset = () => {
    setSearchString('');
  };

  const handleAttemptOpen = (request: DialerRequest) => {
    setRequest(request);
    url.setURLParam('call', request.request_uuid);
  };

  const handleRequestCancellation = () => {
    mutate();
  };

  const handleStatusSelection = (date: string, status: string) => {
    let urlParamValue = '';
    if (url.urlParams.status_per_day) {
      // this routin splits the old string represented params into a a matrix to make it easier to operate on
      const oldStatusesList: string[] = url.urlParams.status_per_day.split(',');
      const oldStatusesMatrix = oldStatusesList.map((dateStatusPair) => dateStatusPair.split(':'));
      const selectedDateIndex = oldStatusesMatrix.findIndex((dateStatusPair) => dateStatusPair[0] === date);

      // if the date already exists in old params
      if (selectedDateIndex >= 0) {
        if (!status) {
          oldStatusesMatrix.splice(selectedDateIndex, 1);
        } else {
          oldStatusesMatrix.splice(selectedDateIndex, 1, [oldStatusesMatrix[selectedDateIndex][0], status]);
        }
      } else if (status) {
        oldStatusesMatrix.push([date, status]);
      }

      const newStatusesList = oldStatusesMatrix.map((dateStatusPair) => dateStatusPair.join(':'));
      urlParamValue = newStatusesList.join(',');
    } else {
      if (!status) {
        return;
      }
      urlParamValue = `${date}:${status}`;
    }
    if (date && status) {
      const page = getPage(date);
      url.setURLParams({
        status_per_day: urlParamValue,
        page: page,
      });
    } else {
      url.setURLParam('status_per_day', urlParamValue);
    }
  };

  const handleAttemptsFilter = (attempts: string[]) => {
    const sorted = [...attempts].sort();

    // the ... parameter sticks around from earlier iterations even if the 4+ option is unchecked
    // therefore, we need to remove it
    if (sorted[0] === '...') {
      sorted.splice(0, 1);
    }

    const attemptsString = sorted.join(',');
    const urlParamValue = sorted[sorted.length - 1] === '4' ? attemptsString + ',...' : attemptsString;
    url.setURLParams({ attempts: urlParamValue, page: null });
    window.sessionStorage.removeItem('lastFiltered');
  };

  const handleExportRequest = async () => {
    try {
      setDownloading(true);
      await exportCallbackReport(url.urlParams);
    } catch (error) {
      console.error(error);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <Page name='Callbacks'>
      <AppLayout>
        <AppLayoutContainer>
          <AppLayoutHeader className={styles.header}>
            <AppLayoutTitle>היסטוריית שיחות חוזרות</AppLayoutTitle>
            <Button
              loading={downloading}
              variant='link'
              style={{ width: '8rem' }}
              onClick={handleExportRequest}
              disabled={requests?.length === 0}
            >
              ייצוא לאקסל
            </Button>
          </AppLayoutHeader>
          <section className={styles.searchBar}>
            <Search
              placeholder='חיפוש מספר טלפון...'
              onChange={(e) => handleSearch(e.target.value)}
              onReset={() => handleSearchReset()}
              value={searchString || ''}
            />
            <DateRange
              customArrowIcon={<Reset size={16} />}
              displayFormat='DD MMMM'
              keepOpenOnDateSelect
              noBorder
              renderCalendarInfo={() => (
                <button
                  type='button'
                  disabled={!dateRange.from_date || !dateRange.to_date}
                  className={classNames(styles.filterFormButton, styles.primary)}
                  onClick={() => {
                    setFocusedInput(null);
                    // changing urlParams twice in a single function does not work.
                    // it happens becuase changing the parameters is done with useCallback.
                    // therefore, we are changing both parameters with one call
                    url.setURLParams({ ...dateRange, page: null, status_per_day: null });
                    window.sessionStorage.removeItem('lastFiltered');
                  }}
                  style={{
                    marginBlockEnd: '1rem',
                    marginInlineEnd: '1.5rem',
                  }}
                >
                  עדכון
                </button>
              )}
              showDefaultInputIcon
              minimumNights={0}
              startDate={moment(dateRange.from_date)}
              startDatePlaceholderText='מתאריך'
              startDateId='callBackReportStartDate'
              endDate={moment(dateRange.to_date)}
              endDatePlaceholderText='עד תאריך'
              endDateId='callBackReportEndDate'
              focusedInput={focusedInput || null}
              isOutsideRange={(day) => {
                return day.startOf('day').isAfter(moment());
              }}
              onFocusChange={(input) => setFocusedInput(input)}
              onDatesChange={({ startDate, endDate }) => {
                setDateRange({
                  from_date: startDate?.format('YYYY-MM-DD').toString(),
                  to_date: endDate?.format('YYYY-MM-DD').toString() || startDate?.format('YYYY-MM-DD').toString(),
                });
              }}
            />
          </section>
          <section className={styles.filters}>
            <Filter
              active={url.urlParams.agent_group_numbers}
              badge={
                url.urlParamsMultiValue.agent_group_numbers &&
                url.urlParamsMultiValue.agent_group_numbers.length > 0 ? (
                  <div className={styles.filterBadge}>{url.urlParamsMultiValue.agent_group_numbers.length}</div>
                ) : undefined
              }
              initialValues={{ agent_group_numbers: url.urlParamsMultiValue.agent_group_numbers }}
              label='קבוצה'
              onReset={() => {
                url.removeURLParams(['agent_group_numbers', 'page']);
                window.sessionStorage.removeItem('lastFiltered');
              }}
              onSubmit={(values) => {
                const value = values.agent_group_numbers.join(',');
                url.setURLParams({ agent_group_numbers: value, page: null });
                window.sessionStorage.removeItem('lastFiltered');
              }}
            >
              {selfManagedGroups?.map(({ number, name }) => (
                <Field
                  key={number}
                  component={(props: any) => <Checkbox title={name} {...props.field} />}
                  name='agent_group_numbers'
                  type='checkbox'
                  value={number}
                />
              ))}
            </Filter>
            <Filter
              active={url.urlParams.to_age || url.urlParams.from_age}
              badge={
                url.urlParams.from_age || url.urlParams.to_age ? (
                  <div className={styles.filterBadge}>
                    <OptionsHorizontal size={16} />
                  </div>
                ) : undefined
              }
              initialValues={{
                rangeFunction: url.urlParams.to_age ? 'max' : 'min',
                days: Math.floor(parseInt(url.urlParams.to_age || url.urlParams.from_age) / (24 * 60)) || '',
                hours: (parseInt(url.urlParams.to_age || url.urlParams.from_age) / 60) % 24 || '',
              }}
              label='זמן המתנה לשיחה חוזרת'
              onReset={() => {
                url.removeURLParams(['to_age', 'from_age', 'page']);
                window.sessionStorage.removeItem('lastFiltered');
              }}
              onSubmit={(values) => {
                if (values.days < 0 || values.hours < 0) {
                  values.days = 0;
                  values.hours = 0;
                  return;
                }
                const ttaValue =
                  (parseInt(values.days.toString()) * 24 * 60 || 0) + (parseInt(values.hours.toString()) * 60 || 0);
                if (values.rangeFunction === 'min') {
                  url.setURLParams({
                    to_age: null,
                    from_age: ttaValue,
                    page: null,
                  });
                } else {
                  url.setURLParams({
                    to_age: ttaValue,
                    from_age: null,
                    page: null,
                  });
                }
                window.sessionStorage.removeItem('lastFiltered');
              }}
            >
              <SegmentedControl>
                <Field
                  name='rangeFunction'
                  type='radio'
                  value='min'
                  component={(props: any) => <SegmentedControlOption {...props.field}>לפחות</SegmentedControlOption>}
                />
                <Field
                  name='rangeFunction'
                  type='radio'
                  value='max'
                  component={(props: any) => <SegmentedControlOption {...props.field}>עד</SegmentedControlOption>}
                />
              </SegmentedControl>
              <div className={styles.timeInput}>
                <label className={styles.timeUnit}>
                  <Field autoComplete='off' name='days' type='text' placeholder='0' />
                  <div className={styles.timeUnitLabel}>ימים</div>
                </label>
                <label className={styles.timeUnit}>
                  <Field autoComplete='off' name='hours' type='text' placeholder='0' />
                  <div className={styles.timeUnitLabel}>שעות</div>
                </label>
              </div>
            </Filter>
            <Filter
              active={url.urlParams.attempts}
              badge={
                url.urlParamsMultiValue.attempts && url.urlParamsMultiValue.attempts.length > 0 ? (
                  <div className={styles.filterBadge}>
                    {url.urlParamsMultiValue.attempts.indexOf('...') === -1
                      ? url.urlParamsMultiValue.attempts.length
                      : url.urlParamsMultiValue.attempts.length - 1}
                  </div>
                ) : undefined
              }
              initialValues={{ attempts: url.urlParamsMultiValue.attempts }}
              label='ניסיונות חיוג'
              onReset={() => {
                url.removeURLParams(['attempts', 'page']);
                window.sessionStorage.removeItem('lastFiltered');
              }}
              onSubmit={(values: { attempts: string[] }) => {
                handleAttemptsFilter(values.attempts);
              }}
            >
              {numOfAttempts.map((attempts) => (
                <Field
                  key={attempts}
                  component={(props: any) => (
                    <Checkbox title={attempts ? `ניסיון חיוג ${attempts}` : 'לא בוצע ניסיון חיוג'} {...props.field} />
                  )}
                  name='attempts'
                  type='checkbox'
                  value={`${attempts}`}
                />
              ))}
            </Filter>

            {(url.urlParams.from_age ||
              url.urlParams.to_age ||
              url.urlParams.agent_group_numbers ||
              url.urlParams.attempts) && (
              <button
                className={styles.resetFiltersButton}
                onClick={() => url.removeURLParams(['from_age', 'to_age', 'agent_group_numbers', 'attempts'])}
              >
                איפוס
              </button>
            )}
          </section>
        </AppLayoutContainer>
        {loading ? (
          <div className={styles.loadingMessage}>
            <Spinner />
          </div>
        ) : (
          <div>
            {requests && meta ? (
              <RequestList
                requests={requests}
                params={{ dateRange, handleAttemptOpen, handleStatusSelection }}
                counters={meta.counts}
                onCancel={handleRequestCancellation}
              />
            ) : (
              <></>
            )}
          </div>
        )}
        <div className={styles.pagination}>
          <Pagination
            page={parseInt(url.urlParams.page) || 1}
            max={meta?.pages || 1}
            onPageChange={(page) => {
              url.setURLParam('page', page);
            }}
          />
        </div>
      </AppLayout>
    </Page>
  );
};
