import styles from './callback-requests-list.module.scss';
import { Table } from '../../../../design/table/table';
import { DialerRequestAttributes } from '../../../interfaces';
import { getHebTimeDiff } from '../../../../utils/time/time';
import { safeParsePhoneNumber } from '../../../../utils/strings/parse-phone';
import { Column } from '../../../../design/table/types';

export const DialerRequestsList = ({ dialerRequests }: DialerRequestsListProps) => {
  const refinedData = dialerRequests.map(request => {
    const waitTime = (request.end_date?.getTime() ?? Date.now()) - request.start_date.getTime();
    const returnNumber = request.return_number.match(/^[0-9]+$/)
      ? safeParsePhoneNumber(request.return_number, 'IL')
      : 'לא ניתן להציג את מספר המתקשר';
    return {
        id: request.id, 
        startDate: request.start_date,
        endDate: request.end_date,
        returnNumber,
        waitTime,
        startDateNoTime: request.start_date_no_time
     }

  });

  const columns: Array<Column<typeof refinedData[0], keyof typeof refinedData[0]>> = [
    {
      key: 'startDate',
      header: 'תאריך הבקשה',
      className: styles.callerNumber,
      render: (row) => <div>{row.startDateNoTime}</div>,
      sort: (a, b) => a.startDateNoTime.localeCompare(b.startDateNoTime)
    },
    {
      key: 'returnNumber',
      header: 'מתקשר',
      className: styles.callerNumber
    },
    {
      key: 'waitTime',
      header: 'זמן המתנה לשיחה חוזרת',
      className: styles.waitTime,
      render: (row) => <div>{getHebTimeDiff(row.startDate, row.endDate ?? new Date())} </div>
    }
  ];

  return (
    <Table columns={columns} data={refinedData} showHeaders />
  )
}

interface DialerRequestsListProps {
  dialerRequests: DialerRequestAttributes[],
  callback_alert_threshold?: number,
  tableName?: string | undefined
}
