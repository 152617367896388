import { useState, useEffect } from "react";
import styles from "./horizontal-dates.module.scss"

export const HorizontalDates = ({ dates, selectedDate, onSelectedDateChange }: HorizontalDatesProps) => {
  const [selected, setSelected] = useState(dates.findIndex(date => date.toISOString().substring(0, 10) === selectedDate));

  useEffect(() => {
    setSelected(dates.findIndex(date => date.toISOString().substring(0, 10) === selectedDate));
  }, [selectedDate, dates])

  useEffect(() => {
    onSelectedDateChange(dates[selected]);
  }, [selected])

  return (
    <div className={styles.scrollMenu}>
      {dates.map((date, index) =>
        <div key={date.getTime()} onClick={() => setSelected(index)}>
          <div className={selected === index ? styles.selectedCard : styles.card}>
            <div style={{ direction: 'rtl' }}>{date.toLocaleDateString('he', { day: "numeric", month: "long" })}</div>
          </div>
        </div>
      )}
    </div>
  );
}

interface HorizontalDatesProps {
  dates: Date[],
  selectedDate: string,
  onSelectedDateChange: (date: Date) => void
}
