import styles from './menu.module.scss';
import { Button } from '../../../design/button';
import { AppLayoutBreadcrumbLink, AppLayoutBreadcrumbs } from '../../../layouts/app-layout/app-layout';
import { ArrowRight } from '../../../design/icons';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

export default function Menu({ sections, selectedSection, ...props }: MenuProps) {
  const handleClick = (section: { id: string; name: string }) => {
    const element = document.getElementById(section.id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={styles.menu}>
      <MenuHeader dialerId={props.dialerId} dialerName={props.dialerName} />
      {sections.map((section) => {
        const isSelected = section.id === selectedSection.id;
        return (
          <Button
            key={section.id}
            className={classNames(styles.scrollButton, { [styles.selected]: isSelected })}
            onClick={() => handleClick(section)}
          >
            {isSelected ? <span className={styles.colorRect} /> : undefined}
            {section.name}
          </Button>
        );
      })}
    </div>
  );
}

interface MenuProps {
  sections: { id: string; name: string }[];
  selectedSection: { id: string; name: string };
  dialerId: number;
  dialerName: string;
}

const MenuHeader = ({ dialerId, dialerName }: MenuHeaderProps) => {
  const navigate = useNavigate();
  return (
    <div className={styles.menuHeader}>
      <div className={styles.breadCrumbs}>
        <ArrowRight className={styles.arrowRight} onClick={() => navigate('/emergency-dialer/dialers/')} />
        <AppLayoutBreadcrumbs>
          <AppLayoutBreadcrumbLink to='/emergency-dialer/dialers/'>רשימה</AppLayoutBreadcrumbLink>
          <AppLayoutBreadcrumbLink to={`/emergency-dialer/dialers/${dialerId}`}>
            {dialerId} {dialerName}
          </AppLayoutBreadcrumbLink>
        </AppLayoutBreadcrumbs>
      </div>

      <div className={styles.menuTitle}>
        {dialerId} {dialerName}
      </div>
    </div>
  );
};
interface MenuHeaderProps {
  dialerId: number;
  dialerName: string;
}
