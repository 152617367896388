/**
 * This function takes two strings describing a date and produces a list of dates in that range
 * 
 * @param fromDate A string formatted starting date, in any format the JS Date object constructor accepts.
 * @param toDate A string formatted ending date, in any format the JS Date object constructor accepts.
 * @returns An array of JS date objects starting at fromDate and ending at toDate.
 */
export const createDateList = (fromDate: string, toDate: string, newFirst = false): Date[] => {
  const dates: Date[] = [];

  try {
    const date = new Date(fromDate);
    const endDate = new Date(toDate);
    while (date <= endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
  } catch (error: any) {
    throw error
  }
  newFirst && dates.reverse();
  return dates;
}