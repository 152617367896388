import React from 'react';
import { Icon, IconProps } from './icon';

export const NavOpen = ({ ...props }: IconProps) => (
  <Icon
    viewBox="0 0 96 96"
    fill="currentColor"
    {...props}
  >
    <g fill="currentColor">
      <path d="M17,32 L79,32 L79,40 L17,40 L17,32 Z M17,56 L79,56 L79,64 L17,64 L17,56 Z"></path>
    </g>
  </Icon>
);


