import { useEffect, useState } from "react";
import { NetworkObject } from "../api/types";
import { secureFetch } from "../auth/server-auth";

const DEFAULT_REFRESH_RATE = 10 * 1000; // refresh data every x seconds

export const useNetworkData = (domainUuid: string | undefined, refreshRate?: number) => {
  const [data, setData] = useState<NetworkObject[]>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!domainUuid) { return; }
      setLoading(true);
      const url = `${process.env.REACT_APP_NETWORK_MONITOR_URL}/dom/${domainUuid}`;

      try {
        const { res } = await secureFetch(url);
        if (res.status === 200) {
          const jsonData = await res.json();
          setData(jsonData.data);
        }
      } catch (err) {
        console.error('Error: ', err);
      } finally {
        setLoading(false);
      }
    }
    
    if (!domainUuid) {
      return;
    }

    fetchData()
    .catch(e => console.error('Error: ', e));
    const intervalId: NodeJS.Timer = setInterval(fetchData, refreshRate || DEFAULT_REFRESH_RATE);

    return () => {
      clearInterval(intervalId);
    }
  }, [domainUuid]);

  
  return { data, loading: loading && !data };
}