import { Field } from 'formik';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { MonitorProps } from '.';
import { GroupResource } from '../api/types';
import { Button } from '../design/button';
import { Checkbox } from '../design/checkbox/checkbox';
import { Filter } from '../design/filter/filter';
import { Fullscreen } from '../design/icons/fullscreen';
import { Spinner } from '../design/spinner/spinner';
import { isCallInboundAbandoned, isCallInboundAnswered } from '../utils/calls/filter';
import { SLAChart } from './common/charts';
import { AgentsChart } from './common/charts/agents-chart';
import { Card } from './common/components';
import { TablesContainer } from './common/components/tables-container/tables-container';
import { WaitingCalls } from './common/components/waiting-calls-table/WaitingCallsCard';
import {
  AgentOutboundState,
  AgentState,
  AgentStatus,
  BreakType,
  CallAttributes,
  HebStatusType,
  StatusType,
} from './interfaces';
import styles from './monitor.module.scss';
import { MonitorConfiguration } from './use-monitor-config';
import classNames from 'classnames';

const FULL_SCREEN_TOOLTIP = 'תצוגת מסך מלא. אם הכפתור לא מגיב ללחיצה ניתן להשתמש במקש F11';
export const RED = '#C94236';
export const YELLOW = '#F1C21B';
export const GREY = '#9EA5AD';
export const BLUE = '#28A0FF';
export const GREEN = '#51D5A5';

export const STATUSES_FOR_PIE_CHART: { id: StatusType; name: string; color: string }[] = [
  { id: StatusType.Available, name: 'בזמינות', color: GREEN },
  { id: StatusType.Busy, name: 'בשיחה', color: RED },
  { id: StatusType.Break, name: 'הפסקה', color: GREY },
  { id: StatusType.Mission, name: 'משימה', color: YELLOW },
];

// getStatus
export const getStatus = (
  status: AgentStatus,
  state: AgentState,
  outboundState: AgentOutboundState | undefined,
  breakType?: BreakType | undefined
): StatusType => {
  if (outboundState) {
    return StatusType.BusyOutbound;
  }

  switch (status) {
    case AgentStatus.OnBreak:
      switch (breakType?.type) {
        case 'mission':
          return StatusType.Mission;
        case 'break':
          return StatusType.Break;
        case undefined:
          return StatusType.Break;
      }

    case AgentStatus.Available:
    case AgentStatus.AvailableOnDemand:
      switch (state) {
        case AgentState.Waiting:
          return StatusType.Available;
        case AgentState.InQueueCall:
          return StatusType.Busy;
        case AgentState.Receiving:
          return StatusType.Receiving;
      }
      break;
  }

  return StatusType.Mission; // shouldn't happen
};

export const getHebStatus = (status: AgentStatus, state: AgentState, outboundState: AgentOutboundState | undefined) => {
  const statusType = getStatus(status, state, outboundState);

  switch (statusType) {
    case StatusType.Available:
      return HebStatusType.Available;
    case StatusType.Busy:
      return HebStatusType.Busy;
    case StatusType.BusyOutbound:
      return HebStatusType.BusyOutbound;
    case StatusType.Receiving:
      return HebStatusType.Receiving;
    case StatusType.Break:
      return HebStatusType.Break;
    case StatusType.Mission:
      return HebStatusType.Mission;
  }
};

export const DesktopMonitor = memo(({ ...props }: MonitorProps) => {
  const { agents, calls, dialerRequests, groups } = props.data;
  const loading =
    groups === undefined ||
    props.selectedGroups === undefined ||
    props.monitorConfig === undefined ||
    props.breakTypes === undefined;

  const { toggleFullScreen, onGroupSelection, onTabClick, selectedGroups, monitorConfig } = props;

  const selfManagedGroupUuids = props.user.domain.config.selfManagedGroups || [];

  return loading ? (
    <div className={styles.loadingMessage}>
      <Spinner />
    </div>
  ) : (
    <div className={styles.monitorContainer}>
      {/* בחירת קבוצות + שני כפתורים למעלה משמאל */}
      <ButtonsBar
        toggleFullScreen={toggleFullScreen}
        groups={groups}
        selectedGroups={selectedGroups}
        onGroupsSelection={onGroupSelection}
      />

      <div className={styles.dashboardContainer}>
        {/* רמת שירות */}
        <Card className={styles.SLA}>
          <SLAChart
            calls={calls}
            slaThreshold={monitorConfig.ideal_answer_time}
            selfManagedGroupUuids={selfManagedGroupUuids}
            monitoredGroupUuids={selectedGroups}
            validCallThreshold={monitorConfig.valid_call_threshold}
          />
        </Card>

        {/* פס מספרים עליון */}
        <div className={styles.counters}>
          {/* סה"כ שיחות */}
          <Card className={styles.total}>
            סה"כ
            <div className={styles.dataContent}>{calls.filter((call) => call.callDirection !== 'local').length}</div>
          </Card>

          {/* נכנסות, נענו, לא נענו, שיחה חוזרת */}
          <InboundCallsCounters calls={calls} dialerRequests={dialerRequests} monitorConfig={monitorConfig} />

          {/* יוצאות */}
          <Card className={styles.total}>
            יוצאות
            <div className={styles.dataContent}>{calls.filter((call) => call.callDirection === 'outbound').length}</div>
          </Card>
        </div>

        {/* ממתינות */}
        <Card className={styles.waitingCallsContainer}>
          <WaitingCalls
            calls={calls}
            groupUuids={selectedGroups}
            waitingThreshold={monitorConfig.waiting_alert_threshold}
          />
        </Card>

        {/* מצב סוכנים מימין */}
        <Card className={styles.agents}>
          <AgentsChart agents={agents} breakTypes={props.breakTypes} />
        </Card>

        {/* טאבים בתחתית */}
        <Card className={styles.users}>
          <TablesContainer
            groups={groups}
            agents={agents}
            calls={calls}
            dialerRequests={dialerRequests}
            selectedGroups={selectedGroups}
            monitorConfig={monitorConfig}
            breakTypes={props.breakTypes}
            onTabClick={onTabClick}
          />
        </Card>
      </div>
    </div>
  );
});

// בחירת קבוצות + שני כפתורים למעלה משמאל
const ButtonsBar = memo(({ selectedGroups, groups, toggleFullScreen, onGroupsSelection }: ButtonBarProps) => {
  const handleReset = () => {
    onGroupsSelection(groups.map((group) => group.id));
  };

  const handleSubmit = (values: any) => {
    onGroupsSelection(values.groups);
  };

  const initialValues = { groups: selectedGroups?.length ? selectedGroups : groups.map((g) => g.id) };

  return (
    <div className={styles.controls}>
      <Filter
        active={false}
        initialValues={initialValues}
        variant='button-like'
        label='מחלקות'
        onReset={handleReset}
        onSubmit={handleSubmit}
      >
        <div className={styles.filterWithRows}>
          {groups ? (
            groups.map((group) => (
              <div key={group.id} className={styles.groupFilterRow}>
                <Field
                  component={(props: any) => <Checkbox title={group.name} {...props.field} />}
                  name='groups'
                  type='checkbox'
                  value={group.id}
                  key={group.id}
                />
              </div>
            ))
          ) : (
            <Spinner />
          )}
        </div>
      </Filter>

      <div className={styles.leftButtons}>
        <Link to={`/monitor/config`}>
          <Button>הגדרות</Button>
        </Link>
        <Button className={styles.fullScreenButton} title={FULL_SCREEN_TOOLTIP} onClick={toggleFullScreen}>
          <span>
            <Fullscreen />
          </span>
          <span>מסך מלא</span>
        </Button>
      </div>
    </div>
  );
});

interface ButtonBarProps {
  toggleFullScreen: () => void;
  groups: GroupResource[];
  selectedGroups: string[];
  onGroupsSelection: (groups: string[]) => void;
}

const InboundCallsCounters = memo(
  ({
    calls,
    dialerRequests,
    monitorConfig,
  }: {
    calls: CallAttributes[];
    dialerRequests: any[];
    monitorConfig: MonitorConfiguration;
  }) => {
    const inboundCalls = calls.filter((call) => call.callDirection === 'inbound');
    const answeredInbound = inboundCalls.filter((call) =>
      isCallInboundAnswered(call, monitorConfig.valid_call_threshold)
    );
    const unansweredInbound = inboundCalls.filter((call) =>
      isCallInboundAbandoned(call, monitorConfig.valid_call_threshold)
    );

    const unansweredOverThreshold = unansweredInbound.length >= monitorConfig.unanswered_alert_threshold;
    const dialerRequestsClass =
      dialerRequests.length >= monitorConfig.callback_alert_threshold
        ? styles.counterOverThreshold
        : styles.inboundCounter;

    return (
      <Card className={styles.inboundCallsCounters}>
        <div className={styles.inboundCounter}>
          נכנסות
          <div className={styles.dataContent}>{inboundCalls.length}</div>
        </div>

        <div className={styles.partitionVertical} />

        {/* <div className={styles.inboundCallsDescription}> */}
        <div className={styles.inboundCounter}>
          נענו
          <div className={styles.dataContent}>{answeredInbound.length}</div>
        </div>

        <div className={classNames(styles.inboundCounter, { [styles.counterOverThreshold]: unansweredOverThreshold })}>
          לא נענו
          <div className={styles.dataContent}>{unansweredInbound.length}</div>
        </div>

        <div className={dialerRequestsClass}>
          בקשה לשיחה חוזרת
          <div className={styles.dataContent}>{dialerRequests.length}</div>
        </div>
        {/* </div> */}
      </Card>
    );
  }
);
