import { ChangeEvent, useContext, useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { Field } from 'formik';
import moment from 'moment';
import 'moment-duration-format';
import { Checkbox } from '../design/checkbox/checkbox';
import { Filter } from '../design/filter/filter';
import { Pagination } from '../design/pagination/pagination';
import { Search } from '../design/search';
import { SegmentedControl, SegmentedControlOption } from '../design/segmented-control';
import { CallList } from './call-list/call-list';
import { CallType, CallTypeTabs } from './call-type-filter/call-type-tabs';
import styles from './report.module.scss';
import { OptionsHorizontal } from '../design/icons';
import { useLocation, useParams } from 'react-router-dom';
import { CallShape } from './types';
import { selectedCallContext } from './selected-call';
import { useURLParams } from '../app/use-url-params';
import { useCalls } from './use-calls';
import { playerContext } from './player-state';
import { Button } from '../design/button';
import { Page } from '../page';
import { AppLayout, AppLayoutHeader, AppLayoutTitle, useMediaQuery } from '../layouts/app-layout/app-layout';
import { useMe } from '../api/use-me';
import { useBusinessNumbers } from '../api/use-business-number';
import { mobileQuery } from '../theme/media';
import { MultiSelect } from '../design/multi-select/multi-select';
import { exportCdrReport } from '../api/export-report';
import { useUsers } from '../api/use-users';
import { useAllExtensions } from '../api/use-extensions';
import { BusinessNumberResource, ExtensionResource, GroupResource, PermissionGroup } from '../api/types';
import useDebounce from 'react-use/lib/useDebounce';
import { useAllGroups } from '../api/use-groups';
import { TEXTS } from './constants/texts';
import { FILTER_KEYS, URL_PARAMS_SORT_LIST } from './constants/constants';
import { getSelfManagedNumbers } from '../utils/callsHistory/getSelfManagedNumbers';
import { getSelfManagedExtensions } from '../utils/callsHistory/getSelfManagedExtensions';
import { getSelfManagedGroups } from '../utils/callsHistory/getSelfManagedGroups';

import NewDateRange, { DateRange, TimeRange } from '../design/date-range/new-date-range';

export const CallsLog = () => {
  const user = useMe();
  const isMobile = useMediaQuery(mobileQuery);
  const multiTenantUser = useUsers(user.data?.id)?.users?.[0];
  const params = useParams<any>()['*']?.replace(/^\/|\/$/g, '');
  const { groupedData: groupedGroups } = useAllGroups(multiTenantUser);
  const url = useURLParams({ path: '/calls-history', sortList: URL_PARAMS_SORT_LIST, params });

  const { calls, error: callsError, loading: loadingCalls } = useCalls(url.urlParams);
  const {
    data: businessNumbers,
    error: busNumError,
    loading: loadingBusinessNumbers,
  } = useBusinessNumbers(multiTenantUser);
  const {
    groupedData: groupedExtensions,
    error: extensionsError,
    loading: loadingExtensions,
  } = useAllExtensions(multiTenantUser);

  const [downloading, setDownloading] = useState<boolean>(false);
  const [resultCounters, setResultCounters] = useState<any>({});
  const [searchString, setSearchString] = useState<string | undefined>(url.urlParams.phone);
  const [debouncedSearchString, setDebouncedSearchString] = useState<string | undefined>(searchString);

  const [, setActiveCall] = useContext(selectedCallContext);
  const [, setPlayerVisibility] = useContext(playerContext);

  const currentDomainUuid = user?.data?.domain.id;
  const userDomains = multiTenantUser?.domains;
  const areFiltersActive = Object.keys(url.urlParams).filter((param) => FILTER_KEYS.includes(param)).length > 0;
  const selfManagedNumbers = getSelfManagedNumbers(businessNumbers, multiTenantUser);
  const extensions = getSelfManagedExtensions(groupedExtensions, multiTenantUser);
  const groups = getSelfManagedGroups(groupedGroups, multiTenantUser);
  const cognitoUserGroups = user?.data?.groups;
  const isSupport = cognitoUserGroups?.includes(PermissionGroup.Support);
  const isMultiTenant = userDomains?.length ? userDomains.length > 1 : false;

  useDebounce(
    () => {
      setDebouncedSearchString(searchString);
    },
    2000,
    [searchString]
  );

  const { pathname } = useLocation();
  const isBasePath = pathname === '/calls-history';

  useEffect(() => {
    if (debouncedSearchString && debouncedSearchString.length > 0) {
      url.setURLParam('phone', debouncedSearchString);
      mixpanel.track('Search Number');
    } else {
      url.removeURLParam('phone');
    }
  }, [debouncedSearchString, url.removeURLParam, url.setURLParam]);

  useEffect(() => {
    if (isBasePath || !url.urlParams.from || !url.urlParams.to) {
      const range = {
        from: moment().subtract(1, 'week').format('YYYY-MM-DD').toString(),
        to: moment().format('YYYY-MM-DD').toString(),
      };
      url.setURLParams(range);
    }

    if (!url.urlParams.fromTime || !url.urlParams.toTime) {
      url.setURLParams({ fromTime: '00:00', toTime: '23:59' });
    }
  }, [url.urlParams.from, url.urlParams.to, url.urlParams.fromTime, url.urlParams.toTime, url.setURLParams]);

  const handleDateChange = (date: DateRange, time: TimeRange) => {
    url.setURLParams({ ...date, ...time });
  };

  useEffect(() => {
    if (calls) {
      setResultCounters({ ...calls.meta.count });
    }
  }, [calls]);

  const handleCallOpen = (call: CallShape) => {
    setActiveCall(call);
    url.setURLParam('call', call.uuid);
  };

  const handleCallPlay = (call: CallShape) => {
    setPlayerVisibility(true);
    mixpanel.track('Play Recording', {
      Context: 'Calls List',
      'Call Direction': call.direction,
      Searched: url.urlParams.hasOwnProperty('phone'),
    });
    mixpanel.people.increment('Recordings Played');
    mixpanel.people.set('Last Played Recording', moment().toISOString());
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
  };

  const handleSearchReset = () => {
    setSearchString(undefined);
    url.removeURLParam('phone');
  };

  const handleExportRequest = async () => {
    try {
      setDownloading(true);
      await exportCdrReport(url.urlParams);
    } catch (error) {
      console.error(error);
    } finally {
      setDownloading(false);
    }
  };

  const trackFilterEvent = (name: string, value: any) => {
    mixpanel.track('Filter Calls', {
      'Applied Filters': Object.keys(url.urlParams),
      Name: name,
      Value: value,
    });
  };

  const parsedCounters = Object.entries(resultCounters).reduce(
    (acc, curr) => {
      const counter = parseInt(curr[1] as string);

      if (isNaN(counter)) return acc;
      return {
        ...acc,
        [curr[0]]: counter,
      };
    },
    {
      total: 0,
      inbound: 0,
      outbound: 0,
      callback: 0,
      local: 0,
    }
  );

  const extensionsToMultiSelectMapper = (extensions: ExtensionResource[]) => {
    const mapper = (extension: ExtensionResource) => ({
      id: extension.destinationNumber,
      label: `${extension.destinationNumber} ${extension.description || ''}`,
    });
    return extensions?.map(mapper);
  };

  const businessNumbersToMultiSelect = (numbers: BusinessNumberResource[]) => {
    return numbers?.map((number) => ({
      id: number.number,
      label: number.label ? `${number.number} - ${number.label}` : `${number.number}`,
    }));
  };

  const groupsToMultiSelectMapper = (groups: GroupResource[]) => {
    const mapper = (group: GroupResource) => ({
      id: group.number!,
      label: `${group.number} ${group.description || ''}`,
    });
    return groups?.map(mapper);
  };

  const getMultiSelectInitialValues = (filterKey: string) => {
    const entries = url.urlParams[filterKey]
      ?.split(';')
      ?.map((query: string) => query.split(':'))
      ?.map(([k, v]: string[]) => [k, v.split(',')]);

    const initialValues = Object.fromEntries<string[]>(entries || []);
    return initialValues;
  };

  const handleSubmitMultiSelectFilter = (filterKey: string, values: Record<string, string[]>) => {
    const param = Object.entries(values)
      .filter(([key, value]) => value.length !== 0)
      .map(([key, value]) => [key, value.join(',')])
      .map((query) => query.join(':'))
      .join(';');

    url.setURLParam(filterKey, param);
  };

  const handleTabChange = (type: CallType | undefined) => {
    url.setURLParams({
      type,
      page: null,
    });

    mixpanel.track('Change Call Direction', {
      Name: type || 'all',
    });
  };

  const callTypeTabsValues = {
    all: parsedCounters.total.toLocaleString(),
    inbound: parsedCounters.inbound.toLocaleString(),
    outbound: parsedCounters.outbound.toLocaleString(),
    callback: parsedCounters.callback.toLocaleString(),
    local: parsedCounters.local.toLocaleString(),
  };

  if (
    (callsError || extensionsError || busNumError) &&
    !(loadingCalls || loadingExtensions || loadingBusinessNumbers)
  ) {
    return (
      <Page name='Calls History Error'>
        <AppLayout>
          <div className={styles.generalErrorMessage}>
            <div className={styles.title}>{TEXTS.ERROR_TITLE}</div>
            <div className={styles.content}>
              {TEXTS.ERROR_CONTENT_TEXT}
              <br />
              <a href={`mailto:${TEXTS.ERROR_CONTENT_SUPPORT_EMAIL}`}>{TEXTS.ERROR_CONTENT_SUPPORT_EMAIL}</a>
              {TEXTS.ERROR_CONTENT_SUPPORT_TEXT}
              <a href={`tel:${TEXTS.ERROR_CONTENT_SUPPORT_PHONE}`}>{TEXTS.ERROR_CONTENT_SUPPORT_PHONE}</a>
            </div>
            <Button className={styles.reload} onClick={() => window.location.reload()}>
              {TEXTS.ERROR_ACTION}
            </Button>
          </div>
        </AppLayout>
      </Page>
    );
  }

  const data = calls?.data.map((call) => {
    return {
      ...call,
      domainDescription: userDomains?.find((domain) => domain.domain_uuid === call.domain_uuid)?.domain_owner_name,
    };
  });

  return (
    <Page name='Calls History'>
      <AppLayout>
        <AppLayoutHeader className={styles.header}>
          <AppLayoutTitle>{TEXTS.TITLE}</AppLayoutTitle>
          <Button
            loading={downloading}
            variant='link'
            style={{ width: '8rem' }}
            onClick={handleExportRequest}
            disabled={calls?.data.length === 0}
          >
            {TEXTS.EXPORT}
          </Button>
        </AppLayoutHeader>
        <section className={styles.searchBar}>
          <Search
            placeholder={TEXTS.SEARCH_PLACEHOLDER}
            onChange={handleSearch}
            onReset={handleSearchReset}
            value={searchString || ''}
          />
          <NewDateRange
            path='/calls-history'
            urlParamsSortList={URL_PARAMS_SORT_LIST}
            onDateChange={handleDateChange}
          />
        </section>
        <section className={styles.callTabs}>
          <CallTypeTabs
            activeTab={url.urlParams.type}
            onChange={handleTabChange}
            className={styles.typeFilter}
            values={callTypeTabsValues}
          />
        </section>
        <section className={isMobile ? styles.mobileFilters : styles.filters}>
          <Filter
            active={url.urlParams.status}
            badge={
              url.urlParamsMultiValue.status && url.urlParamsMultiValue.status?.length ? (
                <div className={styles.filterBadge}>{url.urlParamsMultiValue.status.length}</div>
              ) : undefined
            }
            initialValues={{ status: url.urlParamsMultiValue.status }}
            label={TEXTS.STATUS}
            onReset={() => url.removeURLParam('status')}
            onSubmit={(values) => {
              const value = values.status.join(',');
              url.setURLParam('status', value);
              trackFilterEvent('Status', values.status);
            }}
          >
            <div className={styles.filterWithRows}>
              <Field
                component={(props: any) => <Checkbox title={TEXTS.ANSWERED} {...props.field} />}
                name='status'
                type='checkbox'
                value='answered'
              />
              <Field
                component={(props: any) => <Checkbox title={TEXTS.NOT_ANSWER} {...props.field} />}
                name='status'
                type='checkbox'
                value='failed'
              />
              <Field
                component={(props: any) => <Checkbox title={TEXTS.VOICE_MAIL} {...props.field} />}
                name='status'
                type='checkbox'
                value='voicemail'
              />
            </div>
          </Filter>
          <Filter
            active={url.urlParams.maxTta || url.urlParams.minTta}
            badge={
              url.urlParams.minTta || url.urlParams.maxTta ? (
                <div className={styles.filterBadge}>
                  <OptionsHorizontal size={16} />
                </div>
              ) : undefined
            }
            initialValues={{
              rangeFunction: url.urlParams.maxTta ? 'max' : 'min',
              minutes: Math.floor(parseInt(url.urlParams.maxTta || url.urlParams.minTta) / 60) || '',
              seconds: Math.floor(parseInt(url.urlParams.maxTta || url.urlParams.minTta) % 60) || '',
            }}
            label={TEXTS.WAITING_FOR_ANSWER}
            onReset={() => url.removeURLParams(['maxTta', 'minTta'])}
            onSubmit={(values) => {
              const ttaValue =
                (parseInt(values.minutes.toString()) * 60 || 0) + (parseInt(values.seconds.toString()) || 0);
              if (values.rangeFunction === 'min') {
                url.setURLParams({
                  maxTta: null,
                  minTta: ttaValue,
                });
                trackFilterEvent('Minimum TTA', ttaValue.toString());
              } else {
                url.setURLParams({
                  maxTta: ttaValue,
                  minTta: null,
                });
                trackFilterEvent('Maximum TTA', ttaValue.toString());
              }
            }}
          >
            <SegmentedControl>
              <Field
                name='rangeFunction'
                type='radio'
                value='min'
                component={(props: any) => (
                  <SegmentedControlOption {...props.field}>{TEXTS.AT_LEAST}</SegmentedControlOption>
                )}
              />
              <Field
                name='rangeFunction'
                type='radio'
                value='max'
                component={(props: any) => (
                  <SegmentedControlOption {...props.field}>{TEXTS.UNTIL}</SegmentedControlOption>
                )}
              />
            </SegmentedControl>
            <div className={styles.timeInput}>
              <label className={styles.timeUnit}>
                <Field autoComplete='off' name='minutes' type='text' placeholder='0' />
                <div className={styles.timeUnitLabel}>{TEXTS.MINUTES}</div>
              </label>
              <label className={styles.timeUnit}>
                <Field autoComplete='off' name='seconds' type='text' placeholder='0' />
                <div className={styles.timeUnitLabel}>{TEXTS.MILLISECONDS}</div>
              </label>
            </div>
          </Filter>
          <Filter
            active={url.urlParams.maxDuration || url.urlParams.minDuration}
            badge={
              url.urlParams.minDuration || url.urlParams.maxDuration ? (
                <div className={styles.filterBadge}>
                  <OptionsHorizontal size={16} />
                </div>
              ) : undefined
            }
            initialValues={{
              rangeFunction: url.urlParams.maxDuration ? 'max' : 'min',
              minutes: Math.floor(parseInt(url.urlParams.maxDuration || url.urlParams.minDuration || 0) / 60) || '',
              seconds: Math.floor(parseInt(url.urlParams.maxDuration || url.urlParams.minDuration || 0) % 60) || '',
            }}
            label={TEXTS.CALL_DURATION}
            onReset={() => url.setURLParams({ minDuration: undefined, maxDuration: undefined })}
            onSubmit={(values) => {
              const durationValue =
                (parseInt(values.minutes.toString()) * 60 || 0) + (parseInt(values.seconds.toString()) || 0);
              if (values.rangeFunction === 'min') {
                url.setURLParams({
                  maxDuration: null,
                  minDuration: durationValue,
                });
                trackFilterEvent('Minimum Duration', durationValue.toString());
              } else {
                url.setURLParams({
                  maxDuration: durationValue,
                  minDuration: null,
                });
                trackFilterEvent('Maximum Duration', durationValue.toString());
              }
            }}
          >
            <SegmentedControl>
              <Field
                name='rangeFunction'
                type='radio'
                value='min'
                component={(props: any) => (
                  <SegmentedControlOption {...props.field}>{TEXTS.AT_LEAST}</SegmentedControlOption>
                )}
              />
              <Field
                name='rangeFunction'
                type='radio'
                value='max'
                component={(props: any) => (
                  <SegmentedControlOption {...props.field}>{TEXTS.UNTIL}</SegmentedControlOption>
                )}
              />
            </SegmentedControl>
            <div className={styles.timeInput}>
              <label className={styles.timeUnit}>
                <Field autoComplete='off' name='minutes' type='text' placeholder='0' />
                <div className={styles.timeUnitLabel}>{TEXTS.MINUTES}</div>
              </label>
              <label className={styles.timeUnit}>
                <Field autoComplete='off' name='seconds' type='text' placeholder='0' />
                <div className={styles.timeUnitLabel}>{TEXTS.MILLISECONDS}</div>
              </label>
            </div>
          </Filter>
          <Filter /** Business numbers filter */
            initialValues={getMultiSelectInitialValues('businessNumbersPerDomain')}
            active={url.urlParams.businessNumbersPerDomain}
            badge={
              url.urlParams.businessNumbersPerDomain && url.urlParamsMultiValue.businessNumbersPerDomain?.length ? (
                <div className={styles.filterBadge}>
                  {url.urlParamsMultiValue.businessNumbersPerDomain?.length
                    ? url.urlParamsMultiValue.businessNumbersPerDomain.length
                    : ''}
                </div>
              ) : undefined
            }
            label={TEXTS.BUSINESS_PHONES}
            onReset={() => url.removeURLParam('businessNumbersPerDomain')}
            onSubmit={(values) => {
              trackFilterEvent('businessNumbers', values.businessNumbersPerDomain);
              handleSubmitMultiSelectFilter('businessNumbersPerDomain', values);
            }}
          >
            <div className={styles.filterWithRows}>
              {userDomains
                ?.filter((domain) => selfManagedNumbers?.[domain.domain_uuid]?.length)
                ?.sort((a, b) => (a.domain_uuid === currentDomainUuid ? -1 : 1))
                ?.map((domain) => {
                  const shortDomainName = domain.domain_name.split('.')[0];
                  const domainInitialSelections =
                    getMultiSelectInitialValues('businessNumbersPerDomain')[shortDomainName] || [];
                  return (
                    <MultiSelect
                      key={domain.domain_uuid}
                      initialSelections={domainInitialSelections}
                      items={businessNumbersToMultiSelect(selfManagedNumbers[domain.domain_uuid])}
                      primaryItem={{ id: shortDomainName, label: domain.domain_owner_name }}
                      hidePrimaryItem={userDomains.length === 1}
                      itemsCountForSearch={5}
                    />
                  );
                })}
            </div>
          </Filter>
          <Filter /** Extensions filter */
            initialValues={getMultiSelectInitialValues('extensionsPerDomain')}
            active={url.urlParams.extensionsPerDomain}
            badge={
              url.urlParamsMultiValue.extensionsPerDomain?.length ? (
                <div className={styles.filterBadge}>
                  {url.urlParamsMultiValue.extensionsPerDomain?.length
                    ? url.urlParamsMultiValue.extensionsPerDomain.length
                    : ''}
                </div>
              ) : undefined
            }
            label={TEXTS.EXTENSIONS}
            onReset={() => url.removeURLParam('extensionsPerDomain')}
            onSubmit={(values) => {
              trackFilterEvent('extensions', values.extensionsPerDomain);
              handleSubmitMultiSelectFilter('extensionsPerDomain', values);
            }}
          >
            <div className={styles.filterWithRows}>
              {userDomains
                ?.filter((domain) => extensions?.[domain.domain_uuid]?.length)
                ?.sort((a, b) => (a.domain_uuid === currentDomainUuid ? -1 : 1))
                ?.map((domain) => {
                  const shortDomainName = domain.domain_name.split('.')[0];
                  const domainInitialSelections =
                    getMultiSelectInitialValues('extensionsPerDomain')[shortDomainName] || [];
                  return (
                    <MultiSelect
                      key={domain.domain_uuid}
                      initialSelections={domainInitialSelections}
                      items={extensionsToMultiSelectMapper(extensions[domain.domain_uuid])}
                      primaryItem={{ id: shortDomainName, label: domain.domain_owner_name }}
                      hidePrimaryItem={userDomains.length === 1}
                      itemsCountForSearch={5}
                    />
                  );
                })}
            </div>
          </Filter>
          {isSupport && (
            <Filter /** Groups filter */
              initialValues={getMultiSelectInitialValues('groupsPerDomain')}
              active={url.urlParams.groupsPerDomain}
              badge={
                url.urlParamsMultiValue.groupsPerDomain?.length ? (
                  <div className={styles.filterBadge}>
                    {url.urlParamsMultiValue.groupsPerDomain?.length
                      ? url.urlParamsMultiValue.groupsPerDomain.length
                      : ''}
                  </div>
                ) : undefined
              }
              label={TEXTS.GROUPS}
              onReset={() => url.removeURLParam('groupsPerDomain')}
              onSubmit={(values) => {
                trackFilterEvent('groups', values.groupsPerDomain);
                handleSubmitMultiSelectFilter('groupsPerDomain', values);
              }}
            >
              <div className={styles.filterWithRows}>
                {userDomains
                  ?.filter((domain) => groups?.[domain.domain_uuid]?.length)
                  ?.sort((a, b) => (a.domain_uuid === currentDomainUuid ? -1 : 1))
                  ?.map((domain) => {
                    const shortDomainName = domain.domain_name.split('.')[0];
                    const domainInitialSelections =
                      getMultiSelectInitialValues('groupsPerDomain')[shortDomainName] || [];
                    return (
                      <MultiSelect
                        key={domain.domain_uuid}
                        initialSelections={domainInitialSelections}
                        items={groupsToMultiSelectMapper(groups[domain.domain_uuid])}
                        primaryItem={{ id: shortDomainName, label: domain.domain_owner_name }}
                        hidePrimaryItem={userDomains.length === 1}
                        itemsCountForSearch={5}
                      />
                    );
                  })}
              </div>
            </Filter>
          )}
          {isMultiTenant && isSupport && (
            <Filter
              active={url.urlParams.domains}
              badge={
                url.urlParamsMultiValue.domains && url.urlParamsMultiValue.domains?.length ? (
                  <div className={styles.filterBadge}>{url.urlParamsMultiValue.domains.length}</div>
                ) : undefined
              }
              initialValues={{ domains: url.urlParamsMultiValue.domains }}
              label={TEXTS.ORGANIZATIONS}
              onReset={() => url.removeURLParam('domains')}
              onSubmit={(values) => {
                const value = values.domains.join(',');
                url.setURLParam('domains', value);
                trackFilterEvent('domains', values.status);
              }}
            >
              <div className={styles.filterWithRows}>
                {userDomains?.map((domain) => (
                  <Field
                    key={domain.domain_uuid}
                    component={(props: any) => <Checkbox title={domain.domain_owner_name} {...props.field} />}
                    name='domains'
                    type='checkbox'
                    value={domain.domain_name.substring(0, 4)}
                  />
                ))}
              </div>
            </Filter>
          )}
          {areFiltersActive && (
            <button className={styles.resetFiltersButton} onClick={() => url.removeURLParams(FILTER_KEYS)}>
              {TEXTS.RESET}
            </button>
          )}
        </section>
        <CallList data={data} multiTenant={isMultiTenant} onOpen={handleCallOpen} onPlay={handleCallPlay} />
        <div className={styles.pagination}>
          <Pagination
            page={parseInt(url.urlParams.page) || 1}
            min={1}
            max={calls?.meta.totalPages}
            onPageChange={(page) => {
              mixpanel.track('Paginate', {
                'Current Page': url.urlParams.page || 1,
                Destination: page,
                'Total Pages': calls?.meta.totalPages,
              });
              url.setURLParam('page', page);
            }}
          />
        </div>
      </AppLayout>
    </Page>
  );
};
