import { API } from "aws-amplify";
import { DialerEvent } from "../api/dialers";
import useSWR from 'swr';
import moment from "moment";

const SWR_CONFIG = { revalidateOnFocus: false, revalidateOnReconnect: false };
const defaultDates = {
  from: moment().subtract(1, 'week').format('YYYY-MM-DD').toString(),
  to: moment().format('YYYY-MM-DD').toString(),
};

export const useDialerEvents = (fromDate?: string, toDate?: string) => {
  const endpoint = `/dialer-event?from=${fromDate || defaultDates.from}&to=${toDate || defaultDates.to}`;
  const { data, error, mutate, isLoading } = useSWR(endpoint, fetcher, SWR_CONFIG);

  return {
    data: isLoading ? undefined : data?.data as DialerEvent[],
    error: error,
    loading: isLoading,
    mutate: mutate
  }
}

export const useDialerEvent = (eventId: number) => {
  const endpoint = `/dialer-event?event_id=${eventId}`;
  
  const { data, error, mutate, isLoading } = useSWR(eventId ? endpoint : null, fetcher, SWR_CONFIG);

  return {
    data: isLoading ? undefined : data?.data as DialerEvent,
    error: error,
    loading: isLoading,
    mutate: mutate
  }
}

async function fetcher(url: string) {
  const res = await API.get(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, url, {});
  return res;
}