import { Icon, IconProps } from "./icon";

export const Save = (props: IconProps) => (
  <Icon
    viewBox="0 0 18 18"
    fill="currentColor"
    {...props}
  >
    <path d="M1.29712 0.158691H15.2971L18.0041 2.86569C18.1917 3.05318 18.2971 3.3075 18.2971 3.57269V17.1587C18.2971 17.4239 18.1918 17.6783 18.0042 17.8658C17.8167 18.0533 17.5623 18.1587 17.2971 18.1587H1.29712C1.0319 18.1587 0.777549 18.0533 0.590012 17.8658C0.402476 17.6783 0.297119 17.4239 0.297119 17.1587V1.15869C0.297119 0.893475 0.402476 0.639121 0.590012 0.451585C0.777549 0.264048 1.0319 0.158691 1.29712 0.158691V0.158691ZM4.29712 1.15869V6.15869H13.2971V1.15869H4.29712ZM3.29712 9.15869V16.1587H15.2971V9.15869H3.29712ZM10.2971 2.15869H12.2971V5.15869H10.2971V2.15869Z" />
  </Icon>
)