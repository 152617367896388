import ApexChart from 'react-apexcharts';
import { CallAttributes } from '../../interfaces';
import { BLUE, GREEN, YELLOW } from '../../monitor';
import { calculateSlaCounts } from './calculateSlaCounts';

type uuid = string;

export const SlaChart = (props: SlaChartProps) => {
  const slaCounts = calculateSlaCounts(props.calls, props.slaThreshold, props.selfManagedGroupUuids, props.monitoredGroupUuids, props.validCallThreshold);
  const sla = slaCounts.sla;

  return (
    <div>
      <div>רמת שירות</div>
      <ApexChart
        type='radialBar'
        height='100%'
        options={{
          grid: {
            padding: {
              bottom: 0,
            }
          },
          stroke: { lineCap: 'round' },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: .2,
              opacityFrom: .9,
              opacityTo: .9,
              stops: [50, 60, 80, 90, 100]
            }
          },
          colors: [colorPicker],
          noData: {
            text: 'אין מספיק נתונים כדי לחשב נתון זה'
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "70%",
              },
              dataLabels: {
                name: {
                  show: false
                },
                value: {
                  show: true,
                  fontSize: "40px",
                  fontWeight: 500,
                  fontFamily: 'ploni',
                  offsetY: 14,
                  color: '#181D24',
                }
              }
            }
          },
        }}
        series={sla !== undefined ? [sla] : []}
      />
    </div>
  );
}

interface SlaChartProps {
  calls: CallAttributes[];
  selfManagedGroupUuids: uuid[];
  monitoredGroupUuids: uuid[];
  slaThreshold: number;
  validCallThreshold: number;
}

const BAD = 25;
const INTERMEDIATE = 50;
const GOOD = 85;

const colorPicker = ({ value }: any) => {
  if (value <= BAD) { return '#f00'; }
  else if (value <= INTERMEDIATE) { return YELLOW; }
  else if (value <= GOOD) { return '#282FEF'; }
  else { return GREEN; }
}
