import * as Yup from 'yup';
import { useState } from 'react';
import { API } from 'aws-amplify';
import { DialogStateReturn } from 'reakit';
import { useNavigate } from 'react-router-dom';

import { Field } from '../../design/form/field';
import styles from './dial-groups-page.module.scss';
import { Dialog } from '../../design/dialog/dialog';
import { Input } from '../../design/form/input/input';
import { useDefaultDialer, useDialGroups } from '../use-dial-groups';
import { ErrorDialog } from '../../design/dialog/error-dialog/error-dialog';

const LAUNCH_CODE_MIN = 1000;
const LAUNCH_CODE_MAX = 9999;
const ERROR_MESSAGE = 'לא הצלחנו לייצר את קבוצת הקריאה בשבילך';

export default function CreateDialGroupDialog({ dialog }: CreateDialGroupDialogProps) {
  const { data: dialGroups, mutate: mutateDialGroups } = useDialGroups();
  const { data } = useDefaultDialer();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const dialGroupLaunchCodes = dialGroups?.map((dg) => dg.launch_code);
  const launchCode = generateLaunchCode(LAUNCH_CODE_MIN, LAUNCH_CODE_MAX, dialGroupLaunchCodes);
  const navigate = useNavigate();

  const handleSubmit = async (values: Record<string, any>) => {
    try {
      setLoading(true);
      const res = await API.post(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, `/emergency-dialer`, {
        body: {
          ...defaultDialer,
          name: values.name,
          launch_code: launchCode,
        },
      });
      await mutateDialGroups();
      navigate(`/emergency-dialer/dialers/${res.data}`);
      dialog.hide();
    } catch (error: any) {
      setError(true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  if (!data) {
    return <div></div>;
  }

  const { dialer_id, domain_uuid, ...defaultDialer } = data;
  const forwardCallsFormSchema: Yup.Schema = Yup.object().shape({
    name: Yup.string().min(4, 'על שם הקבוצת קריאה להיות לפחות באורך 4 תווים').required('נא להזין שם לקבוצת החיוג'),
  });

  if (error) {
    return <ErrorDialog dialog={dialog} mainMessage={ERROR_MESSAGE} onClose={() => setError(false)} />;
  }

  return (
    <Dialog
      {...dialog}
      variant='form'
      header={'יצירת קבוצת קריאה'}
      onSubmit={handleSubmit}
      initialValues={{
        name: '',
      }}
      validationSchema={forwardCallsFormSchema}
      submitLabel={'יצירת קבוצת קריאה'}
      cancelLabel={'ביטול'}
      hideOnClickOutside={false}
      loading={loading}
      aria-label={'create-dial-group'}
      onClose={() => setError(false)}
    >
      <div className={styles.name}>
        <div className={styles.fieldTitle}>שם קבוצת קריאה</div>
        <Field name='name'>
          <Input type='text' placeholder='למשל: קבוצת כוננות ביטחון' />
        </Field>
        {error ? <div className={styles.error}>{error}</div> : undefined}
      </div>
    </Dialog>
  );
}

interface CreateDialGroupDialogProps {
  dialog: DialogStateReturn;
}

const generateLaunchCode = (min: number, max: number, invalidCodes: string[] | undefined): string => {
  for (let i = 0; i < 25; i++) {
    // so won't loop forever
    const rand = ~~(Math.random() * 1000000) + Date.now();
    const code = String((rand % (max - min + 1)) + min); // 1000-9999

    if (!invalidCodes?.includes(code)) {
      return code;
    }
  }

  throw new Error(`Cannot generate launch code`);
};
