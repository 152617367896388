import { CountryCode, parsePhoneNumber } from 'libphonenumber-js';

export const safeParsePhoneNumber = (phoneNumber: string, country: CountryCode = 'IL') => {
  try {
    return parsePhoneNumber(phoneNumber, country).formatNational();
  } catch (error) {
    if (phoneNumber.match(/^\d*$/)) {
      console.log(`Could not parse phone number '${phoneNumber}', `, error);
    } else {
      // "anonymous" and alike, don't log because too noisy
    }
    return "מספר חסוי";
  }
}