import classNames from 'classnames';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDialogState } from 'reakit';
import { useMe } from '../../../api/use-me';
import { Button } from '../../../design/button';
import { DateRange } from '../../../design/date-range/date-range';
import { Reset } from '../../../design/icons';
import { Search } from '../../../design/search/search';
import { Spinner } from '../../../design/spinner/spinner';
import { AppLayout, AppLayoutContainer, AppLayoutHeader, AppLayoutTitle } from '../../../layouts/app-layout/app-layout';
import { Page } from '../../../page';
import { buttonTextStrings } from '../../../utils/strings/string-translator';
import { AttemptCardContext } from '../../attempt-card-context';
import { DialerRequest } from '../../types';
import { useCallbackRequests } from '../../use-calls';
import { useURLParams } from '../../../app/use-url-params';
import { CancelDialog } from '../call-list-dialog/cancel-dialog';
import { CallbackRequestsList } from './callback-requests-list';
import styles from './calls-log.module.scss';
import { HorizontalDates } from './horizontal-dates';
import { createDateList } from '../../../utils/time/dates';
import { TrashCan } from '../../../design/icons/trash-can';
import { Checkbox } from '../../../design/checkbox/checkbox';
import useDebounce from 'react-use/lib/useDebounce';

const urlParamsSortList = [
  'call', // call param must be first, so call details dialog route will function properly (see calls-history file)
  'return_number',
  'from_date',
  'to_date',
  'from_age',
  'to_age',
  'agent_group_numbers',
  'status_per_day',
  'attempts',
];

const EPSILON = 0.5;

export const CallbacksReportMobile = () => {
  const params = useParams<any>()['*']?.replace(/^\/|\/$/g, '')!;
  const { pathname } = useLocation();
  const isExact = pathname === '/callbacks';
  const [searchString, setSearchString] = useState<string>();
  const [editState, setEditState] = useState(false);
  const [, setRequest] = useContext(AttemptCardContext);
  const [debouncedSearchString, setDebouncedSearchString] = useState<string>();
  useDebounce(
    () => {
      setDebouncedSearchString(searchString);
    },
    2000,
    [searchString]
  );
  const [requests, setRequests] = useState<DialerRequest[]>([]);
  const [page, setPage] = useState(1);
  const [allChecked, setAllChecked] = useState(false);
  const [checked, setChecked] = useState<Set<string>>(new Set());
  const [isOnTop, setOnTop] = useState<boolean>(false);
  const stickyHeader = useRef<HTMLDivElement>(null!);
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null);
  const cancelDialog = useDialogState();

  // This ref (and the below metaRef) is needed because we are using a callback function (handlePageBottom)
  // that uses the page and meta values. Because of the nature of JS, values scoped inside a callback function will be
  // determined at the time of definition, not the time of call. Hence if refs will not be used, both meta and page values
  // will always be the initial values, no matter at what time the callback funtion is called.
  const pageRef = useRef(page);
  pageRef.current = page;

  // This ref keeps track of whether the re-render was caused due to paging or other states.
  // This affects the way the requests state behaves. If the re-rerender was caused to due paging, we want to concat the new
  // requests to the old ones, otherwise, the new requests should replace the existing ones.
  const paged = useRef(false);

  const { urlParams, removeURLParam, setURLParam, setURLParams } = useURLParams({
    path: '/callbacks',
    sortList: urlParamsSortList,
    params,
  });

  const [dateRange, setDateRange] = useState({ from_date: urlParams.from_date, to_date: urlParams.to_date });

  const getDefaultDate = () => {
    const nav = window.performance.getEntriesByType('navigation');
    const navType = nav[0].toJSON().type;
    if (navType === 'reload') {
      const selected = window.sessionStorage.getItem('selectedDate');
      return selected ?? dateRange.to_date;
    }
    return dateRange.to_date;
  };

  const [selectedDate, setSelectedDate] = useState<string>(getDefaultDate());

  const meObject = useMe();

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (isExact || !urlParams.from_date || !urlParams.to_date) {
      const range = {
        from_date: moment().subtract(1, 'week').format('YYYY-MM-DD').toString(),
        to_date: moment().format('YYYY-MM-DD').toString(),
      };
      setDateRange(range);
      setURLParams(range);
    }
  }, [urlParams.from_date, urlParams.to_date]);

  useEffect(() => {
    const defaultDateString = getDefaultDate() as string;
    const deaultDate = new Date(defaultDateString);
    if (deaultDate <= new Date(dateRange.to_date) && deaultDate >= new Date(dateRange.from_date)) {
      setSelectedDate(defaultDateString);
    } else {
      setSelectedDate(dateRange.to_date);
      window.sessionStorage.setItem('selectedDate', dateRange.to_date);
    }
  }, [dateRange]);

  useEffect(() => {
    if (debouncedSearchString && debouncedSearchString.length > 0) {
      setURLParam('return_number', debouncedSearchString);
    } else {
      removeURLParam('return_number');
    }
  }, [debouncedSearchString]);

  const {
    requests: data,
    meta,
    loading,
    mutate,
  } = useCallbackRequests({ ...urlParams, from_date: selectedDate, to_date: selectedDate, page: page });
  const metaRef = useRef<typeof meta>(meta);
  metaRef.current = meta;

  // This useEffect tracks all the values that should cause a "hard" re-render, meaning, replacing the displayed requests
  // rather than concat the new ones and returning to the top of the first page.
  useEffect(() => {
    paged.current = false;
    setPage(1);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [
    selectedDate,
    urlParams.status_per_day,
    urlParams.from_date,
    urlParams.to_date,
    urlParams.attempts,
    urlParams.agent_group_numbers,
    urlParams.to_age,
    urlParams.from_age,
    urlParams.return_number,
  ]);

  useEffect(() => {
    data && setRequests(paged.current ? requests.concat(data) : data);
  }, [data]);

  const dateList = createDateList(dateRange.from_date, dateRange.to_date, true);

  const handleSearch = (value: string) => {
    setSearchString(value);
  };

  const handleSearchReset = () => {
    setSearchString(undefined);
  };

  const handleAttemptOpen = (request: DialerRequest) => {
    setRequest(request);
    setURLParam('call', request.request_uuid);
  };

  const handleStatusSelection = (date: string, status: string) => {
    let urlParamValue = '';
    if (urlParams.status_per_day) {
      // this routin splits the old string represented params into a a matrix to make it easier to operate on
      const oldStatusesList: string[] = urlParams.status_per_day.split(',');
      const oldStatusesMatrix = oldStatusesList.map((dateStatusPair) => dateStatusPair.split(':'));
      const selectedDateIndex = oldStatusesMatrix.findIndex((dateStatusPair) => dateStatusPair[0] === date);

      // if the date already exists in old params
      if (selectedDateIndex >= 0) {
        if (!status) {
          oldStatusesMatrix.splice(selectedDateIndex, 1);
        } else {
          oldStatusesMatrix.splice(selectedDateIndex, 1, [oldStatusesMatrix[selectedDateIndex][0], status]);
        }
      } else if (status) {
        oldStatusesMatrix.push([date, status]);
      }

      const newStatusesList = oldStatusesMatrix.map((dateStatusPair) => dateStatusPair.join(':'));
      urlParamValue = newStatusesList.join(',');
    } else {
      if (!status) {
        return;
      }
      urlParamValue = `${date}:${status}`;
    }
    setURLParam('status_per_day', urlParamValue);
  };

  const handleDateSelection = (date: Date) => {
    date && setSelectedDate(date.toISOString().substring(0, 10));
    date && window.sessionStorage.setItem('selectedDate', date.toISOString().substring(0, 10));
    removeURLParam('status_per_day');
  };

  const handlePageBottom = () => {
    if (metaRef.current && pageRef.current < metaRef.current.pages) {
      setPage(pageRef.current + 1);
      paged.current = true;
    }
  };

  const handleCancelReq = () => {
    // Dialer requests that their boxes are checked
    const candidates = requests.filter((request) => checked.has(request.request_uuid));

    if (candidates.length > 0) {
      cancelDialog.show();
    }
  };

  const handleCheckAll = (checked: boolean) => {
    setAllChecked(checked);
    const newCheckedState = new Set<string>();
    if (checked) {
      requests.forEach((request) => {
        const requestOpen = request.request_status === 'active' || request.request_status === 'waiting';
        if (requestOpen) {
          newCheckedState.add(request.request_uuid);
        }
      });
    }
    setChecked(newCheckedState);
  };

  const handleCheckChange = (key: string) => {
    // copy the original state and delete the request if it is present and add it if not
    const newCheckedState = new Set(checked);
    if (!newCheckedState.delete(key)) {
      newCheckedState.add(key);
    }
    setChecked(newCheckedState);

    // if this change made all of the boxes checked (or vice versa), change the allChecked state
    const newAllChecked = newCheckedState.size === requests.length;
    setAllChecked(newAllChecked);
  };

  const onScroll = () => {
    const rect = stickyHeader.current?.getBoundingClientRect();
    if (null !== rect) {
      if (Math.abs(rect?.top) < EPSILON) {
        setOnTop(true);
      } else {
        setOnTop(false);
      }
    }
  };

  return (
    <Page name='Callbacks'>
      <AppLayout>
        <AppLayoutContainer>
          <AppLayoutHeader>
            <div>
              <AppLayoutTitle>היסטוריית שיחות חוזרות</AppLayoutTitle>
            </div>
          </AppLayoutHeader>
          <section className={styles.searchBar}>
            <Search
              placeholder='חיפוש מספר טלפון...'
              onChange={(e) => handleSearch(e.target.value)}
              onReset={() => handleSearchReset()}
              value={searchString || ''}
            />
            <DateRange
              customArrowIcon={<Reset size={16} />}
              displayFormat='DD MMMM'
              keepOpenOnDateSelect
              noBorder
              numberOfMonths={1}
              renderCalendarInfo={() => (
                <button
                  type='button'
                  disabled={!dateRange.from_date || !dateRange.to_date}
                  className={classNames(styles.filterFormButton, styles.primary)}
                  onClick={() => {
                    setFocusedInput(null);
                    setURLParams(dateRange);
                  }}
                  style={{
                    marginBlockEnd: '1rem',
                    fontSize: '0.875rem',
                  }}
                >
                  עדכון
                </button>
              )}
              showDefaultInputIcon
              minimumNights={0}
              startDate={moment(dateRange.from_date)}
              startDatePlaceholderText='מתאריך'
              startDateId='callBackReportStartDate'
              endDate={moment(dateRange.to_date)}
              endDatePlaceholderText='עד תאריך'
              endDateId='callBackReportEndDate'
              focusedInput={focusedInput || null}
              isOutsideRange={(day) => {
                return day.startOf('day').isAfter(moment());
              }}
              onFocusChange={(input) => setFocusedInput(input)}
              onDatesChange={({ startDate, endDate }) => {
                setDateRange({
                  from_date: startDate?.format('YYYY-MM-DD').toString(),
                  to_date: endDate?.format('YYYY-MM-DD').toString() || startDate?.format('YYYY-MM-DD').toString(),
                });
              }}
            />
          </section>
          <section
            className={styles.stickyHeader}
            ref={stickyHeader}
            style={isOnTop ? { borderBottom: '1px solid #e1e6ec' } : {}}
          >
            <HorizontalDates dates={dateList} selectedDate={selectedDate} onSelectedDateChange={handleDateSelection} />
            <div className={styles.editRow}>
              <span className={styles.editLink}>
                {editState ? (
                  <Button variant='link' onClick={() => setEditState(false)}>
                    סיום עריכה
                  </Button>
                ) : (
                  <Button className={styles.editLink} variant='link' onClick={() => setEditState(true)}>
                    {buttonTextStrings.get('edit')}
                  </Button>
                )}
              </span>
              <span className={editState ? styles.editSectionDisplay : styles.editSectionHidden}>
                <Button variant='ghost' onClick={handleCancelReq}>
                  <TrashCan size={28} />
                </Button>
                <Checkbox checked={allChecked} onChange={() => handleCheckAll(!allChecked)} />
              </span>
            </div>
          </section>
          {requests.length > 0 ? (
            <CallbackRequestsList
              requests={requests}
              params={{
                dateRange,
                selectedDate,
                checked,
                editState,
                handleAttemptOpen,
                handleStatusSelection,
                handleDateSelection,
                handlePageBottom,
                handleCheckChange,
              }}
              counters={
                meta?.counts || [
                  {
                    date: '01-01-1970',
                    count: {
                      total: 0,
                      waiting: 0,
                      active: 0,
                      completed: 0,
                      failed: 0,
                      canceled: 0,
                    },
                  },
                ]
              }
            />
          ) : loading ? (
            <div className={styles.loadingMessage}>
              <Spinner />
            </div>
          ) : (
            <div className={styles.emptyMessage}>
              <div className={styles.title}>לא מצאנו בקשות ביום הנבחר</div>
              <div className={styles.content}>שווה לוודא שהסינונים שהגדרת הכרחיים או לבחור יום אחר מהרשימה</div>
            </div>
          )}
          <CancelDialog
            dialog={cancelDialog}
            requests={requests.filter((request: DialerRequest, index: number) => checked.has(request.request_uuid))}
            onConfirm={mutate}
          />
        </AppLayoutContainer>
      </AppLayout>
    </Page>
  );
};
