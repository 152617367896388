import { Duplicate } from '../../design/icons/duplicate';
import { Remove } from '../../design/icons/remove';
import styles from './node-actions-menu.module.scss';
import { texts } from '../utils/texts';
import { FC } from 'react';

const { MENU_ACTION_DUPLICATE_NODE, MENU_ACTION_REMOVE_NODE } = texts;
interface NodeActionsMenuProps {
  isRoot: boolean;
  onRemoveClick: () => void;
  onDuplicateClick: () => void;
}

export const NodeActionsMenu: FC<NodeActionsMenuProps> = ({ isRoot, onRemoveClick, onDuplicateClick }) => {
  return (
    <div>
      <div onClick={onDuplicateClick} className={styles.menuAction}>
        {MENU_ACTION_DUPLICATE_NODE}
        <Duplicate size={24} />
      </div>
      {!isRoot && (
        <div onClick={onRemoveClick} className={styles.menuAction}>
          {MENU_ACTION_REMOVE_NODE}
          <Remove size={24} />
        </div>
      )}
    </div>
  );
};
