import React from 'react';
import { Icon, IconProps } from './icon';

export const LocalCallIcon = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96"
    fill="currentColor"
    {...props}
  >
    <polygon fillRule="evenodd" points="31 54 31 68 11 48 31 28 31 42 65 42 65 28 85 48 65 68 65 54"/>
  </Icon>
);