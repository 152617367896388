import { Typography } from '@mui/material';
import { ChangeEvent, MouseEvent, forwardRef } from 'react';

import { UploadFile } from './UploadFile';
import { CustomButton } from '../customButton/CustomButton';
import { ButtonVariant, Color, TypographyVariant } from '../../../theme/muiTypes';
import { FileField, FileType, FORMAT_TYPE_MAPPING } from '../../../globalTypes/types';
import {
  AudioPlayer,
  VideoPlayer,
  ImagePreview,
  DocumentPreview,
  StyledEditFileContainer,
  StyledEditAudioHeader,
} from './StyledFileField';

interface EditFileFieldProps {
  value: FileField;
  deleteFileBtnText: string;
  changeFileBtnText: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onRemove?: (event: MouseEvent) => void;
}

export const EditFileField = forwardRef<HTMLAudioElement, EditFileFieldProps>(({ onRemove, value, ...props }, ref) => {
  const { deleteFileBtnText, changeFileBtnText, ...rest } = props;
  const { file_name, file_url, file_type, file_format, supported_formats } = value;
  const fileFormat = file_format ?? '';
  const fileType: FileType = FORMAT_TYPE_MAPPING[fileFormat] ?? FileType.application;

  return (
    <StyledEditFileContainer>
      <StyledEditAudioHeader>
        <Typography variant={TypographyVariant.regularText1}>{file_name}</Typography>
        {onRemove && (
          <CustomButton variant={ButtonVariant.text} onClick={onRemove}>
            <span>{deleteFileBtnText}</span>
          </CustomButton>
        )}
      </StyledEditAudioHeader>
      {fileType === FileType.audio && (
        <AudioPlayer src={file_url} controls>
          <source src={file_url} />
        </AudioPlayer>
      )}
      {fileType === FileType.image && <ImagePreview src={file_url} alt={file_name} />}
      {fileType === FileType.video && (
        <VideoPlayer src={file_url} controls>
          <source src={file_url} />
        </VideoPlayer>
      )}
      {fileType === FileType.application && <DocumentPreview />}
      <UploadFile
        file_type={file_type}
        file_format={fileFormat}
        supported_formats={supported_formats}
        btnColor={Color.primary}
        btnVariant={ButtonVariant.text}
        selectFileBtnText={changeFileBtnText}
        {...rest}
      />
    </StyledEditFileContainer>
  );
});
