import React, { ReactNode } from 'react';
import { WarningIcon } from '../icons';
import styles from './warning.module.scss';

export function Warning({ message, iconSize }: WarningProps) {
  return (
    <div className={styles.warning}>
      <WarningIcon size={iconSize || 32} className={styles.icon} />
      {message}
    </div>
  )
}

export interface WarningProps {
  message: ReactNode
  iconSize?: 16 | 20 | 24 | 28 | 32 | 40 | 48;
}