import { useEffect, useRef, useState } from 'react';
import { DialerRequest } from '../../../types';
import { CallListItem } from './call-list-item/call-list-item';
import styles from './call-list.module.scss'

interface CallListProps {
  requests: DialerRequest[],
  params: {
    dateRange: {
      from_date: string,
      to_date: string
    }
    handleAttemptOpen: (request: DialerRequest) => void
    handleStatusSelection: (date: string, status: string) => void
  },
  counters: {
    date: string,
    count: {
      total: number,
      waiting: number,
      active: number,
      completed: number,
      failed: number,
      canceled: number
    }
  }[],
  onCancel: () => void
}

export const RequestList = ({ requests, params, counters, onCancel }: CallListProps) => {
  const [localData, setLocalData] = useState<DialerRequest[]>();
  const lastFiltered = window.sessionStorage.getItem("lastFiltered");
  const lastFilteredElement = useRef<HTMLDivElement>(null!);

  useEffect(() => {
    setLocalData(requests);
  }, [requests])

  useEffect(() => {
    lastFilteredElement.current && lastFilteredElement.current.scrollIntoView(); 
  }, [localData])


  const onStatusSelection = (date: string, status: string) => {
    window.sessionStorage.setItem("lastFiltered", date);
    params.handleStatusSelection(date, status);
  }

  if (localData && localData.length > 0) {

    const groupByDate = (requests: DialerRequest[]) => {

      // Reducing the all the requests to a single object.
      // The object's keys are dates formatted to YYYY-MM-DD.
      // An object value is an array of requests corresponding to the key's date
      return requests.reduce((result: Record<string, DialerRequest[]>, request: DialerRequest) => {

        const groupKey = request.start_date_no_time;
        (result[groupKey] = result[groupKey] || []).push(request);
        return result;

      }, {},)
    };

    const grouped = groupByDate(localData);
    return (
      <div>
        {
          Object.keys(grouped).map((key: string, i: number) => {
            return (
              <div ref={key === lastFiltered ? lastFilteredElement : undefined} key={key}>
                <CallListItem
                  key={key}
                  props={{
                    data: grouped[key],
                    key,
                    counts: counters.find((counts) => counts.date === key)?.count
                  }}
                  params={params} openCard={params.handleAttemptOpen} onStatusSelection={onStatusSelection}
                  onCancel={onCancel}
                />
              </div>
            )
          }
          )
        }
      </div>
    )
  }


  return (
    <div className={styles.emptyMessage}>
      <div className={styles.title}>לא מצאנו בקשות מתאימות</div>
      <div className={styles.content}>
        שווה לוודא שהסינונים שהגדרת הכרחיים או לבחור תקופת זמן ארוכה יותר
      </div>
    </div>
  );

}