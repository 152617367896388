import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import styles from './card.module.scss';

export const Card = ({ children, className, onClick }: CardProps) => {
  return (
    <div className={classNames(styles.card, className)} onClick={onClick}>
      {children}
    </div>
  );
}

interface CardProps extends PropsWithChildren {
  className?: string;
  onClick?: ((e: React.MouseEvent) => void) | undefined;
}