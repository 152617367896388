import classNames from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';
import styles from './button.module.scss';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  stretch?: boolean;
  variant?: ButtonVariantType;
}

export type ButtonVariantType = 'regular' | 'caution' | 'ghost' | 'link' | 'primary' | 'icon';

export const Button = ({
  loading = false,
  stretch = false,
  variant = 'regular',
  children,
  className,
  ...props
}: ButtonProps) => {
  const finalClassName = classNames(
    {
      [styles.root]: true,
      [styles.stretch]: stretch,
    },
    styles[variant],
    className
  );

  const disabled = props.disabled || loading;

  return (
    <button {...props} className={finalClassName} disabled={disabled}>
      {children}
      {loading && <Spinner />}
    </button>
  );
};

export const Spinner = (props: any) => <div className={classNames(styles.spinner, props.className)}></div>;
