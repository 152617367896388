import { ExtensionResource } from "../../api/types";
import { User } from "../../api/use-users";

export const getSelfManagedExtensions = (groupedData?: Record<string, ExtensionResource[]>, multiTenantUser?: User): Record<string, ExtensionResource[]> => {
  if (!groupedData || !multiTenantUser || Object.keys(groupedData).length === 0) {
    return ({});
  }
  const domainsSelfManagedExtensions = Object.fromEntries(multiTenantUser.domains.map(domain => [domain.domain_uuid, domain.domain_self_managed_extensions || []]));
  const entries = Object.entries(groupedData);
  const filteredEntries = entries.map(([domain, extensions]) => [domain, extensions.filter(extension => domainsSelfManagedExtensions[domain].includes(extension.id))])
  return Object.fromEntries(filteredEntries);
}
