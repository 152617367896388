import React, { useState, useRef, InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import { Button } from '../../button';
import styles from './audio-file-input.module.scss';
import { useEffect } from 'react';

export function AudioFileInput({
  onChange,
  onReset,
  label,
  description,
  placeholderFileUrl,
  placeholderFilename,
  resetButtonLabel,
  replaceButtonLabel,
  className,
  uploadButtonLabel,
  ...props
}: AudioFileInputProps) {
  const [audioUrl, setAudioUrl] = useState(placeholderFileUrl);
  const inputRef = useRef<HTMLInputElement | null>();
  const filename = inputRef.current?.files?.[0]?.name;
  const showFullUpload = !props.value && !audioUrl;

  useEffect(() => {
    setAudioUrl(placeholderFileUrl);
  }, [placeholderFileUrl]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {
      setAudioUrl(URL.createObjectURL(file));
    }
    if (onChange) onChange(event);
  };

  const handleReset = () => {
    setAudioUrl(placeholderFileUrl);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    if (onReset) onReset();
  };

  const handleClick = () => inputRef.current?.click();

  return (
    <>
      <input
        type='file'
        className={classNames(styles.input, className)}
        ref={(ref) => (inputRef.current = ref)}
        onChange={handleChange}
        {...props}
      />
      {showFullUpload ? (
        <FullUpload
          onClick={handleClick}
          label={label}
          description={description}
          uploadButtonLabel={uploadButtonLabel}
        />
      ) : (
        <InlineUpload
          onClick={handleClick}
          onRemove={handleReset}
          audioUrl={audioUrl as string /** audioUrl typecheck is enforced with showFullUpload variable */}
          filename={filename}
          placeholderFilename={placeholderFilename}
          resetButtonLabel={resetButtonLabel}
          replaceButtonLabel={replaceButtonLabel}
        />
      )}
    </>
  );
}

export function FullUpload({ onClick, label, description, uploadButtonLabel }: FullUploadProps) {
  const genderCode = document.documentElement.getAttribute('style')?.split(':')[1];
  const isFemale = genderCode?.includes('ss11');
  return (
    <div className={styles.file}>
      {label && (
        <span className={styles.label}>
          {/* העלאת קובץ הקלטה חדש */}
          {label}
        </span>
      )}
      {description && (
        <span className={styles.description}>
          {/* ניתן להעלות קבצי אודיו עד 5MB */}
          {description}
        </span>
      )}
      <Button type='button' className={styles.uploadButton} onClick={onClick}>
        {uploadButtonLabel ? uploadButtonLabel : isFemale ? 'בחרי קובץ' : 'בחר קובץ'}
      </Button>
    </div>
  );
}

export function InlineUpload({
  onClick,
  onRemove,
  filename,
  audioUrl,
  placeholderFilename,
  resetButtonLabel,
  replaceButtonLabel,
}: InlineUploadProps) {
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.load();
    }
  }, [audioUrl]);

  return (
    <>
      <div className={styles.actions}>
        {filename ? (
          <>
            <span style={{ flexGrow: 1 }}>{filename}</span>
            <Button type='button' variant='link' onClick={onRemove}>
              {/* הסרת הקלטה */}
              {resetButtonLabel || 'הסרת קובץ'}
            </Button>
          </>
        ) : (
          <>
            <span>
              {/* הקלטה נוכחית */}
              {placeholderFilename || ''}
            </span>
            <Button type='button' variant='link' onClick={onClick}>
              {/* העלאת הקלטה חדשה */}
              {replaceButtonLabel || 'החלפת קובץ'}
            </Button>
          </>
        )}
      </div>
      {audioUrl && (
        <audio controls className={styles.record} ref={audioRef}>
          <source src={audioUrl} />
        </audio>
      )}
    </>
  );
}

export interface AudioFileInputProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholderFileUrl?: string;
  onReset?: () => void;
  label?: string;
  description?: string;
  placeholderFilename?: string;
  uploadButtonLabel?: string;
  resetButtonLabel?: string;
  replaceButtonLabel?: string;
}

interface FullUploadProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  label?: string;
  description?: string;
  uploadButtonLabel?: string;
}

interface InlineUploadProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  onRemove: () => void;
  filename?: string;
  audioUrl: string;
  placeholderFilename?: string;
  resetButtonLabel?: string;
  replaceButtonLabel?: string;
}
