import moment from 'moment';
import mixpanel from 'mixpanel-browser';
import { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAudio } from 'react-use';
import { useDialogState } from 'reakit/Dialog';
import { useURLParams } from '../../app/use-url-params';
import { useCall } from '../use-calls';
import { playerContext } from '../player-state';
import classNames from 'classnames';
import styles from './call-details-dialog.module.scss';
import { Page } from '../../page';
import { Dialog } from '../../design/dialog/dialog';

const resultLabels = {
  failed: "לא נענתה",
  voicemail: "תא קולי"
};

const directionLabels = {
  inbound: "נכנסת",
  outbound: "יוצאת",
  local: "פנימית"
}

export const CallDetailsDialog = () => {
  const [player, setPlayer] = useContext(playerContext);
  const navigate = useNavigate();

  const { urlParams, removeURLParam } = useURLParams({
    path: '/calls-history',
    params: useLocation().pathname.replace(/^\/|\/$/g, '').replace('calls-history/', '')
  });  

  const { data, error, loading } = useCall(urlParams.call);

  const dialog = useDialogState({
    visible: urlParams.call !== undefined
  });

  const onPlay = () => {
    if (call) {
      mixpanel.track("Play Recording", {
        "Context": "Call Details",
        "Call Direction": call.direction,
        "Searched": urlParams.hasOwnProperty("search"),
      });
      mixpanel.people.increment("Recordings Played");
      mixpanel.people.set("Last Played Recording", moment().toISOString());
    }
  }

  useEffect(() => {
    if (player) {
      setPlayer(false);
    }
  }, [player, setPlayer])

  useEffect(() => {
    if (!dialog.visible) {
      removeURLParam('call');
    }
  }, [dialog.visible, removeURLParam]);

  if (error) {
    return <div>error</div>;
  }

  if (loading) {
    return <div>loading</div>
  }

  const call = data?.call;

  return call ? (
    <Page name="Call Details">
      <div className={styles.modalGrid}>
        <Dialog
          {...dialog}
          header="פרטי שיחה"
          variant="info"
          preventBodyScroll={true}
          className={styles.dialog}
          aria-label="פרטי שיחה"
          onClose={() => navigate(-1)}
        >
          <div className={styles.content}>
            <div className={styles.detail}>
              <span className={styles.label}>
                שיחה {directionLabels[call.direction]}
              </span>
              <div>
                <span className={styles.value}>
                  {call.start_time.format('DD MMM YYYY, HH:mm')}
                </span>
                {resultLabels[call.result as 'failed'] && (
                  <span>
                    &nbsp;&middot; {resultLabels[call.result as 'failed']}
                  </span>
                )}
              </div>
            </div>
            <div
              className={classNames(styles.parties, styles[call.direction], styles[call.result])}
            >
              <div className={styles.party}>
                <div className={styles.title}>מתקשר</div>
                <span className={styles.number}>
                  {call.caller_private_extension ||
                    call.caller_virtual_extension}
                </span>
              </div>
              <div className={styles.party}>
                <div className={styles.title}>יעד</div>
                <span className={styles.number}>
                  {call.result === 'ivr' && 'תפריט קולי '}
                  {call.callee_private_extension ||
                    call.callee_virtual_extension}
                </span>
              </div>
            </div>
            <section className={styles.details}>
              {
                ((call.direction === "inbound" && call.callee_virtual_extension) || (call.direction === "outbound" && call.caller_virtual_extension)) && (
                  <div className={styles.detail}>
                    <span className={styles.label}>קו טלפון</span>
                    <span className={styles.value}>
                      {call.direction === "inbound" ? call.callee_virtual_extension : call.caller_virtual_extension}
                    </span>
                  </div>
                )
              }
              {call.result === "answered" && call.end_time.isValid() && call.answer_time?.isValid() && (
                <div className={styles.detail}>
                  <span className={styles.label}>משך השיחה</span>
                  <span className={styles.value}>{moment.duration(call.end_time.diff(call.answer_time)).format("HH:mm:ss", { trim: false })}</span>
                </div>
              )}
              <div className={styles.detail}>
                <span className={styles.label}>זמן המתנה</span>
                <span className={styles.value}>{call.answer_time?.isValid() && `${call.answer_time.diff(call.start_time, "seconds")} שניות`}</span>
              </div>
              {data && data.sms.length > 0 && (
                <div className={styles.detail}>
                  <span className={styles.label}>הודעות סמס{data.sms.length > 1 && ` (${data.sms.length})`}</span>
                  <div>
                    {data.sms.map((sms: any) => <div className={styles.value}>{sms.body}</div>)}
                  </div>
                </div>
              )}
            </section>

            {call.record_url && <AudioPlayer src={call.record_url} onPlay={onPlay} />}
          </div>
        </Dialog>
      </div>
    </Page>
  ) : <div>error</div>;

};

export interface CallDetailsDialogProps {
  onClose?: () => void;
}

const AudioPlayer = ({ src, onPlay }: { onPlay?: () => void; src: string }) => {
  const [audio] = useAudio({
    src,
    autoPlay: false,
    controls: true,
    className: styles.player,
    onPlay,
  });

  return audio;
};
