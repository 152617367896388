import { Popover, styled } from '@mui/material';
import { DropdownProps } from './Dropdown';

interface StyledDropdownProps extends DropdownProps {
  menuArrow?: boolean;
}

const dropdownProps: PropertyKey[] = ['menuArrow'];

export const StyledDropdown = styled(Popover, {
  shouldForwardProp: (prop) => !dropdownProps.includes(prop),
})<StyledDropdownProps>(({ theme, menuArrow }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.borderRadius.default,
    filter: `dropShadow(${theme.shadows[3]})`,
    overflow: 'visible',
    marginTop: theme.spacing(3),

    '&::before': menuArrow
      ? {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 20,
          width: 10,
          height: 10,
          background: theme.backgrounds.bg_main,
          transform: 'translateY(-50%) rotate(45deg)',
          zIndex: 0,
        }
      : undefined,
  },
}));
