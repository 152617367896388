import { API } from 'aws-amplify';
import useSWR from 'swr';
import { JobTitle } from '../api/dialers';

const SWR_CONFIG = { revalidateOnFocus: false, revalidateOnReconnect: false };

export const useJobTitles = () => {

  const endpoint = `/job-title`;
  const { data, error, mutate, isLoading } = useSWR(endpoint, fetcher, SWR_CONFIG);

  return {
    data: isLoading ? undefined : data?.data as JobTitle[],
    error: error,
    loading: isLoading,
    mutate: mutate
  }
}

async function fetcher(path: string) {
  const res = await API.get(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, path, {});
  return res;
}