
import { Icon, IconProps } from './icon';

export const ChevronRight = ({ ...props }: IconProps) => (
  <Icon
    fill="currentColor"
    {...props}
  >
    <polygon fillRule="evenodd" points="10 6 8.59 7.41 13.17 12 8.59 16.59 10 18 16 12" />
  </Icon>
);