import { useContext, useEffect, useRef, useState } from 'react';
import { CallListItem } from './call-list-item/call-list-item';
import { CallShape } from '../types';
import styles from './call-list.module.scss';

import { Spinner } from '../../design/spinner/spinner';
import { selectedCallContext } from '../selected-call';
import { playerContext } from '../player-state';
import classNames from 'classnames';

const EPSILON = 0.5;

export const CallList = ({ data, multiTenant, onOpen, onPlay  }: CallListProps) => {
  const [activeCall, setActiveCall] = useContext(selectedCallContext);
  const [playerVisible, setPlayerVisibility] = useContext(playerContext);
  const [isOnTop, setIsOnTop] = useState(false);
  const stickyHeader = useRef<HTMLDivElement>(null!);
  const handleClosePlayer = () => {
    setActiveCall(undefined);
    setPlayerVisibility(false);
  };

  const handlePlayCall = (call: CallShape) => {
    setActiveCall(call);
    if (onPlay) onPlay(call);
  };

  const handleScroll = () => {
    const rect = stickyHeader.current?.getBoundingClientRect();
    if (null !== rect) {
      if (Math.abs(rect?.top) < EPSILON) {
        setIsOnTop(true);
      } else {
        setIsOnTop(false);
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, []);

  if (!data) {
    return (
      <div className={styles.loadingMessage}>
        <Spinner />
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className={styles.emptyMessage}>
        <div className={styles.title}>לא מצאנו שיחות מתאימות</div>
        <div className={styles.content}>
          שווה לוודא שהסינונים שהגדרת הכרחיים או לבחור תקופת זמן ארוכה יותר
        </div>
      </div>
    );
  }

  return (
    <section className={styles.root}>
      <div className={classNames(multiTenant ? styles.multiHeader : styles.header, isOnTop ? styles.bottomShadow : "")} ref={stickyHeader}>
        <span>תאריך</span>
        <span>שיחה</span>
        {multiTenant && <span>ארגון</ span>}
        <span>המתנה למענה</span>
        <span>משך השיחה</span>
      </div>
      <ul>
        {data.map((call) => (
          <CallListItem
            key={`callListItem_${call.uuid}`}
            multiTenant={multiTenant}
            onOpen={onOpen}
            onPlayCall={handlePlayCall}
            onClosePlayer={handleClosePlayer}
            isActive={playerVisible && activeCall?.uuid === call.uuid}
            data={call}
          />
        ))}
      </ul>
    </section>
  );
};

export interface CallListProps {
  data?: (CallShape & {domainDescription?: string})[];
  multiTenant?: boolean;
  onOpen?: (call: CallShape) => void;
  onPlay?: (call: CallShape) => void;
}
