import { AppLayout, AppLayoutContainer, AppLayoutHeader, AppLayoutTitle } from '../../layouts/app-layout/app-layout';
import { Page } from '../../page';
import ExtensionsList from '../extensions-list/extensions-list';

export default function ExtensionsListPage() {
  return (
    <Page name="My Numbers">
      <AppLayout>
        <AppLayoutContainer>
          <AppLayoutHeader>
            <AppLayoutTitle>שלוחות</AppLayoutTitle>
          </AppLayoutHeader>
          <ExtensionsList />
        </AppLayoutContainer>
      </AppLayout>
    </Page>
  )
}
