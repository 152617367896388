import { useEffect, useRef, useState } from "react";
import { Button, ButtonProps } from "../button/button";
import { Dropdown } from "../dropdown/dropdown";

export const ButtonWithDropdown = ({
  collapseOnClickOutside = true,
  collapseOnClickMenu = false,
  isLocateRelatively = false,
  isParentAbsolute,
  ...props
}: ButtonWithDropdownProps) => {
  const [expanded, setExpanded] = useState(props.dropdownExpanded ?? false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const dropdownRef = useRef<HTMLDivElement>(null!);
  const buttonRef = useRef<HTMLDivElement>(null!);

  const resizeListener = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  const onClick = () => {
    if (props.onClick) props.onClick(); // preform user's onClick, if he provided one
    setExpanded((expanded) => {
      if (expanded) {
        props.onClose && props.onClose();
      }
      return !expanded;
    });
  };

  useEffect(() => {
    if (props.dropdownExpanded !== undefined && props.dropdownExpanded !== null) {
      setExpanded(props.dropdownExpanded);
    }
  }, [props.dropdownExpanded]);

  useEffect(() => {
    const mouseListener = (event: any) => {
      // Do nothing if clicking ref's element or descendent elements
      if (collapseOnClickMenu) {
        props.onClose && props.onClose();
        setExpanded(false);
        return;
      }

      if (
        !buttonRef.current ||
        buttonRef.current.contains(event.target) ||
        !collapseOnClickOutside
      ) {
        return;
      }
      props.onClose && props.onClose();
      setExpanded(false);
    };

    document.addEventListener("mouseup", mouseListener);
    document.addEventListener("touchstart", mouseListener);

    window.addEventListener("resize", resizeListener);
    return () => {
      document.removeEventListener("mouseup", mouseListener);
      document.removeEventListener("touchstart", mouseListener);
      window.removeEventListener("resize", resizeListener);
    };
  }, [collapseOnClickMenu]);

  useEffect(() => {
    if (isParentAbsolute) {
      return;
    }
    if (dropdownRef.current && buttonRef.current) {
      const equatorX = window.innerWidth / 2;
      const documentDirection = window.getComputedStyle(document.documentElement).direction;
      const buttonPosition = buttonRef.current.getBoundingClientRect();
      const buttonPositionLeft = Math.round(buttonPosition.left);
      const buttonPositionTop = Math.round(buttonPosition.top);
      const buttonPositionBottom = Math.round(buttonPosition.bottom);
      const menuPosition = dropdownRef.current.getBoundingClientRect();
      const menuHeight = menuPosition.height;

      // if button is in the left half of the screen, drop down should flip
      if (equatorX > buttonPosition.x) {
        dropdownRef.current.style[documentDirection === "ltr" ? "right" : "left"] = `${
          buttonPositionLeft + window.scrollX
        }px`;
      }
      if (buttonPositionBottom + menuHeight >= windowHeight) {
        // Content window should pop upwards
        dropdownRef.current.style["bottom"] = `${
          windowHeight - buttonPositionTop - (!props.isSticky ? window.scrollY : 0)
        }px`;
        dropdownRef.current.style["top"] = "auto";
      } else {
        // Content window should be dropping down - reset default position fields
        dropdownRef.current.style["bottom"] = "auto";
        dropdownRef.current.style["top"] = "auto";
      }
    }
  }, [windowWidth, windowHeight, isParentAbsolute]);

  useEffect(() => {
    if (isParentAbsolute) {
      return;
    }
    if (dropdownRef.current && buttonRef.current) {
      const equatorX = window.innerWidth / 2;
      const documentDirection = window.getComputedStyle(document.documentElement).direction;
      const buttonPosition = buttonRef.current.getBoundingClientRect();
      let buttonPositionLeft = isLocateRelatively
        ? buttonRef.current.offsetLeft
        : Math.round(buttonPosition.left);

      if (equatorX > buttonPosition.x) {
        dropdownRef.current.style[documentDirection === "ltr" ? "right" : "left"] = `${
          buttonPositionLeft + window.scrollX
        }px`;
      }
    }
  }, [expanded, isLocateRelatively, isParentAbsolute]);

  return (
    <div ref={buttonRef} className={props.className} id='button-with-dropdown'>
      <Button
        disabled={props.disabled}
        variant={props.variant}
        loading={props.loading}
        stretch={props.stretch}
        onClick={onClick}
        className={props.buttonClassName}
        type={props.type}
      >
        <span>{props.label || ""}</span>
        <span>{expanded ? props.expandedBadge : props.collapsedBadge}</span>
      </Button>
      <Dropdown expanded={expanded} ref={dropdownRef} className={props.menuClassName}>
        {props.children}
      </Dropdown>
    </div>
  );
};

interface ButtonWithDropdownProps extends ButtonProps {
  onClick?: () => void;
  onClose?: () => void;
  collapsedBadge?: React.ReactNode;
  expandedBadge?: React.ReactNode;
  collapseOnClickOutside?: boolean;
  collapseOnClickMenu?: boolean;
  label?: React.ReactNode;
  children?: React.ReactNode;
  buttonClassName?: string;
  menuClassName?: string;
  isSticky?: boolean; // If an element sticks to the main window - exclude the window.scroll calculation for that element
  isParentAbsolute?: boolean; // If parent element of button is positioned with the `absolute` property - skip all positioning calculations
  isLocateRelatively?: boolean; //
  dropdownExpanded?: boolean; // This is an experimental feature and should be used as such
}
