import { Icon, IconProps } from "./icon";


export const Caution = ({...props}: IconProps) => (
  <Icon 
    viewBox="0 0 22 19"
    fill="currentColor"
    {...props}
  >
    <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M22 19L11 0L0 19H22ZM10 16V14H12V16H10ZM10 12H12V8H10V12Z" fill="#181D24"/>
    </svg>
  </Icon>
)