import React from 'react';
import { Icon, IconProps } from './icon';

export const ArrowDown = ({...props}: IconProps) => (
  <Icon 
    viewBox="0 0 96 96"
    fill="currentColor"
    {...props}
  >
    <polygon fillRule="evenodd" points="65.001 27 60.759 31.243 76.88 46.121 8.88 46.121 8.88 52.121 76.88 52.121 60.759 67 65.001 71.243 87.123 49.121" transform="rotate(90 48.001 49.121)"/>
  </Icon>
);




