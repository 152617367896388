import { useContext, useEffect, useState } from "react";
import { Dialog } from "../../../../design/dialog/dialog";
import { activeUserContext } from "../../../active-user-context";
import { Edit } from "../../../../design/icons/edit";
import { Check } from "../../../../design/icons/check";
import { Input } from "../../../../design/form/input/input";
import * as Yup from "yup";
import { Field } from "../../../../design/form/field";
import styles from "./edit-user-dialog.module.scss";
import { DialogStateReturn } from "reakit/ts";
import { API } from "aws-amplify";

const editUserSchema: Yup.Schema = Yup.object().shape({
  userEmail: Yup.string().email("כתובת המייל שהכנסת לא תקינה").required("שכחת להזין כתובת"),
  userName: Yup.string(),
});

export const EditUserDialog = ({
  dialog,
  onEdit,
}: {
  dialog: DialogStateReturn;
  onEdit: () => void;
}) => {
  const [activeUser, setActiveUser] = useContext(activeUserContext);
  const [isNameEditActive, setNameEditActive] = useState(false);
  const [isEmailEditActive, setEmailEditActive] = useState(false);
  const [name, setName] = useState(activeUser?.user_name || "");
  const [email, setEmail] = useState(activeUser?.user_email || "");
  const [loading, setLoading] = useState(false);

  const handleNameKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      setNameEditActive(false);
    }

    if (event.key === "Tab") {
      setNameEditActive(false);
      setEmailEditActive(true);
    }
  };

  const handleEmailKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      setEmailEditActive(false);
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    event.preventDefault();
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    event.preventDefault();
  };

  const handleSubmit = async () => {
    const urlName = process.env.REACT_APP_MULTI_TENANT_API_NAME!;
    const endpoint = `/user/${activeUser?.user_uuid}`;
    const body =
      email === activeUser?.user_email
        ? {
            user_name: name,
          }
        : {
            user_name: name,
            user_email: email,
          };
    const myInit = { body: body };
    try {
      setLoading(true);
      await API.patch(urlName, endpoint, myInit);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      onEdit();
      handleClose();
    }
  };

  const handleClose = () => {
    setActiveUser(undefined);
    setNameEditActive(false);
    setEmailEditActive(false);
    dialog.hide();
  };

  useEffect(() => {
    if (activeUser) {
      setName(activeUser?.user_name);
      setEmail(activeUser?.user_email);
    }
  }, [activeUser]);

  return activeUser ? (
    <Dialog
      {...dialog}
      variant='form'
      header={
        isNameEditActive ? (
          <div className={styles.field}>
            <Input
              onKeyDown={handleNameKeyDown}
              name='userName'
              onChange={handleNameChange}
              value={name}
            />
            <span onClick={() => setNameEditActive(false)} style={{ cursor: "pointer" }}>
              <Check size={24} />
            </span>
          </div>
        ) : (
          <div className={styles.field}>
            <span>{name}</span>
            <span onClick={() => setNameEditActive(true)} style={{ cursor: "pointer" }}>
              <Edit size={24} />
            </span>
          </div>
        )
      }
      initialValues={{ userName: name, userEmail: email }}
      onSubmit={handleSubmit}
      onClose={handleClose}
      validationSchema={editUserSchema}
      aria-label='עריכת פרטי משתמש'
    >
      <div>
        {isEmailEditActive ? (
          <div className={styles.field}>
            <Field name='userEmail' value={email} onChange={handleEmailChange}>
              <Input onKeyDown={handleEmailKeyDown} />
            </Field>
            <span onClick={() => setEmailEditActive(false)} style={{ cursor: "pointer" }}>
              <Check size={24} />
            </span>
          </div>
        ) : (
          <div className={styles.field}>
            <span>{email}</span>
            <span onClick={() => setEmailEditActive(true)} style={{ cursor: "pointer" }}>
              <Edit size={24} style={{ display: "inline-block" }} />
            </span>
          </div>
        )}
      </div>
    </Dialog>
  ) : (
    <></>
  );
};
