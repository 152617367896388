import React from 'react';
import { Icon, IconProps } from './icon';

export const OptionsHorizontal = ({...props}: IconProps) => (
  <Icon 
    viewBox="0 0 96 96"
    fill="currentColor"
    {...props}
  >
    <path d="M32,48 C32,52.418278 28.418278,56 24,56 C19.581722,56 16,52.418278 16,48 C16,43.581722 19.581722,40 24,40 C28.418278,40 32,43.581722 32,48 Z M56,48 C56,52.418278 52.418278,56 48,56 C43.581722,56 40,52.418278 40,48 C40,43.581722 43.581722,40 48,40 C52.418278,40 56,43.581722 56,48 Z M80,48 C80,52.418278 76.418278,56 72,56 C67.581722,56 64,52.418278 64,48 C64,43.581722 67.581722,40 72,40 C76.418278,40 80,43.581722 80,48 Z"></path>
  </Icon>
);


