import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useMe } from '../../../../api/use-me';
import { Table } from '../../../../design/table/table';
import { Column } from '../../../../design/table/types';
import { getDigitTimeDiff } from '../../../../utils/time/time';
import { CallAttributes, call_getTheGroup, call_hasTheGroup } from '../../../interfaces';
import styles from './waiting-calls-list.module.scss';
import { safeParsePhoneNumber } from '../../../../utils';
import { waitingCallsFilter } from '../../../utils/callFilters';

interface WaitingCallsListProps {
  calls: CallAttributes[];
  tableName: string | undefined;
  wait_time_threshold: number;
}

export const WaitingCallsList = (props: WaitingCallsListProps) => {
  const user = useMe();
  const selfManagedGroupUuids = user.data?.domain.config.selfManagedGroups || [];

  const [currentTime, setCurrentTime] = useState<Date>(new Date());

  useEffect(() => {
    const intervalId: NodeJS.Timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    }
  }, []);

  const refinedData = props.calls
//  .filter(waitingCallsFilter) // already done in tables-container
    .filter(call => call_hasTheGroup(call, selfManagedGroupUuids))
    .map(call => {
      const group = call_getTheGroup(call, selfManagedGroupUuids)!;

      return {
        ...call,
        groupName: group.groupName,
        origination: safeParsePhoneNumber(call.origination || '', "IL") ?? 'מספר חסום',
        waitTime: getDigitTimeDiff(group.enterTime, currentTime)
      }
    }
  );

  const columns: Array<Column<typeof refinedData[0], keyof typeof refinedData[0]>> = [
    {
      key: 'groupName',
      header: 'מחלקה',
      className: styles.groupName
    },
    {
      key: 'origination',
      header: 'מתקשר',
      className: styles.callerNumber
    },
    {
      key: 'waitTime',
      header: 'זמן המתנה',
      render: (row) => {
        const waitTime = currentTime.getTime() - row.startTime.getTime();
        const className = classNames(styles.waitTime, { [styles.exceedTimeLimit]: waitTime > props.wait_time_threshold * 1000 });
        return (<div className={className}>{row.waitTime}</div>)
      }
    }
  ];

  return (
    <Table columns={columns} data={refinedData} showHeaders tableName={props?.tableName} />
  )
}
