import classNames from 'classnames';
import { memo, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useMe } from '../../api/use-me';
import { Domain, useUsers } from '../../api/use-users';
import { Button } from '../../design/button';
import { ButtonWithDropdown } from '../../design/button-with-dropdown/button-with-dropdown';
import { ArrowRight } from '../../design/icons';
import { ChevronLeft } from '../../design/icons/chevron-left';
import { ChevronRight } from '../../design/icons/chevron-right';
import { Spinner } from '../../design/spinner/spinner';
import { AgentsStatusList, AgentsStatusSums } from '../common/components';
import { AgentAttributes, BreakType } from '../interfaces';
import { getAgentsStatusSums } from '../utils/getAgentsStatusSums';
import styles from './menu.module.scss';

export const Menu = memo(({ stopFullScreen, data, breakTypes }: MenuProps) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const navigate = useNavigate();

  const backToHadshanut = async () => {
    await stopFullScreen();
    navigate('/');
  }

  const handleToggleMenu = () => {
    setExpanded(!expanded);
  }

  const loading = breakTypes === undefined;

  return (
    <div className={classNames(styles.menu, { [styles.expanded]: expanded })}>

      <div className={styles.topButtons}>

        {
          expanded ?
            <div className={styles.backToHadshanutButton}>
              <NavLink to='/' onClick={backToHadshanut}><ArrowRight /></NavLink>
              <Button variant='link' onClick={backToHadshanut}>חזרה</Button>
            </div> :
            <div></div>
        }
        <div className={styles.menuExpandButton} onClick={handleToggleMenu}>
          {
            expanded ?
              <ChevronRight color='white' /> :
              <ChevronLeft color='white' />
          }
        </div>
      </div>

      {
        loading ? (
          <div className={styles.loadingMessage}><Spinner /></div>
        ): (
          <div>
            <MenuContent expanded={expanded} agents={data} breakTypes={breakTypes} />
            {
              expanded && (
                <AgentsStatusList agents={data} breakTypes={breakTypes} />
              )
            }
          </div>
        )
      }
    </div>
  )
})

export const MenuContent = ({ expanded, agents, breakTypes }: MenuContentProps) => {
  const statusSums = getAgentsStatusSums(agents, breakTypes);

  return (
    <div className={classNames(styles.menuContent, { [styles.collapsed]: !expanded })}>
      <ViewModeSelection expanded={expanded} />
      <HorizontalPartition />
      <AgentsStatusSums agentsStatus={statusSums.map(status => ({ label: status.status.name, count: status.value, color: status.status.color }))} />
      <HorizontalPartition />
    </div>
  )
}

interface MenuContentProps {
  expanded: boolean;
  agents: AgentAttributes[];
  breakTypes: BreakType[];
}

const ViewModeSelection = ({ expanded }: { expanded: boolean }) => {

  // const [monitorConfig, setMonitorConfig] = useContext(MonitorStateContext);
  const navigate = useNavigate();
  const { data: user } = useMe();
  const domains = useUsers(user?.id).users?.[0]?.domains.filter((domain: Omit<Domain, "users">) => domain.domain_uuid === user?.domain.id);

  return (
    <div className={classNames(styles.viewModeSelection, {[styles.expanded]: expanded })}>

      <div className={classNames(styles.userInfo, {[styles.expanded]: expanded })}>
        <div className={styles.userName}> {user?.name} </div>
        {expanded && <div className={styles.domainName}> {domains?.[0]?.domain_owner_name ?? ""} </div>}
      </div>

      <ButtonWithDropdown
        label={'מוקד'}
        buttonClassName={styles.buttonStyle}
        // label={monitorConfig.isAdmin ? 'מנהל' : 'מוקד'}
      >
        <div className={styles.dropdownItem} onClick={() => {
          // setMonitorConfig(prev => ({
          //   ...prev,
          //   isAdmin: true
          // }));
          navigate('/monitor/admin');
        }}>מנהל</div>
        {/* <div className={styles.dropdownItem} onClick={() => {
          if (monitorConfig.isAdmin) {
            window.open('/monitor', '_blank');
          }
        }}>מוקד</div> */}
      </ButtonWithDropdown>
    </div>
  );
}

const HorizontalPartition = () => <div className={styles.partitionHorizontal} />

interface MenuProps {
  stopFullScreen: () => Promise<void>;
  data: AgentAttributes[];
  breakTypes?: BreakType[] | undefined;
}