import { useContext } from 'react';
import { Dialog } from '../design/dialog/dialog';
import { activeBotContext } from './bot-list/bot-context';
import { useNavigate } from 'react-router-dom';

const DIALOG_LABEL = 'עריכת בוט פעיל';
const SUBMIT_LABEL = 'המשך';
const WARNING = `האם ברצונך לערוך בוט פעיל?
הבוט יישאר פעיל וניתן יהיה לצפות ולערוך את גרסת הפרסום האחרונה בלחיצה על תאריך הפרסום שלו.`;
export const EditWarningDialog = ({ dialog }: any) => {
  const [activeBot, setActiveBot] = useContext(activeBotContext);
  const navigate = useNavigate();
  
  return (
    <Dialog {...dialog}
      variant="caution"
      header={DIALOG_LABEL}
      onSubmit={() => {
        const id = activeBot?.id;
        setActiveBot(undefined);
        navigate(`/whatsapp-bots/${id}/edit`, {
          state: { published: true }
        });
      }}
      submitLabel={SUBMIT_LABEL}
      onClose={() => {}}
      aria-label={DIALOG_LABEL}
    >
      {WARNING}
    </Dialog >
  );
};