import { useCallback, useMemo, useState } from 'react';

import { Section } from '../types';

export const useFormScroll = (sections: Section[]) => {
  const [selectedSection, setSelectedSection] = useState<Section>(sections[0]);

  const handleScroll = useCallback(() => {
    const MAX = Number.MAX_VALUE;
    const SOME_CONSTANT = 4;
    const sectionsHeights = sections.map((section) => {
      const el = document.getElementById(section.id)!;
      const elRect = el.getBoundingClientRect();
      return { ...section, top: elRect.top + elRect.height / SOME_CONSTANT };
    });

    const highlightedSection = sectionsHeights.reduce(
      (min, curr) => {
        if (curr.top < 0) return min;
        return curr.top < min.top ? curr : min;
      },
      { ...sections[0], top: MAX }
    );

    const { top, ...section } = highlightedSection;
    setSelectedSection(section);
  }, [sections]);

  const memoSelectedSection = useMemo(() => selectedSection, [selectedSection]);

  return {
    handleScroll,
    memoSelectedSection,
  };
};
