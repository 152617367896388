import React, { useState } from "react";
import { useDestinations } from "../../api/use-destinations";
import { BusinessHours, TimeConditionResource } from "./business-hours/business-hours";
import { DestinationNode, DestinationNodeProps } from "./destination-node";
import { CallflowNode, CallflowNodeContent, CallflowNodeHeader, CallflowNodeTitle, CallflowNodeOverline } from "./callflow-node/callflow-node";
import { useEffect } from "react";
import { useMe } from "../../api/use-me";

export function TimeConditionNode(props: DestinationNodeProps) {
  const user = useMe();
  const destinations = useDestinations(user?.data);
  const destination = destinations.data ? destinations.data[props.destination] : null;
  const [nextDestination, setNextDestination] = useState<string>();

  useEffect(() => {
    if (!nextDestination && destination) {
      const action = destination.conditionalActions[0];

      if (action?.action?.destination) {
        setNextDestination(action.action.destination);
      }
    }
  }, [nextDestination, destination]);

  return (
    <>
      <CallflowNode className={props.className}>
        <CallflowNodeHeader>
          <CallflowNodeOverline>{props.overline}</CallflowNodeOverline>
          <CallflowNodeTitle>{props.title || "שעות פעילות"}</CallflowNodeTitle>
        </CallflowNodeHeader>
        <CallflowNodeContent>
          <BusinessHours
            timeCondition={destination as unknown as TimeConditionResource}
            onChange={condition => setNextDestination(condition.action.destination)}
            activeActionDestination={nextDestination}
          />
        </CallflowNodeContent>
      </CallflowNode>
      {nextDestination && (
        <DestinationNode
          destination={nextDestination}
        />
      )}
    </>
  )
}