import { useNavigate, useParams } from "react-router-dom";
import { ButtonWithDropdown } from "../../../design/button-with-dropdown/button-with-dropdown";
import { Caution, Emergency, RemoveCircle, View } from "../../../design/icons";
import { ChevronDown } from "../../../design/icons/chevron-down";
import { ChevronUp } from "../../../design/icons/chevron-up";
import styles from "./list-item.module.scss";
import EventDialog from "../../dialer-dialog/event-dialog";
import { useDialogState } from "reakit";
import { useState } from "react";
import { DialerEvent, DialerEventType } from "../../../api/dialers";
import CancelEventDialog from "../../dialer-dialog/cancel-event-dialog";
import DeleteGroupDialog from "../../dialer-dialog/delete-group-dialog";
import { Remove } from "../../../design/icons/remove";

export default function ListItem({
  data,
  onListItemClick,
  mutateEvents,
  mutateGroups,
}: ListItemProps) {
  const isActive = data.activeEvent !== undefined;
  let translatedEventType;
  switch (data.activeEvent?.event_type) {
    case "emergency":
      translatedEventType = "אירוע אמת";
      break;
    case "drill":
      translatedEventType = "אירוע תרגיל";
      break;
    case "cancelation":
      translatedEventType = "ביטול אירוע";
      break;
  }

  return (
    <li className={styles.listItem}>
      <span className={styles.dialGroupRow} onClick={onListItemClick}>
        <span className={styles.dialerDetails}>
          <span className={styles.strong}>{data.dialer_id}</span>
          &nbsp;
          <span>{data.name}</span>
        </span>
        <span className={styles.activeEvent}>
          {isActive ? `${translatedEventType} בתהליך` : undefined}
        </span>
        {isActive ? (
          <div className={styles.eventApprovals}>
            <span className={styles.strong}>{data.activeEvent?.num_of_approvals} אישרו הגעה</span>
            <span>מתוך {data.activeEvent?.num_of_callees}</span>
          </div>
        ) : undefined}
      </span>
      <ButtonWithDropdown
        label='פעולות'
        buttonClassName={styles.menuButton}
        expandedBadge={<ChevronUp />}
        collapsedBadge={<ChevronDown />}
      >
        <MenuItems
          activeEvent={data.activeEvent}
          dialer_id={data.dialer_id}
          dialer_name={data.name}
          mutateEvents={mutateEvents}
          mutateGroups={mutateGroups}
        />
      </ButtonWithDropdown>
    </li>
  );
}

interface ListItemProps {
  data: {
    dialer_id: number;
    activeEvent?: DialerEvent | undefined;
    name: string;
  };
  onListItemClick: () => void;
  mutateEvents: () => void;
  mutateGroups: () => void;
}

const MenuItems = ({
  activeEvent,
  dialer_id,
  dialer_name,
  mutateEvents,
  mutateGroups,
}: MenuItemsProps) => {
  const navigate = useNavigate();
  const params = useParams<any>()["*"]?.replace(/^\/|\/$/g, "");
  const launchEventDialog = useDialogState();
  const cancelEventDialog = useDialogState();
  const deleteDialerGroupDialog = useDialogState();
  const [eventType, setEventType] = useState<DialerEventType>("emergency");
  const isActive = activeEvent !== undefined;

  const handleSelectEventType = (eventType: DialerEventType) => {
    setEventType(eventType);
    launchEventDialog.show();
  };

  const handleSelectActionType = () => {
    deleteDialerGroupDialog.show();
  };

  if (!isActive) {
    return (
      <div>
        <div className={styles.menuItem} onClick={() => handleSelectEventType("emergency")}>
          <span className={styles.caution}>הפעלת אירוע אמת</span>
          <Emergency />
        </div>

        <div className={styles.menuItem} onClick={() => handleSelectEventType("drill")}>
          <span>הפעלת תרגיל</span>
          <Caution />
        </div>

        <div className={styles.menuItem} onClick={handleSelectActionType}>
          <span className={styles.caution}>מחיקת קבוצת קריאה</span>
          <Remove />
        </div>
        <EventDialog
          dialog={launchEventDialog}
          eventType={eventType}
          dialerId={dialer_id}
          dialerName={dialer_name}
          mutateEvents={mutateEvents}
        />
        <DeleteGroupDialog
          dialog={deleteDialerGroupDialog}
          dialerId={dialer_id}
          dialerName={dialer_name}
          mutateGroups={mutateGroups}
        />
      </div>
    );
  }

  switch (activeEvent.event_type) {
    case "emergency": {
      return (
        <div>
          <div className={styles.menuItem} onClick={() => cancelEventDialog.show()}>
            <span>ביטול אירוע</span>
            <RemoveCircle />
          </div>

          <div
            className={styles.menuItem}
            onClick={() =>
              navigate(`/emergency-dialer/event/${activeEvent.event_id || ""}`, {
                state: { urlParams: params },
              })
            }
          >
            <span className={styles.link}>צפייה ביומן אירוע</span>
            <View />
          </div>
          <CancelEventDialog
            dialog={cancelEventDialog}
            activeEvent={activeEvent}
            mutateEvents={mutateEvents}
          />
        </div>
      );
    }
    case "drill": {
      return (
        <div>
          <div
            className={styles.menuItem}
            onClick={() => {
              setEventType("emergency");
              launchEventDialog.show();
            }}
          >
            <span className={styles.caution}>הפעלת אירוע אמת</span>
            <Emergency />
          </div>
          <div className={styles.menuItem} onClick={() => cancelEventDialog.show()}>
            <span>ביטול תרגיל</span>
            <RemoveCircle />
          </div>
          <div
            className={styles.menuItem}
            onClick={() => () => {
              navigate(`/emergency-dialer/event/${activeEvent.event_id}`, {
                state: { urlParams: params },
              });
            }}
          >
            <span className={styles.link}>צפייה ביומן אירוע</span>
            <View />
          </div>
          <CancelEventDialog
            dialog={cancelEventDialog}
            activeEvent={activeEvent}
            mutateEvents={mutateEvents}
          />
          <EventDialog
            dialog={launchEventDialog}
            eventType={eventType}
            dialerId={dialer_id}
            dialerName={dialer_name}
            mutateEvents={mutateEvents}
          />
        </div>
      );
    }
    case "cancelation": {
      return (
        <div>
          <div
            className={styles.menuItem}
            onClick={() => {
              setEventType("emergency");
              launchEventDialog.show();
            }}
          >
            <span className={styles.caution}>הפעלת אירוע אמת</span>
            <Emergency />
          </div>

          <div
            className={styles.menuItem}
            onClick={() => {
              setEventType("drill");
              launchEventDialog.show();
            }}
          >
            <span>הפעלת תרגיל</span>
            <Caution />
          </div>
          <div className={styles.menuItem} onClick={() => cancelEventDialog.show()}>
            <span>עצירת אירוע ביטול</span>
            <RemoveCircle />
          </div>
          <CancelEventDialog
            dialog={cancelEventDialog}
            activeEvent={activeEvent}
            mutateEvents={mutateEvents}
          />
          <EventDialog
            dialog={launchEventDialog}
            eventType={eventType}
            dialerId={dialer_id}
            dialerName={dialer_name}
            mutateEvents={mutateEvents}
          />
        </div>
      );
    }
  }
};

interface MenuItemsProps {
  dialer_id: number;
  dialer_name: string;
  activeEvent?: DialerEvent | undefined;
  mutateEvents: () => void;
  mutateGroups: () => void;
}
