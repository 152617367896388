import { API } from 'aws-amplify';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useInvitation } from '../../api/use-invitation';
import { Spinner } from '../../design/spinner/spinner';
import { AuthLayout } from '../../layouts/auth-layout/auth-layout';
import { Page } from '../../page';
import { CustomerRegistrationForm, CustomerRegistrationFormValues } from '../components/customer-registration-form';
import errorDictionary from '../error-dictionary';

export function InvitationPage() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>()
  const [error, setError] = useState<string>()
  const invitation = useInvitation(id!);

  const handleSubmit = async (values: CustomerRegistrationFormValues) => {
    const body = {...values, username: values.username.toLowerCase()};
    try {
      setError(undefined);
      setLoading(true);
      await API.post(process.env.REACT_APP_WEB_API_NAME!, '/customers/invitations/registration', {
        headers: {
          'x-ipcom-invitation-id': id,
        },
        body: body
      });
      navigate(`/auth/confirm`, { state: body });
    } catch (error: any) {
      setLoading(false);
      setError(errorDictionary[error.response.data.code]);
    }
  }

  if (invitation.loading) {
    return (
      <Page name="Invitation">
        <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spinner />
        </div>
      </Page>
    )
  }

  if (invitation.error) {
    return (
      <Page name="Invitation">
        <AuthLayout>
          שגיאה
        </AuthLayout>
      </Page>
    )
  }

  if (invitation.data && invitation.data.status !== "PENDING") {
    return (
      <Page name="Invitation">
        <AuthLayout>
          ההזמנה כבר לא בתוקף
        </AuthLayout>
      </Page>
    )
  }

  return (
    <Page name="Invitation">
      <AuthLayout>
        <h1>הרשמה - {invitation.data?.user.organizationName}</h1>
        <CustomerRegistrationForm
          initialValues={{
            gender: 'male',
            username: '',
            password: ''
          }}
          onSubmit={values => handleSubmit(values)}
          loading={loading}
          error={error}
        />
      </AuthLayout>
    </Page>
  );
}

export type InvitationPageParams = string | Record<string, string | undefined>;

