import styles from "./break-types-table.module.scss";
import { BreakType } from "../interfaces";
import { FieldArray, Form, Formik } from "formik";
import { Field } from "../../design/form/field";
import { Input } from "../../design/form/input/input";
import { Select } from "../../design/form/select";
import { API } from "aws-amplify";

const fieldArrayName = "break_types";
export const BreakTypesTable = ({
  breakTypes,
  domainUuid,
}: {
  breakTypes: BreakType[];
  domainUuid: string;
}) => {
  const onBlur = async (e: any) => {
    try {
      const [ , indexStr, fieldName] = e.target.name.split(".");
      const newValue = e.target.value;
      const index = parseInt(indexStr);
      const breakType = breakTypes[index];

      if (newValue === breakType[fieldName as keyof BreakType]) return;
      await API.patch(process.env.REACT_APP_CCM_API_NAME!, `/break-types?code=${breakType.code}`, {
        body: { [fieldName]: newValue },
      });
    } catch (error: any) {
      console.error("error: ", error.message || JSON.stringify(error));
    }
  };

  return (
    <Formik initialValues={{ break_types: breakTypes }} onSubmit={() => {}} enableReinitialize>
      {({ values }) => (
        <Form>
          <FieldArray
            name={fieldArrayName}
            render={() => (
              <div className={styles.sectionContainer}>
                <div className={styles.listContainer}>
                  <ul className={styles.optionsList}>
                    {values.break_types.length > 0 &&
                      values.break_types.map((breakType, index) => {
                        return (
                          <Option key={index} data={breakType} rowIndex={index} onBlur={onBlur} />
                        );
                      })}
                  </ul>
                </div>
              </div>
            )}
          />
        </Form>
      )}
    </Formik>
  );
};

const Option = ({ data, rowIndex, onBlur }: OptionProps) => {
  const BREAK_TYPES = ["break", "mission"];

  return (
    <div className={styles.optionContainer}>
      <span className={styles.optionIndex}>{data.code}</span>
      <div className={styles.option}>
        <Field name={`break_types.${rowIndex}.label`} value={data.label} onBlur={onBlur}>
          <Input placeholder='לדוגמה: הפסקת צהריים' id={`${rowIndex}-input`} />
        </Field>

        <Field name={`break_types.${rowIndex}.type`} value={data.type} onChange={onBlur}>
          <Select>
            {BREAK_TYPES.map((breakType) => {
              return (
                <option key={`${data.code}-${breakType}`} value={breakType}>
                  {" "}
                  {breakType === "break" ? "הפסקה" : "משימה"}{" "}
                </option>
              );
            })}
          </Select>
        </Field>
      </div>
    </div>
  );
};
interface OptionProps {
  data: BreakType;
  rowIndex: number;
  onBlur: (e: any) => void;
}
