import { getBezierPath } from 'reactflow';
import { Button } from '../../design/button';
import { Remove } from '../../design/icons/remove';
import styles from './custom-edge.module.scss';

const foreignObjectSize = 40;

export const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data
}: any /* Some type problems with these arguments... 
					Need to find the correct types for them */) => {

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

	
  return (
		<>
			<path
				id={id}
				style={style}
				className="react-flow__edge-path"
				d={edgePath}
				markerEnd={markerEnd}
			/>
			<foreignObject
					width={foreignObjectSize}
					height={foreignObjectSize}
					x={labelX - foreignObjectSize / 2}
					y={(labelY - foreignObjectSize / 2) + 4}
					requiredExtensions="http://www.w3.org/1999/xhtml"
			>
				{ data?.expanded &&
					<Button
						className={styles.deleteButton}
						onClick={(e) => data.onRemoveOption(id)}
					>
						<Remove size={24} className={styles.deleteIcon} />
					</Button>
				}
			</foreignObject>
    </>
  );
}