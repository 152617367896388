import { CallAttributes, call_getTheGroupMember } from "../../interfaces";

// SLA rules:
// * take into account calls with all of: direction is inbound, of relevant group, with valid times, either older than SLA threshold or ended
// * for calls that visited more than one group, the first relevant group is used for calculations
// * if answered within <= SLA seconds, regard as good, else regard as bad
// * the SLA is ratio (percentage) of good calls per all relavant (good + bad) calls

type uuid = string;

export interface SlaCounts {
  total: number;            // number of all (relevant) calls
  good: number;             // number of good (relevant, within SLA threshold) calls
  sla: number | undefined;  // percentage of good per total calls, undefined if total = 0
}

export function calculateSlaCounts(
  calls: CallAttributes[],            // all calls
  slaThresholdSeconds: number,        // max ringing time for answered call to be regarded as meeting SLA
  selfManagedGroupUuids: uuid[],
  monitoredGroupUuids: uuid[],        // groups selected by user
  validCallThresholdSeconds: number   // min length for call that was answered to be regarded as meeting SLA
): SlaCounts
{
  // iterate on calls
  const { total, good } = calls
    // take only inbound
    .filter(call => call.callDirection === 'inbound')
    // reduce into callCounts: { total, good }
    .reduce((callCounts, call) => {
      // find the relevant group and member (the queue and agent time info)
      const groupMember = monitoredGroupUuids && call_getTheGroupMember(call, selfManagedGroupUuids);

      if (!groupMember || !groupMember.member) {
        return callCounts; // shouldn't happen
      }

      const { group, member } = groupMember;

      // calculate call's times
      const enterTime  = group.enterTime   && new Date(group.enterTime  ).getTime();  // enterTIme is when the call entered group (vs. member)
      const answerTime = member.answerTime && new Date(member.answerTime).getTime();
      const exitTime   = member.exitTime   && new Date(member.exitTime  ).getTime();

      // only calls with enterTime are relevant
      if (!enterTime) {
        return callCounts; // shouldn't happen
      }

      // a call that is still ringing, and younger than SLA threshold, is irrelevant
      if (!answerTime && !exitTime && ((Date.now() - enterTime) < (slaThresholdSeconds * 1000))) {
        return callCounts;
      }

      // a call is considered good if rang < SLA threshold and then answered
      const ringShortEnough = enterTime && answerTime && ((answerTime - enterTime) < (slaThresholdSeconds * 1000));

      // a call is considered answered after N seconds of being answered
      const talkLongEnough  = answerTime && exitTime && ((exitTime - answerTime) > (validCallThresholdSeconds * 1000));

      // count the call in total and maybe as good
      return {
        total: callCounts.total + 1,
        good: callCounts.good + (ringShortEnough && talkLongEnough ? 1 : 0),
      };
    }, { total: 0, good: 0 });

  // calculate SLA
  const sla = total ? Math.round(good / total * 100) : undefined;
  return { total, good, sla };
}
