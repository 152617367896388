import { Field } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useMe } from "../../../api/use-me";
import { Button } from "../../../design/button";
import { Checkbox } from "../../../design/checkbox/checkbox";
import { Filter } from "../../../design/filter/filter";
import { OptionsHorizontal } from "../../../design/icons";
import {
  SegmentedControl,
  SegmentedControlOption,
} from "../../../design/segmented-control/segmented-control";
import {
  buttonTextStrings,
  getCallbackRequestStatusString,
} from "../../../utils/strings/string-translator";
import { DialerRequest } from "../../types";
import { useGroups } from "../../../api/use-groups";
import { useURLParams } from "../../../app/use-url-params";
import styles from "../desktop/calls-list/call-list-item/call-list-item.module.scss";
import { RenderTags } from "../filter-tag/render-tags";
import { getHebTimeDiff } from "../../../utils/time/time";
import { parsePhoneNumber } from "libphonenumber-js";

import { Spinner } from "../../../design/spinner/spinner";
import useDialers from "../../use-dialers";
import { safeParsePhoneNumber } from "../../../utils/strings/parse-phone";

interface RequestListProps {
  requests: DialerRequest[];
  params: {
    dateRange: {
      from_date: string;
      to_date: string;
    };
    selectedDate: string;
    checked: Set<string>;
    editState: boolean
    handleAttemptOpen: (request: DialerRequest) => void;
    handleStatusSelection: (date: string, status: string) => void;
    handleDateSelection: (date: Date) => void;
    handlePageBottom: () => void;
    handleCheckChange: (key: string) => void
  };
  counters: {
    date: string;
    count: {
      total: number;
      waiting: number;
      active: number;
      completed: number;
      failed: number;
      canceled: number;
    };
  }[];
}

enum TagLabels {
  open = 1,
  completed,
  canceled,
  failed,
}

/**
 * This function calculates which call status tag should be displayed as clicked
 * @param statusList An array of strings formatted as YYYY-MM-DD:TagLabel
 * @param date The date in question, also a string formatted as YYYY-MM-DD
 * @returns A number representing the index of the selected tag
 */
const getDateStatus = (statusList: string[], date: string) => {
  const relevantEntry = statusList.find((entry) => {
    const indexOfColon = entry.indexOf(":");
    return entry.substring(0, indexOfColon) === date;
  });

  if (!relevantEntry) {
    return 0;
  }
  const status = relevantEntry.substring(relevantEntry.indexOf(":") + 1);
  return TagLabels[status as keyof typeof TagLabels];
};
export const CallbackRequestsList = (props: RequestListProps) => {
  const params = useParams<any>()['*']?.replace(/^\/|\/$/g, "")!;

  const requests = props.requests;
  const observer = useRef<IntersectionObserver>(null!);
  const lastRequestElementRef = useCallback((requestDiv: HTMLDivElement) => {

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(observees => {
      if (observees[0].isIntersecting) {
        props.params.handlePageBottom();
      }
    })

    if (requestDiv) {
      observer.current.observe(requestDiv);
    }

  }, [])



  const meObject = useMe();

  const agentGroups = useGroups(meObject.data?.domain?.id);
  const { dialers } = useDialers();
  const maxAttempts = dialers ? Math.max(...dialers.map(dialer => dialer.cust_max_attempts)) : 0;
  const numOfAttempts = Array.from({ length: maxAttempts }, (_, i) => i + 1);

  const {
    urlParams,
    urlParamsMultiValue,
    removeURLParam,
    removeURLParams,
    setURLParam,
    setURLParams,
  } = useURLParams({
    path: "/callbacks",
    params,
  });

  const defaultTag = urlParams.status_per_day
    ? getDateStatus(
      urlParams.status_per_day.split(","),
      props.params.selectedDate
    )
    : 0;
  const [clickedTag, setClickedTag] = useState(defaultTag);

  useEffect(() => {
    setClickedTag(0);
  }, [props.params.selectedDate])

  useEffect(() => {
    props.params.handleStatusSelection(
      props.params.selectedDate,
      TagLabels[clickedTag]
    );
  }, [clickedTag]);

  const onTagClick = (index: number) => {
    setClickedTag(index);
  }

  return (
    <div>
      <section>
        <div style={{ padding: "0 2px 0 2px" }}>
          <RenderTags
            clickedTag={clickedTag}
            onTagClick={onTagClick}
            counters={props.counters[0]?.count}
          />
        </div>
        <div className={styles.filters}>
          <Filter
            active={urlParams.agent_group_numbers}
            badge={
              urlParamsMultiValue.agent_group_numbers &&
                urlParamsMultiValue.agent_group_numbers.length > 0 ? (
                <div className={styles.filterBadge}>
                  {urlParamsMultiValue.agent_group_numbers.length}
                </div>
              ) : undefined
            }
            initialValues={{
              agent_group_numbers: urlParamsMultiValue.agent_group_numbers,
            }}
            label="קבוצה"
            onReset={() => removeURLParam("agent_group_numbers")}
            onSubmit={(values) => {
              const value = values.agent_group_numbers.join(",");
              setURLParam("agent_group_numbers", value);
            }}
          >

            {agentGroups?.data?.map(({ number, name }) =>
              <Field
                key={number}
                component={(props: any) => (
                  <Checkbox title={name} {...props.field} />
                )}
                name="agent_group_numbers"
                type="checkbox"
                value={number}
              />
            )}
          </Filter>
          <Filter
            active={urlParams.to_age || urlParams.from_age}
            badge={
              urlParams.from_age || urlParams.to_age ? (
                <div className={styles.filterBadge}>
                  <OptionsHorizontal size={16} />
                </div>
              ) : undefined
            }
            initialValues={{
              rangeFunction: urlParams.to_age ? "max" : "min",
              days:
                Math.floor(
                  parseInt(urlParams.to_age || urlParams.from_age) / (24 * 60)
                ) || "",
              hours:
                (parseInt(urlParams.to_age || urlParams.from_age) / 60) % 24 ||
                "",
            }}
            label="זמן המתנה לשיחה חוזרת"
            onReset={() => removeURLParams(["to_age", "from_age"])}
            onSubmit={(values) => {
              const ttaValue =
                (parseInt(values.days.toString()) * 24 * 60 || 0) +
                (parseInt(values.hours.toString()) * 60 || 0);
              if (values.rangeFunction === "min") {
                setURLParams({
                  to_age: null,
                  from_age: ttaValue,
                });
              } else {
                setURLParams({
                  to_age: ttaValue,
                  from_age: null,
                });
              }
            }}
          >
            <SegmentedControl>
              <Field
                name="rangeFunction"
                type="radio"
                value="min"
                component={(props: any) => (
                  <SegmentedControlOption {...props.field}>
                    לפחות
                  </SegmentedControlOption>
                )}
              />
              <Field
                name="rangeFunction"
                type="radio"
                value="max"
                component={(props: any) => (
                  <SegmentedControlOption {...props.field}>
                    עד
                  </SegmentedControlOption>
                )}
              />
            </SegmentedControl>
            <div className={styles.timeInput}>
              <label className={styles.timeUnit}>
                <Field
                  autoComplete="off"
                  name="days"
                  type="text"
                  placeholder="0"
                />
                <div className={styles.timeUnitLabel}>ימים</div>
              </label>
              <label className={styles.timeUnit}>
                <Field
                  autoComplete="off"
                  name="hours"
                  type="text"
                  placeholder="0"
                />
                <div className={styles.timeUnitLabel}>שעות</div>
              </label>
            </div>
          </Filter>
          <Filter
            active={urlParams.attempts}
            badge={
              urlParamsMultiValue.attempts &&
                urlParamsMultiValue.attempts.length > 0 ? (
                <div className={styles.filterBadge}>
                  {urlParamsMultiValue.attempts.indexOf("...") === -1
                    ? urlParamsMultiValue.attempts.length
                    : urlParamsMultiValue.attempts.length - 1}
                </div>
              ) : undefined
            }
            initialValues={{ attempts: urlParamsMultiValue.attempts }}
            label="ניסיונות חיוג"
            onReset={() => removeURLParam("attempts")}
            onSubmit={(values: { attempts: string[] }) => {
              setURLParam("attempts", values.attempts.join(","));
            }}
          >
            {numOfAttempts.map(attempts =>
              <Field
                key={attempts}
                component={(props: any) => (
                  <Checkbox
                    title={`ניסיון חיוג ${attempts}`}
                    {...props.field}
                  />
                )}
                name="attempts"
                type="checkbox"
                value={`${attempts}`}
              />
            )}
          </Filter>
        </div>
      </section>
      <section>
        {requests.map((request, index) => {

          if (null === request.request_uuid) {
            return (
              <div className={styles.emptyMessage} key={0}>
                <div className={styles.title}>לא מצאנו בקשות בסטטוס המבוקש</div>
                <div className={styles.content}>
                  שווה לוודא שיש בקשות בסטטוס זה ביום הנבחר
                </div>
              </div>
            )
          }
          const return_number: string = request.return_number
            ? request.return_number
            : ``;
          const start_time= request.start_date;
          const formatted_start_time = start_time ?? new Date();
          const end_time = request.end_date;
          const formatted_end_time = end_time ?? new Date();
          const waitingTime = getHebTimeDiff(
            formatted_start_time,
            formatted_end_time
          );
          const requestOpen = request.request_status === "active" || request.request_status === "waiting";
          return (
            <div
              ref={index === requests.length - 1 ? lastRequestElementRef : undefined}
              key={request.request_uuid}
              className={styles.root}>
              <span className={styles.requestDetails}>
                <div className={styles.firstRow}>
                  <span>{getCallbackRequestStatusString(request)}</span>
                  <div className={styles.dot} />
                  <span>
                    {formatted_start_time?.toLocaleTimeString("he-IL", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </div>
                <div className={styles.detailsContainer}>
                  <div className={styles.phoneBack}>טלפון לחזרה</div>
                  <div className={styles.phoneBack}>
                    {safeParsePhoneNumber(return_number, "IL")}
                  </div>
                  <div>זמן המתנה</div>
                  <div>{waitingTime}</div>
                </div>
                <br />
                <div>
                  <Button
                    variant="link"
                    onClick={() => props.params.handleAttemptOpen(request)}
                  >
                    פרטי בקשה
                  </Button>
                </div>
              </span>
              <span
                className={
                  props.params.editState && requestOpen
                    ? styles.editSectionDisplay
                    : styles.editSectionHidden
                }
              >
                <Checkbox
                  checked={props.params.checked.has(request.request_uuid)}
                  onChange={() => props.params.handleCheckChange(request.request_uuid)}
                />
              </span>
            </div>
          );
        })}
      </section>
      {
        props.counters[0]?.count.total === 0 ?
          <div className={styles.loadingMessage}>
            <Spinner />
          </div>
          : <></>
      }
    </div>
  );
};
