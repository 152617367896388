import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0075DB',
      light: '#C9EDFF',
      dark: '#0075DB',
    },
    secondary: {
      main: '#757D86',
      light: '#E1E6EC',
      dark: '#9EA5AD',
    },
    error: {
      main: '#C94236',
    },
    success: {
      main: '#0075DB',
    },
    warning: {
      main: '#F1C21B',
    },
    grey: {
      50: '#F3F5F8',
      100: '#E1E6EC',
      200: '#BCC3CB',
      300: '#9EA5AD',
      400: '#757D86',
    },
  },
  typography: {
    fontFamily: 'ploni, sans-serif',

    h1: {
      fontSize: '2.75rem', // 44px
      lineHeight: '3rem',
      color: '#181D24',
      fontWeight: 400,
    },
    h2: {
      fontSize: '2rem', // 32px
      color: '#181D24',
      fontWeight: 400,
    },
    h3: {
      fontSize: '1.75rem', // 28px
      color: '#181D24',
      fontWeight: 400,
    },
    h4: {
      fontSize: '1.5rem', // 24px
      lineHeight: '2rem',
      color: '#181D24',
      fontWeight: 400,
    },
    h5: {
      fontSize: '1.25rem', // 20px
      color: '#181D24',
      fontWeight: 400,
    },
    boldCaption1: {
      fontWeight: 600,
      fontSize: '1rem', // 16px
      color: '#181D24',
      lineHeight: '1.25rem',
    },
    boldCaption2: {
      fontWeight: 600,
      fontSize: '0.875rem', // 14px
      color: '#181D24',
      lineHeight: '1.25rem',
    },
    boldText1: {
      fontWeight: 600,
      fontSize: '1rem', // 16px
      color: '#757D86',
      lineHeight: '1.25rem',
    },
    boldText2: {
      fontWeight: 600,
      fontSize: '1rem', // 16px
      color: '#9EA5AD',
      lineHeight: '1.25rem',
    },
    largeText1: {
      fontWeight: 400,
      fontSize: '1.125rem', // 18px
      color: '#181D24',
      lineHeight: '1.5rem',
    },
    largeText2: {
      fontWeight: 400,
      fontSize: '1.125rem', // 18px
      color: '#9EA5AD',
      lineHeight: '1.5rem',
    },
    largeText3: {
      fontWeight: 400,
      fontSize: '1.125rem', // 18px
      color: '#757D86',
      lineHeight: '1.5rem',
    },
    regularText1: {
      fontWeight: 400,
      fontSize: '1rem', // 16px
      color: '#181D24',
      lineHeight: '1.25rem',
    },
    regularText2: {
      fontWeight: 400,
      fontSize: '1rem', // 16px
      color: '#757D86',
      lineHeight: '1.25rem',
    },
    regularText3: {
      fontWeight: 400,
      fontSize: '1rem', // 16px
      color: '#9EA5AD',
      lineHeight: '1.25rem',
    },
  },
  fontSizes: {
    small: '0.75rem', // 12px
    medium: '0.875rem', // 14px
    main: '1rem', // 16px
    large: '1.125rem', // 18px
  },
  lineHeights: {
    small: '1rem', // 16px
    medium: '1.125rem', // 18px;
    main: '1.25rem', // 20px;
    large: '1.375rem', // 22px
    xlarge: '1.5rem', // 24px
  },
  fieldHeights: {
    xSmall: '1.125rem', // 18px
    small: '1.25rem', // 20px
    medium: '1.375rem', // 22px
    default: '1.5rem', // 24px
    main: '2.25rem', // 36px
    large: '2.5rem', // 40px
  },
  fontWeights: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  texts: {
    primary: '#181D24',
    secondary: '#757D86',
    tertiary: '#9EA5AD',
    white: '#FFFFFF',
    darkBlue: '#004E9B',
  },
  borderRadius: {
    base: '4px',
    default: '8px',
    badge: '30px',
    main: '16px',
    rounded: '20px',
    ellipse: '40px',
    circle: '50%',
  },
  borders: {
    default: '1px solid #BCC3CB',
    main: '1px solid #181D24',
    primary: '1px solid #0075DB',
  },
  sizes: {
    small: {
      width: '16px',
      height: '16px',
    },
    medium: {
      width: '24px',
      height: '24px',
    },
    large: {
      width: '32px',
      height: '32px',
    },
  },
  backgrounds: {
    bg_main: '#FFFFFF',
    bg_modal: '#F2F5F8F2',
    bg_upload_file: '#F3F5F8',
  },
  shadows: [
    'none',
    '0px 0px 0px 1px #BCC3CB, 0px 12px 12px -12px #BCC3CB, 0px 40px 40px -24px #E1E6EC',
    '0px 0px 0px 1px rgba(27, 56, 89, 0.15), 0px 12px 12px -12px rgba(34, 57, 84, 0.30), 0px 10px 60px 0px rgba(27, 56, 89, 0.05)',
    '0px 0px 0px 1px rgba(24, 29, 36, 0.10), 0px 12px 12px -12px #BCC3CB, 0px 40px 40px -24px #E1E6EC',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  spacing: 4,
  direction: 'rtl',
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'ploni';
          font-style: normal;
          font-weight: 400;
          font-display: swap;
          color: #181D24;
          src: url('public/fonts/ploni-regular-aaa.woff2') format('woff2'),
                url('public/fonts/ploni-regular-aaa.woff') format('woff'),
                url('public/fonts/ploni-regular-aaa.eot') format('eot');
        },
        @font-face {
          font-family: 'ploni';
          font-style: semibold;
          font-weight: 600;
          font-display: swap;
          color: #181D24;
          src: url('public/fonts/ploni-demibold-aaa.woff2') format('woff2'),
                url('public/fonts/ploni-demibold-aaa.woff') format('woff'),
                url('public/fonts/ploni-demibold-aaa.eot') format('eot');
        }
        color: '#181D24',
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          const { variant, color } = ownerState;
          const commonStyles = {
            borderRadius: theme.borderRadius.rounded,
            fontSize: theme.fontSizes.main,
            fontWeight: theme.fontWeights.semiBold,
            padding: `${theme.spacing(2.5)} ${theme.spacing(8)}`,
          };

          if (variant === 'contained' && color === 'primary') {
            return {
              ...commonStyles,
              background: 'linear-gradient(225deg, #282FEF 0.03%, #33B1FF 98.61%)',
              color: theme.palette.common.white,
            };
          }

          if (variant === 'contained' && color === 'secondary') {
            return {
              ...commonStyles,
              backgroundColor: theme.palette.secondary.light,
              color: theme.texts.primary,
            };
          }

          if (variant === 'text' && color === 'primary') {
            return {
              ...commonStyles,
              color: theme.palette.primary.dark,
            };
          }

          return {};
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'standard' && {
            borderRadius: theme.borderRadius.default,
          }),
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant && {
            borderRadius: theme.borderRadius.default,
            borderColor: theme.palette.grey[100],
            width: '100%',
          }),
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          fontSize: theme.fontSizes.large,
          fontWeight: theme.fontWeights.regular,
          color: theme.palette.grey[400],
        }),
      },
    },
  },
});

export default theme;
