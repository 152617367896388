// monitor-call interfaces and functions

type uuid = string;

type cti_CallDirection = "inbound" | "outbound" | "local" | "unknown";
type cti_CallEndReason = "answered" | "canceled" | "failed" | "ivr" | "voicemail" | "other";

interface dao_ModelObject {
  id: string;
  createDate?: Date;
}

interface cti_DItem extends dao_ModelObject {
  // nothing here
}

interface cti_DItemCti extends cti_DItem {
  seq: string;
  domainUuid: uuid;
  number: string;
  name?: string | undefined;
  description?: string | undefined;
}

export interface   VisitedMember {
  memberUuid: uuid;
  memberNumber: string;
  memberName: string;
  enterTime: Date;
  answerTime?: Date | undefined;
  exitTime?: Date | undefined;
}

export enum GroupType {
  RG = "rg",
  CCQ = "ccq",
}

export interface VisitedGroup {
  groupUuid: uuid;
  groupNumber: string;
  groupName: string;
  groupType: GroupType;
  enterTime: Date;
  exitTime?: Date | undefined;
  visitedMembers: VisitedMember[];
}

export interface cti_DCall extends cti_DItemCti {
  // call
  callDirection: cti_CallDirection;
  connCountForCallDirection: number;
  origination: string | undefined;       // the one that created the call
  destination: string | undefined;       // the one that created the call
  dialedNumber: string | undefined;      // the one that created the call
  corrupted: boolean | undefined;        // the logic suspects there's something wrong with the call, eg missing event
  callHandled: boolean | undefined;      // the customer got what they need regardless of human answer. eg callback or info sms. in these cases the dialplan sets CALL_HANDLED=1
  visitedGroups: VisitedGroup[];         // Rings/Queues that the call visited
  recordFiles: string[] | undefined;
  mos?: number | undefined;
  endReason: cti_CallEndReason | undefined;
  // uuids
  agentUuid: uuid | undefined;           // the agent uuid that the call is at NOW
  // call times
  startTime: Date;                       // when event callCreated is sent
  billingTime: Date | undefined;         // for outbound call only: when event callAnswered is sent
  ringingTime: Date | undefined;         // when event callRinging is sent
  answerTime: Date | undefined;          // when event callAnswered is sent
  endTime: Date | undefined;             // when event callEnded is sent
  // call once flags
  sentCallRinging: boolean;              // to ensure sent once
  sentCallAnswered: boolean;             // to ensure sent once
}

export interface CallAttributes extends cti_DCall {
  // nothing here, this interface is for naming compatability with previous version of the Agent interface
}

export function call_theGroupUuid(call: CallAttributes, selfManagedGroupUuids: uuid[]): uuid | undefined {
  return call_getTheGroup(call, selfManagedGroupUuids)?.groupUuid;
}

export function call_getTheGroup(call: CallAttributes, selfManagedGroupUuids: uuid[]): VisitedGroup | undefined{
  return call_getTheGroupMember(call, selfManagedGroupUuids)?.group;
}

export function call_getTheMember(call: CallAttributes, selfManagedGroupUuids: uuid[]): VisitedMember | undefined {
  return call_getTheGroupMember(call, selfManagedGroupUuids)?.member;
}

export function call_groupUuids(call: CallAttributes): uuid[] {
  return call.visitedGroups.map(group => group.groupUuid);
}

export function call_groupTypes(call: CallAttributes): GroupType[] {
  return call.visitedGroups.map(group => group.groupType);
}

export function call_groupEnterTimes(call: CallAttributes): Date[] {
  return call.visitedGroups.map(group => group.enterTime);
}

export function call_groupExitTimes(call: CallAttributes): (Date | undefined)[] {
  return call.visitedGroups.map(group => group.exitTime);
}

export interface VisitedGroupAndMember {
  group: VisitedGroup,
  member: VisitedMember | undefined,
};

// call_getTheAnswerGroupMember: search for answering self-managed group member, top down
export function call_getTheAnswerGroupMember(call: CallAttributes, selfManagedGroupUuids: uuid[]): VisitedGroupAndMember | undefined {
  for (const group of call.visitedGroups) {
    if (selfManagedGroupUuids[0] === "*" || selfManagedGroupUuids.includes(group.groupUuid)) {
      for (const member of group.visitedMembers) {
        if (member.answerTime) {
          return { group, member }; // there's exactly 1 answering member
        }
      }
    }
  }

  return undefined;
}

// call_getTheNonAnswerGroupMember: search for non-answering self-managed group member, top down
export function call_getTheNonAnswerGroupMember(call: CallAttributes, selfManagedGroupUuids: uuid[]): VisitedGroupAndMember | undefined {
  for (const group of call.visitedGroups) {
    if (selfManagedGroupUuids[0] === "*" || selfManagedGroupUuids.includes(group.groupUuid)) {
      for (const member of group.visitedMembers) {
        if (!member.answerTime) {
          return { group, member };
        }
      }
    }
  }

  return undefined;
}

// call_getThe1stGroup - take the self-managed group, top down
export function call_getThe1stGroup(call: CallAttributes, selfManagedGroupUuids: uuid[]): VisitedGroup | undefined {
  for (const group of call.visitedGroups) {
    if (selfManagedGroupUuids[0] === "*" || selfManagedGroupUuids.includes(group.groupUuid)) {
      return group;
    }
  }

  return undefined;
}

// call_getThe1stAnswerOrLastGroupMember - get the self-managed group member that answered, and the last ggroup member if non answered
export function call_getThe1stAnswerOrLastGroupMember(call: CallAttributes, selfManagedGroupUuids: uuid[]): VisitedGroupAndMember | undefined {
  let lastCandidateGroup: VisitedGroup | undefined = undefined;

  // look for member that answered
  for (const group of call.visitedGroups) {
    if (selfManagedGroupUuids[0] === "*" || selfManagedGroupUuids.includes(group.groupUuid)) {
      lastCandidateGroup = group;

      // take answering member if there
      for (const member of group.visitedMembers) {
        if (member.answerTime) {
          return { group, member };
        }
      }
    }
  }

  // no member answered, take last group as found earlier
  if (lastCandidateGroup) {
    const group = lastCandidateGroup;
    const member = group?.visitedMembers[0];
    return { group, member };
  }

  return undefined;
}

// call_getTheGroupNoMember get the group that is self managed, top down
export function call_getTheGroupNoMember(call: CallAttributes, selfManagedGroupUuids: uuid[]): VisitedGroupAndMember | undefined {
  for (const group of call.visitedGroups) {
    if (selfManagedGroupUuids[0] === "*" || selfManagedGroupUuids.includes(group.groupUuid)) {
      return { group, member: undefined };
    }
  }

  return undefined;
}

export function call_hasTheGroup(call: CallAttributes, selfManagedGroupUuids: uuid[]): boolean {
  return call_getTheGroupNoMember(call, selfManagedGroupUuids) !== undefined;
}

export function call_getTheGroupMember(call: CallAttributes, selfManagedGroupUuids: uuid[]): VisitedGroupAndMember | undefined {
  return call_getTheAnswerGroupMember   (call, selfManagedGroupUuids) ||
         call_getTheNonAnswerGroupMember(call, selfManagedGroupUuids) ||
         call_getTheGroupNoMember       (call, selfManagedGroupUuids) ;
}
