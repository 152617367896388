import ButtonBar from "../../design/button-bar/button-bar";
import { Button, ButtonProps } from "../../design/button/button";
import {
  AppLayout,
  AppLayoutContainer,
  AppLayoutHeader,
  AppLayoutTitle,
} from "../../layouts/app-layout/app-layout";
import { Page } from "../../page";
import { useParams } from "react-router-dom";
import { Filter } from "../../design/filter/filter";
import { Callee, DialGroup, JobTitle } from "../../api/dialers";
import { Table } from "../../design/table/table";
import styles from "./callees-page.module.scss";
import { useCallees } from "../use-callees";
import { useJobTitles } from "../use-job-titles";
import { Spinner } from "../../design/spinner/spinner";
import { ReactNode, useContext, useState } from "react";
import { Field } from "formik";
import { Checkbox } from "../../design/checkbox/checkbox";
import { useURLParams } from "../../app/use-url-params";
import { EditCalleeDialog } from "./dialogs/edit-callee-dialog";
import { useDialogState } from "reakit";
import { JobTitlesDialog } from "./dialogs/job-titles-dialog";
import { LoadExcelDialog } from "./dialogs/load-excel-dialog";
import { CalleeContext } from "../context/callee-context";
import { API } from "aws-amplify";
import { CalleeStatusDialog } from "./dialogs/callee-status-dialog";
import { Tag } from "../../design/tag/tag";
import classNames from "classnames";
import { useDialGroups } from "../use-dial-groups";
import { TagsEditor } from "../../design/tags-editor/tags-editor";
import { ButtonWithDropdown } from "../../design/button-with-dropdown/button-with-dropdown";
import { ChevronDown } from "../../design/icons/chevron-down";
import { safeParsePhoneNumber } from "../../utils/strings/parse-phone";
import { Column, SearchLocation } from "../../design/table/types";
import { DeleteCalleeDialog } from "./dialogs/delete-callee-dialog";

export type ActionType =
  | null
  | "addCallee"
  | "editCallee"
  | "loadExcel"
  | "editJobTitle"
  | "deleteCallee"
  | "editCalleeStatus";
type StatusType = "active" | "inactive";

export default function CalleesPage() {
  const {
    data: callees,
    error: calleesError,
    loading: calleesLoading,
    mutate: calleesMutate,
  } = useCallees();
  const [checked, setChecked] = useState<Set<string>>(new Set<string>());
  const [statusType, setStatusType] = useState<StatusType>("active");
  const [currentAction, setCurrentAction] = useState<ActionType>(null);

  const editCalleeDialog = useDialogState();
  const jobTitlesDialog = useDialogState();
  const loadExcelDialog = useDialogState();
  const calleeStatusDialog = useDialogState();
  const deleteCalleeDialog = useDialogState();
  const [, setSelectedCallee] = useContext(CalleeContext);
  const params = useParams<any>()["*"]?.replace(/^\/|\/$/g, "");
  const url = useURLParams({ path: "/emergency-dialer/callees", params });
  const {
    data: jobTitles,
    error: jobTitlesError,
    loading: jobTitlesLoading,
    mutate: jobTitleMutate,
  } = useJobTitles();
  const { data: dialGroups, error: dialGroupsError, loading: dialGroupsLoading } = useDialGroups();

  const activeFilteredCities: string[] = url.urlParams.cities?.split(",");
  const activeFilteredDialGroups: string[] = url.urlParams.dial_groups?.split(",");
  const activeFilteredStatus: string[] = url.urlParams.status?.split(",");
  const activeFilteredJobTitles: string[] = url.urlParams.job_titles?.split(",");

  const addCalleeEnabled = jobTitles ? jobTitles.length > 0 : false;
  const editMode = currentAction === "editCalleeStatus" || currentAction === "deleteCallee";
  const actionButtons: Button[] = [
    { id: "load-excel-file", label: "טעינת אקסל", onClick: () => handleActionSelect("loadExcel") },
    { id: "job-titles", label: "תפקידים", onClick: () => handleActionSelect("editJobTitle") },
    {
      id: "add-callee",
      label: "הוספת כונן",
      variant: "primary",
      onClick: () => handleActionSelect("addCallee"),
      disabled: !addCalleeEnabled,
    },
  ];
  const buttons = actionButtons.map((btn) => {
    return {
      id: btn.id,
      component: (
        <Button
          key={btn.id}
          variant={btn.variant ? btn.variant : "regular"}
          className={styles.actionButton}
          onClick={btn.onClick || undefined}
          disabled={btn.disabled}
        >
          {btn.label}
        </Button>
      ),
    };
  });

  if (calleesLoading || jobTitlesLoading || dialGroupsLoading) {
    return (
      <Page name='Callees Page'>
        <AppLayout>
          <AppLayoutContainer>
            <AppLayoutHeader></AppLayoutHeader>
          </AppLayoutContainer>
          <Spinner />
        </AppLayout>
      </Page>
    );
  } else if (calleesError || jobTitlesError || dialGroupsError) {
    return (
      <Page name='Callees Page'>
        <AppLayout>
          <AppLayoutContainer>
            <AppLayoutHeader></AppLayoutHeader>
          </AppLayoutContainer>
          ארעה שגיאה בקריאת הנתונים
        </AppLayout>
      </Page>
    );
  } else if (!callees || !jobTitles || !dialGroups) {
    return (
      <Page name='Callees Page'>
        <AppLayout>
          <AppLayoutContainer>
            <AppLayoutHeader></AppLayoutHeader>
          </AppLayoutContainer>
          לא נמצאו נתונים
        </AppLayout>
      </Page>
    );
  }

  const handleMasterCheck = () => {
    const newChecked = new Set<string>();
    if (checked.size < filteredData.length) {
      filteredData.forEach((callee) => newChecked.add(callee.callee_uuid));
      newChecked.add("master");
    }
    setChecked(newChecked);
  };

  const handleCheckChange = (id: string) => {
    const newChecked = new Set<string>(checked);
    if (newChecked.has(id)) {
      newChecked.delete(id);
      newChecked.delete("master");
    } else {
      newChecked.add(id);
      if (newChecked.size === dataForTable.length) {
        newChecked.add("master");
      }
    }
    setChecked(newChecked);
  };

  const handleActionSelect = (selectedAction: ActionType) => {
    setCurrentAction(selectedAction);
    switch (selectedAction) {
      case "loadExcel":
        loadExcelDialog.show();
        break;
      case "editJobTitle":
        jobTitlesDialog.show();
        break;
      case "addCallee":
      case "editCallee":
        editCalleeDialog.show();
        break;
      case "deleteCallee":
        const selectedCallees = callees?.filter((callee) => checked.has(callee.callee_uuid));
        if (!selectedCallees?.length) {
          handleEditMode(null);
          calleeStatusDialog.hide();
          return;
        }
        deleteCalleeDialog.show();
        break;
      default:
        break;
    }
  };

  const handleStatusSelect = (statusType: StatusType) => {
    const selectedCallees = callees?.filter((callee) => checked.has(callee.callee_uuid));
    if (!selectedCallees?.length) {
      handleEditMode(null);
      calleeStatusDialog.hide();
      return;
    }
    setStatusType(statusType);
    calleeStatusDialog.show();
  };

  const handleEditMode = (selectedAction: ActionType) => {
    if (!selectedAction) {
      setChecked(new Set<string>());
    }
    setCurrentAction(selectedAction);
  };

  const handleSelectCallee = (callee: Callee, actionType: ActionType) => {
    setSelectedCallee(callee);
    handleActionSelect(actionType);
  };

  const handleSubmitCalleeStatus = async (status: "active" | "inactive") => {
    const selectedCallees = callees?.filter((callee) => checked.has(callee.callee_uuid));
    try {
      await Promise.all(
        selectedCallees.map((callee) => {
          const { create_date, update_date, ...rest } = callee;
          return API.put(
            process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!,
            `/callee?uuid=${callee.callee_uuid}`,
            {
              body: {
                ...rest,
                enabled: status === "active" ? true : false,
                dial_groups: rest.dial_groups || [],
              },
            }
          );
        })
      );
    } catch (err) {
      console.error(err);
    } finally {
      await calleesMutate();
      handleEditMode(null);
      calleeStatusDialog.hide();
    }
  };

  const renderDeleteCalleeBtn = (): ReactNode => {
    if (!editMode) {
      return (
        <Button onClick={() => handleEditMode("deleteCallee")} variant='regular'>
          מחיקת כונן
        </Button>
      );
    } else if (currentAction === "editCalleeStatus") {
      return;
    } else if (currentAction === "deleteCallee") {
      return (
        <Button onClick={() => handleActionSelect("deleteCallee")} variant='primary'>
          סיום
        </Button>
      );
    }
  };

  const renderEditCalleeStatusBtn = (): ReactNode => {
    if (!editMode || (editMode && currentAction === "editCalleeStatus")) {
      return (
        <ButtonWithDropdown
          label='שינוי סטטוס כונן'
          expandedBadge={<ChevronDown size={16} />}
          collapseOnClickOutside={false}
          onClick={() => handleEditMode("editCalleeStatus")}
          onClose={() => handleEditMode(null)}
          buttonClassName={styles.changeCalleeStatusButton}
          dropdownExpanded={editMode}
          id='callee-status-change-dropdown'
        >
          <div className={styles.enableCalleeButton} onClick={() => handleStatusSelect("active")}>
            <span>שינוי כונן לפעיל</span>
            <span style={{ color: "#181D24", fontSize: "xx-large" }}>+</span>
          </div>
          <div
            className={styles.disableCalleeButton}
            onClick={() => handleStatusSelect("inactive")}
          >
            <span>שינוי כונן ללא פעיל</span>
            <span style={{ color: "#181D24", fontSize: "xx-large" }}>-</span>
          </div>
        </ButtonWithDropdown>
      );
    }
    return;
  };

  const filterCities = (callee: Callee): boolean => {
    if (!url.urlParams.cities) {
      return true;
    }
    return activeFilteredCities?.includes(callee.city);
  };

  const filterDialGroups = (callee: Callee): boolean => {
    if (!url.urlParams.dial_groups) {
      return true;
    }
    return activeFilteredDialGroups.some((dialGroupId: string) => {
      return callee.dial_groups?.find((group) => group === parseInt(dialGroupId));
    });
  };

  const filterStatus = (callee: Callee): boolean => {
    if (!url.urlParams.status) {
      return true;
    }
    return activeFilteredStatus.some((status: string) => {
      return (callee.enabled && status === "active") || (!callee.enabled && status === "inactive");
    });
  };

  const filterJobTitles = (callee: Callee): boolean => {
    if (!url.urlParams.job_titles) {
      return true;
    }
    return activeFilteredJobTitles.some(
      (jobUuid: string) => callee.job_title_id === Number(jobUuid)
    );
  };

  const filteredNotForDisplay = (callees || []).filter((callee) => {
    let isFiltered = true;
    isFiltered &&= filterCities(callee);
    isFiltered &&= filterDialGroups(callee);
    isFiltered &&= filterStatus(callee);
    return isFiltered;
  });
  
  const filteredData = filteredNotForDisplay.filter(filterJobTitles);

  const cities = callees.map((callee) => callee.city);
  const distinctCities = cities.filter((city, index) => {
    return cities.indexOf(city) === index;
  });

  const activeJobTitles = jobTitles
    .filter((job) => filteredNotForDisplay?.some((callee) => callee.job_title_id === job.job_title_id))
    .map((job) => ({
      job,
      count: filteredNotForDisplay?.filter((c) => job.job_title_id === c.job_title_id).length || 0,
    }));


  const dataForTable = filteredData.map((callee) => {
    const jobTitleDescription: string | undefined = jobTitles?.find(
      (jobTitle) => jobTitle.job_title_id === callee.job_title_id
    )?.description;
    return {
      id: callee.callee_uuid,
      ...callee,
      job_title: jobTitleDescription,
      dial_groups: callee.dial_groups?.map(
        (group) => dialGroups?.find((dg) => dg.dialer_id === group)?.name
      ),
      menu: (
        <Button
          className={styles.editButton}
          onClick={() => handleSelectCallee(callee, "editCallee")}
        >
          ...
        </Button>
      ),
    };
  });

  const calleeNames = [...checked]
    .filter((id) => id !== "master")
    .map((id) => callees?.find((callee) => callee.callee_uuid === id)?.full_name);

  const columns: Array<Column<typeof dataForTable[0], keyof typeof dataForTable[0]>> = [
    {
      key: "id_number",
      header: "תעודת זהות",
      className: styles.id,
      render(row) {
        return (
          <div className={classNames({ [styles.disabledCallee]: !row.enabled })}>
            {row.id_number}
          </div>
        );
      },
    },
    {
      key: "full_name",
      header: "שם כונן",
      className: styles.name,
      render(row) {
        return (
          <div className={classNames({ [styles.disabledCallee]: !row.enabled })}>
            {row.full_name}
          </div>
        );
      },
    },
    {
      key: "phone_number1",
      header: "מספר טלפון",
      className: styles.phoneNumber,
      render(row) {
        const additionalNumbers = [row.phone_number1, row.phone_number2, row.phone_number3].filter(
          (number) => number !== null && number !== "" && number !== undefined
        );
        return (
          <div
            title={additionalNumbers.join(" ,")}
            className={classNames(
              { [styles.disabledCallee]: !row.enabled },
              styles.allPhoneNumbers
            )}
          >
            <span>{safeParsePhoneNumber(row.phone_number1 ?? "", "IL")}</span>
            {additionalNumbers.length > 1 ? (
              <span className={styles.moreContentLabel}>{`${additionalNumbers.length - 1}+`}</span>
            ) : undefined}
          </div>
        );
      },
    },
    {
      key: "job_title",
      header: "תפקיד",
      className: styles.jobTitle,
      render(row) {
        return (
          <div className={classNames({ [styles.disabledCallee]: !row.enabled })}>
            {row.job_title}
          </div>
        );
      },
    },
    {
      key: "dial_groups",
      header: "קבוצת קריאה",
      className: styles.dialGroups,
      render(row) {
        const calleeDialGroups = row.dial_groups || [];
        const allTags = calleeDialGroups.map((dialGroup) => ({ id: dialGroup!, text: dialGroup! }));
        return (
          <div className={styles.allDialGroups}>
            <span>{calleeDialGroups[0]}</span>
            {calleeDialGroups.length > 1 && (
              <TagsEditor
                allTags={allTags}
                initiallySelectedTags={allTags}
                tagClassName={styles.ReactTags__tag}
                containerClassName={styles.container}
                tagsContainerClassName={styles.tagsContainer}
                buttonLabel={`${calleeDialGroups.length - 1}+`}
                buttonClassName={styles.moreContentLabel}
                readOnly
              />
            )}
          </div>
        );
      },
    },
    {
      key: "city",
      header: "יישוב",
      className: styles.area,
      render(row) {
        return (
          <div className={classNames({ [styles.disabledCallee]: !row.enabled })}>{row.city}</div>
        );
      },
    },
    {
      key: "menu",
      header: "",
      className: styles.actionsMenu,
    },
  ];

  if (calleesError || jobTitlesError) {
    return <div>שגיאה</div>;
  }

  return (
    <Page name='Callees Page'>
      <AppLayout>
        <AppLayoutContainer>
          <AppLayoutHeader className={styles.appLayoutHeader}>
            <div className={styles.pageHeader}>
              <AppLayoutTitle>ניהול כוננים</AppLayoutTitle>
              <ButtonBar buttons={buttons} />
            </div>
          </AppLayoutHeader>
          <main>
            <Table
              columns={columns}
              data={dataForTable}
              search
              searchPlaceholder='חפש מספר נייד, תעודת זהות...'
              searchLocation={SearchLocation.RIGHT}
              checkboxes={editMode}
              checkedSet={checked}
              onCheckChange={handleCheckChange}
              onMasterCheck={handleMasterCheck}
            >
              <div className={styles.aboveTable}>
                <span>
                  <Filters
                    activeJobTitles={activeJobTitles}
                    distinctCities={distinctCities}
                    dialGroups={dialGroups}
                  />
                </span>
                <span className={styles.editStatusSection}>
                  {editMode && (
                    <Button variant='ghost' onClick={() => handleEditMode(null)}>
                      ביטול
                    </Button>
                  )}
                  {renderEditCalleeStatusBtn()}
                  {renderDeleteCalleeBtn()}
                </span>
              </div>
            </Table>
          </main>
          <EditCalleeDialog
            dialog={editCalleeDialog}
            callees={callees}
            calleesMutate={calleesMutate}
            action={currentAction}
          />
          <LoadExcelDialog dialog={loadExcelDialog} calleesMutate={calleesMutate} />
          <JobTitlesDialog
            jobTitles={jobTitles}
            callees={callees}
            dialog={jobTitlesDialog}
            jobTitleMutate={jobTitleMutate}
            jobTitlesLoading={jobTitlesLoading}
          />
          <CalleeStatusDialog
            dialog={calleeStatusDialog}
            statusType={statusType}
            calleeNames={calleeNames}
            handleStatusChange={() => handleSubmitCalleeStatus(statusType)}
          />
          <DeleteCalleeDialog
            selectedValues={checked}
            calleeNames={calleeNames}
            onClose={() => handleEditMode(null)}
            dialog={deleteCalleeDialog}
            calleesMutate={calleesMutate}
          />
        </AppLayoutContainer>
      </AppLayout>
    </Page>
  );
}

const Filters = ({ activeJobTitles, distinctCities, dialGroups }: FiltersProps) => {
  const params = useParams<any>()["*"]?.replace(/^\/|\/$/g, "");
  const url = useURLParams({ path: "/emergency-dialer/callees", params });
  const initialSelectedJobs = url.urlParams.job_titles;
  const [jobTitles, setJobTitles] = useState<number[]>(initialSelectedJobs?.split(",") || []);

  const isTagSelected = (id: number) => jobTitles.some((job) => job === id);

  const handleTagClick = (job: JobTitle) => {
    if (jobTitles.some((id) => id === job.job_title_id)) {
      const newJobTitles = jobTitles.filter((j) => j !== job.job_title_id);
      setJobTitles(newJobTitles);
      url.setURLParam("job_titles", newJobTitles.join(","));
    } else {
      const newJobTitles = [...jobTitles, job.job_title_id];
      setJobTitles(newJobTitles);
      url.setURLParam("job_titles", newJobTitles.join(","));
    }
  };

  const totalJobTitles = activeJobTitles?.reduce((acc, curr) => {
    return acc + curr.count;
  }, 0);

  const jobTitleTagButtons: Button[] = (activeJobTitles || [])?.map((job) => ({
    id: String(job.job.job_title_id),
    label: job.job.description,
    component: (
      <Tag
        key={job.job.job_title_id}
        label={`${job.job.description} (${job.count})`}
        variant='filled'
        color='neutral'
        className={classNames(styles.tag, {
          [styles.selectedTag]: isTagSelected(job.job.job_title_id),
        })}
        onClick={() => handleTagClick(job.job)}
      />
    ),
  }));

  const buttons = [
    {
      id: "all",
      label: `סה"כ (${totalJobTitles})`,
      component: (
        <Tag
          key={"all"}
          label={`סה"כ (${totalJobTitles})`}
          variant='filled'
          color='neutral'
          className={classNames(styles.tag, {
            [styles.selectedTag]:
              activeJobTitles?.length === jobTitles.length || jobTitles.length === 0,
          })}
          onClick={() => {
            url.removeURLParam("job_titles");
            setJobTitles([]);
          }}
        />
      ),
    },
    ...jobTitleTagButtons,
  ];

  const numFilteredCities = url.urlParamsMultiValue.cities?.length;
  const numFilteredDialGroups = url.urlParamsMultiValue.dial_groups?.length;

  const areFiltersActive =
    Object.keys(url.urlParams).filter((param) => filterKeys.includes(param)).length > 0;

  return (
    <div>
      <div className={styles.filters}>
        <Filter /* Dial Groups filter */
          active={url.urlParams.dial_groups}
          badge={
            numFilteredDialGroups ? (
              <div className={styles.filterBadge}>{numFilteredDialGroups}</div>
            ) : undefined
          }
          initialValues={{ dial_groups: url.urlParamsMultiValue.dial_groups }}
          label='קבוצת קריאה'
          onReset={() => url.removeURLParam("dial_groups")}
          onSubmit={(values) => {
            const value = values.dial_groups.join(",");
            url.setURLParam("dial_groups", value);
          }}
        >
          <div>
            {dialGroups?.map((dialGroup) => (
              <Field
                key={dialGroup.dialer_id}
                component={(props: any) => (
                  <Checkbox
                    title={dialGroup.dialer_id + " - " + dialGroup.name}
                    {...props.field}
                    style={{ marginBlockEnd: "8px" }}
                  />
                )}
                name='dial_groups'
                type='checkbox'
                value={`${dialGroup.dialer_id}`}
              />
            ))}
          </div>
        </Filter>

        <Filter /* Cities filter */
          active={url.urlParams.cities}
          badge={
            numFilteredCities ? (
              <div className={styles.filterBadge}>{numFilteredCities}</div>
            ) : undefined
          }
          initialValues={{ cities: url.urlParamsMultiValue.cities }}
          label='יישוב'
          onReset={() => url.removeURLParam("cities")}
          onSubmit={(values) => {
            const value = values.cities.join(",");
            url.setURLParam("cities", value);
          }}
        >
          <div>
            {distinctCities?.map((city) => (
              <Field
                key={city}
                component={(props: any) => (
                  <Checkbox title={city} {...props.field} style={{ marginBlockEnd: "8px" }} />
                )}
                name='cities'
                type='checkbox'
                value={city}
              />
            ))}
          </div>
        </Filter>

        <Filter /* Callee status filter */
          active={url.urlParams.status}
          badge={
            url.urlParamsMultiValue.status && url.urlParamsMultiValue.status?.length ? (
              <div className={styles.filterBadge}>{url.urlParamsMultiValue.status.length}</div>
            ) : undefined
          }
          initialValues={{ status: url.urlParamsMultiValue.status }}
          label='סטטוס כונן'
          onReset={() => url.removeURLParam("status")}
          onSubmit={(values) => {
            const value = values.status.join(",");
            url.setURLParam("status", value);
          }}
        >
          <div>
            <Field
              component={(props: any) => (
                <Checkbox title={"פעיל"} {...props.field} style={{ marginBlockEnd: "8px" }} />
              )}
              name='status'
              type='checkbox'
              value={"active"}
            />
            <Field
              component={(props: any) => (
                <Checkbox title={"לא פעיל"} {...props.field} style={{ marginBlockEnd: "8px" }} />
              )}
              name='status'
              type='checkbox'
              value={"inactive"}
            />
          </div>
        </Filter>
        {areFiltersActive && (
          <button
            className={styles.resetFiltersButton}
            onClick={() => {
              url.removeURLParams(filterKeys);
              setJobTitles([]);
            }}
          >
            איפוס
          </button>
        )}
      </div>

      <div className={styles.belowFilters}>
        <div className={styles.tagFiltersContainer}>
          <ButtonBar buttons={buttons} />
        </div>
      </div>
    </div>
  );
};

const filterKeys = ["job_titles", "dial_groups", "cities", "status"];

interface FiltersProps {
  activeJobTitles: { job: JobTitle; count: number }[];
  distinctCities: string[];
  dialGroups: DialGroup[] | undefined;
}

interface Button extends ButtonProps {
  id: string;
  label: string;
}
