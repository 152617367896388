import { Field, FieldProps, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { Checkbox, CheckboxProps } from "../checkbox";
import { ChevronDown } from "../icons/chevron-down";
import { ChevronUp } from "../icons/chevron-up";
import styles from "./multi-select.module.scss";
import { Search } from "../search/search";
import { Input } from "../form/input/input";

export const MultiSelect = <T extends MultiSelectItemType>({
  items,
  primaryItem,
  initialSelections,
  hidePrimaryItem = false,
  itemsCountForSearch = 5,
}: MultiSelectProps<T>) => {
  const [selected, setSelected] = useState<Set<string>>(new Set(initialSelections));
  const [expanded, setExpanded] = useState(false);
  const [searchString, setSearchString] = useState<string>("");
  const { setFieldValue } = useFormikContext();

  const getPrimaryCheckedState = (): CheckboxProps["state"] => {
    switch (selected.size) {
      case 0:
        return "empty";
      case items?.length:
        return "checked";
    }
    return "intermediate";
  };

  const [primaryChecked, setPrimaryChecked] = useState<CheckboxProps["state"]>("empty");

  useEffect(() => {
    if (!items) {
      return;
    }

    setPrimaryChecked(getPrimaryCheckedState());
  }, [items]);

  const handleCheck = (itemId: string): void => {
    if (!selected.has(itemId)) {
      const clone = new Set(selected.add(itemId));
      setSelected(clone);
      setPrimaryChecked(selected.size === items.length ? "checked" : "intermediate");
      return;
    }

    const clone = new Set<string>();
    selected.forEach((id) => {
      if (id === itemId) {
        return;
      }
      clone.add(id);
    });
    setSelected(clone);
    setPrimaryChecked(clone.size === 0 ? "empty" : "intermediate");
  };

  const handleCheckPrimary = () => {
    if (selected.size > 0) {
      setSelected(new Set());
      setFieldValue(primaryItem.id, []);
      setPrimaryChecked("empty");
      return;
    }

    setPrimaryChecked("checked");
    setFieldValue(
      primaryItem.id,
      items?.map((item) => item.id)
    );
    setSelected(new Set(items?.map((item) => item.id)));
  };
  const showItems = expanded || hidePrimaryItem;
  return (
    <div>
      {!hidePrimaryItem && (
        <div className={styles.item}>
          <Checkbox state={primaryChecked} onChange={handleCheckPrimary} />
          <span
            onClick={() => {
              setExpanded(!expanded);
            }}
            className={styles.button}
          >
            {primaryItem.label}
            {expanded ? <ChevronUp /> : <ChevronDown />}
          </span>
        </div>
      )}
      {showItems && itemsCountForSearch !== undefined && items.length >= itemsCountForSearch && (
        <div className={styles.searchBarContainer}>
          <Search
            inputClassName={styles.searchBar}
            placeholder='חיפוש'
            onChange={(e) => setSearchString(e.target.value)}
          />
        </div>
      )}
      {showItems && (
        <div className={!hidePrimaryItem ? styles.innerItemsWrapper : undefined}>
          {items
            ?.filter((item) => item.label.includes(searchString))
            ?.map((item) => (
              <div
                key={item.id}
                className={!hidePrimaryItem ? styles.innerItem : styles.innerItemNoPrimary}
              >
                <Field
                  name={primaryItem.id}
                  value={item.id}
                  type='checkbox'
                  component={({ field }: FieldProps) => (
                    <Checkbox
                      {...field}
                      checked={selected.has(item.id)}
                      title={item.label}
                      onChange={(e) => {
                        handleCheck(item.id);
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

interface MultiSelectItemType {
  id: string;
  label: string;
}

export interface MultiSelectProps<T extends MultiSelectItemType> {
  items: T[];
  primaryItem: T;
  initialSelections: string[];
  hidePrimaryItem?: boolean;
  itemsCountForSearch?: number;
}
