import { DataType } from '../../globalTypes/types';

export const getOptimisticData = (data: any, updatedData: any, dataType: DataType) => {
  switch (dataType) {
    case DataType.primitive:
    case DataType.date:
    case DataType.function:
    case DataType.symbol:
    case DataType.bigint:
    case DataType.undefined:
    case DataType.null:
      return updatedData;
    case DataType.object:
      return { ...data, ...updatedData };
    case DataType.array:
    case DataType.map:
      return [...data, updatedData];
    default:
      return updatedData;
  }
};
