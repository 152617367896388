import { API } from "aws-amplify";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDialogState } from "reakit";
import { Dialog } from "../../design/dialog/dialog";
import { Spinner } from "../../design/spinner/spinner";
import {
  useBusinessNumberEventCollection,
  useBusinessNumberEventResource,
  useBusinessNumberResource,
} from "../../api/use-business-number";
import { useMe } from "../../api/use-me";

export function DeleteEvent() {

  const { number, id } = useParams<{ number: string, id: string }>();
  const user = useMe();
  const businessNumber = useBusinessNumberResource(number!);
  const event = useBusinessNumberEventResource(number!, id!);
  const eventCollection = useBusinessNumberEventCollection(number!);
  const dialog = useDialogState({
    visible: true
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await API.del(process.env.REACT_APP_WEB_API_NAME!, `/domains/${user?.data?.domain.id}/business-numbers/${number}/events/${id}`, {});
      mixpanel.track('Delete Scheduled Event');
      await eventCollection.mutate();
      await businessNumber.mutate();
      navigate(`/my-numbers/${number}`);
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false);
    }

  }

  const handleCancel = () => {
    navigate(`/my-numbers/${number}`);
  }

  if (event.error) {
    return (
      <div>Error</div>
    )
  }


  return (
    <Dialog
      {...dialog}
      variant="caution"
      header={`מחיקת אירוע${event.data ? '–' + event.data.name : ''}`}
      onSubmit={handleDelete}
      onClose={handleCancel}
      hideOnClickOutside={false}
      loading={loading}
    >
      {
        event.loading ?
          <Spinner /> :
          <p>
            האם את/ה בטוח/ה שאת/ה רוצה למחוק את האירוע {event.data && `${event.data.name}`}? השינויים שהגדרת לתאריך {event.data && `${moment(event.data.date.split('~')[0]).format('DD/MM/YYYY')}`} לא ייכנסו לתוקף.
          </p>
      }
    </Dialog>
  );
}
