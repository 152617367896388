import React, { useState, InputHTMLAttributes, ChangeEvent } from 'react';
import classNames from 'classnames';
import { Search as SearchIcon } from '../icons/search';
import styles from './search.module.scss';
import { Reset } from '../icons';

export const Search = ({
  className,
  inputClassName,
  onChange,
  onReset,
  ...props
}: SearchProps) => {
  const [empty, setEmpty] = useState(
    (!props.defaultValue && !props.value) ||
    props.defaultValue?.toString().length === 0 ||
    props.value?.toString().length === 0
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmpty(e.target.value.length === 0);
    if (onChange) onChange(e);
  };

  const handleReset = () => {
    setEmpty(true);
    if (onReset) onReset();
  };

  return (
    <div className={classNames(styles.search, className)}>
      <SearchIcon
        className={classNames({ [styles.active]: !empty }, styles.searchIcon)}
        size={20}
      />
      <input
        className={classNames(styles.input, inputClassName)}
        onChange={handleChange}
        type="text"
        {...props}
      />
      {typeof onReset === "function" && !empty && 
        (
          <button
            type="button"
            className={styles.reset}
            onClick={() => handleReset()}
          >
            <Reset size={24} />
          </button>
        )}
    </div>
  );
};

export interface SearchProps extends InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string;
  onReset?: () => void;
}
