import { API } from "aws-amplify";
import useSWR from "swr";
import { DialerGlobalSettingsWithFiles } from "../api/dialers";
import { useMe } from "../api/use-me";
import { UserResource } from "../api/types";

const SWR_CONFIG = { revalidateOnFocus: false, revalidateOnReconnect: false };

export const useGlobalSettings = () => {
  const user = useMe();
  const endpoint = `/global-dialer-settings`;
  const { data, error, mutate, isLoading } = useSWR(
    user.data ? endpoint : null,
    (path) => fetcher(path, user.data!),
    SWR_CONFIG
  );

  return {
    data: isLoading ? undefined : (data?.data as DialerGlobalSettingsWithFiles),
    error: error,
    loading: isLoading,
    mutate: mutate,
  };
};

async function fetcher(path: string, user: UserResource) {
  try {
    return await API.get(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, path, {});
  } catch (error: any) {
    if (error.response?.status && error.response.status === 404) {
      await API.post(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, `/global-dialer-settings`, {
        body: getDefaultSettings(user),
      });
      return await API.get(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, path, {});
    }
    console.error("Could not fetch monitor configuration ");
    console.error(error);
  }
}

export const getDefaultSettings = (user: UserResource): DialerGlobalSettingsWithFiles => {
  const defaultSettings = DEFAULT_GLOBAL_SETTINGS as Record<string, any>;

  Object.entries(defaultSettings).forEach(([k, v]: string[]) => {
    if (k.includes("url")) {
      defaultSettings[k] = v.replace("{domain}", user.domain.name);
    }
  });

  return defaultSettings as DialerGlobalSettingsWithFiles;
};

export const DEFAULT_GLOBAL_SETTINGS: DialerGlobalSettingsWithFiles = {
  trauma_already_canceling_file_name: `trauma_already_canceling`,
  trauma_already_canceling_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_already_canceling.wav/file`,
  trauma_ask_when_cancel_active_file_name: `trauma_ask_when_cancel_active`,
  trauma_ask_when_cancel_active_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_ask_when_cancel_active.wav/file`,
  trauma_ask_when_drill_active_file_name: `trauma_ask_when_drill_active`,
  trauma_ask_when_drill_active_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_ask_when_drill_active.wav/file`,
  trauma_ask_when_no_active_file_name: `trauma_ask_when_no_active`,
  trauma_ask_when_no_active_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_ask_when_no_active.wav/file`,
  trauma_ask_when_real_active_file_name: `trauma_ask_when_real_active`,
  trauma_ask_when_real_active_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_ask_when_real_active.wav/file`,
  trauma_bye_file_name: `trauma_bye`,
  trauma_bye_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_bye.wav/file`,
  trauma_cancelation_canceled_file_name: `trauma_cancelation_canceled`,
  trauma_cancelation_canceled_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_cancelation_canceled.wav/file`,
  trauma_cancelation_confirm_file_name: `trauma_cancelation_confirm`,
  trauma_cancelation_confirm_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_cancelation_confirm.wav/file`,
  trauma_cancelation_prefix_file_name: `trauma_cancelation_prefix`,
  trauma_cancelation_prefix_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_cancelation_prefix.wav/file`,
  trauma_cancelation_rejected_file_name: `trauma_cancelation_rejected`,
  trauma_cancelation_rejected_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_cancelation_rejected.wav/file`,
  trauma_cancelation_type_file_name: `trauma_cancelation_type`,
  trauma_cancelation_type_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_cancelation_type.wav/file`,
  trauma_confirm_launch_file_name: `trauma_confirm_launch`,
  trauma_confirm_launch_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_confirm_launch.wav/file`,
  trauma_drill_prefix_file_name: `trauma_drill_prefix`,
  trauma_drill_prefix_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_drill_prefix.wav/file`,
  trauma_enter_launch_code_file_name: `trauma_enter_launch_code`,
  trauma_enter_launch_code_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_enter_launch_code.wav/file`,
  trauma_event_cancel_failed_file_name: `trauma_event_cancel_failed`,
  trauma_event_cancel_failed_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_event_cancel_failed.wav/file`,
  trauma_event_canceled_file_name: `trauma_event_canceled`,
  trauma_event_canceled_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_event_canceled.wav/file`,
  trauma_event_start_failed_file_name: `trauma_event_start_failed`,
  trauma_event_start_failed_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_event_start_failed.wav/file`,
  trauma_event_started_file_name: `trauma_event_started`,
  trauma_event_started_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_event_started.wav/file`,
  trauma_hakasha_shguya_file_name: `trauma_hakasha_shguya`,
  trauma_hakasha_shguya_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_hakasha_shguya.wav/file`,
  trauma_launch_cancel_cancelation_file_name: `trauma_launch_cancel_cancelation`,
  trauma_launch_cancel_cancelation_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_launch_cancel_cancelation.wav/file`,
  trauma_launch_cancelation_file_name: `trauma_launch_cancelation`,
  trauma_launch_cancelation_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_launch_cancelation.wav/file`,
  trauma_launch_code_invalid_file_name: `trauma_launch_code_invalid`,
  trauma_launch_code_invalid_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_launch_code_invalid.wav/file`,
  trauma_launch_code_not_exists_file_name: `trauma_launch_code_not_exists`,
  trauma_launch_code_not_exists_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_launch_code_not_exists.wav/file`,
  trauma_launch_drill_file_name: `trauma_launch_drill`,
  trauma_launch_drill_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_launch_drill.wav/file`,
  trauma_launch_real_file_name: `trauma_launch_real`,
  trauma_launch_real_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_launch_real.wav/file`,
  trauma_real_prefix_file_name: `trauma_real_prefix`,
  trauma_real_prefix_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_real_prefix.wav/file`,
  trauma_unauthorized_file_name: `trauma_unauthorized`,
  trauma_unauthorized_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_unauthorized.wav/file`,
  trauma_unauthorized_launch_code_file_name: `trauma_unauthorized_launch_code`,
  trauma_unauthorized_launch_code_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_unauthorized_launch_code.wav/file`,
  trauma_welcome_file_name: `trauma_welcome`,
  trauma_welcome_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:trauma_welcome.wav/file`,
  //
  domain_uuid: "",
  launch_code_max_attempts: 3,
  business_numbers: [],
};
