import React, { ChangeEvent, cloneElement, ReactElement } from 'react';
import { FieldConfig, useField } from 'formik';
import { AudioFileInputProps } from '../audio-file-input/audio-file-input';
import styles from './audio-file-field.module.scss';

export function AudioFileField({
  onChange,
  onReset,
  label,
  description,
  children,
  ...props
}: AudioFileFieldProps & FieldConfig) {
  const [, meta, helper] = useField(props);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) onChange(event);
    helper.setValue(event.target.files ? event.target.files[0] : null);
  };

  const handleReset = () => {
    if (onReset) onReset();
    helper.setValue(null);
  };

  const control = cloneElement(children as React.ReactElement<AudioFileInputProps>, {
    onReset: handleReset,
    onChange: handleChange,
  });

  if (label) {
    return (
      <div className={styles.root}>
        <span className={styles.label}>{label}</span>
        {description && <span className={styles.description}>{description}</span>}
        {control}
        {meta.error && meta.touched && <span className={styles.error}>{meta.error}</span>}
      </div>
    );
  }
  return (
    <>
      {control}
      {meta.error && meta.touched && <span className={styles.error}>{meta.error}</span>}
    </>
  );
}

interface AudioFileFieldProps {
  children: ReactElement<AudioFileInputProps>;
  description?: string;
  label?: string;
  required?: boolean;
  onChange?: (e: ChangeEvent<any>) => void;
  onReset?: () => void;
}
