import ApexChart from 'react-apexcharts';
import styles from './charts.module.scss';
import { STATUSES_FOR_PIE_CHART, BLUE, GREEN, GREY, RED, YELLOW, getStatus } from '../../monitor';
import { AgentAttributes, BreakType, StatusType } from '../../interfaces';
import { memo } from 'react';

const CHART_OFFSET_Y = 20;
const CHART_OFFSET_X = 20;
const FONT_WEIGHT = 400;
const FONT_SIZE = '48px';
const LABEL_OFFSET = -24;
const VALUE_OFFSET = -2;

export const AgentsChart = memo((props: AgentsChartProps) => {

  const statusCounts = STATUSES_FOR_PIE_CHART.map(status => ({
    status,
    value: props.agents.reduce((acc, agent) => {
      const breakType = props.breakTypes?.find(bt => bt.code === agent.breakType);
      let agentStatus = getStatus(agent.agentStatus, agent.agentState, agent.agentOutboundState, breakType);
      switch (agentStatus) {  // we decided that outbound-talking and inbound-ringing appear as busy ins the agent chart and agent strip
        case StatusType.BusyOutbound: agentStatus = StatusType.Busy; break;
        case StatusType.Receiving   : agentStatus = StatusType.Busy; break;
      }
      return agentStatus === status.id ? acc + 1 : acc;
    }, 0)
  }));

  return (
    <div id='agents-chart' className={styles.agentsChartContainer}>
      <div className={styles.agentsChartTitle}>מצב סוכנים</div>
      <ApexChart
        type='donut'
        options={{
          stroke: { lineCap: 'round' },
          chart: {
            offsetY: CHART_OFFSET_Y,
            offsetX: CHART_OFFSET_X
          },
          plotOptions: {
            pie: {
              donut: {
                size: '85%',
                labels: {
                  show: true,
                  name: {
                    offsetY: LABEL_OFFSET
                  },
                  value: {
                    offsetY: VALUE_OFFSET,
                    fontFamily: 'ploni',
                    fontSize: FONT_SIZE,
                    fontWeight: FONT_WEIGHT,
                  },
                  total: {
                    show: true,
                    label: undefined
                  }
                }
              },
            }
          },
          labels: ['בזמינות', 'שיחה', 'הפסקה', 'משימה', 'מצלצל'],
          colors: [GREEN, RED, GREY, YELLOW, BLUE],
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          }
        }}
        series={statusCounts.map(agent => agent.value)}
        height='105%'
      />
      <div className={styles.agentsChartMetadataContainer}>
        {
          statusCounts.map(agent => (
            <div key={agent.status.name} className={styles.agentsChartLegenditem}>
              <div className={styles.agentsChartLegendLabel}>
                <div className={styles.metadataMarker} style={{ backgroundColor: agent.status.color }} />
                {agent.status.name}
              </div>
              {agent.value}
            </div>)
          )
        }
      </div>
    </div>
  );
})

interface AgentsChartProps {
  agents: AgentAttributes[];
  breakTypes?: BreakType[] | undefined;
}