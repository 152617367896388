import { Typography } from '@mui/material';
import { ElementType, FocusEvent, forwardRef } from 'react';
import { Controller, FieldValues, useFieldArray, useFormContext } from 'react-hook-form';

import { DynamicInput } from './DynamicInput';
import { GLOBAL_TEXTS } from '../../../utils/globalTexts';
import { DynamicField, FieldKey } from '../../../formConfig/types';
import { CustomButton } from '../../common/customButton/CustomButton';
import { ButtonVariant, TypographyVariant } from '../../../theme/muiTypes';
import { StyledDynamicField, StyledDynamicFieldContainer } from './StyledDynamicField';
import { CallbackFieldName, ControllerState, DynamicFieldVariant } from '../../../callbacks/types';

const { ADD_NEW } = GLOBAL_TEXTS;

interface CustomDynamicFieldProps {
  name: CallbackFieldName;
  label?: string;
  fieldKey: FieldKey;
  className?: string;
  dynamicFieldVariant: DynamicFieldVariant;
  labelVariant?: TypographyVariant;
  Icon?: ElementType;
  onRemove: (fieldValueList: DynamicField[]) => void;
  onInputBlur: (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    controllerState: ControllerState<FieldValues>
  ) => void;
}

export const CustomDynamicField = forwardRef<HTMLInputElement, CustomDynamicFieldProps>(
  (
    {
      name,
      label,
      fieldKey,
      className,
      dynamicFieldVariant,
      labelVariant = TypographyVariant.boldCaption1,
      onRemove,
      onInputBlur,
    },
    ref
  ) => {
    const { control } = useFormContext();

    const { fields, append, remove, replace } = useFieldArray({
      name,
      control,
    });

    const handleAddItem = () => {
      append({ [fieldKey]: '' });
    };

    const handleRemoveItem = (index: number, fieldId: string) => {
      const fieldValueList = fields.filter((field) => field.id !== fieldId);
      remove(index);
      onRemove(fieldValueList);
    };

    const handleInputBlur = (
      event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
      controllerState: ControllerState<FieldValues>,
      index: number
    ) => {
      let updatedValues: DynamicField[] = [...fields];
      const newValue = event.target.value.trim();

      if (newValue) {
        updatedValues = fields.map((field) => (field[fieldKey] ? field : { ...field, [fieldKey]: newValue }));
      } else {
        remove(index);
      }
      updatedValues = updatedValues.filter((field) => field[fieldKey]);
      replace(updatedValues); // updating the dynamic field values
      onInputBlur(event, controllerState);
    };

    return (
      <StyledDynamicField className={className}>
        <Typography variant={labelVariant}>{label}</Typography>
        <StyledDynamicFieldContainer className={className}>
          {fields.map((field, index) => (
            <Controller
              key={field.id}
              control={control}
              name={`${name}.${index}.${fieldKey}` as CallbackFieldName}
              render={(controllerState) => {
                const {
                  field: { onBlur, ...rest },
                  fieldState: { invalid: error },
                } = controllerState;
                const props = { ...rest, error };
                return (
                  <DynamicInput
                    field={field}
                    index={index}
                    fieldKey={fieldKey}
                    className={className}
                    dynamicFieldVariant={dynamicFieldVariant}
                    onRemove={handleRemoveItem}
                    onBlur={(event) => handleInputBlur(event, controllerState, index)}
                    {...props}
                  />
                );
              }}
            />
          ))}
          <CustomButton name={`${name}_button`} chipButton variant={ButtonVariant.text} onClick={handleAddItem}>
            {ADD_NEW}
          </CustomButton>
        </StyledDynamicFieldContainer>
      </StyledDynamicField>
    );
  }
);
