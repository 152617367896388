import { KeyedMutator } from 'swr';

import { GroupResource, UserResource } from '../../api/types';

interface Groups {
  data: GroupResource[] | undefined;
  error: any;
  loading: boolean;
  mutate: KeyedMutator<{ data: GroupResource[] }>;
}

export const getAgentGroupNumber = (groups: Groups, user: UserResource | undefined) => {
  if (user && user.domain && user.domain.config && groups && groups.data) {
    const selfManagedGroups =
      groups.data.filter((group) => user.domain.config.selfManagedGroups.includes(group.id)) || [];

    const agent_group_number = selfManagedGroups && selfManagedGroups[0] && selfManagedGroups[0].number;
    return agent_group_number;
  }

  return undefined;
};
