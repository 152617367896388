import { FC, cloneElement, ChangeEvent } from "react";
import { FieldConfig, useField } from "formik";

import styles from "./field.module.scss";

export interface FieldProps {
  description?: string;
  label?: string;
  required?: boolean;
  onChange?: (e: ChangeEvent<any>) => void;
  onBlur?: (e: ChangeEvent<any>) => void;
}

export const Field: FC<FieldProps & FieldConfig> = ({
  children,
  description,
  label,
  onChange,
  required = false,
  ...props
}) => {
  const [field, meta] = useField(props);

  const handleChange = (e: ChangeEvent<any>) => {
    if (onChange) onChange(e);
    field.onChange(e);
  };

  const handleBlur = (e: ChangeEvent<any>) => {
    if (props.onBlur) props.onBlur(e);
    field.onBlur(e);
  };

  const control = cloneElement(children as React.ReactElement<any>, {
    ...field,
    onChange: handleChange,
    onBlur: handleBlur,
    className: styles.control,
  });

  if (label) {
    return (
      <label className={styles.root}>
        <span className={styles.label}>{label}</span>
        {description && <span className={styles.description}>{description}</span>}
        {control}
        {meta.error && meta.touched && <span className={styles.error}>{meta.error}</span>}
      </label>
    );
  }

  return (
    <>
      {control}
      {meta.error && meta.touched && <span className={styles.error}>{meta.error}</span>}
    </>
  );
};
