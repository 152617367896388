import React from 'react';
import { Icon, IconProps } from './icon';

export const WarningIcon = ({ ...props }: IconProps) => (
  <Icon
    viewBox="0 0 96 96"
    fill="currentColor"
    {...props}
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill="currentColor">
        <path d="M48,12 C67.882251,12 84,28.117749 84,48 C84,67.882251 67.882251,84 48,84 C28.117749,84 12,67.882251 12,48 C12,28.117749 28.117749,12 48,12 Z M45,28 L51,28 L51,56 L45,56 L45,28 Z M45,62 L51,62 L51,68 L45,68 L45,62 Z"></path>
      </g>
    </g>
  </Icon>
);


