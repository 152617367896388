import { API } from "aws-amplify";
import useSWR from "swr";

export function useGroupedCalls(filters?: { [key: string]: string }) {

  const queryString = Object.entries(filters || {}).map(([key, value]) => `${key}=${value}`).join('&');
  const shouldFetch = filters?.from && filters?.to;

  const { data, error, isLoading, isValidating } = useSWR(shouldFetch ? `/calls_grouped?${queryString}` : null, callsFetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });


  return {
    groupedCalls: data,
    error,
    isLoading: isLoading || !shouldFetch,
    isValidating
  }
}

const callsFetcher = async (path: string) => {
  const data = await API.get(process.env.REACT_APP_CALLS_HISTORY_API_NAME!, path, {});
  return data as GroupedCalls[];
}

export interface GroupedCalls {
  groupKey: string | number;
  inboundCount: number;
  inboundAnsweredCount: number;
  inboundUnansweredCount: number;
  callbackAttemptsCount: number;
  outboundCount: number;
  outboundAnsweredCount: number;
  outboundUnansweredCount: number;
  avgTta: number;
  avgDuration: number;
  answerRate: number;
  sla: number;
}