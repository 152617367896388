import { MarkerType, Position } from 'reactflow';
import { CustomEdge } from '../components/custom-edge';
import { CustomNode } from '../components/custom-node';
import { FileField, FileFormat, FileType, InputFileMode } from '../../globalTypes/types';

export const connectionLineStyle = { stroke: '#9AD4F1', strokeWidth: 4 };
export const edgeMarkerEnd = { type: MarkerType.Arrow, strokeWidth: 1.2, color: '#9AD4F1' };

export const nodeTypes = {
  customNode: CustomNode,
};

export const edgeTypes = {
  customEdge: CustomEdge,
};

export const ZOOM_FACTOR = 0.8;
export const CONNECTION_RADIUS = 50;
export const ROOT_NODE_TEXT_LIMIT = 1007;

export const ROOT_NODE = {
  /* Initial root node */ id: '00000000-0000-0000-0000-00000000abba',
  type: 'customNode',
  data: {},
  position: { x: 0, y: 0 },
  targetPosition: Position.Left,
};

export const WHATSAPP_IMAGE_FORMATS = ['png', 'jpg', 'gif', 'jpeg'];
export const WHATSAPP_AUDIO_FORMATS = ['mp3', 'ogg', 'amr', 'aac'];
export const WHATSAPP_VIDEO_FORMATS = ['mp4', 'avi', '3gpp'];
export const WHATSAPP_DOCUMENT_FORMATS = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'ppts', 'zip', 'txt', 'rar'];

export const WHATSAPP_FILES_FORMAT: FileFormat[] = [
  'png',
  'jpg',
  'gif',
  'jpeg',
  'mp3',
  'ogg',
  'amr',
  'aac',
  'mp4',
  'avi',
  '3gpp',
  'pdf',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'ppts',
  'zip',
  'txt',
  'rar',
];

export const DEFAULT_FILE_FIELD: FileField = {
  file: null,
  file_name: '',
  file_url: '',
  file_type: FileType.application,
  file_mode: InputFileMode.ADD,
  file_format: null,
  supported_formats: WHATSAPP_FILES_FORMAT,
};
