import React, { FC, cloneElement, ChangeEvent } from 'react';
import { FieldConfig, useField } from 'formik';

import styles from './field.module.scss';

export interface FieldProps {
  description?: string;
  label?: string;
  required?: boolean;
  onChange?: (e: ChangeEvent<any>) => void;
}

export const FileUploadField: FC<FieldProps & FieldConfig> = ({ children, description, label, onChange, required = false, ...props }) => {
  const [field, meta, helper] = useField(props.name);
  const handleChange = (event: ChangeEvent<any>) => {
    if(onChange) onChange(event);
    const value = event.currentTarget.files[0];
    helper.setValue(value);
  }

  // value is undefined because file input must be uncontrolled
  // this is a security constraint to only allow the user to upload files
  // https://reactjs.org/docs/uncontrolled-components.html#the-file-input-tag
  const control = cloneElement(
    children as React.ReactElement<any>,
    { ...field, onChange: handleChange, className: styles.control, value: undefined, }
  );
  

  if (label) {
    return (
      <label
        className={styles.root}
      >
        <span className={styles.label}>{label}</span>
        {description && <span className={styles.description}>{description}</span>}
        {control}
        {meta.error && meta.touched && <span className={styles.error}>{meta.error}</span>}
      </label>
    )
  }
  return control;
}