import { API } from "aws-amplify";
import { Field, FieldProps } from "formik";
import { useContext, useState } from "react";
import { BusinessNumberResource } from "../api/types";
import { secureFetch } from "../auth/server-auth";
import { Dialog } from "../design/dialog/dialog";
import { Select } from "../design/form/select";
import { activeBotContext } from "./bot-list/bot-context";
import { WhatsAppBot } from "./utils/types";

const DIALOG_LABEL = 'קישור מספר עסקי לבוט';
const SUBMIT_LABEL = 'עדכון';

export const EditNumberDialog = ({ dialog, numbers, mutate, domainUuid }: EditNumberDialogProps) => {
  const [activeBot, setActiveBot] = useContext(activeBotContext);
  const [loading, setLoading] = useState(false);

  const setWebhook = async (botNumber?: string | undefined) => {
    const setWebhookBody = {
      botPhone: botNumber
    }
    const serverUrl = process.env.REACT_APP_WHATSAPP_BOT_SERVER_URL;

    await secureFetch(
      `${serverUrl}/dom/${domainUuid}/webhook`,
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(setWebhookBody)
        }
    );
  }

  const updateIvr = async (botNumber?: string | undefined) => {
    const body = {
      ...activeBot,
      business_number: botNumber || undefined
    };
    
    await API.put(process.env.REACT_APP_WHATSAPP_BOT_API_NAME!, '/update-ivr', { body });
    await mutate();
  }

  const handleSubmit = async (formValues: { botNumber: string }) => {
    setLoading(true);
    
    try {
      await updateIvr(formValues.botNumber);

      await setWebhook(formValues.botNumber);
    }
    catch (err) {
      console.error(err);
    }
    finally {
      dialog.hide();
      setLoading(false);
    }
  }
  
  return (
    <Dialog {...dialog}
      variant="form"
      header={DIALOG_LABEL}
      onSubmit={handleSubmit}
      submitLabel={SUBMIT_LABEL}
      loading={loading}
      aria-label={DIALOG_LABEL}>
      <Field name="botNumber" { ...dialog }>
        {({ field }: FieldProps) => (
          <Select {...field}>
            {
              activeBot?.business_number && <option value={undefined}></option>
            }
            <option value={activeBot?.business_number}>{activeBot?.business_number}</option>
            {
              numbers
              ?.map(number => (
                <option key={number.id} value={number.number}>{number.number}</option>
              ))
            }
          </Select>
        )}
      </Field>
    </Dialog >
  )
}


interface EditNumberDialogProps {
  dialog: any
  mutate: () => Promise<WhatsAppBot[] | undefined>
  numbers: BusinessNumberResource[] | undefined;
  domainUuid?: string | undefined;
}