import { API } from "aws-amplify";
import { DialerAttempt, DialerRequest } from "../api/dialers";
import useSWR from 'swr';

const SWR_CONFIG = { revalidateOnFocus: false, revalidateOnReconnect: false };

export const useDialerRequests = (eventId: number) => {
  const endpoint = `/dialer-request?event_id=${eventId}`;
  const { data, error, mutate, isLoading } = useSWR(eventId ? endpoint : null, fetcher, SWR_CONFIG);

  return {
    data: isLoading ? undefined : data?.data as DialerRequest[],
    error: error,
    loading: isLoading,
    mutate: mutate
  }
}

export const useDialerRequest = (requestUuid: string) => {
  const endpoint = `/dialer-request?request_uuid=${requestUuid}`;
  const { data, error, mutate, isLoading } = useSWR(requestUuid ? endpoint : null, fetcher, SWR_CONFIG);

  return {
    data: isLoading ? undefined : data?.data as DialerRequest,
    error: error,
    loading: isLoading,
    mutate: mutate
  }
}

export const useDialerAttempts = (requestUuid?: string | undefined) => {
  const endpoint = `/dialer-attempts?request_uuid=${requestUuid}`;
  const { data, error, mutate, isLoading } = useSWR(requestUuid ? endpoint : null, fetcher, SWR_CONFIG);

  return {
    data: isLoading ? undefined : data?.data as DialerAttempt[],
    error: error,
    loading: isLoading,
    mutate: mutate
  }
}

async function fetcher(url: string) {
  const res = await API.get(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, url, {});
  return res;
}