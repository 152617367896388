import { DialogStateReturn } from "reakit";
import { Dialog } from "../../../design/dialog/dialog";
import styles from "./dialogs.module.scss";
import { ErrorDialog } from "../../../design/dialog/error-dialog/error-dialog";

const DIALOG_LABEL_SINGLE = "מחיקת מפעיל";
const DIALOG_LABEL_MULTIPLE = "מחיקת מפעילים";
const CONFIRM_LABEL = "מחיקה";
const CANCEL_LABEL = "ביטול";

export const LaunchersDeleteDialog = ({
  dialog,
  launcherNames,
  handleDelete,
  onClose,
  loading,
  error,
  setError,
}: LaunchersDeleteDialogProps) => {
  const dialogHeader = launcherNames?.length === 1 ? DIALOG_LABEL_SINGLE : DIALOG_LABEL_MULTIPLE;
  if (error) {
    return <ErrorDialog dialog={dialog} onClose={() => setError(false)} />;
  }

  return (
    <Dialog
      {...dialog}
      variant='caution'
      header={dialogHeader}
      loading={loading}
      onSubmit={handleDelete}
      submitLabel={CONFIRM_LABEL}
      cancelLabel={CANCEL_LABEL}
      hideOnClickOutside={false}
      onClose={onClose}
      aria-label={dialogHeader}
    >
      האם ברצונך למחוק את
      {launcherNames.length === 1 ? (
        <span>
          &nbsp;
          <span className={styles.strong}>{launcherNames[0]}</span> מרשימת המפעילים ?
        </span>
      ) : (
        " המפעילים הבאים:"
      )}
      <br />
      {launcherNames.length > 1 ? (
        <div className={styles.strong}>{launcherNames.join(", ")}</div>
      ) : undefined}
      {launcherNames.length > 1 ? <div>מרשימת המפעילים?</div> : undefined}
    </Dialog>
  );
};

interface LaunchersDeleteDialogProps {
  dialog: DialogStateReturn;
  launcherNames: (string | undefined)[];
  handleDelete: () => void;
  onClose: () => void;
  loading: boolean;
  error: boolean;
  setError: (error: boolean) => void;
}
