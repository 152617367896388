import { DialogStateReturn } from "reakit";
import { Launcher } from "../../../api/dialers";
import { Dialog } from "../../../design/dialog/dialog";
import { Field } from "../../../design/form/field";
import { AutoSuggestInput } from "../../../design/form/input/auto-suggest-input";
import { Input } from "../../../design/form/input/input";
import { useContext, useState } from "react";
import { HorizontalPartition } from "../../../design/partition/horizontal-partition";
import { API } from "aws-amplify";
import styles from "./dialogs.module.scss";
import { LauncherContext } from "../../context/launcher-context";
import { Checkbox } from "../../../design/checkbox/checkbox";
import { useDialGroups } from "../../use-dial-groups";
import Autosuggest, { ChangeEvent } from "react-autosuggest";
import { useMe } from "../../../api/use-me";
import { User, useDomains } from "../../../api/use-users";
import { Canceled20 } from "../../../design/icons";
import { ErrorDialog } from "../../../design/dialog/error-dialog/error-dialog";
import * as yup from "yup";
import { ISRAELI_PHONE_REGEXP } from "../../../utils/strings/string-translator";

const SUBMIT_LABEL = "שמירת מפעיל";
const CANCEL_LABEL = "ביטול";
const FAILED_TO_SAVE_MESSAGE = "לא הצלחנו לשמור את השינויים מסיבה לא ידועה.";
const BAD_REQUEST_MAIN_MESSAGE = "אחד (או יותר) מהשדות לא תקינים.";

const launcherValidationSchema: yup.Schema = yup.object().shape({
  phone_number: yup
    .string()
    .matches(ISRAELI_PHONE_REGEXP, "מספר הטלפון לא תקין")
    .required("טלפון עיקרי הוא שדה חובה"),
});

export const EditLauncherDialog = ({ dialog, launchersMutate }: EditLauncherDialogProps) => {
  const { data: dialGroups } = useDialGroups();
  const [selectedLauncher, setSelectedLauncher] = useContext(LauncherContext);
  const id = selectedLauncher?.launcher_uuid;
  const DIALOG_LABEL = id ? "עריכת מפעיל" : "הוספת מפעיל";
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<number>();
  const activeUser = useMe();
  const [searchValue, setSearchValue] = useState<string>("");
  const domains = useDomains(activeUser.data?.domain.id);
  const users = domains.domains?.[0]?.users?.filter(
    (user) => user?.user_name.includes(searchValue) || user?.user_email.includes(searchValue)
  );
  const [selectedHadshanutUser, setSelectedHadshanutUser] =
    useState<Omit<User, "domains"> | undefined>();

  const suggestions = users?.sort((userA: any, userB: any) => {
    return userA.user_name >= userB.user_name ? 1 : -1;
  });

  const handleSetUserAsLauncher = (
    e: React.FormEvent<HTMLElement>,
    autosuggestParams: ChangeEvent
  ) => {
    if (autosuggestParams.method !== "click" && autosuggestParams.method !== "enter") {
      return;
    }
    const user = users?.find((user: any) => user.user_email === autosuggestParams.newValue);

    if (user) {
      setSelectedHadshanutUser(user);
      if (selectedLauncher) {
        setSelectedLauncher({ ...selectedLauncher, user_uuid: user.user_uuid });
      }
    }
  };

  const autosuggestInputProps = {
    onChange: handleSetUserAsLauncher,
    placeholder: "כתובת מייל או שם",
    value: searchValue,
  };

  const handleClose = () => {
    launchersMutate();
    setSelectedHadshanutUser(undefined);
    setSelectedLauncher(undefined);
    setError(undefined);
    dialog.hide();
  };

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      const launcher: Launcher = {
        launcher_uuid: id || "",
        domain_uuid: "",
        user_uuid: selectedHadshanutUser?.user_uuid || undefined,
        // is_global: values.is_global || false,
        is_global: false,
        phone_number: values.phone_number,
        full_name: values.full_name,
        dial_groups: values.dial_groups?.map((dg: string) => parseInt(dg)) || [],
      };
      if (!selectedLauncher) {
        await API.post(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, "/launcher", {
          body: launcher,
        });
      } else {
        delete launcher["create_date"]; // Field is not allowed
        delete launcher["update_date"]; // Field is not allowed

        await API.put(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, `/launcher?uuid=${id}`, {
          body: launcher,
        });
      }
      launchersMutate();
      handleClose();
    } catch (err: any) {
      setError(err.response?.status ?? 500);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveUser = () => {
    if (selectedLauncher) {
      setSelectedLauncher({ ...selectedLauncher, user_uuid: undefined });
    }
    setSelectedHadshanutUser(undefined);
  };

  if (error) {
    const mainMessage = error === 400 ? BAD_REQUEST_MAIN_MESSAGE : FAILED_TO_SAVE_MESSAGE;
    return <ErrorDialog dialog={dialog} mainMessage={mainMessage} onClose={handleClose} />;
  }

  return (
    <Dialog
      {...dialog}
      variant='form'
      header={DIALOG_LABEL}
      onSubmit={handleSubmit}
      initialValues={
        selectedLauncher
          ? {
              ...selectedLauncher,
              dial_groups: selectedLauncher?.dial_groups?.map((dg) => String(dg)),
            }
          : emptyLauncher
      }
      validationSchema={launcherValidationSchema}
      submitLabel={SUBMIT_LABEL}
      cancelLabel={CANCEL_LABEL}
      hideOnClickOutside={false}
      loading={loading}
      onClose={handleClose}
      aria-label={DIALOG_LABEL}
    >
      <section className={styles.editLauncherDialogBody}>
        <div className={styles.launcherFields}>
          <div className={styles.formField}>
            <label>תיאור מפעיל</label>
            <Field name='full_name'>
              <Input name='full_name' />
            </Field>
          </div>

          <div className={styles.formField}>
            <label>מספר טלפון</label>
            <Field name='phone_number'>
              <Input name='phone_number' />
            </Field>
          </div>

          <div className={styles.formField}>
            <label>אימייל של משתמש קיים</label>
            <div>
              <Autosuggest
                id='users'
                suggestions={searchValue.length > 0 && suggestions ? suggestions : []}
                renderSuggestion={UserSuggestion}
                getSuggestionValue={(user) => user.user_email}
                onSuggestionsFetchRequested={({ value }) => setSearchValue(value)}
                onSuggestionsClearRequested={() => setSearchValue("")}
                renderInputComponent={AutoSuggestInput}
                inputProps={autosuggestInputProps}
                theme={styles}
              />
              {selectedHadshanutUser || selectedLauncher?.user_uuid ? (
                <span className={styles.selectedUser}>
                  <Canceled20 onClick={handleRemoveUser} className={styles.removeUserBtn} />
                  {selectedHadshanutUser?.user_name ||
                    users?.find((user) => user.user_uuid === selectedLauncher?.user_uuid)
                      ?.user_name}
                </span>
              ) : undefined}
            </div>
          </div>

          {/* <div className={styles.formField}>
            <label>גלובאלי</label>
            <Field
              name='is_global'
              type='checkbox'
            >
              <Checkbox name='is_global' />
            </Field>
          </div> */}
        </div>

        <HorizontalPartition width={100} />

        <div className={styles.dialGroupsSelect} style={{ width: "85%", marginBlockEnd: "32px" }}>
          <label>חייגנים להפעלה</label>
          {dialGroups?.map((group) => (
            <Field
              key={group.dialer_id}
              type='checkbox'
              name='dial_groups'
              value={`${group.dialer_id}`}
            >
              <Checkbox title={group.name} />
            </Field>
          ))}
        </div>
      </section>
    </Dialog>
  );
};

const emptyLauncher: Launcher = {
  launcher_uuid: "",
  domain_uuid: "",
  full_name: "",
  phone_number: "",
  is_global: false,
  dial_groups: [],
};

interface EditLauncherDialogProps {
  launchersMutate: () => void;
  dialog: DialogStateReturn;
}

const UserSuggestion = (user: { user_name: string; user_email: string }) => (
  <div className={styles.suggestionContent}>
    {user.user_name} {user.user_email}
  </div>
);
