import { DialogStateReturn } from "reakit";
import { Callee } from "../../../api/dialers";
import { Dialog } from "../../../design/dialog/dialog";
import { Field } from "../../../design/form/field";
import { Input } from "../../../design/form/input/input";
import { useContext, useState } from "react";
import { HorizontalPartition } from "../../../design/partition/horizontal-partition";
import { Select } from "../../../design/form/select";
import { API } from "aws-amplify";
import styles from "./dialogs.module.scss";
import { useJobTitles } from "../../use-job-titles";
import { CalleeContext } from "../../context/callee-context";
import classNames from "classnames";
import { Checkbox } from "../../../design/checkbox/checkbox";
import { DEFAULT_DIALER_ID, useDialGroups } from "../../use-dial-groups";
import { ErrorDialog } from "../../../design/dialog/error-dialog/error-dialog";
import * as yup from "yup";
import {
  ISRAELI_PHONE_REGEXP,
  isValidIsraeliIdNumber,
} from "../../../utils/strings/string-translator";
import { dialogTexts } from "../../utils/texts";
import { ActionType } from "../callees-page";

const CANCEL_LABEL = "ביטול";
const FAILED_TO_SAVE_MESSAGE = "לא הצלחנו לשמור את השינויים מסיבה לא ידועה.";
const BAD_REQUEST_MAIN_MESSAGE = "אחד (או יותר) מהשדות לא תקינים.";
const BAD_REQUEST_SECONDARY_MESSAGE = 'ודא/י ת"ז תקינה וכי מספרי הטלפון לא בשימוש קודם.';

const calleeValidationSchema: yup.Schema = yup.object().shape({
  phone_number1: yup
    .string()
    .matches(ISRAELI_PHONE_REGEXP, "מספר הטלפון לא תקין")
    .required("טלפון עיקרי הוא שדה חובה"),
  phone_number2: yup
    .string()
    .matches(ISRAELI_PHONE_REGEXP, "מספר הטלפון לא תקין")
    .nullable()
    .optional(),
  phone_number3: yup
    .string()
    .matches(ISRAELI_PHONE_REGEXP, "מספר הטלפון לא תקין")
    .nullable()
    .optional(),
  id_number: yup
    .string()
    .test("is_valid_id", 'מספר ת"ז לא תקין', isValidIsraeliIdNumber)
    .required("תעודת זהות הוא שדה חובה"),
});

export const EditCalleeDialog = ({
  dialog,
  callees,
  calleesMutate,
  action,
}: EditCalleeDialogProps) => {
  const [selectedCallee, setSelectedCallee] = useContext(CalleeContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  const { data: jobTitles } = useJobTitles();
  const { data: dialGroups } = useDialGroups();

  const { CREATE_CALLEE_TEXT, UPDATE_CALLEE_TEXT, DUPLICATED_PERSONAL_ID_ERROR_TEXT } = dialogTexts;

  const id = selectedCallee?.callee_uuid;
  const DIALOG_LABEL = id ? "עריכת כונן" : "הוספת כונן";

  const handleClose = () => {
    setSelectedCallee(undefined);
    setErrorMsg(undefined);
    dialog.hide();
  };

  const handleError = (error: number | undefined) => {
    if (error === 400) {
      setErrorMsg(BAD_REQUEST_MAIN_MESSAGE);
    } else {
      setErrorMsg(FAILED_TO_SAVE_MESSAGE);
    }
  };

  const isTeudatZehutExist = (newTeudatZehut: string) =>
    callees.some(
      (callee) => callee.callee_uuid !== id && callee.id_number.trim() === newTeudatZehut.trim()
    );

  const handleSubmit = async (values: any) => {
    const isDuplicatedTeudatZehut = isTeudatZehutExist(values.id_number);
    if (isDuplicatedTeudatZehut) {
      setErrorMsg(DUPLICATED_PERSONAL_ID_ERROR_TEXT);
      return;
    }

    try {
      setLoading(true);
      const callee: Callee = {
        callee_uuid: id || "",
        domain_uuid: "",
        phone_number1: values.phone_number1,
        phone_number2: values.phone_number2 || null,
        phone_number3: values.phone_number3 || null,
        full_name: values.full_name,
        job_title_id: values.job_title_id,
        id_number: values.id_number,
        dial_groups: values.dial_groups?.map((dg: string) => parseInt(dg)) || [],
        city: values.city,
      };
      if (!selectedCallee) {
        await API.post(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, "/callee", {
          body: callee,
        });
      } else {
        delete callee["create_date"]; // Field is not allowed
        delete callee["update_date"]; // Field is not allowed

        await API.put(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, `/callee?uuid=${id}`, {
          body: callee,
        });
      }
      calleesMutate();
      handleClose();
    } catch (err: any) {
      handleError(err.response?.status);
      console.error(err.response);
    } finally {
      setLoading(false);
    }
  };

  const submitBtnLabel = action === "addCallee" ? CREATE_CALLEE_TEXT : UPDATE_CALLEE_TEXT;

  if (errorMsg) {
    const secondaryMessage =
      errorMsg === BAD_REQUEST_MAIN_MESSAGE ? BAD_REQUEST_SECONDARY_MESSAGE : undefined;
    return (
      <ErrorDialog
        dialog={dialog}
        onClose={handleClose}
        mainMessage={errorMsg}
        contactSupport={false}
        showSecondaryMessage={false}
        secondaryMessage={secondaryMessage}
      />
    );
  }

  return (
    <Dialog
      {...dialog}
      variant='form'
      header={<Header label={DIALOG_LABEL} enabled={selectedCallee?.enabled} />}
      onSubmit={handleSubmit}
      initialValues={
        selectedCallee
          ? {
              ...selectedCallee,
              dial_groups:
                selectedCallee?.dial_groups
                  ?.filter((dg) => dg !== DEFAULT_DIALER_ID)
                  ?.map((dg) => String(dg)) || [],
            }
          : emptyCallee
      }
      validationSchema={calleeValidationSchema}
      submitLabel={submitBtnLabel}
      cancelLabel={CANCEL_LABEL}
      hideOnClickOutside={false}
      loading={loading}
      onClose={handleClose}
      aria-label={DIALOG_LABEL}
    >
      <section className={styles.editCalleeDialog}>
        <div className={styles.calleeDataFields}>
          <div className={styles.formField}>
            <label>שם כונן</label>
            <Field name='full_name'>
              <Input name='full_name' />
            </Field>
          </div>

          <div className={styles.formField}>
            <label>תעודת זהות</label>
            <Field name='id_number'>
              <Input name='id_number' />
            </Field>
          </div>

          <div className={styles.formField}>
            <label>מספר טלפון 1</label>
            <Field name='phone_number1'>
              <Input name='phone_number1' />
            </Field>
          </div>

          <div className={styles.formField}>
            <label>מספר טלפון 2</label>
            <Field name='phone_number2'>
              <Input name='phone_number2' />
            </Field>
          </div>

          <div className={styles.formField}>
            <label>מספר טלפון 3</label>
            <Field name='phone_number3'>
              <Input name='phone_number3' />
            </Field>
          </div>
        </div>

        <div className={styles.formField}>
          <label>יישוב</label>
          <Field name='city'>
            <Input name='city' />
          </Field>
        </div>

        <HorizontalPartition width={100} />

        <div className={styles.formSelectionField}>
          <label>בחירת תפקיד</label>
          <Field name='job_title_id'>
            <Select name='job_title_id' multiple={false}>
              <option value={`${selectedCallee?.job_title_id || ""}`}>
                {" "}
                {jobTitles?.find((job) => job.job_title_id === selectedCallee?.job_title_id)
                  ?.description || ""}{" "}
              </option>
              {jobTitles
                ?.filter((job) => selectedCallee?.job_title_id !== job.job_title_id)
                ?.map((option) => (
                  <option key={option?.job_title_id} value={option?.job_title_id}>
                    {" "}
                    {option?.description}{" "}
                  </option>
                ))}
            </Select>
          </Field>
        </div>

        <div className={styles.formSelectionField} style={{ marginBlockEnd: "32px" }}>
          <label>בחירת קבוצות קריאה</label>
          <div className={styles.dialGroupsSelect}>
            {dialGroups?.map((dialGroup, index) => (
              <Field
                key={dialGroup.dialer_id}
                type='checkbox'
                name={`dial_groups`}
                value={`${dialGroup.dialer_id}`}
              >
                <Checkbox title={dialGroup.name} />
              </Field>
            ))}
          </div>
        </div>
      </section>
    </Dialog>
  );
};

const emptyCallee: Callee = {
  callee_uuid: "",
  domain_uuid: "",
  full_name: "",
  job_title_id: 0,
  phone_number1: "",
  phone_number2: "",
  phone_number3: "",
  id_number: "",
  dial_groups: [],
  city: "",
};

interface EditCalleeDialogProps {
  calleesMutate: () => void;
  dialog: DialogStateReturn;
  callees: Callee[];
  action: ActionType;
}

const Header = ({ label, enabled }: { label: string; enabled: boolean | undefined }) => {
  return (
    <div className={styles.header}>
      <span>{label}</span>
      {enabled !== undefined ? <Badge enabled={enabled} /> : undefined}
    </div>
  );
};

const Badge = ({ enabled }: { enabled: boolean | undefined }) => {
  const label = enabled ? "פעיל" : "לא פעיל";
  return <span className={classNames(styles.badge, { [styles.inactive]: !enabled })}>{label}</span>;
};
