import { AgentAttributes, BreakType, StatusType } from "../interfaces";
import { STATUSES_FOR_PIE_CHART, getStatus } from "../monitor";

export function getAgentsStatusSums(agents: AgentAttributes[], breakTypes: BreakType[]) {
  return STATUSES_FOR_PIE_CHART.map(status => ({
    status,
    value: agents.reduce((acc, agent) => {
      const breakType = breakTypes?.find(bt => bt.code === agent.breakType);
      let agentStatus = getStatus(agent.agentStatus, agent.agentState, agent.agentOutboundState, breakType);
      switch (agentStatus) {  // we decided that outbound-talking and inbound-ringing appear as busy ins the agent chart and agent strip
        case StatusType.BusyOutbound: agentStatus = StatusType.Busy; break;
        case StatusType.Receiving   : agentStatus = StatusType.Busy; break;
      }
      return agentStatus === status.id ? acc + 1 : acc;
    }, 0)
  }));
}