import styles from './extensions-list.module.scss';
import { useExtensions } from '../../api/use-extensions';
import { Spinner } from '../../design/spinner/spinner';
import { useMe } from '../../api/use-me';
import { DisabledForwardDialog } from '../extensions-dialog/disabled-forward-dialog';
import { ExtensionsForwardDialog } from '../extensions-dialog/extensions-forward-dialog';
import { ExtensionsCloseDialog } from '../extensions-dialog/extensions-close-dialog';
import { ExtensionsNameDialog } from '../extensions-dialog/extensions-name-dialog';
import { ExtensionResource } from '../../api/types';
import { useDialogState } from "reakit";
import { useContext } from "react";
import { activeExtensionContext } from '../active-extensions-context';
import { Table } from '../../design/table/table';
import { ButtonWithDropdown } from '../../design/button-with-dropdown/button-with-dropdown';
import { ChevronDown } from '../../design/icons/chevron-down';
import { ChevronUp } from '../../design/icons/chevron-up';
import { ExtensionMenu } from './extension-menu/extension-menu';
import { useMediaQuery } from '../../layouts/app-layout/app-layout';
import { mobileQuery } from '../../theme/media';
import ExtensionsListItem from './extensions-list-item';
import { safeParsePhoneNumber } from '../../utils/strings/parse-phone';
import { Column } from '../../design/table/types';

export default function ExtensionsList() {
  const user = useMe();
  const { data, loading } = useExtensions(true);
  const extensionsList = data?.filter(extension => user.data?.domain.config.selfManagedExtensions.includes(extension.id)) || [];
  const isMobile = useMediaQuery(mobileQuery);
  const disabledForwardDialog = useDialogState();
  const callForwardDialog = useDialogState();
  const closedModeDialog = useDialogState();
  const nameEditDialog = useDialogState();

  const [, setActiveExtension] = useContext(activeExtensionContext)

  const handleSimForward = (number: any) => {
    const knownPrefixes = [
      '22361',
      '22362',
      '11361',
      '11362'
    ];

    if (number?.length >= 15) {
      const prefix = number.substring(0, 5);
      for (let i = 0; i < knownPrefixes.length; i++) {
        if (prefix === knownPrefixes[i]) {
          return true;
        }
      }
    }
    return false;
  }

  const handleSimNumber = (number: any) => {
    const result = handleSimForward(number);
    if (result) {
      const parsedNumber = number ? formatPhoneNumber(number.substring(5, number.length)) : '';

      return {
        number: parsedNumber,
        edit: false
      };
    }
    return {
      number: formatPhoneNumber(number),
      edit: true
    };
  }

  const formatPhoneNumber = (number: string) => {
    return number ? safeParsePhoneNumber(number, 'IL') : '';
  }

  const displayDetails = (extension: ExtensionResource) => {
    if (extension.forwardAllEnabled) {
      const parseNumber = handleSimNumber(extension.forwardAllDestination);
      return (
        <span className={styles.forwardNumber}>
          {
            extension.forwardAllEnabled ?
              (`שיחות מועברות ל-${parseNumber.number}`)
              :
              ''
          }
        </span>
      );
    }
  }

  const dialogs = [
    <DisabledForwardDialog dialog={disabledForwardDialog} />,
    <ExtensionsForwardDialog dialog={callForwardDialog} />,
    <ExtensionsCloseDialog dialog={closedModeDialog} />,
    <ExtensionsNameDialog dialog={nameEditDialog} />
  ];

  const handleCallForwardClick = (extension: ExtensionResource) => {
    setActiveExtension(extension);
    callForwardDialog.show();
  }

  const handleCancelForward = (extension: ExtensionResource) => {
    setActiveExtension(extension);
    closedModeDialog.show()
  }

  const handleNameEditClick = (extension: ExtensionResource) => {
    setActiveExtension(extension);
    nameEditDialog.show();
  }

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spinner />
      </div>
    )
  }

  if (!extensionsList || extensionsList.length === 0) {
    return (
      <div>
        <div className={styles.content}>
          בעמוד הזה את/ה יכול/ה לנהל את השלוחות של העסק שלך, למשל:
          <ul>
            <li>עריכת שם לשלוחה בעסק</li>
            <li>העברת כל השיחות הנכנסות למספר אחר</li>
          </ul>
        </div>
        <p className={styles.content}>
          כדי להפעיל את היכולת לנהל בעצמך את השלוחות שלך, פנה/י לתמיכה: <a href="mailto:support@ip-com.co.il">support@ip-com.co.il</a>
        </p>
      </div>
    )
  }

  const tableData = extensionsList.map(extension => {
    return {
      id: extension.id,
      number: extension.destinationNumber,
      name: extension.description,
      forward: displayDetails(extension),
      menu: (
        <ButtonWithDropdown
          label="פעולות"
          collapsedBadge={<ChevronDown size={24} />}
          expandedBadge={<ChevronUp size={24} />}
          buttonClassName={styles.button}
        >
          <ExtensionMenu
            isForwarded={extension.forwardAllEnabled}
            onForwardClick={() => handleCallForwardClick(extension)}
            onCancelForwardClick={() => handleCancelForward(extension)}
            onEditNameClick={() => handleNameEditClick(extension)}
          />
        </ButtonWithDropdown>
      )
    }
  })

  const columns: Array<Column<typeof tableData[0], keyof typeof tableData[0]>> = [
    {
      key: "number",
      className: styles.number
    },
    {
      key: "name",
      className: styles.name

    },
    {
      key: "forward",
      className: styles.forwardNumberContainer
    },
    {
      key: "menu",
    }
  ]

  return isMobile ? (
    <>
    {
      extensionsList.map(extension => (
        <ExtensionsListItem extension={extension}/>
      ))
    }
    </>
  )
    : (
      <>
      <Table showHeaders = { false } sort = { false } data = { tableData } columns = { columns } />
  { dialogs.map((dialog, index) => <div key={index}>{dialog}</div>) }
    </>
  )
}
