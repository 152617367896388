import { FC } from 'react';

import { StyledCustomDivider } from './StyledCustomDivider';

interface CustomDividerProps {
  hidden: boolean;
  className?: string;
}

export const CustomDivider: FC<CustomDividerProps> = ({ hidden, ...rest }) => {
  return <StyledCustomDivider hidden={hidden} {...rest} />;
};
