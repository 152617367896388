import { ComponentType } from '../../formConfig/types';
import { CallbackFieldConfig, CallbackFieldName } from '../types';

export const getFieldNames = (fieldsValue: CallbackFieldConfig[]): CallbackFieldName[] =>
  fieldsValue
    .filter(
      (fieldValue) =>
        fieldValue.componentType === ComponentType.FileInput || fieldValue.componentType === ComponentType.DynamicField
    )
    .map((fileValue) => fileValue.name);
