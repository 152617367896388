const mobile = '478px';
const tablet = '991px';
const desktop = '1279px';
const wideDesktop = '1440px';

const getDeviceQuery  = (deviceWidth : string) => `(max-width: ${deviceWidth})`

export const mobileQuery = getDeviceQuery(mobile);
export const tabletQuery = getDeviceQuery(tablet);
export const desktopQuery = getDeviceQuery(desktop);
export const wideDesktopQuery = getDeviceQuery(wideDesktop);