import React from 'react';
import { Icon, IconProps } from './icon';

export const ArrowRight = ({...props}: IconProps) => (
  <Icon 
    viewBox="0 0 96 96"
    fill="currentColor"
    {...props}
  >
    <polygon fillRule="evenodd" points="66.121 25.879 61.879 30.121 78 45 10 45 10 51 78 51 61.879 65.879 66.121 70.121 88.243 48"/>
  </Icon>
);




