import { API } from "aws-amplify";
import useSWR from "swr";

export function useInvitationsApi<Data=any,Error=any>(path: string|null, params?: any) {
  const resource = useSWR<Data,Error>(path ? [path, params] : null, fetcher);
  const loading = path && !resource.data && !resource.error;

  return {
    ...resource,
    loading
  }
}

async function fetcher(key: string|string[], params: any = {}) {
  const path = Array.isArray(key)
    ? `/${key.join('/')}`
    : `/${key}`;
  return API.get('invitations', path, params);
}