import { useMemo } from 'react';
import classNames from 'classnames';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import Menu from './Menu';
import theme from '../../theme/muiTheme';
import { ICallbackDialerForm } from '../types';
import { hadshanutUserState } from '../../state';
import { PermissionLevel } from '../../api/types';
import { GLOBAL_TEXTS } from '../../utils/globalTexts';
import { useFormScroll } from '../hooks/useFormScroll';
import { Spinner } from '../../design/spinner/spinner';
import { useURLParams } from '../../app/use-url-params';
import styles from './CallbackDialerSettings.module.scss';
import { CallbackDialerForm } from './CallbackDialerForm';
import { useCallbackDialer } from '../hooks/useCallbackDialer';
import { getUserPermissionLevel } from '../utils/getUserPermissions';
import { FORM_SECTIONS } from '../formConfig/callbackDialerFormConfig';
import { useCallbackFieldsConfig } from '../hooks/useCallbackFieldsConfig';
import { processCallbackDataToForm } from '../utils/processCallbackDataToForm';
import { AppLayout, AppLayoutContainer } from '../../layouts/app-layout/app-layout';
import { CustomInfoField } from '../../components/common/customInfoField/CustomInfoField';

const { GLOBAL_ERROR } = GLOBAL_TEXTS;

export const CallbackDialerSettings = () => {
  const params = useParams<any>()['*']?.replace(/^\/|\/$/g, '');
  const currentUrl = useURLParams({ path: '/callback-dialer/dialers', params });
  const dialerId = parseInt(currentUrl.urlParams?.['dialers']);
  const userData = useRecoilValue(hadshanutUserState);
  const updatedFormFieldsConfig = useCallbackFieldsConfig();
  const { dialerData, apiConfig, error } = useCallbackDialer(dialerId);
  const { memoSelectedSection, handleScroll } = useFormScroll(FORM_SECTIONS);
  const userPermissionLevel: PermissionLevel | undefined = getUserPermissionLevel(userData);

  const dialerName = dialerData?.name;
  const selectedSectionId = memoSelectedSection.id;
  const hasBusinessNumberOptions = updatedFormFieldsConfig.business_number.options.length > 0;

  const dialerFormValues = useMemo(
    () => processCallbackDataToForm(dialerData, userPermissionLevel),
    [dialerData, userPermissionLevel]
  );

  if (!dialerFormValues || !hasBusinessNumberOptions) {
    return (
      <AppLayout>
        <AppLayoutContainer>
          {error ? <CustomInfoField label={GLOBAL_ERROR} value='' /> : <Spinner />}
        </AppLayoutContainer>
      </AppLayout>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <AppLayout
        sectionClassName={classNames(styles.resetSection, styles.resetSectionContent, styles.sectionClassName)}
        isFullScreen
      >
        <Menu selectedSectionId={selectedSectionId} dialerId={dialerId} dialerName={dialerName} />
        <CallbackDialerForm
          apiConfig={apiConfig}
          dialerFormValues={dialerFormValues as ICallbackDialerForm}
          handleScroll={handleScroll}
        />
      </AppLayout>
    </ThemeProvider>
  );
};
