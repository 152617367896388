import { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { EditContext } from './edit-context';
import { AttemptCardContext } from './attempt-card-context';
import { DialerRequest } from './types';
import { RequestInfoDialog } from './calls-log/call-list-dialog/request-info';
import { CallbacksRouter } from './calls-log';
import { useMe } from '../api/use-me';
import { CallbackDialerSettings } from './callbackDialerSettings/CallbackDialerSettings';
import CallbackDialersPage from './callbackDialersManagement/CallbackDialersPage';
import { PermissionGroup } from '../api/types';

export const allowedGroups = [PermissionGroup.Support];

export const Callbacks = () => {
  const user = useMe();
  const dataState = useState<any>();
  const cardDetails = useState<DialerRequest>();
  const authorized = allowedGroups.some((group) => user?.data?.groups.includes(group));

  if (!authorized && !user.loading) {
    return <Navigate to='/' />;
  }

  return (
    <>
      <EditContext.Provider value={dataState}>
        <AttemptCardContext.Provider value={cardDetails}>
          <Routes>
            <Route Component={CallbackDialersPage} path='/' />
            <Route Component={CallbackDialersPage} path='/dialers/*' />
            <Route Component={CallbackDialerSettings} path='/dialers/:dialerId' />
            <Route Component={CallbacksRouter} path='/history/*' />
            <Route Component={RequestInfoDialog} path='/call/:attempt/*' />
          </Routes>
        </AttemptCardContext.Provider>
      </EditContext.Provider>
    </>
  );
};
