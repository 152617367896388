import { Typography, styled } from '@mui/material';
import { InsertDriveFileOutlined as FilePreview } from '@mui/icons-material';

export const StyledFieldWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  rowGap: theme.spacing(2.5),
}));

export const StyledUploadFileContainer = styled('div')(({ theme }) => ({
  maxWidth: '655px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  padding: `${theme.spacing(6)} ${theme.spacing(6)} ${theme.spacing(8)}`,
  backgroundColor: theme.palette.grey[50],
  borderRadius: theme?.borderRadius?.main,
  marginBottom: theme.spacing(6),
}));

export const StyledUploadFileTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
}));

export const StyledUploadFileSubTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  color: theme.palette.grey[300],
  marginBottom: theme.spacing(2),
}));

export const StyledEditFileContainer = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: '480px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  rowGap: theme.spacing(4),
}));

export const StyledEditAudioHeader = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const AudioPlayer = styled('audio')(({ theme }) => ({}));
export const DocumentPreview = styled(FilePreview)(({ theme }) => ({
  width: '5rem',
  height: '5rem',
}));
export const ImagePreview = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  maxHeight: '202px',
}));
export const VideoPlayer = styled('video')(({ theme }) => ({
  maxWidth: '100%',
  maxHeight: '202px',
}));
