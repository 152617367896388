import { styled } from '@mui/material';

export const StyledEditableDropdown = styled('div')(({ theme }) => ({
  maxWidth: '326px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  alignSelf: 'flex-start',
  padding: `${theme.spacing(3)} ${theme.spacing(4.5)}`,
}));

export const StyledBtnWrapper = styled('div')(({ theme }) => ({
  margin: `${theme.spacing(3)} 0`,
}));
