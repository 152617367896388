import { Navigate, Route, Routes } from 'react-router';
import { ConfirmEmailPage } from './pages/confirm-email-page';
import { ForgotPassword } from './pages/forgot-password';
import { InvitationPage } from './pages/invitation-page';
import { Login } from './pages/login';
import { ResetPassword } from './pages/reset-password';

export default function Auth() {

  return (
    <Routes>
      <Route
        path="/invitation/:id"
        Component={InvitationPage}
      />
      <Route
        path="/forgot-password"
        Component={ForgotPassword}
      />
      <Route
        path="/reset-password"
        Component={ResetPassword}
      />
      <Route
        path="/confirm"
        Component={ConfirmEmailPage}
      /> 
      <Route
        path="/login"
        Component={Login}
      />
      <Route
        path="/*"
        element={<Navigate replace to='/auth/login' />}
      />
    </Routes>
  )
};