import { NavLink } from 'react-router-dom';
import { memo, useEffect, useMemo, useState } from 'react';

import { getTime } from '../../getTime';
import styles from './agents-list.module.scss';
import { useMe } from '../../../../api/use-me';
import { Available, Break, Busy } from '../../icons';
import { Mission, Receiving } from '../../icons/tags';
import { GroupResource } from '../../../../api/types';
import { Table } from '../../../../design/table/table';
import { Column } from '../../../../design/table/types';
import { getHebStatus, getStatus } from '../../../monitor';
import { BreakType } from '../../../interfaces/monitor-messages';
import { CallAttributes } from '../../../interfaces/monitor-call';
import ManageAgentGroups from '../manageAgentGroups/ManageAgentGroups';
import { safeParsePhoneNumber } from '../../../../utils/strings/parse-phone';
import { getDigitTimeDiff, getDigitTimeFromMillis } from '../../../../utils/time/time';
import { ButtonWithDropdown } from '../../../../design/button-with-dropdown/button-with-dropdown';
import {
  AgentAttributes,
  AgentOutboundState,
  AgentState,
  AgentStatus,
  StatusType,
} from '../../../interfaces/monitor-agent';

const MIN_TIME = new Date(0, 0, 0);
const MAX_TIME = new Date(9999, 12, 31);

const timeHourMinute = (date: Date) =>
  `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`; // returns "HH:MM"

export const AgentsList = memo((props: AgentsListProps) => {
  const user = useMe();

  const minTime = props.calls.reduce(
    (minTime, call) => (call.startTime && call.startTime < minTime ? call.startTime : minTime),
    MAX_TIME
  );
  const maxTime = props.calls.reduce(
    (maxTime, call) => (call.endTime && call.endTime > maxTime ? call.endTime : maxTime),
    MIN_TIME
  );
  const timeFilter = `fromTime/${timeHourMinute(minTime)}/toTime/${timeHourMinute(maxTime)}`;

  const refinedData = props.agents.map((agent) => {
    const agentsGroupNames = agent.queueUuids.map(
      (groupUuid) => props.groups?.find((group) => group.id === groupUuid)?.name ?? ''
    );

    const callUuid = agent.outboundCallUuid || agent.callUuid; // precedence to outbound call
    const agentCall = callUuid ? props.calls.find((call) => call.id === callUuid) : undefined;
    const agentCalls = agentCall ? [agentCall] : []; // the code below supports several calls per agent, but here only one call is taken (Tomer's "simple" monitor). TBD in the future about many calls per agent

    const otherPartyNumber = (call: CallAttributes) =>
      call.callDirection === 'inbound'
        ? call.origination
        : call.callDirection === 'outbound'
        ? call.destination
        : undefined;

    const inCallWith = agentCalls // we dont support yet agent in several concurrent calls, so for now could just take first array item
      .filter((call) => call && !call.endTime)
      .map((call) => call!)
      .filter((call) => ['inbound', 'outbound'].includes(call.callDirection))
      .map((call) => safeParsePhoneNumber(otherPartyNumber(call) || 'no_number', 'IL'));

    const breakType = props.breakTypes.find((breakType) => breakType.code === agent.breakType);

    return {
      ...agent,
      id: agent.id,
      name: agent.name ?? agent.number,
      divisions: agentsGroupNames,
      status: getStatus(agent.agentStatus, agent.agentState, agent.agentOutboundState, breakType),
      inCallWith: inCallWith.join(', ') || '--',
      inboundCalls: agent.inboundAnswered + agent.inboundUnanswered,
      inboundAnsweredCalls: agent.inboundAnswered,
      outboundCalls: agent.outboundAnswered + agent.outboundUnanswered,
      outboundAnsweredCalls: agent.outboundAnswered,
      totalAnsweredCalls: agent.inboundAnswered + agent.outboundAnswered,
      totalCalls: agent.inboundAnswered + agent.outboundAnswered + agent.inboundUnanswered + agent.outboundUnanswered,
      totalTimeInCalls: getDigitTimeFromMillis(agent.totalTalkDuration ?? 0, 'HH:MM:SS'),
      breakType: breakType,
      actions: (
        <ButtonWithDropdown label='...' buttonClassName={styles.menuButton}>
          <div>האזנה</div>
          <div>לחישה</div>
          <div>התפרצות</div>
        </ButtonWithDropdown>
      ),
    };
  });

  const columns: Array<Column<(typeof refinedData)[0], keyof (typeof refinedData)[0]>> = [
    {
      key: 'name',
      header: 'סוכן',
      className: styles.agentName,
      render: (row) => <AgentInfo name={row.name ?? row.number} currExtension={row.number} />,
    },
    {
      key: 'divisions',
      header: 'מחלקה',
      className: styles.agentDivisions,
      render: (row) => (
        <AgentCcqs
          ccqNames={row.divisions}
          agentId={row.id}
          domainId={row.domainUuid}
          groups={props.groups}
          queueUuids={row.queueUuids}
        />
      ),
      sort: (a, b) => a.divisions[0].localeCompare(b.divisions[0]),
    },
    {
      key: 'status',
      header: 'סטטוס',
      className: styles.agentStatusContainer,
      sort: (a: (typeof refinedData)[0], b: (typeof refinedData)[0]) => {
        const aStatus = getHebStatus(a.agentStatus, a.agentState, a.agentOutboundState);
        const bStatus = getHebStatus(b.agentStatus, b.agentState, b.agentOutboundState);
        return aStatus.localeCompare(bStatus);
      },
      render: (item) => (
        <AgentStatusLabel
          status={item.agentStatus}
          state={item.agentState}
          outboundState={item.agentOutboundState}
          statusStartTime={getTime(item.statusStartTime)}
          breakType={item.breakType}
        />
      ),
    },
    {
      key: 'inCallWith',
      header: 'בשיחה עם',
      className: styles.inCallWith,
    },
    {
      key: 'inboundCalls',
      header: 'צלצל',
      className: styles.inboundCallsCount,
      render: (row) => {
        if (!user.data) return <span>{row.inboundCalls}</span>;

        const extension = row.contact;
        const extensionFilterValue = `${user.data.domain.name.substring(0, 4)}:${extension}`;
        const todayDate = new Date();
        const dateFilterValue = `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`;
        const url = `/calls-history/from/${dateFilterValue}/to/${dateFilterValue}/type/inbound/extensionsPerDomain/${extensionFilterValue}/${timeFilter}`;
        return (
          <NavLink to={url} style={{ borderBlockEnd: '1px solid #181D24' }}>
            {row.inboundCalls}
          </NavLink>
        );
      },
    },
    {
      key: 'inboundAnsweredCalls',
      header: 'נענה',
      className: styles.inboundCallsAnswered,
      render: (row) => {
        if (!user.data) return <span>{row.inboundAnsweredCalls}</span>;

        const extension = row.contact;
        const extensionFilterValue = `${user.data.domain.name.substring(0, 4)}:${extension}`;
        const todayDate = new Date();
        const dateFilterValue = `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`;
        const url = `/calls-history/from/${dateFilterValue}/to/${dateFilterValue}/type/inbound/extensionsPerDomain/${extensionFilterValue}/status/answered/${timeFilter}`;
        return (
          <NavLink to={url} style={{ borderBlockEnd: '1px solid #181D24' }}>
            {row.inboundAnsweredCalls}
          </NavLink>
        );
      },
    },
    {
      key: 'outboundCalls',
      header: 'יוצא',
      className: styles.outboundCallsCount,
      render: (row) => {
        if (!user.data) return <span>{row.outboundCalls}</span>;

        const extension = row.contact;
        const extensionFilterValue = `${user.data.domain.name.substring(0, 4)}:${extension}`;
        const todayDate = new Date();
        const dateFilterValue = `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`;
        const url = `/calls-history/from/${dateFilterValue}/to/${dateFilterValue}/type/outbound/extensionsPerDomain/${extensionFilterValue}/${timeFilter}`;
        return (
          <NavLink to={url} style={{ borderBlockEnd: '1px solid #181D24' }}>
            {row.outboundCalls}
          </NavLink>
        );
      },
    },
    {
      key: 'outboundAnsweredCalls',
      header: 'נענה',
      className: styles.outboundCallsAnswered,
      render: (row) => {
        if (!user.data) return <span>{row.outboundAnsweredCalls}</span>;

        const extension = row.contact;
        const extensionFilterValue = `${user.data.domain.name.substring(0, 4)}:${extension}`;
        const todayDate = new Date();
        const dateFilterValue = `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`;
        const url = `/calls-history/from/${dateFilterValue}/to/${dateFilterValue}/type/outbound/extensionsPerDomain/${extensionFilterValue}/status/answered/${timeFilter}`;
        return (
          <NavLink to={url} style={{ borderBlockEnd: '1px solid #181D24' }}>
            {row.outboundAnsweredCalls}
          </NavLink>
        );
      },
    },
    {
      key: 'totalAnsweredCalls',
      header: 'סך הכל נענו',
      className: styles.totalAnsweredCalls,
      render: (row) => {
        if (!user.data) return <span>{row.totalAnsweredCalls}</span>;

        const extension = row.contact;
        const extensionFilterValue = `${user.data.domain.name.substring(0, 4)}:${extension}`;
        const todayDate = new Date();
        const dateFilterValue = `${todayDate.getFullYear()}-${todayDate.getMonth() + 1}-${todayDate.getDate()}`;
        const url = `/calls-history/from/${dateFilterValue}/to/${dateFilterValue}/extensionsPerDomain/${extensionFilterValue}/status/answered/${timeFilter}`;
        return (
          <NavLink to={url} style={{ borderBlockEnd: '1px solid #181D24' }}>
            {row.totalAnsweredCalls}
          </NavLink>
        );
      },
    },
    {
      key: 'totalTimeInCalls',
      header: 'זמן בשיחות',
      className: styles.totalTimeInCalls,
    },
    {
      key: 'actions',
      header: '',
      className: styles.actions,
    },
  ];

  return <Table columns={columns} data={refinedData} sort={true} tableName={props?.tableName} />;
});

interface AgentsListProps {
  groups: GroupResource[];
  agents: AgentAttributes[];
  calls: CallAttributes[];
  selectedGroups: string[];
  breakTypes: BreakType[];
  tableName?: string | undefined;
}

export const AgentsStatusList = memo((props: AgentsStatusListProps) => {
  const refinedData = props.agents.map((agent) => ({
    ...agent,
    breakType: props.breakTypes?.find((bt) => bt.code === agent.breakType),
  }));

  return (
    // <VisibilityWrapper visible={props.visible}>
    <ul className={styles.listContainer}>
      {refinedData.map((agent) => (
        <li key={agent.id} className={styles.agentStatusListItem}>
          <AgentInfo name={agent.name ?? agent.number} currExtension={agent.number} />

          <AgentStatusLabel
            status={agent.agentStatus}
            state={agent.agentState}
            outboundState={agent.agentOutboundState}
            breakType={agent.breakType}
            statusStartTime={getTime(agent.statusStartTime)}
          />
        </li>
      ))}
    </ul>
    // </VisibilityWrapper>
  );
});

interface AgentsStatusListProps {
  // visible: boolean;
  agents: AgentAttributes[];
  breakTypes?: BreakType[] | undefined;
}

const AgentInfo = ({ name, currExtension }: AgentInfoProps) => (
  <div>
    <span>{name} | </span>
    <span className={styles.agentGroup}>{currExtension}</span>
  </div>
);

interface AgentInfoProps {
  name: string;
  currExtension: string;
}

const AgentCcqs = ({ agentId, domainId, ccqNames, queueUuids, groups }: AgentCcqsProps) => {
  const memoizedGroups = useMemo(() => groups, [groups]);
  const memoizedCcqNames = useMemo(() => ccqNames, [ccqNames]);
  const memoizedQueueUuids = useMemo(() => queueUuids, [queueUuids]);

  const props = {
    agentId,
    domainId,
    groups: memoizedGroups,
    ccqNames: memoizedCcqNames,
    queueUuids: memoizedQueueUuids,
  };

  return (
    <div className={styles.agentCcqsContainer}>
      <ManageAgentGroups {...props} />
    </div>
  );
};

interface AgentCcqsProps {
  agentId: string;
  domainId: string;
  ccqNames: string[];
  queueUuids: string[];
  groups: GroupResource[];
}

const AgentStatusLabel = memo(({ status, state, outboundState, statusStartTime, breakType }: AgentStatusProps) => {
  const [currStatusDuration, setDuration] = useState<string>('00:00');

  useEffect(() => {
    const interval = setInterval(() => {
      if (!statusStartTime) return;
      setDuration(getDigitTimeDiff(new Date(statusStartTime), new Date(), false));
    }, 1000);
    return () => clearInterval(interval);
  }, [statusStartTime]);

  const breakLabel = breakType?.label;
  const breakColor = breakType?.type === 'mission' ? 'yellow' : 'grey';

  return (
    <div className={styles.agentStatus}>
      {getStatusComponent(status, state, outboundState, breakLabel, breakColor)}
      <div className={styles.agentStatusDuration}>{currStatusDuration}</div>
    </div>
  );
});

interface AgentStatusProps {
  status: AgentStatus;
  state: AgentState;
  outboundState: AgentOutboundState | undefined;
  statusStartTime: number;
  breakType?: BreakType;
}

const getStatusComponent = (
  status: AgentStatus,
  state: AgentState,
  outboundState: AgentOutboundState | undefined,
  breakLabel?: string,
  breakColor?: string
) => {
  const statusType = getStatus(status, state, outboundState);

  switch (statusType) {
    case StatusType.Available:
      return <Available />;
    case StatusType.Busy:
      return <Busy />;
    case StatusType.BusyOutbound:
      return <Busy outgoing />;
    case StatusType.Receiving:
      return <Receiving />;
    case StatusType.Break:
      return <Break text={breakLabel} color={breakColor} />;
    case StatusType.Mission:
      return <Mission />;
  }
};
