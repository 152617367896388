import React, { useContext } from 'react';
import classNames from 'classnames';
import { useDialogState } from 'reakit';
import styles from './extensions-list-item.module.scss';
import { ExtensionsForwardDialog } from '../extensions-dialog/extensions-forward-dialog';
import { ExtensionsCloseDialog } from '../extensions-dialog/extensions-close-dialog';
import { activeExtensionContext } from '../active-extensions-context';
import { ExtensionResource } from '../../api/types';
import { ButtonWithDropdown } from '../../design/button-with-dropdown/button-with-dropdown';
import { ExtensionMenu } from './extension-menu/extension-menu';
import { ChevronDown } from '../../design/icons/chevron-down';
import { ChevronUp } from '../../design/icons/chevron-up';
import { ExtensionsNameDialog } from '../extensions-dialog/extensions-name-dialog';
import { DisabledForwardDialog } from '../extensions-dialog/disabled-forward-dialog';
import { safeParsePhoneNumber } from '../../utils/strings/parse-phone';

export default function ExtensionsListItem(props: ExtensionListItemProps) {
  const disabledForwardDialog = useDialogState();
  const callForwardDialog = useDialogState();
  const closedModeDialog = useDialogState();
  const nameEditDialog = useDialogState();

  const [, setActiveExtension] = useContext(activeExtensionContext)

  const handleSimForward = (number: any) => {
    const knownPrefixes = [
      '22361',
      '22362',
      '11361',
      '11362'
    ];

    if (number?.length >= 15) {
      const prefix = number.substring(0, 5);
      for (let i = 0; i < knownPrefixes.length; i++) {
        if (prefix === knownPrefixes[i]) {
          return true;
        }
      }
    }
    return false;
  }

  const handleSimNumber = (number: any) => {
    const result = handleSimForward(number);
    if (result) {
      const parsedNumber = number ? formatPhoneNumber(number.substring(5, number.length)) : '';

      return {
        number: parsedNumber,
        edit: false
      };
    }
    return {
      number: formatPhoneNumber(number),
      edit: true
    };
  }

  const formatPhoneNumber = (number: string) => {
    return number ? safeParsePhoneNumber(number, 'IL') : '';
  }

  const displayDetails = () => {
    if (props.extension.forwardAllEnabled) {
      const parseNumber = handleSimNumber(props.extension.forwardAllDestination);
      return (
        <span className={styles.forwardNumber}>
          {
            props.extension.forwardAllEnabled ?
              (`שיחות מועברות ל-${parseNumber.number}`)
              :
              ''
          }
        </span>
      );
    }
  }

  const dialogs = [
    <DisabledForwardDialog dialog={disabledForwardDialog} />,
    <ExtensionsForwardDialog dialog={callForwardDialog} />,
    <ExtensionsCloseDialog dialog={closedModeDialog} />,
    <ExtensionsNameDialog dialog={nameEditDialog} />
  ];

  const handleCallForwardClick = (extension: ExtensionResource) => {
    setActiveExtension(extension);
    callForwardDialog.show();
  }

  const handleCancelForward = (extension: ExtensionResource) => {
    setActiveExtension(extension);
    closedModeDialog.show()
  }

  const handleNameEditClick = (extension: ExtensionResource) => {
    setActiveExtension(extension);
    nameEditDialog.show();
  }

  return (
    <>
      <li className={styles.root} key={props.extension.id}>
        <span className={classNames(styles.party)}>
          {props.extension.destinationNumber}
        </span>
        <span className={classNames(styles.description)}>
          {props.extension.description}
        </span>
        {displayDetails()}
        <span className={styles.actions}>
          <ButtonWithDropdown
            label="פעולות"
            collapsedBadge={<ChevronDown size={24} />}
            expandedBadge={<ChevronUp size={24} />}
            buttonClassName={styles.button}
          >
            <ExtensionMenu
              isForwarded={props.extension.forwardAllEnabled}
              onForwardClick={() => handleCallForwardClick(props.extension)}
              onCancelForwardClick={() => handleCancelForward(props.extension)}
              onEditNameClick={() => handleNameEditClick(props.extension)}
            />
          </ButtonWithDropdown>
        </span>

      </li>
      {dialogs.map((dialog, index) => <div key={index}>{dialog}</div>)}
    </>
  )
}

interface ExtensionListItemProps {
  extension: ExtensionResource;
}
