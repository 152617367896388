import { useRecoilValue } from 'recoil';

import { hadshanutUserState } from '../../state';
import { PermissionLevelOption } from '../types';
import { PermissionLevel } from '../../api/types';
import { useBusinessNumberCollection } from '../../api/use-business-number';
import { CALLBACK_FORM_FIELDS_CONFIG } from '../formConfig/callbackDialerFormConfig';
import { getAvailablePermissionOptions, getUserPermissionLevel } from '../utils/getUserPermissions';

export const useCallbackFieldsConfig = () => {
  const userData = useRecoilValue(hadshanutUserState);
  const businessNumbers = useBusinessNumberCollection(true);

  const selfManagedNumbers =
    businessNumbers?.data &&
    businessNumbers?.data.filter((businessNumber) =>
      userData?.domain.config.selfManagedNumbers.includes(businessNumber.id)
    );
  const businessNumberList =
    selfManagedNumbers &&
    selfManagedNumbers
      .filter((selfManagedNumber) => selfManagedNumber.number.trim().length > 0)
      .map((businessNumber) => businessNumber.number);

  const businessNumberOptions =
    (businessNumberList &&
      businessNumberList?.map((businessNumber) => ({ value: businessNumber, label: businessNumber }))) ||
    [];

  CALLBACK_FORM_FIELDS_CONFIG.business_number.options = businessNumberOptions;

  const permissionLevel = getUserPermissionLevel(userData) as PermissionLevel;
  const availablePermissionOptions: PermissionLevelOption[] = getAvailablePermissionOptions(permissionLevel);

  return {
    ...CALLBACK_FORM_FIELDS_CONFIG,
    business_number: { ...CALLBACK_FORM_FIELDS_CONFIG.business_number, options: businessNumberOptions },
    selectedPermission: { ...CALLBACK_FORM_FIELDS_CONFIG.selectedPermission, options: availablePermissionOptions },
  };
};
