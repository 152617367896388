import { API } from 'aws-amplify';
import useSWR from 'swr';
import { useMe } from './use-me';
import { PermissionGroup } from './types';

const SWR_CONFIG = { revalidateOnFocus: false, revalidateOnReconnect: false };

export const useDomains = (domainUuid?: string) => {
  const isAdmin = useMe().data?.groups.includes(PermissionGroup.Support);
  const isAuthenticated = isAdmin || domainUuid; // Request must be either from an admin, or a regular user providing domainUuid
  const endpoint = `/domain/${domainUuid}`;
  const { data, error, mutate, isLoading } = useSWR(isAuthenticated ? endpoint : null, fetcher, SWR_CONFIG);

  return {
    domains: isLoading ? undefined : domainUuid ? [data as Domain] : (data as Domain[]),
    error: error,
    loading: isLoading,
    mutate: mutate,
  };
};

export const useUsers = (userUUid?: string) => {
  const isAdmin = useMe().data?.groups.includes(PermissionGroup.Support);
  const isAuthenticated = isAdmin || userUUid; // Request must be either from an admin, or a regular user providing userUUid
  const endpoint = `/user/${userUUid || ''}`;
  const { data, error, mutate, isLoading } = useSWR(isAuthenticated ? endpoint : null, fetcher, SWR_CONFIG);

  return {
    users: isLoading ? undefined : userUUid ? [data as User] : (data as User[]),
    error: error,
    loading: isLoading,
    mutate: mutate,
  };
};

export const useQuery = (searchValues: Record<string, string>) => {
  const endpoint = '/query?';
  const searchPath = Object.entries(searchValues)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  const { data, error, mutate } = useSWR(endpoint + searchPath, fetcher, SWR_CONFIG);
  return {
    data: data,
    error: error,
    loading: !error && !data,
    mutate: mutate,
  };
};

const fetcher = async (path: string) => {
  const res = await API.get(process.env.REACT_APP_MULTI_TENANT_API_NAME!, path, {});
  return res;
};

export interface Domain {
  domain_uuid: string;
  domain_name: string;
  domain_owner_name: string;
  domain_self_managed_numbers?: string[];
  domain_self_managed_extensions?: string[];
  domain_self_managed_groups?: string[];
  users: Omit<User, 'domains'>[];
}

export interface User {
  user_uuid: string;
  user_name: string;
  user_email: string;
  domains: Omit<Domain, 'users'>[];
}
