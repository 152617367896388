import { memo } from 'react';
import { Typography } from '@mui/material';

import { FormSection } from '../types';
import { TypographyVariant } from '../../theme/muiTypes';
import { AGENT_DIALING_SETTINGS_TEXTS } from '../utils/texts';
import { useFormFieldRenderer } from '../../hooks/useFormFieldRenderer';
import { CustomCard } from '../../components/common/customCard/CustomCard';
import { useCallbackFieldsConfig } from '../hooks/useCallbackFieldsConfig';
import { CustomDivider } from '../../components/common/customDivider/CustomDivider';
import { StyledCardsContainer, StyledFormContainer } from './StyledCallbackDialerSettings';

const { AGENT_DIALING_TITLE, AGENT_DIALING_SUB_TITLE } = AGENT_DIALING_SETTINGS_TEXTS;

export const AgentDialingSettings = memo(({ section, apiConfig }: FormSection) => {
  const {
    active_now,
    agent_greeting,
    agent_max_ringing,
    agent_approval_key,
    agent_max_attempts,
    agent_dial_interval,
    agent_abort_call_key,
    agent_continuous_call,
    agent_play_cust_number,
    agent_play_num_requests,
    incoming_call_permitted,
    agent_complete_call_key,
  } = useCallbackFieldsConfig();
  const renderFormField = useFormFieldRenderer(apiConfig);

  return (
    <StyledFormContainer id={section.id}>
      <Typography variant={TypographyVariant.h1}>{AGENT_DIALING_TITLE}</Typography>
      <Typography variant={TypographyVariant.regularText2}>{AGENT_DIALING_SUB_TITLE}</Typography>
      <StyledCardsContainer className='general-settings'>
        <CustomCard className='column'>
          {renderFormField(active_now)}
          <CustomDivider hidden={!active_now.uiConfig.hasPermission} />
          {renderFormField(agent_max_attempts)}
          {renderFormField(agent_dial_interval)}
          {renderFormField(agent_max_ringing)}
          <CustomDivider hidden={!agent_max_ringing.uiConfig.hasPermission} />
          {renderFormField(agent_approval_key)}
          {renderFormField(agent_greeting)}
          <CustomDivider hidden={!agent_greeting.uiConfig.hasPermission} />
          {renderFormField(agent_abort_call_key)}
          {renderFormField(agent_complete_call_key)}
          {renderFormField(agent_continuous_call)}
          <CustomDivider hidden={!agent_continuous_call.uiConfig.hasPermission} />
          {renderFormField(agent_play_cust_number)}
          <CustomDivider hidden={!agent_play_cust_number.uiConfig.hasPermission} />
          {renderFormField(agent_play_num_requests)}
          <CustomDivider hidden={!agent_play_num_requests.uiConfig.hasPermission} />
          {renderFormField(incoming_call_permitted)}
        </CustomCard>
      </StyledCardsContainer>
    </StyledFormContainer>
  );
});

AgentDialingSettings.displayName = 'AgentDialingSettings';
