import { API } from "aws-amplify";
import { Field, FieldProps } from "formik";
import { useState } from "react";
import { useMe } from "../../../../api/use-me";
import { Checkbox } from "../../../../design/checkbox/checkbox";
import { Input } from "../../../../design/form/input/input";
import { Check } from "../../../../design/icons/check";
import { Edit } from "../../../../design/icons/edit";
import { Spinner } from "../../../../design/spinner/spinner";
import { SelfManagedResource } from "../self-managed-resources";
import styles from "./config-resource-dialog.module.scss";
import { ConfigResourceType } from "./config-resource-dialog";

export const SelfManagedResourceConfig = ({
  heading,
  resource,
  resourceName,
  mutate,
}: SelfManagedResourceConfigProps) => {
  const user = useMe();

  const handleResourceLabelEdit = async (label: string, resource: SelfManagedResource) => {
    if (label === resource.label) return; // if no change is made, no need to fire a request.

    try {
      if (resourceName === "groups") {
        const body = {
          name: label,
          number: resource.number,
        };
        await API.patch(
          process.env.REACT_APP_PHP_SERVICES_NAME!,
          `/crud/dom/${user?.data?.domain.id}/RingGroup/${resource?.number}`,
          { body }
        );
      } else {
        const body = {
          label: label,
          labelId: resource.id,
        };
        const endpoint = resourceName === "numbers" ? "business-numbers" : "extension";
        const entry = resourceName === "numbers" ? "domains" : "domain";
        await API.put(
          process.env.REACT_APP_WEB_API_NAME!,
          `/${entry}/${user?.data?.domain.id}/${endpoint}/${resource?.number}/name`,
          { body }
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      mutate();
    }
  };

  return (
    <>
      <h3
        className={styles.resourceHeading}
        style={resourceName === "numbers" ? { marginBlockStart: "16px" } : {}}
      >
        {heading}
      </h3>
      {resource.map((resource) => (
        <div key={resource.id} className={styles.resourceField}>
          <Field type='checkbox' name={resourceName} value={resource.id}>
            {({ field }: FieldProps) => <Checkbox {...field} />}
          </Field>
          <Resource resource={resource} onLabelEdit={handleResourceLabelEdit} />
        </div>
      ))}
    </>
  );
};

const Resource = (props: ResourceProps) => {
  const [editState, setEditState] = useState(false);
  const [label, setLabel] = useState(props.resource.label || "");
  const [loading, setLoading] = useState(false);

  const confirmLabelEdit = async () => {
    try {
      setLoading(true);
      await props.onLabelEdit(label, props.resource);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setEditState(false);
    }
  };

  return editState ? (
    <>
      <Input
        value={label}
        onChange={(event) => setLabel(event.target.value)}
        disabled={loading}
        className={styles.editField}
      />
      {loading ? (
        <Spinner className={styles.smallSpinner} />
      ) : (
        <Check onClick={confirmLabelEdit} className={styles.clickableIcon} />
      )}
    </>
  ) : (
    <>
      <div className={styles.wrapper}>
        {props.resource.number} {props.resource.label}
      </div>
      {<Edit onClick={() => setEditState(true)} className={styles.clickableIcon} />}
    </>
  );
};

interface SelfManagedResourceConfigProps {
  heading: string;
  resource: SelfManagedResource[];
  resourceName: ConfigResourceType;
  mutate: () => void;
}

interface ResourceProps {
  resource: SelfManagedResource;
  onLabelEdit: (label: string, resource: SelfManagedResource) => void;
}
