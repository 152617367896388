import { createContext, useState } from "react";
import { Launcher } from "../../api/dialers";


export const LauncherContext = createContext<[Launcher | undefined, React.Dispatch<React.SetStateAction<Launcher | undefined>>]>([
  undefined, () => undefined 
]);

export const LauncherContextProvider = ({ children }: { children: React.ReactNode }) => {
  const ctx = useState<Launcher | undefined>(undefined);
  
  return (
    <LauncherContext.Provider value={ctx}>
      {children}
    </LauncherContext.Provider>
  )
}