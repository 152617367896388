import React, { HTMLAttributes } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { WarningIcon } from '../../design/icons/warning-icon';
import { Tag } from '../../design/tag/tag';
import { InvitationResource } from '../../api/types';
import { InvitationListItem } from '../invitation-list-item/invitation-list-item';
import styles from './invitation-list.module.scss';


export function InvitationList({
  title,
  items,
  ...props
}: InvitationListProps) {
  return (
    <div id={props.id}>
      {title && <h3 className={styles.title}>{title}</h3>}
      <ul className={styles.list}>
        {items.map((invitation) => {
          const status = invitation.status;
          const willExpireSoon =
            status === 'PENDING' &&
            invitation.expiresAt - Date.now() <= 3 * 24 * 60 * 60 * 1000;

          return (
            <InvitationListItem className={styles.item} key={invitation.id}>
              <div className={styles.organizationDetails}>
                <Tag
                  className={styles.domainName}
                  label={invitation.user.domainName.split('.', 1)[0]}
                />

                <span className={styles.organizationName}>
                  {invitation.user.organizationName}
                </span>
                <span className={styles.userName}>{invitation.user.name}</span>
              </div>
              <span className={styles.time}>
                <span>
                  {status === 'PENDING' &&
                    `תפוג ${moment(invitation.expiresAt).fromNow()}`}
                </span>
                {willExpireSoon && (
                  <WarningIcon size={24} className={styles.warningIcon} />
                )}

                {status === 'EXPIRED' &&
                  moment(invitation.expiresAt).format('[לא תקפה] DD/MM/YYYY')}

                {status === 'ACCEPTED' &&
                  moment(invitation.acceptedAt).format('[אושרה] DD/MM/YYYY')}
              </span>
              <span className={styles.itemActions}>
                <Link to={`/invitations/${invitation.id}`}>
                  פרטי הזמנה
                </Link>
              </span>
            </InvitationListItem>
          );
        })}
      </ul>
    </div>
  );
}

export interface InvitationListProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  items: InvitationResource[];
}
