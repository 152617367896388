import classNames from 'classnames';
import { Field } from 'formik';
import { memo, useCallback } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { MonitorProps } from '../..';
import { GroupResource } from '../../../api/types';
import { Button } from '../../../design/button';
import { Spinner } from '../../../design/button/button';
import { Checkbox } from '../../../design/checkbox/checkbox';
import { Filter } from '../../../design/filter';
import { ArrowRight } from '../../../design/icons';
import { HorizontalPartition } from '../../../design/partition/horizontal-partition';
import { SLAChart } from '../../common/charts';
import { AgentsChart } from '../../common/charts/agents-chart';
import { Card } from '../../common/components';
import { TabTitle } from '../../common/components/tables-container/tables-container';
import { WaitingCalls } from '../../common/components/waiting-calls-table/WaitingCallsCard';
import { TEXT_HEB } from '../../common/texts';
import { CallAttributes } from '../../interfaces';
import { getInboundCalls, getOutboundCalls } from '../../utils/callFilters';
import styles from './main-page.module.scss';
import { isCallInboundAbandoned, isCallInboundAnswered } from '../../../utils';
import { MonitorConfiguration } from '../../use-monitor-config';
const { BACK, CALL_CENTER, GROUP_PLUR, TOTAL, CALL_PLUR, OUTBOUND_PLUR, ANSWERED_PLUR, UNANSWERED_PLUR, INBOUND_PLUR } = TEXT_HEB;

interface MainPageProps extends MonitorProps {
  onCardClick: (tab: TabTitle | 'main') => void;
}

export const MainPage: React.FC<MainPageProps> = ({ data, selectedGroups, ...props }) => {
  const navigate = useNavigate();
  const backToHadshanut = async () => {
    navigate('/');
  }
  
  return (
    <div className={styles.layout}>

      <div className={styles.backToHadshanutButton}>
        <NavLink to='/' onClick={backToHadshanut}><ArrowRight /></NavLink>
        <Button variant='link' onClick={backToHadshanut}>{ BACK }</Button>
      </div>

      <div className={styles.pageHeader}>
        <div className={styles.title}>{CALL_CENTER}</div>
        <ButtonsBar groups={data.groups} selectedGroups={selectedGroups} onGroupsSelection={props.onGroupSelection} />
      </div>

      <div className={styles.grid}>

        <Card className={styles.totalCalls}>
          <TotalCallsCount
            title={TOTAL + " " + CALL_PLUR}
            value={data.calls.length}
          />
        </Card>

        <Card className={styles.waitingCalls} onClick={() => props.onCardClick('waiting-calls')}>
          <WaitingCalls
            calls={data.calls}
            groupUuids={selectedGroups}
            waitingThreshold={props.monitorConfig.waiting_alert_threshold}
          />
        </Card>

        <Card className={styles.inboundBreakdown}>
          <InboundCallsBreakdown
            calls={data.calls}
            monitorConfig={props.monitorConfig}
            onClick={props.onCardClick}
          />
        </Card>

        <Card className={styles.agentsChart} onClick={() => props.onCardClick('agents')}>
          <AgentsChart
            agents={data.agents}
            breakTypes={props.breakTypes}
          />
        </Card>

        <Card className={classNames(styles.outboundTotal, styles.titleWithValue)} onClick={() => props.onCardClick('outbound-calls')}>
          <div className={styles.title}>{OUTBOUND_PLUR}</div>
          <div className={styles.value}>
            { getOutboundCalls(data.calls).length }
          </div>
        </Card>

        <Card className={styles.SLA}>
          <SLAChart
            calls={data.calls}
            slaThreshold={props.monitorConfig.ideal_answer_time}
            monitoredGroupUuids={selectedGroups}
            selfManagedGroupUuids={data.groups.map(group => group.id)}
            validCallThreshold={props.monitorConfig.valid_call_threshold}
          />
        </Card>
      </div>
    </div>
  );
};


const TotalCallsCount: React.FC<{ title: string, value: string | number }> = memo(({ title, value }) => {

  return (
    <div className={styles.titleWithValue}>
      <div className={styles.title}>{ title }</div>
      <div className={styles.value}>{ value } </div>
    </div>
  )
});

interface InboundCallsBreakdownProps {
  calls: CallAttributes[];
  onClick: (tab: TabTitle | 'main') => void;
  monitorConfig: MonitorConfiguration;
}

const InboundCallsBreakdown: React.FC<InboundCallsBreakdownProps> = ({ calls, onClick, monitorConfig }) => {
  const totalInbound = getInboundCalls(calls);
  const inboundAnswered = totalInbound.filter(call => isCallInboundAnswered(call, monitorConfig.valid_call_threshold));
  const inboundUnanswered = totalInbound.filter(call => isCallInboundAbandoned(call, monitorConfig.valid_call_threshold));
  const callBackRequests = 0; // TBD where to get this data from

  return (
    <div className={styles.inboundBreakdownContainer}>

      <div className={styles.titleWithValue} style={{marginInlineStart: '24px'}} onClick={() => onClick('inbound-calls')}>
        <div className={styles.title}>{ INBOUND_PLUR }</div>
        <div className={styles.value}>{ totalInbound.length }</div>
      </div>

      <HorizontalPartition />

      <div className={styles.breakdown}>


        <div className={styles.titleWithValue}>
          <div className={styles.value}>{ inboundAnswered.length }</div>
          <div className={styles.title}>{ ANSWERED_PLUR }</div>
        </div>

        <span className={styles.verticalPartition} />

        <div className={styles.titleWithValue} onClick={() => onClick('unanswered-calls')}>
          <div className={styles.value}>{ inboundUnanswered.length }</div>
          <div className={styles.title}>{ UNANSWERED_PLUR }</div>
        </div>

        <span className={styles.verticalPartition} />

        <div className={styles.titleWithValue}>
          <div className={styles.value}>{ callBackRequests }</div>
          <div className={styles.title}>{ 'שיחה חוזרת' }</div>
        </div>

      </div>
    </div>
  )
}

// בחירת קבוצות + שני כפתורים למעלה משמאל
const ButtonsBar = memo(({ selectedGroups, groups, onGroupsSelection }: ButtonBarProps) => {
  const handleReset = useCallback(() => {
    onGroupsSelection(groups.map(group => group.id));
  }, [groups.length, onGroupsSelection]);

  const handleSubmit = (values: any) => {
    onGroupsSelection(values.groups);
  }

  const initialValues = { groups: selectedGroups?.length ? selectedGroups : groups.map(g => g.id) };
  
  return (
    <div className={styles.controls}>
      <Filter
        active={false}
        initialValues={initialValues}
        variant='button-like'
        label={GROUP_PLUR}
        onReset={handleReset}
        onSubmit={handleSubmit}
      >
        <div className={styles.filterWithRows}>
          {groups
            ? groups.map(group =>
              <div key={group.id} className={styles.groupFilterRow}>
                <Field
                  component={(props: any) => (
                    <Checkbox
                      title={group.name}
                      {...props.field}
                      style={{marginBlock: '4px'}}
                    />
                  )}
                  name="groups"
                  type="checkbox"
                  value={group.id}
                  key={group.id}
                />
              </div>
            )
            : <Spinner />
          }
        </div>
      </Filter>
    </div >
  )
});

interface ButtonBarProps {
  groups: GroupResource[];
  selectedGroups: string[];
  onGroupsSelection: (groups: string[]) => void;
}