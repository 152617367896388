import React, { useState, useRef, InputHTMLAttributes } from "react";
import classNames from "classnames";
import { Button } from "../../button";
import styles from "./file-input.module.scss";
import { useEffect } from "react";

export function FileInput({
  onChange,
  onReset,
  label,
  description,
  placeholderFileUrl,
  placeholderFilename,
  uploadButtonLabel,
  resetButtonLabel,
  replaceButtonLabel,
  className,
  ...props
}: FileInputProps) {
  const [fileUrl, setFileUrl] = useState(placeholderFileUrl);
  const inputRef = useRef<HTMLInputElement | null>();
  const filename = inputRef.current?.files?.[0]?.name

  const showFullUpload = !props.value && !fileUrl;

  useEffect(() => {
    setFileUrl(placeholderFileUrl);
  }, [placeholderFileUrl]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (file) {
      setFileUrl(URL.createObjectURL(file));
    }
    if (onChange) onChange(event);
  };

  const handleReset = () => {
    setFileUrl(placeholderFileUrl);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
    if (onReset) onReset();
  };

  const handleClick = () => inputRef.current?.click();

  return (
    <>
      <input
        type="file"
        className={classNames(styles.input, className)}
        ref={(ref) => (inputRef.current = ref)}
        onChange={handleChange}
        {...props}
      />
      {showFullUpload ? (
        <FullUpload
          onClick={handleClick}
          label={label}
          description={description}
          uploadButtonLabel={uploadButtonLabel}
        />
      ) : (
        <InlineUpload
          onClick={handleClick}
          onRemove={handleReset}
          filename={filename}
          placeholderFilename={placeholderFilename}
          resetButtonLabel={resetButtonLabel}
          replaceButtonLabel={replaceButtonLabel}
        />
      )}
    </>
  );
}

function FullUpload({
  onClick,
  label,
  description,
  uploadButtonLabel,
}: FullUploadProps) {
  return (
    <div className={styles.file}>
      {label && (
        <span className={styles.label}>
          {/* העלאת קובץ הקלטה חדש */}
          {label}
        </span>
      )}
      {description && (
        <span className={styles.description}>
          {/* ניתן להעלות קבצי אודיו עד 5MB */}
          {description}
        </span>
      )}
      <Button type="button" className={styles.uploadButton} onClick={onClick}>
        {uploadButtonLabel ? uploadButtonLabel : "בחר/י קובץ..."}
      </Button>
    </div>
  );
}

function InlineUpload({
  onClick,
  onRemove,
  filename,
  placeholderFilename,
  resetButtonLabel,
  replaceButtonLabel,
}: InlineUploadProps) {

  return (
    <>
      <div className={styles.actions}>
        {filename ? (
          <>
            <span style={{ flexGrow: 1 }}>{filename}</span>
            <Button type="button" variant="link" onClick={onRemove}>
              {/* הסרת הקלטה */}
              {resetButtonLabel || "הסרת קובץ"}
            </Button>
          </>
        ) : (
          <>
            <span>
              {/* הקלטה נוכחית */}
              {placeholderFilename || ""}
            </span>
            <Button type="button" variant="link" onClick={onClick}>
              {/* העלאת הקלטה חדשה */}
              {replaceButtonLabel || "החלפת קובץ"}
            </Button>
          </>
        )}
      </div>
    </>
  );
}

export interface FileInputProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholderFileUrl?: string;
  onReset?: () => void;
  label?: string;
  description?: string;
  placeholderFilename?: string;
  uploadButtonLabel?: string;
  resetButtonLabel?: string;
  replaceButtonLabel?: string;
}

interface FullUploadProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  label?: string;
  description?: string;
  uploadButtonLabel?: string;
}

interface InlineUploadProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  onRemove: () => void;
  filename?: string;
  placeholderFilename?: string;
  resetButtonLabel?: string;
  replaceButtonLabel?: string;
}
