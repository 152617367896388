import { useMediaQuery } from "../../layouts/app-layout/app-layout";
import { tabletQuery } from "../../theme/media";
import { CallbacksReportDesktop } from "./desktop";
import { CallbacksReportMobile } from "./mobile";

/**
 * This component is strictly a gateway component that determines which version of the Callbacks Report will be rendered.
 * The mobile and desktop versions of the report are more different than similar which led to many if statements and scss media queries which, in turn, made
 * the code hard to maintain.
 * 
 * The current components, CallbacksReportDesktop and CallbacksReportMobile are similar in some ways, and different in others. Please view them carefully and 
 * attentively  before making any changes to the current architecture. 
 * 
 * @returns Either the desktop or mobile version of the callback requests report. The component returned is dependent on the
 *          width of the viewport.    
 */
export const CallbacksRouter = () => {
    const isTabletOrMobile = useMediaQuery(tabletQuery);

    if (!isTabletOrMobile) {
        return <CallbacksReportDesktop />
    }

    return <CallbacksReportMobile />
}