import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import ExtensionsListPage from './extensions-list-page/extensions-list-page';
import { activeExtensionContext } from './active-extensions-context';
import { ExtensionResource } from '../api/types';

export function Extensions() {
	const activeExtensionState = useState<ExtensionResource>();

	return (
		<activeExtensionContext.Provider value={activeExtensionState}>
			<Routes>
				<Route
					path="/"
					Component={ExtensionsListPage}
				/>
			</Routes>
		</activeExtensionContext.Provider>
	)
}
