import { API } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDialogState } from 'reakit';
import { Dialog } from '../../design/dialog/dialog';
import { Spinner } from '../../design/spinner/spinner';
import { useBusinessNumberEventCollection, useBusinessNumberEventResource, useBusinessNumberResource } from '../../api/use-business-number';
import { EventForm, EventFormValues } from './event-form/event-form';
import { useMe } from '../../api/use-me';



export function EditEvent() {

  const { number, id } = useParams<{ number: string, id: string }>();
  const user = useMe();
  const businessNumber = useBusinessNumberResource(number!);
  const event = useBusinessNumberEventResource(number!, id!);
  const eventCollection = useBusinessNumberEventCollection(number!);
  const dialog = useDialogState({
    visible: true
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const handleSubmit = async (values: EventFormValues) => {
    try {
      setLoading(true);
      const formData = new FormData();
      if (values.id) {
        formData.append('id', values.id);
      }
      formData.append('name', values.name);
      formData.append('date', `${values.startDate}~${values.endDate}`);
      formData.append('status', values.closed ? 'closed' : 'open');
      formData.append('openAt', values.openAt);
      formData.append('closeAt', values.closeAt);
      if (values.file) {
        formData.append('file', values.file);
      }
      if (values.recording) {
        formData.append('recording', values.recording);
      }
      await API.put(process.env.REACT_APP_WEB_API_NAME!, `/domains/${user?.data?.domain.id}/business-numbers/${number}/events/${event.data?.id}`, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        body: formData
      });
      mixpanel.track('Update Scheduled Event', {
        'Event Start Date': moment(values.startDate).utc().toString(),
        'Event End Date': moment(values.endDate).utc().toString(),
      });
      await businessNumber.mutate();
      await eventCollection.mutate();
      await event.mutate();
      navigate(`/my-numbers/${number}`);
    } catch (error: any) {
      if (typeof error.response.data === "string") {
        setError(error.response.data);
      } else {
        const errorMessage = error.response.data[0].message ? error.response.data[0].message : JSON.stringify(error.response.data[0]);
        setError(errorMessage);
      }
      setLoading(false);
    }
  }

  const handleCancel = () => {
    navigate(`/my-numbers/${number}`);
  }

  if (event.error) {
    return (
      <div>Error</div>
    )
  }

  return (
    <Dialog
      {...dialog}
      variant="form"
      header={`עריכת אירוע${event.data ? '-' + event.data.name : ''}`}
      onClose={handleCancel}
      initialValues={{
        id: event?.data?.id,
        name: event?.data?.name || '',
        startDate: event?.data?.date.split('~')[0] || "",
        endDate: event?.data?.date.split('~')[1] || "",
        closed: !event?.data?.openAt && !event?.data?.closeAt,
        openAt: event?.data?.openAt || '',
        closeAt: event?.data?.closeAt || '',
        file: null,
        recording: ''
      }}
      loading={loading}
      onSubmit={handleSubmit}
    >
      {
        event.data ? (
          <EventForm
            closedMessageUrl={event.data.closedMessageUrl}
            error={error}
          />
        ) : (
          <Spinner />
        )
      }
    </Dialog>
  )
}
