import { GroupResource } from "../../../../api/types";
import { DEFAULT_DATE, ensureDate, getOldestCallDate, getYoungestCallDate, isCallInboundAbandoned, isCallInboundAnswered } from "../../../../utils";
import { CallAttributes, call_getTheGroup, call_theGroupUuid } from "../../../interfaces";
import { MonitorConfiguration } from "../../../use-monitor-config";

/*
  the goal:
  > for each key = group + caller, collect all unanswered calls until (incuding) answered call in one GroupCallerEntry (visual line)

  logic:
  > iterate on calls
    > if inbound  & not answered - add call to pendingGroupCaller (key => calls list)
    > if inbound  & answered & there's pendingGroupCaller entry for the key  - create groupCallerEntries טופל entry and delete from pendingGroupCaller
    > if outbound & answered & there's pendingGroupCaller entry for the key  - create groupCallerEntries טופל בשיחה יוצאת entry and delete from pendingGroupCaller
  > iterate on entries that remained in pendingGroupCaller (that is, caller is still waiting to be called)
    > create groupCallerEntries טרם טופל and delete from pendingGroupCaller
*/

type uuid = string;
const constructKey = (groupUuid: uuid, caller: string) => `${groupUuid},${caller}`;

// UnansweredGroupCaller
export interface UnansweredGroupCaller {
  id: string,
  callerNumber: string,
  lastCall: string,
  queueName: string,
  callsCount: number,
  totalRingTimeMs: number,
  status: 'טופל בשיחה נכנסת' | 'טופל בשיחה יוצאת' | 'טרם טופל' | 'בקשת חזרה טופלה' | 'חיוג חזרה נכשל';
  oldestCallStartTime: Date;    // for generating link to calls history report
  youngestCallStartTime: Date;  // for generating link to calls history report
}

// unansweredCallsList
export function createUnansweredCallsList(calls: CallAttributes[], monitorConfig: MonitorConfiguration, selfManagedGroups: GroupResource[] | undefined): UnansweredGroupCaller[] {
  const selfManagedGroupUuids = selfManagedGroups?.map(g => g.id) || [];

  // happens for calls that timeParameters.ts couln'd find their first group instance
  calls
    .filter(call => (!(call.startTime instanceof Date)))
    .forEach(call => {
      const { startTime, createDate } = call;
      call.startTime = ensureDate(startTime) || ensureDate(createDate) || DEFAULT_DATE;
      console.warn(`UnansweredCallsList(): Bad startTime, call.startTime: ${startTime}, call.createDate: ${createDate}, fixed call.startTime: ${call.startTime}`);
    });

  // arrays for results of the logic below
  const pendingGroupCaller = new Map<string, CallAttributes[]>();   // for non-closed inbound calls of group + caller
  const groupCallerEntries: UnansweredGroupCaller[] = [];           // for closed entries

  // find all closed entries, populate pendingGroupCaller and groupCallerEntries
  calls
    .filter(call => call.endTime !== undefined)                       // take only ended calls
    .sort((a, b) => a.startTime!.getTime() - b.startTime!.getTime())  // sort calls by startTime
    .forEach(call => {                                                // populate maps pendingGroupCaller and outboundCalls
      switch (call.callDirection) {
        // for inbound, populate pendingGroupCaller / callerGroupEntries
        case 'inbound': {
          const callerNumber = call.origination ?? 'unknown';

          const group = call_getTheGroup(call, selfManagedGroupUuids);
          if (!group) { console.error(`UnansweredCallList(): call belongs to no group thus not counted, call: `, call); break; }

          const groupUuid = group.groupUuid;
          const key = constructKey(groupUuid, callerNumber);

          // if not answered, add call to pendingGroupCaller' grouped calls
          if (isCallInboundAbandoned(call, monitorConfig.valid_call_threshold)) {
            pendingGroupCaller.has(key) || pendingGroupCaller.set(key, []);

            const groupedCalls = pendingGroupCaller.get(key)!;
            groupedCalls.push(call);
          }

          // if answered and there are previous non answered calls, add entry to groupCallerEntries, remove from pendingGroupCaller
          else if (isCallInboundAnswered(call, monitorConfig.valid_call_threshold)) {
            if (pendingGroupCaller.has(key)) { // do something only if there are pending calls
              const groupedCalls = pendingGroupCaller.get(key)!;

              // the total waitTime from first not answered call till the answered call
              const totalRingTime = groupedCalls.reduce((total, nextCall) => total + (nextCall.endTime!.getTime() - nextCall.startTime.getTime()), 0); // not-answered calls, so endTime - startTime = ringTime

              // move the groupedCall from pendingGroupCaller to groupCallerEntries
              const groupCallerEntry: UnansweredGroupCaller = {
                id: String(groupCallerEntries.length), // trick, sequential number starting 0
                callerNumber: callerNumber,
                lastCall: groupedCalls.at(-1)!.endTime!.toLocaleTimeString("he-IL", { hour: "2-digit", minute: "2-digit" }),  // groupCalls always has at least 1 call, calls enter there when has endTime
                queueName: selfManagedGroups?.find(selfManagedGroup => selfManagedGroup.id === groupUuid)?.name ?? 'תור לא ידוע',
                callsCount: groupedCalls.length,
                totalRingTimeMs: totalRingTime,
                status: "טופל בשיחה נכנסת",
                youngestCallStartTime: getYoungestCallDate(groupedCalls),
                oldestCallStartTime: getOldestCallDate(groupedCalls),
              };

              groupCallerEntries.push(groupCallerEntry);
              pendingGroupCaller.delete(key);
            } else {
              // call doesn't belong to pending (not-answered) hroupedCalls, ignore
            }
          }

          // if call is not abandoned/answered, eg still ringing
          else {
            // ignore the call
          }

          break;
        }

        // for outbound, if answered or too many attmpts, close the groupedCalls
        case 'outbound': {
          const calledNumber = call.destination!;
          if (!calledNumber) { console.error(`UnansweredCallList: No call.destination, call: `, call); break; }

          const group = call_getTheGroup(call, selfManagedGroupUuids)!;
          if (!group) { console.error(`UnansweredCallList: No call_getTheGroup(call), call:`, call); break; }

          const groupUuid = group.groupUuid;
          const key = constructKey(groupUuid, calledNumber);
          
          // close the entry when outbound call answered or reaching outbound attempts threshold: add entry to groupCallerEntries, remove from pendingGroupCaller
          // unanswered_attempts_threshold is the max outbound attempts before closing the entry
          // unanswered_attempts_threshold may be -1 which means entry is closed only on answered outgoing call, not on attempts threshols
          if (pendingGroupCaller.has(key)) { // do something only if there are pending calls
            const groupedCalls = pendingGroupCaller.get(key)!;

            // the total waitTime from first not answered call till the answered call
            const totalRingTime = groupedCalls.reduce((total, nextCall) => total + (nextCall.endTime!.getTime() - nextCall.startTime.getTime()), 0); // not-answered calls, so endTime - startTime = ringTime

            // move the groupedCall from pendingGroupCaller to groupCallerEntries
            if (call.answerTime || groupedCalls.length === monitorConfig.unanswered_attempts_threshold) {
              const groupedCallerEntry: UnansweredGroupCaller = {
                id: String(groupCallerEntries.length),
                callerNumber: call.destination!,
                lastCall: groupedCalls.at(-1)!.startTime.toLocaleTimeString("he-IL", { hour: "2-digit", minute: "2-digit" }),
                queueName: selfManagedGroups?.find(nextGroup => nextGroup.id === groupUuid)?.name ?? 'תור לא ידוע',
                callsCount: groupedCalls.length,
                totalRingTimeMs: totalRingTime,
                status: call.answerTime ? "טופל בשיחה יוצאת" : "חיוג חזרה נכשל",
                youngestCallStartTime: getYoungestCallDate(groupedCalls),
                oldestCallStartTime: getOldestCallDate(groupedCalls),
              }

              groupCallerEntries.push(groupedCallerEntry);
              pendingGroupCaller.delete(key);
            }
          }

          break;
        }
      }
    });
  // calls.forEach ended

  // for unclosed group + caller calls, create group entry טרם טופל
  pendingGroupCaller.forEach((groupedCalls, key) => {
    // calculate waitTime calls of group + caller
    const totalRingTime = groupedCalls.reduce((total, nextCall) => total + (nextCall.endTime!.getTime() - nextCall.startTime.getTime()), 0)

    groupCallerEntries.push({
      id: key,
      callerNumber: key.split(',')[1],
      lastCall: groupedCalls.at(-1)!.startTime.toLocaleTimeString("he-IL", { hour: "2-digit", minute: "2-digit" }),
      queueName: selfManagedGroups?.find(group => group.id === call_theGroupUuid(groupedCalls[0], selfManagedGroupUuids))?.name ?? 'תור לא ידוע',
      callsCount: groupedCalls.length,
      totalRingTimeMs: totalRingTime,
      status: "טרם טופל",
      youngestCallStartTime: getYoungestCallDate(groupedCalls),
      oldestCallStartTime: getOldestCallDate(groupedCalls),
    });
  })

  pendingGroupCaller.clear();

  return groupCallerEntries;
}