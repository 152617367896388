import React from 'react';
import { Tag } from '../../../design/tag/tag';
import { useMediaQuery } from '../../../layouts/app-layout/app-layout';
import { mobileQuery } from '../../../theme/media';
import { requestTabFilterStrings } from "../../../utils/strings/string-translator";
import { RequestStatusTab } from './request-status-tabs';
import styles from './filter-tag.module.scss';
import ButtonBar from '../../../design/button-bar/button-bar';

interface RenderTagsProps {
  clickedTag: number,
  onTagClick: (index: number) => void,
  counters: {
    total: number,
    waiting: number,
    active: number,
    completed: number,
    failed: number,
    canceled: number
  }
}

export const RenderTags = ({ clickedTag, onTagClick, counters }: RenderTagsProps) => {
  const isMobile = useMediaQuery(mobileQuery);


  if (counters) {
    const getCounter = (status: string) => {
      switch (status) {
        case "all":
          return counters.total;
        case "open":
          return counters.waiting + counters.active;
        case "completed":
          return counters.completed;
        case "canceled":
          return counters.canceled;
        case "failed":
          return counters.failed;
        default:
          return 0;
      }
    }

    const filters = [
      { key: "all", text: `${requestTabFilterStrings.get("all")} ${counters.total}` },
      { key: "open", text: `${requestTabFilterStrings.get("open")} ${counters.waiting + counters.active}` },
      { key: "completed", text: `${requestTabFilterStrings.get("completed")} ${counters.completed}` },
      { key: "canceled", text: `${requestTabFilterStrings.get("canceled")} ${counters?.canceled}` },
      { key: "failed", text: `${requestTabFilterStrings.get("failed")} ${counters.failed}` }
    ];

    const tags = filters.map((filter, index) => {
      const determineColor = clickedTag === index ? styles.clickedTag : styles.tag;

      return {
        id: index.toString(),
        component: (
          <Tag
            key={filter.text}
            label={filter.text}
            variant="filled"
            color="neutral"
            className={determineColor}
          />
        )
      }
    })

    return !isMobile ? (
      <ButtonBar
        buttons={tags}
        className={styles.tag_list}
        toggleBar={true}
        onToggle={id => onTagClick(parseInt(id))}
        selectedButtonId={clickedTag.toString()}
      />
    ) : (
      <div className={styles.tag_list}>
        {
          filters.map((tagDetails: { key: string, text: string }, i: number) => (
            <RequestStatusTab
              active={clickedTag === i}
              key={tagDetails.text}
              title={String(requestTabFilterStrings.get(tagDetails.key))}
              onClick={() => onTagClick(i)}
              counter={getCounter(tagDetails.key)}
            />
          )
          )
        }
      </div>
    )
  }
  return (<></>)
}