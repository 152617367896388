interface Texts {
  CHECKBOX_LABEL: string;
  ROOT_NODE_TITLE: string;
  MEDIA_URL_LABEL: string;
  MENU_ACTION_ADD_MEDIA: string;
  MENU_ACTION_REMOVE_NODE: string;
  MEDIA_DIALOG_TITLE: string;
  WHATSAPP_UPLOAD_FILE_TITLE: string;
  WHATSAPP_COORDINATE_TITLE: string;
  WHATSAPP_FILE_INPUT_PLACEHOLDER: string;
  WHATSAPP_COORDINATE_INPUT_PLACEHOLDER: string;
  SELECT_MEDIA_LABEL: string;
  MENU_ACTION_DUPLICATE_NODE: string;
  ROOT_NODE_PLACEHOLDER: string;
  NODE_INPUT_PLACEHOLDER: string;
  ADD_OPTIONS_BUTTON_LABEL: string;
  WHATSAPP_FILE_URL_LABEL: string;
  WHATSAPP_COORDINATE_LABEL: string;
}

export const texts: Texts = {
  CHECKBOX_LABEL: 'הצג כפתור חזרה לתפריט הראשי',
  ROOT_NODE_TITLE: 'הודעת פתיחה',
  MEDIA_URL_LABEL: 'קישור למדיה',
  MEDIA_DIALOG_TITLE: 'העלאת קבצים',
  SELECT_MEDIA_LABEL: 'הוספת קובץ',
  MENU_ACTION_ADD_MEDIA: 'הוספת קובץ',
  MENU_ACTION_DUPLICATE_NODE: 'שכפול',
  MENU_ACTION_REMOVE_NODE: 'מחיקה',
  ROOT_NODE_PLACEHOLDER: 'הכנס טקסט כאן לדוגמא: שלום! אני הבוט החכם של מחלקת התמיכה. באיזה נושא אתם זקוקים לתמיכה?',
  ADD_OPTIONS_BUTTON_LABEL: 'הוספת אפשרויות',
  NODE_INPUT_PLACEHOLDER: 'הטקסט שלך כאן',
  WHATSAPP_UPLOAD_FILE_TITLE: 'העלאת קבצים',
  WHATSAPP_COORDINATE_TITLE: 'הוספת מיקום',
  WHATSAPP_FILE_URL_LABEL: 'הטמעת קובץ מתוך מקישור:',
  WHATSAPP_COORDINATE_LABEL: 'הכניסו קורדינטות',
  WHATSAPP_FILE_INPUT_PLACEHOLDER: 'הכניסו כאן לינק לקובץ',
  WHATSAPP_COORDINATE_INPUT_PLACEHOLDER: 'קו אורך, קו רוחב',
};
