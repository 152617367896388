import { CallAttributes } from "../interfaces";


function getCallsByDirection(calls?: CallAttributes[] | undefined, direction?: 'inbound' | 'outbound' | 'local') {
  if (!calls) return [];
  if (!direction) {
    return calls;
  }
  
  return calls.filter(call => call.callDirection === direction);
}

export function getInboundCalls(calls?: CallAttributes[] | undefined): CallAttributes[] {
  return getCallsByDirection(calls, 'inbound');
}

export function getOutboundCalls(calls?: CallAttributes[] | undefined): CallAttributes[] {
  return getCallsByDirection(calls, 'outbound');
}

export function waitingCallsFilter(call: CallAttributes): boolean {
  return (call.callDirection === 'inbound' || call.callDirection === "unknown") && !call.endTime && !call.answerTime;
}

export function getWaitingCalls(calls?: CallAttributes[]): CallAttributes[] {
  if (!calls?.length) return [];
  return calls?.filter(waitingCallsFilter);
}