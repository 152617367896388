import { useCallback, useState } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';

import { useData } from './useData';
import { SnackbarVariant } from '../theme/muiTypes';
import { HandleFieldEvent } from '../formConfig/types';
import { isFileField } from '../callbacks/utils/getFieldDetails';
import { FileType, InputFileMode, RequestMetaData, RequestMethod } from '../globalTypes/types';
import { handleFileFieldChange } from '../callbacks/utils/handleFileField';
import { getFieldNames } from '../callbacks/utils/getFieldNames';
import { CALLBACK_FORM_FIELDS_CONFIG } from '../callbacks/formConfig/callbackDialerFormConfig';
import {
  HandleRemoveFile,
  CallbackFieldName,
  FieldSavingStatus,
  ICallbackDialerForm,
  HandleRemoveDynamicValue,
} from '../callbacks/types';
import {
  processFieldKey,
  isSameFieldValue,
  processFieldValue,
  getDynamicFieldValues,
} from '../callbacks/utils/processFormField';

const { report_type } = CALLBACK_FORM_FIELDS_CONFIG;

interface FormFieldHandlers {
  fieldSavingStatus: FieldSavingStatus;
  handleRemoveFile: HandleRemoveFile;
  handleFieldEvent: HandleFieldEvent<FieldValues>;
  setFieldSavingStatus: (fieldUpdatingState: FieldSavingStatus) => void;
  handleRemoveDynamicValue: HandleRemoveDynamicValue;
}

export const useFormFieldHandler = (props: RequestMetaData): FormFieldHandlers => {
  const [fieldSavingStatus, setFieldSavingStatus] = useState<FieldSavingStatus>({
    key: '',
    isOpen: false,
    variant: SnackbarVariant.info,
  });
  const { trigger, setValue, getValues } = useFormContext();
  const { data, isLoading, apiDataHandler } = useData(props);

  const handleFieldSavingStatus = useCallback(
    (fieldName: CallbackFieldName, availableToUpdate: boolean, response: any) => {
      if (!availableToUpdate) {
        setFieldSavingStatus({ isOpen: true, key: fieldName, variant: SnackbarVariant.warning });
      } else if (response) {
        setFieldSavingStatus({ isOpen: true, key: fieldName, variant: SnackbarVariant.success });
      } else {
        setFieldSavingStatus({ isOpen: true, key: fieldName, variant: SnackbarVariant.error });
      }
    },
    []
  );

  const handleFieldEvent: HandleFieldEvent<FieldValues> = useCallback(
    async (event, controllerState) => {
      const eventType = event.type;
      const target = event.target as HTMLInputElement;

      const formValues = getValues() as ICallbackDialerForm;
      if (eventType === 'change' || eventType === 'click') {
        controllerState.field.onChange(event);
      } else if (eventType === 'blur') {
        controllerState.field.onBlur();
      }

      const { fieldName, fieldKey } = processFieldKey(event);
      const fieldValue = processFieldValue({ fieldName, fieldKey, target, formValues, setValue });
      const isValid = await trigger(fieldName);
      const availableToUpdate: boolean = isValid && data && !isLoading;
      const sameFieldValue = isSameFieldValue(fieldValue, data[fieldName]);

      if (sameFieldValue) return;

      if (!availableToUpdate && controllerState.fieldState.isTouched) {
        handleFieldSavingStatus(fieldName, availableToUpdate, undefined);
      }

      if (availableToUpdate && fieldName in data) {
        const newData = { [fieldName]: fieldValue };
        const response = await apiDataHandler({ method: RequestMethod.PATCH, newData, shouldUseOptimistic: true });
        handleFieldSavingStatus(fieldName, isValid, response);
      }

      if (availableToUpdate && fieldName === report_type.name) {
        const response = await apiDataHandler({
          method: RequestMethod.PATCH,
          newData: fieldValue,
          shouldUseOptimistic: true,
        });
        handleFieldSavingStatus(fieldName, availableToUpdate, response);
      }

      if (isFileField(fieldName)) {
        handleFileFieldChange({ fieldName, fieldValue, availableToUpdate, apiDataHandler, handleFieldSavingStatus });
      }
    },
    [apiDataHandler, data, getValues, handleFieldSavingStatus, isLoading, setValue, trigger]
  );

  const handleRemoveDynamicValue: HandleRemoveDynamicValue = useCallback(
    async (fieldName: CallbackFieldName, fieldValueList) => {
      const fieldConfigValues = Object.values(CALLBACK_FORM_FIELDS_CONFIG);
      const dynamicFieldNames = getFieldNames(fieldConfigValues);
      const dynamicFieldValues = getDynamicFieldValues(fieldName, fieldValueList);
      const sameFieldValue = isSameFieldValue(dynamicFieldValues, data[fieldName]);
      const isValid = await trigger(fieldName);

      if (sameFieldValue) return;

      if (!isValid) {
        setFieldSavingStatus({ isOpen: true, key: fieldName, variant: SnackbarVariant.warning });
        return;
      }

      if (dynamicFieldNames.includes(fieldName) && isValid && data && !isLoading) {
        const newData = { [fieldName]: dynamicFieldValues };
        setFieldSavingStatus({ isOpen: true, key: fieldName, variant: SnackbarVariant.success });
        try {
          await apiDataHandler({ method: RequestMethod.PATCH, newData, shouldUseOptimistic: true });
        } catch (error) {
          setFieldSavingStatus({ isOpen: true, key: fieldName, variant: SnackbarVariant.error });
        }
      }
    },
    [apiDataHandler, data, isLoading, trigger]
  );

  const handleRemoveFile: HandleRemoveFile = useCallback(
    async (fieldName: CallbackFieldName) => {
      const fieldConfigValues = Object.values(CALLBACK_FORM_FIELDS_CONFIG);
      const fileFieldNames = getFieldNames(fieldConfigValues);
      const isValid = await trigger(fieldName);

      if (fileFieldNames.includes(fieldName)) {
        setValue(fieldName, {
          file: null,
          file_name: '',
          file_url: '',
          file_type: FileType.audio,
          file_mode: InputFileMode.ADD,
        });
        return;
      }

      if (!isValid) {
        setFieldSavingStatus({ isOpen: true, key: fieldName, variant: SnackbarVariant.warning });
        return;
      }
    },
    [setValue, trigger]
  );

  return {
    fieldSavingStatus,
    handleFieldEvent,
    handleRemoveFile,
    setFieldSavingStatus,
    handleRemoveDynamicValue,
  };
};
