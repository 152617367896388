import React, { SVGAttributes } from 'react';
import classNames from 'classnames';
import styles from './icon.module.scss';

export const Icon = ({ className, size=24, ...props }: IconProps) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    className={classNames(styles.icon, className)}
    {...props}
  />
);

export interface IconProps extends SVGAttributes<SVGSVGElement> {
  size?: 16 | 20 | 24 | 28 | 32 | 40 | 48;
  accent?: string;
}
