import { Auth, Hub } from 'aws-amplify';
import { ReactElement, useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { authenticatedState, hadshanutUserState } from '../state';
import moment from 'moment';
import mixpanel from 'mixpanel-browser';
import { useMe } from '../api/use-me';

const DEFAULT_DOMAIN = '00000000-0000-0000-0000-00000000abba';

export function AuthRoot({ children }: AuthRootProps) {
  const [loading, setLoading] = useState(true);
  const [domainlessUser, setDomainlessUser] = useState(false);

  const { data: hadshanutUser } = useMe();
  const setHadshanutUser = useSetRecoilState(hadshanutUserState);
  const [authenticated, setAuthenticated] = useRecoilState(authenticatedState);

  useEffect(() => {
    async function getUser() {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user.attributes['custom:domain_uuid'] === DEFAULT_DOMAIN) {
          setDomainlessUser(true);
          throw Error('USER HAS NO DOMAINS');
        }
        setAuthenticated(true);
      } catch (error: any) {
        console.error(error);
        setAuthenticated(false);
      } finally {
        setLoading(false);
      }
    }

    getUser();
  }, [setAuthenticated]);

  useEffect(() => {
    if (authenticated && hadshanutUser) {
      setHadshanutUser(hadshanutUser);
    } else {
      setHadshanutUser(undefined);
    }
  }, [hadshanutUser, authenticated, setHadshanutUser]);

  useEffect(() => {
    function listener(data: any) {
      switch (data.payload.event) {
        case 'signIn':
          setAuthenticated(true);
          const now = moment().toISOString();
          mixpanel.people.set_once('Activated At', now);
          mixpanel.people.set('Last Login', now);
          window.location.reload(); // Solves an issue where previous logged-in user info is shown
          break;
        case 'signOut':
          setAuthenticated(false);
      }
    }
    const res = Hub.listen('auth', listener);

    return () => {
      Hub.remove('auth', listener);
      // res();
    };
  }, [setAuthenticated]);

  if (loading) {
    return <div>טוען...</div>;
  }

  if (domainlessUser) {
    return (
      <span style={{ width: '100%', textAlign: 'center', fontWeight: 'bold' }}>
        <p>משתמש לא משוייך לאף דומיין, אנא פנה למוקד התמיכה בטלפון: </p>
        <p style={{ direction: 'ltr' }}>*8182</p>
      </span>
    );
  }

  return children;
}

export interface AuthRootProps {
  children: ReactElement;
}
