import { API } from "aws-amplify";
import mixpanel from "mixpanel-browser";
import { useContext, useState } from "react";
import { useExtensions } from "../../api/use-extensions";
import { useMe } from "../../api/use-me";
import { Dialog } from "../../design/dialog/dialog";
import { Field } from "../../design/form/field";
import { Input } from "../../design/form/input/input";
import { activeExtensionContext } from "../active-extensions-context";

export const ExtensionsNameDialog = ({ dialog }: any) => {
  const [activeExtension, setActiveExtension] = useContext(activeExtensionContext);
  const [loading, setLoading] = useState(false);
  const user = useMe();
  const { mutate } = useExtensions();

  const handleSubmit = async (values: { name: string }) => {
    try {
      setLoading(true);
      const body = {
        labelId: activeExtension?.id,
        label: values.name,
      };
      await API.put(
        process.env.REACT_APP_WEB_API_NAME!,
        `/domain/${user?.data?.domain.id}/extension/${activeExtension?.destinationNumber}/name`,
        { body }
      );
      mixpanel.track("Create Extension Name", {
        "Destination Type": values.name,
      });
      await mutate();
      dialog.hide();
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    setActiveExtension(undefined);
  };

  return (
    <Dialog
      {...dialog}
      variant='form'
      header={`עריכת שם שלוחה - (${activeExtension?.destinationNumber})`}
      initialValues={{ name: activeExtension?.description }}
      onSubmit={handleSubmit}
      submitLabel='שמירת שם'
      onClose={onClose}
      loading={loading}
      aria-label='שינוי שם שלוחה'
    >
      <Field name='name' label='שם שלוחה'>
        <Input type='text' placeholder='למשל, מארחת בוקר' />
      </Field>
    </Dialog>
  );
};
