import styles from './agents-status-sums.module.scss';

export const AgentsStatusSums = ({ agentsStatus }: AgentsStatusSumsProps) => {
  return (
    <section className={styles.agentStatusSums}>
      {
        agentsStatus.map(status => <Status key={status.label} label={status.label} count={status.count} color={status.color} />)
      }
    </section>
  )
}

const Status = ({ label, count, color }: Status) => (
  <div className={styles.statusContainer}>

    <div className={styles.colorRect} style={{backgroundColor: color}} />

    <div className={styles.statusLabel}>
      <div className={styles.countLabel}>
        {label}
      </div>
      <div className={styles.countData}>
        {count}
      </div>
    </div>
  </div>
);

interface Status {
  label: string
  count: number
  color: string
}

interface AgentsStatusSumsProps {
  agentsStatus: Status[]
}