import classNames from "classnames";
import { ForwardedRef, InputHTMLAttributes, forwardRef } from "react";
import styles from "./input.module.scss";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  ltr?: boolean;
  ref?: ForwardedRef<HTMLInputElement>;
}

export const Input = forwardRef(
  ({ ltr = false, className, ...props }: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
    return (
      <input
        ref={ref}
        className={classNames(
          {
            [styles.root]: true,
            [styles["ltr-value"]]: ltr,
          },
          className
        )}
        {...props}
      />
    );
  }
);
