import { LinearProgress, styled } from '@mui/material';

import { SnackbarVariant } from '../../../theme/muiTypes';

interface LinearProgressProps {
  severity: SnackbarVariant;
}

export const StyledLinearProgressBar = styled(LinearProgress)<LinearProgressProps>(({ theme, severity }) => ({
  '&.MuiLinearProgress-root': {
    width: '100%',
    height: '4px',
    borderRadius: 10,
    backgroundColor: 'transparent',

    '& .MuiLinearProgress-bar': {
      backgroundColor: theme.palette[severity].main,
      borderRadius: 10,
    },
  },
}));
