import { GroupResource } from "../../api/types";
import { User } from "../../api/use-users";

export const getSelfManagedGroups = (groupedData?: Record<string, GroupResource[]>, multiTenantUser?: User): Record<string, GroupResource[]> => {
  if (!groupedData || !multiTenantUser || Object.keys(groupedData).length === 0) {
    return ({});
  }
  const domainsSelfManagedGroups = Object.fromEntries(multiTenantUser.domains.map(domain => [domain.domain_uuid, domain.domain_self_managed_groups || []]));
  const entries = Object.entries(groupedData);
  const filteredEntries = entries.map(([domain, groups]) => [domain, groups.filter(group => domainsSelfManagedGroups[domain].includes(group.id))])
  return Object.fromEntries(filteredEntries);
}