import { ActionType, CallAttributes } from "../interfaces";

interface CallAction {
  type: ActionType;
  call: CallAttributes;
}

export function callsReducer(state: CallAttributes[], action: CallAction): CallAttributes[] {
  const { type, call: newCall } = action;
  let result: CallAttributes[];

  switch (type) {
    case ActionType.POST: // usually POST doesn't happen because when the call is created, the CtiServer doesn't know yet if it's inbound/outbound/local so it's not sent to the relate-app
      if (state.find(call => call.id === newCall.id)) {
        result = state;
      } else {
        result = [...state, newCall];
      }
      break;

    case ActionType.DELETE:
      result =  state.filter(call => call.id !== newCall.id);
      break;

    case ActionType.PUT:
      const oldCall = state.find(call => call.id === newCall.id);
      if (!oldCall) { // add call in case it's first seen with PUT because had no POST. see comment above at POST
        result = [...state, action.call];
      } else {
        result = state.filter(call => call.id !== newCall.id).concat([{...oldCall, ...newCall}]);
      }
      break;

    case ActionType.CLEAR:
      result = [];
      break;

    default:
      result = state;
      break;
  }

  return result;
}