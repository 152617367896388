import { API } from "aws-amplify";
import { Callee } from "./dialers";
import { getIpracticomAccessToken } from "../auth/server-auth";

export const exportCallbackReport = async (filters?: { [key: string]: string }) => {
  const paramsWhitelist = [
    "domain_uuid",
    "agent_group_numbers",
    "dialer_ids",
    "from_date",
    "to_date",
    "from_age", // minutes
    "to_age",    // minutes
    "return_number", // the server removes non-digit and adds % at start and end. searches also on caller_number
    "attempts",
    "page",
    "status_per_day",
  ];

  // get only params that are present in whitelist
  const filteredParams = Object.entries(filters || {}).filter(([param]) => paramsWhitelist.includes(param));
  const endpoint = '/export_callback_report?';
  const fileName = filters ? `ReportCallbacks_${filters["from_date"]}-${filters["to_date"]}.xlsx` : "ReportCallbacks.xlsx";

  await downloadReport(filteredParams, endpoint, fileName);

}

export const exportCdrReport = async (filters?: { [key: string]: string }) => {
  const paramsWhitelist = [
    "from",
    "to",
    "search",
    "type",
    "extension",
    "maxDuration",
    "maxTta",
    "minDuration",
    "minTta",
    "status",
    "page",
    "businessNum",
    "extensionsPerDomain",
    "groupBy",
    "groupKey"
  ];
  // get only params that are present in whitelist
  const filteredParams = Object.entries(filters || {}).filter(([param]) => paramsWhitelist.includes(param));
  const endpoint = '/export_cdr_report?';
  const fileName = filters ? `ReportCdrs${filters["from"]}-${filters["to"]}.xlsx` : "ReportCallbacks.xlsx";
  await downloadReport(filteredParams, endpoint, fileName);
}

export const exportEventsHistoryReport = async (filters?: { [key: string]: string }) => {
  const paramsWhitelist = [
    "from",
    "to",
    "dial_groups",
    "type",
    "status",
    "launchers"
  ];
  // get only params that are present in whitelist
  const filteredParams = Object.entries(filters || {}).filter(([param]) => paramsWhitelist.includes(param));
  const endpoint = '/export_dialer_event_report?';
  const fileName = filters ? `Events-${filters["from"]}-${filters["to"]}.xlsx` : "Events.xlsx";
  await downloadReport(filteredParams, endpoint, fileName);
}

export const exportEventRequestsReport = async (filters: { [key: string]: string }, data: Partial<Callee>[]) => {
  data = data.filter(callee => callee.enabled);

  const paramsWhitelist = [
    "job_titles",
    "status",
    "dial_attempts",
    "response_type",
    "event_number"
  ];
  
  // get only params that are present in whitelist
  const filteredParams = Object.entries(filters || {}).filter(([param]) => paramsWhitelist.includes(param));
  const endpoint = '/export_event_requests_report?';
  const fileName = filters ? `Event ${filters['event_number']} details.xlsx` : "Event details.xlsx";
  await downloadReport(filteredParams, endpoint, fileName, data);
}

export const exportCallsSummaryReport = async (filters?: { [key: string]: string }) => {
  const paramsWhitelist = [
    "from",
    "to",
    "fromTime",
    "toTime",
    "search",
    "extension",
    "maxDuration",
    "maxTta",
    "minDuration",
    "minTta",
    "page",
    "extensionsPerDomain",
    "membersPerDomain",
    "groupBy",
    "groupKey"
  ];
  // get only params that are present in whitelist
  const filteredParams = Object.entries(filters || {}).filter(([param]) => paramsWhitelist.includes(param));
  const endpoint = '/export_calls_summary_report?';
  const fileName = filters ? `Calls-Summary-${filters["from"]}-${filters["to"]}.xlsx` : "Calls-Summary.xlsx";
  await downloadReport(filteredParams, endpoint, fileName);
}

export const exportSmsBillingReport = async (filters: Record<string, string>) => {
  const token = await getIpracticomAccessToken();
  const paramsWhitelist = [
    "fromDate",
    "toDate",
    "search"
  ];
  // get only params that are present in whitelist
  const filteredParams = Object
    .entries(filters || {})
    .filter(([param]) => paramsWhitelist.includes(param));
  const endpoint = '/export_sms_billing_report?';
  const fileName = filters ? `SmsBilling${filters["fromDate"]}-${filters["toDate"]}.xlsx` : "SmsBilling.xlsx";
  await downloadReport(filteredParams, endpoint, fileName, { token });
}

const downloadReport = async (filteredParams: [string, string][], endpoint: string, fileName: string, data?: any) => {
  // reduce all params to a single string, param values are comma (,) separated and params are ampersand (&) separated
  // (eg. "domain_uuid=abc123&agent_group_numbers=400,500")
  const queryString = filteredParams.map(([param, value]) => `${param}=${value}`).join('&');
  const finalPath = endpoint + queryString;
  let res;
  if (data) {
    res = await API.post(
      process.env.REACT_APP_EXPORT_REPORT_API_NAME!,
      finalPath,
      {
        responseType: 'blob',
        body: data
      }
    );
  } else {
    res = await API.get(
      process.env.REACT_APP_EXPORT_REPORT_API_NAME!,
      finalPath,
      {
        responseType: 'blob'
      }
    );
  }
  const url = window.URL.createObjectURL(res);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
}
