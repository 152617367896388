import * as Yup from 'yup';
import { useState } from 'react';
import { DialogStateReturn } from 'reakit';
import { useNavigate } from 'react-router-dom';

import { Field } from '../../design/form/field';
import { CallbackDialer } from '../../api/dialers';
import { Dialog } from '../../design/dialog/dialog';
import { Input } from '../../design/form/input/input';
import { RequestData, RequestMethod } from '../../globalTypes/types';
import { ErrorDialog } from '../../design/dialog/error-dialog/error-dialog';
import styles from '../../emergency-dialer/dial-groups/dial-groups-page.module.scss';

const ERROR_MESSAGE = 'לא הצלחנו לייצר את חייגן שיחה חוזרת בשבילך';

interface CreateCallbackDialerDialogProps {
  dialog: DialogStateReturn;
  apiDataHandler: ({ method, newData, shouldUseOptimistic }: RequestData) => Promise<any>;
  defaultDialerData: CallbackDialer | undefined;
  mutateCallbackDialers: () => void;
}

export default function CreateCallbackDialerDialog({
  dialog,
  apiDataHandler,
  defaultDialerData,
  mutateCallbackDialers,
}: CreateCallbackDialerDialogProps) {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  let defaultDialer: Omit<CallbackDialer, 'dialer_id' | 'domain_uuid'>;

  if (defaultDialerData) {
    const { dialer_id, domain_uuid, ...rest } = defaultDialerData;
    defaultDialer = rest;
  }

  const handleSubmit = async (values: Record<string, any>) => {
    try {
      setLoading(true);
      const newData = { ...defaultDialer, name: values.name };
      const res = await apiDataHandler({ method: RequestMethod.POST, newData, shouldUseOptimistic: false });
      navigate(`/callback-dialer/dialers/${res.dialer_id}`);
      mutateCallbackDialers();
      dialog.hide();
    } catch (error: any) {
      setError(true);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const forwardCallsFormSchema: Yup.Schema = Yup.object().shape({
    name: Yup.string()
      .min(4, 'על שם חייגן שיחה חוזרת להיות לפחות באורך 4 תווים')
      .max(64, 'על שם חייגן שיחה חוזרת להיות באורך עד 64 תווים')
      .required('נא להזין שם לחייגן שיחה חוזרת'),
  });

  if (error) {
    return <ErrorDialog dialog={dialog} mainMessage={ERROR_MESSAGE} onClose={() => setError(false)} />;
  }

  return (
    <Dialog
      {...dialog}
      variant='form'
      header={'יצירת חייגן שיחה חוזרת'}
      onSubmit={handleSubmit}
      initialValues={{
        name: '',
      }}
      validationSchema={forwardCallsFormSchema}
      submitLabel={'יצירת חייגן שיחה חוזרת'}
      cancelLabel={'ביטול'}
      hideOnClickOutside={false}
      loading={loading}
      aria-label={'create-callback-dialer'}
      onClose={() => setError(false)}
    >
      <div className={styles.name}>
        <div className={styles.fieldTitle}>שם חייגן שיחה חוזרת</div>
        <Field name='name'>
          <Input type='text' placeholder='למשל: חייגן חיוג חוזר' />
        </Field>
        {error ? <div className={styles.error}>{error}</div> : undefined}
      </div>
    </Dialog>
  );
}
