import { Button } from "../../../design/button"
import { Password } from "../../../design/icons/password"
import { Remove } from "../../../design/icons/remove"
import { Edit } from "../../../design/icons/edit"
import styles from "./user-actions-menu.module.scss"

export const UserActionsMenu = ({ onEditNameEmailClick, onResetPasswordClick, onRemoveUserClick }: ExtensionMenuProps) => (
  <>
    <div onClick={onEditNameEmailClick} className={styles.menuAction}>
      עריכת שם ומייל
      <Edit size={24} />
    </div>
    <div onClick={onResetPasswordClick} className={styles.menuAction}>
      איפוס סיסמה
      <Password size={24} />
    </div>
    <div onClick={onRemoveUserClick} className={styles.menuAction}>
      <span className={styles.cancelAction}>הסרת משתמש מדומיין</span>
      <Remove size={24} />
    </div >
  </>
)

interface ExtensionMenuProps {
  onEditNameEmailClick: () => void,
  onResetPasswordClick: () => void,
  onRemoveUserClick: () => void,
}