import { useMemo } from 'react';

import { useData } from '../../hooks/useData';

export const useCallbackDialer = (dialerId: number, shouldFetch = true) => {
  const endpointURL = `/callback-dialer?dialer_id=${dialerId}`;
  const apiName = process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!;

  const {
    data: dialerData,
    error,
    mutate,
    isLoading,
    apiDataHandler,
  } = useData({
    apiName,
    endpointURL,
    shouldFetch,
  });

  const apiConfig = useMemo(
    () => ({
      apiName,
      endpointURL,
      shouldFetch,
    }),
    [apiName, endpointURL, shouldFetch]
  );

  return {
    error,
    mutate,
    isLoading,
    apiConfig,
    dialerData,
    apiDataHandler,
  };
};
