import { UserResource } from "../../api/types"
import { ButtonWithDropdown } from "../../design/button-with-dropdown/button-with-dropdown"
import { ArrowDropDown } from "../../design/icons/arrow-drop-down"
import { DomainSelection as DomainSelection } from "../domain-selection"
import styles from "./domain-selection-desktop.module.scss"

export const DomainSelectionDesktop = ({ user }: DomainSelectionProps) => {
  return (
    <div className={styles.userAreaDesktop}>
      <div className={styles.userInfo}>
        <div>
          <div className={styles.organization}> {user?.organization.name} </div>
          <div className={styles.userName}> {user?.name} </div>
        </div>
      </div>
      <ButtonWithDropdown
        isSticky={true}
        className={styles.dropDownWrapper}
        buttonClassName={styles.userActions}
        menuClassName={styles.dropdownMenu}
        collapsedBadge={<ArrowDropDown size={24} />}
        expandedBadge={<ArrowDropDown size={24} className={styles.expandedBadge} />}
      >
        <DomainSelection user={user} />
      </ButtonWithDropdown>
    </div>
  )
}

interface DomainSelectionProps {
  user: UserResource | undefined
}