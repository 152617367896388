import { createContext, useState } from "react";
import { DialGroup } from "../../api/dialers";


export const DialGroupContext = createContext<[DialGroup | undefined, React.Dispatch<React.SetStateAction<DialGroup | undefined>>]>([
  undefined, () => undefined 
]);

export const DialGroupContextProvider = ({ children }: { children: React.ReactNode }) => {
  const ctx = useState<DialGroup | undefined>(undefined);
  return (
    <DialGroupContext.Provider value={ctx}>
      {children}
    </DialGroupContext.Provider>
  )
}