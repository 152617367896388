import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDialogState } from 'reakit';
import { useMediaQuery } from '../../../layouts/app-layout/app-layout';
import { Calendar } from '../../calendar/calendar';
import { Dialog } from '../../dialog/dialog';
import { Input } from '../input/input';

export function DateInput({
  dateToDisplayValue,
  onChange,
  value: initialValue,
  calendarDialogTitle,
  ...props
}: DateInputProps) {
  const dialog = useDialogState();
  const [value, setValue] = useState<string>(initialValue || '');
  const [displayValue, setDisplayValue] = useState<any>(
    dateToDisplayValue && initialValue ? dateToDisplayValue(new Date(initialValue)) : undefined
  );
  const isMobile = useMediaQuery('(hover: none) and (max-width: 991px)');

  const handleFocus = () => {
    dialog.show();
  };

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue);
      setDisplayValue(moment(initialValue).format('DD/MM/YYYY'));
    }
  }, [initialValue]);

  return isMobile ? (
    <Input
      type='date'
      onChange={(event) => {
        setValue(event.target.value);
        if (onChange) onChange(event.target.value);
      }}
      {...props}
      value={value}
    />
  ) : (
    <>
      {!dialog.visible && (
        <>
          <Input type='hidden' value={value} {...props} />
          <Input readOnly type='text' value={displayValue} onFocus={handleFocus} />
        </>
      )}
      <Dialog {...dialog} variant='info' header={calendarDialogTitle} aria-label='לוח שנה'>
        <Calendar
          value={initialValue ? new Date(initialValue) : null}
          onChange={(date: Date, event: ChangeEvent) => {
            dialog.hide();
            const dateValue = moment(date).format('YYYY-MM-DD');
            const displayValue = dateToDisplayValue ? dateToDisplayValue(date) : date;
            setDisplayValue(displayValue);
            setValue(dateValue);
            if (onChange) onChange(dateValue);
          }}
        />
      </Dialog>
    </>
  );
}

export interface DateInputProps {
  name?: string;
  dateToDisplayValue?: (date: Date) => string;
  onChange?: (value: string) => void;
  value?: string;
  calendarDialogTitle?: string;
}
