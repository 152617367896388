import { ChangeEvent, forwardRef } from 'react';

import { UploadFile } from './UploadFile';
import { ButtonVariant, Color } from '../../../theme/muiTypes';
import { FileFormat, FileType } from '../../../globalTypes/types';
import { AGENT_DIALING_SETTINGS_TEXTS } from '../../../callbacks/utils/texts';
import { StyledUploadFileContainer, StyledUploadFileSubTitle, StyledUploadFileTitle } from './StyledFileField';

const { FILE_SIZE_LIMIT, UPLOAD_NEW_IVR_FILE } = AGENT_DIALING_SETTINGS_TEXTS;

interface AddFileFieldProps {
  file_type: FileType;
  file_format: FileFormat | null;
  selectFileBtnText: string;
  supported_formats?: FileFormat[];
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const AddFileField = forwardRef<HTMLInputElement, AddFileFieldProps>(({ ...props }, ref) => {
  const { selectFileBtnText, ...rest } = props;
  return (
    <StyledUploadFileContainer>
      <StyledUploadFileTitle>{UPLOAD_NEW_IVR_FILE}</StyledUploadFileTitle>
      <StyledUploadFileSubTitle>{FILE_SIZE_LIMIT}</StyledUploadFileSubTitle>
      <UploadFile
        selectFileBtnText={selectFileBtnText}
        btnVariant={ButtonVariant.contained}
        btnColor={Color.secondary}
        {...rest}
      />
    </StyledUploadFileContainer>
  );
});
