import { MonitorProps } from '..';
import { Button } from '../../design/button';
import { ArrowRight } from '../../design/icons';
import { AppLayoutBreadcrumbLink, AppLayoutBreadcrumbs } from '../../layouts/app-layout/app-layout';
import { TabTitle } from '../common/components/tables-container/tables-container';
import { AgentsPage } from './agents/agents-page';
import { InboundCallsPage } from './inbound-calls/inbound-calls-page';
import { MainPage } from './main/main-page';
import styles from './main/main-page.module.scss';
import { OutboundCallsPage } from './outbound-calls-page/OutboundCallsPage';
import { UnansweredCallsPage } from './unanswered-calls-page/UnansweredCallsPage';
import { WaitingCallsPage } from './waiting-calls-page/waiting-calls-page';

export interface MobileMonitorProps extends MonitorProps {
  activeTab: TabTitle | 'main';
  setCurrentPage: (tab: TabTitle | 'main') => void;
  onBackBtnClick: () => void;
}

export const MobileMonitor: React.FC<MobileMonitorProps> = ({ ...props }) => {
  
  const pages: Record<TabTitle | 'main', any> = {
    "main": <MainPage {...props} onCardClick={props.setCurrentPage} />,
    "waiting-calls": <WaitingCallsPage {...props} />,
    "agents": <AgentsPage {...props} />,
    'inbound-calls': <InboundCallsPage {...props} />,
    'unanswered-calls': <UnansweredCallsPage {...props} />,
    'outbound-calls': <OutboundCallsPage {...props} />,
    'callback-requests': undefined,
    "calls": undefined,
  }

  return pages[props.activeTab];
  
}

export const PageHeader: React.FC<{ name: string, onBackBtnClick: () => void; }> = ({ name, onBackBtnClick }) => {

  return (
    <div className={styles.backToHadshanutButton}>
      <Button style={{ padding: 0 }} onClick={onBackBtnClick}><ArrowRight /></Button>

      <AppLayoutBreadcrumbs style={{ margin: '0' }}>
        <AppLayoutBreadcrumbLink to="/monitor" onClick={onBackBtnClick} >ראשי</AppLayoutBreadcrumbLink>
        <AppLayoutBreadcrumbLink to="#">{ name }</AppLayoutBreadcrumbLink>
      </AppLayoutBreadcrumbs>

    </div>
  )
}