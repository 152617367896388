export const TEXTS = {
	ERROR_TITLE: 'לא הצלחנו לטעון את העמוד מסיבה לא ידועה',
	ERROR_CONTENT_TEXT: 'נסה/י לטעון מחדש. אם הבעיה חוזרת, אנא פנה/י לתמיכה הטכנית',
	ERROR_CONTENT_SUPPORT_PHONE: '03-657-6576',
	ERROR_CONTENT_SUPPORT_EMAIL: 'support@ip-com.co.il',
	ERROR_CONTENT_SUPPORT_TEXT: ' או	בטלפון: ',
	ERROR_ACTION: 'טען/י מחדש',
	TITLE: 'היסטוריית שיחות',
	EXPORT: 'ייצוא לאקסל',
	SEARCH_PLACEHOLDER: 'חיפוש מספר טלפון...',
	UPDATE: 'עדכון',
	START_DATE_PLACEHOLDER: 'מתאריך',
	END_DATE_PLACEHOLDER: 'עד תאריך',
	STATUS: 'סטטוס',
	ANSWERED: 'נענתה',
	NOT_ANSWER: 'לא נענתה',
	VOICE_MAIL: 'תא קולי',
	WAITING_FOR_ANSWER: 'המתנה למענה',
	AT_LEAST: 'לפחות',
	UNTIL: 'עד',
	MILLISECONDS: 'שניות',
	MINUTES: 'דקות',
	CALL_DURATION: 'משך שיחה',
	BUSINESS_PHONES: 'קווי טלפון של העסק',
	EXTENSIONS: 'שלוחות',
	GROUPS: 'קבוצות',
	ORGANIZATIONS: 'ארגונים',
	RESET: 'איפוס',
}