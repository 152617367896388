import { API } from 'aws-amplify';
import useSWR from 'swr';
import { Launcher } from '../api/dialers';

const SWR_CONFIG = { revalidateOnFocus: false, revalidateOnReconnect: false };

export const useLaunchers = () => {

  const endpoint = `/launcher`;
  const { data, error, mutate, isLoading } = useSWR(endpoint, fetcher, SWR_CONFIG);
  return {
    data: isLoading ? undefined : data?.data as Launcher[],
    error: error,
    loading: isLoading,
    mutate: mutate
  }
}

export const useLauncher = (uuid: string) => {
  const endpoint = `/launcher?uuid=${uuid}`;
  const { data, error, mutate, isLoading } = useSWR(uuid ? endpoint : null, fetcher, SWR_CONFIG);
  return {
    data: isLoading ? undefined : data?.data as Launcher,
    error: error,
    loading: isLoading,
    mutate: mutate
  }
}

async function fetcher(path: string) {
  const res = await API.get(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, path, {});
  return res;
}