import { memo } from 'react';
import { useWatch } from 'react-hook-form';
import { Typography } from '@mui/material';

import { FormSection } from '../types';
import { SMS_SETTINGS_TEXTS } from '../utils/texts';
import { TypographyVariant } from '../../theme/muiTypes';
import { useFormFieldRenderer } from '../../hooks/useFormFieldRenderer';
import { CustomCard } from '../../components/common/customCard/CustomCard';
import { useCallbackFieldsConfig } from '../hooks/useCallbackFieldsConfig';
import { CustomDivider } from '../../components/common/customDivider/CustomDivider';
import { StyledCardsContainer, StyledFormContainer } from './StyledCallbackDialerSettings';

const { SMS_TITLE, SMS_SUB_TITLE } = SMS_SETTINGS_TEXTS;

export const SmsSettings = memo(({ section, apiConfig }: FormSection) => {
  const { sms_content_type, sms_text_at_start, sms_text_at_failure, sms_text_between_attempts } =
    useCallbackFieldsConfig();
  const renderFormField = useFormFieldRenderer(apiConfig);
  const contentTypeValue = useWatch({ name: sms_content_type.name });

  return (
    <StyledFormContainer id={section.id}>
      <Typography variant={TypographyVariant.h1}>{SMS_TITLE}</Typography>
      <Typography variant={TypographyVariant.regularText2}>{SMS_SUB_TITLE}</Typography>
      <StyledCardsContainer className='general-settings'>
        <CustomCard className='column'>
          {renderFormField(sms_content_type)}
          <CustomDivider hidden={!sms_content_type.uiConfig.hasPermission} />
          {contentTypeValue === sms_text_at_start.name && renderFormField(sms_text_at_start)}
          {contentTypeValue === sms_text_between_attempts.name && renderFormField(sms_text_between_attempts)}
          {contentTypeValue === sms_text_at_failure.name && renderFormField(sms_text_at_failure)}
        </CustomCard>
      </StyledCardsContainer>
    </StyledFormContainer>
  );
});

SmsSettings.displayName = 'SmsSettings';
