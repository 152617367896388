import { ActionType, CallAttributes, DialerRequestAttributes } from "../interfaces";

interface DialerRequestAction {
  type: ActionType;
  dialerRequest: DialerRequestAttributes;
}

export function dialerRequestReducer(state: DialerRequestAttributes[], action: DialerRequestAction) {
  const { type, dialerRequest } = action;
  switch (type) {
    case ActionType.POST:
      if (state.find(g => g.id === dialerRequest.id)) {
        return state;
      }
      return [...state, dialerRequest];
      
    case ActionType.DELETE:
      return state.filter(agnt => agnt.id !== dialerRequest.id);

    case ActionType.PUT:
      const oldGroup = state.find(grp => grp.id === dialerRequest.id);
      if (!oldGroup) {
        return [...state, action.dialerRequest];
      }
      return state.filter(agnt => agnt.id !== dialerRequest.id).concat([{...oldGroup, ...dialerRequest}]);
    
    case ActionType.CLEAR: return []
    default:
      return state;
  }
}