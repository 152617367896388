import { Box, MenuItem, styled } from '@mui/material';

const itemListProps: PropertyKey[] = ['onDelete', 'isBlockElement'];

export const StyledItemList = styled('div', {
  shouldForwardProp: (prop) => !itemListProps.includes(prop),
})(({ theme }) => ({
  width: '100%',
  display: 'flex',

  '&.column': {
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },

  '&.wrap': {
    flexWrap: 'wrap',
    padding: `${theme.spacing(3)} ${theme.spacing(0)}`,
    rowGap: theme.spacing(3),
  },

  '&.chips': {
    columnGap: theme.spacing(2),
    '& .MuiChip-label': {
      padding: `0px ${theme.spacing(2)}`,
    },
  },

  '&.menu': {
    padding: `${theme.spacing(2)} 0`,
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    padding: `${theme.spacing(2)} ${theme.spacing(0)}`,

    '& .MuiChip-label': {
      padding: `0px ${theme.spacing(2)}`,
    },

    '&:hover': {
      background: 'none',
    },
  },
}));

export const StyledItemBox = styled(Box)(({ theme }) => ({
  '&:hover': {
    cursor: 'pointer',
  },
}));
