import { API } from 'aws-amplify';
import useSWR from 'swr';

export default function useDomains(search?: string): { loading: boolean; error: any; domains: DomainAttributes[] } {
  const key = ['/domains', search || ''];
  const { data, error } = useSWR(key, (url: string[]) => {
    const requestParams = {
      queryStringParameters: search && search.length > 0 ? { search } : {},
    };
    return fetchDomains(url[0], requestParams);
  });

  const domains = data || [];
  const loading = key !== null && !data && !error;

  return {
    domains,
    error,
    loading,
  };
}

function fetchDomains(path: string, params: any) {
  return API.get('customerAppApi', path, params);
}

export interface DomainAttributes {
  id: string;
  name: string;
  ownerName: string;
}
