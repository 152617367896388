import { Route, Routes } from "react-router-dom";
import { MonitorContainer } from ".";
import { MonitorConfig } from "./configuration/monitor-config";

export const MonitorRouter = () => {
  
  return (
    <Routes>
      <Route
        path='/config'
        element={<MonitorConfig />}
      />
      <Route
        path='/*'
        element={<MonitorContainer />}
      />
    </Routes>
  )
}