import { HTMLAttributes } from 'react';
import classNames from 'classnames';
import styles from './request-status-tabs.module.scss';

export const RequestStatusTab = ({
  active = false,
  title,
  counter,
  children,
  ...props
}: RequestStatusProps) => (
  <button className={classNames(styles.requestStatusTab, { [styles.active]: active })} {...props}>
    <div className={styles.content}>
      <div className={styles.title}>{title}</div>
    </div>
    <div className={styles.title}>{counter}</div>
  </button>
);

export interface RequestStatusProps extends HTMLAttributes<HTMLButtonElement> {
  active?: boolean;
  title: string;
  counter: number
}