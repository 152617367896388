import moment from 'moment';
import classNames from 'classnames';
import styles from './call-list-item.module.scss';
import { parsePhoneNumber } from 'libphonenumber-js';
import { CallShape } from '../../types';
import { Button } from '../../../design/button';
import { useExtensions } from '../../../api/use-extensions';

import {
  InboundCallIcon,
  LocalCallIcon,
  OutboundCallIcon,
  MoreHorizontalIcon,
  PlayOutlined,
  CloseIcon,
} from '../../../design/icons';
import { safeParsePhoneNumber } from '../../../utils/strings/parse-phone';

export const CallListItem = ({
  onOpen,
  onPlayCall,
  onClosePlayer,
  isActive,
  multiTenant,
  data,
}: CallListItemProps) => {
  const isMissedCall = data.direction === 'inbound' && data.result === 'failed';
  const extensions = useExtensions();

  const handleKnownPrefixes = (phone_number: string | null): boolean => {
    const knownPrefixes = [
      '22361',
      '22362',
      '11361',
      '11362'
    ];

    if (phone_number && phone_number?.length >= 15) {
      const prefix = phone_number?.substring(0, 5);
      for (let i = 0; i < knownPrefixes.length; i++) {
        if (prefix === knownPrefixes[i]) {
          return true;
        }
      }
    }
    return false;
  }

  const formatSpecialCase = (phone_number: string | null) => {
    const result = handleKnownPrefixes(phone_number);
    return phone_number && result ? safeParsePhoneNumber(phone_number?.substring(5, phone_number?.length), 'IL') : phone_number;
  }

  const displayNumWithExt = (private_extension: string | null, virtual_extension: string | null) => {
    let returnResult: any = ''
    const formatted_virtual = formatSpecialCase(virtual_extension);
    const formatted_private = formatSpecialCase(private_extension);
    if (private_extension && private_extension.length < 4) {
      const data = extensions?.data?.find( data => data.destinationNumber === private_extension );
      returnResult = data?.description === null || data?.description === undefined ? `${formatted_private}` : `${formatted_private} ${data?.description}`
    }
    if (!returnResult) {
      returnResult = private_extension ? formatted_private : formatted_virtual;
    }
    else if (returnResult && typeof returnResult[0] == 'undefined') {
      returnResult = formatted_private;
    }
    return returnResult;
  }

  return (
    <li className={multiTenant ? styles.multiRoot : styles.root}>
      <div className={classNames(styles.dateAndType)}>
        <div className={classNames(styles.dateTime)}>
          <span className={styles.date}>
            {data.start_time.format('YYYY-MM-DD') ===
              moment().format('YYYY-MM-DD')
              ? 'היום'
              : data.start_time.isBetween(moment().subtract(7, 'd'), moment())
                ? data.start_time.format('dddd')
                : data.start_time.format('DD.MM.YYYY')}
          </span>
          <span className={styles.time}>{data.start_time.format('HH:mm')}</span>
        </div>
        <div className={styles.callDirection}>
          {(data.direction !== 'inbound' && icons[data.direction]) || null}
          <span className={styles.callDirectionLabel}>
            {callInfoLabel[data.direction]}
          </span>
        </div>
      </div>
      <div className={classNames(styles.call, { [styles.missed]: isMissedCall, })}>
        <span className={classNames(styles.party, styles.caller)}>
          {displayNumWithExt(data.caller_private_extension, data.caller_virtual_extension)}
        </span>
        <span className={classNames(styles.party, styles.callee)}>
          {data.result === 'ivr' && 'תפריט קולי '}
          {displayNumWithExt(data.callee_private_extension, data.callee_virtual_extension)}
        </span>
        <span className={styles.meta}>
          {callInfoLabel[data.result as 'failed'] && (
            <span className={classNames(styles.metaItem)}>
              {callInfoLabel[data.result as 'failed']}
            </span>
          )}
          {data.sms_count > 0 && (
            <span className={styles.metaItem}>נשלח סמס</span>
          )}
        </span>
      </div>

      {multiTenant && <div className={styles.domain}>
        {data.domainDescription}
      </div>}
      <div className={styles.tta}>
        {`${data.answer_time?.isValid()
          ? data.answer_time.diff(data.start_time, 'seconds')
          : data.end_time.diff(data.start_time, 'seconds')
          } שניות`}
      </div>
      <div className={styles.duration}>
        {data.result === 'answered' &&
          data.end_time.isValid() &&
          data.answer_time?.isValid() &&
          moment
            .duration(data.end_time.diff(data.answer_time))
            .format('mm:ss', { trim: false })}
      </div>
      <div className={styles.recordingButton}>
        {data.record_url ? (
          isActive ? (
            <Button
              variant="primary"
              className={styles.stopButton}
              onClick={onClosePlayer}
            >
              <CloseIcon size={24} />
            </Button>
          ) : (
            <Button
              variant="ghost"
              className={styles.playButton}
              onClick={() => onPlayCall(data)}
            >
              <PlayOutlined size={24} />
            </Button>
          )
        ) : undefined}
      </div>
      <div className={styles.callDetails}>
        {onOpen && (
          <>
            <Button
              variant="ghost"
              className={styles.callDetailsButton}
              onClick={() => {
                if (onOpen) {
                  onOpen(data);
                }
              }}
            >
              <MoreHorizontalIcon size={24} className={styles.icon} />
            </Button>
            <Button
              variant="link"
              className={styles.callDetailsLink}
              onClick={() => {
                if (onOpen) {
                  onOpen(data);
                }
              }}
            >
              פרטי שיחה
            </Button>
          </>
        )}
      </div>
    </li>
  );
};

const callInfoLabel = {
  inbound: 'נכנסת',
  outbound: 'יוצאת',
  local: 'פנימית',
  failed: 'לא נענתה',
  voicemail: 'תא קולי',
  canceled: 'בוטלה',
};

const icons = {
  inbound: (
    <InboundCallIcon className={styles.icon} accent="#41C36B" size={20} />
  ),
  outbound: (
    <OutboundCallIcon className={styles.icon} accent="#2EB4FF" size={20} />
  ),
  local: <LocalCallIcon className={styles.icon} size={20} />,
};

export interface CallListItemProps {
  data: CallShape & { domainDescription?: string };
  multiTenant?: boolean;
  onOpen?: (call: CallShape) => void;
  onPlayCall: (call: CallShape) => void;
  onClosePlayer: () => void;
  isActive: boolean;
}
