import { ChangeEvent, FC, memo, MouseEvent, ReactNode } from 'react';

import { ItemList } from '../itemList/ItemList';
import { CustomChip } from '../customChip/CustomChip';
import { SelectOption } from '../../../formConfig/types';
import { CustomInput } from '../customInput/CustomInput';
import { StyledSearchDropdown } from './StyledSearchDropdown';

interface SearchDropdownProps {
  inputIcon?: ReactNode;
  items: SelectOption[];
  searchTerm: string;
  placeholder?: string;
  searchDisabled: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: MouseEvent<Element>, item: SelectOption) => void;
}

export const SearchDropdown: FC<SearchDropdownProps> = memo(
  ({ items, inputIcon, placeholder, searchTerm, searchDisabled, onClick, onChange }) => {
    return (
      <StyledSearchDropdown>
        <CustomInput
          fullWidth
          icon={inputIcon}
          value={searchTerm}
          disabled={searchDisabled}
          placeholder={placeholder}
          onChange={onChange}
        />
        <ItemList
          items={items}
          isBlockElement
          ItemComponent={CustomChip}
          className='menu column chips'
          onClick={onClick}
        />
      </StyledSearchDropdown>
    );
  }
);
