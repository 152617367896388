import { useState, useContext } from "react";
import * as Yup from "yup";
import mixpanel from "mixpanel-browser";
import { API } from "aws-amplify";
import { isValidNumber } from "libphonenumber-js";
import { Dialog } from "../../design/dialog/dialog";
import { Warning } from "../../design/warning/warning";
import { activeExtensionContext } from "../active-extensions-context";
import { useExtensions } from "../../api/use-extensions";
import { useMe } from "../../api/use-me";
import { Input } from "../../design/form/input/input";
import { Field } from "../../design/form/field";
import { useRedirectCalls } from "../../api/useRedirectCalls";
import { isRedirectableNumber } from "../../utils/groups/isRedirectableNumber";

export function ExtensionsForwardDialog(props: any) {
  const user = useMe();
  const [loading, setLoading] = useState(false);
  const extensions = useExtensions();
  const [activeExtension, setActiveExtension] = useContext(activeExtensionContext);
  const { data: redirectableCalls } = useRedirectCalls(user.data?.domain.id);

  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      const body = {
        ...activeExtension,
        forwardAllDestination: values.number.replaceAll("-", ""),
        forwardAllEnabled: true,
      };

      const trackEventName = activeExtension?.forwardAllEnabled
        ? "Edit Forwarding Extension"
        : "Create Forwarding Extension";

      await API.put(
        process.env.REACT_APP_WEB_API_NAME!,
        `/domains/${user?.data?.domain.id}/extensions/${activeExtension?.destinationNumber}`,
        { body }
      );
      mixpanel.track(trackEventName, {
        "Destination Type": values.number.replaceAll("-", ""),
      });
      await extensions?.mutate();
      props.dialog.hide();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleExtensionValidation = (value: string | undefined) => {
    const enteredValue = value ? value.trim() : "";
    const validNumber = isValidNumber(enteredValue, "IL");
    const redirectableNumber = isRedirectableNumber(
      enteredValue,
      redirectableCalls,
      activeExtension?.destinationNumber
    );
    return validNumber || redirectableNumber;
  };

  const forwardCallsFormSchema = Yup.object().shape({
    number: Yup.string()
      .test(
        "valid-il-number",
        "המספר שהכנסת לא תקין. אפשר להעביר שיחות למספר חוץ או למספר פנימי חוקי",
        handleExtensionValidation
      )
      .test(
        "circular-forwarding",
        `שיחות לשלוחה ${activeExtension?.destinationNumber} כבר מופנות למספר זה`,
        (value) => {
          return !(
            activeExtension?.forwardAllEnabled &&
            value?.replaceAll("-", "") === activeExtension?.forwardAllDestination
          );
        }
      ),
  });

  const handleClose = () => {
    setActiveExtension(undefined);
  };
  return (
    <Dialog
      {...props.dialog}
      variant='form'
      header={`(${activeExtension?.destinationNumber}) העברת שיחות `}
      onSubmit={handleSubmit}
      onClose={handleClose}
      validationSchema={forwardCallsFormSchema}
      submitLabel='להעביר שיחות'
      loading={loading}
      initialValues={{
        number: !activeExtension?.forwardAllEnabled
          ? ""
          : activeExtension?.forwardAllDestination || "",
      }}
      aria-label='הפנית שיחה'
    >
      <Field name='number' label='מספר טלפון אליו תרצה/י להעביר את השיחות'>
        <Input type='tel' placeholder='מספר קווי או נייד' />
      </Field>
      <Warning
        message={"שים/י לב, העברת שיחות למספר חיצוני תגרום לתוספת עלויות דקות שיחה"}
        iconSize={20}
      />
    </Dialog>
  );
}
