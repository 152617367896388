import { Domain } from '../api/use-users';
import styles from './domain-list.module.scss';

export function DomainList(props: DomainListProps) {
  return (
    <ul className={styles.list}>
      {
        props.domains.sort((a, b) => a.domain_owner_name.localeCompare(b.domain_owner_name)).map((domain) =>
          <li key={domain.domain_uuid} onClick={() => {props.onClick(domain)}}>
            {domain.domain_owner_name || domain.domain_uuid}
          </li>)
      }
    </ul>
  )
}
interface DomainListProps {
  domains: Omit<Domain, "users">[],
  onClick: (domain: Omit<Domain, "users">) => Promise<void>
}