import { TextField, styled } from '@mui/material';
import { CustomInputProps } from './CustomInput';

export const StyledInputContainer = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  rowGap: theme.spacing(2.5),
}));

const textFieldProps: PropertyKey[] = ['hasPermission'];

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => !textFieldProps.includes(prop),
})<CustomInputProps>(({ theme }) => ({
  boxSizing: 'border-box',

  '&.MuiFormControl-root': {
    width: '100%',
  },

  '& .MuiInputBase-root': {
    padding: 0,
    borderRadius: theme.borderRadius.default,

    '& .MuiInputAdornment-root': {
      marginLeft: theme.spacing(2),
    },

    '&:not(.MuiInputBase-multiline)': {
      textarea: {
        height: `${theme.fieldHeights.default} !important`,
      },
    },
  },

  '& .MuiInputBase-input': {
    width: '100%',
    padding: `${theme.spacing(1.5)} ${theme.spacing(4)}`,
    height: theme.fieldHeights.default,
    color: theme.palette.secondary.main,
    fontSize: theme.fontSizes.large,
    fontWeight: theme.fontWeights.regular,
    borderRadius: theme.borderRadius.default,
  },

  '& .Mui-disabled': {
    textFillColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.grey[50],
  },

  '&.PlainText': {
    '& .Mui-disabled': {
      background: 'transparent !important',

      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  },
}));
