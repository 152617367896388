import { ReactElement, useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

export const Page = (props: PageProps) => {
 
  useEffect(() => {
    mixpanel.track("Page View", {Page: props.name});
  }, [props.name]);
  
  return props.children;
}


interface PageProps {
  name: string;
  children: ReactElement<any, any>
}