import { Route, Routes } from "react-router-dom";
import { useMediaQuery } from "../layouts/app-layout/app-layout";
import { mobileQuery } from "../theme/media";
import DialGroupsPage from "./dial-groups/dial-groups-page";
import CalleesPage from "./callees/callees-page";
import { CalleeContextProvider } from "./context/callee-context";
import { LauncherContextProvider } from "./context/launcher-context";
import { DialGroupContextProvider } from "./context/dial-groups-context";
import EventsLogPage from "./events-log/events-log-page";
import EventDescriptionPage from "./events-log/event-details/event-description-page";
import AdminSettingsPage from "./dialer-settings/admin-settings/admin-settings";
import { DialerRequestContextProvider } from "./context/dialer-request-context";
import SettingsPage from "./dialer-settings/dialer-settings";
import LaunchersPage from "./launchers/launchers-page";

export default function EmergencyDialer() {
  const isMobile = useMediaQuery(mobileQuery);

  if (isMobile) {
    return <div>Mobile version of Emergency Dialer</div>;
  }

  return (
    <Routes>
      <Route
        path='/callees/*'
        element={
          <CalleeContextProvider>
            <CalleesPage />
          </CalleeContextProvider>
        }
      />
      <Route
        path='/launchers/*'
        element={
          <LauncherContextProvider>
            <LaunchersPage />
          </LauncherContextProvider>
        }
      />
      <Route path='/events-log/*' element={<EventsLogPage />} />
      <Route
        path='/event/:eventNumber/*'
        element={
          <DialerRequestContextProvider>
            <EventDescriptionPage />
          </DialerRequestContextProvider>
        }
      />
      <Route
        path='/dialers/*'
        element={
          <DialGroupContextProvider>
            <DialGroupsPage />
          </DialGroupContextProvider>
        }
      />
      <Route
        path='/dialers/:dialerId'
        element={
          <DialGroupContextProvider>
            <SettingsPage />
          </DialGroupContextProvider>
        }
      />
      <Route
        path='/settings'
        element={
          <DialGroupContextProvider>
            <AdminSettingsPage />
          </DialGroupContextProvider>
        }
      />
    </Routes>
  );
}
