import React from 'react';
import { Icon, IconProps } from './icon';

export const OutboundCallIcon = ({accent, ...props}: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 96 96"
    fill="currentColor"
    {...props}
  >
    <g fillRule="evenodd">
      <path d="M80,24 C80,54.6186665 55.4268799,79.4979733 24.9260624,79.9924976 L24,80 L24,66.5 L39.75,60.75 L46.75,67.75 C55.5444022,63.3527989 62.7290384,56.3100079 67.3014984,47.6240372 L67.75,46.75 L60.75,39.75 L66.5,24 L80,24 Z"/>
      <polygon points="42.799 51.284 30.071 38.557 20 48.456 20 20 48.456 20 38.556 30.071 51.284 42.799" fill={accent} />
    </g>
  </Icon>
);