import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Button } from '../design/button/button';
import { Field } from '../design/form/field';
import { Input } from '../design/form/input/input';
import { GeneralError } from '../design/general-error/general-error';
import { DomainAttributes } from '../api/use-domains';
import { DomainSearchInput } from '../system-admin/components/domain-search-input';

export function InvitationForm({ error, loading, ...props }: InvitationFormProps) {

  const [domain, setDomain] = useState<DomainAttributes>();

  return domain ? (
    <Formik
      {...props}
      onSubmit={values => props.onSubmit(values)}
      initialValues={{
        ...props.initialValues,
        domainId: domain.id,
        domainName: domain.name,
        organizationName: domain.ownerName
      }}
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
    >
      <Form>
        <header style={{ marginBlockEnd: '2rem' }}>
          <h1 style={{ margin: 0 }}>
            {domain.ownerName}
            <span style={{ direction: 'ltr' }}>({domain.name})</span>
          </h1>
          <Button type="reset" variant="link" onClick={() => setDomain(undefined)}>שינוי</Button>
        </header>
        <Field
          name="name"
          label="שם מלא של מקבל ההזמנה"
        >
          <Input autoComplete="off" />
        </Field>
        <Button
          type="submit"
          variant="primary"
          loading={loading}
        >
          יצירת קישור הזמנה
        </Button>
        {error && <GeneralError error={error} />}
      </Form>
    </Formik>
  ) : (
    <DomainSearchInput
      onSelect={domain => setDomain(domain)}
    />
  );
}

export interface InvitationFormProps {
  initialValues: InvitationFormValues;
  error?: string;
  loading?: boolean;
  onSubmit: (values: InvitationFormValues) => void;
}

export interface InvitationFormValues {
  domainId: string;
  name: string;
  organizationName: string;
}