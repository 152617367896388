import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import styles from './callflow-node.module.scss';


export function CallflowNode({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={classNames(className, styles.node)} {...props} />
  );
}

export function CallflowNodeHeader({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return (
    <header className={classNames(className, styles.header)} {...props} />
  )
}

export function CallflowNodeOverline({ children, className, ...props}: HTMLAttributes<HTMLSpanElement>) {
  return (
    <span className={classNames(className, styles.overline)}>{children}</span>
  )
}

export function CallflowNodeTitle({ children, className, ...props }: HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h4 className={classNames(className, styles.title)} {...props}>
      {children}
    </h4>
  )
}

export function CallflowNodeActions({className, ...props}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={classNames(className, styles.actions)} {...props} />
  )
}

export function CallflowNodeContent({className, ...props}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={classNames(className, styles.content)} {...props} />
  )
}