const dictionary: ErrorDictionary = {
  'Attempt limit exceeded, please try after some time.':
    'עברת את מספר הניסיונות המותר. אפשר לנסות שוב בעוד כחצי שעה.',
  'Custom auth lambda trigger is not configured for the user pool.':
    'לא הקלדת סיסמה',
  'Incorrect username or password.': 'כתובת המייל או הסיסמה שהקלדת לא נכונים',
  'Invalid verification code provided, please try again.':
    'קוד האימות שהזנת לא נכון',
  'Username/client id combination not found.':
    'לא הצלחנו למצוא את כתובת המייל שהקלדת',
  'Password does not conform to policy: Password not long enough':
    'הסיסמה שהקלדת קצרה מידי - על הסיסמה להכיל לפחות 8 תווים עם אות אנגלית אחת גדולה ואות אנגלית אחת קטנה',
  'Resent verification code': 'שלחנו לך קוד אימות חדש',
  'User does not exist.': 'כתובת המייל שהזנת לא רשומה במערכת',
  'Username cannot be empty': 'לא הזנת כתובת מייל',
  'Confirmation code cannot be empty': 'לא הזנת קוד אימות',
  'Password cannot be empty': 'לא הזנת סיסמה חדשה',
  CodeMismatchException:
    'הקוד שהזנת לא נכון, ממליצים לבדוק את המייל ולנסות פעם נוספת',
  InvalidPasswordException: 'על הסיסמה להכיל לפחות 8 תווים, אות אנגלית אחת גדולה ואות אנגלית אחת קטנה',
};

//following dictionary was found at invitation-page.tsx

// const errorDictionary: any = {
//   "UsernameExistsException": "האימייל שהכנסת כבר רשום למערכת",
//   "InvalidPasswordException": "הסיסמה לא תקינה, הכנס סיסמה באורך 8 תווים לפחות ",
//   "InvalidParameterException": "הערכים שהזנת אינם חוקיים, בדוק שכתובת האימייל תקינה והסיסמה בת 8 תווים לפחות"
// }


//following dictionary was found at confirm-email-form.tsx

// const errorDictionary: { [key: string]: string } = {
//   CodeMismatchException: 'הקוד שהזנת לא נכון, ממליצים לבדוק את המייל ולנסות פעם נוספת',
//   InvalidParameterException: 'הסיסמה שהזנת לא תקינה. על הסיסמה להכיל לפחות 8 תווים',
//   InvalidPasswordException: 'הסיסמה שהזנת לא תקינה. על הסיסמה להכיל לפחות 8 תווים, אות אנגלית אחת גדולה',
//   UsernameExistsException: 'כתובת האימייל שהקלדת כבר קיימת במערכת',
// }

// following dictionary was found at login.tsx

// const errorDictionary: any = {
//   "Incorrect username or password.": "כתובת המייל או הסיסמה שהקלדת לא נכונים",
//   "Username/client id combination not found.": "לא הצלחנו למצוא את כתובת המייל שהקלדת",
//   "Resent verification code": "שלחנו לך קוד אימות חדש",
//   "Attempt limit exceeded, please try after some time.":
//     "עברת את מספר הניסיונות המותר. אפשר לפנות לתמיכה לקבלת עזרה.",
//   "User does not exist.": "כתובת המייל שהזנת לא רשומה במערכת",
//   "Username cannot be empty": "לא הזנת כתובת מייל",
//   "Custom auth lambda trigger is not configured for the user pool.": "לא הקלדת סיסמה",
//   "Password does not conform to policy: Password not long enough":
//     "הסיסמה שהקלדת קצרה מידי - על הסיסמה להכיל לפחות 8 תווים",
//   CodeMismatchException: "הקוד שהזנת לא נכון, ממליצים לבדוק את המייל ולנסות פעם נוספת",
// };



interface ErrorDictionary {
  [key: string]: string;
}

export default dictionary;
