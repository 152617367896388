import classNames from "classnames";
import { ForwardedRef, InputHTMLAttributes } from "react";
import styles from "./input.module.scss";

export interface AutoSuggestInputProps extends InputHTMLAttributes<HTMLInputElement> {
  ltr?: boolean;
  ref?: ForwardedRef<HTMLInputElement>;
}

export const AutoSuggestInput = ({
  ltr = false,
  className,
  ref,
  ...props
}: AutoSuggestInputProps) => {
  return (
    <input
      ref={ref}
      className={classNames(
        {
          [styles.root]: true,
          [styles["ltr-value"]]: ltr,
        },
        className
      )}
      {...props}
    />
  );
};
