import React from 'react';
import { Icon, IconProps } from './icon';

export const ArrowUTurnRight = ({...props}: IconProps) => (
  <Icon 
    viewBox="0 0 96 96"
    fill="currentColor"
    {...props}
  >
    <path fillRule="evenodd" d="M38,78 C24.745166,78 14,67.254834 14,54 C14,40.8777143 24.5313372,30.2151543 37.6031161,30.0032153 L38,30 L73.9996407,30 L57.8786797,15.1213203 L62.1213203,10.8786797 L84.2426407,33 L62.1213203,55.1213203 L57.8786797,50.8786797 L74.0006407,36 L38,36 C28.0588745,36 20,44.0588745 20,54 C20,63.8306685 27.8807833,71.8206815 37.6693348,71.9970237 L38,72 L48,72 L48,78 L38,78 Z"/>
  </Icon>
);


