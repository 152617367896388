import { Card, styled } from '@mui/material';

export const StyledCustomCard = styled(Card)(({ theme }) => ({
  padding: `${theme.spacing(10)} ${theme.spacing(12)} ${theme.spacing(14)}`,
  borderRadius: theme.borderRadius.main,
  boxShadow: theme.shadows[2],
  '&.column': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    rowGap: theme.spacing(4),
  },
}));
