interface QueryParams {
  domainId: string;
  agentId: string | number;
  groupId?: string | number;
  baseURL: string;
}

export const getAgentSwrKey = (queryParams: QueryParams) => {
  const { baseURL, domainId, agentId, groupId } = queryParams;
  let endPoint: string | null = null;
  if (domainId && agentId && !groupId) {
    endPoint = `${baseURL}/agent/dom/${domainId}/agent/${agentId}`;
  } else if (domainId && agentId && groupId) {
    endPoint = `${baseURL}/agent/dom/${domainId}/agent/${agentId}/queue/${groupId}`;
  } else {
    endPoint = `${baseURL}/agent/dom/${domainId}`;
  }

  return endPoint;
};
