import useSWR from 'swr';
import { API } from 'aws-amplify';

import { useMe } from '../api/use-me';
import { DialGroup } from '../api/dialers';
import { UserResource } from '../api/types';
import { DEFAULT_CALLEE_RESPONSE_OPTS } from './dialer-settings/components/callee-response-table/callee-response-table';

const SWR_CONFIG = { revalidateOnFocus: false, revalidateOnReconnect: false };
export const DEFAULT_DIALER_ID = -1;

export const useDialGroups = () => {
  const endpoint = `/emergency-dialer`;
  const { data, error, mutate, isLoading } = useSWR(endpoint, fetcher, SWR_CONFIG);

  return {
    data: isLoading ? undefined : (data?.data as DialGroup[])?.filter((dg) => dg.dialer_id !== DEFAULT_DIALER_ID),
    error: error,
    loading: isLoading,
    mutate: mutate,
  };
};

export const useDefaultDialer = () => {
  return useDialGroup(DEFAULT_DIALER_ID);
};

export const useDialGroup = (id: number | undefined) => {
  const user = useMe();
  const endpoint = `/emergency-dialer?dialer_id=${id}`;
  const shouldFetch = user?.data && id;
  const { data, error, mutate, isLoading } = useSWR(
    shouldFetch ? endpoint : null,
    id !== -1 ? fetcher : (url) => defaultFetcher(url, user.data!),
    SWR_CONFIG
  );

  if (data?.data) {
    delete data?.data.create_date;
    delete data?.data.update_date;
  }

  return {
    data: data?.data as DialGroup,
    error: error,
    loading: isLoading,
    mutate: mutate,
  };
};

async function fetcher(url: string) {
  try {
    const res = await API.get(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, url, {});
    return res;
  } catch (error: any) {
    console.error(error);
  }
}

async function defaultFetcher(url: string, user: UserResource) {
  try {
    const res = await API.get(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, url, {});
    return res;
  } catch (error: any) {
    if (error.response?.status === 404) {
      const cust_prompt_file_url = DEFAULT_DIALER.cust_prompt_file_url!.replace('{domain}', user.domain.name);
      const cust_cancelation_prompt_file_url = DEFAULT_DIALER.cust_cancelation_prompt_file_url!.replace(
        '{domain}',
        user.domain.name
      );
      const defaultDialer = Object.assign(DEFAULT_DIALER, {
        cust_prompt_file_url,
        cust_cancelation_prompt_file_url,
      });
      await API.post(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, `/emergency-dialer?dialer_id=-1`, {
        body: defaultDialer,
      });
    }
  }
}

export const DEFAULT_DIALER: DialGroup = {
  // --------------------------------------------------------------
  /* General settings */
  dialer_id: -1,
  name: 'DEFAULT_EMERGENCY_DIALER',
  max_active: 5,
  priority: 5,
  business_number: '03-1234567',
  business_number_for_sms: 'BusNum',
  language: 'he',
  enabled: true,
  speaker: 'efrat',

  package_cust_calls: 200,
  package_cust_sms: 200,
  package_cust_minutes: 200,
  // minutes_billing_code: 0,
  sms_billing_code: '0',

  /* Dialer Settings Section */
  cust_max_attempts: 3,
  cust_dial_interval: 4,
  cust_max_ringing: 30,
  callee_sms_text: undefined,

  max_event_age: 30,
  default_event_cancel_type: 'partial',
  interact_by_phone: true,
  interact_by_sms: true,
  launch_code: '1234',
  send_realtime_emails: false,
  callee_responses: DEFAULT_CALLEE_RESPONSE_OPTS,
  send_summary_emails: false,
  /* Dialer Config Section */
  domain_uuid: '',
  cust_prompt_file_name: `default_cust_prompt`,
  cust_prompt_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:default_cust_prompt.wav/file`,
  cust_cancelation_prompt_file_name: `default_cust_cancelation_prompt`,
  cust_cancelation_prompt_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:trauma:default_cust_cancelation_prompt.wav/file`,
  dialer_name_file_name: 'dialer_name',
  dialer_name_file_url: 'dialer_name.wav',
};
