// monitor-messages interfaces and functions

import { EventType } from "../web-socket/pub-sub/event-bus";
import { AgentAttributes } from "./monitor-agent";
import { CallAttributes } from "./monitor-call";
import { DialerRequestAttributes } from "./monitor-dialer";

type uuid = string;

// the various action that go between websocket client and server
export enum ActionType {
  POST    = 'create',
  PUT     = 'update',
  DELETE  = 'delete',
  CLEAR   = 'clear',
  DUMP    = 'dump',
  PING    = 'ping',
  PONG    = 'pong',
  QUIT    = 'quit',
}

// Message: base for data that goes from server to client
export interface Message<DATA = AgentAttributes | Counter /* | .... */ | any> {
  action: ActionType;
  objectType: EventType | undefined;
  data: DATA;
}

// DumpMessages
export interface DumpMessage extends Message<DumpObject[]> {
  action: ActionType.DUMP;
}

// PingMessage
export interface PingMessage extends Message<{ time: number }> {
  action: ActionType.PING,
}

// Pong message
export interface PongMessage extends Message<{ time: number }> {
    action: ActionType.PONG,
}

// DumpObject
export interface DumpObject {
  objectType: EventType,
  objects: AgentAttributes[] | CallAttributes[]
}

// TimeSeriesMessage
export interface TimeSeriesMessage extends Message<Record<string, TimeSeries[]>> {
  action: ActionType.PUT;
  objectType: EventType.TIME_SERIES;
  data: Record<string, TimeSeries[]>;
}

// TimeSeries
export interface TimeSeries {
  id: string;
  name: string;
  dataset: number[];
}

// AgentMessage
export interface AgentMessage extends Message<AgentAttributes> {
  objectType: EventType.AGENT;
  data: AgentAttributes;
}

// GroupMessage
export interface GroupMessage extends Message<GroupAttributes> {
  objectType: EventType.GROUP;
  data: GroupAttributes;
}

// CallMessage
export interface CallMessage extends Message<CallAttributes> {
  objectType: EventType.CALL;
  data: CallAttributes
}

// DialerRequestMessage
export interface DialerRequestMessage extends Message<DialerRequestAttributes> {
  objectType: EventType.DIALER_REQUEST;
  data: DialerRequestAttributes
}

// CountersMessage
export interface CountersMessage extends Message<Counter[]> {
  objectType: EventType.COUNTERS;
  data: Counter[]
}

// GroupAttributes
export interface GroupAttributes {
  id: uuid;
  name: string;
  number: number;
}

// Counter
export interface Counter {
  counterId: string;
  name: string;
  value: number;
}

export interface BreakType {
  domain_uuid: string;
  code: string;
  label: string;
  color?: string | undefined; 
  type?: 'break' | 'mission';
}
