import React, { HTMLAttributes } from 'react';

import { Icon, IconProps } from './icon';

export const Canceled = ({...props}: IconProps) => (
  <Icon 
    viewBox="0 0 96 96"
    fill="currentColor"
    {...props}
  >
    <path fillRule="evenodd" d="M48,12 C67.882251,12 84,28.117749 84,48 C84,67.882251 67.882251,84 48,84 C28.117749,84 12,67.882251 12,48 C12,28.117749 28.117749,12 48,12 Z M48,18 C64.5685425,18 78,31.4314575 78,48 C78,64.5685425 64.5685425,78 48,78 C31.4314575,78 18,64.5685425 18,48 C18,31.4314575 31.4314575,18 48,18 Z M64,44.5 L32,44.5 L32,51.5 L64,51.5 L64,44.5 Z"/>
  </Icon>
);

export const Canceled16 = (props: HTMLAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 96 96"
    fill="currentColor"
    {...props}
  >
    <path fillRule="evenodd" d="M48,12 C67.882251,12 84,28.117749 84,48 C84,67.882251 67.882251,84 48,84 C28.117749,84 12,67.882251 12,48 C12,28.117749 28.117749,12 48,12 Z M48,18 C64.5685425,18 78,31.4314575 78,48 C78,64.5685425 64.5685425,78 48,78 C31.4314575,78 18,64.5685425 18,48 C18,31.4314575 31.4314575,18 48,18 Z M64,44.5 L32,44.5 L32,51.5 L64,51.5 L64,44.5 Z"/>
  </svg>
);

export const Canceled20 = (props: HTMLAttributes<SVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 96 96"
    fill="currentColor"
    {...props}
  >
    <path fillRule="evenodd" d="M48,12 C67.882251,12 84,28.117749 84,48 C84,67.882251 67.882251,84 48,84 C28.117749,84 12,67.882251 12,48 C12,28.117749 28.117749,12 48,12 Z M48,18 C64.5685425,18 78,31.4314575 78,48 C78,64.5685425 64.5685425,78 48,78 C31.4314575,78 18,64.5685425 18,48 C18,31.4314575 31.4314575,18 48,18 Z M64,44.5 L32,44.5 L32,51.5 L64,51.5 L64,44.5 Z"/>
  </svg>
);

