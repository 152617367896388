import { Button } from "../../design/button";
import { AppLayout, AppLayoutContainer, AppLayoutHeader, AppLayoutTitle } from "../../layouts/app-layout/app-layout";
import { Page } from "../../page";
import styles from './digital-agent-page.module.scss';

interface DigitalAgentPageProps {  }

export const DigitalAgentPage: React.FC<DigitalAgentPageProps> = () => {

  const handleClick = async () => {
    window.open(`/digital-agent-message`, '_blank');
  }
  
  return (
    <Page name='Digital Agent'>
      <AppLayout>
        <AppLayoutContainer>
          <AppLayoutHeader className={styles.header}>
            <AppLayoutTitle>נציג דיגיטלי</AppLayoutTitle>
            <Button
              variant="primary"
              className={styles.btn}
              onClick={handleClick}
            >
              למעבר לנציג דיגיטלי
            </Button>
          </AppLayoutHeader>
        </AppLayoutContainer>
      </AppLayout>
    </Page>
  )
}
