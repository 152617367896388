import { Node } from 'reactflow';
import { texts } from './texts';
import { WHATSAPP_FILES_FORMAT } from './constants';
import { fetchAttachmentURL } from '../../utils/apiService';
import { attachmentMenuConfig } from './attachmentMenuConfig';
import { AttachmentInputName, AttachmentType, NodeData } from './types';
import { FileField, FileFormat, FileType, InputFileMode } from '../../globalTypes/types';

export const getFileFormat = (file_url: string) => {
  const idxOfLastDot = file_url.lastIndexOf('.');
  const idxOfLastSlash = file_url.lastIndexOf('/');
  const file_format = file_url.slice(idxOfLastDot + 1, idxOfLastSlash) as FileFormat;
  return file_format;
};

export const getAttachmentValues = (selectedNode: Node<NodeData>): FileField | string => {
  const { attachment_name, attachment_url, attachment_type } = selectedNode.data;
  if (!attachment_name || !attachment_type) {
    return attachment_url as string;
  }

  const file_name = selectedNode.data.attachment_name as string;
  const file_url = selectedNode.data.attachment_url as string;
  const file_type = selectedNode.data.attachment_type as FileType;
  const file_format = file_name.slice(file_name.lastIndexOf('.') + 1) as FileFormat;
  const hasValue = file_name && file_url && file_type;
  const file_mode = hasValue ? InputFileMode.EDIT : InputFileMode.ADD;

  return {
    file: null,
    file_name,
    file_url,
    file_type,
    file_mode,
    file_format,
    supported_formats: WHATSAPP_FILES_FORMAT,
  };
};

export const getFileValues = (file: File | null): FileField | null => {
  if (!file) return null;
  const file_name = file?.name;
  const file_url = URL.createObjectURL(file);
  const file_mode = InputFileMode.EDIT;
  const file_type = file.type.split('/')[0] as FileType;
  const file_format = file_name.split('/')[1] as FileFormat;
  const supported_formats = WHATSAPP_FILES_FORMAT;
  return { file, file_name, file_url, file_mode, file_type, file_format, supported_formats };
};

export const getAcceptableFormats = (
  supported_formats: FileFormat[] | undefined,
  file_type: FileType | null
): string => {
  const fileType = file_type ?? '';
  if (supported_formats === undefined || supported_formats?.length === 0) {
    return `${fileType}/*`;
  }
  const formatsString = supported_formats.map((format) => `.${format}`).join(', ');
  return formatsString;
};

export const getAttachmentDialogTexts = (selectedAttachmentType: AttachmentType | null) => {
  const {
    WHATSAPP_FILE_URL_LABEL,
    WHATSAPP_COORDINATE_LABEL,
    WHATSAPP_COORDINATE_TITLE,
    WHATSAPP_UPLOAD_FILE_TITLE,
    WHATSAPP_FILE_INPUT_PLACEHOLDER,
    WHATSAPP_COORDINATE_INPUT_PLACEHOLDER,
  } = texts;
  const whatsappTexts = {
    dialogTitle: WHATSAPP_UPLOAD_FILE_TITLE,
    inputLabel: WHATSAPP_FILE_URL_LABEL,
    inputPlaceholder: WHATSAPP_FILE_INPUT_PLACEHOLDER,
    inputName: AttachmentInputName.FileURL,
  };

  if (selectedAttachmentType === AttachmentType.Location) {
    whatsappTexts.dialogTitle = WHATSAPP_COORDINATE_TITLE;
    whatsappTexts.inputLabel = WHATSAPP_COORDINATE_LABEL;
    whatsappTexts.inputPlaceholder = WHATSAPP_COORDINATE_INPUT_PLACEHOLDER;
    whatsappTexts.inputName = AttachmentInputName.Coordinates;
  }
  return whatsappTexts;
};

export const updateAttachmentData = async (
  nodeAttachment: FileField | string,
  selectedAttachmentType: AttachmentType | null,
  botId: string
) => {
  const isStringAttachment = typeof nodeAttachment === 'string';
  if (selectedAttachmentType === AttachmentType.Location && isStringAttachment) {
    return {
      attachment_location: nodeAttachment,
      active_attachment: nodeAttachment ? AttachmentType.Location : undefined,
    };
  }
  if (selectedAttachmentType === AttachmentType.File && isStringAttachment) {
    return {
      attachment_url: nodeAttachment,
      attachment_name: undefined,
      attachment_type: undefined,
      active_attachment: nodeAttachment ? AttachmentType.File : undefined,
    };
  }
  if (selectedAttachmentType === AttachmentType.File && !isStringAttachment) {
    const response = await fetchAttachmentURL(nodeAttachment, botId);
    return response
      ? {
          attachment_url: response.attachment_url,
          attachment_name: nodeAttachment.file_name,
          attachment_type: nodeAttachment.file_type,
          active_attachment: AttachmentType.File,
        }
      : null;
  }
};

export const setInitialNodeAttachment = (selectedNode: Node<NodeData>) => {
  const { attachment_url, attachment_location, active_attachment } = selectedNode.data;
  if (active_attachment === AttachmentType.Location) {
    return attachment_location || '';
  }
  if (active_attachment === AttachmentType.File && attachment_url) {
    return getAttachmentValues(selectedNode);
  }
  return '';
};

export const isValidUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

const latitudeExp = /^(\+|-)?(?:90(?:\.0+)?|(?:[0-8]?[0-9])(?:\.[0-9]+)?)$/;
const longitudeExp = /^(\+|-)?(?:180(?:\.0+)?|(?:1[0-7][0-9]|[0-9]?[0-9])(?:\.[0-9]+)?)$/;

const validateLatitude = (lat: string): boolean => latitudeExp.test(lat);

const validateLongitude = (lng: string): boolean => longitudeExp.test(lng);

const isValidCoordinates = (location: string): boolean => {
  const [lat, lng] = location.split(', ');
  if (!lat || !lng) {
    return false;
  }
  return validateLatitude(lat) && validateLongitude(lng);
};

export const isValidInput = (inputName: string, attachment: FileField | string): boolean => {
  const isStringAttachment = typeof attachment === 'string';
  if (!isStringAttachment) {
    return true;
  }
  if (inputName === AttachmentInputName.FileURL && isStringAttachment) {
    return !attachment || isValidUrl(attachment);
  }
  if (inputName === AttachmentInputName.Coordinates && isStringAttachment) {
    return !attachment || isValidCoordinates(attachment);
  }

  return false;
};

export const updateMenuStyle = (data: NodeData) => {
  const { active_attachment } = data;

  attachmentMenuConfig.forEach((menuItem) => {
    if (!active_attachment || active_attachment === menuItem.value) {
      menuItem.disabled = false;
    } else {
      menuItem.disabled = true;
    }
  });
};
