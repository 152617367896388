import { useEffect, useState } from "react";
import { useBusinessNumberCollection } from "../../../api/use-business-number";
import { useDestinations } from "../../../api/use-destinations";
import { useExtensions } from "../../../api/use-extensions";
import { useGroups } from "../../../api/use-groups";
import { useMe } from "../../../api/use-me";
import { Button } from "../../../design/button/button"
import { SelfManageResources } from "./self-managed-resources";
import { Spinner } from "../../../design/spinner/spinner";
import { useDialogState } from "reakit";
import { ConfigResourceDialog, ConfigResourceDialogProps } from "./dialogs/config-resource-dialog";
import styles from "./self-manage.module.scss"
import { BusinessNumberResource, DOMAIN_FEATURES_LIST, DestinationResource, DomainFeature, DomainFeatures, ExtensionResource, GroupResource } from "../../../api/types";

export const SelfManageSettings = () => {

  const user = useMe();
  const extensions = useExtensions(true);
  const configResourceDialog = useDialogState();
  const groups = useGroups(user?.data?.domain.id);
  const destinations = useDestinations(user.data);
  const [activeConfig, setActiveConfig] = useState<ConfigResourceDialogProps["activeConfig"]>("numbers");
  const numbers = useBusinessNumberCollection(true);
  const [loading, setLoading] = useState(user.loading);
  const selfManagedNumbers = numbers?.data?.filter(number => user.data?.domain.config.selfManagedNumbers.includes(number.id)) || [];
  const selfManagedExtensions = extensions?.data?.filter(extension => user.data?.domain.config.selfManagedExtensions.includes(extension.id)) || [];
  const selfManagedGroups = groups?.data?.filter(group => user.data?.domain.config.selfManagedGroups.includes(group.id)) || [];

  const hasFeature = (fName: DomainFeature) => {
    const isActiveIdx = 1;
    const feature = Object.entries(
      user?.data?.domain.config.domainFeatures || {}
    ).find(
      ([featureName, isFeatureActive]) => 
        (featureName === fName) && isFeatureActive
    );
    return feature?.[isActiveIdx];
  }
    
  const domainFeatures: DomainFeatures = Object.fromEntries(DOMAIN_FEATURES_LIST.map(feature => [feature, hasFeature(feature)])) as DomainFeatures;

  const offDestination = destinations.data ? Object
    .values(destinations.data)
    .filter(destination => destination.id === user.data?.domain.config.offDestination) : [];

  const allResources = mapResources(destinations.data ? Object.values(destinations.data) : [], numbers.data || [], extensions.data || [], groups.data || []);
  const activeResources = mapResources(offDestination, selfManagedNumbers, selfManagedExtensions, selfManagedGroups);

  const dialog = <ConfigResourceDialog
    dialog={configResourceDialog}
    activeConfig={activeConfig}
    onActiveConfigChange={(activeConfig: ConfigResourceDialogProps["activeConfig"]) => setActiveConfig(activeConfig)}
    allResources={{ ...allResources, domainFeatures }}
    activeResources={{ ...activeResources, domainFeatures }}
  />

  const handleResourceClick = (resource: ConfigResourceDialogProps["activeConfig"]) => {
    setActiveConfig(resource);
    configResourceDialog.show();
  }

  useEffect(() => {
    setLoading(destinations.loading || numbers.loading || extensions.loading || groups.loading)
  }, [destinations.loading, numbers.loading, extensions.loading, groups.loading])


  return (
    <>
      <div className={styles.heading}>
        <span>הגדרות ניהול עצמי</span>
        <Button onClick={() => configResourceDialog.show()}>עריכה</Button>
      </div>
      {
        loading ? (
          <Spinner />
        ) : (
          <>
            <SelfManageResources resources={activeResources} onResourceClick={handleResourceClick} />
            <div>{dialog}</div>
          </>
        )
      }
    </>
  )
}

const mapResources = (
  destinations: DestinationResource[],
  numbers: BusinessNumberResource[],
  extensions: ExtensionResource[],
  groups: GroupResource[]
) => {
  const groupsFilter = (dest: DestinationResource) => (
    (dest?.number?.length === 3 && "235".includes(dest.number.at(0) || "Z")) || // 2xx, 3xx, 5xx  - acceptable ring group destination
    (dest?.number?.length === 4 &&   "5".includes(dest.number.at(0) || "Z")) || // 5xxx           - acceptable ring group destination
    (dest?.number?.length === 7                                            ) || // xxxx-xxx       - acceptable ring group destination
    (dest?.number?.length >= 9)                                                 // 05x-xxx-xxxx   - acceptable ring group destination
  );

  return {
    destinations: destinations.map(destination => ({ id: destination.id, label: destination.description, number: destination.number })),
    numbers: numbers.map(number => ({ id: number.id, label: number.label, number: number.number })),
    extensions: extensions.map(extension => ({ id: extension.id, label: extension.description, number: extension.destinationNumber })),
    groups: groups.filter((group) => {
      return group.number?.[0] === "5" || group.destinations.some(groupsFilter) // either 5xx (therefore CCQ) or has acceptable group destination
    }).map(group => ({ id: group.id, label: group.name, number: group.number }))
  }
}
