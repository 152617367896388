import { InvitationList } from '../invitation-list/invitation-list';
import { InvitationResource } from '../../api/types';
import { useState } from 'react';
import { Search } from '../../design/search/search';
import styles from './invitations-section.module.scss';

export function InvitationsSection({ invitations }: InvitationsSectionProps) {

  const [displayList, setDisplayList] = useState(invitations);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchString = event.target.value;

    const filteredInvitations = invitations?.filter(invitation => {
      const user = invitation.user;
      return user.name.includes(searchString) || user.domainName.includes(searchString) || user.organizationName.includes(searchString);
    })

    setDisplayList(filteredInvitations);
  }

  const statusList = displayList.reduce(
    (map, invitation) => ({
      ...map,
      [invitation.status]: {
        ...map[invitation.status],
        items: [...map[invitation.status].items, invitation],
      },
    }),
    {
      ACCEPTED: { label: 'אושרו', items: [] },
      EXPIRED: { label: 'לא תקפות', items: [] },
      PENDING: { label: 'ממתינות', items: [] },
    }
  );

  const showPending = statusList && statusList['PENDING'].items.length > 0;
  const showExpired = statusList && statusList['EXPIRED'].items.length > 0;
  const showAccepted = statusList && statusList['ACCEPTED'].items.length > 0;

  return (
    <div className={styles.root}>
      <div className={styles.nav}>
        <Search onChange={onSearch} className={styles.searchBar} placeholder='חיפוש...'/>
        {showPending && (
          <a href="#pendingInvitationsList">{`${statusList['PENDING'].label} (${statusList['PENDING'].items.length})`}</a>
        )}
        {showExpired && (
          <a href="#expiredInvitationsList">{`${statusList['EXPIRED'].label} (${statusList['EXPIRED'].items.length})`}</a>
        )}
        {showAccepted && (
          <a href="#acceptedInvitationsList">{`${statusList['ACCEPTED'].label} (${statusList['ACCEPTED'].items.length})`}</a>
        )}
      </div>
      <div className={styles.invitationsList}>
        {showPending && (
          <>
            <InvitationList
              id={'pendingInvitationsList'}
              title={statusList['PENDING'].label}
              items={statusList['PENDING'].items}
            />
          </>
        )}
        {showExpired && (
          <>
            <InvitationList
              id={'expiredInvitationsList'}
              title={statusList['EXPIRED'].label}
              items={statusList['EXPIRED'].items}
            />
          </>
        )}
        {showAccepted && (
          <>
            <InvitationList
              id={'acceptedInvitationsList'}
              title={statusList['ACCEPTED'].label}
              items={statusList['ACCEPTED'].items}
            />
          </>
        )}
      </div>
    </div>
  );
}

interface InvitationsSectionProps {
  invitations: InvitationResource[];
}
