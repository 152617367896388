import { createContext, useState } from "react";
import { Callee } from "../../api/dialers";


export const CalleeContext = createContext<[Callee | undefined, React.Dispatch<React.SetStateAction<Callee | undefined>>]>([
  undefined, () => undefined 
]);

export const CalleeContextProvider = ({ children }: { children: React.ReactNode }) => {
  const ctx = useState<Callee | undefined>(undefined);
  return (
    <CalleeContext.Provider value={ctx}>
      {children}
    </CalleeContext.Provider>
  )
}