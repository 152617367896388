import styles from './text-element.module.scss';

export const TextElement: React.FC<{label: string, value: string }> = ({ ...props }) => {
  return (
    <div className={styles.labelAndValueContainer}>
      <span className={styles.label}>{ props.label }</span>
      <span className={styles.value}>
        {props.value}
      </span>
    </div>
  );
}