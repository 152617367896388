import useSWR from "swr";
import { API } from "aws-amplify";
import { BreakType } from "./interfaces";
import { getDomainId } from "../utils/monitor/get-domain-id";
import { getBreakTypeByCode } from "../utils/monitor/get-break-type-by-code";

const NEW_DEFAULT_BREAK_CODE = "*43";

export const useBreakTypes = () => {
  const { data, error, isLoading, mutate } = useSWR("/break-types", breakTypesFetcher, SWR_CONFIG);
  return { data, error, isLoading, mutate };
};

export interface BreakTypeResponse {
  data: BreakType[];
  success: boolean;
}

const breakTypesFetcher = async (endpoint: string) => {
  const urlName = process.env.REACT_APP_CCM_API_NAME!;

  try {
    const res: BreakTypeResponse = await API.get(urlName, endpoint, {});
    let breakTypeData = res.data;
    const newBreakTypeFromResponse = getBreakTypeByCode(breakTypeData, NEW_DEFAULT_BREAK_CODE);
    if (!newBreakTypeFromResponse) {
      const domain_uuid = getDomainId(breakTypeData);
      if (domain_uuid) {
        const newBreakType = getBreakTypeByCode(DEFAULT_BREAK_TYPES, NEW_DEFAULT_BREAK_CODE);
        if (newBreakType) {
          breakTypeData = [{ ...newBreakType, domain_uuid }, ...breakTypeData];
          await API.post(urlName, `/break-types`, {
            body: newBreakType,
          });
        }
      }
    }
    return breakTypeData;
  } catch (error: any) {
    if (error.response?.status && error.response.status === 404) {
      await Promise.all(
        DEFAULT_BREAK_TYPES.map((bt) => API.post(urlName, "/break-types", { body: bt }))
      );
      return DEFAULT_BREAK_TYPES;
    }
    console.error("Could not fetch break types");
    console.error(error);
  }
};

const SWR_CONFIG = {
  revalidateOnFocus: false,
  dedupingInterval: 5 * 60 * 1000, // 5 minutes
  refreshInterval: 5 * 60 * 1000, // 5 minutes
};

const DEFAULT_BREAK_TYPES: BreakType[] = [
  {
    domain_uuid: "",
    code: "*43",
    label: "הפסקה",
  },
  {
    domain_uuid: "",
    code: "*45",
    label: "הפסקה",
  },
  {
    domain_uuid: "",
    code: "*46",
    label: "הפסקה",
  },
  {
    domain_uuid: "",
    code: "*47",
    label: "הפסקה",
  },
  {
    domain_uuid: "",
    code: "*48",
    label: "הפסקה",
  },
  {
    domain_uuid: "",
    code: "*49",
    label: "הפסקה",
  },
];
