import { FC } from 'react';
import { SnackbarCloseReason } from '@mui/material';

import { snackbarDefaultConfig } from '../../constants';
import LinearProgressBar from '../linearProgressBar/LinearProgressBar';
import { StyledHighlightedMessage, StyledSnackbar } from './StyledCustomSnackbar';
import { SnackbarHorizontal, SnackbarVariant, SnackbarVertical } from '../../../theme/muiTypes';

const { AUTO_HIDE_DURATION } = snackbarDefaultConfig;

interface CustomSnackbarProps {
  keyId: string;
  isOpen: boolean;
  variant: SnackbarVariant;
  regularText: string;
  timeInterval?: number;
  highlightedText?: string | null;
  autoHideDuration?: number;
  verticalPosition?: SnackbarVertical;
  horizontalPosition?: SnackbarHorizontal;
  onClose: (event: Event | React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => void;
}

export const CustomSnackbar: FC<CustomSnackbarProps> = ({
  keyId,
  isOpen,
  variant,
  regularText,
  timeInterval,
  highlightedText,
  autoHideDuration = AUTO_HIDE_DURATION,
  verticalPosition = SnackbarVertical.bottom,
  horizontalPosition = SnackbarHorizontal.left,
  onClose,
}) => {
  const highlightedMessage = <StyledHighlightedMessage variant={variant}>{highlightedText}</StyledHighlightedMessage>;
  const regularMessage = (
    <StyledHighlightedMessage variant={SnackbarVariant.info}>{regularText}</StyledHighlightedMessage>
  );
  const message = (
    <span>
      {highlightedMessage} {regularMessage}
    </span>
  );

  return (
    <StyledSnackbar
      key={keyId}
      open={isOpen}
      message={message}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      action={<LinearProgressBar duration={autoHideDuration} timeInterval={timeInterval} severity={variant} />}
      anchorOrigin={{ vertical: verticalPosition, horizontal: horizontalPosition }}
    />
  );
};
