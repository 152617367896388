import { NavLink } from "react-router-dom";
import { useGroups } from "../../../../api/use-groups";
import { useMe } from "../../../../api/use-me";
import { Table } from "../../../../design/table/table";
import { Column } from "../../../../design/table/types";
import { safeParsePhoneNumber } from "../../../../utils/strings/parse-phone";
import { CallAttributes, DialerRequestAttributes } from "../../../interfaces";
import { MonitorConfiguration } from "../../../use-monitor-config";
import { createUnansweredCallsList } from "./unanswered-calls-list-data";
import styles from './unanswered-calls-list.module.scss';
import { getDigitTimeFromMillis } from "../../../../utils";

export const UnansweredCallsList = ({ calls, dialerRequests, monitorConfig, tableName }: Props) => {
  // take general info
  const user = useMe();
  const groups = useGroups(user.data?.domain.id).data;
  const selfManagedGroups = groups?.filter(group => user?.data?.domain.config.selfManagedGroups.includes(group.id));

  // invoke the data preparation function
  const unansweredCallsList = createUnansweredCallsList(calls, monitorConfig, selfManagedGroups);

  const columns: Array<Column<typeof unansweredCallsList[0], keyof typeof unansweredCallsList[0]>> = [
    {
      key: 'callerNumber',
      header: 'מתקשר',
      className: styles.callerNumber,
      render: (row) => <span style={row.status !== 'טרם טופל' ? { color: '#757D86', fontWeight: '400' } : {}}>{safeParsePhoneNumber(row.callerNumber)}</span>,
    },
    {
      key: 'queueName',
      header: 'מחלקה',
      className: styles.queueName,
      render: (row) => <span style={row.status !== 'טרם טופל' ? { color: '#757D86', fontWeight: '400' } : {}}>{row.queueName}</span>,
    },
    {
      key: 'callsCount',
      header: 'שיחות',
      className: styles.callsCount,
      render: (row) => {
        const group = selfManagedGroups?.find(group => group.name === row.queueName);

        if (!group || !user.data) return (
          <span style={row.status !== 'טרם טופל' ? { color: '#757D86', fontWeight: '400' } : {}}>{row.callsCount}</span>
        )

        const searchValue = row.callerNumber;
        const mapDateToFilter = (date: Date) => {

          const month = date.getMonth() + 1;
          const day = date.getDate();
          const hr = date.getHours();
          const min = date.getMinutes();
          const sec = date.getSeconds();

          return `${date.getFullYear()}` +
            `-${month < 10 ? `0${month}` : month}` +
            `-${day < 10 ? `0${day}` : day}` +
            `+${hr < 10 ? `0${hr}` : hr}` +
            `:${min < 10 ? `0${min}` : min}` +
            `:${sec < 10 ? `0${sec}` : sec}`;
        }
        const startDate = mapDateToFilter(row.oldestCallStartTime);
        const endDate = mapDateToFilter(row.youngestCallStartTime);
        const startDateFilterValue = `${startDate}`;
        const endDateFilterValue = `${endDate}`;
        const phoneQuery = `phone/${searchValue}`;
        const dateQuery = `from/${startDateFilterValue}/to/${endDateFilterValue}`;
        const groupQuery = `groupsPerDomain/${user.data.domain.name.substring(0, 4)}:${group.number}`;
        const url = `/calls-history/${phoneQuery}/${dateQuery}/status/failed/${groupQuery}`;
        return (
          <NavLink to={url} style={{ borderBlockEnd: '1px solid #181D24' }}>{row.callsCount}</NavLink>
        )
      }
    },
    {
      key: 'lastCall',
      header: 'שעת שיחה אחרונה',
      className: styles.lastCall,
      render: (row) => <span style={row.status !== 'טרם טופל' ? { color: '#757D86', fontWeight: '400' } : {}}>{row.lastCall}</span>
    },
    {
      key: 'totalRingTimeMs',
      header: 'זמן המתנה מצטבר',
      className: styles.avgWaitTime,
      render: (row) => <span style={row.status !== 'טרם טופל' ? { color: '#757D86', fontWeight: '400' } : {}}>{getDigitTimeFromMillis(row.totalRingTimeMs, 'MM:SS')}</span>
    },
    {
      key: 'status',
      header: 'סטטוס שיחה',
      className: styles.status,
      render: (row) => <span style={row.status !== 'טרם טופל' ? { color: '#757D86', fontWeight: '400' } : {}}>{row.status}</span>
    },
  ];

  return (
    <Table columns={columns} data={unansweredCallsList} showHeaders tableName={tableName} />
  )
}

interface Props {
  calls: CallAttributes[],
  dialerRequests: DialerRequestAttributes[],
  monitorConfig: MonitorConfiguration,
  tableName?: string | undefined
}
