import { useEffect, useState } from "react";
import { useDialogState } from "reakit";
import { useMe } from "../../api/use-me";
import { LoadingPage } from "../../app/loading-page";
import { secureFetch } from "../../auth/server-auth";
import { ErrorDialog } from "../../design/dialog/error-dialog/error-dialog";
import { Browser, useBrowserInfo } from "../../utils/useBrowserInfo";
import { useRecoilValue } from "recoil";
import { hadshanutUserState } from "../../state";

export const Digital99: React.FC<{}> = () => {
  const user = useRecoilValue(hadshanutUserState);
  const [url, setUrl] = useState<string>();
  const [visible, setVisible] = useState<boolean>(false);
  const { browser } = useBrowserInfo();
  const [error, setError] = useState<string | undefined>();
  const errorDialog = useDialogState();

  useEffect(() => {
    async function get99DigitalUrl() {
      if (!user) return;

      const [domainUuid, email] = [user.domain.id, user.email];
      const endpoint = `${process.env.REACT_APP_99_DIGITAL_SERVER_URL}/dom/${domainUuid}/agentLogin/${email}`;
      const { res } = await secureFetch(endpoint);
      const resJson = await res.json();

      if (!resJson?.data) {
        setError(`לא נמצאו פרטי התחברות עבור: ${user.email}`);
        return;
      }

      if (browser === Browser.Chrome) {
        // Open the URL itself in a new tab for Apple devices
        setUrl(resJson.data);
      } else {
        window.location.href = resJson.data;
      }
    }

    let timeoutId: any;
    get99DigitalUrl()
      .then(res => {timeoutId = setTimeout(() => {
        setVisible(true);
      }, 4000)});

    return () => {
      clearTimeout(timeoutId);
    }
  }, [user]);

  if (error) {
    return <ErrorDialog dialog={errorDialog} onClose={() => console.log('closing')} />;
  }

  return (
    <div style={{ height: '100%' }}>
      {url && (
        <iframe
          title='digital agent integration page'
          height='100%'
          width='100%'
          src={url}
          style={{ display: visible ? 'block' : 'none' }}
        />
      )}
      {!visible && <LoadingPage text="נא להמתין בזמן שהנציג הדיגיטלי נטען" />}
    </div>
  );
};
