import { useRecoilValue } from "recoil";
import { authenticatedState } from "../state";
import { UserResource } from "./types";
import { useApi } from "./use-api";

export function useMe(force=false) {
  const authenticated = useRecoilValue(authenticatedState);
  return useApi<UserResource>(authenticated ? 'me' : null, undefined, force);
}

