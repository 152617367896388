import { Chip, styled } from '@mui/material';

export const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: '#004E9B',

  '&.MuiButtonBase-root': {
    height: '24px',
    '& .MuiChip-icon': {
      ...theme.sizes.small,
    },

    '& .MuiChip-deleteIcon': {
      margin: `${theme.spacing(0)} ${theme.spacing(0)} ${theme.spacing(0)} ${theme.spacing(1)}`,
      ...theme.sizes.small,
    },
  },

  '& .MuiChip-label': {
    fontSize: theme.fontSizes.main,
    fontWeight: theme.fontWeights.semiBold,
    color: theme.texts.darkBlue,
  },

  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
}));
