import { Link } from 'react-router-dom';
import { Spinner } from '../../design/spinner/spinner';
import {
  AppLayoutContainer,
  AppLayoutHeader,
  AppLayout,
  AppLayoutTitle,
} from '../../layouts/app-layout/app-layout';
import { Page } from '../../page';
import { InvitationResource } from '../../api/types';
import { InvitationsSection } from '../../invitations/invitations-section/invitations-section';
import { useInvitationsApi } from '../use-invitations-api';
import styles from './invitation-list-page.module.scss';

export function InvitationListPage() {
  const { data: invitations, loading } = useInvitationsApi<InvitationResource[]>('/');

  return (
    <Page name="Invitation List">
      <AppLayout>
        <AppLayoutContainer>
          <AppLayoutHeader className={styles.header}>
            <div>
              <AppLayoutTitle>ניהול הזמנות</AppLayoutTitle>
            </div>
            <div className={styles.actionBar}>
              <Link to="/invitations/new" className={styles.link}>
                הזמנה חדשה
              </Link>
            </div>
          </AppLayoutHeader>
          {loading ? (
            <Spinner />
          ) : invitations ? (
            <InvitationsSection invitations={invitations} />
          ) : (
            <div>Error</div>
          )}
        </AppLayoutContainer>
      </AppLayout>
    </Page>
  );
}