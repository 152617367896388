import { RedirectableCall } from "../../api/types";

export const isRedirectableNumber = (
    enteredValue: string, 
    redirectableCalls: RedirectableCall[] | undefined, 
    activeGroupNumber: string | undefined = ''
  ) => {
  const rediractableNumbers = redirectableCalls ? redirectableCalls.map((redirectableCall) => redirectableCall.number) : [];
  return rediractableNumbers.includes(enteredValue) && enteredValue !== activeGroupNumber;
};
