import { API } from 'aws-amplify';
import useSWR from 'swr';
import { GroupResource } from './types';
import { User } from './use-users';

const SWR_CONFIG = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};
const requestSpecification = '/crud/dom';

export const useGroups = (domainUuid: string | undefined) => {
  const { data, error, mutate } = useSWR(
    () => (domainUuid ? requestSpecification + `/${domainUuid}/RingGroup/*` : null),
    groupsFetcher,
    SWR_CONFIG
  );
  data?.data.forEach((group) => {
    const nameClean = group.name?.match(/^\d+$/) ? undefined : group.name;
    group.name = nameClean?.trim() || group.description?.trim() || group.name?.trim() || group.number;
  });

  return {
    data: data?.data,
    error: error,
    loading: !error && !data,
    mutate: mutate,
  };
};

export const useAllGroups = (user?: User) => {
  const userDomains = user?.domains;
  const domainUuids = userDomains?.map((domain) => domain.domain_uuid);
  const paths = domainUuids?.map((domainUuid) => `${requestSpecification}/${domainUuid}/RingGroup/*`);
  const { data, error, isLoading } = useSWR(paths, multiTenantGroupsFetcher, SWR_CONFIG);
  const groupedData: Record<string, GroupResource[]> = Object.fromEntries(
    data?.map((groups, index) => [domainUuids?.[index], groups]) || []
  );

  return { groupedData, error, isLoading };
};

const multiTenantGroupsFetcher = async (ids: string[]) => {
  const urlName = process.env.REACT_APP_PHP_SERVICES_NAME!;
  return await Promise.all(ids.map((id) => API.get(urlName, id, {})));
};

const groupsFetcher = async (path: string) => {
  const res = await API.get(process.env.REACT_APP_PHP_SERVICES_NAME!, path, {});

  return {
    data: res,
  } as { data: GroupResource[] };
};
