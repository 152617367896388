import { useState } from 'react';
import { DialogStateReturn } from 'reakit';

import { useMe } from '../../api/use-me';
import { DIALOG_TEXTS } from '../utils/texts';
import { fetcher } from '../../utils/apiService';
import { Dialog } from '../../design/dialog/dialog';
import { RequestMethod } from '../../globalTypes/types';
import { ErrorDialog } from '../../design/dialog/error-dialog/error-dialog';
import styles from '../../emergency-dialer/dialer-dialog/dialer-dialog.module.scss';

const {
  CANCEL_BUTTON_TEXT,
  DELETE_CALLBACK_TITLE,
  DELETE_CALLBACK_MESSAGE_1,
  DELETE_CALLBACK_MESSAGE_2,
  DELETE_CALLBACK_BUTTON_TEXT,
} = DIALOG_TEXTS;

interface DeleteCallbackDialogProps {
  dialog: DialogStateReturn;
  dialerId: number;
  dialerName: string;
  mutateCallbackDialers: () => void;
}

export default function DeleteCallbackDialog({
  dialog,
  dialerId,
  dialerName,
  mutateCallbackDialers,
}: DeleteCallbackDialogProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { loading: userLoading } = useMe();

  const handleError = (err: any): void => {
    console.error(err?.response?.data);
    setError(true);
    const { data, error } = err.response.data;
    if (!data || !error) {
      setErrorMessage(undefined);
      return;
    }
    // Default
    setErrorMessage(undefined);
  };

  const handleClose = () => {
    dialog.hide();
  };

  const handleDeleteGroup = async () => {
    try {
      setLoading(true);
      await fetcher({
        apiName: process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!,
        endpointURL: `/callback-dialer?dialer_id=${dialerId}`,
        method: RequestMethod.DELETE,
        data: { body: {} },
      });
      mutateCallbackDialers();
      handleClose();
    } catch (err: any) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  if (error) {
    return (
      <ErrorDialog
        mainMessage={errorMessage}
        showSecondaryMessage={false}
        dialog={dialog}
        onClose={() => setError(false)}
      />
    );
  }

  return (
    <Dialog
      {...dialog}
      variant='caution'
      loading={userLoading || loading}
      header={DELETE_CALLBACK_TITLE}
      submitLabel={DELETE_CALLBACK_BUTTON_TEXT}
      cancelLabel={CANCEL_BUTTON_TEXT}
      hideOnClickOutside={false}
      onSubmit={handleDeleteGroup}
      onClose={handleClose}
      aria-label={DELETE_CALLBACK_TITLE}
    >
      <section>
        <span>
          {DELETE_CALLBACK_MESSAGE_1} <span className={styles.strong}>{dialerName}</span> {DELETE_CALLBACK_MESSAGE_2}
        </span>
      </section>
    </Dialog>
  );
}
