import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

// @TODO FIX
// when calling setURLParam twice, only the second call will take place

export function useURLParams(options: UseURLParamsOptionsShape) {
  const navigate = useNavigate();
  const { path, sortList, params } = options;

  const setURLParams = useCallback((newParams: any) => {
    const map = {
      ...mapParams(params),
      ...newParams
    };

    // @TODO don't push if next route is identical to current
    navigate(`${path}/${buildParamStr(map, sortList)}`);
  },[params, path, sortList]);

  const setURLParam = useCallback((key: string, value: any) => setURLParams({[key]: value}), [setURLParams]);
  const removeURLParam = useCallback((key: string) => setURLParam(key, null),[setURLParam]);
  const removeURLParams = useCallback((params: string[]) => setURLParams(params.reduce((a,p) => ({...a,[p]: null}),{})), [setURLParams]);

  return {
    urlParams: mapParams(options.params),
    urlParamsMultiValue: mapParams(options.params, true),
    removeURLParam,
    removeURLParams,
    setURLParam,
    setURLParams,
  };
}

function buildParamStr(map: any, sortList?: string[]) {
  return Object.entries(map)
    .filter(([key, value]) => key && value)
    .sort(([a],[b]) => sortList ? sortList.indexOf(a) - sortList.indexOf(b) : 0)
    .reduce((acc,[key, value]) => key && value ? `${acc}${key}/${value}/` : acc, '')
}

function mapParams(params: string | undefined, multiValue: boolean = false) {
  if (!params) return '';
  return params.split('/')
    .reduce<any>(
      (acc, key, index, origin) => index%2 === 0 && key !== "" ? (
        {...acc, [key]: multiValue ? origin[index+1].split(',') : origin[index+1]}
      ) : acc
    ,{});
}

interface UseURLParamsOptionsShape {
  path: string;
  params: string | undefined;
  sortList?: string[];
}