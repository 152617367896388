import { useCallback } from "react";
import { CompareFunction } from "../types";
import { TEXTS } from "../constants/texts";

export const useSortColumn = () => {
  const compareFunction = useCallback(
    <T>(columnName: keyof T, existingSortFn: CompareFunction<T> | undefined) => {
      if (existingSortFn) {
        return existingSortFn;
      }

      return (a: T, b: T) => {
        const firstEl = a[columnName];
        const secondEl = b[columnName];
        
        if (typeof firstEl !== typeof secondEl) {
          if (!(firstEl === null || secondEl === null || firstEl === undefined || secondEl === undefined)) {
            throw Error(TEXTS.TYPE_ERROR);
          }
          if (firstEl === null || firstEl === undefined) {
            return secondEl === null || secondEl === undefined ? 0 : -1;
          }
          else {
            // secondEl is null or undefined
            return firstEl === null || firstEl === undefined ? 0 : 1;
          }
        }
        switch (typeof firstEl) {
          case "string":
            return (firstEl as string).localeCompare(secondEl as string);
          case "number":
            return (firstEl as number) - (secondEl as number);
          case "boolean":
            return Number(firstEl) - Number(secondEl);
          default:
            throw Error(TEXTS.TYPE_ERROR);
        }
      };
    },
    []
  );

  return compareFunction;
};
