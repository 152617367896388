import { API } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';
import { useContext, useState } from 'react';
import { Dialog } from '../../design/dialog/dialog';
import { activeExtensionContext } from '../active-extensions-context';
import { useMe } from '../../api/use-me';
import { useExtensions } from '../../api/use-extensions';
import { parsePhoneNumber } from 'libphonenumber-js';
import { safeParsePhoneNumber } from '../../utils/strings/parse-phone';

export function ExtensionsCloseDialog(props: any) {
  const user = useMe();
  const extensions = useExtensions();
  const [loading, setLoading] = useState(false);
  const [activeExtension, setActiveExtension] = useContext(activeExtensionContext);

  // "legal" cast to string because we are canceling a forward, hence, there must be a forward destination
  // and it will never be null or undefined
  const forwardDestination: string = activeExtension?.forwardAllDestination as string

  const handleConfirm = async (values: any) => {
    try {
      setLoading(true);
      const body = {
        ...activeExtension,
        forwardAllEnabled: false,
      }

      await API.put(process.env.REACT_APP_WEB_API_NAME!, `/domains/${user?.data?.domain.id}/extensions/${activeExtension?.destinationNumber}`, { body });
      mixpanel.track('Delete Forwarding Extension', {
        'Destination Type': activeExtension?.forwardAllDestination
      })
      await extensions?.mutate();
      props.dialog.hide();
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false);
    }
  }

  const handleClose = () => {
    setActiveExtension(undefined);
  }

  return activeExtension && activeExtension.forwardAllEnabled ? (
    <Dialog
      {...props.dialog}
      variant="caution"
      header={`ביטול העברת שיחות (${safeParsePhoneNumber(forwardDestination, "IL")})`}
      submitLabel='ביטול העברה'
      cancelLabel='להשאיר העברה'
      onSubmit={handleConfirm}
      onClose={handleClose}
      loading={loading}
      aria-label="ביטול העברת שיחה"
    >
      {`כל השיחות הנכנסות יגיעו לשלוחה ${activeExtension.description || activeExtension.destinationNumber}`}
    </Dialog>
  ) : <></>;
}
