
import { DialogStateReturn } from 'reakit';
import { Dialog } from '../../design/dialog/dialog';
import { DialerEvent, DialerEventCancelType, DialerEventCancelationTypes } from '../../api/dialers';
import { useState } from 'react';
import { API } from 'aws-amplify';
import { useMe } from '../../api/use-me';
import { useLaunchers } from '../use-launchers';
import { ErrorDialog } from '../../design/dialog/error-dialog/error-dialog';

const FAILED_TO_SAVE_MESSAGE = 'לא הצלחנו לבטל את האירוע מסיבה לא ידועה'

export default function CancelEventDialog({ dialog, activeEvent, mutateEvents }: CancelEventDialogProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { data: user } = useMe();
  const { data: launchers } = useLaunchers();
  const currentLauncher = launchers?.find(launcher => launcher.user_uuid === user?.id && launcher.dial_groups.includes(activeEvent.dialer_id));
  const authorizedToCancelEvent = currentLauncher !== undefined;
  const isEmergency = activeEvent.event_type === 'emergency';
  const isMale = user?.gender === 'male';
  let submitLabel =  'ביטול אירוע';
  let action = 'לבטל את האירוע';
  switch(activeEvent.event_type) {
    case 'emergency':
      submitLabel = 'ביטול אירוע אמת';
      action = 'לבטל אירוע אמת';
      break;
    case 'drill':
      submitLabel = 'ביטול תרגיל';
      action = 'לבטל את התרגיל';
      break;
    case 'cancelation':
      submitLabel = 'עצירת פעולת ביטול';
      action = 'לעצור פעולת ביטול';
      break;
  }

  const handleClose = () => {
    setError(false);
    dialog.hide();
  }

  const handleCancelEvent = async () => {
    try {
      setLoading(true);
      const params = [
        `domain_name=${user?.domain.name}`,
        `dialer_id=${activeEvent.dialer_id}`,
        `event_id=${activeEvent.event_id}`,
        `launcher_uuid=${currentLauncher?.launcher_uuid || ''}`,
        `quiet=${activeEvent.event_type === 'cancelation'}`
      ];
      await API.put('emergencyDialerApi', `/cancel-event?${params.join('&')}`, { body: {} });
      await new Promise(resolve => setTimeout(() => resolve(mutateEvents()), 1500));
      handleClose();
    } catch (err) {
      console.error(err);
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  if (error) {
    return (
      <ErrorDialog dialog={dialog} mainMessage={FAILED_TO_SAVE_MESSAGE} onClose={handleClose} />
    )
  }

  if (!authorizedToCancelEvent) {
    return (
      <Dialog {...dialog}
        variant={'message'}
        header={'ביטול אירוע'}
        submitLabel={'חזרה'}
        cancelLabel={'ביטול'}
        hideOnClickOutside={false}
        onSubmit={() => dialog.hide()}
        onClose={handleClose}
        aria-label={'cancel-event-dialog'}
      >
        <p>
          אינך מורשה לשגר אירוע עבור {activeEvent.dialer_name}
        </p>
      </Dialog>
    )
  }
  return (
    <Dialog {...dialog}
      variant={'caution'}
      header={'ביטול אירוע'}
      submitLabel={submitLabel}
      cancelLabel={'התחרטתי'}
      loading={loading}
      hideOnClickOutside={false}
      onSubmit={() => handleCancelEvent()}
      aria-label={'cancel-event-dialog'}
    >
      <section>
        <p>
          ביטול האירוע ייכנס לתוקף מיידי.<br />
          כל ההתראות על אירוע מאת <b>{activeEvent.dialer_name}</b> יבוטלו.
        </p>
        <p>
          <b>האם {isMale ? 'אתה' : 'את'} {isMale ? 'בטוח' : 'בטוחה'} שברצונך {action}?</b>
        </p>
      </section>
    </Dialog>
  )
}
interface CancelEventDialogProps {
  dialog: DialogStateReturn;
  activeEvent: DialerEvent;
  mutateEvents: () => void;
}
