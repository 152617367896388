import { Menu, MenuItem, MenuProps, styled } from '@mui/material';

const menuProps: PropertyKey[] = ['menuArrow'];

interface StyledMenuProps extends MenuProps {
  menuArrow?: boolean;
}

export const StyledMenu = styled(Menu, {
  shouldForwardProp: (prop) => !menuProps.includes(prop),
})<StyledMenuProps>(({ theme, menuArrow }) => ({
  borderRadius: theme.borderRadius.default,

  '& .MuiPaper-root': {
    boxShadow: 'none',
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    marginTop: theme.spacing(3),

    '& .MuiMenu-list': {
      padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
    },

    '&::before': menuArrow
      ? {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 8,
          width: 10,
          height: 10,
          background: theme.backgrounds.bg_main,
          transform: 'translateY(-50%) rotate(45deg)',
          zIndex: 0,
        }
      : undefined,
  },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  '&.MuiMenuItem-root': {
    lineHeight: theme.lineHeights.xlarge,
    padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
    ...theme.typography.regularText1,
    color: theme.palette.primary.main,

    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },
  },
}));
