

interface IText {
  ANSWERED_PLUR: string;
  ANSWERED_SING: string;

  UNANSWERED_PLUR: string;
  UNANSWERED_SING: string;

  WAITING_TIME_PLUR: string;
  WAITING_TIME_SING: string;

  INBOUND_PLUR: string;
  INBOUND_SING: string;

  OUTBOUND_PLUR: string;
  OUTBOUND_SING: string;

  CALL_PLUR: string;
  CALL_SING: string;

  CALL_CENTER: string;

  TOTAL: string;

  GROUP_PLUR: string;
  GROUP_SING: string;

  BACK: string;
}

export const TEXT_HEB: IText = {
  ANSWERED_PLUR: 'נענו',
  ANSWERED_SING: 'נענתה',

  UNANSWERED_PLUR: "לא נענו",
  UNANSWERED_SING: "לא נענתה",

  WAITING_TIME_PLUR: "זמני המתנה",
  WAITING_TIME_SING: "זמן המתנה",

  CALL_CENTER: "מוקד",

  INBOUND_PLUR: "נכנסות",
  INBOUND_SING: "נכנסת",

  OUTBOUND_PLUR: "יוצאות",
  OUTBOUND_SING: "יוצאת",

  CALL_PLUR: "שיחות",
  CALL_SING: "שיחה",

  TOTAL: 'סה"כ',

  GROUP_PLUR: "קבוצות",
  GROUP_SING: "קבוצה",
  
  BACK: "חזרה"
}