import ButtonBar from "../../design/button-bar/button-bar";
import { Button, ButtonProps } from "../../design/button/button";
import {
  AppLayout,
  AppLayoutContainer,
  AppLayoutHeader,
  AppLayoutTitle,
} from "../../layouts/app-layout/app-layout";
import { Page } from "../../page";
import { Filter } from "../../design/filter/filter";
import { Table } from "../../design/table/table";

import styles from "./launchers-page.module.scss";
import { Spinner } from "../../design/spinner/spinner";
import { useContext, useState } from "react";
import { useLaunchers } from "../use-launchers";
import { EditLauncherDialog } from "./dialogs/edit-launcher-dialog";
import { useDialogState } from "reakit";
import { LoadExcelDialog } from "./dialogs/load-excel-dialog";
import { Checkbox } from "../../design/checkbox/checkbox";
import { Field } from "formik";
import { useParams } from "react-router-dom";
import { useURLParams } from "../../app/use-url-params";
import { Launcher } from "../../api/dialers";
import { LaunchersDeleteDialog } from "./dialogs/launchers-delete-dialog";
import { API } from "aws-amplify";
import { LauncherContext } from "../context/launcher-context";
import { useDialGroups } from "../use-dial-groups";
import { safeParsePhoneNumber } from "../../utils/strings/parse-phone";
import { Column, SearchLocation } from "../../design/table/types";

const PAGE_TITLE = "ניהול מפעילים";

export default function LaunchersPage() {
  const {
    data: launchers,
    error: launchersError,
    loading: launchersLoading,
    mutate: launchersMutate,
  } = useLaunchers();
  const { data: dialGroups } = useDialGroups();
  const [showCheckboxes, setShowCheckboxes] = useState<boolean>(false);
  const [checked, setChecked] = useState<Set<string>>(new Set<string>());
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<boolean>(false);
  const params = useParams<any>()["*"]?.replace(/^\/|\/$/g, "");
  const url = useURLParams({ path: "/emergency-dialer/launchers", params });
  const editLauncherDialog = useDialogState();
  const loadExcelDialog = useDialogState();
  const launcherDeleteDialog = useDialogState();
  const [, setSelectedLauncher] = useContext(LauncherContext);

  const actionButtons: ActionButtonProps[] = [
    {
      id: "add-callee",
      label: "הוספת מפעיל",
      variant: "primary",
      onClick: () => editLauncherDialog.show(),
    },
    { id: "load-excel-file", label: "טעינת אקסל", onClick: () => loadExcelDialog.show() },
  ];

  const handleMasterCheck = () => {
    const newChecked = new Set<string>();
    if (checked.size < refinedData.length) {
      refinedData.forEach((callee) => newChecked.add(callee.id));
      newChecked.add("master");
    }
    setChecked(newChecked);
  };

  const handleCheckChange = (id: string) => {
    const newChecked = new Set<string>(checked);
    if (newChecked.has(id)) {
      newChecked.delete(id);
      newChecked.delete("master");
    } else {
      newChecked.add(id);
      if (newChecked.size === refinedData.length) {
        newChecked.add("master");
      }
    }
    setChecked(newChecked);
  };

  const toggleCheckboxes = () => {
    if (showCheckboxes) {
      setChecked(new Set<string>());
    }
    setShowCheckboxes(!showCheckboxes);
  };

  const handleDelete = async (checked: Set<string>) => {
    const ids = Array.from(checked).filter((id) => id !== "master");
    if (!ids.length) {
      toggleCheckboxes();
      launcherDeleteDialog.hide();
      return;
    }
    setLoadingDelete(true);
    try {
      await Promise.all(
        ids.map((id) =>
          API.del(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, `/launcher?uuid=${id}`, {
            body: {
              uuid: id,
            },
          })
        )
      );
      launcherDeleteDialog.hide();
    } catch (err) {
      setDeleteError(true);
      console.error(err);
    } finally {
      await launchersMutate();
      setLoadingDelete(false);
      toggleCheckboxes();
    }
  };

  const buttons = actionButtons.map((btn) => {
    return {
      id: btn.id,
      component: (
        <Button
          key={btn.id}
          variant={btn.variant ? btn.variant : "regular"}
          className={styles.actionButton}
          onClick={btn.onClick || undefined}
        >
          {btn.label}
        </Button>
      ),
    };
  });

  if (launchersLoading && !launchersError) {
    return (
      <Page name='Launchers Page'>
        <AppLayout>
          <AppLayoutContainer>
            <AppLayoutHeader></AppLayoutHeader>
          </AppLayoutContainer>
          <Spinner />
        </AppLayout>
      </Page>
    );
  }

  const activeFilteredDialGroups = url.urlParams.dial_groups?.split(",");

  const filterDialGroups = (launcher: Launcher): boolean => {
    return activeFilteredDialGroups.some((dialGroupId: string) => {
      return launcher.dial_groups?.find((group) => String(group) === dialGroupId);
    });
  };

  const filteredData = (launchers || []).filter((launcher) => {
    let isFiltered = true;
    if (url.urlParams.dial_groups) {
      isFiltered &&= filterDialGroups(launcher);
    }
    return isFiltered;
  });

  const refinedData = (filteredData || []).map((launcher) => {
    return {
      id: launcher.launcher_uuid,
      ...launcher,
      dial_groups: launcher.dial_groups?.map(
        (group) => dialGroups?.find((dg) => dg.dialer_id === group)?.name
      ),
      phone_number: safeParsePhoneNumber(launcher.phone_number),
      menu: (
        <Button
          className={styles.editButton}
          onClick={() => {
            setSelectedLauncher(launcher);
            editLauncherDialog.show();
          }}
        >
          ...
        </Button>
      ),
    };
  });

  const columns: Array<Column<typeof refinedData[0], keyof typeof refinedData[0]>> = [
    {
      key: "phone_number",
      header: "מספר טלפון",
      className: styles.phoneNumber,
    },
    {
      key: "full_name",
      header: "תיאור/שם מפעיל",
      className: styles.name,
    },
    {
      key: "dial_groups",
      header: "קבוצות קריאה להפעלה",
      className: styles.dialGroups,
      render(row, column) {
        const launcherDialGroups = row.dial_groups;
        return (
          <div title={launcherDialGroups?.join(", ")} className={styles.allDialGroups}>
            <span>{row.dial_groups?.[0]}</span>
            {launcherDialGroups?.length > 1 ? (
              <span className={styles.moreContentLabel}>{`${launcherDialGroups.length - 1}+`}</span>
            ) : undefined}
          </div>
        );
      },
    },
    {
      key: "menu",
      header: "",
      className: styles.actionsMenu,
    },
  ];

  return (
    <Page name='Launchers Page'>
      <AppLayout>
        <AppLayoutContainer>
          <AppLayoutHeader className={styles.appLayoutHeader}>
            <div className={styles.pageHeader}>
              <AppLayoutTitle>{PAGE_TITLE}</AppLayoutTitle>
              <ButtonBar buttons={buttons} />
            </div>
          </AppLayoutHeader>
          <main>
            <Table
              columns={columns}
              data={refinedData}
              search
              searchPlaceholder='חפש מספר נייד, תיאור מקפיץ...'
              searchLocation={SearchLocation.RIGHT}
              checkboxes={showCheckboxes}
              checkedSet={checked}
              onCheckChange={handleCheckChange}
              onMasterCheck={handleMasterCheck}
            >
              <Filters
                toggleShowCheckbox={toggleCheckboxes}
                deleteDialogRef={launcherDeleteDialog}
                checkedLaunchers={checked}
              />
            </Table>
          </main>
          <EditLauncherDialog dialog={editLauncherDialog} launchersMutate={launchersMutate} />
          <LoadExcelDialog dialog={loadExcelDialog} launchersMutate={launchersMutate} />
          <LaunchersDeleteDialog
            dialog={launcherDeleteDialog}
            onClose={() => toggleCheckboxes()}
            launcherNames={Array.from(checked)
              .filter((id) => id !== "master")
              .map((id) => launchers?.find((launcher) => launcher.launcher_uuid === id)?.full_name)}
            handleDelete={() => handleDelete(checked)}
            loading={loadingDelete}
            setError={setDeleteError}
            error={deleteError}
          />
        </AppLayoutContainer>
      </AppLayout>
    </Page>
  );
}

const Filters = ({ toggleShowCheckbox, deleteDialogRef, checkedLaunchers }: FiltersProps) => {
  const params = useParams<any>()["*"]?.replace(/^\/|\/$/g, "");
  const url = useURLParams({ path: "/emergency-dialer/launchers", params });
  const [deleteState, setDeleteState] = useState<boolean>(false);
  const { data: dialGroups } = useDialGroups();

  const numFilteredDialGroups = url.urlParamsMultiValue.dial_groups?.length;
  const areFiltersActive =
    Object.keys(url.urlParams).filter((param) => filterKeys.includes(param)).length > 0;

  return (
    <div>
      <div className={styles.filters}>
        <Filter /* Dial Groups filter */
          active={url.urlParams.dial_groups}
          badge={
            numFilteredDialGroups ? (
              <div className={styles.filterBadge}>{numFilteredDialGroups}</div>
            ) : undefined
          }
          initialValues={{ dial_groups: url.urlParamsMultiValue.dial_groups }}
          label='קבוצת קריאה'
          onReset={() => url.removeURLParam("dial_groups")}
          onSubmit={(values) => {
            const value = values.dial_groups.join(",");
            url.setURLParam("dial_groups", value);
          }}
        >
          <div>
            {dialGroups?.map((dialGroup) => (
              <Field
                key={dialGroup.dialer_id}
                component={(props: any) => (
                  <Checkbox
                    title={dialGroup.dialer_id + " - " + dialGroup.name}
                    {...props.field}
                    style={{ marginBlockEnd: "8px" }}
                  />
                )}
                name='dial_groups'
                type='checkbox'
                value={`${dialGroup.dialer_id}`}
              />
            ))}
          </div>
        </Filter>
        {areFiltersActive && (
          <button
            className={styles.resetFiltersButton}
            onClick={() => url.removeURLParams(filterKeys)}
          >
            איפוס
          </button>
        )}
      </div>

      <div className={styles.deleteButtonSection}>
        <Button
          style={{ visibility: deleteState ? "visible" : "collapse" }}
          onClick={() => {
            setDeleteState(false);
            toggleShowCheckbox();
          }}
          variant='ghost'
        >
          ביטול
        </Button>
        <Button
          onClick={() => {
            if (deleteState && checkedLaunchers.size) {
              deleteDialogRef.show();
            } else {
              toggleShowCheckbox();
            }
            setDeleteState(!deleteState);
          }}
          variant={deleteState ? "primary" : "regular"}
        >
          {deleteState ? "סיום" : "מחיקת מפעיל"}
        </Button>
      </div>
    </div>
  );
};

interface FiltersProps {
  toggleShowCheckbox: () => void;
  deleteDialogRef: any;
  checkedLaunchers: Set<string>;
}

interface ActionButtonProps extends ButtonProps {
  id: string;
  label: string;
}

const filterKeys = ["dial_groups"];
