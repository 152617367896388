import { DialerGlobalSettingsWithFiles } from './dialers';

export enum PermissionGroup {
  Customer = 'customer',
  Support = 'support',
  Admin = 'admin',
}

export enum PermissionLevel {
  Customer = 1,
  Support = 2,
  Admin = 3,
}

export interface UserResource {
  id: string;
  name: string;
  email: string;
  phone_number: string;
  gender: genderType;
  groups: PermissionGroup[];
  organization: UserOrganizationAttributes;
  domain: UserDomainAttributes;
  data: DialerGlobalSettingsWithFiles;
}

export type genderType = 'male' | 'female';

export interface UserOrganizationAttributes {
  name: string;
}

export interface UserDomainAttributes {
  id: string;
  name: string;
  config: {
    offDestination?: string;
    selfManagedNumbers: string[];
    selfManagedExtensions: string[];
    selfManagedGroups: string[];
    domainFeatures: DomainFeatures;
  };
}

export type DomainFeature =
  | 'merkazia'
  | 'callbackDialer'
  | 'monitor'
  | 'emergencyDialer'
  | 'whatsapp'
  | 'networkMonitor'
  | 'digitalAgent'
  | 'billing';

export type DomainFeatures = Record<DomainFeature, boolean | undefined>;
const dummy: DomainFeatures = {
  merkazia: undefined,
  callbackDialer: undefined,
  monitor: undefined,
  emergencyDialer: undefined,
  whatsapp: undefined,
  networkMonitor: undefined,
  digitalAgent: undefined,
  billing: undefined,
};

export const DOMAIN_FEATURES_MAP: Record<DomainFeature, string> = {
  merkazia: 'מרכזייה',
  callbackDialer: 'חייגן שיחה חוזרת',
  emergencyDialer: 'חייגן בתי חולים (חייגן כוננים)',
  monitor: 'מוניטור',
  whatsapp: 'שירות ווטסאפ',
  networkMonitor: 'ניטור רשת',
  digitalAgent: 'נציג דיגיטלי',
  billing: 'פירוט חיובי סמס'
};

export const DOMAIN_FEATURES_LIST: DomainFeature[] = Object.keys(dummy) as DomainFeature[];

export type DialplanFormatVersionType = '2021-07-22' | 'legacy';

export interface BusinessNumberResourceRootDestination {
  id: string;
  destinationNumber: string;
  type: 'default' | 'event' | 'temp' | 'closed';
}

export interface BusinessNumberResource {
  id: string;
  number: string;
  dialplanFormatVersion: DialplanFormatVersionType;
  forwardIncomingCallsTo: string | null;
  destinationNumber: string;
  RootDestinationCollection: BusinessNumberResourceRootDestination[];
  activeRootDestinationId: string;
  eventCollectionDestinationNumber: string;
  activeEventId?: string;
  label?: string;
  labelId?: string;
}

export type NumberResourceMode = 'open' | 'closed' | 'forwarding';
export type BusinessNumberMode = 'open' | 'closed' | 'forwarding';

export interface EventResource {
  id: string;
  name: string;
  date: string;
  openAt: string;
  closeAt: string;
  openDestinationNumber: string | null;
  closedDestinationNumber: string;
  closedMessageUrl: string;
}

export interface DestinationResource {
  id: string;
  name: string;
  number: string;
  type: string;
  [key: string]: any; // this is bad
}

export interface RecordingResource {
  id: string;
  name: string;
  fileName: string;
  uploadedAt: string;
  url: string;
}

export interface InvitationResource {
  id: string;
  status: 'PENDING' | 'EXPIRED' | 'ACCEPTED';
  expiresAt: number;
  acceptedAt: number | null;
  createdAt: number;
  user: {
    name: string;
    domainName: string;
    domainId: string;
    organizationName: string;
  };
}

export interface ExtensionResource {
  id: string;
  destinationNumber: string;
  alias?: string;
  forwardAllDestination?: string;
  forwardAllEnabled: boolean;
  description?: string;
  enabled: boolean;
}

export interface GroupResource {
  id: string;
  name?: string;
  number?: string;
  description?: string;
  forwardDestination?: string;
  forwardEnabled: boolean;
  destinations: DestinationResource[];
}

export interface BusinessNumberLabelResource {
  labelId: string | undefined;
  label: string | undefined;
  phoneNumber: string | undefined;
}

export interface ResourceMap<Resource> {
  [key: string]: Resource;
}

export type RediractableTypes = 'RingGroup' | 'Extension' | 'IVR';

export interface RedirectableCall {
  type: RediractableTypes;
  number: string;
  name: string;
  enabled: boolean;
}

export interface BillingResource {
  domain_uuid: string;
  domain: string; // domain name (9505.ip-com.co.il)
  createTime: Date;
}

export interface SmsBilling extends BillingResource {
  id: number;
  sourceApp: SourceAppType;
  profileName: string;
  smsTo: string;
  smsCountForBilling: number;
  smsText: string;
  smsFrom: string;
  billingMonth: string;
}

export type SourceAppType = 'Dialplan' | 'PractiDialer';

export type NetworkObjectType = 'RouterNIC' | 'Switch' | 'AccessPoint' | 'Other';
export type NetworkObjectStatus = 'Active' | 'Inactive';

export interface NetworkObject {
  id: string; // eg: מתג ראשי
  name: string; // eg: מתג ראשי
  type: NetworkObjectType;
  component: string; // for type=RouterNIC eg: ADSL 1, ADSL 2, LTE. For type=Other eg: טלפון אלחוטי, מחשב ביקום ראשי
  status: NetworkObjectStatus;
  statusDate: Date; // the time of status change
  ipAddress: string; // nn.nn.nn.nn
  macAddress: string; // xx:xx:xx:xx:xx:xx
  portsNumber: number | undefined; // relevant only for מתגים
  location: string; // eg: בר כניסה אחורית
}
