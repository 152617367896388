import { useEffect, useState } from "react";
import { SmsBilling } from "./types";
import { secureFetch } from "../auth/server-auth";

const BILLING_API_URL = process.env.REACT_APP_BILLING_API_URL;

export const useBilling = <T>(filters: Record<string, any>, type: string, domainUuid?: string | undefined) => {

  const [data, setData] = useState<SmsBilling[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!filters.fromDate || !filters.toDate) {
        // console.error('missing fromDate or toDate in use-billing.ts');
        return;
      }
      setLoading(true);
      let url = `${BILLING_API_URL}/billing/sms/dom/${domainUuid}`;

      if (Object.keys(filters).length) {
        // Append query string to url
        url += '?' +
          Object.entries(filters)
            .filter(([key, value]) => value !== '' && value !== undefined)
            .map(([key, value]) => `${key}=${value}`).join('&');
      }

      try {
        const { res } = await secureFetch(url);
        if (res.status === 200) {
          const jsonData = await res.json();
          setData(jsonData.data);
        }
      } catch (err) {
        console.error('Error: ', err);
      } finally {
        setLoading(false);
      }
    }
    
    if (!domainUuid) {
      return;
    }
    fetchData()
    .catch(e => console.error('Error: ', e));
  }, [filters, domainUuid]);
  
  return { data, loading };
}