import React from 'react';
import { Icon, IconProps } from './icon';

export const PhoneLeft = ({...props}: IconProps) => (
  <Icon 
    viewBox="0 0 96 96"
    fill="currentColor"
    {...props}
  >
    <path fillRule="evenodd" d="M80,16 C80,50.9927617 51.9164341,79.4262552 17.058357,79.9914258 L16,80 L16,64 L34,58 L42,66 C52.0507454,60.9746273 60.2617582,52.9257233 65.4874267,42.9988997 L66,42 L58,34 L64,16 L80,16 Z"/>
  </Icon>
);


