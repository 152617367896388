import { useState } from "react";
import { useUsers } from "../api/use-users";
import { Spinner } from "../design/spinner";
import { Checkbox } from "../design/checkbox/checkbox";
import { Search } from "../design/search/search";
import { Table } from "../design/table/table";
import { AppLayout, AppLayoutContainer, AppLayoutHeader, AppLayoutTitle } from "../layouts/app-layout/app-layout";
import { Page } from "../page";
import styles from "./user-management.module.scss";
import { Column } from "../design/table/types";

const CHECKBOX_TITLE = `הצגת משתמשים עם מולטי דומיין בלבד`;

export const UserManagement = () => {

  const { users, loading } = useUsers();
  const [filterMultiDomainUsers, setFilterMultiDomainUsers] = useState(false);
  const [searchString, setSearchString] = useState<string>('');

  // Count distinct users from filtered list
  const emails = users?.map(user => user.user_email);

  const numUsers = emails?.filter((email, index) => {
    return emails.indexOf(email) === index
  })?.length;

  const searchFilter = (user: ListItem) => {
    return (
      user.user_name?.toLowerCase().includes(searchString.toLowerCase()) ||
      user.user_email?.toLowerCase().includes(searchString.toLowerCase()) ||
      user.domain_name?.toLowerCase().includes(searchString.toLowerCase()) ||
      user.domain_owner_name?.toLowerCase().includes(searchString.toLowerCase())
    );
  }

  const filteredData = users
  ?.flatMap((user) => {
    /* Users will be mapped to arrays of size 1,
      unless they have more than 1 domain (Multi-Tenants) */
    return user.domains.map((domain) => {
      const domainNumber = domainShortName(domain.domain_name);
      return {
        id: `${user.user_email}_${domain.domain_uuid}`,
        user_name: user.user_name,
        user_email: user.user_email,
        domain_name: domainNumber,
        domain_owner_name: domain.domain_owner_name,
        is_multi_tenant: (user.domains.length > 1)
      }
    });
  })
  ?.filter((user) => {
    if (filterMultiDomainUsers) {
      return user.is_multi_tenant && searchFilter(user)
    }

    return searchFilter(user);
  });

  const columns: Array<Column<ListItem, keyof ListItem>> = [
    {
      key: "user_name",
      header: 'שם משתמש',
      className: styles.userName
    },
    {
      key: "user_email",
      header: 'כתובת מייל',
      className: styles.userEmail
    },
    {
      key: "domain_name",
      header: 'דומיין',
      className: styles.domainName
    },
    {
      key: "domain_owner_name",
      header: 'לקוח',
      className: styles.domainOwnerName
    }
  ];

  return (
    <Page name='User Management'>
      <AppLayout>
        <AppLayoutContainer>
          <AppLayoutHeader>
            <AppLayoutTitle>ניהול משתמשים</AppLayoutTitle>
            <div className={styles.headerContainer}>
              {numUsers ?
                <b style={{alignSelf: 'end'}}>{numUsers} משתמשים</b> :
                <b></b>
              }

              <Search
                placeholder="חיפוש..."
                onChange={(e) => setSearchString(e.target.value)}
                onReset={() => setSearchString('')}
                value={searchString || ''}
              />
            </div>
          </AppLayoutHeader>
          
          {
            loading ? (
              <div className={styles.loading}>
                <Spinner />
              </div>
            ) : 
            (
              <>
                <div className={styles.checkbox}>
                  <Checkbox 
                    title={CHECKBOX_TITLE}
                    onChange={() => setFilterMultiDomainUsers(!filterMultiDomainUsers)}
                  />
                </div>
                
                <Table showHeaders={true} data={filteredData} columns={columns} />
              </>
            )
            
          }
        </AppLayoutContainer>
      </AppLayout>
    </Page>
  );
}

const domainShortName = (domain: string) => {
  return domain.split('.')[0];
}

interface ListItem {
  id: string,
  user_name: string,
  user_email: string,
  domain_name: string,
  domain_owner_name: string,
  is_multi_tenant: boolean
    
}