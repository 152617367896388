
import { Icon, IconProps } from './icon';

export const ChevronLeft = ({ ...props }: IconProps) => (
  <Icon
    fill="currentColor"
    {...props}
  >
    <polygon fillRule="evenodd" points="15.41 7.41 14 6 8 12 14 18 15.41 16.59 10.83 12" />
  </Icon>
);