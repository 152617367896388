import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { MonitorProps } from '../..';
import { HorizontalPartition } from '../../../design/partition/horizontal-partition';
import { getDigitTimeFromMillis } from '../../../utils';
import { Card } from '../../common/components';
import { CallAttributes } from '../../interfaces';
import { getWaitingCalls } from '../../utils/callFilters';
import { getCallerName } from '../../utils/getCallerName';
import { getWaitingTime } from '../../utils/getWaitingTime';
import { TextElement } from '../TextElement/text-element';
import { PageHeader } from '../mobile-monitor';
import styles from './waiting-calls-page.module.scss';

interface WaitingCallsPageProps extends MonitorProps { onBackBtnClick: () => void; }

export const WaitingCallsPage: React.FC<WaitingCallsPageProps> = ({ ...props }) => {
  
const calls = getWaitingCalls(props.data.calls);
  const [, setCurrentTime] = useState<number>(Date.now());

  useEffect(() => {
    const intervalId: NodeJS.Timer = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    }
  }, []);

  return (
    <div className={styles.layout}>
      <PageHeader name='שיחות ממתינות' onBackBtnClick={props.onBackBtnClick} />
      <div className={styles.cardsList}>
        {
          calls.map(call => {
            return (
              <WaitingCallCard
                key={call.id}
                call={call}
                wait_time_threshold={props.monitorConfig.waiting_alert_threshold}
              />
            )
          })
        }
      </div>
    </div>
  );
};


interface WaitingCallCardProps {
  call: CallAttributes;
  wait_time_threshold: number;
}

const WaitingCallCard: React.FC<WaitingCallCardProps> = ({ call, wait_time_threshold }) => {


  const callerName = getCallerName(call);
  const waitingTime = Date.now() - getWaitingTime(call);

  return (
    <Card className={styles.card}>

      <TextElement
        label={'מתקשר'}
        value={callerName}
      />

      <HorizontalPartition />

      <div className={styles.labelAndValueContainer}>
        <span className={styles.label}>{ 'זמן המתנה' }</span>
        <span className={classNames(styles.value, { [styles.exceedTimeLimit]: waitingTime > wait_time_threshold * 1000 })}>
          {getDigitTimeFromMillis(waitingTime, 'MM:SS')}
        </span>
      </div>
    </Card>
  )
}