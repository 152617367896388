type uuid = string;

// see comments about columns in repo emergency-dialer, file emergency-dialer.sql, table base_dialers
export interface BaseDialer {
  //general
  dialer_id: number;
  domain_uuid: uuid;
  name: string;
  enabled: boolean;
  max_active: number;
  priority: number;
  business_number: string;
  business_number_for_sms?: string | undefined;
  language: string;
  speaker: string;
  // customer (also callee)
  cust_dial_prefix?: string | undefined;
  cust_max_attempts: number;
  cust_dial_interval: number;
  cust_max_ringing: number;
  cust_prompt_file_name?: string | undefined;
  cust_prompt_file_url?: string | undefined;
  // billing
  package_cust_calls?: number | undefined;
}

// see comments about columns in repo emergency-dialer, file emergency-dialer.sql, table emergency_dialers
export interface EmergencyDialer extends BaseDialer {
  // general
  domain_uuid: uuid;
  max_event_age: number;
  default_event_cancel_type: DialerEventCancelType;
  // Launcher
  launch_code: string;
  // Callee: phone
  interact_by_phone: boolean;
  cust_cancelation_prompt_file_name?: string | undefined;
  cust_cancelation_prompt_file_url?: string | undefined;
  // Callee: sms
  interact_by_sms: boolean;
  callee_sms_text?: string | undefined;
  // reports
  send_realtime_emails: boolean;
  realtime_recipients?: string | undefined;
  send_summary_emails: boolean;
  summary_recipients?: string | undefined;
  minutes_billing_code?: number | undefined;
  sms_billing_code?: string | undefined;
  package_cust_sms?: number | undefined;
  package_cust_minutes?: number | undefined;
}

export interface DialGroup extends EmergencyDialer {
  callee_responses: CalleeResponse[];
  dialer_name_file_name: string;
  dialer_name_file_url: string;
}

// see comments about columns in repo emergency-dialer, file emergency-dialer.sql, table callback_dialers
export interface CallbackDialer extends BaseDialer {
  // general
  time_condition?: string | undefined;
  dial_first_to: 'agent' | 'customer';
  max_request_age: number;
  pushy_request_age?: number | undefined;
  calls_recorded: boolean;
  blacklist: string[] | null;
  international_calls_permitted: boolean;
  active_now: boolean;
  // agent
  agent_group_number: string;
  agent_max_attempts: number;
  agent_dial_interval: number;
  agent_max_ringing: number;
  agent_approval_key?: string | undefined;
  agent_abort_call_key?: string | undefined;
  agent_complete_call_key?: string | undefined;
  agent_continuous_call: boolean;
  agent_play_cust_number: boolean;
  agent_play_num_requests: boolean;
  agent_greeting_file_name: string;
  agent_greeting_file_url: string;
  // incoming call
  incoming_call_enabled: boolean;
  incoming_call_int_number?: string | undefined;
  incoming_call_permitted?: string[] | undefined;
  // customer
  cust_min_duration: number;
  cust_approval_key?: string | undefined;
  sms_text_between_attempts_enabled: boolean;
  sms_text_between_attempts?: string | undefined;
  sms_text_at_failure_enabled: boolean;
  sms_text_at_failure?: string | undefined;
  sms_text_at_start_enabled: boolean;
  sms_text_at_start?: string | undefined;
  cust_prompt_file_name: string;
  cust_prompt_file_url: string;
  //
  create_request_on_abandon: boolean;
  delete_request_on_answer: boolean;
  min_time_for_abandon?: number | undefined;
  min_time_for_answer?: number | undefined;
  allow_change_mobile_number: boolean;
  allow_change_landline_number: boolean;
  approve_request_created: boolean;
  cust_request_created_file_name: string;
  cust_request_created_file_url: string;
  sms_report_text?: string | undefined;
  sms_report_sender_name?: string | undefined;
  sms_report_destination_numbers?: string[] | undefined;
  mail_report_text?: string | undefined;
  mail_report_title?: string | undefined;
  mail_report_sender_name?: string | undefined;
  mail_report_destination_addresses?: string[] | undefined;
  interact_by_sms: boolean;
  interact_by_mail: boolean;
  // Check this field
  package_cust_sms?: number | undefined;
  package_cust_minutes?: number | undefined;
  minutes_billing_code?: number | undefined;
  sms_billing_code?: string | undefined;
}

export interface Callee {
  callee_uuid: string;
  domain_uuid: string;
  full_name: string;
  phone_number1?: string | undefined;
  phone_number2?: string | undefined;
  phone_number3?: string | undefined;
  job_title_id: number;
  id_number: string;
  dial_groups: number[];
  city: string;
  enabled?: boolean;
  create_date?: Date | undefined;
  update_date?: Date | undefined;
}

export interface Launcher {
  launcher_uuid: string;
  domain_uuid: string;
  user_uuid?: string | undefined;
  full_name: string;
  phone_number: string;
  dial_groups: number[];
  is_global: boolean;
  create_date?: Date | undefined;
  update_date?: Date | undefined;
}

export interface JobTitle {
  job_title_id: number;
  description: string;
}

export type AttemptStatus =
  | 'dialing' // attempt is active
  | 'answered' // attempt ended successfully
  | 'failed'; // attempt failed with error

export type AttemptCompletionCode =
  // customer
  | 'customer_cannot_dial'
  | 'customer_no_answer'
  | 'customer_busy'
  | 'customer_ivr'
  | 'customer_fax'
  | 'customer_hangup'
  // agent
  | 'agent_cannot_dial'
  | 'agent_no_answer'
  | 'agent_busy'
  | 'agent_ivr'
  | 'agent_fax'
  | 'agent_hangup'
  // other
  | 'admin_success'
  | 'admin_error'
  | 'success'
  | 'failed';

export interface DialerAttempt {
  attempt_uuid: string;
  request_uuid: string;
  call_uuid: string;
  destination: string;
  attempt_status: AttemptStatus;
  completion_code?: AttemptCompletionCode | undefined;
  start_date: Date;
  answer_date?: Date | undefined;
  end_date?: Date | undefined;
  agent_number?: string | undefined;
  agent_name?: string | undefined;
  sms_text?: string | undefined;
  has_recording: boolean;
}

export interface DialerGlobalSettings {
  domain_uuid: string;
  business_numbers: string[];
  // launcher general
  launch_code_max_attempts: number;
  // Callee: sms
  real_prefix_sms_text?: string | undefined;
  drill_prefix_sms_text?: string | undefined;
  cancelation_prefix_sms_text?: string | undefined;
}

export interface DialerGlobalSettingsWithFiles extends DialerGlobalSettings {
  trauma_already_canceling_file_name: string;
  trauma_already_canceling_file_url: string;
  trauma_ask_when_cancel_active_file_name: string;
  trauma_ask_when_cancel_active_file_url: string;
  trauma_ask_when_drill_active_file_name: string;
  trauma_ask_when_drill_active_file_url: string;
  trauma_ask_when_no_active_file_name: string;
  trauma_ask_when_no_active_file_url: string;
  trauma_ask_when_real_active_file_name: string;
  trauma_ask_when_real_active_file_url: string;
  trauma_bye_file_name: string;
  trauma_bye_file_url: string;
  trauma_cancelation_canceled_file_name: string;
  trauma_cancelation_canceled_file_url: string;
  trauma_cancelation_confirm_file_name: string;
  trauma_cancelation_confirm_file_url: string;
  trauma_cancelation_prefix_file_name: string;
  trauma_cancelation_prefix_file_url: string;
  trauma_cancelation_rejected_file_name: string;
  trauma_cancelation_rejected_file_url: string;
  trauma_cancelation_type_file_name: string;
  trauma_cancelation_type_file_url: string;
  trauma_confirm_launch_file_name: string;
  trauma_confirm_launch_file_url: string;
  trauma_drill_prefix_file_name: string;
  trauma_drill_prefix_file_url: string;
  trauma_enter_launch_code_file_name: string;
  trauma_enter_launch_code_file_url: string;
  trauma_event_cancel_failed_file_name: string;
  trauma_event_cancel_failed_file_url: string;
  trauma_event_canceled_file_name: string;
  trauma_event_canceled_file_url: string;
  trauma_event_start_failed_file_name: string;
  trauma_event_start_failed_file_url: string;
  trauma_event_started_file_name: string;
  trauma_event_started_file_url: string;
  trauma_hakasha_shguya_file_name: string;
  trauma_hakasha_shguya_file_url: string;
  trauma_launch_cancel_cancelation_file_name: string;
  trauma_launch_cancel_cancelation_file_url: string;
  trauma_launch_cancelation_file_name: string;
  trauma_launch_cancelation_file_url: string;
  trauma_launch_code_invalid_file_name: string;
  trauma_launch_code_invalid_file_url: string;
  trauma_launch_code_not_exists_file_name: string;
  trauma_launch_code_not_exists_file_url: string;
  trauma_launch_drill_file_name: string;
  trauma_launch_drill_file_url: string;
  trauma_launch_real_file_name: string;
  trauma_launch_real_file_url: string;
  trauma_real_prefix_file_name: string;
  trauma_real_prefix_file_url: string;
  trauma_unauthorized_file_name: string;
  trauma_unauthorized_file_url: string;
  trauma_unauthorized_launch_code_file_name: string;
  trauma_unauthorized_launch_code_file_url: string;
  trauma_welcome_file_name: string;
  trauma_welcome_file_url: string;
}

export interface CalleeResponse {
  response_key: number;
  response_type: 'approve' | 'decline' | undefined;
  response_text: string;
}

export interface DialerEventKey {
  domain_uuid: uuid;
  dialer_id: number;
  event_id: number;
}

export interface DialerEvent extends DialerEventKey {
  domain_name: string;
  start_date: Date;
  end_date?: Date | undefined;
  expiration_date: Date;
  dialer_name: string;
  event_type: DialerEventType;
  event_status: DialerEventStatus;
  num_of_callees: number;
  num_of_approvals: number;
  num_of_rejections: number;
  launcher_uuid: uuid;
  launcher_name: string;
  launch_code: string;
  launch_medium: DialerLaunchMedium;
  locker?: string | undefined; // for housekeeping
  enabled: boolean;
}

export type DialerLaunchMedium = 'phone' | 'app';

export interface DialerRequest extends Callee, CalleeResponse {
  request_uuid: string;
  domain_uuid: string;
  dialer_profile_id: number;
  dialer_id: number;
  dialer_sms_profile_id: number;
  enabled: boolean;
  request_priority: number;
  business_number: string;

  //request transient state
  request_status: DialerRequestStatus;
  completion_code: 'success' | 'expired' | 'too_many_attempts'; // set when request fails
  in_call: boolean; // for dialer internal use

  //dates
  start_date: Date; // this rather than create_date is used by Hadshanut
  last_attempt_date: Date; // set when attempt ends
  end_date: Date; // set when request ends for whatever reason

  //customer
  max_attempts: number; // attempts that reached customer
  num_attempts: number; // attempts that reached customer
  dial_interval: number; // minutes between dial to customer
  caller_number: string; // number that made the dialer request, for Callback dialer
  return_number: string; // number to which the request needs to call

  //agent, for Callback dialer
  agent_max_attempts: number; // attempts that reached agent but not customer
  agent_num_attempts: number; // attempts that reached agent but not customer
  agent_dial_interval: number; // minutes between dial to agent
  agent_group_number: string;
  agent_group_name: string;

  //event & callee, for Emergency dialer
  event_id: number;
  callee_uuid: string;
  response_media?: 'sms' | 'phone';
  response_date?: Date | undefined;
  callee_sms_phone?: string | undefined;
}

export type DialerRequestStatus = 'waiting' | 'active' | 'completed' | 'failed' | 'canceled';

export type DialerEventType = 'emergency' | 'drill' | 'cancelation';

export type DialerEventStatus = 'active' | 'canceled' | 'expired' | 'completed';

export type DialerEventCancelType = 'quiet' | 'partial' | 'full';

export const TranslateEventStatus: Record<DialerEventStatus, string> = {
  active: 'פעיל',
  canceled: 'בוטל',
  expired: 'פג-תוקף',
  completed: 'הסתיים',
};

export const TranslateDialRequestStatus: Record<DialerRequestStatus, string> = {
  waiting: 'בין נסיונות חיוג',
  active: 'מחייג',
  completed: 'הסתיים בהצלחה',
  failed: 'נכשל',
  canceled: 'בוטל',
};

export const DialerEventCancelationTypes: Record<
  DialerEventCancelType,
  { value: string; label: string; description: string }
> = {
  quiet: {
    value: 'quiet',
    label: 'ביטול שקט',
    description: 'האירוע יבוטל אך לא נתריע לאף כונן על הביטול',
  },
  partial: {
    value: 'partial',
    label: 'עדכון חלקי',
    description: 'האירוע יבוטל ונעדכן רק כוננים שכבר קיבלו שיחת הקפצה',
  },
  full: {
    value: 'full',
    label: 'עדכון מלא',
    description: 'האירוע יבוטל ונתריע לכל הכוננים שהיה אירוע שבוטל',
  },
};
