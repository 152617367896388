import { Amplify, Auth } from "aws-amplify";

const config = {
  Auth: {
    identityPoolId: process.env.REACT_APP_AUTH_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
    // cookieStorage: {
    //   domain: process.env.REACT_APP_AUTH_COOKIES_STORAGE_DOMAIN || window.location.hostname,
    //   secure: process.env.REACT_APP_AUTH_COOKIES_STORAGE_SECURE?.toLowerCase() === 'true',
    //   path: process.env.REACT_APP_AUTH_COOKIES_STORAGE_PATH,
    //   expires: parseInt(process.env.REACT_APP_AUTH_COOKIES_STORAGE_EXPIRES || '365'),
    // },
  },
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_WEB_API_NAME,
        endpoint: process.env.REACT_APP_WEB_API_URL,
        custom_header: async function getInitialHeader() {
          try {
            const session = await Auth.currentSession();
            const token = session
              .getIdToken()
              .getJwtToken();

            return {
              'Authorization': `Bearer ${token}`
            };
          } catch (e) {
            return {}
          }
        },
      },
      {
        name: process.env.REACT_APP_CALLS_HISTORY_API_NAME,
        endpoint: process.env.REACT_APP_CALLS_HISTORY_API_URL,
        custom_header: async function getInitialHeader() {
          try {
            const session = await Auth.currentSession();
            const token = session
              .getIdToken()
              .getJwtToken();

            return {
              'Authorization': `Bearer ${token}`
            };
          } catch (e) {
            return {}
          }
        },
      },
      {
        name: process.env.REACT_APP_PROCESS_DIALER_API_NAME,
        endpoint: process.env.REACT_APP_PROCESS_DIALER_API_URL,
        custom_header: async function getInitialHeader() {
          try {
            const session = await Auth.currentSession();
            const token = session
              .getIdToken()
              .getJwtToken();

            return {
              'Authorization': `Bearer ${token}`
            };
          } catch (e) {
            return {}
          }
        },
      },
      {
        name: process.env.REACT_APP_EXPORT_REPORT_API_NAME,
        endpoint: process.env.REACT_APP_EXPORT_REPORT_API_URL,
        custom_header: async function getInitialHeader() {
          try {
            const session = await Auth.currentSession();
            const token = session
              .getIdToken()
              .getJwtToken();

            return {
              'Authorization': `Bearer ${token}`
            };
          } catch (e) {
            return {}
          }
        },
      },
      {
        name: process.env.REACT_APP_PHP_SERVICES_NAME,
        endpoint: process.env.REACT_APP_PHP_SERVICES_URL,
        custom_header: async function getInitialHeader() {
          try {
            const session = await Auth.currentSession();
            const token = session
              .getIdToken()
              .getJwtToken();

            return {
              'Authorization': `Bearer ${token}`
            };
          } catch (e) {
            return {}
          }
        },
      },
      {
        name: process.env.REACT_APP_MULTI_TENANT_API_NAME,
        endpoint: process.env.REACT_APP_MULTI_TENANT_API_URL,
        custom_header: async function getInitialHeader() {
          try {
            const session = await Auth.currentSession();
            const token = session
              .getIdToken()
              .getJwtToken();

            return {
              'Authorization': `Bearer ${token}`
            };
          } catch (e) {
            return {}
          }
        },
      },
      {
        name: process.env.REACT_APP_WHATSAPP_BOT_API_NAME,
        endpoint: process.env.REACT_APP_WHATSAPP_BOT_API_URL,
        custom_header: async function getInitialHeader() {
          try {
            const session = await Auth.currentSession();
            const token = session
              .getIdToken()
              .getJwtToken();

            return {
              'Authorization': `Bearer ${token}`
            };
          } catch (e) {
            return {}
          }
        },
      },
      {
        name: process.env.REACT_APP_EMERGENCY_DIALER_API_NAME,
        endpoint: process.env.REACT_APP_EMERGENCY_DIALER_API_URL,
        custom_header: async function getInitialHeader() {
          try {
            const session = await Auth.currentSession();
            const token = session
              .getIdToken()
              .getJwtToken();

            return {
              'Authorization': `Bearer ${token}`
            };
          } catch (e) {
            return {}
          }
        },
      },
      {
        name: process.env.REACT_APP_CCM_API_NAME,
        endpoint: process.env.REACT_APP_CCM_API_URL,
        custom_header: async function getInitialHeader() {
          try {
            const session = await Auth.currentSession();
            const token = session
              .getIdToken()
              .getJwtToken();

            return {
              'Authorization': `Bearer ${token}`
            };
          } catch (e) {
            return {}
          }
        },
      },
      {
        name: 'invitations',
        endpoint: process.env.REACT_APP_INVITATION_API_URL,
        // custom_header: async function getInitialHeader() {
        //   try {
        //     const session = await Auth.currentSession();
        //     const token = session
        //       .getIdToken()
        //       .getJwtToken();

        //     return {
        //       'Authorization': `Bearer ${token}`
        //     };
        //   } catch (e) {
        //     return {}
        //   }
        // },
      }
    ],
  }
};

Amplify.configure(config);