import { atom } from 'recoil';
import { UserResource } from './api/types';

export const authenticatedState = atom<boolean>({
  key: 'authenticatedState',
  default: false,
});

export const unreadCustomerUpdatesState = atom<boolean>({
  key: 'unreadCustomerUpdatesState',
  default: false,
});

export const hadshanutUserState = atom<UserResource | undefined>({
  key: 'hadshanutUser',
  default: undefined,
});
