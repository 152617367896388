import React, { FC, cloneElement } from 'react';
import { FieldConfig, useField } from 'formik';

import styles from './field.module.scss';

export interface FieldProps {
  description?: string;
  label?: string;
  required?: boolean;
  onChange?: (value: string) => void;
}

export const DateField: FC<FieldProps & FieldConfig> = ({ children, description, label, onChange, required = false, ...props }) => {
  const [field, meta, helper] = useField(props);
  const handleChange = (value: string) => {
    if(onChange) onChange(value);
    helper.setValue(value);
  }

  const control = cloneElement(
    children as React.ReactElement<any>,
    { ...field, onChange: handleChange, className: styles.control }
  );
  

  if (label) {
    return (
      <label
        className={styles.root}
      >
        <span className={styles.label}>{label}</span>
        {description && <span className={styles.description}>{description}</span>}
        {control}
        {meta.error && meta.touched && <span className={styles.error}>{meta.error}</span>}
      </label>
    )
  }
  return (
    <>
      {control}
      {meta.error && meta.touched && <span className={styles.error}>{meta.error}</span>}
    </>
  );
}