import React from "react";
import { useDestinations } from "../../api/use-destinations";
import { useMe } from "../../api/use-me";
import { FallbackNode } from "./fallback-node";
import { MenuNode } from "./menu-node/menu-node";
import { MessageNode } from "./message-node/message-node";
import { TimeConditionNode } from "./time-condition-node";

export function DestinationNode(props: DestinationNodeProps) {

  const user = useMe();
  const destinations = useDestinations(user?.data);
  const destination = destinations.data ? destinations.data[props.destination] : null;

  switch(destination?.type) {
    case 'menu': return <MenuNode {...props} />;
    case 'message': return <MessageNode {...props} />;
    case 'TimeCondition': return <TimeConditionNode {...props} />;
    default: return <FallbackNode {...props} />;
  }
}

export interface DestinationNodeProps {
  destination: string;
  title?: string;
  overline?: string;
  className?: string;
}