import { styled } from '@mui/material';

export const StyledCard = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(5)} ${theme.spacing(5)} ${theme.spacing(7)}`,
}));

export const CallBackDialerWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.backgrounds.bg_modal,
  padding: `${theme.spacing(34.5)} ${theme.spacing(11)} ${theme.spacing(34.5)} ${theme.spacing(31.5)}`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  rowGap: theme.spacing(20),
  flexGrow: 2,
  overflowY: 'scroll',
}));

export const StyledFormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
}));

export const StyledCardsContainer = styled('div')(({ theme }) => ({
  '&.general-settings': {
    display: 'flex',
    flexDirection: 'column',
    marginBlockStart: theme.spacing(2.5),
    width: '100%',
  },
}));

export const StyledFieldWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  rowGap: theme.spacing(2.5),
}));

export const StyledUploadFileContainer = styled('div')(({ theme }) => ({
  maxWidth: '655px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  padding: `${theme.spacing(6)} ${theme.spacing(6)} ${theme.spacing(8)}`,
  backgroundColor: theme.palette.grey[50],
  borderRadius: theme.borderRadius.main,
  marginBottom: theme.spacing(6),
}));

export const StyledMessageRecordWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '480px',
  width: '100%',
}));

export const StyledDeleteRecord = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(3),
}));

export const StyledContent = styled('div')(({ theme }) => ({
  display: 'flex',
  rowGap: theme.spacing(2),
}));

export const StyledFormPermissions = styled('div')(({ theme }) => ({
  backgroundColor: 'transparent',
  position: 'absolute',
  top: theme.spacing(16),
  right: theme.spacing(11),
}));
