import React from 'react';
import { Icon, IconProps } from './icon';

export const Search = ({ ...props }: IconProps) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      d="M9.5,3 C13.0966667,3 16,5.90333333 16,9.5 C16,10.91454 15.5509166,12.2218361 14.7871927,13.2874458 L20.49,19 L19,20.49 L13.2874458,14.7871927 C12.2218361,15.5509166 10.91454,16 9.5,16 C5.90333333,16 3,13.0966667 3,9.5 C3,5.90333333 5.90333333,3 9.5,3 Z M9.5,14 C7.01,14 5,11.99 5,9.5 C5,7.01 7.01,5 9.5,5 C11.99,5 14,7.01 14,9.5 C14,11.99 11.99,14 9.5,14 Z"
      fillRule="evenodd"
    ></path>
  </Icon>
);
