import { API } from 'aws-amplify';
import { useRecoilValue } from 'recoil';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { NavigationType, useLocation, useNavigationType, useParams } from 'react-router-dom';

import { Page } from '../page';
import { Menu } from './menu/menu';
import styles from './index.module.scss';
import { Button } from '../design/button';
import { DesktopMonitor } from './monitor';
import { tabletQuery } from '../theme/media';
import { useGroups } from '../api/use-groups';
import { hadshanutUserState } from '../state';
import { useStore } from './web-socket/useStore';
import { useBreakTypes } from './use-break-types';
import { Spinner } from '../design/spinner/spinner';
import { useURLParams } from '../app/use-url-params';
import { MobileMonitor } from './mobile/mobile-monitor';
import { useWebSocket } from './web-socket/useWebSocket';
import { DEFAULT_CCM_CONFIG } from './configuration/monitor-config';
import { usePersistentStorageValue } from '../hooks/useLocalStorage';
import { GroupResource, PermissionGroup, UserResource } from '../api/types';
import { TabTitle } from './common/components/tables-container/tables-container';
import { toGroupNumbersArray, toGroupUuidsArray } from './utils/transformGroups';
import { AgentAttributes, BreakType, CallAttributes, DialerRequestAttributes } from './interfaces';
import { MonitorConfiguration, MonitorConfigurationReturnType, useMonitorConfig } from './use-monitor-config';
import {
  AppLayout,
  AppLayoutContainer,
  AppLayoutHeader,
  AppLayoutTitle,
  useMediaQuery,
} from '../layouts/app-layout/app-layout';

const mobilePages: TabTitle[] = [
  'agents',
  'callback-requests',
  'inbound-calls',
  'outbound-calls',
  'unanswered-calls',
  'waiting-calls',
];

export const MonitorContainer: React.FC = () => {
  const user = useRecoilValue(hadshanutUserState);
  const monitorConfig = useMonitorConfig();
  const data = useStore(monitorConfig.data);
  const { data: breakTypes } = useBreakTypes();
  const { data: groups } = useGroups(user?.domain.id);
  const isTabletOrMobile = useMediaQuery(tabletQuery);
  const selfManagedGroups = useMemo(
    () => groups?.filter((group) => user?.domain?.config?.selfManagedGroups?.includes(group.id)) ?? [],
    [groups]
  );

  const initialConfigError =
    monitorConfig &&
    monitorConfig?.error &&
    monitorConfig.error?.response &&
    monitorConfig.error.response?.status === 404;

  if (
    !user ||
    !groups ||
    isTabletOrMobile === undefined ||
    breakTypes === undefined ||
    (monitorConfig.data === undefined && !initialConfigError)
  ) {
    return (
      <div className={styles.loadingMessage}>
        <Spinner />
      </div>
    );
  }

  return (
    <MonitorPage
      user={user}
      groups={selfManagedGroups}
      isTabletOrMobile={isTabletOrMobile}
      breakTypes={breakTypes}
      monitorConfig={monitorConfig}
      data={data}
    />
  );
};

interface MonitorPageProps {
  user: UserResource;
  groups: GroupResource[];
  isTabletOrMobile: boolean;
  breakTypes: BreakType[];
  monitorConfig: MonitorConfigurationReturnType;
  data: {
    agents: AgentAttributes[];
    calls: CallAttributes[];
    dialerRequests: DialerRequestAttributes[];
  };
}

export const MonitorPage: React.FC<MonitorPageProps> = memo(
  ({ user, groups, isTabletOrMobile, breakTypes, monitorConfig, data }) => {
    const [fullScreen, setFullScreen] = useState<boolean>(false);
    const params = useParams<any>()['*']?.replace(/^\/|\/$/g, '');
    const url = useURLParams({ path: '/monitor', params });

    const isDesktop = !isTabletOrMobile;
    const isSupport = user.groups?.includes(PermissionGroup.Support);
    const domainUuid = user.domain.id;
    const userUuid = user.id;
    const allGroupUuids = groups.map(g => g.id);

    const getSelectedGroupsFromUrl = useCallback(() => {
      const selectedGroupsFromURL: string[] | undefined = url.urlParams.groups?.split(',');
      if (selectedGroupsFromURL && selectedGroupsFromURL.length > 0) {
        return toGroupUuidsArray(selectedGroupsFromURL, groups);
      }

      return allGroupUuids || [];
    }, [allGroupUuids, url.urlParams.groups]);

    const defaultTab = isTabletOrMobile ? 'main' : 'agents';

    const { value: activeTab, setValue: setActiveTabInStorage } = usePersistentStorageValue<TabTitle | 'main'>(
      'active-tab',
      defaultTab,
      'monitor'
    );
    const { value: selectedGroups, setValue: setSelectedGroupsInStorage } = usePersistentStorageValue(
      'groups',
      getSelectedGroupsFromUrl(),
      'monitor'
    );

    const { isConnected } = useWebSocket(domainUuid, userUuid, selectedGroups);

    const location = useLocation();
    const navigationType = useNavigationType();

    useEffect(() => {
      if (navigationType === NavigationType.Pop) {
        // This function runs when the user clicks the "back" navigation button in the browser
        if (isTabletOrMobile && activeTab !== 'main') {
          // forcefully set current page state to main (ONLY FOR MOBILE?)
          setActiveTabInStorage('main');
        }
      }
    }, [location, navigationType, isTabletOrMobile]);

    useEffect(() => {
      let tab: TabTitle | 'main' = 'agents';
      if (params?.length) {
        // When user navigates to /monitor page with params - save those params in localStorage
        const tabInUrl = url.urlParams.tab;
        tab = isTabletOrMobile
          ? mobilePages.includes(tabInUrl)
            ? tabInUrl
            : 'main'
          : tabInUrl === 'main'
          ? 'agents'
          : tabInUrl;

        if (isTabletOrMobile && !mobilePages.includes(tabInUrl)) {
          // handle mobile tab param
          url.setURLParam('tab', 'main');
          setActiveTabInStorage('main');
        } else if (isDesktop && tab !== 'main') {
          // handle desktop tab param
          setActiveTabInStorage(tab);
        }

        const groupUuidsFromUrl = getSelectedGroupsFromUrl();
        // Store groups in local storage
        if (groupUuidsFromUrl.length) {
          setSelectedGroupsInStorage(groupUuidsFromUrl);
        } else {
          setSelectedGroupsInStorage(allGroupUuids)
        }

      } else {
        // When user navigates to /monitor without any parameters
        tab = isTabletOrMobile ? 'main' : activeTab;

      }
      const selectedGroupNumbers = toGroupNumbersArray(selectedGroups, groups);

      // Override groups in url if needed:
      const groupNumbersForUrl = selectedGroupNumbers.length === 0 ? null : selectedGroupNumbers;

      const newUrlParams = Object.fromEntries([
        ['tab', tab],
        ['groups', groupNumbersForUrl],
      ]);

      url.setURLParams(newUrlParams);
    }, [activeTab]);

    const handleSelectPage = useCallback(
      (tab: TabTitle | 'main') => {
        setActiveTabInStorage(tab);
        url.setURLParam('tab', tab);
      },
      [groups, url.setURLParam]
    );

    // this function is for the mobile monitor only
    const handleBackBtnClick = useCallback(() => {
      if (activeTab === 'main') {
        return;
      }
      setActiveTabInStorage('main');
      url.setURLParam('tab', 'main');
    }, [activeTab]);

    const insertMonitorConfigRecord = async () => {
      await API.post(process.env.REACT_APP_CCM_API_NAME!, '/ccm-configuration', { body: DEFAULT_CCM_CONFIG });
    };

    const stopFullScreen = useCallback(async () => {
      try {
        await document.exitFullscreen();
        setFullScreen(false);
      } catch (err) {
        console.error(err);
      }
    }, [setFullScreen]);

    const startFullScreen = useCallback(async () => {
      try {
        await document.body.requestFullscreen();
        setFullScreen(true);
      } catch (err) {
        console.error(err);
      }
    }, [setFullScreen]);

    const toggleFullScreen = useCallback(async () => {
      if (fullScreen) {
        await stopFullScreen();
      } else {
        await startFullScreen();
      }
    }, [fullScreen]);

    const handleSelectGroup = useCallback(
      (groupUuids: string[]) => {
        if (groupUuids.length === 0 || groupUuids.length === groups.length) {
          // if all groups are filtered (same as 0 groups are filtered) remove the parameter from url
          url.removeURLParam('groups');
        } else {
          // if given groups count is in range [1, selfManagedGroups.length - 1] - set the group numbers in url
          url.setURLParam('groups', toGroupNumbersArray(groupUuids, groups));
        }
        // if given groups count is greater than 0 - set it in local storage
        // otherwise - set all groups in local storage
        const newGroups = groupUuids.length ? groupUuids : groups.map((group) => group.id);
        setSelectedGroupsInStorage(newGroups);
      },
      [groups, url.setURLParam, url.removeURLParam]
    );

    if (monitorConfig?.error?.response?.status) {
      if (monitorConfig.error.response.status === 404) {
        return (
          <Page name='Monitor'>
            <AppLayout>
              <AppLayoutContainer>
                <AppLayoutHeader>
                  <AppLayoutTitle>מוניטור</AppLayoutTitle>
                </AppLayoutHeader>
                <div className={styles.content}>
                  בעמוד זה אתה יכול לנטר את פעילות המענה הטלפוני האנושי או מוקד השירות, למשל:
                  <ul>
                    <li>צפייה בסטטוס הנציגים במוקד</li>
                    <li>מעקב אחר סטטוס שיחות נוטשות</li>
                    <li>ניטור בזמן אמת של שיחות ממתינות למענה</li>
                  </ul>
                </div>
                <p className={styles.content}>
                  כדי להפעיל את השירות, פנה/י לתמיכה: <a href='mailto:support@ip-com.co.il'>support@ip-com.co.il</a>
                </p>
                {isSupport && <Button onClick={insertMonitorConfigRecord}>הפעלת שירות</Button>}
              </AppLayoutContainer>
            </AppLayout>
          </Page>
        );
      } else {
        return (
          <Page name='Monitor'>
            <AppLayout>
              <AppLayoutContainer>
                <AppLayoutHeader>
                  <AppLayoutTitle>מוניטור</AppLayoutTitle>
                </AppLayoutHeader>
                <div className={styles.content}>לא הצלחנו לטעון את העמוד מסיבה לא ידועה</div>
                <p className={styles.content}>
                  כדי להפעיל את השירות, פנה/י לתמיכה: <a href='mailto:support@ip-com.co.il'>support@ip-com.co.il</a>
                </p>
              </AppLayoutContainer>
            </AppLayout>
          </Page>
        );
      }
    }

    return !data || !groups.length || !monitorConfig.data || !breakTypes ? (
      <div className={styles.loadingMessage}>
        <Spinner />
      </div>
    ) : isTabletOrMobile ? (
      <MobileMonitor
        selectedGroups={selectedGroups}
        onGroupSelection={handleSelectGroup}
        data={{ ...data, groups: groups }}
        monitorConfig={monitorConfig.data}
        breakTypes={breakTypes}
        user={user}
        activeTab={activeTab as TabTitle}
        setCurrentPage={handleSelectPage}
        onBackBtnClick={handleBackBtnClick}
        toggleFullScreen={async () => {}} // remove
        onTabClick={() => {}}
      />
    ) : (
      <div className={styles.pageContainer}>
        {/* החלק הימני עם רשימת סוכנים ודומה */}
        <Menu stopFullScreen={stopFullScreen} data={data.agents} breakTypes={breakTypes} />
        {/* החלק השמאלי שהוא מרבית מסך המוניטור */}
        <DesktopMonitor
          selectedGroups={selectedGroups}
          onGroupSelection={handleSelectGroup}
          toggleFullScreen={toggleFullScreen}
          onTabClick={handleSelectPage}
          data={{ ...data, groups: groups }}
          monitorConfig={monitorConfig.data}
          breakTypes={breakTypes}
          user={user}
        />
      </div>
    );
  }
);

export interface MonitorProps {
  selectedGroups: string[];
  onGroupSelection: (groups: string[]) => void;
  onTabClick: (tab: TabTitle | 'main') => void;
  toggleFullScreen: () => Promise<void>;
  monitorConfig: MonitorConfiguration;
  breakTypes: BreakType[];
  data: {
    agents: AgentAttributes[];
    calls: CallAttributes[];
    dialerRequests: DialerRequestAttributes[];
    groups: GroupResource[];
  };
  user: UserResource;
}
