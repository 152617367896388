import { Node, Edge } from 'reactflow';
import { FileType } from '../../globalTypes/types';

export interface Option {
  userInput: string;
  portId: string;
  data: string;
}

export interface WhatsAppBot {
  name: string;
  id: string;
  enabled: boolean;
  draft_date: Date;
  publish_date?: Date;
  business_number: string | undefined;
  nodes: Node<NodeData>[];
  edges: Edge[];
  viewport: {
    x: number;
    y: number;
    zoom: number;
  };
}

export interface NodeData {
  title: string;
  options: Option[];
  messageContent: string;
  attachment_url: string | undefined;
  attachment_type: FileType | undefined;
  attachment_name: string | undefined;
  active_attachment: AttachmentType | undefined;
  attachment_location: string | undefined;
  mainMenuCheckbox: boolean;
  onRemoveOption: (id: string) => void;
  onRemoveNodeClick: (id: string) => void;
  onDuplicateNodeClick: (id: string) => void;
  onSelectAttachmentMenuItem: (menuItem: MenuItem) => void;
}

export interface AttachmentFile {
  attachment_name: string | undefined;
  attachment_type: FileType | undefined;
  attachment_url: string | undefined;
}
export interface AttachmentLocation {
  attachment_location: string | undefined;
}

export enum AttachmentType {
  File = 'file',
  Location = 'location',
  Contact = 'contact',
}

export interface MenuItem {
  value: AttachmentType;
  label: number | string;
  disabled?: boolean;
  icon?: React.ReactElement;
}

export enum MenuItemLabel {
  File = 'קובץ',
  Location = 'מיקום',
}

export enum AttachmentInputName {
  FileURL = 'file_url',
  Coordinates = 'coordinates',
}
