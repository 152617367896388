import styles from "./dropdown.module.scss";
import classNames from "classnames";
import React from "react";

export const Dropdown = React.forwardRef((props: DropdownProps, ref: React.ForwardedRef<HTMLDivElement>) => (

  
  <div className={classNames(styles.window, props.className, { [styles.expanded]: props.expanded })} ref={ref}>
    {props.children}
  </div>
));

interface DropdownProps{
  expanded: boolean,
  children?: React.ReactNode,
  className?: string
}