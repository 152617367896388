import { MouseEvent, useContext, useEffect } from 'react';
import { Portal } from 'reakit';
import styles from './player.module.scss';
import { useAudio } from 'react-use';
import { CallShape } from '../types';

import { selectedCallContext } from '../selected-call';
import { useNavigate } from 'react-router-dom';
import { playerContext } from '../player-state';
import { Button } from '../../design/button';
import { CloseIcon } from '../../design/icons';

const callInfoLabel = {
  inbound: "נכנסת",
  outbound: "יוצאת",
  local: "פנימית",
  failed: "לא נענתה",
  voicemail: "תא קולי",
  canceled: "בוטלה",
}


export const Player = () => {
  
  const navigate = useNavigate();
  const [call, setCall] = useContext(selectedCallContext);
  const [playerVisible, setPlayerVisibility] = useContext(playerContext);

  useEffect(() => {
    if(!call) navigate('/calls-history');
  }, [call, navigate])

  const [audio] = useAudio({
    src: call?.record_url || "",
    autoPlay: call && playerVisible,
    controls: true,
    className: styles.audio,
  });

  if(!playerVisible || !call) {
    return null;
  }

  return (
    <Portal>
      <div className={styles.player}>
        <div className={styles.callDetails}>
          <div className={styles.content}>
            <div>
              {callInfoLabel[call.direction as "inbound"]} <span className={styles.party}>{call.caller_private_extension || call.caller_virtual_extension}</span> <span>←</span> <span className={styles.party}>{call.callee_private_extension || call.callee_virtual_extension}</span>
            </div>
            <span className={styles.datetime}>{call.start_time.format("DD MMM YYYY, HH:mm")}</span>
          </div>
        </div>
        {audio}
        
          <Button
            variant={"ghost"}
            onClick={() => {setCall(undefined); setPlayerVisibility(false)}}
            className={styles.closeButton}
          >
            <CloseIcon
              className={styles.close}
              size={20}
            />
          </Button>
        
      </div>
    </Portal>
  )
};

export interface PlayerProps {
  onClose?: (e: MouseEvent<HTMLButtonElement>) => void
  call: CallShape;
}