import styles from './general-error.module.scss';

export const GeneralError = ({error}: GeneralErrorProps) => {
    return (
        <div className={styles.root} dangerouslySetInnerHTML={{__html: error}} />
    );
};

interface GeneralErrorProps {
    error: string;
}