import { CallAttributes, call_groupUuids } from "../interfaces";

export function groupCallsByCcq(calls: CallAttributes[], selfManagedGroups: string[], pred?: ((call: CallAttributes) => boolean) | undefined): Record<string, CallAttributes[]> {
  // group call by group uuid
  const groupedInboundCalls = calls
    .filter(pred ? pred : () => true)
    .reduce((grouped, call) => {
      const targetGroupId = call_groupUuids(call).find(group => selfManagedGroups.includes(group)); // first self managed group that the call visited
      if (!targetGroupId) return grouped; // this call is faulty and should not count

      if (!grouped[targetGroupId]) {
        grouped[targetGroupId] = []
      }

      grouped[targetGroupId].push(call);
      return grouped;
    }, {} as GroupedCalls);

  return groupedInboundCalls;
}

type GroupedCalls = Record<string, CallAttributes[]>;
