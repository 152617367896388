import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import { Callbacks } from '../callbacks';
import { CallsHistory } from '../calls-history/calls-history';
import { ClientSettingsPage } from '../client-settings/client-settings-page';
import { Extensions } from '../extensions/extensions';
import { Groups } from '../groups/groups';
import { Invitations } from '../invitations/invitations';
import { MyNumbers } from '../my-numbers/my-numbers';
import { ProfilePage } from '../profile/proflile-page';
import { UserManagement } from '../user-management/user-management';
import WhatsAppBots from '../whatsapp-bot';
import { GuestRoute } from '../guest-route';
import Auth from '../auth';
import { PrivateRoute } from '../private-route';
import { MonitorRouter } from '../monitor/routes';
import EmergencyDialer from '../emergency-dialer';
import Landing from '../emergency-dialer/landing/landing';
import { CallsHistoryReport } from '../calls-history-report/calls-history-report';
import SmsBillingPage from '../billing/sms-billing/sms-billing-page';
import NetworkMonitor from '../network-monitor/network-monitor';
import { DigitalAgentRouter } from '../digital-agent';
import { Digital99 } from '../digital-agent/integration/99-digital';

export const Router = () => (
  <Routes>
    <Route
      path='/auth/*'
      element={
        <GuestRoute>
          <Auth />
        </GuestRoute>
      }
    />
    <Route path='/erd/*' element={<Landing />} />
    <Route path='/invitation/:id' element={<FromToNavigate path='/auth/invitation' />} />
    <Route
      path='/calls-history/*'
      element={
        <PrivateRoute>
          <CallsHistory />
        </PrivateRoute>
      }
    />
    <Route
      path='/*'
      element={
        <PrivateRoute>
          <CallsHistory />
        </PrivateRoute>
      }
    />
    <Route
      path='/emergency-dialer/*'
      element={
        <PrivateRoute>
          <EmergencyDialer />
        </PrivateRoute>
      }
    />
    <Route
      path='/calls-summary/*'
      element={
        <PrivateRoute>
          <CallsHistoryReport />
        </PrivateRoute>
      }
    />
    <Route
      // path="/callbacks/*"
      path='/callback-dialer/*'
      element={
        <PrivateRoute>
          <Callbacks />
        </PrivateRoute>
      }
    />
    <Route
      path='/my-numbers/*'
      element={
        <PrivateRoute>
          <MyNumbers />
        </PrivateRoute>
      }
    />
    <Route
      path='/extensions/*'
      element={
        <PrivateRoute>
          <Extensions />
        </PrivateRoute>
      }
    />
    <Route
      path='/groups/*'
      element={
        <PrivateRoute>
          <Groups />
        </PrivateRoute>
      }
    />
    <Route
      path='/sms-billing/*'
      element={
        <PrivateRoute>
          <SmsBillingPage />
        </PrivateRoute>
      }
    />
    <Route
      path='/whatsapp-bots/*'
      element={
        <PrivateRoute>
          <WhatsAppBots />
        </PrivateRoute>
      }
    />
    <Route
      path='/digital-agent/*'
      element={
        <PrivateRoute>
          <DigitalAgentRouter />
        </PrivateRoute>
      }
    />
    <Route
      path='/digital-agent-message/*'
      element={
        <PrivateRoute>
          <Digital99 />
        </PrivateRoute>
      }
    />
    <Route
      path='/monitor/*'
      element={
        <PrivateRoute>
          <MonitorRouter />
        </PrivateRoute>
      }
    />
    <Route
      path='/network-monitor/*'
      element={
        <PrivateRoute>
          <NetworkMonitor />
        </PrivateRoute>
      }
    />
    <Route
      path='/invitations/*'
      element={
        <PrivateRoute>
          <Invitations />
        </PrivateRoute>
      }
    />
    <Route
      path='/client-settings/*'
      element={
        <PrivateRoute>
          <ClientSettingsPage />
        </PrivateRoute>
      }
    />
    <Route
      path='/profile/*'
      element={
        <PrivateRoute>
          <ProfilePage />
        </PrivateRoute>
      }
    />
    <Route
      path='/user-management/*'
      element={
        <PrivateRoute>
          <UserManagement />
        </PrivateRoute>
      }
    />
  </Routes>
);

const FromToNavigate = ({ path }: { path: string }) => {
  const { id } = useParams();
  return <Navigate to={`${path}/${id}`} />;
};
