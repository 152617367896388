import { Icon, IconProps } from './icon';

export const Check = ({ ...props }: IconProps) => (
  <Icon
    fill="currentColor"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M8.9999 16.2L4.7999 12L3.3999 13.4L8.9999 19L20.9999 6.99998L19.5999 5.59998L8.9999 16.2Z"/>
  </Icon>
);
