import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import RadioGroup, { RadioButton } from '../../../design/radio-group/radio-group';
import styles from './components.module.scss';
import { Input } from '../../../design/form/input/input';
import { Button } from '../../../design/button';
import { JobTitle } from '../../../api/dialers';
import { ButtonProps } from '../../../design/button/button';
import { Tag } from '../../../design/tag/tag';
import ButtonBar from '../../../design/button-bar/button-bar';
import { useNavigate } from 'react-router-dom';
import { useLaunchers } from '../../use-launchers';
import { Select } from '../../../design/form/select';
import { Field } from '../../../design/form/field';
import { HorizontalPartition } from '../../../design/partition/horizontal-partition';
import { AudioFileField } from '../../../design/form/audio-file-field/audio-file-field';
import { AudioFileInput } from '../../../design/form/audio-file-input/audio-file-input';
import { useField, useFormikContext } from 'formik';
import { CalleeResponseTable } from './callee-response-table/callee-response-table';
import { DEFAULT_DIALER } from '../../use-dial-groups';
import { Edit } from '../../../design/icons/edit';
import { Check } from '../../../design/icons/check';
import { safeParsePhoneNumber } from '../../../utils/strings/parse-phone';
import { useGlobalSettings } from '../../use-global-settings';

const SMS_CONTENT_PLACEHOLDER = 'אהלן';
const SMS_IDENTIFIER_PLACEHOLDER = 'Wikipedia';
export interface VisibilityProps {
  write?: boolean;
  isAdmin: boolean;
  save?: (fieldName: string, fieldValue: any) => void;
}
export const EditDialGroupName = ({ write, isAdmin }: VisibilityProps) => {
  const [editState, setEditState] = useState(false);
  const dialerName = useField('name')[0].value;
  return (
    <div className={styles.container}>
      <span className={styles.description}>שם קבוצת קריאה</span>
      {editState ? (
        <div className={styles.row}>
          <Field name='name'>
            <Input disabled={!isAdmin && !write} />
          </Field>
          <span style={{ cursor: 'pointer' }} onClick={() => setEditState(false)}>
            <Check />
          </span>
        </div>
      ) : (
        <div className={styles.row}>
          <span className={styles.dialerName}>{dialerName}</span>
          <span style={{ cursor: 'pointer' }} onClick={() => setEditState(true)}>
            <Edit />
          </span>
        </div>
      )}
    </div>
  );
};

export const DialGroupActivationCode = () => {
  const launchCode = useField('launch_code')[1].value;

  return (
    <div className={styles.container}>
      <span className={styles.description}>קוד הפעלת קריאה</span>
      <span className={styles.content}>{launchCode}</span>
    </div>
  );
};

export const EditDialGroupStatus = ({ write, isAdmin }: VisibilityProps) => {
  const btns: RadioButton[] = [
    {
      id: 'enabled',
      label: 'פעיל',
      value: 'enabled',
    },
    {
      id: 'disabled',
      label: 'לא פעיל',
      value: 'disabled',
    },
  ];

  return (
    <Checker isAdmin={isAdmin}>
      <RadioGroup radioButtons={btns} groupTitle='סטטוס קבוצת קריאה' groupId='enabled' disabled={!isAdmin && !write} />
    </Checker>
  );
};

export const DialerSettingsForm = ({ isAdmin }: VisibilityProps) => {
  const DIALER_PRIORITY_OPTS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const DIALER_DIAL_ATTEMPTS = [1, 2, 3];
  const DIALER_DIAL_INTERVAL = [2, 4, 6, 8, 10];
  const DIALER_HANGUP_INTERVAL = [10, 20, 30, 40, 50];
  return (
    <div className={styles.dialerSettingsBody}>
      {
        <Checker isAdmin={isAdmin}>
          <ConditionalSelectField
            fieldName={'priority'}
            fieldDescription={'עדיפות קבוצת קריאה'}
            values={DIALER_PRIORITY_OPTS}
            type='numeric'
            write
            isAdmin={isAdmin}
          />
        </Checker>
      }
      {
        <Checker isAdmin={isAdmin}>
          <ConditionalSelectField
            fieldName={'cust_max_attempts'}
            fieldDescription={'מספר נסיונות חיוג לכונן'}
            values={DIALER_DIAL_ATTEMPTS}
            write
            isAdmin={isAdmin}
          />
        </Checker>
      }
      {
        <Checker isAdmin={isAdmin}>
          <ConditionalSelectField
            fieldName={'cust_dial_interval'}
            fieldDescription={'זמן בדקות בין נסיונות חיוג'}
            values={DIALER_DIAL_INTERVAL}
            write
            isAdmin={isAdmin}
          />
        </Checker>
      }
      {
        <Checker isAdmin={isAdmin}>
          <ConditionalSelectField
            fieldName={'max_event_age'}
            fieldDescription={'זמן בדקות לעצירת התקשרות וביטול רשומות פתוחות'}
            values={DIALER_HANGUP_INTERVAL}
            write
            isAdmin={isAdmin}
          />
        </Checker>
      }
    </div>
  );
};

export const DialerConfigurationForm = ({ isAdmin, ...props }: VisibilityProps) => {
  const DIALER_CONFIG_OPTS = {
    phone: 'טלפונית',
    sms: 'סמס',
    phone_sms: 'טלפונית וסמס',
  };
  const RECORDING_NAME_FIELD_NAME = 'cust_prompt_file_name';
  const RECORDING_URL_FIELD_NAME = 'cust_prompt_file_url';
  const CANCEL_RECORDING_NAME_FIELD_NAME = 'cust_cancelation_prompt_file_name';
  const CANCEL_RECORDING_URL_FIELD_NAME = 'cust_cancelation_prompt_file_url';
  const dial_config = useField('dialer_config')[1].value;
  const [selected, setSelected] = useState<keyof typeof DIALER_CONFIG_OPTS>(dial_config);
  const { setFieldValue } = useFormikContext();
  const promptFileUrl: string = useField(RECORDING_URL_FIELD_NAME)[1].value;
  const promptFileName: string = useField(RECORDING_NAME_FIELD_NAME)[1].value;
  const cancelFileUrl: string = useField(CANCEL_RECORDING_URL_FIELD_NAME)[1].value;
  const cancelFileName: string = useField(CANCEL_RECORDING_NAME_FIELD_NAME)[1].value;
  const dialerNameFileUrl: string = useField('dialer_name_file_url')[1].value;
  const dialerNameFileName: string = useField('dialer_name_file_name')[1].value;

  const { data: globalSettingsData } = useGlobalSettings();
  const businessNumberList = globalSettingsData?.business_numbers || [];

  const handleFileSave = (fieldName: string, value: any) => {
    setFieldValue(fieldName, value);
    props.save && props.save(fieldName, value);
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <div className={styles.dialerConfigBody}>
      <div className={styles.configSelector}>
        <label className={styles.strong}>בחירת תצורת הפעלת קריאה</label>
        <Field
          name='dialer_config'
          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
            setSelected(e.target.value as keyof typeof DIALER_CONFIG_OPTS)
          }
        >
          <Select name='dialer_config' multiple={false}>
            {Object.entries(DIALER_CONFIG_OPTS).map(([key, value]) => (
              <option key={key} value={key}>
                {' '}
                {value}{' '}
              </option>
            ))}
          </Select>
        </Field>
      </div>
      <div className={styles.configuration}>
        <CalleeResponseTable save={props.save} />

        {(selected === 'phone' || selected === 'phone_sms') && (
          <div className={styles.dialerConfigBody}>
            <Checker isAdmin={isAdmin}>
              <Field name='business_number' label='מספר מזהה בשיחה יוצאת'>
                <Select>
                  <option value={'03-4567890'}></option>
                  {businessNumberList?.map((number, index) => (
                    <option key={number.concat(String(index))} value={number}>
                      {safeParsePhoneNumber(number, 'IL')}
                    </option>
                  ))}
                </Select>
              </Field>
            </Checker>
            <div className={styles.fileFieldContainer}>
              <div className={styles.strong}>קובץ קולי להשמעה בחיוג לכוננים</div>
              <div className={styles.fileField}>
                <AudioFileField
                  name={RECORDING_NAME_FIELD_NAME}
                  onReset={() => handleFileSave(RECORDING_URL_FIELD_NAME, null)}
                >
                  <AudioFileInput
                    placeholderFileUrl={promptFileUrl || undefined}
                    placeholderFilename={promptFileName || undefined}
                    name={RECORDING_URL_FIELD_NAME}
                    label={'העלאת קובץ הקלטה חדש'}
                    description={'ניתן להעלות קבצי אודיו עד 5MB'}
                  />
                </AudioFileField>
              </div>
            </div>
            <div className={styles.fileFieldContainer}>
              <div className={styles.strong}>קובץ קולי להשמעה בביטול אירוע</div>
              <div className={styles.fileField}>
                <AudioFileField
                  name={CANCEL_RECORDING_NAME_FIELD_NAME}
                  onReset={() => handleFileSave(CANCEL_RECORDING_URL_FIELD_NAME, null)}
                >
                  <AudioFileInput
                    placeholderFileUrl={cancelFileUrl || undefined}
                    placeholderFilename={cancelFileName || undefined}
                    name={CANCEL_RECORDING_URL_FIELD_NAME}
                    label={'העלאת קובץ הקלטה חדש'}
                    description={'ניתן להעלות קבצי אודיו עד 5MB'}
                  />
                </AudioFileField>
              </div>
            </div>

            {props.write && (
              <div className={styles.fileFieldContainer}>
                <div className={styles.strong}>קובץ קולי להשמעת שם קבוצת קריאה</div>
                <div className={styles.fileField}>
                  <AudioFileField
                    name={'dialer_name_file_name'}
                    onReset={() => handleFileSave('dialer_name_file_url', DEFAULT_DIALER.dialer_name_file_url)}
                  >
                    <AudioFileInput
                      placeholderFileUrl={dialerNameFileUrl || undefined}
                      placeholderFilename={dialerNameFileName || undefined}
                      name={'dialer_name_file_url'}
                      label={'העלאת קובץ הקלטה חדש'}
                      description={'ניתן להעלות קבצי אודיו עד 5MB'}
                    />
                  </AudioFileField>
                </div>
              </div>
            )}
          </div>
        )}

        {selected === 'phone_sms' && (
          <div className={styles.lastBorder}>
            <HorizontalPartition />
          </div>
        )}

        {(selected === 'sms' || selected === 'phone_sms') && (
          <div className={styles.dialerConfigBody}>
            <div>
              <label className={styles.strong}>מזהה סמס בשליחה</label>
              <Field name='business_number_for_sms'>
                <Input placeholder={SMS_IDENTIFIER_PLACEHOLDER} onKeyDown={handleKeyPress} />
              </Field>
            </div>
            <div>
              <label className={styles.strong}>נוסח סמס שיישלח לכוננים</label>
              <Field name='callee_sms_text'>
                <Input placeholder={SMS_CONTENT_PLACEHOLDER} onKeyDown={handleKeyPress} />
              </Field>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const LaunchersSection = ({ dialerId }: LaunchersSectionProps) => {
  const navigate = useNavigate();
  const { data: launchers } = useLaunchers();
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className={styles.container}>
          <span className={styles.description}>מספר מפעילי אירוע</span>
          <span className={styles.content}>
            {launchers?.filter((launcher) => launcher.dial_groups.some((dg) => dg === dialerId))?.length}
          </span>
        </div>
        <Button onClick={() => navigate(`/emergency-dialer/launchers/dial_groups/${dialerId}`)} variant='link'>
          מעבר לעריכת מפעילים
        </Button>
      </div>
    </div>
  );
};
interface LaunchersSectionProps {
  dialerId: number;
}

export const CalleesSection = ({ jobTitles, dialerId }: CalleesSectionProps) => {
  const totalJobTitles = jobTitles?.reduce((acc, curr) => {
    return acc + curr.count;
  }, 0);

  const jobTitleTagButtons: Button[] = (jobTitles || [])?.map((job) => ({
    id: String(job.job.job_title_id),
    label: job.job.description,
    component: (
      <Tag
        key={job.job.job_title_id}
        label={`${job.job.description} (${job.count})`}
        variant='filled'
        color='neutral'
        className={styles.tag}
      />
    ),
  }));
  const buttons = [
    {
      id: 'all',
      label: `סה"כ (${totalJobTitles})`,
      component: (
        <Tag key={'all'} label={`סה"כ (${totalJobTitles})`} variant='filled' color='neutral' className={styles.tag} />
      ),
    },
    ...jobTitleTagButtons,
  ];
  const navigate = useNavigate();
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div className={styles.container}>
          <ButtonBar buttons={buttons} />
        </div>
        <Button onClick={() => navigate(`/emergency-dialer/callees/dial_groups/${dialerId}`)} variant='link'>
          מעבר לעריכת כוננים
        </Button>
      </div>
    </div>
  );
};

interface CalleesSectionProps {
  jobTitles: { job: JobTitle; count: number }[] | undefined;
  dialerId: number;
}
interface Button extends ButtonProps {
  id: string;
  label: string;
}

export const Section = ({ children, section }: SectionProps) => {
  return (
    <div className={styles.sectionContainer} id={section.id}>
      <div className={styles.sectionTitle}>{section.name}</div>
      <div className={styles.sectionDescription}>{section.description}</div>
      <div className={styles.sectionContent}>{children}</div>
    </div>
  );
};
export interface Section {
  id: string;
  name: string;
  description: string;
}
export interface SectionProps {
  children: React.ReactNode;
  section: Section;
}

const ConditionalSelectField = ({
  write,
  isAdmin,
  fieldName,
  fieldDescription,
  values,
  ...props
}: ConditionalSelectProps) => {
  const field = useField(fieldName);
  return isAdmin || write ? (
    <div>
      <label className={styles.strong}>{fieldDescription}</label>
      <Field name={fieldName} type={props.type || 'text'}>
        <Select name={fieldName} multiple={false}>
          {values.map((option) => (
            <option key={option} value={option}>
              {' '}
              {option}{' '}
            </option>
          ))}
        </Select>
      </Field>
    </div>
  ) : (
    <div>
      <label className={styles.strong}>{fieldDescription}</label>
      <div>{field[0].value}</div>
    </div>
  );
};

export const ConditionalInputField = ({
  write,
  isAdmin,
  fieldName,
  fieldDescription,
  placeholder,
}: ConditionalInputProps) => {
  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <div>
      <label className={styles.strong}>{fieldDescription}</label>
      <Field name={fieldName}>
        <Input onKeyDown={handleKeyPress} placeholder={placeholder || ''} disabled={!(isAdmin || write)} />
      </Field>
    </div>
  );
};

interface ConditionalSelectProps extends VisibilityProps {
  fieldName: string;
  fieldDescription: string;
  values: any[];
  type?: 'numeric';
}

interface ConditionalInputProps extends VisibilityProps {
  fieldName: string;
  fieldDescription: string;
  placeholder?: string;
}

/**
 *
 * @param hideFromClient - Boolean value which is responsible for hiding (Not rendering) an element if 'isAdmin' param is set to false.
 * @param isAdmin - Boolean value which means to always render elements if set to 'true'.
 * @param children - Element to be rendered.
 * @returns
 */
export const Checker = ({ hideFromClient: hide, isAdmin, children }: CheckerProps) => {
  if (isAdmin) {
    return <div>{children}</div>;
  } else if (hide) {
    return <></>;
  } else {
    return <div>{children}</div>;
  }
};

interface CheckerProps {
  children: React.ReactNode;
  isAdmin: boolean;
  hideFromClient?: boolean;
}
