import { useParams } from 'react-router-dom';
import styles from './landing.module.scss';
import { useURLParams } from '../../app/use-url-params';
import { Spinner } from '../../design/spinner/spinner';
import { DialerEvent } from '../../api/dialers';
import { Form, Formik } from 'formik';
import RadioGroup, { RadioButton } from '../../design/radio-group/radio-group';
import { Button } from '../../design/button';
import { API } from 'aws-amplify';
import { calculateCrc, useGuestParams, validate } from '../use-guest-params';
import { Logo } from '../../design/logo/logo';
import { Check } from '../../design/icons/check';
import { useState } from 'react';
import { ErrorIcon } from '../../design/icons/error-icon';

export default function Landing() {
  const params = useParams<any>()['*'];
  const url = useURLParams({ path: `/erd`, params });
  const { d, e, p, x } = url.urlParams;
  const { data, loading, error, mutate } = useGuestParams({ d, e, p, x });
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  if (!validate({ d, e, p, x })) {
    return <div className={styles.page}> UNAUTHORIZED </div>
  }

  if (loading) {
    return <div className={styles.page}> <Spinner /> </div>
  }
  if (error) {
    return <div className={styles.page}>Error</div>
  }
  if (!data) {
    return <div className={styles.page}>No data</div>
  }

  const { event, dialer: dialGroup, dialerRequest } = data;
  const calleeResponses = dialGroup?.callee_responses;

  if (!calleeResponses) {
    return (
      <div>
        No callee responses were found
      </div>
    )
  }

  if (!dialerRequest) {
    return (
      <div className={styles.page}>
        No dialer request for phone number 0{p}
      </div>
    )
  }

  if (!x) {
    return (
      <div className={styles.page}>
        Unauthorized
      </div>
    );
  }

  if (!(e && p && d)) {
    return (
      <div className={styles.page}>
        Invalid parameters
      </div>
    )
  }

  const radioButtons: RadioButton[] = calleeResponses.map(response => ({
    id: `${response.response_key}`,
    label: response.response_text,
    value: response.response_key
  }));
  
  const handleSubmit = async (values: any) => {
    try {
      setSubmitLoading(true);
      const queryParams = { callee_uuid: dialerRequest?.callee_uuid, dialer_id: dialGroup.dialer_id, event_id: e };
      const { calcedX: x, urlPath: queryStr } = calculateCrc(queryParams);
      await API.patch(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, `/callee-response?${queryStr}&x=${x}`, {
        body: {
          domain_name: d,
          click: values.rsvp
        }
      });
      await new Promise(resolve => setTimeout(() => resolve(mutate()), 1500));
    }
    catch (err) { console.error(err); setSubmitError(true); }
    finally { setSubmitLoading(false) }
  }

  const didRespond = dialerRequest.response_date !== undefined && dialerRequest.response_date !== null;

  if (event?.event_status !== 'active' && !didRespond) {
    return (
      <div>
        Event is no longer active
      </div>
    )
  }

  if (submitError) {
    return (
      <div className={styles.page}>
        <main>
          <div className={styles.header}>
            <span className={styles.strong}>אירעה שגיאה!</span>&nbsp;
          </div>
          <div className={styles.form}>
            <div className={styles.errorCircle}>
              <ErrorIcon />
            </div>
            <Button
              onClick={window.location.reload}
              variant='regular'
              className={styles.submitBtn}
            >
              טען עמוד מחדש
            </Button>
          </div>
        </main>
        <div className={styles.logo}>
          <span>Powered by</span>
          <Logo width={240} height={16} />
        </div>
      </div>
    )
  }


  return (
    <div className={styles.page}>
      <main>
        <Header event={event} didRespond={didRespond} />

        {
          didRespond
            ? (
              <div className={styles.form}>
                <div className={styles.successCircle}>
                  <Check />
                </div>
              </div>
            ) : (
              <Formik
                initialValues={{
                  rsvp: dialerRequest?.response_key
                }}
                onSubmit={handleSubmit}
              >
                <Form
                  className={styles.form}
                >
                  <RadioGroup
                    groupId='rsvp'
                    radioButtons={radioButtons}
                    containerClassName={styles.radioGroupContainer}
                    buttonClassName={styles.radioButton}
                    disabled={dialerRequest?.response_key !== null}
                  />

                  <Button
                    loading={submitLoading}
                    variant='primary'
                    type='submit'
                    className={styles.submitBtn}
                  >
                    שליחת תגובה לזימון
                  </Button>
                </Form>
              </Formik>
            )}
      </main>

      <div className={styles.logo}>
        <span>Powered by</span>
        <Logo width={240} height={16} />
      </div>
    </div>
  )
}

const Header = ({ event, didRespond }: { event: DialerEvent, didRespond: boolean }) => {

  return (
    <div className={styles.header}>
      <span className={styles.strong}>קיבלת זימון</span>&nbsp;
      <span className={styles.large}>אירוע {event.event_type === 'emergency' ? 'אמת' : 'תרגיל'} {event.dialer_name}</span>

      {
        didRespond
          ? <div>תגובתך לאירוע נשמרה בהצלחה!</div>
          : <div>האם את/ה מאשר/ת הגעה לאירוע {event.event_type === 'emergency' ? 'אמת' : 'תרגיל'}?</div>}
    </div>
  )
}