import { CallbackDialer } from '../../api/dialers';

export const DEFAULT_CALLBACK_DIALER_ID = -2;

export const DEFAULT_DIALER: CallbackDialer = {
  // --------------------------------------------------------------
  /* General settings */
  dialer_id: -2,
  calls_recorded: true,
  domain_uuid: '',
  name: 'DEFAULT_CALLBACK_DIALER',
  enabled: true,
  max_active: 5,
  priority: 5,
  business_number: '03-1234567',
  language: 'he',
  speaker: 'efrat',
  dial_first_to: 'agent',
  max_request_age: 168, // in hours
  international_calls_permitted: false,

  /* Agent settings */
  active_now: true,
  agent_group_number: '',
  agent_max_attempts: 100,
  agent_dial_interval: 10,
  agent_max_ringing: 30,
  agent_continuous_call: false, // is agent in call continuously
  agent_play_num_requests: false, // play to the agent the number of requests in a dialer queue
  agent_play_cust_number: false, // play to the agent the customer number
  agent_greeting_file_name: `default_agent_greeting`,
  agent_greeting_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:default_agent_greeting.wav/file`,

  /* Incoming call settings */
  incoming_call_enabled: false, // ability to call to the dialer

  /* Customer settings */
  cust_min_duration: 3, // less then 3 minutes is considered as unanswered call
  cust_max_attempts: 3, // attempts to customer
  cust_dial_interval: 60, // interval attempts to customer in minutes
  cust_max_ringing: 30,

  /* No Replay settings */
  create_request_on_abandon: true, // create request if call wan't answered
  delete_request_on_answer: true, // delete request if call answered
  allow_change_mobile_number: true,
  allow_change_landline_number: true,
  approve_request_created: true,
  cust_request_created_file_name: `default_cust_request_created`,
  cust_request_created_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:default_cust_request_created.wav/file`,

  // Default values for not required fields - will be used as default values for the entire callback form
  business_number_for_sms: 'Test 03-1234567',
  cust_dial_prefix: undefined,
  cust_prompt_file_name: `default_cust_prompt`,
  cust_prompt_file_url: `${process.env
    .REACT_APP_IPCOM_API_URL!}/tel/dom/{domain}/recording/d$:he:ziv:default_cust_prompt.wav/file`,
  package_cust_sms: 0,
  package_cust_minutes: 0,
  minutes_billing_code: 0,
  sms_billing_code: undefined,
  time_condition: undefined,
  pushy_request_age: 0,
  blacklist: null,
  agent_approval_key: undefined,
  agent_abort_call_key: undefined,
  agent_complete_call_key: undefined,
  incoming_call_int_number: undefined,
  incoming_call_permitted: undefined,
  cust_approval_key: undefined,
  sms_text_between_attempts_enabled: true,
  sms_text_between_attempts: undefined,
  sms_text_at_failure_enabled: true,
  sms_text_at_failure: undefined,
  sms_text_at_start_enabled: true,
  sms_text_at_start: undefined,
  min_time_for_abandon: 0,
  min_time_for_answer: 0,
  sms_report_text: undefined,
  sms_report_sender_name: undefined,
  sms_report_destination_numbers: undefined,
  mail_report_text: undefined,
  mail_report_title: undefined,
  mail_report_sender_name: undefined,
  mail_report_destination_addresses: undefined,
  interact_by_sms: true,
  interact_by_mail: false,
};
