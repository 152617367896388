import { ReactElement } from "react";
import mixpanel from "mixpanel-browser";
import moment from "moment";
import { useEffect } from "react";
import { useMe } from "../api/use-me";


export  function AnalyticsRoot({ children }: AnalyticsRootProps) {
  useAnalytics();
  return children;
}

export function useAnalytics() {

  const user = useMe();

  useEffect(() => {
    if(user?.data) {      
      const now = moment().toISOString();
      mixpanel.identify(user.data.id);
      mixpanel.people.set_once("Organization", user.data.organization.name);
      mixpanel.people.set_once("Activated At", now);
      mixpanel.people.set("Last Login", now);
      if (user.data.groups) {
        mixpanel.people.set("Department", user.data.groups);
      }
    }
  }, [user]);

}

export interface AnalyticsRootProps {
  children: ReactElement
}