export function getTime(date: Date | string | number | undefined | null): number {
  if (!date) {  // for "null"
    return 0;
  }

  switch (typeof date) {
    case "number"   : return date;
    case "string"   : return new Date(date).getTime();
    case "undefined": return 0;
    case "object"   : return (date as Date).getTime();
  }

  return 0; // shouldn't happen
}
