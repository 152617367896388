import { Box } from 'reakit';
import { styled, Input } from '@mui/material';

export const StyledDynamicField = styled('div')(({ theme }) => ({
  width: 'fill-available',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  rowGap: theme.spacing(4),
}));

export const StyledDynamicFieldContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  rowGap: theme.spacing(2.5),
  columnGap: theme.spacing(2.5),
  border: theme.borders.default,
  borderRadius: theme.borderRadius.default,
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  width: '100%',
  height: 'fit-content',
}));

const inputProps: PropertyKey[] = ['hasPermission'];

export const StyledChipInput = styled(Input, {
  shouldForwardProp: (prop) => !inputProps.includes(prop),
})(({ theme }) => ({
  direction: 'ltr',
  maxWidth: '114px',
  height: theme.fieldHeights.default,
  fontSize: theme.fontSizes.medium,
  backgroundColor: theme.palette.grey[100],
  borderRadius: theme.borderRadius.ellipse,
  padding: `${theme.spacing(0)} ${theme.spacing(2.5)}`,

  '&.MuiInput-root': {
    color: theme.palette.grey[400],
    fontSize: theme.fontSizes.medium,
    fontWeight: theme.fontWeights.regular,

    '& .MuiInputBase-input': {
      padding: 0,
    },
    '& .MuiSvgIcon-root': {
      ...theme.sizes.small,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    '&.Mui-error': {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
  '&::before, ::after': {
    borderBottom: 'none',
    height: theme.fieldHeights.default,
  },
  '&:hover:not(.Mui-focused):not(.Mui-disabled):not(.Mui-error)::before': {
    borderBottom: 'none',
    borderRadius: theme.borderRadius.ellipse,
    border: theme.borders.main,
    height: theme.fieldHeights.default,
  },
  '&.Mui-focused': {
    height: theme.fieldHeights.default,
    border: theme.borders.primary,

    '& .MuiInput-root': {
      color: theme.palette.grey[400],
      fontSize: theme.fontSizes.medium,
      fontWeight: theme.fontWeights.regular,
    },
    '&:hover::before': {
      borderBottom: 'none',
      height: theme.fieldHeights.default,
    },
  },
  '&.Mui-disabled': {
    '&::before': {
      borderBottom: 'none',
    },
  },
  '& .Mui-disabled': {
    WebkitTextFillColor: `${theme.palette.secondary.main} !important`,
  },
}));
