
import classNames from 'classnames';
import styles from './radio-group.module.scss';
import { Field } from 'formik';

/**
 * @param groupId - Creates a radio-group context and allows only 1 button within that group to be selected. Different RadioGroups must have different groupIds.
 * @param radioButtons - An array of items corresponding to `RadioButton` interface mentioned below
 */
export default function RadioGroup({ groupId, groupTitle, radioButtons, containerClassName, groupTitleClassName, onChange, ...props }: RadioGroupProps) {

  return (
    <div role='group' className={classNames(styles.radioGroupContainer, containerClassName, { [styles.disabled]: props.disabled })}>
      {groupTitle ? <span className={groupTitleClassName}>{groupTitle}</span> : undefined}
      {
        radioButtons?.map(btn => {

          return (
            <div key={btn.id} className={classNames(props.buttonClassName, styles.radioButtonContainer)}>
              <Field
                type='radio'
                name={groupId}
                value={`${btn.value}`}
                id={btn.id}
                disabled={props.disabled}
              />
              <label htmlFor={btn.id}>{btn.label}</label>
            </div>
          )
        })
      }
    </div>
  )
}

interface RadioGroupProps {
  groupId: string;
  groupTitle?: string;
  groupTitleClassName?: string;
  radioButtons: RadioButton[];
  containerClassName?: string;
  buttonClassName?: string;
  disabled?: boolean;
  onChange?: (id: string, groupId: string) => void;
}

export interface RadioButton {
  id: string;
  value: any;
  label: string;
}