import { IndexRouteProps, LayoutRouteProps, PathRouteProps, Navigate, Route } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authenticatedState } from "./state";

export function GuestRoute(props: GuestRouteProps) {
  const authenticated = useRecoilValue(authenticatedState);
  if (authenticated) {
    return <Navigate to="/" />;
  }

  return <>{props.children}</>;
}

type GuestRouteProps = PathRouteProps | LayoutRouteProps | IndexRouteProps;