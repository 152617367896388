import { API } from "aws-amplify";
import useSWR from "swr";
import { DialerAttempt } from "./types";

const useAttempts = (request_uuid: string | undefined) => {
  const requestSpecification = "/select_attempts?";
  const { data, error } = useSWR(() => request_uuid ? requestSpecification + `request_uuid=${request_uuid}` : null, attemptsFetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  
  return {
    attempts: data,
    error: error,
    loading: !error && !data,
  }
}

const attemptsFetcher = async (path : string) => {
  const response = await API.get(
    process.env.REACT_APP_PROCESS_DIALER_API_NAME!,
    path,
    {}
  )

  const data = await response.payload;
  data.forEach((dialerAttempt: DialerAttempt) => {
    if (dialerAttempt.end_date) {
      dialerAttempt.end_date = new Date(dialerAttempt.end_date);
    }
    if (dialerAttempt.start_date) {
      dialerAttempt.start_date = new Date(dialerAttempt.start_date);
    }
    if (dialerAttempt.answer_date) {
      dialerAttempt.answer_date = new Date(dialerAttempt.answer_date);
    }
  })
  return data as DialerAttempt[];
}

export default useAttempts;