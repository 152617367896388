import { useRecoilValue } from 'recoil';
import { MonitorProps } from '../..';
import { HorizontalPartition } from '../../../design/partition/horizontal-partition';
import { Spinner } from '../../../design/spinner/spinner';
import { hadshanutUserState } from '../../../state';
import { Card } from '../../common/components';
import { TextElement } from '../TextElement/text-element';
import { CallAttributes } from '../../interfaces';
import { useMonitorConfig } from '../../use-monitor-config';
import { getAnsweredCalls } from '../../utils/getAnsweredCalls';
import { groupCallsByCcq } from '../../utils/groupCallsByCcq';
import styles from './OutboundCallsPage.module.scss';
import { MobileMonitorProps, PageHeader } from '../mobile-monitor';

interface OutboundCallsPageProps extends MobileMonitorProps { onBackBtnClick: () => void; }

// display a list of cards describing each ccq's inbound calls
export const OutboundCallsPage: React.FC<OutboundCallsPageProps> = ({ data, ...props }) => {
  const user = useRecoilValue(hadshanutUserState);
  const { data: conf, isLoading: loading } = useMonitorConfig();
  
  const selfManagedGroupUuids = user?.domain.config.selfManagedGroups || [];

  if (loading || !conf) {
    return <Spinner />;
  }
  const outboundCallFilter = (call: CallAttributes) => call.callDirection === 'outbound';
  const callsGroupedByCcq = groupCallsByCcq(data.calls, selfManagedGroupUuids, outboundCallFilter);

  return (
    <div className={styles.layout}>
      <PageHeader name='שיחות יוצאות' onBackBtnClick={props.onBackBtnClick} />
      <div className={styles.cardsList}>
        {
          Object.entries(callsGroupedByCcq).map(
            ([groupUuid, groupCalls]) => {

              return (
                <GroupedCallsCard
                  key={groupUuid}
                  groupName={data.groups.find(group => group.id === groupUuid)?.name || ''}
                  calls={groupCalls}
                  selfManagedGroupUuids={selfManagedGroupUuids}
                />
              )
            }
          )
        }
      </div>
    </div>
  );
};


interface OutboundCallCardProps {
  calls: CallAttributes[];
  groupName: string;
  selfManagedGroupUuids: string[];
}


// display ccq data on a Card
const GroupedCallsCard: React.FC<OutboundCallCardProps> = ({ calls, groupName }) => {

  const answeredCalls = getAnsweredCalls(calls);
  
  return (
    <Card className={styles.card}>
      <TextElement
        label={'מחלקה'}
        value={groupName}
      />

      <HorizontalPartition />

      <div className={styles.flexRowSpaceBetween}>

        <TextElement
          label={'יוצאות'}
          value={`${calls.length}`}
        />
        
        <span className={styles.verticalPartition} />

        <TextElement
          label={'נענו'}
          value={`${answeredCalls.length}`}
        />

      </div>
    </Card>
  )
}
