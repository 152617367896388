type Color = 'RED' | 'GREEN' | 'BLUE' | 'YELLOW';

export const COLORS: Record<Color, string> = {
  RED: '#C94236',
  GREEN: '#51D5A5',
  BLUE: '#2B52F3',
  YELLOW: ""
}

// export const RED = '#C94236';
// export const YELLOW = '#F1C21B';
// export const GREY = '#E1E6EC';
// export const BLUE = '#28A0FF';
// export const GREEN = '#51D5A5';