import { useState } from "react";
import { Dialog } from "../../design/dialog/dialog";
import { DialogStateReturn } from "reakit";
import { WhatsAppBot } from "../utils/types";
import { ErrorDialog } from "../../design/dialog/error-dialog/error-dialog";
import { secureFetch } from "../../auth/server-auth";

interface PlayBotDialogProps {
  dialog: DialogStateReturn;
  bot: WhatsAppBot | undefined;
  domainUuid: string;
  email: string;
}

export const ApproveTemplateDialog: React.FC<PlayBotDialogProps> = ({ dialog, bot, domainUuid, email }) => {
  const [error, setError] = useState<MyError | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const openingMessage = bot?.nodes[0].data.messageContent;

  if (!bot) {
    return (
      <ErrorDialog
        dialog={dialog}
        onClose={() => {setError(undefined)}}
        mainMessage={"עליך לשמור את הבוט על מנת לבקש אישור תבנית"}
        secondaryMessage=""
      />
    )
  }

  if (!bot.business_number) {
    return (
      <ErrorDialog
        dialog={dialog}
        onClose={() => {setError(undefined)}}
        mainMessage="עליך לשייך לבוט מספר עסקי מרשימת המספרים"
        secondaryMessage="על מנת ליצור מספר עסקי לווטסאפ יש לפנות לתמיכה שלנו"
      />
    )
  }

  if (!openingMessage) {
    return (
      <ErrorDialog
        dialog={dialog}
        onClose={() => {setError(undefined)}}
        mainMessage={"לא ניתן לבקש אישור על הודעת פתיחה ריקה!"}
        secondaryMessage=""
      />
    )
  }

  if (error) {
    return (
      <ErrorDialog
        dialog={dialog}
        mainMessage={error.message}
        secondaryMessage={error.secondaryMessage}
        contactSupport
        onClose={() => setError(undefined)}
      />
    )
  }

  const handleSubmit = async () => {

    try {
      setLoading(true);

      const body = {
        botUuid: bot.id,
        botPhone: bot.business_number,
        userMail: email
      }
      
      const { res } = await secureFetch(
        `${process.env.REACT_APP_WHATSAPP_BOT_SERVER_URL}/dom/${domainUuid}/certify?stage=draft`,
          {
            method: "POST",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
          }
      );
      
      if (!res.ok) {
        const resJson = await res.json();
        const errMessage = resJson.message;
        setError({ ...SERVER_ERROR, message: errMessage || SERVER_ERROR.message });
      }
      dialog.hide();
    }
    catch (error: any) {
      setError(error?.message || 'אירעה שגיאה');
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <Dialog
      {...dialog}
      aria-label="play-bot-dialog"
      variant={"form"}
      header={'אישור תבנית בMeta'}
      onSubmit={handleSubmit}
      submitLabel="הגש בקשה"
      loading={loading}
    >
      <span>
        תהליך הגשת בקשה לאישור תבנית בחברת Meta לוקח עד כ-5 שעות.
        <br/>
        בסיום התהליך נשלח מייל לכתובת {email}
        <br/>
        האם להמשיך?
      </span>
      
    </Dialog>
  )
}


enum ErrorCode {
  INVALID_NUMBER,
  INVALID_CONTENT,
  SERVER_ERROR
}
interface MyError {
  code: ErrorCode;
  message: string;
  secondaryMessage?: string;
}
const SERVER_ERROR = { code: ErrorCode.SERVER_ERROR, message: 'אירעה שגיאה בשרת' };
