import { PropsWithChildren, ReactNode } from 'react';
import styles from './card.module.scss';
import classNames from 'classnames';

export const Card = ({ children, className }: CardProps) => {
  return (
    <div className={classNames(styles.card, className)}>
      {children}
    </div>
  );
}

interface CardProps extends PropsWithChildren { className?: string }