import { useState } from 'react';
import { Route, Routes } from 'react-router-dom'
import { WhatsAppBot } from './utils/types';
import { BotListPage } from './bot-list-page';
import { activeBotContext } from './bot-list/bot-context';
import EditBotPage from './edit-bot/edit-bot-page'

export default function WhatsAppBots() {
  const activeBotState = useState<WhatsAppBot>();
  return (
    <activeBotContext.Provider value={activeBotState}>
      <Routes>
        <Route
          path="/:id/edit"
          Component={EditBotPage}
        />
        
        <Route
          path="/"
          Component={BotListPage}
        />
      </Routes>
    </activeBotContext.Provider>
  );
}
