import { KeyedMutator } from 'swr';
import { useRecoilValue } from 'recoil';
import { useCallback, useMemo } from 'react';

import { hadshanutUserState } from '../../state';
import { useGroups } from '../../api/use-groups';
import { DefaultDialerValidation } from '../types';
import { DEFAULT_DIALER } from '../utils/constants';
import { getBusinessNumber } from '../utils/getBusinessNumber';
import { getAgentGroupNumber } from '../utils/getAgentGroupNumber';
import { ApiDataHandler, RequestMethod } from '../../globalTypes/types';
import { useBusinessNumberCollection } from '../../api/use-business-number';

export const useDefaultCallbackDialer = (mutateCallbackDialers: KeyedMutator<any>) => {
  const user = useRecoilValue(hadshanutUserState);
  const businessNumbers = useBusinessNumberCollection(true);
  const groups = useGroups(user?.domain?.id);
  const business_number = useMemo(() => getBusinessNumber(businessNumbers, user), [businessNumbers, user]);
  const agent_group_number = useMemo(() => getAgentGroupNumber(groups, user), [groups, user]);

  const domain_uuid = user?.domain?.id;
  const domain_name = user?.domain?.name ?? '';
  const cust_prompt_file_url = DEFAULT_DIALER.cust_prompt_file_url!.replace('{domain}', domain_name);
  const agent_greeting_file_url = DEFAULT_DIALER.agent_greeting_file_url!.replace('{domain}', domain_name);
  const cust_request_created_file_url = DEFAULT_DIALER.cust_request_created_file_url!.replace('{domain}', domain_name);

  const defaultDialerValidation: DefaultDialerValidation = {
    isValid: Boolean(domain_uuid && business_number && agent_group_number),
    hasDomain: Boolean(domain_uuid && domain_uuid.trim().length > 0),
    hasBusinessNumber: Boolean(business_number && business_number.trim().length > 0),
    hasAgentGroupNumber: Boolean(agent_group_number && agent_group_number.trim().length > 0),
  };

  const createDefaultCallbackDialer = useCallback(
    async (apiDataHandler: ApiDataHandler) => {
      if (domain_uuid && business_number && agent_group_number) {
        const defaultCallbackDialer = {
          ...DEFAULT_DIALER,
          domain_uuid,
          business_number,
          agent_group_number,
          cust_prompt_file_url,
          agent_greeting_file_url,
          cust_request_created_file_url,
        };
        try {
          await apiDataHandler({
            method: RequestMethod.POST,
            newData: defaultCallbackDialer,
            shouldUseOptimistic: false,
          });
          mutateCallbackDialers();
        } catch (error) {
          console.error('Failed to create default callback dialer', error);
        }
      }
    },
    [
      domain_uuid,
      business_number,
      agent_group_number,
      cust_prompt_file_url,
      agent_greeting_file_url,
      cust_request_created_file_url,
      mutateCallbackDialers,
    ]
  );

  return {
    defaultDialerValidation,
    createDefaultCallbackDialer,
  };
};
