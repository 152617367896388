import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import { ArrowUpLeft, ArrowDown, ArrowHorizontal, PhoneRight, ArrowUTurnRight } from '../../design/icons';
import styles from './call-type-tabs.module.scss';
import ButtonBar, { Button } from '../../design/button-bar/button-bar';


export const CallTypeTabs = ({
  activeTab,
  className,
  onChange,
  values,
}: CallTypeTabsProps) => {


  const handleClick = (type?: CallType) => {
    if (onChange) onChange(type);
  };

  const tabs = [
    {
      id: "all",
      component:
        <CallTypeTab
          active={!activeTab}
          type="all"
          title="כל השיחות"
          subtitle={values?.all?.toString()}
        />
    },
    {
      id: "inbound",
      component:
        <CallTypeTab
          active={activeTab === "inbound"}
          type="inbound"
          title="נכנסות"
          subtitle={values?.inbound?.toString()}
        />
    },
    {
      id: "outbound",
      component:
        <CallTypeTab
          active={activeTab === "outbound"}
          type="outbound"
          title="יוצאות"
          subtitle={values?.outbound?.toString()}
        />
    },
    {
      id: "local",
      component:
        <CallTypeTab
          active={activeTab === "local"}
          type="local"
          title="פנימיות"
          subtitle={values?.local?.toString()}
        />
    }
  ] as Button[];

  return (
    <ButtonBar
      onToggle={(id) => handleClick(id !== "all" ? id as CallType : undefined)}
      buttons={tabs}
      selectedButtonId={activeTab as string}
      toggleBar={true}
      className={classNames(styles.callTypeFilter, className)}
    />
  );
}

export interface CallTypeTabsProps {
  activeTab?: CallType;
  className?: string;
  values?: {
    all?: string;
    inbound?: string;
    outbound?: string;
    callback?: string;
    local?: string;
  }
  onChange?: (type?: CallType) => void;
}

const icons = {
  all: <PhoneRight size={32} />,
  inbound: <ArrowDown size={32} />,
  outbound: <ArrowUpLeft size={32} />,
  callback: <ArrowUTurnRight size={32} />,
  local: <ArrowHorizontal size={32} />
}

export const CallTypeTab = ({
  active = false,
  className,
  subtitle,
  title,
  type,
}: CallTypeProps) => (
  <div className={classNames(styles.callTypeTab, styles[type], { [styles.active]: active }, className)}>
    <div className={styles.content}>
      <div className={styles.title}>{title}</div>
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    </div>
    <div className={classNames(styles.icon, styles[type])}>{icons[type]}</div>
  </div>
)

export interface CallTypeProps extends HTMLAttributes<HTMLButtonElement> {
  active?: boolean;
  title: string;
  subtitle?: string;
  type: CallType;
}

export type CallType = "all" | "inbound" | "outbound" | "callback" | "local";
