import { AppLayout, AppLayoutContainer, AppLayoutHeader, AppLayoutTitle } from "../layouts/app-layout/app-layout";
import { GroupsList } from "./groups-list/groups-list";
import { Page } from "../page";

export const GroupsListPage = () => (
    <Page name="My Groups">
        <AppLayout>
            <AppLayoutContainer>
                <AppLayoutHeader>
                    <AppLayoutTitle>קבוצות</AppLayoutTitle>
                </AppLayoutHeader>
                <GroupsList />
            </AppLayoutContainer>
        </AppLayout>
    </Page>
)