import { forwardRef } from 'react';
import { RadioProps } from '@mui/material';

import { RadioOption } from '../../../formConfig/types';
import {
  StyledFormControl,
  StyledFormControlLabel,
  StyledFormLabel,
  StyledRadio,
  StyledRadioGroup,
} from './StyledCustomRadio';

interface CustomRadioProps extends RadioProps {
  name: string;
  label?: string;
  options: RadioOption[];
  value?: string | number | boolean;
}

export const CustomRadio = forwardRef<HTMLInputElement, CustomRadioProps>(
  ({ label, value, name, options, ...props }, ref) => {
    return (
      <StyledFormControl>
        <StyledFormLabel focused={false}>{label}</StyledFormLabel>
        <StyledRadioGroup name={name} value={value}>
          {options.map((option, index) => (
            <StyledFormControlLabel
              key={index}
              value={option.value}
              control={<StyledRadio {...props} inputRef={ref} />}
              label={option.label}
            />
          ))}
        </StyledRadioGroup>
      </StyledFormControl>
    );
  }
);
