import { API } from "aws-amplify";
import mixpanel from "mixpanel-browser";
import { useContext, useState } from "react";
import { useGroups } from "../../api/use-groups";
import { useMe } from "../../api/use-me";
import { Dialog } from "../../design/dialog/dialog";
import { Field } from "../../design/form/field";
import { Input } from "../../design/form/input/input";
import { activeGroupContext } from "../active-group-context";

export const GroupNameDialog = ({ dialog }: any) => {
  const [activeGroup, setActiveGroup] = useContext(activeGroupContext);
  const [loading, setLoading] = useState(false);
  const user = useMe();
  const { mutate } = useGroups(user.data?.domain.id);

  const handleSubmit = async (values: { name: string }) => {
    try {
      setLoading(true);
      const body = {
        name: values.name,
        number: activeGroup?.number
      }
      await API.patch(
        process.env.REACT_APP_PHP_SERVICES_NAME!,
        `/crud/dom/${user?.data?.domain.id}/RingGroup/${activeGroup?.number}`,
        { body });
      mixpanel.track('Create Group Name', {
        'Destination Type': activeGroup?.name
      });
      await mutate();
    } catch (error: any) {
      console.error(error);
    } finally {
      dialog.hide();
      setLoading(false);
    }
  }

  const onClose = () => {
    setActiveGroup(undefined);
  }

  return (
    <Dialog {...dialog}
      variant="form"
      header={`עריכת שם קבוצה - (${activeGroup?.number} ${activeGroup?.name || ""})`}
      initialValues={{ name: activeGroup?.name || "" }}
      onSubmit={handleSubmit}
      submitLabel="שמירת שם"
      onClose={onClose}
      loading={loading}
      aria-label="שינוי שם שלוחה">
      <Field name="name" label="שם קבוצה">
        <Input placeholder="למשל, מארחת בוקר" />
      </Field>
    </Dialog >
  )
}
