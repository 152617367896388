import { DialerAttempt, DialerRequest, RequestStatus } from "../../callbacks/types";

export function getCallbackRequestStatusString(dialerRequest: DialerRequest): string {

  // if (dialerRequest.request_status === 'canceled') return 'בוטלה';
  if (dialerRequest.request_status === 'completed') return 'טופלה';

  if (dialerRequest.request_status === 'active' || dialerRequest.request_status === 'waiting') {
    return dialerRequest.num_attempts == 0 ? 'פתוחה' : `ניסיון ${dialerRequest.num_attempts}/${dialerRequest.max_attempts}`;
  }

  if (['failed', 'canceled'].includes(dialerRequest.request_status)) {
    switch (dialerRequest.completion_code) {
      case 'expired': return 'בקשה לא הסתיימה בפרק הזמן המרבי'
      case 'too_many_attempts': return 'כל נסיונות החיוג נכשלו'
      case 'duplicate': return 'בקשה כפולה'
      case 'already_talked': return 'הייתה שיחה עם הלקוח לאחר השארת בקשת החיוג'
      case 'manually': return 'בקשה בוטלה דרך ממשק הניהול'
    }
  }

  console.error('dialer request has illegal status');
  return 'סטטוס לא תקין';
}

export function getCallbackAttemptStatusString(dialerAttempt: DialerAttempt): string {
  if (dialerAttempt.attempt_status === 'answered') return 'נענתה';
  if (dialerAttempt.attempt_status === 'dialing') return 'בחיוג';

  if (dialerAttempt.attempt_status === 'failed') {
    switch (dialerAttempt.completion_code) {
      case 'customer_cannot_dial': return 'חיוג ללקוח נכשל'
      case 'admin_success': return 'הצלחה מנהלתית'
      case 'admin_error': return 'שיחה הושהתה'
      case 'agent_cannot_dial': return 'חיוג לנציג נכשל'
      case 'agent_no_answer': return 'אין מענה מהנציג'
      case 'agent_rejected': return 'הנציג דחה את השיחה'
      case 'agent_busy': return 'הנציג תפוס'
      case 'agent_ivr': return 'מענה קולי ענה במקום נציג'
      case 'agent_fax': return 'פקס ענה במקום נציג'
      case 'agent_hangup': return 'הנציג ניתק'
      case 'customer_no_answer': return 'אין מענה מהלקוח'
      case 'customer_rejected': return 'הלקוח דחה את השיחה'
      case 'customer_busy': return 'הלקוח תפוס'
      case 'customer_ivr': return 'מענה קולי ענה במקום הלקוח'
      case 'customer_fax': return 'פקס ענה במקום הלקוח'
      case 'customer_hangup': return 'הלקוח ניתק'
    }
  }

  console.error('dialer attempt has illegal status')
  return 'סטטוס לא תקין'
}


export const requestTabFilterStrings = new Map<string, string>([
  ["all", 'סה"כ'],
  ["open", "פתוחות"],
  ["completed", "טופלו"],
  ["canceled", "בוטלו"],
  ["failed", "לא זמין"]
])

export const buttonTextStrings = new Map<string, string>([
  ["cancel", "ביטול"],
  ["edit", "עריכה"],
  ["finish", "סיום"],
  ["cancel-dial-request", "ביטול בקשת חיוג"],
  ["cancel-dial-requests", "ביטול בקשות חיוג"],
  ["forward-call", "הפנית שיחה"],
  ["confirm-forward", "ביצוע הפניה"]
])

export const ISRAELI_PHONE_REGEXP = /^(?:(?:(\+?972|\(\+?972\)|\+?\(972\))(?:\s|\.|-)?([1-9]\d?))|(0[23489]{1})|(0[57]{1}[0-9]))(?:\s|\.|-)?([^0\D]{1}\d{2}(?:\s|\.|-)?\d{4})$/;

export const isValidIsraeliIdNumber = (id?: string | undefined) => {
  if (!id) return false;
  if (id.length > 9) return false;
  const cleanId = addLeadingZeros('id_number', id, 9);

  // Convert the ID number to an array of digits
  const digits = cleanId.split('').map(Number);

  let sum = 0;
  for (let i = 0; i < digits.length; i++) {
    const isEven = i % 2 === 0;
    const digit = digits[i];
    sum += digit * (isEven ? 1 : 2);
    if (digit >= 5 && !isEven) {
      sum -= 9;
    }
  }

  return sum % 10 === 0;
}

export const addLeadingZeros = (fieldName: string, value: string, requiredTotalLength: number): string => {
  if (value.length > requiredTotalLength) {
    console.error(`${fieldName} is too long`)
    return value;
  }
  const leadingZeros = new Array((requiredTotalLength - value.length) + 1).join('0');
  return leadingZeros + value;
}