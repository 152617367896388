import { CSSProperties } from 'react';

export enum TypographyVariant {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  boldCaption1 = 'boldCaption1',
  boldCaption2 = 'boldCaption2',
  boldText1 = 'boldText1',
  boldText2 = 'boldText2',
  largeText1 = 'largeText1',
  largeText2 = 'largeText2',
  largeText3 = 'largeText3',
  regularText1 = 'regularText1',
  regularText2 = 'regularText2',
  regularText3 = 'regularText3',
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    [TypographyVariant.boldCaption1]: CSSProperties;
    [TypographyVariant.boldCaption2]: CSSProperties;
    [TypographyVariant.boldText1]: CSSProperties;
    [TypographyVariant.boldText2]: CSSProperties;
    [TypographyVariant.largeText1]: CSSProperties;
    [TypographyVariant.largeText2]: CSSProperties;
    [TypographyVariant.largeText3]: CSSProperties;
    [TypographyVariant.regularText1]: CSSProperties;
    [TypographyVariant.regularText2]: CSSProperties;
    [TypographyVariant.regularText3]: CSSProperties;
  }

  interface TypographyVariantsOptions {
    [TypographyVariant.boldCaption1]?: CSSProperties;
    [TypographyVariant.boldCaption2]?: CSSProperties;
    [TypographyVariant.boldText1]?: CSSProperties;
    [TypographyVariant.boldText2]?: CSSProperties;
    [TypographyVariant.largeText1]?: CSSProperties;
    [TypographyVariant.largeText2]?: CSSProperties;
    [TypographyVariant.largeText3]?: CSSProperties;
    [TypographyVariant.regularText1]?: CSSProperties;
    [TypographyVariant.regularText2]?: CSSProperties;
    [TypographyVariant.regularText3]?: CSSProperties;
  }

  interface Theme {
    borderRadius: {
      base: string;
      default: string;
      badge: string;
      main: string;
      rounded: string;
      ellipse: string;
      circle: string;
    };
    fontSizes: {
      small: string;
      medium: string;
      main: string;
      large: string;
    };
    lineHeights: {
      small: string;
      medium: string;
      main: string;
      large: string;
      xlarge: string;
    };
    fieldHeights: {
      xSmall: string;
      small: string;
      medium: string;
      default: string;
      main: string;
      large: string;
    };
    fontWeights: {
      light: number;
      regular: number;
      medium: number;
      semiBold: number;
      bold: number;
    };
    texts: {
      primary: string;
      secondary: string;
      tertiary: string;
      white: string;
      darkBlue: string;
    };
    backgrounds: {
      bg_main: string;
      bg_modal: string;
      bg_upload_file: string;
    };
    sizes: {
      small: {
        width: string;
        height: string;
      };
      medium: {
        width: string;
        height: string;
      };
      large: {
        width: string;
        height: string;
      };
    };
    borders: {
      default: string;
      main: string;
      primary: string;
    };
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    borderRadius: {
      base?: string;
      default?: string;
      badge?: string;
      main?: string;
      rounded?: string;
      ellipse?: string;
      circle?: string;
    };
    fontSizes: {
      small?: string;
      medium?: string;
      main?: string;
      large?: string;
    };
    lineHeights: {
      small?: string;
      medium?: string;
      main?: string;
      large?: string;
      xlarge?: string;
    };
    fieldHeights: {
      xSmall?: string;
      small?: string;
      medium?: string;
      default?: string;
      main?: string;
      large?: string;
    };
    fontWeights: {
      light: number;
      regular: number;
      medium: number;
      semiBold: number;
      bold: number;
    };
    texts: {
      primary?: string;
      secondary?: string;
      tertiary?: string;
      white?: string;
      darkBlue?: string;
    };
    backgrounds: {
      bg_main?: string;
      bg_modal?: string;
      bg_upload_file?: string;
    };
    sizes: {
      small?: {
        width?: string;
        height?: string;
      };
      medium?: {
        width?: string;
        height?: string;
      };
      large?: {
        width?: string;
        height?: string;
      };
    };
    borders: {
      default?: string;
      main?: string;
      primary?: string;
    };
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    [TypographyVariant.boldCaption1]: true;
    [TypographyVariant.boldCaption2]: true;
    [TypographyVariant.boldText1]: true;
    [TypographyVariant.boldText2]: true;
    [TypographyVariant.largeText1]: true;
    [TypographyVariant.largeText2]: true;
    [TypographyVariant.largeText3]: true;
    [TypographyVariant.regularText1]: true;
    [TypographyVariant.regularText2]: true;
    [TypographyVariant.regularText3]: true;
    h6: false;
    inherit: false;
    overline: false;
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
    button: false;
    caption: false;
  }
}

export enum ButtonVariant {
  contained = 'contained',
  outlined = 'outlined',
  text = 'text',
}

export enum Color {
  primary = 'primary',
  secondary = 'secondary',
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export enum IconSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum LoadingPosition {
  start = 'start',
  end = 'end',
}

export enum IconPosition {
  start = 'start',
  end = 'end',
  center = 'center',
}

export enum SnackbarHorizontal {
  left = 'left',
  center = 'center',
  right = 'right',
}

export enum SnackbarVertical {
  top = 'top',
  bottom = 'bottom',
}

export enum HorizontalPosition {
  Center = 'center',
  Left = 'left',
  Right = 'right',
}

export enum VerticalPosition {
  Center = 'center',
  Bottom = 'bottom',
  Top = 'top',
}

export enum SnackbarVariant {
  success = 'success',
  error = 'error',
  warning = 'warning',
  info = 'info',
}

export enum LinearProgressVariant {
  indeterminate = 'indeterminate',
  determinate = 'determinate',
  buffer = 'buffer',
  query = 'query',
}

export enum FieldVariant {
  Inline = 'inline',
  Block = 'block',
}

export enum FieldType {
  Chip = 'chip',
  MenuChip = 'menuChip',
  Item = 'item',
  MenuItem = 'menuItem',
}

export enum ChipVariant {
  Filled = 'filled',
  Outlined = 'outlined',
}

export enum ChipSize {
  small = 'small',
  medium = 'medium',
}
