import { useEffect, useState } from 'react';
import { useMediaQuery } from '../layouts/app-layout/app-layout';
import { mobileQuery, tabletQuery, desktopQuery, wideDesktopQuery } from '../theme/media';
import { GroupedCalls } from './use-grouped-calls';

export const getFromToTimes = (groupedBy: string, groupKey: string | number, fromTime: string, toTime: string) => {
  if (groupKey === 'summation') {
    return { fromTime, toTime };
  }

  if (groupedBy.startsWith('hours')) {
    const hoursInterval = Number(groupedBy.split(':')[1]);

    if (!hoursInterval) {
      console.error('groupBy/hours:x filter was not set properly');
      return { fromTime, toTime };
    }

    return {
      fromTime: `${Number(groupKey) < 10 ? '0' : ''}${groupKey}:00`,
      toTime: `${addMinutesToTime(formatGroupKey(String(groupKey), groupedBy), hoursInterval * 60)}`,
    };
  }

  if (groupedBy.startsWith('minutes')) {
    const minutesInterval = Number(groupedBy.split(':')[1]);
    if (!minutesInterval) {
      console.error('groupBy/minutes:x filter was not set properly');
      return { fromTime, toTime };
    }

    return {
      fromTime: `${groupKey}`,
      toTime: `${addMinutesToTime(String(groupKey), minutesInterval)}`,
    };
  }

  return { fromTime, toTime };
};

export const formatGroupKey = (groupKey: string, groupedBy: string): string => {
  if (groupKey === 'summation') {
    return 'סה"כ';
  }
  if (groupedBy.startsWith('hours')) return `${Number(groupKey) < 10 ? '0' : ''}${groupKey}:00`;
  if (groupedBy == 'dayOfMonth') return `${groupKey} בחודש`;
  if (groupedBy == 'week') return `שבוע ${groupKey}`;

  if (groupedBy === 'dayOfWeek') {
    switch (groupKey) {
      case '1':
        return "יום א'";
      case '2':
        return "יום ב'";
      case '3':
        return "יום ג'";
      case '4':
        return "יום ד'";
      case '5':
        return "יום ה'";
      case '6':
        return "יום ו'";
      case '7':
        return "יום ש'";
    }
  }

  if (groupedBy === 'month') {
    switch (groupKey) {
      case '1':
        return 'ינואר';
      case '2':
        return 'פברואר';
      case '3':
        return 'מרץ';
      case '4':
        return 'אפריל';
      case '5':
        return 'מאי';
      case '6':
        return 'יוני';
      case '7':
        return 'יולי';
      case '8':
        return 'אוגוסט';
      case '9':
        return 'ספטמבר';
      case '10':
        return 'אוקטובר';
      case '11':
        return 'נובמבר';
      case '12':
        return 'דצמבר';
    }
  }

  return groupKey;
};

export const getGroupedByHeader = (groupedBy: string) => {
  if (groupedBy.startsWith('minutes') || groupedBy.startsWith('hours')) return 'שעה';

  switch (groupedBy) {
    case 'dayOfMonth':
      return 'יום בחודש';
    case 'dayOfWeek':
      return 'יום בשבוע';
    case 'month':
      return 'חודש';
    case 'week':
      return 'שבוע';
  }

  return groupedBy;
};

export const emptyCallsGroup: GroupedCalls = {
  groupKey: '',
  inboundCount: 0,
  inboundAnsweredCount: 0,
  inboundUnansweredCount: 0,
  callbackAttemptsCount: 0,
  outboundCount: 0,
  outboundAnsweredCount: 0,
  outboundUnansweredCount: 0,
  avgTta: 0,
  avgDuration: 0,
  answerRate: 0,
  sla: 0,
};

function addMinutesToTime(timeString: string, minutesToAdd: number) {
  // Parse the input time string
  const [hours, minutes] = timeString.split(':').map(Number);

  // Add the specified number of minutes
  const totalMinutes = hours * 60 + minutes + minutesToAdd;

  // Calculate the new hours and minutes
  const newHours = Math.floor(totalMinutes / 60);
  const newMinutes = totalMinutes % 60;

  // Format the result back into 'HH:mm' format
  const resultTimeString = `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;

  return resultTimeString;
}

export const useTickAmount = (arr: string[], groupedBy: string) => {
  const isMobile = useMediaQuery(mobileQuery);
  const isTablet = useMediaQuery(tabletQuery) && !isMobile;
  const isDesktop = useMediaQuery(desktopQuery) && !isMobile && !isTablet;
  const isWideDesktop =
    (useMediaQuery(wideDesktopQuery) && !isMobile && !isTablet && !isDesktop) || (!isMobile && !isTablet && !isDesktop);

  const [tickAmount, setTickAmount] = useState<number>(0);
  const [rotate, setRotate] = useState<number>(0);
  const [offsetY, setOffsetY] = useState<number>(0);

  const timeStep =
    groupedBy === 'minutes:15'
      ? 15
      : groupedBy === 'minutes:30'
      ? 30
      : groupedBy === 'hours:1'
      ? 24
      : groupedBy === 'hours:3'
      ? Math.floor(24 / 3)
      : groupedBy === 'dayOfMonth'
      ? 31
      : groupedBy === 'dayOfWeek'
      ? 31
      : groupedBy === 'week'
      ? Math.floor(365 / 7)
      : groupedBy === 'month'
      ? 12
      : 1;

  const MOBILE_THRESHOLD = 5;
  const TABLET_THRESHOLD = 5;
  const DESKTOP_THRESHOLD = 8;
  const WIDE_DESKTOP_THRESHOLD = 10;

  const resizeHandler = () => {
    if (window.innerWidth >= 712 && window.innerWidth <= 992) {
      setRotate(0);
      setOffsetY(0);
    } else {
      setRotate((isMobile || isTablet) && arr.length >= 10 ? -30 : 0);
      setOffsetY(isMobile || isTablet ? 20 : 0);
    }
  };

  useEffect(() => {
    /**
     * BUG: at 711px ~ 992px screen width the chart does not show x-axis content
     */
    let tickAmount;

    if (isMobile) {
      tickAmount = arr.length > MOBILE_THRESHOLD ? MOBILE_THRESHOLD : arr.length;
    } else if (isTablet) {
      tickAmount = arr.length > TABLET_THRESHOLD ? TABLET_THRESHOLD : arr.length;
    } else if (isDesktop) {
      tickAmount = arr.length > DESKTOP_THRESHOLD ? DESKTOP_THRESHOLD : arr.length;
    } else if (isWideDesktop) {
      tickAmount = arr.length > WIDE_DESKTOP_THRESHOLD ? WIDE_DESKTOP_THRESHOLD : arr.length;
    }

    window.addEventListener('resize', resizeHandler);

    setTickAmount(tickAmount || timeStep);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [isMobile, isTablet, isDesktop, isWideDesktop, arr.length]);

  return { tickAmount, rotate, offsetY };
};
