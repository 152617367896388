import React from 'react';
import { Icon, IconProps } from './icon';

export const NavClose = ({ ...props }: IconProps) => (
  <Icon
    viewBox="0 0 96 96"
    fill="currentColor"
    {...props}
  >
    <g fill="currentColor">
      <path d="M67.0918831,23.2512627 L72.7487373,28.9081169 L53.6562627,47.9992627 L72.7487373,67.0918831 L67.0918831,72.7487373 L47.9992627,53.6562627 L28.9081169,72.7487373 L23.2512627,67.0918831 L42.3432627,47.9992627 L23.2512627,28.9081169 L28.9081169,23.2512627 L47.9992627,42.3432627 L67.0918831,23.2512627 Z"></path>
    </g>
  </Icon>
);


