import React from 'react';
import { DestinationNode } from './destination-node';
import { BusinessNumberResource, BusinessNumberResourceRootDestination, DestinationResource } from '../../api/types';
import { EventCallflow } from './event-callflow/event-callflow';
import { CallForwardingCallflow } from './call-forwarding-callflow/call-forwarding-callflow';

export function Callflow({ number, rootDestination }: CallflowProps) {
  
  const activeRootDestination =  number.RootDestinationCollection
    .find(rootDestination => rootDestination.id === number.activeRootDestinationId) as BusinessNumberResourceRootDestination;


  if (number.forwardIncomingCallsTo) {
    return <CallForwardingCallflow number={number} />
  }

  // @TODO consider setting activeEventId to null in backend
  // when there is different active root destination
  if (number.activeEventId && activeRootDestination?.type === "event") {
    return (
      <EventCallflow
        number={number}
        eventId={number.activeEventId}
        rootDestination={rootDestination}
      />
    );
  }

  return (
    <DestinationNode
      destination={activeRootDestination?.destinationNumber}
    />
  )
}

export interface CallflowProps {
  number: BusinessNumberResource;
  rootDestination: DestinationResource;
}