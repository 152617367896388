import { memo } from 'react';
import classNames from 'classnames';

import { Button } from '../../design/button';
import { useNavigate } from 'react-router-dom';
import { ArrowRight } from '../../design/icons';
import { FORM_SECTIONS } from '../formConfig/callbackDialerFormConfig';
import styles from '../../emergency-dialer/dialer-settings/menu/menu.module.scss';
import { AppLayoutBreadcrumbLink, AppLayoutBreadcrumbs } from '../../layouts/app-layout/app-layout';

interface MenuProps {
  selectedSectionId: string;
  dialerId: number;
  dialerName: string;
}

function Menu({ selectedSectionId, dialerId, dialerName }: MenuProps) {
  const handleClick = (sectionId: string) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={styles.menu}>
      <MenuHeader dialerId={dialerId} dialerName={dialerName} />
      {FORM_SECTIONS.map((section) => {
        const isSelected = section.id === selectedSectionId;
        return (
          <Button
            key={section.id}
            className={classNames(styles.scrollButton, { [styles.selected]: isSelected })}
            onClick={() => handleClick(section.id)}
          >
            {isSelected ? <span className={styles.colorRect} /> : undefined}
            {section.name}
          </Button>
        );
      })}
    </div>
  );
}

export default memo(Menu);

interface MenuHeaderProps {
  dialerId: number;
  dialerName: string;
}

const MenuHeader = memo(({ dialerId, dialerName }: MenuHeaderProps) => {
  const navigate = useNavigate();
  return (
    <div className={styles.menuHeader}>
      <div className={styles.breadCrumbs}>
        <ArrowRight className={styles.arrowRight} onClick={() => navigate('/callback-dialer/dialers/')} />
        <AppLayoutBreadcrumbs>
          <AppLayoutBreadcrumbLink to='/callback-dialer/dialers/'>רשימה</AppLayoutBreadcrumbLink>
          <AppLayoutBreadcrumbLink to={`/callback-dialer/dialers/${dialerId}`}>
            {dialerId} {dialerName}
          </AppLayoutBreadcrumbLink>
        </AppLayoutBreadcrumbs>
      </div>

      <div className={styles.menuTitle}>
        {dialerId} {dialerName}
      </div>
    </div>
  );
});
