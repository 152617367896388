import { HTMLAttributes, ReactNode, useEffect, useRef, useState } from "react";
import { ChevronDown, ChevronUp } from "@carbon/icons-react";
import { Form, Formik } from "formik";
import { Button } from "../button";
import classNames from "classnames";
import styles from "./filter.module.scss";

export const Filter = ({
  active = false,
  variant = "default",
  badge,
  children,
  initialValues,
  label,
  onReset,
  onSubmit,
}: FilterProps) => {
  const [expanded, setExpanded] = useState(false);
  const handleToggle = () => setExpanded((expanded) => !expanded);

  const filterRef = useRef<any>();
  const windowRef = useRef<any>();

  useEffect(() => {
    const listener = (event: any) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!filterRef.current || filterRef.current.contains(event.target)) {
        return;
      }

      setExpanded(false);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [filterRef]);

  const handleFormSubmit = (values: any) => {
    onSubmit(values);
    setExpanded(false);
  };

  const handleResetForm = (handleReset: () => void) => {
    onReset();
    handleReset();
    setExpanded(false);
  };

  useEffect(() => {
    if (filterRef.current && windowRef.current) {
      const equator = window.innerWidth / 2;
      const documentDirection = window.getComputedStyle(document.documentElement).direction;
      const filterPosition = filterRef.current.getBoundingClientRect();

      if (equator > filterPosition) {
        windowRef.current.style[documentDirection === "ltr" ? "right" : "left"] = 0;
      } else {
        windowRef.current.style[documentDirection === "ltr" ? "left" : "right"] = 0;
      }
    }
  }, [filterRef, windowRef]);

  const isDefault = variant === "default";
  return (
    <div className={classNames(styles.filter, { [styles.expanded]: expanded })} ref={filterRef}>
      {isDefault ? (
        <button
          className={classNames(styles.handle, { [styles.expanded]: expanded })}
          onClick={() => handleToggle()}
        >
          <span className={styles.label}>{label}</span>
          {badge || (expanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />)}
        </button>
      ) : (
        <Button onClick={() => handleToggle()} className={styles.button}>
          <span className={styles.label}>{label}</span>
          {badge || (expanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />)}
        </Button>
      )}

      <div className={classNames(styles.window, { [styles.expanded]: expanded })} ref={windowRef}>
        <Formik
          key={`statusForm_${active ? "on" : "off"}`}
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
        >
          {({ handleSubmit, handleReset }) => (
            <Form>
              <FilterContent>{children}</FilterContent>
              <FilterFooter>
                <Button
                  type='submit'
                  className={styles.filterFormButton}
                  onClick={() => handleSubmit()}
                  variant='primary'
                >
                  סינון
                </Button>
                <Button
                  type='button'
                  className={styles.filterFormButton}
                  onClick={() => handleResetForm(handleReset)}
                  variant='link'
                >
                  איפוס
                </Button>
              </FilterFooter>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export const FilterHeader = ({ className, ...props }: HTMLAttributes<HTMLElement>) => (
  <header className={classNames(styles.header, className)} {...props} />
);

export const FilterContent = ({ className, ...props }: HTMLAttributes<HTMLElement>) => (
  <section className={classNames(styles.content, className)} {...props} />
);

export const FilterFooter = ({ className, ...props }: HTMLAttributes<HTMLElement>) => (
  <footer className={classNames(styles.footer, className)} {...props} />
);

export interface FilterProps {
  active: boolean;
  badge?: ReactNode;
  variant?: "default" | "button-like";
  children?: ReactNode;
  initialValues: any;
  label: ReactNode;
  onReset: () => void;
  onSubmit: (values: any) => void;
}
