import { DefaultDialerValidation } from '../types';

export const renderDefaultDialerMsg = (defaultDialerValidation: DefaultDialerValidation) => {
  const errorMessages = { business_number: '', agent_group_number: '' };
  if (!defaultDialerValidation.hasBusinessNumber) {
    errorMessages.business_number = 'אין אף מספר ברשימת "המספרים שלי".';
  }
  if (!defaultDialerValidation.hasAgentGroupNumber) {
    errorMessages.agent_group_number = 'אין אף קבוצה ברשימת "הקבוצות שלי".';
  }
  return `${errorMessages.business_number} ${errorMessages.agent_group_number}`;
};
