import { FC } from 'react';
import { MenuItem } from '@mui/material';
import { SelectOption } from '../../../formConfig/types';

interface MenuListProps {
  options: SelectOption[];
}

export const MenuList: FC<MenuListProps> = ({ options }) => {
  const menuList = options.map((option, index) => (
    <MenuItem key={index} value={option.value}>
      {option.label}
    </MenuItem>
  ));

  return menuList.length > 0 ? <>{menuList}</> : null;
};
