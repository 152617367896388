import { useDialogState } from 'reakit';

import { Remove } from '../../design/icons/remove';
import { ChevronUp } from '../../design/icons/chevron-up';
import { ChevronDown } from '../../design/icons/chevron-down';
import DeleteCallbackDialog from '../dialerDialog/DeleteCallbackDialog';
import styles from '../../emergency-dialer/dial-groups/list-item/list-item.module.scss';
import { ButtonWithDropdown } from '../../design/button-with-dropdown/button-with-dropdown';

interface CallbackDialerListItemProps {
  data: {
    dialer_id: number;
    name: string;
  };
  onListItemClick: () => void;
  mutateCallbackDialers: () => void;
}

export default function CallbackDialerListItem({
  data,
  onListItemClick,
  mutateCallbackDialers,
}: CallbackDialerListItemProps) {
  return (
    <li className={styles.listItem}>
      <span className={styles.dialGroupRow} onClick={onListItemClick}>
        <span className={styles.dialerDetails}>
          <span className={styles.strong}>{data.dialer_id}</span>
          &nbsp;
          <span>{data.name}</span>
        </span>
      </span>
      <ButtonWithDropdown
        label='פעולות'
        buttonClassName={styles.menuButton}
        expandedBadge={<ChevronUp />}
        collapsedBadge={<ChevronDown />}
      >
        <MenuItems dialer_id={data.dialer_id} dialer_name={data.name} mutateCallbackDialers={mutateCallbackDialers} />
      </ButtonWithDropdown>
    </li>
  );
}

interface MenuItemsProps {
  dialer_id: number;
  dialer_name: string;
  mutateCallbackDialers: () => void;
}

const MenuItems = ({ dialer_id, dialer_name, mutateCallbackDialers }: MenuItemsProps) => {
  const deleteCallbackDialerDialog = useDialogState();

  const handleSelectActionType = () => {
    deleteCallbackDialerDialog.show();
  };

  return (
    <div>
      <div className={styles.menuItem} onClick={handleSelectActionType}>
        <span className={styles.caution}>מחיקת חייגן שיחה</span>
        <Remove />
      </div>
      <DeleteCallbackDialog
        dialog={deleteCallbackDialerDialog}
        dialerId={dialer_id}
        dialerName={dialer_name}
        mutateCallbackDialers={mutateCallbackDialers}
      />
    </div>
  );
};
