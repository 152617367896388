import { useEffect, useState } from 'react';

export enum OS {
  WindowsPhone = "Windows Phone",
  Windows = "Windows",
  Android = "Android",
  Linux = "Linux",
  iOS = "iOS",
  MacOS = "Mac OS",
  Unknown = "Unknown OS"
}

export enum Browser {
  Firefox = "Mozilla Firefox",
  SamsungInternet = "Samsung Internet",
  Opera = "Opera",
  IE = "Microsoft Internet Explorer",
  Edge = "Microsoft Edge",
  Chrome = "Google Chrome",
  Safari = "Safari",
  Unknown = "Unknown Browser"
}



interface BrowserInfo {
  os: OS;
  browser: Browser;
  userAgent: string;
}

const getOS = (): OS => {
  const userAgent = window.navigator.userAgent;
  console.log('userAgent: ', userAgent);
  if (/windows phone/i.test(userAgent)) {
    return OS.WindowsPhone;
  }
  if (/win/i.test(userAgent)) {
    return OS.Windows;
  }
  if (/android/i.test(userAgent)) {
    return OS.Android;
  }
  if (/linux/i.test(userAgent)) {
    return OS.Linux;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return OS.iOS;
  }
  if (/Macintosh/.test(userAgent)) {
    return OS.MacOS;
  }
  return OS.Unknown;
};

const getBrowser = (): Browser => {
  const userAgent = window.navigator.userAgent;
  if (userAgent.indexOf("Chrome") > -1 && "chrome" in window) {
    return Browser.Chrome;
  }

  return Browser.Unknown;
};

export const useBrowserInfo = (): BrowserInfo => {
  const [browserInfo, setBrowserInfo] = useState<BrowserInfo>({
    os: OS.Unknown,
    browser: Browser.Unknown,
    userAgent: '',
  });

  useEffect(() => {
    const os = getOS();
    const browser = getBrowser();
    const userAgent = navigator.userAgent;

    setBrowserInfo({
      os,
      browser,
      userAgent,
    });
  }, []);

  return browserInfo;
};
