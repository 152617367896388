import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import styles from './auth-layout.module.scss';
import { Logo } from '../../design/logo/logo';

export const AuthLayout = ({children, className, ...props}: AuthLayoutProps) => (
  <div className={classNames(styles.root, className)} {...props}>
    <header className={classNames(styles.header, props.headerClassName)}>
     <Logo width={175} height={27}/>
    </header>
    <main className={classNames(styles.main, props.mainClassName)}>
      <div className={classNames(styles.content, props.contentClassName)}>
        {children}
      </div>
    </main>
  </div>
);

export interface AuthLayoutProps extends HTMLAttributes<HTMLDivElement> {
  headerClassName?: string,
  mainClassName?: string,
  contentClassName?: string
}