import { BusinessNumberResource, EventResource } from './types';
import { useApi } from './use-api';
import { useMe } from './use-me';
import useSWR from 'swr';
import { User } from './use-users';
import { API } from 'aws-amplify';

const SWR_CONFIG = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

// Check why this hook returna error
export function useBusinessNumberCollection(force = false) {
  const user = useMe(force);
  const domainId = user?.data?.domain.id;
  const path = domainId ? ['domains', domainId, 'business-numbers'].join('/') : null;
  return useApi<BusinessNumberResource[]>(path, undefined, force);
}

export function useBusinessNumberResource(number: string, force = false) {
  const user = useMe(force);
  const domainId = user?.data?.domain.id;
  const path = domainId ? ['domains', domainId, 'business-numbers', number].join('/') : null;
  return useApi<BusinessNumberResource>(path, undefined, force);
}

export function useBusinessNumberEventCollection(number: string) {
  const user = useMe();
  const domainId = user?.data?.domain.id;
  const path = domainId ? ['domains', domainId, 'business-numbers', number, 'events'].join('/') : null;
  return useApi<EventResource[]>(path);
}

export function useBusinessNumberEventResource(number: string, eventId: string) {
  const user = useMe();
  const domainId = user?.data?.domain.id;
  const path = domainId ? ['domains', domainId, 'business-numbers', number, 'events', eventId].join('/') : null;
  return useApi<EventResource>(path);
}

/**
 * A hook to fetch business numbers of Multi-Tenant users.
 * @param user - a Multi-tenant user
 * @returns An object key:value pairs. An entry has a domain uuid as a key and a business numbers array as a value.
 */
export const useBusinessNumbers = (user?: User) => {
  const userDomains = user?.domains;
  const domainUuids = userDomains?.map((domain) => domain.domain_uuid);
  const paths = domainUuids?.map((id) => `/domains/${id}/business-numbers`);
  const { data, error } = useSWR(paths, multiTenantBusinessNumberFetcher, SWR_CONFIG);
  const groupedData: Record<string, BusinessNumberResource[]> = Object.fromEntries(
    data?.map((numbers, index) => [domainUuids?.[index], numbers]) || []
  );

  return { data: groupedData, error, loading: !error && !data };
};

const multiTenantBusinessNumberFetcher = async (ids: string[]) => {
  const urlName = process.env.REACT_APP_WEB_API_NAME!;
  const data = [];
  for (const id of ids) {
    try {
      data.push(await API.get(urlName, id, {}));
    } catch (err) {
      data.push([]);
      console.error('Failed to fetch business numbers of domain: ', id);
    }
  }
  return data;
};
