import { ConfigResourceDialogProps } from "./dialogs/config-resource-dialog";
import styles from "./self-managed-resources.module.scss";

export const SelfManageResources = ({ resources, onResourceClick }: SelfManagedResourcesProps) => (
  <div className={styles.resourceList}>
    {resources.destinations?.length ?
      <SelfManageResource
        heading={"יעד סגור | שיחות נכנסות"}
        resource={resources.destinations}
        onResourceClick={() => onResourceClick("numbers")}
      /> : <></>
    }
    {resources.numbers?.length ?
      <SelfManageResource
        heading={`מספרים בניהול עצמי (${resources.numbers?.length || ""})`}
        resource={resources.numbers}
        onResourceClick={() => onResourceClick("numbers")}
      /> : <></>
    }
    {resources.extensions?.length ?
      <SelfManageResource
        heading={`שלוחות בניהול עצמי (${resources.extensions?.length || ""})`}
        resource={resources.extensions}
        onResourceClick={() => onResourceClick("extensions")}
      /> : <></>
    }
    {resources.groups?.length ?
      <SelfManageResource
        heading={`קבוצות בניהול עצמי (${resources.groups?.length || ""})`}
        resource={resources.groups}
        onResourceClick={() => onResourceClick("groups")}
      /> : <></>
    }
  </div>
)

const SelfManageResource = (props: SelfManagedResourceProps) => {
  return (
    <div className={styles.resourceWrapper} onClick={props.onResourceClick}>
      <div className={styles.heading}>{props.heading}</div>
      <div>
        {props.resource?.map(resource => (
          <div key={resource.id} className={styles.resource}>
            <span>{resource.number}</span>
            <span>{resource.label}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

interface SelfManagedResourcesProps {
  resources: {
    numbers?: SelfManagedResource[],
    destinations?: SelfManagedResource[],
    extensions?: SelfManagedResource[],
    groups?: SelfManagedResource[]
  },
  onResourceClick: (resource: ConfigResourceDialogProps["activeConfig"]) => void
}

interface SelfManagedResourceProps {
  resource?: SelfManagedResource[],
  onResourceClick: () => void
  heading?: string
}

export interface SelfManagedResource {
  id: string,
  number?: string,
  label?: string
}
