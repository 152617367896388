import React from 'react';
import { Icon, IconProps } from './icon';

export const ArrowUpLeft = ({...props}: IconProps) => (
  <Icon 
    viewBox="0 0 96 96"
    fill="currentColor"
    {...props}
  >
    <polygon fillRule="evenodd" points="20 51.284 26 51.284 25.121 29.364 74.033 78.276 78.276 74.033 29.364 25.122 51.284 26 51.284 20 20 20"/>
  </Icon>
);


