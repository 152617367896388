import { useEffect, useState } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import { CloseIcon } from '../icons';
import { HorizontalPartition } from '../partition/horizontal-partition';
import { Button } from '../button';
import { Plus } from '../icons/plus';
import styles from './tags-editor.module.scss';
import classNames from 'classnames';
import { Search } from '../search/search';
import { ButtonWithDropdown } from '../button-with-dropdown/button-with-dropdown';

const DEFAULT_ADD_TAG_LABEL = 'הוספה';
const DEFAULT_SEARCH_LABEL = 'חיפוש';

/**
 *
 * @param tagClassName - Override ReactTags__tags css class to provide styles for tag components
 * @param buttonClassName
 * @param containerClassName
 * @param tagsContainerClassName
 * @param onTagAdded
 * @param onTagRemove
 * @param initiallySelectedTags
 * @param allTags
 * @param addTagLabel
 * @param searchTagLabel
 * @param buttonLabel
 * @param readOnly
 * @param allowInlineAddition
 */
export const TagsEditor = <T extends Tag = Tag>(props: TagsEditorProps<T>) => {
  const [tags, setTags] = useState<Tag[]>(props.initiallySelectedTags || []);
  const [editState, setEditState] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');

  const handleDelete = (i: number) => {
    if (props.onTagRemove) props.onTagRemove(tags[i].id);

    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag: Tag) => {
    const newTags = [...tags, tag];
    setTags(newTags);
  };

  const handleDrag = (tag: Tag, currPos: number, newPos: number) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const onClose = () => {
    setEditState(false);
    setQuery('');
  };

  useEffect(() => {
    if (props.initiallySelectedTags) setTags(props.initiallySelectedTags);
  }, [props.initiallySelectedTags]);

  return (
    <ButtonWithDropdown label={props.buttonLabel || ''} onClose={onClose} buttonClassName={props.buttonClassName}>
      {editState && !props.readOnly ? (
        <div className={classNames(styles.tagsContainer, props.containerClassName)}>
          <div style={{ width: '100%', marginInline: 'auto' }} className={styles.searchFieldWrapper}>
            <Search
              placeholder={props.searchTagLabel || DEFAULT_SEARCH_LABEL}
              onChange={(e) => setQuery(e.target.value)}
              onReset={() => setQuery('')}
              className={styles.searchField}
            />
          </div>
          <ul className={styles.list}>
            {
              /* Render all selected tags with a deletion icon */
              tags.map((tag, index) => (
                <li key={tag.id}>
                  {
                    <TagElement
                      id={tag.id}
                      text={tag.text}
                      className={props.tagClassName}
                      removeIcon={
                        <CloseIcon style={{ cursor: 'pointer' }} size={16} onClick={() => handleDelete(index)} />
                      }
                    />
                  }
                </li>
              ))
            }
          </ul>

          {tags.length ? <HorizontalPartition width={95} className={styles.partition} /> : undefined}
          <ul className={styles.list}>
            {
              /* Render all tags - excluding those which are selected already */
              props.allTags
                .filter((tag) => tags.every((t) => tag.id !== t.id))
                .filter((tag) => tag.text.includes(query))
                .sort((a, b) => (a.text < b.text ? -1 : 1))
                .map((tag, index) => (
                  <li key={tag.id}>
                    {
                      <TagElement
                        id={tag.id}
                        text={tag.text}
                        className={props.tagClassName}
                        onClick={() => handleAddition(tag)}
                      />
                    }
                  </li>
                ))
            }
          </ul>
        </div>
      ) : (
        <div className={classNames(styles.tagsContainer, props.containerClassName)}>
          <ReactTags
            readOnly
            tags={tags}
            inline
            inputProps={{
              disabled: props.readOnly || !props.allowInlineAddition,
              hidden: props.readOnly || !props.allowInlineAddition,
            }}
            placeholder={props.addTagLabel || DEFAULT_ADD_TAG_LABEL}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
            classNames={{
              tag: classNames(props.tagClassName, styles.ReactTags__tag),
              tags: classNames(props.tagsContainerClassName),
              selected: classNames(styles.ReactTags__selected),
            }}
            autocomplete
            maxLength={15}
            removeComponent={({ index }: any) => {
              return props.readOnly ? (
                <div />
              ) : (
                <CloseIcon
                  style={{ cursor: 'pointer' }}
                  size={16}
                  onClick={() => {
                    setTags([...tags].filter((tag, idx) => index !== idx));
                  }}
                />
              );
            }}
          />

          {!props.readOnly && <HorizontalPartition width={90} />}

          {!props?.readOnly && (
            <div className={styles.editTags}>
              <Button className={styles.plusButton} onClick={() => setEditState(!editState)}>
                <Plus width={12} height={12} />
              </Button>
              <Button variant='link' onClick={() => setEditState(!editState)}>
                {' '}
                {props.addTagLabel || DEFAULT_ADD_TAG_LABEL}{' '}
              </Button>
            </div>
          )}
        </div>
      )}
    </ButtonWithDropdown>
  );
};

interface TagsEditorProps<T> {
  buttonClassName?: string;
  containerClassName?: string;
  tagsContainerClassName?: string;
  tagClassName?: string;
  onTagAdded?: (id: string) => void;
  onTagRemove?: (id: string) => void;
  initiallySelectedTags?: T[];
  allTags: T[];
  addTagLabel?: string;
  searchTagLabel?: string;
  buttonLabel?: string;
  readOnly?: boolean;
  allowInlineAddition?: boolean;
}

interface Tag {
  id: string;
  text: string;
}

const TagElement = ({ id, text, removeIcon, className, onClick }: TagElementProps) => {
  return (
    <span id={id} className={classNames(styles.ReactTags__tag, className)} onClick={onClick}>
      {text} {removeIcon || undefined}
    </span>
  );
};

interface TagElementProps {
  id: string;
  text: string;
  removeIcon?: React.ReactNode;
  className?: string;
  onClick?: () => void;
}
