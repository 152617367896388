import { CallAttributes } from "../../monitor/interfaces";

// isCallInboundAbandoned
export function isCallInboundAbandoned(call: CallAttributes, validCallThreshold: number): boolean {
  if (call.callDirection !== "inbound") {
    return false;
  }

  let result: boolean;

  if (call.answerTime) {
    if (call.endTime) {
      result = (call.endTime.getTime() - call.answerTime.getTime()) < validCallThreshold * 1000;  // inbouמd & answered & ended: true/false by short/long talk
    } else {
      result = false; // inbound & answered & not ended: false
    }
  } else {
    result = !!call.endTime;  // inbound & not answered: true/false by ended/not ended
  }

  return result;
}

// isCallInboundAnswered
export function isCallInboundAnswered(call: CallAttributes, validCallThreshold: number): boolean {
  if (call.callDirection !== "inbound") {
    return false;
  }

  if (call.answerTime) {
    if (call.endTime) {
      return (call.endTime.getTime() - call.answerTime.getTime()) >= (validCallThreshold * 1000);  // inbound & answered & ended: true/false by long/short call
    } else {
      return true; // inbound & answered & not ended: true
    }
  } else {
    return false; // inbound & not answered: false
  }
}

// getOldestCallDate
export function getOldestCallDate(calls: CallAttributes[]): Date {
  return calls
    .map(call => call.startTime)
    .reduce((minStartTime, startTime) => startTime < minStartTime ? startTime : minStartTime);
}

// getYoungestCallDate
export function getYoungestCallDate(calls: CallAttributes[]): Date {
  return calls
    .map(call => call.startTime)
    .reduce((maxStartTime, startTime) => startTime > maxStartTime ? startTime : maxStartTime);
}
