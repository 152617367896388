import { Auth } from 'aws-amplify';
import { Form, Formik } from 'formik';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { useState } from 'react';
import * as yup from 'yup';
import { Button } from '../../design/button/button';
import { Field } from '../../design/form/field/field';
import { Input } from '../../design/form/input/input';
import { GeneralError } from '../../design/general-error/general-error';
import errorDictionary from '../error-dictionary';
import styles from './confirm-email-form.module.scss';
import { CustomerRegistrationFormValues } from './customer-registration-form';

const confirmationSchema = yup.object().shape({
  code: yup.string()
    .length(6, 'על קוד האימות להיות באורך 6 ספרות')
    .required('שכחת למלא את הקוד'),
});

export function ConfirmEmailForm({ user }: ConfirmEmailFormProps) {
  const [loading, setLoading] = useState<boolean>();
  const [loadingResend, setLoadingResend] = useState<boolean>();
  const [error, setError] = useState<string>();

  const handleConfirm = async (values: any) => {
    try {
      setLoading(true);
      await Auth.confirmSignUp(user.username, values.code);
      const now = moment().toISOString();
      mixpanel.people.set_once({
        '$created': now,
        'Activated At': now
      });
      mixpanel.people.set("Preffered Gender", user.gender);
      mixpanel.identify();
      await Auth.signIn(user.username, user.password);
    } catch (error: any) {
      setError(error.code);
    } finally {
      setLoading(false);
    }
  }

  const handleResendSignup = async (username: string) => {
    try {
      setLoadingResend(true);
      await Auth.resendSignUp(username);
      alert('שלחנו לך את קוד האימות למייל')
    } catch (e) {
      // console.error(e);
      alert('לא הצלחנו לשלוח לך את קוד האימות. אפשר ליצור קשר עם התמיכה');
    } finally {
      setLoadingResend(false);
    }
  }

  return (
    <Formik
      initialValues={{
        code: ''
      }}
      validationSchema={confirmationSchema}
      onSubmit={handleConfirm}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <Form autoComplete="off">
        <header className={styles.header}>
          <h3 className={styles.title}>הצטרפות לתוכנית ה־Beta</h3>
          <p className={styles.description}>
            שלחנו קוד אימות באורך 6 ספרות למייל שלך. לא קיבלת? <Button
              type="button"
              variant="link"
              loading={loadingResend}
              onClick={() => handleResendSignup(user.username)}
            >
              לשלוח את הקוד פעם נוספת
            </Button>
          </p>
        </header>
        <Field name="code" label="קוד אימות 6 ספרות">
          <Input type="text" autoComplete="off" />
        </Field>
        {error && <GeneralError error={errorDictionary[error]} />}
        <div className={styles.actions}>
          <Button
            variant="primary"
            type="submit"
            loading={loading}
          >
            הצטרפות
          </Button>
        </div>
      </Form>
    </Formik>
  )
}

export interface ConfirmEmailFormProps {
  user: CustomerRegistrationFormValues
}