import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import { News } from '../news/news';
import useMoment from './use-moment';
import { AuthRoot } from './auth-root';
import Theme from '../theme/theme';
import { AnalyticsRoot } from './analytics';
import './integration/amplify';
import './integration/mixpanel';
import './app.scss';
import { Router } from './router';

function App() {
  useMoment();
  return (
    <RecoilRoot>
      <BrowserRouter>
        <AuthRoot>
          <AnalyticsRoot>
            <Theme>
              <Router />
              <News />
            </Theme>
          </AnalyticsRoot>
        </AuthRoot>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
