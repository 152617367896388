import { useState, useEffect, useCallback } from 'react';
import { Search } from '../search/search';
import styles from './table.module.scss';
import { Column, ColumnSortConfig, SearchLocation, SortOrder } from './types';
import { getNewSortConfig } from './utils/getNewSortConfig';
import { useSortColumn } from './hooks/useSortColumn';
import { TableHeader } from './tableHeader/TableHeader';
import { TableRows } from './tableRows/TableRows';
import { TEXTS } from './constants/texts';
import { usePersistentStorageValue } from '../../hooks/useLocalStorage';
import { JobTitle } from '../../api/dialers';

const INITIAL_COLUMN_SORT_CONFIG: ColumnSortConfig = {
  columnName: '',
  sortOrder: SortOrder.NotSorted,
};

interface TableProps<T, K extends keyof T> {
  data?: T[];
  columns: Column<T, K>[];
  showHeaders?: boolean;
  sort?: boolean;
  search?: boolean;
  searchPlaceholder?: string;
  searchLocation?: SearchLocation;
  checkboxes?: boolean;
  onCheckChange?: (id: string) => void;
  onMasterCheck?: () => void;
  checkedSet?: Set<string>;
  children?: React.ReactNode;
  tableName?: string | undefined;
}

export const Table = <T extends { id: string }, K extends keyof T>({
  showHeaders = true,
  sort = true,
  ...props
}: TableProps<T, K>) => {
  const [data, setData] = useState(props.data);

  const { value: columnSortConfig, setValue: setColumnSortConfig } = usePersistentStorageValue<ColumnSortConfig>(
    props?.tableName,
    INITIAL_COLUMN_SORT_CONFIG
  );
  const compareFunction = useSortColumn();

  const handleUpdateData = useCallback(
    (propsData: T[]) => {
      const sortBy = columnSortConfig?.columnName as keyof T;
      if (!sortBy || !sort) {
        setData(propsData);
      } else if (columnSortConfig?.sortOrder !== SortOrder.NotSorted) {
        const columnToSort = props.columns.find((column) => column.key === sortBy);
        const sortCallback = compareFunction(sortBy, columnToSort?.sort);
        const dataCopy = [...propsData];
        const sortedData = dataCopy.sort((a, b) =>
          columnSortConfig?.sortOrder === SortOrder.Ascending ? sortCallback(a, b) : -sortCallback(a, b)
        );
        setData([...sortedData]);
      } else {
        // Rearrange data to its original order
        setData(props.data);
      }
    },
    [props.columns, compareFunction, columnSortConfig]
  );

  useEffect(() => {
    handleUpdateData(props.data || []);
  }, [props.data, handleUpdateData]);

  const handleHeaderClick = (column: Column<T, K>) => {
    if (!sort) return;
    if (column.key === 'checkbox') return;
    if (!columnSortConfig) return;

    const newSortConfig = getNewSortConfig(column.key as string, columnSortConfig);
    setColumnSortConfig(newSortConfig);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawSearchValue = event.target.value;
    const searchValue = rawSearchValue.toLocaleLowerCase().replaceAll('-', '');
    const rawSearchSpace = props?.data?.map((val) => {
      return { ...val };
    }) as Record<string, any>[];

    rawSearchSpace?.forEach((obj) => {
      const entries = Object.entries(obj);
      entries.forEach(([key, value]) => {
        if (Array.isArray(value)) {
          obj[key] = value.join(',');
        }
      });
    });

    const newData = props?.data?.filter((obj, index) =>
      Object.values(rawSearchSpace[index] as Object).some(
        (val) => typeof val === 'string' && val.toLocaleLowerCase().replaceAll('-', '').includes(searchValue)
      )
    );
    setData(newData);
  };

  return (
    <div className={styles.container}>
      {props.search && (
        <div
          className={props.searchLocation === SearchLocation.RIGHT ? styles.searchWrapperRight : styles.searchWrapper}
        >
          <Search
            onChange={handleSearch}
            placeholder={props.searchPlaceholder ?? TEXTS.SEARCH_PLACEHOLDER}
            className={styles.searchBar}
          />
        </div>
      )}
      {props.children && <div>{props.children}</div>}
      <table>
        {showHeaders && (
          <TableHeader
            columns={props.columns}
            onClick={handleHeaderClick}
            sortConfig={columnSortConfig || INITIAL_COLUMN_SORT_CONFIG}
            onMasterCheck={props.onMasterCheck}
            onCheck={props.onCheckChange}
            checked={props.checkedSet}
            checkboxes={props.checkboxes}
          />
        )}
        <TableRows
          data={data}
          columns={props.columns}
          onMasterCheck={props.onMasterCheck}
          onCheck={props.onCheckChange}
          checked={props.checkedSet}
          checkboxes={props.checkboxes}
        />
      </table>
    </div>
  );
};
