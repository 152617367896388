import { Select, styled } from '@mui/material';

export const StyledCustomSelect = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  rowGap: theme.spacing(2.5),
}));

const selectProps: PropertyKey[] = ['hasPermission'];
export const StyledSelect = styled(Select, {
  shouldForwardProp: (prop) => !selectProps.includes(prop),
})(({ theme }) => ({
  '&.full-width': {
    width: '100%',
  },

  '&.MuiInputBase-root': {
    height: theme.fieldHeights.main,
    fontSize: theme.fontSizes.large,
    fontWeight: theme.fontWeights.regular,
    color: theme.palette.secondary.main,
    borderRadius: theme.borderRadius.default,

    '.MuiSelect-select': {
      padding: `${theme.spacing(1.5)} ${theme.spacing(4)} !important`,
    },
    '& .MuiSelect-nativeInput': {
      height: theme.fieldHeights.main,
    },
  },

  '& .MuiSelect-icon.MuiSvgIcon-root': {
    right: 'auto',
  },

  '& .MuiSelect-icon,.MuiSelect-iconOpen': {
    left: '8px',
  },

  '&.PlainTextSelect': {
    '&.MuiOutlinedInput-root': {
      marginLeft: theme.spacing(8),

      '.MuiSelect-select': {
        padding: '0 !important',
      },
      '& fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        border: 'none',
      },
      '&.Mui-focused fieldset': {
        border: 'none',
      },
    },
    '&.MuiSelect-select': {
      padding: 0,
    },
  },
}));
