import { memo } from 'react';

import { ApiConfig } from '../../globalTypes/types';
import { StyledFormPermissions } from './StyledCallbackDialerSettings';
import { useFormFieldRenderer } from '../../hooks/useFormFieldRenderer';
import { useCallbackFieldsConfig } from '../hooks/useCallbackFieldsConfig';

interface FormPermissionsProps {
  apiConfig: ApiConfig;
}

export const FormPermissions = memo(({ apiConfig }: FormPermissionsProps) => {
  const { selectedPermission } = useCallbackFieldsConfig();
  const renderFormField = useFormFieldRenderer(apiConfig);

  return <StyledFormPermissions>{renderFormField(selectedPermission)}</StyledFormPermissions>;
});

FormPermissions.displayName = 'FormPermissions';
