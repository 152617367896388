import classNames from "classnames";
import { NetworkObject, NetworkObjectStatus } from "../api/types";
import { useMe } from "../api/use-me";
import { Spinner } from "../design/spinner/spinner";
import { Table } from "../design/table/table";
import { Column } from "../design/table/types";
import { AppLayout, AppLayoutContainer, AppLayoutHeader, AppLayoutTitle } from "../layouts/app-layout/app-layout";
import { Page } from "../page";
import { getDigitTimeFromMillis } from "../utils/time/time";
import styles from './network-monitor.module.scss';
import { useNetworkData } from "./use-network-data";
import { useEffect, useState } from "react";
import { Button } from "../design/button";

export default function NetworkMonitor() {
  const { data: user } = useMe();
  const { data, loading } = useNetworkData(user?.domain.id);
  const [currentTime, setCurrentTime] = useState<number>(Date.now());
  useEffect(() => {
    const intervalId: NodeJS.Timer = setInterval(() => setCurrentTime(Date.now()), 1000);
    return () => clearInterval(intervalId);
  }, []);
  const isAdmin = false;
  if (!user || !data || loading) {
    return (
      <Page name='Network Monitor'>
      <AppLayout>
        <AppLayoutContainer>
          <AppLayoutHeader>
            <AppLayoutTitle>ניטור רשת</AppLayoutTitle>
          </AppLayoutHeader>
          <Spinner />
        </AppLayoutContainer>
      </AppLayout>
    </Page>
    )
  }

  type NetworkObj = NetworkObject & { menu: JSX.Element };
  const switchesColumns: Array<Column<NetworkObj, keyof NetworkObj>> = [
    {
      key: 'status',
      header: '',
      className: styles.status,
      render(row) {
        return <div> <TranslatedStatus status={row.status} />  </div>
      }
    },
    {
      key: 'statusDate',
      header: 'זמן בסטטוס',
      className: styles.statusDate,
      render(row) {
        return <div>{getDigitTimeFromMillis(currentTime - new Date(row.statusDate).getTime(), 'HH:MM:SS')}</div> 
      }
    },
    {
      key: 'name',
      header: 'שם מתג',
      className: styles.name,
    },
    {
      key: 'ipAddress',
      header: 'כתובת',
      className: styles.ipAddress,
    },
    {
      key: 'macAddress',
      header: 'כתובת MAC',
      className: styles.macAddress,
      render(row) {
        return (
          <div>{row.macAddress.toUpperCase()}</div>
        )
      }
    },
    {
      key: 'portsNumber',
      header: 'מספר פורטים',
      className: styles.portsNumber,
    },
    {
      key: 'location',
      header: 'מיקום התקנה',
      className: styles.location,
    },
    {
      key: 'menu',
      header: '',
      className: styles.menu
    }
  ];
  
  const accessPointsColumns: Array<Column<NetworkObj, keyof NetworkObj>> = [
    {
      key: 'status',
      header: '',
      className: styles.status,
      render(row) {
        return <div> <TranslatedStatus status={row.status} />  </div>
      }
    },
    {
      key: 'statusDate',
      header: 'זמן בסטטוס',
      className: styles.statusDate,
      render(row) {
        return <div>{getDigitTimeFromMillis(currentTime - new Date(row.statusDate).getTime(), 'HH:MM:SS')}</div> 
      }
    },
    {
      key: 'name',
      header: 'שם יחידה',
      className: styles.name,
    },
    {
      key: 'ipAddress',
      header: 'כתובת',
      className: styles.ipAddress,
    },
    {
      key: 'macAddress',
      header: 'כתובת MAC',
      className: styles.macAddress,
      render(row) {
        return (
          <div>{row.macAddress.toUpperCase()}</div>
        )
      }
    },
    {
      key: 'location',
      header: 'מיקום התקנה',
      className: styles.location,
    },
    {
      key: 'menu',
      header: '',
      className: styles.menu
    }
  ];
  
  const othersColumns: Array<Column<NetworkObj, keyof NetworkObj>> = [
    {
      key: 'status',
      header: '',
      className: styles.status,
      render(row) {
        return <div> <TranslatedStatus status={row.status} />  </div>
      }
    },
    {
      key: 'statusDate',
      header: 'זמן בסטטוס',
      className: styles.statusDate,
      render(row) {
        return <div>{getDigitTimeFromMillis(currentTime - new Date(row.statusDate).getTime(), 'HH:MM:SS')}</div> 
      }
    },
    {
      key: 'name',
      header: 'שם מכשיר',
      className: styles.name,
    },
    {
      key: 'component',
      header: 'סוג מכשיר',
      className: styles.type
    },
    {
      key: 'ipAddress',
      header: 'כתובת',
      className: styles.ipAddress,
    },
    {
      key: 'macAddress',
      header: 'כתובת MAC',
      className: styles.macAddress,
      render(row) {
        return (
          <div>{row.macAddress.toUpperCase()}</div>
        )
      }
    },
    {
      key: 'location',
      header: 'מיקום התקנה',
      className: styles.location,
    },
    {
      key: 'menu',
      header: '',
      className: styles.menu
    }
  ];

  const switches = data.filter(obj => obj.type === 'Switch').map(obj => ({...obj, menu: isAdmin ? <div>...</div> : <div></div>}));
  const accessPoints = data.filter(obj => obj.type === 'AccessPoint').map(obj => ({...obj, menu: isAdmin ? <div>...</div> : <div></div>}));
  const others = data.filter(obj => obj.type === 'Other').map(obj => ({...obj, menu: isAdmin ? <div>...</div> : <div></div>}));

  return (
    <Page name='Network Monitor'>
      <AppLayout>
        <AppLayoutContainer>
          <AppLayoutHeader className={styles.pageHeader}>
            
            <AppLayoutTitle>{TEXTS.PAGE_TITLE}</AppLayoutTitle>
            
            {
              isAdmin && (
                <Button
                  variant="primary"
                  className={styles.addNewMonitorGroupBtn}
                >
                  יצירת קבוצת ניטור
                </Button>
              )
            }

          </AppLayoutHeader>

          <AdslSection data={data} />

          <section className={styles.sectionsContainer}>
            <Section title={TEXTS.SWITCHES}>
              <Table data={switches} columns={switchesColumns} />
            </Section>

            <Section title={TEXTS.ACCESS_POINTS}>
              <Table data={accessPoints} columns={accessPointsColumns} />
            </Section>

            <Section title={TEXTS.OTHERS}>
              <Table data={others} columns={othersColumns} />
            </Section>
          </section>
          
        </AppLayoutContainer>
      </AppLayout>
    </Page>
  )
}

interface AdslSectionProps {
  data: NetworkObject[];
}
const AdslSection: React.FC<AdslSectionProps> = ({ data }) => {
  const routerNicObjcets = data.filter(item => item.type === 'RouterNIC');
  const [currentTime, setCurrentTime] = useState<Date>(new Date());

  useEffect(() => {
    const intervalId: NodeJS.Timer = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <section className={styles.adslSection}>
      {
        routerNicObjcets.map(nic => {
          const isActive = nic.status === 'Active';
          const translatedStatus = isActive ? 'פעיל' : 'לא פעיל';
          const timeInMillis = currentTime.getTime() - new Date(nic.statusDate).getTime();
          return (
            <button key={nic.id} className={classNames(styles.adslEntry, {[styles.active]: isActive})}>
              <div className={styles.content}>
                <div className={styles.title}>{nic.name}</div>
                {nic.status && <div className={styles.subtitle}>{translatedStatus}</div>}
              </div>
              <div className={styles.timeAndIP}>
                <span>
                  { getDigitTimeFromMillis(timeInMillis, 'HH:MM:SS').substring(0, 5) }
                </span>
                <span> { nic.ipAddress } </span>
              </div>
            </button>
          )
        })
      }
    </section>
  )
}

interface SectionProps {
  title: string;
  children: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({ title, children }) => {
  return (
    <section className={styles.switchSection}>
      <span className={styles.sectionHeader}> {title} </span>
      { children }
    </section>
  )
}

const TranslatedStatus: React.FC<{ status: NetworkObjectStatus }> = ({ status }) => {
  const isActive = status === 'Active';
  const translatedText = isActive ? 'פעיל' : 'לא פעיל';
  return (
    <div className={classNames(styles.statusTag, { [styles.active]: isActive })}>
      {translatedText}
    </div>
  )
}

const TEXTS = {
  PAGE_TITLE: 'ניטור רשת',
  SWITCHES: 'מתגים',
  ACCESS_POINTS: 'נקודות גישה אלחוטיות',
  OTHERS: 'מכשירים נוספים'
}