import { DialogStateReturn } from "reakit";
import { Dialog } from "../../design/dialog/dialog";
import styles from "./dialer-dialog.module.scss";
import { API } from "aws-amplify";
import { useMe } from "../../api/use-me";
import { useState } from "react";
import { dialogTexts } from "../utils/texts";
import { ErrorDialog } from "../../design/dialog/error-dialog/error-dialog";

interface ActionDialogProps {
  dialog: DialogStateReturn;
  dialerId: number;
  dialerName: string;
  mutateGroups: () => void;
}

export default function DeleteGroupDialog({
  dialog,
  dialerId,
  dialerName,
  mutateGroups,
}: ActionDialogProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const { loading: userLoading } = useMe();
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  if (error) {
    return (
      <ErrorDialog
        mainMessage={errorMessage}
        showSecondaryMessage={false}
        dialog={dialog}
        onClose={() => setError(false)}
      />
    );
  }

  const handleError = (err: any): void => {
    console.error(err?.response?.data);
    setError(true);
    const { data, error } = err.response.data;
    if (!data || !error) {
      setErrorMessage(undefined);
      return;
    }
    if (error?.code === 7 && data.includes('has 1 or more')) {
      setErrorMessage(DIAL_GROUP_RELATED_ENTITIES);
      return;
    }
    // Default
    setErrorMessage(undefined);
  }

  const {
    DELETE_GROUP_TITLE,
    DELETE_GROUP_MESSAGE_1,
    DELETE_GROUP_MESSAGE_2,
    DELETE_GROUP_BUTTON_TEXT,
    DELETE_GROUP_CANCEL_BUTTON_TEXT,
    DIAL_GROUP_RELATED_ENTITIES
  } = dialogTexts;

  const handleClose = () => {
    dialog.hide();
  };

  const handleDeleteGroup = async () => {
    try {
      setLoading(true);
      await API.del("emergencyDialerApi", `/emergency-dialer?dialer_id=${dialerId || ""}`, {
        body: {},
      });
      await new Promise((resolve) => setTimeout(() => resolve(mutateGroups()), 1500));
      handleClose();
    } catch (err: any) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      {...dialog}
      variant='caution'
      loading={userLoading || loading}
      header={DELETE_GROUP_TITLE}
      submitLabel={DELETE_GROUP_BUTTON_TEXT}
      cancelLabel={DELETE_GROUP_CANCEL_BUTTON_TEXT}
      hideOnClickOutside={false}
      onSubmit={handleDeleteGroup}
      onClose={handleClose}
      aria-label={DELETE_GROUP_TITLE}
    >
      <section>
        <span>
          {DELETE_GROUP_MESSAGE_1} <span className={styles.strong}>{dialerName}</span>{" "}
          {DELETE_GROUP_MESSAGE_2}
        </span>
      </section>
    </Dialog>
  );
}
