import { useMemo } from 'react';

import { useData } from '../../hooks/useData';
import { CallbackDialer } from '../../api/dialers';
import { DEFAULT_CALLBACK_DIALER_ID } from '../utils/constants';

export const useCallbackDialers = () => {
  const endpointURL = '/callback-dialer';
  const apiName = process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!;

  const { data, error, mutate, isLoading, apiDataHandler } = useData({
    apiName,
    endpointURL,
  });

  let defaultDialerData: CallbackDialer | undefined;
  let dialerData: CallbackDialer[] | undefined;

  if (data) {
    dialerData = data.filter((dialer: CallbackDialer) => dialer.dialer_id !== DEFAULT_CALLBACK_DIALER_ID);
    defaultDialerData = data.find((dialer: CallbackDialer) => dialer.dialer_id === DEFAULT_CALLBACK_DIALER_ID);
  }

  const apiConfig = useMemo(
    () => ({
      apiName,
      endpointURL,
    }),
    [apiName, endpointURL]
  );

  return {
    error,
    isLoading,
    apiConfig,
    dialerData,
    defaultDialerData,
    mutate,
    apiDataHandler,
  };
};
