import { memo } from 'react';
import { useWatch } from 'react-hook-form';
import { Typography } from '@mui/material';

import { FormSection } from '../types';
import { REPORT_SETTINGS_TEXTS } from '../utils/texts';
import { TypographyVariant } from '../../theme/muiTypes';
import { useFormFieldRenderer } from '../../hooks/useFormFieldRenderer';
import { CustomCard } from '../../components/common/customCard/CustomCard';
import { useCallbackFieldsConfig } from '../hooks/useCallbackFieldsConfig';
import { CustomDivider } from '../../components/common/customDivider/CustomDivider';
import { StyledCardsContainer, StyledFormContainer } from './StyledCallbackDialerSettings';

const { REPORT_TITLE, REPORT_SUB_TITLE } = REPORT_SETTINGS_TEXTS;

export const ReportSettings = memo(({ section, apiConfig }: FormSection) => {
  const {
    report_type,
    sms_report_text,
    mail_report_text,
    mail_report_title,
    sms_report_sender_name,
    mail_report_sender_name,
    sms_report_destination_numbers,
    mail_report_destination_addresses,
  } = useCallbackFieldsConfig();
  const renderFormField = useFormFieldRenderer(apiConfig);
  const reportTypeValue = useWatch({ name: report_type.name });

  const smsReport = (
    <>
      {renderFormField(sms_report_sender_name)}
      {renderFormField(sms_report_destination_numbers)}
      {renderFormField(sms_report_text)}
    </>
  );

  const mailReport = (
    <>
      {renderFormField(mail_report_sender_name)}
      {renderFormField(mail_report_destination_addresses)}
      {renderFormField(mail_report_title)}
      {renderFormField(mail_report_text)}
    </>
  );

  const smsMailReport = (
    <>
      {renderFormField(sms_report_sender_name)}
      {renderFormField(sms_report_destination_numbers)}
      {renderFormField(sms_report_text)}
      {renderFormField(mail_report_sender_name)}
      {renderFormField(mail_report_destination_addresses)}
      {renderFormField(mail_report_title)}
      {renderFormField(mail_report_text)}
    </>
  );

  return (
    <StyledFormContainer id={section.id}>
      <Typography variant={TypographyVariant.h1}>{REPORT_TITLE}</Typography>
      <Typography variant={TypographyVariant.regularText2}>{REPORT_SUB_TITLE}</Typography>
      <StyledCardsContainer className='general-settings'>
        <CustomCard className='column'>
          {renderFormField(report_type)}
          <CustomDivider hidden={!report_type.uiConfig.hasPermission} />
          {reportTypeValue === 'sms' && smsReport}
          {reportTypeValue === 'mail' && mailReport}
          {reportTypeValue === 'sms_mail' && smsMailReport}
          {!reportTypeValue && null}
        </CustomCard>
      </StyledCardsContainer>
    </StyledFormContainer>
  );
});

ReportSettings.displayName = 'ReportSettings';
