import { createElement, ElementType, FC, MouseEvent, ReactElement } from 'react';
import { Avatar } from '@mui/material';

import { ChipSize, ChipVariant, Color, FieldVariant } from '../../../theme/muiTypes';
import { StyledChip } from './StyledCustomChip';
import { SelectOption } from '../../../formConfig/types';

export interface CustomChipProps {
  value: SelectOption;
  size?: ChipSize;
  color?: Color;
  variant?: ChipVariant;
  fieldVariant?: FieldVariant;
  avatarLabel?: string;
  icon?: ElementType;
  avatar?: ElementType;
  onClick?: (event: MouseEvent<Element>, item: SelectOption) => void;
  onDelete?: (value: SelectOption) => void;
}

export const CustomChip: FC<CustomChipProps> = ({
  icon,
  avatar,
  value,
  avatarLabel,
  color = Color.primary,
  size = ChipSize.medium,
  variant = ChipVariant.Filled,
  fieldVariant = FieldVariant.Inline,
  onDelete,
  onClick,
  ...rest
}) => {
  const { label } = value;
  const IconElement = icon ? createElement(icon) : null;
  const AvatarElement = avatar ? createElement(avatar) : null;
  const stringAvatar = !AvatarElement && avatarLabel ? avatarLabel : null;
  const isCustomDeleteChip = Boolean(onDelete && IconElement);
  const isDefaultDeleteChip = Boolean(onDelete && !IconElement);
  const isIconChip = Boolean(!onDelete && IconElement);
  const isAvatarImageChip = Boolean(AvatarElement);
  const isAvatarStringChip = Boolean(!AvatarElement && avatarLabel);

  const handleDelete = () => {
    if (onDelete) {
      onDelete(value);
    }
  };

  const handleClick = (event: MouseEvent) => {
    if (onClick) {
      onClick(event, value);
    }
  };

  const props = { color, variant, size, ...rest };

  let chipElement;

  const renderChip = () => {
    if (isCustomDeleteChip) {
      chipElement = (
        <StyledChip label={label} deleteIcon={IconElement as ReactElement} onDelete={handleDelete} {...props} />
      );
    } else if (isDefaultDeleteChip) {
      chipElement = <StyledChip onClick={handleClick} label={label} onDelete={handleDelete} {...props} />;
    } else if (isIconChip) {
      chipElement = <StyledChip onClick={handleClick} icon={IconElement as ReactElement} label={label} {...props} />;
    } else if (isAvatarImageChip) {
      chipElement = (
        <StyledChip onClick={handleClick} avatar={<Avatar>{AvatarElement}</Avatar>} label={label} {...props} />
      );
    } else if (isAvatarStringChip) {
      chipElement = (
        <StyledChip onClick={handleClick} avatar={<Avatar>{stringAvatar}</Avatar>} label={label} {...props} />
      );
    } else {
      chipElement = <StyledChip label={label} onClick={handleClick} {...props} />;
    }

    return chipElement;
  };

  return renderChip();
};
