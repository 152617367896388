import { Column } from "../types";
import styles from "../table.module.scss";
import { Checkbox } from "../../checkbox/checkbox";
import { ReactElement, ReactNode } from "react";

interface TableRowProps<T, K extends keyof T> {
  data: T[] | undefined;
  columns: Column<T, K>[];
  checked?: Set<string>;
  onCheck?: (id: string) => void;
  onMasterCheck?: () => void;
  checkboxes?: boolean;
}

export const TableRows = <T extends { id: string }, K extends keyof T>({
  checkboxes,
  checked,
  onMasterCheck,
  onCheck,
  data,
  columns,
}: TableRowProps<T, K>) => {
  const showCheckbox = Boolean(checkboxes && onMasterCheck && onCheck && checked);

  const renderField = <T extends { id: string }, K extends keyof T>(
    rowData: T,
    column: Column<T, K>
  ): ReactNode => {
    if (column.render) {
      return column.render(rowData, column.key);
    }
    return rowData[column.key] as any as ReactElement;
  };

  const rows = data?.map((entry) => (
    <tr key={entry.id}>
      {showCheckbox && (
        <td key={`${entry.id}-checkbox`} className={styles.checkbox}>
          <Checkbox checked={checked?.has(entry.id)} onChange={() => onCheck?.(entry.id)} />
        </td>
      )}
      {columns.map((column) => (
        <td className={column.className} key={`${entry.id}-${String(column.key)}`}>
          {renderField(entry, column)}
        </td>
      ))}
    </tr>
  ));

  return rows ? <tbody>{rows}</tbody> : <></>;
};
