import { InputHTMLAttributes, useRef, useEffect } from 'react';
import styles from './checkbox.module.scss';

/**
 * @param props extends InputHTMLAttributes<HTMLInputElement>
 * Supports intermediate state. State can be passed with the "state" prop.
 * @returns  JSX.Element
 */
export const Checkbox = (props: CheckboxProps) => {
  const checkboxRef = useRef<HTMLInputElement>(null!);
  
  useEffect(() => {
    if (!props.state) {
      checkboxRef.current.checked = props.checked || false;
      return;
    }

    if (props.state === "checked") {
      checkboxRef.current.checked = true;
      checkboxRef.current.indeterminate = false;
      return;
    }
    
    if (props.state === "intermediate") {
      checkboxRef.current.checked = false;
      checkboxRef.current.indeterminate = true;
      return;
    }

    checkboxRef.current.checked = false;
    checkboxRef.current.indeterminate = false;
    
  }, [props.state])

  return (
  <label className={styles.checkbox}>
    <input type="checkbox" ref={checkboxRef} {...props} />
    <span className={styles.box} />
    <span className={styles.label} title={props.title}>{props.title}</span>
  </label>
)}

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  state?: "checked" | "empty" | "intermediate"
}