import { parsePhoneNumber } from 'libphonenumber-js';
import React from 'react';
import { BusinessNumberResource } from '../../../api/types';
import { CallflowNode, CallflowNodeContent, CallflowNodeTitle } from '../callflow-node/callflow-node';
import { safeParsePhoneNumber } from '../../../utils/strings/parse-phone';

export function CallForwardingCallflow({ number }: CallForwardingCallflowProps) {
  const displayNumber = safeParsePhoneNumber(number.forwardIncomingCallsTo || '', 'IL');

  return (
    <CallflowNode>
      <CallflowNodeContent>
        <CallflowNodeTitle>העברת שיחות</CallflowNodeTitle>
        <div>כל השיחות עוברות למספר</div>
        <span>{displayNumber}</span>
      </CallflowNodeContent>
    </CallflowNode>
  );
}

export interface CallForwardingCallflowProps {
  number: BusinessNumberResource;
}