import { API } from 'aws-amplify';
import useSWR from 'swr';
import { RedirectableCall } from './types';

const SWR_CONFIG = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};


export const useRedirectCalls = (domainUuid: string | undefined) => {
  const getKey = () => domainUuid ? `/domain/${domainUuid}/objects?types=Extension,IVR,RingGroup,CallCenterQueue` : null;

  const { data } = useSWR(getKey, redirectCallsFetcher, SWR_CONFIG);

  return { data };
};

export const redirectCallsFetcher = async (path: string): Promise<RedirectableCall[]> => await API.get(process.env.REACT_APP_WEB_API_NAME!, path, {});
