import classNames from "classnames";
import { Field } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useDebounce from "react-use/lib/useDebounce";
import { exportCallsSummaryReport } from "../api/export-report";
import { ExtensionResource, GroupResource } from "../api/types";
import { useAllExtensions } from "../api/use-extensions";
import { useAllGroups } from "../api/use-groups";
import { useMe } from "../api/use-me";
import { User, useUsers } from "../api/use-users";
import { useURLParams } from "../app/use-url-params";
import { Button } from "../design/button";
import { Card } from "../design/card/card";
import { Checkbox } from "../design/checkbox/checkbox";
import { DateRange } from "../design/date-range/date-range";
import { Filter } from "../design/filter/filter";
import { Input } from "../design/form/input/input";
import { Select } from "../design/form/select";
import { OptionsHorizontal, Reset } from "../design/icons";
import ResizeIcon from '../design/icons/resize.png';
import ShrinkIcon from '../design/icons/shrink.png';
import { MultiSelect } from "../design/multi-select/multi-select";
import { Search } from "../design/search";
import {
  SegmentedControl,
  SegmentedControlOption,
} from "../design/segmented-control/segmented-control";
import { Spinner } from "../design/spinner/spinner";
import {
  AppLayout,
  AppLayoutHeader,
  AppLayoutTitle,
  useMediaQuery,
} from "../layouts/app-layout/app-layout";
import { Page } from "../page";
import { mobileQuery } from "../theme/media";
import { COLORS } from "./constants";
import { GroupedCallsAreaChart } from "./grouped-calls-graph";
import { GroupedCallsTable } from "./grouped-calls-table";
import styles from "./report.module.scss";
import { useGroupedCalls } from "./use-grouped-calls";
import { emptyCallsGroup } from "./util";

const DEFAULT_NUM_OF_MONTHS = 2;
const filterKeys = [
  "answerRate",
  "sla",
  "minTta",
  "maxTta",
  "minDuration",
  "maxDuration",
  "businessNumbersPerDomain",
  "extensionsPerDomain",
  "groupsPerDomain",
  "domains",
  "membersPerDomain",
];
const EXPORT = "ייצוא לאקסל";

const getSelfManagedExtensions = (
  groupedData?: Record<string, ExtensionResource[]>,
  multiTenantUser?: User
): Record<string, ExtensionResource[]> => {
  if (!groupedData || !multiTenantUser || Object.keys(groupedData).length === 0) {
    return {};
  }
  const domainsSelfManagedExtensions = Object.fromEntries(
    multiTenantUser.domains.map((domain) => [
      domain.domain_uuid,
      domain.domain_self_managed_extensions || [],
    ])
  );
  const entries = Object.entries(groupedData);
  const filteredEntries = entries.map(([domain, extensions]) => [
    domain,
    extensions.filter((extension) => domainsSelfManagedExtensions[domain].includes(extension.id)),
  ]);
  return Object.fromEntries(filteredEntries);
};

const getSelfManagedGroups = (
  groupedData?: Record<string, GroupResource[]>,
  multiTenantUser?: User
): Record<string, GroupResource[]> => {
  if (!groupedData || !multiTenantUser || Object.keys(groupedData).length === 0) {
    return {};
  }
  const domainsSelfManagedGroups = Object.fromEntries(
    multiTenantUser.domains.map((domain) => [
      domain.domain_uuid,
      domain.domain_self_managed_groups || [],
    ])
  );
  const entries = Object.entries(groupedData);
  const filteredEntries = entries.map(([domain, groups]) => [
    domain,
    groups.filter((group) => domainsSelfManagedGroups[domain].includes(group.id)),
  ]);
  return Object.fromEntries(filteredEntries);
};

export const CallsHistoryReport = () => {
  const { data: user, loading: loadingUser } = useMe();
  const currentDomainUuid = user?.domain.id;
  const isMobile = useMediaQuery(mobileQuery);
  const { pathname } = useLocation();
  const isExact = pathname === "/calls-summary";
  const { users: multiTenantUsers, loading: loadingMultiTenant } = useUsers(user?.id);
  const multiTenantUser = multiTenantUsers?.[0];
  const userDomains = multiTenantUser?.domains;
  const params = useParams<any>()["*"]?.replace(/^\/|\/$/g, "");
  const url = useURLParams({ path: "/calls-summary", params });
  const [searchString, setSearchString] = useState<string | undefined>(url.urlParams.phone);
  const [debouncedSearchString, setDebouncedSearchString] =
    useState<string | undefined>(searchString);
  const [focusedInput, setFocusedInput] = useState<"startDate" | "endDate" | null>(null);
  const [dateRange, setDateRange] = useState({ from: url.urlParams.from, to: url.urlParams.to });
  const isMultiTenant = userDomains?.length ? userDomains.length > 1 : false;
  const areFiltersActive =
    Object.keys(url.urlParams).filter((param) => filterKeys.includes(param)).length > 0;
  const {
    groupedData: groupedExtensions,
    error: extensionsError,
    loading: loadingExtensions,
  } = useAllExtensions(multiTenantUser);
  const {
    groupedData: groupedGroups,
    error: groupsError,
    isLoading: loadingGroups,
  } = useAllGroups(multiTenantUser);
  const extensions = getSelfManagedExtensions(groupedExtensions, multiTenantUser);
  const groups = getSelfManagedGroups(groupedGroups, multiTenantUser);
  const {
    groupedCalls,
    error: groupedCallsError,
    isLoading: loadingGroupedCalls,
  } = useGroupedCalls(url.urlParams);
  const [fromTime, setFromTime] = useState<string | undefined>(url.urlParams.fromTime);
  const [toTime, setToTime] = useState<string | undefined>(url.urlParams.toTime);
  const loading = loadingExtensions || loadingGroups || loadingGroupedCalls || loadingUser || loadingMultiTenant;
  const error = extensionsError || groupsError || groupedCallsError;
  const [downloading, setDownloading] = useState<boolean>();
  const [expanded, setExpanded] = useState<boolean>(false);

  useDebounce(
    () => {
      setDebouncedSearchString(searchString);
    },
    2000,
    [searchString]
  );

  useEffect(() => {
    if (debouncedSearchString && debouncedSearchString.length > 0) {
      url.setURLParam("phone", debouncedSearchString);
    } else {
      url.removeURLParam("phone");
    }
  }, [debouncedSearchString, url.removeURLParam, url.setURLParam]);

  useEffect(() => {
    if (isExact || !url.urlParams.from || !url.urlParams.to) {
      const range = {
        from: moment().subtract(1, "week").format("YYYY-MM-DD").toString(),
        to: moment().format("YYYY-MM-DD").toString(),
      };
      setDateRange(range);
      url.setURLParams(range);

      if (!url.urlParams.fromTime && !url.urlParams.toTime) {
        setFromTime("00:00");
        setToTime("23:59");
        url.setURLParams({ fromTime: "00:00", toTime: "23:59" });
      }
      if (!url.urlParams.groupBy) {
        url.setURLParams({ groupBy: "hours:1" });
      }
    }
  }, [
    url.urlParams.from,
    url.urlParams.to,
    url.urlParams.groupBy,
    url.urlParams.fromTime,
    url.urlParams.toTime,
    url.setURLParams,
  ]);

  if (error) {
    console.error(error);
    return (
      <Page name='Grouped Calls Error'>
        <AppLayout>
          <div className={styles.generalErrorMessage}>
            <div className={styles.title}>לא הצלחנו לטעון את העמוד מסיבה לא ידועה</div>
            <div className={styles.content}>
              נסה/י לטעון מחדש. אם הבעיה חוזרת, אנא פנה/י לתמיכה הטכנית <br />
              <a href='mailto:support@ip-com.co.il'>support@ip-com.co.il</a> או בטלפון:{" "}
              <a href='tel:03-657-6576'>03-657-6576</a>
            </div>
            <Button className={styles.reload} onClick={() => window.location.reload()}>
              טען/י מחדש
            </Button>
          </div>
        </AppLayout>
      </Page>
    );
  }

  const summationData = groupedCalls?.reduce((acc, curr) => ({
    ...acc,
    inboundCount: acc.inboundCount + curr.inboundCount,
    inboundAnsweredCount: acc.inboundAnsweredCount + curr.inboundAnsweredCount,
    inboundUnansweredCount: acc.inboundUnansweredCount + curr.inboundUnansweredCount,

    outboundCount: acc.outboundCount + curr.outboundCount,
    outboundUnansweredCount: acc.outboundUnansweredCount + curr.outboundUnansweredCount,
    outboundAnsweredCount: acc.outboundAnsweredCount + curr.outboundAnsweredCount,

    callbackAttemptsCount: acc.callbackAttemptsCount + curr.callbackAttemptsCount
  }), emptyCallsGroup);

  const handleSearch = (value: string) => {
    setSearchString(value);
  };

  const handleSearchReset = () => {
    setSearchString(undefined);
    url.removeURLParam("phone");
  };

  const handleFromTimeChange = (event: any) => {
    if (!event.target.value) return;
    setFromTime(event.target.value);
  };

  const handleToTimeChange = (event: any) => {
    if (!event.target.value) return;
    setToTime(event.target.value);
  };

  const getMultiSelectInitialValues = (filterKey: string) => {
    const entries = url.urlParams[filterKey]
      ?.split(";")
      ?.map((query: string) => query.split(":"))
      ?.map(([k, v]: string[]) => [k, v.split(",")]);

    const initialValues = Object.fromEntries<string[]>(entries || []);
    return initialValues;
  };

  const handleSubmitMultiSelectFilter = (filterKey: string, values: Record<string, string[]>) => {
    const param = Object.entries(values)
      .filter(([key, value]) => value.length !== 0)
      .map(([key, value]) => [key, value.join(",")])
      .map((query) => query.join(":"))
      .join(";");

    url.setURLParam(filterKey, param);
  };

  const groupsToMultiSelectMapper = (groups: GroupResource[]) => {
    const mapper = (group: GroupResource) => ({
      id: group.number!,
      label: `${group.number} ${group.description || ""}`,
    });
    return groups?.map(mapper);
  };

  const extensionsToMultiSelectMapper = (extensions: ExtensionResource[]) => {
    const mapper = (extension: ExtensionResource) => ({
      id: `x${extension.destinationNumber}`,
      label: `${extension.destinationNumber} ${extension.description || ""}`,
    });
    return extensions?.map(mapper);
  };

  const handleExportRequest = async () => {
    try {
      setDownloading(true);
      await exportCallsSummaryReport(url.urlParams);
    } catch (error) {
      console.error(error);
    } finally {
      setDownloading(false);
    }
  };

  const metadata = [
    {
      label: 'לא נענו',
      color: COLORS["RED"],
      value: (summationData?.inboundUnansweredCount) || 0
    },
    {
      label: 'נענו',
      color: COLORS.GREEN,
      value: summationData?.inboundAnsweredCount || 0
    },
    {
      label: 'יוצאות',
      color: COLORS.BLUE,
      value: summationData?.outboundCount || 0
    }
  ];

  return (
    <Page name='Calls History Report'>
      <AppLayout>
        <AppLayoutHeader className={styles.header}>
          <AppLayoutTitle>סיכום היסטוריית שיחות</AppLayoutTitle>
          <Button
            loading={downloading}
            variant='link'
            style={{ width: "8rem" }}
            onClick={handleExportRequest}
          >
            {EXPORT}
          </Button>
        </AppLayoutHeader>
        <section className={styles.searchSection}>
          <div className={styles.searchBar}>
            <Search
              placeholder='חיפוש'
              onChange={(e) => handleSearch(e.target.value)}
              onReset={() => handleSearchReset()}
              value={searchString || ""}
            />
          </div>
          <div className={styles.intervalSelector}>
            <Select
              value={url.urlParams.groupBy}
              onChange={(e) => url.setURLParam("groupBy", e.target.value)}
              style={{ paddingBlock: "11px" }}
            >
              <option value='minutes:15'>רבע שעה</option>
              <option value='minutes:30'>חצי שעה</option>
              <option value='hours:1'>שעה</option>
              <option value='hours:3'>3 שעות</option>
              <option value='dayOfMonth'>יום בחודש</option>
              <option value='dayOfWeek'>יום בשבוע</option>
              <option value='week'>שבוע</option>
              <option value='month'>חודש</option>
            </Select>
          </div>
          <div className={styles.dateSelector}>
            <DateRange
              keepFocusOnInput={true}
              customArrowIcon={<Reset size={16} />}
              displayFormat='DD MMMM'
              numberOfMonths={isMobile ? 1 : DEFAULT_NUM_OF_MONTHS}
              keepOpenOnDateSelect
              noBorder
              renderCalendarInfo={() => (
                <div className={styles.dateRangeFooter}>
                  <div className={styles.timeFilters}>
                    <span className={styles.fromTimeSelector}>
                      <Input value={fromTime} onChange={handleFromTimeChange} type='time' />
                    </span>
                    <span className={styles.toTimeSelector}>
                      <Input value={toTime} onChange={handleToTimeChange} type='time' />
                    </span>
                  </div>
                  <button
                    type='button'
                    disabled={!dateRange.from || !dateRange.to}
                    className={classNames(styles.filterFormButton, styles.primary)}
                    onClick={() => {
                      setFocusedInput(null);
                      url.setURLParams({ fromTime, toTime, ...dateRange });
                    }}
                    style={{
                      marginBlockEnd: "1rem",
                      marginInlineEnd: isMobile ? "" : "1.5rem",
                      fontSize: isMobile ? "0.875rem" : "",
                    }}
                  >
                    עדכון
                  </button>
                </div>
              )}
              showDefaultInputIcon
              minimumNights={0}
              startDate={moment(dateRange.from)}
              startDatePlaceholderText='מתאריך'
              startDateId='callsHistoryReportStartDate'
              endDate={moment(dateRange.to)}
              endDatePlaceholderText='עד תאריך'
              endDateId='callHistoryReportEndDate'
              focusedInput={focusedInput || null}
              onFocusChange={(input) => setFocusedInput(input)}
              isOutsideRange={(day) => {
                return day.startOf("day").isAfter(moment());
              }}
              onDatesChange={({ startDate, endDate }) => {
                setDateRange({
                  from: startDate?.format("YYYY-MM-DD").toString(),
                  to:
                    endDate?.format("YYYY-MM-DD").toString() ||
                    startDate?.format("YYYY-MM-DD").toString(),
                });
              }}
            />
          </div>
        </section>
        <section className={isMobile ? styles.mobileFilters : styles.filters}>
          <Filter
            active={url.urlParams.maxTta || url.urlParams.minTta}
            badge={
              url.urlParams.minTta || url.urlParams.maxTta ? (
                <div className={styles.filterBadge}>
                  <OptionsHorizontal size={16} />
                </div>
              ) : undefined
            }
            initialValues={{
              rangeFunction: url.urlParams.maxTta ? "max" : "min",
              minutes:
                Math.floor(parseInt(url.urlParams.maxTta || url.urlParams.minTta) / 60) || "",
              seconds:
                Math.floor(parseInt(url.urlParams.maxTta || url.urlParams.minTta) % 60) || "",
            }}
            label='המתנה ממוצעת'
            onReset={() => url.removeURLParams(["maxTta", "minTta"])}
            onSubmit={(values) => {
              const ttaValue =
                (parseInt(values.minutes.toString()) * 60 || 0) +
                (parseInt(values.seconds.toString()) || 0);
              if (values.rangeFunction === "min") {
                url.setURLParams({
                  maxTta: null,
                  minTta: ttaValue,
                });
              } else {
                url.setURLParams({
                  maxTta: ttaValue,
                  minTta: null,
                });
              }
            }}
          >
            <SegmentedControl>
              <Field
                name='rangeFunction'
                type='radio'
                value='min'
                component={(props: any) => (
                  <SegmentedControlOption {...props.field}>לפחות</SegmentedControlOption>
                )}
              />
              <Field
                name='rangeFunction'
                type='radio'
                value='max'
                component={(props: any) => (
                  <SegmentedControlOption {...props.field}>עד</SegmentedControlOption>
                )}
              />
            </SegmentedControl>
            <div className={styles.timeInput}>
              <label className={styles.timeUnit}>
                <Field autoComplete='off' name='minutes' type='text' placeholder='0' />
                <div className={styles.timeUnitLabel}>דקות</div>
              </label>
              <label className={styles.timeUnit}>
                <Field autoComplete='off' name='seconds' type='text' placeholder='0' />
                <div className={styles.timeUnitLabel}>שניות</div>
              </label>
            </div>
          </Filter>
          <Filter
            active={url.urlParams.maxDuration || url.urlParams.minDuration}
            badge={
              url.urlParams.minDuration || url.urlParams.maxDuration ? (
                <div className={styles.filterBadge}>
                  <OptionsHorizontal size={16} />
                </div>
              ) : undefined
            }
            initialValues={{
              rangeFunction: url.urlParams.maxDuration ? "max" : "min",
              minutes:
                Math.floor(
                  parseInt(url.urlParams.maxDuration || url.urlParams.minDuration || 0) / 60
                ) || "",
              seconds:
                Math.floor(
                  parseInt(url.urlParams.maxDuration || url.urlParams.minDuration || 0) % 60
                ) || "",
            }}
            label='משך שיחה ממוצע'
            onReset={() => url.setURLParams({ minDuration: undefined, maxDuration: undefined })}
            onSubmit={(values) => {
              const durationValue =
                (parseInt(values.minutes.toString()) * 60 || 0) +
                (parseInt(values.seconds.toString()) || 0);
              if (values.rangeFunction === "min") {
                url.setURLParams({
                  maxDuration: null,
                  minDuration: durationValue,
                });
              } else {
                url.setURLParams({
                  maxDuration: durationValue,
                  minDuration: null,
                });
              }
            }}
          >
            <SegmentedControl>
              <Field
                name='rangeFunction'
                type='radio'
                value='min'
                component={(props: any) => (
                  <SegmentedControlOption {...props.field}>לפחות</SegmentedControlOption>
                )}
              />
              <Field
                name='rangeFunction'
                type='radio'
                value='max'
                component={(props: any) => (
                  <SegmentedControlOption {...props.field}>עד</SegmentedControlOption>
                )}
              />
            </SegmentedControl>
            <div className={styles.timeInput}>
              <label className={styles.timeUnit}>
                <Field autoComplete='off' name='minutes' type='text' placeholder='0' />
                <div className={styles.timeUnitLabel}>דקות</div>
              </label>
              <label className={styles.timeUnit}>
                <Field autoComplete='off' name='seconds' type='text' placeholder='0' />
                <div className={styles.timeUnitLabel}>שניות</div>
              </label>
            </div>
          </Filter>
          <Filter /** Members filter */
            initialValues={getMultiSelectInitialValues("membersPerDomain")}
            active={url.urlParams.membersPerDomain}
            badge={
              url.urlParamsMultiValue.membersPerDomain?.length ? (
                <div className={styles.filterBadge}>
                  {url.urlParamsMultiValue.membersPerDomain?.length
                    ? url.urlParamsMultiValue.membersPerDomain.length
                    : ""}
                </div>
              ) : undefined
            }
            label='נציגים/שלוחות'
            onReset={() => url.removeURLParam("membersPerDomain")}
            onSubmit={(values) => {
              handleSubmitMultiSelectFilter("membersPerDomain", values);
            }}
          >
            <div className={styles.filterWithRows}>
              {userDomains
                ?.filter((domain) => extensions?.[domain.domain_uuid]?.length)
                ?.sort((a, b) => (a.domain_uuid === currentDomainUuid ? -1 : 1))
                ?.map((domain) => {
                  const shortDomainName = domain.domain_name.split(".")[0];
                  const domainInitialSelections =
                    getMultiSelectInitialValues("membersPerDomain")[shortDomainName] || [];
                  return (
                    <MultiSelect
                      key={domain.domain_uuid}
                      initialSelections={domainInitialSelections}
                      items={extensionsToMultiSelectMapper(extensions[domain.domain_uuid])}
                      primaryItem={{ id: shortDomainName, label: domain.domain_owner_name }}
                      hidePrimaryItem={userDomains.length === 1}
                      itemsCountForSearch={5}
                    />
                  );
                })}
            </div>
          </Filter>
          {
            <Filter /** Groups filter */
              initialValues={getMultiSelectInitialValues("groupsPerDomain")}
              active={url.urlParams.groupsPerDomain}
              badge={
                url.urlParamsMultiValue.groupsPerDomain?.length ? (
                  <div className={styles.filterBadge}>
                    {url.urlParamsMultiValue.groupsPerDomain?.length
                      ? url.urlParamsMultiValue.groupsPerDomain.length
                      : ""}
                  </div>
                ) : undefined
              }
              label='קבוצות'
              onReset={() => url.removeURLParam("groupsPerDomain")}
              onSubmit={(values) => {
                handleSubmitMultiSelectFilter("groupsPerDomain", values);
              }}
            >
              <div className={styles.filterWithRows}>
                {userDomains
                  ?.filter((domain) => groups?.[domain.domain_uuid]?.length)
                  ?.sort((a, b) => (a.domain_uuid === currentDomainUuid ? -1 : 1))
                  ?.map((domain) => {
                    const shortDomainName = domain.domain_name.split(".")[0];
                    const domainInitialSelections =
                      getMultiSelectInitialValues("groupsPerDomain")[shortDomainName] || [];
                    return (
                      <MultiSelect
                        key={domain.domain_uuid}
                        initialSelections={domainInitialSelections}
                        items={groupsToMultiSelectMapper(groups[domain.domain_uuid])}
                        primaryItem={{ id: shortDomainName, label: domain.domain_owner_name }}
                        hidePrimaryItem={userDomains.length === 1}
                        itemsCountForSearch={5}
                      />
                    );
                  })}
              </div>
            </Filter>
          }
          {isMultiTenant && (
            <Filter
              active={url.urlParams.domains}
              badge={
                url.urlParamsMultiValue.domains && url.urlParamsMultiValue.domains?.length ? (
                  <div className={styles.filterBadge}>{url.urlParamsMultiValue.domains.length}</div>
                ) : undefined
              }
              initialValues={{ domains: url.urlParamsMultiValue.domains }}
              label='ארגונים'
              onReset={() => url.removeURLParam("domains")}
              onSubmit={(values) => {
                const value = values.domains.join(",");
                url.setURLParam("domains", value);
              }}
            >
              <div className={styles.filterWithRows}>
                {userDomains?.map((domain) => (
                  <Field
                    key={domain.domain_uuid}
                    component={(props: any) => (
                      <Checkbox title={domain.domain_owner_name} {...props.field} />
                    )}
                    name='domains'
                    type='checkbox'
                    value={domain.domain_name.substring(0, 4)}
                  />
                ))}
              </div>
            </Filter>
          )}
          {areFiltersActive && (
            <button
              className={styles.resetFiltersButton}
              onClick={() => url.removeURLParams(filterKeys)}
            >
              איפוס
            </button>
          )}
        </section>
        {loading ? (
          <div className={styles.loadingMessage}>
            <Spinner />
          </div>
        ) : (
          <div>

            <Card className={styles.graphCard}>

              <GroupedCallsAreaChart expanded={expanded} data={groupedCalls!} metadata={metadata} groupedBy={url.urlParams.groupBy} />
              <Button
                variant="ghost"
                className={styles.resizeBtn}
                onClick={() => setExpanded(!expanded)}
              >
                <img src={ expanded ? ShrinkIcon : ResizeIcon} width={16} height={16}/>
              </Button>
            </Card> 

            <GroupedCallsTable data={groupedCalls!} urlParams={url.urlParams} />
            
          </div>
        )}
      </AppLayout>
    </Page>
  );
};