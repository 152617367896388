import { FC } from 'react';
import { DialogStateReturn } from 'reakit';
import { ErrorDialog } from '../../design/dialog/error-dialog/error-dialog';
import { DefaultDialerValidation } from '../types';
import { renderDefaultDialerMsg } from '../utils/renderDefaultDialerErrorMsg';

interface DefaultDialerErrorDialogProps {
  dialog: DialogStateReturn;
  defaultDialerValidation: DefaultDialerValidation;
}

export const DefaultDialerErrorDialog: FC<DefaultDialerErrorDialogProps> = ({ dialog, defaultDialerValidation }) => {
  const errorMessage = renderDefaultDialerMsg(defaultDialerValidation);

  const handleClose = () => {
    dialog.hide();
  };

  return <ErrorDialog dialog={dialog} mainMessage={errorMessage} onClose={() => handleClose} />;
};
