import { forwardRef } from 'react';
import { Typography } from '@mui/material';

import { MenuList } from '../menuList/MenuList';
import { SelectOption } from '../../../formConfig/types';
import { TypographyVariant } from '../../../theme/muiTypes';
import { StyledCustomSelect, StyledSelect } from './StyledCustomSelect';

export interface CustomSelectProps {
  label?: string;
  value?: string | number | unknown;
  options: SelectOption[];
  fullWidth?: boolean;
  className?: string;
  onChange?: (event: any) => void;
}

export const CustomSelect = forwardRef<HTMLSelectElement, CustomSelectProps>(
  ({ label, value, options, fullWidth = true, ...props }, ref) => {
    return (
      <StyledCustomSelect>
        {label && <Typography variant={TypographyVariant.boldCaption1}>{label}</Typography>}
        <StyledSelect ref={ref} value={value} {...props} fullWidth displayEmpty>
          <MenuList options={options} />
        </StyledSelect>
      </StyledCustomSelect>
    );
  }
);
