import React from 'react';
import { Icon, IconProps } from './icon';

export const Reset = ({...props}: IconProps) => (
  <Icon 
    viewBox="0 0 96 96"
    fill="currentColor"
    {...props}
  >
    <g fillRule="evenodd">
        <g>
            <path d="M48,12 C67.882251,12 84,28.117749 84,48 C84,67.882251 67.882251,84 48,84 C28.117749,84 12,67.882251 12,48 C12,28.117749 28.117749,12 48,12 Z M61.5251263,29.5251263 L66.4748737,34.4748737 L51.9491263,48.0001263 L66.4748737,61.5251263 L61.5251263,66.4748737 L48.0001263,51.9491263 L34.4748737,66.4748737 L29.5251263,61.5251263 L44.0511263,48.0001263 L29.5251263,34.4748737 L34.4748737,29.5251263 L48.0001263,44.0511263 L61.5251263,29.5251263 Z"></path>
        </g>
    </g>
  </Icon>
);


