import { useState } from "react";
import classNames from "classnames";
import mixpanel from "mixpanel-browser";
import { DialogStateReturn, useDialogState } from "reakit";
import { Button } from "../../../design/button";
import { useDestinations } from "../../../api/use-destinations";
import {
  CallflowNode,
  CallflowNodeHeader,
  CallflowNodeTitle,
  CallflowNodeContent,
  CallflowNodeActions,
  CallflowNodeOverline,
} from "../callflow-node/callflow-node";
import { DestinationNode, DestinationNodeProps } from "../destination-node";
import styles from "./menu-node.module.scss";
import { Dialog } from "../../../design/dialog/dialog";
import { Warning } from "../../../design/warning/warning";
import { API } from "aws-amplify";
import { useEffect } from "react";
import { useMe } from "../../../api/use-me";
import { Input } from "../../../design/form/input/input";
import { Check } from "../../../design/icons/check";
import { Edit } from "../../../design/icons/edit";
import { GeneralError } from "../../../design/general-error/general-error";
import { Field } from "../../../design/form/field";
import { AudioFileField } from "../../../design/form/audio-file-field/audio-file-field";
import { AudioFileInput } from "../../../design/form/audio-file-input/audio-file-input";
const visitedDestinations = new Set<string>();

export function MenuNode(props: DestinationNodeProps) {
  const user = useMe();
  const destinations = useDestinations(user?.data);
  const destination = destinations.data ? destinations.data[props.destination] : null;
  const [ivrName, setIvrName] = useState<string>(destination?.name || "");
  const [editState, setEditState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nextDestination, setNextDestination] = useState<string>();
  const [shouldRender, setShouldRender] = useState(false);
  const editDialog = useDialogState();

  useEffect(() => {
    if (!nextDestination && destination) {
      const action = Object.entries<any>(destination.actions)[0][1];

      if (action.destination) {
        setNextDestination(action.destination);
      }

      if (!visitedDestinations.has(destination.id)) {
        visitedDestinations.add(destination.id);
        setShouldRender(true);
      } else {
        setShouldRender(false);
      }
    }
  }, [nextDestination, destination]);

  // This useEffect function serves as the equivalent to the class bases "componentWillUnmount".
  // We are clearing the visitedDestinations set in case a client leaves the number page and comes back to it later.
  // In that case, what would happen is that all the visited destinations will still be there and no IVR menu will render.
  // A good place to clear the set is on unmount, hence the use of this weird looking useEffect.
  // Read more avout it => https://dev.to/robmarshall/how-to-use-componentwillunmount-with-functional-components-in-react-2a5g
  useEffect(() => {
    return () => {
      visitedDestinations.clear();
    };
  }, []);

  const handleNameChange = async () => {
    const urlName = process.env.REACT_APP_WEB_API_NAME!;
    const endpoint = `/domain/${user.data?.domain.id}/ivr/${destination?.number}/name`;
    const myInit = {
      body: {
        name: ivrName,
        number: destination?.number,
      },
    };

    try {
      setLoading(true);
      await API.put(urlName, endpoint, myInit);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setEditState(false);
      destinations.mutate();
    }
  };

  if (destination && shouldRender) {
    return (
      <>
        <CallflowNode>
          <CallflowNodeHeader>
            <CallflowNodeOverline>תפריט קולי</CallflowNodeOverline>
            <CallflowNodeTitle>
              {editState ? (
                <div className={styles.nameEditSection}>
                  <Input
                    defaultValue={destination.name}
                    onChange={(event) => {
                      setIvrName(event.target.value);
                    }}
                    style={{ width: "35%" }}
                    disabled={loading}
                  />
                  <Check onClick={handleNameChange} style={{ cursor: "pointer" }} />
                </div>
              ) : (
                <div className={styles.nameEditSection}>
                  <span>{destination.name}</span>
                  <Edit onClick={() => setEditState(true)} style={{ cursor: "pointer" }} />
                </div>
              )}
            </CallflowNodeTitle>
            <CallflowNodeActions>
              <Button variant='link' type='button' onClick={() => editDialog.show()}>
                עריכה
              </Button>
            </CallflowNodeActions>
          </CallflowNodeHeader>
          <CallflowNodeContent>
            <div className={styles.message}>
              <audio
                key={destination.greetingLongRecordingId}
                src={destination.messageUrl}
                controls
              />
            </div>
            <div className={styles.dialpad}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, "✱", 0, "#"].map((digit) => {
                const action = destination.actions[digit];
                const digitDestination = destinations.data
                  ? destinations.data[action?.destination]
                  : null;

                return (
                  <div
                    key={`menu-${destination.id}-key-${digit}`}
                    className={classNames(styles.dialpadKey, {
                      [styles.disabled]: !action,
                      [styles.active]:
                        nextDestination && nextDestination === digitDestination?.number,
                      [styles.clickable]: Boolean(digitDestination),
                    })}
                    onClick={() =>
                      digitDestination ? setNextDestination(digitDestination.number) : null
                    }
                  >
                    <span className={styles.dialpadKeyDigit}>{digit}</span>
                    {action && !digitDestination ? (
                      <span className={styles.dialpadKeyDestination}>{action.destination}</span>
                    ) : (
                      digitDestination && (
                        <span className={styles.dialpadKeyDestination}>
                          {digitDestination.name?.replaceAll("_", " ") || "שלוחה"}
                          {digitDestination.type === "Extension" && (
                            <span> ({digitDestination.number})</span>
                          )}
                        </span>
                      )
                    )}
                  </div>
                );
              })}
            </div>
          </CallflowNodeContent>
        </CallflowNode>
        {editDialog.visible && (
          <EditMenuDialog dialog={editDialog} destination={destination.number} />
        )}
        {nextDestination && <DestinationNode destination={nextDestination} />}
      </>
    );
  }
  return null;
}

function EditMenuDialog(props: EditMenuDialogProps) {
  const user = useMe();
  const destinations = useDestinations(user?.data);
  const destination = destinations.data ? destinations.data[props.destination] : null;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = async (values: { name: string; file: File | null }) => {
    try {
      if (!values.file) {
        return;
      }

      setLoading(true);
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("file", values.file);

      await API.post(
        process.env.REACT_APP_WEB_API_NAME!,
        `/domains/${user?.data?.domain.id}/ivrs/${props.destination}/recording`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: formData,
        }
      );
      mixpanel.track("Change Menu Recording", {
        Context: destination?.number,
      });
      await destinations.mutate();
      setLoading(false);
      props.dialog.hide();
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      {...props.dialog}
      variant='form'
      header={`עריכת תפריט קולי - ${destination?.name}`}
      hideOnClickOutside={false}
      hideOnEsc={true}
      onSubmit={handleSubmit}
      submitLabel='העלאה'
      initialValues={{ name: "", file: null }}
      loading={loading}
      aria-label='עריכת תפריט קולי'
    >
      <Warning message='פנה/י לתמיכה כדי לעדכן את ההקשות' />
      <Field name='name' label='שם ההקלטה'>
        <Input type='text' />
      </Field>
      <AudioFileField name='file'>
        <AudioFileInput
          label={"העלאת קובץ הקלטה חדש"}
          description={"ניתן להעלות קבצי אודיו עד 5MB"}
        />
      </AudioFileField>
      {!loading && error && <GeneralError error='לא הצלחנו להעלות את הקובץ' />}
    </Dialog>
  );
}

interface EditMenuDialogProps {
  dialog: DialogStateReturn;
  destination: string;
}
