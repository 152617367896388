import { memo, useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { DomainFeature } from '../api/types';
import { hadshanutUserState } from '../state';
import { getDomainShortName } from '../utils/domain/getDomainShortName';

interface PersistentStorage {
  getItem(key: string | undefined): string | null;
  setItem(Key: string, value: any): void;
  removeItem(Key: string): void;
}

class LocalStorage implements PersistentStorage {
  getItem(key: string | undefined) {
    if (!key) return;
    const item = localStorage.getItem(key);
    if (item === null || item === 'undefined') return undefined;
    if (item === 'null') return null;

    try {
      return JSON.parse(item);
    } catch (error) {
      console.error(error);
    }

    return item;
  }

  setItem(key: string, value: any): void {
    if (value === undefined) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }
}

class MockStorage implements PersistentStorage {
  getItem() {
    return null;
  }
  setItem() {}
  removeItem(Key: string): void {}
}

export const persistentStorage = window?.localStorage ? new LocalStorage() : new MockStorage();

// A wrapper hook that takes in a feature name (string) and returns a reference to a function that interacts with the local storage
export const usePersistentStorageValue = <T>(
  key: string | undefined,
  initialValue?: T,
  feature: DomainFeature | undefined = undefined
) => {
  const user = useRecoilValue(hadshanutUserState);
  const domainShortName = getDomainShortName(user?.domain.name);
  const keyPrefix = `${domainShortName}${feature ? `.${feature}` : ''}`;
  const newKey = `${keyPrefix}.${key}`;
  const [value, setValue, removeValue] = useStorage<T>(newKey, initialValue);

  return {
    value,
    setValue,
    removeValue,
  };
};

const useStorage = <T>(key: string | undefined, initialValue?: T) => {
  const [value, setValue] = useState<T>(() => {
    const valueFromStorage = persistentStorage.getItem(key);

    if (typeof initialValue === 'object' && !Array.isArray(initialValue) && initialValue !== null) {
      return {
        ...initialValue,
        ...valueFromStorage,
      };
    }
    return valueFromStorage || initialValue;
  });

  const removeValue = useCallback(
    function () {
      localStorage.removeItem(key || '');
    },
    [key]
  );

  useEffect(() => {
    if (!key) {
      return;
    }
    if (!key.match(/^(\d{4})\./)) {
      return;
    } // DO NOT WRITE to local storage if key does not match pattern
    persistentStorage.setItem(key, value);
  }, [key, value]);

  return [value, setValue, removeValue] as const;
};
