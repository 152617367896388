import { styled } from '@mui/material';
import theme from '../../../../theme/muiTheme';

export const StyledGroupName = styled('span')(({ theme }) => ({
  ...theme.typography.regularText1,
  borderBlockEnd: '1px solid rgb(24, 29, 36)',
}));
export const StyledGroupBadge = styled('span')(() => ({
  background: theme.palette.primary.light,
  color: theme.palette.primary.dark,
  paddingInline: theme.spacing(3),
  paddingBlock: '2px',
  fontSize: theme.fontSizes.medium,
  borderRadius: theme.borderRadius.badge,
}));
