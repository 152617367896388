import { CallForwarding } from "../../../design/icons"
import { Remove } from "../../../design/icons/remove"
import { Edit } from "../../../design/icons/edit"
import styles from "./group-menu.module.scss"

export const GroupMenu = ({ isForwarded, onEditNameClick, onForwardClick, onCancelForwardClick }: GroupMenuProps) => (
  <div>
    <div className={styles.menuAction} style={isForwarded ? { border: "0px" } : {}} onClick={onEditNameClick}>
      עריכת שם קבוצה
      <Edit size={24} />
    </div>
    <div className={styles.menuAction} onClick={onForwardClick}>
      העברת שיחות
      <CallForwarding size={24} />
    </div>
    {
      isForwarded && 
      <div onClick={onCancelForwardClick} className={styles.menuAction} style={{ border: "0px" }}>
        <span className={styles.cancelAction}>
          ביטול העברת שיחות
        </span>
        <Remove size={24} />
      </div>
    }
  </div >
)

interface GroupMenuProps {
  isForwarded: boolean,
  onEditNameClick: () => void,
  onForwardClick: () => void,
  onCancelForwardClick: () => void,
}