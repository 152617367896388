import React from 'react';
import { Icon, IconProps } from './icon';

export const Password = ({ ...props }: IconProps) => (
  <Icon
    viewBox="0 0 24 24"
    fill="evenOdd"
    {...props}
  >
    <path d="M4 11.47L3.15 12.95L1.85 12.2L2.7 10.72H1V9.22H2.7L1.85 7.75L3.15 7L4 8.47L4.85 7L6.15 7.75L5.3 9.22H7V10.72H5.3L6.15 12.2L4.85 12.95L4 11.47ZM2 17V19H22V17H2ZM9.85 12.2L11.15 12.95L12 11.47L12.85 12.95L14.15 12.2L13.3 10.72H15V9.22H13.3L14.15 7.75L12.85 7L12 8.47L11.15 7L9.85 7.75L10.7 9.22H9V10.72H10.7L9.85 12.2ZM22.15 7.75L21.3 9.22H23V10.72H21.3L22.15 12.2L20.85 12.95L20 11.47L19.15 12.95L17.85 12.2L18.7 10.72H17V9.22H18.7L17.85 7.75L19.15 7L20 8.47L20.85 7L22.15 7.75Z"/>
  </Icon>
);
