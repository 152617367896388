import { InputAdornment, TextFieldProps, Typography } from '@mui/material';
import { ChangeEvent, FocusEvent, forwardRef, ReactNode } from 'react';

import { TypographyVariant } from '../../../theme/muiTypes';
import { StyledInputContainer, StyledTextField } from './StyledCustomInput';

type OmittedVariantTextFieldProps = Omit<TextFieldProps, 'variant'>;
export interface CustomInputProps extends OmittedVariantTextFieldProps {
  icon?: ReactNode;
  label?: string;
  value?: string;
  name?: string;
  error?: boolean;
  className?: string;
  placeholder?: string;
  variant?: TypographyVariant;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
}

export const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(
  ({ icon, label, className = '', name = '', variant = TypographyVariant.largeText1, ...rest }, ref) => {
    const textFieldInputProps = {
      endAdornment: <InputAdornment position='end'>{icon}</InputAdornment>,
    };

    const adornmentInputProps = icon ? textFieldInputProps : undefined;
    const props = { ...rest, InputProps: adornmentInputProps };

    return (
      <StyledInputContainer className={className}>
        {label && <Typography variant={variant}>{label}</Typography>}
        <StyledTextField name={name} className={className} inputRef={ref} {...props} />
      </StyledInputContainer>
    );
  }
);
