export const DEFAULT_DATE = new Date(0, 0, 0, 0, 0, 0);
export const ensureDate = (date: string | Date | undefined) => typeof date == "string" ? new Date(date) : date;

/**
 * @param startDate - Date object representing the starting time
 * @param endDate - Date object representing the ending time
 * @returns A hebrew, human readable string in the format: דקות xx,שעות yy,ימים zz
 */
export const getHebTimeDiff = (startDate: Date, endDate: Date): string => {
  // times
  const start_time = startDate.getTime(); // epoch time
  const end_time = endDate.getTime(); // epoch time
  const diffMs = end_time - start_time;
  let diffMins = Math.floor(diffMs / 60000);
  let diffHrs = Math.floor(diffMins / 60);
  const diffDays = Math.floor(diffHrs / 24);

  // In any case, we don't want to display minutes greater than 59
  // or hrs over 23. Thus, we'll always take the remainder
  diffHrs %= 24;
  diffMins %= 60;

  // strings
  const strDays = diffDays ? `${diffDays} ימים` : '';
  const strHrs = diffHrs ? `${diffHrs} שעות` : '';
  const strMins = diffMins ? `${diffMins} דקות` : '';

  // filtering out empty strings and returning a joint string.
  const resultString = [strDays, strHrs, strMins].filter(x => x).join(", ") || `${String(Math.floor(diffMs / 1000))} שניות`;
  return resultString;
}

/**
 * @param startDate - Date object representing the starting time
 * @param endDate - Date object representing the ending time
 * @param includeHours - optional, defaults to true
 * @returns A human readable string in the format: HH:MM:SS
 */
export const getDigitTimeDiff = (startDate: Date, endDate: Date, includeHours = true): string => {
  // times
  const start_time = ensureDate(startDate)!.getTime(); // epoch time
  const end_time = ensureDate(endDate)!.getTime(); // epoch time
  const diffMs = Math.max(end_time - start_time, 0);
  let diffSeconds = Math.floor(diffMs / 1000);
  let diffMins = Math.floor(diffSeconds / 60);
  const diffHrs = Math.floor(diffMins / 60);

  // In any case, we don't want to display minutes or seconds greater than 59
  //Thus, we'll always take the remainder
  diffSeconds %= 60;
  diffMins %= 60;

  let resultString = [String(diffMins).padStart(2, '0'), String(diffSeconds).padStart(2, '0')].join(":");
  if (includeHours) resultString = String(diffHrs).padStart(2, '0') + ':' + resultString;
  return resultString;
}

export const getDigitTimeFromMillis = (milliseconds: number, format: 'HH:MM:SS' | 'MM:SS' | 'SS') => {
  const ms = Math.max(milliseconds, 0);
  const totalSeconds = Math.floor(ms / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  if (format === 'HH:MM:SS') {
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }
  if (format === 'MM:SS') {
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }
  return String(totalSeconds); 
}
