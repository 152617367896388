import { useMe } from "./use-me";
import { ExtensionResource } from "./types";
import { useApi } from "./use-api";
import { API } from "aws-amplify";
import useSWR from "swr";
import { User } from "./use-users";

const SWR_CONFIG = {
  revalidateOnFocus: false,
  dedupingInterval: 5 * 60 * 1000, // 5 minutes 
  refreshInterval: 5 * 60 * 1000, // 5 minutes
}

export const useExtensions = (force = false) => {
  const user = useMe(force);
  const domainId = user?.data?.domain.id;
  const path = domainId
    ? ['domains', domainId, 'extensions'].join('/')
    : null;
  return useApi<ExtensionResource[]>(path, undefined, force);
}

export const useAllExtensions = (user?: User) => {
  const userDomains = user?.domains;
  const domainUuids = userDomains?.map(domain => domain.domain_uuid);
  const paths = domainUuids?.map(domainUuid => `/domains/${domainUuid}/extensions`);
  const { data, error, isLoading } = useSWR(paths, multiTenantExtensionFetcher, SWR_CONFIG);
  const groupedData: Record<string, ExtensionResource[]> = Object.fromEntries(data?.map((extensions, index) => [domainUuids?.[index], extensions]) || []);
  return { groupedData, error, loading: isLoading }
}

const multiTenantExtensionFetcher = async (ids : string[]) => {
  const urlName = process.env.REACT_APP_WEB_API_NAME!;
  const data = [];
  for (const id of ids) {
    try {
      data.push(await API.get(urlName, id, {}));
    } catch (err) {
      data.push([]);
      console.error('Failed to fetch extensions of domain: ', id);
    }
  }
  return data;
}
