import { NavLink } from 'react-router-dom';
import { CallAttributes, call_groupUuids } from '../../../interfaces';
import { useGroups } from '../../../../api/use-groups';
import { useMe } from '../../../../api/use-me';
import { Table } from '../../../../design/table/table';
import styles from './outbound-calls-list.module.scss';
import { Column } from '../../../../design/table/types';

export const OutboundCallsList = (props: { calls: CallAttributes[], slaThreshold: number, tableName: string | undefined }) => {
  const user = useMe();
  const { data: groups } = useGroups(user.data?.domain.id);                   // full info of all domain's groups
  const selfManagedGroups = groups?.filter(group => user?.data?.domain.config.selfManagedGroups.includes(group.id));
  // group call by group uuid
  const groupedOutboundCalls = props.calls
    .reduce((grouped, call) => {
      const targetGroupId = call_groupUuids(call).find(group => user?.data?.domain.config.selfManagedGroups.includes(group)); // first self managed group that the call visited
      if (!targetGroupId) return grouped; // this call is faulty and should not count

      if (!grouped[targetGroupId]) {
        grouped[targetGroupId] = []
      }

      grouped[targetGroupId].push(call);
      return grouped;
    }, {} as GroupedCalls);
  
  const refinedData = Object.entries(groupedOutboundCalls).map(([groupId, calls]) => {
    const groupName = groups?.find(group => group.id === groupId)?.name;
    const outboundCount = calls.length;
    const answeredCount = calls.filter(call => call.answerTime).length;
    const unansweredCount = calls.filter(call => call.endTime && !call.answerTime).length;
    return {
      id: groupId,
      groupName,
      outboundCount,
      answeredCount,
      unansweredCount
    }
  });

  const columns: Array<Column<typeof refinedData[0], keyof typeof refinedData[0]>> = [
    {
      key: 'groupName',
      header: "מחלקה",
      className: styles.column
    },
    {
      key: 'outboundCount',
      header: 'יוצאות',
      className: styles.column,
      render: (row) => {
        const group = selfManagedGroups?.find(group => group.id === row.id);

        if (!group || !user.data) return (
          <span>{row.outboundCount}</span>
        )

        const todayDate = new Date();
        const dateFilterValue = `${todayDate.getFullYear()}-${todayDate.getMonth()+1}-${todayDate.getDate()}`
        const dateQuery = `from/${dateFilterValue}/to/${dateFilterValue}`
        const groupQuery = `groupsPerDomain/${user.data.domain.name.substring(0, 4)}:${group.number}`;
        const url = `/calls-history/type/outbound/${dateQuery}/${groupQuery}`;
        return (
          <NavLink to={url} style={{borderBlockEnd: '1px solid #181D24'}}>{row.outboundCount}</NavLink>
        )
      }
    },
    {
      key: 'answeredCount',
      header: 'נענו',
      className: styles.column,
      render: (row) => {
        const group = selfManagedGroups?.find(group => group.id === row.id);

        if (!group || !user.data) return (
          <span>{row.outboundCount}</span>
        )

        const todayDate = new Date();
        const dateFilterValue = `${todayDate.getFullYear()}-${todayDate.getMonth()+1}-${todayDate.getDate()}`
        const dateQuery = `from/${dateFilterValue}/to/${dateFilterValue}`
        const groupQuery = `groupsPerDomain/${user.data.domain.name.substring(0, 4)}:${group.number}`;
        const url = `/calls-history/type/outbound/status/answered/${dateQuery}/${groupQuery}`;
        return (
          <NavLink to={url} style={{borderBlockEnd: '1px solid #181D24'}}>{row.answeredCount}</NavLink>
        )
      }
    },
    {
        key: 'unansweredCount',
        header: 'לא נענו',
        className: styles.column,
        render: (row) => {
          const group = selfManagedGroups?.find(group => group.id === row.id);
  
          if (!group || !user.data) return (
            <span>{row.outboundCount}</span>
          )
  
          const todayDate = new Date();
          const dateFilterValue = `${todayDate.getFullYear()}-${todayDate.getMonth()+1}-${todayDate.getDate()}`
          const dateQuery = `from/${dateFilterValue}/to/${dateFilterValue}`
          const groupQuery = `groupsPerDomain/${user.data.domain.name.substring(0, 4)}:${group.number}`;
          const url = `/calls-history/type/outbound/status/failed/${dateQuery}/${groupQuery}`;
          return (
            <NavLink to={url} style={{borderBlockEnd: '1px solid #181D24'}}>{row.unansweredCount}</NavLink>
          )
        }
      },
  ];

  return (
    <Table columns={columns} data={refinedData} showHeaders tableName={props?.tableName} />
  )
}

type GroupedCalls = Record<string, CallAttributes[]>