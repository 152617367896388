import { Route, Routes } from 'react-router-dom';
import { InvitationListPage } from './pages/invitation-list-page';
import { InvitationPage } from './pages/invitation-page';
import { NewInvitationPage } from './pages/new-invitation-page';

export function Invitations() {
  return (
    <Routes>
      <Route
        path="/new"
        Component={NewInvitationPage}
      />
      <Route
        path="/*"
        Component={InvitationManagement}
      />
    </Routes>
  )
}

function InvitationManagement() {
  return (
    <>
      <Routes>
        <Route
          path="/:id"
          Component={InvitationPage}
        />
        <Route
          path="/*"
          Component={InvitationListPage}
        />
      </Routes>
    </>
  )
}