import { ThemeProvider } from '@mui/material/styles';
import { SnackbarCloseReason } from '@mui/material';
import { FC, memo, MouseEvent, useCallback, useMemo, useState } from 'react';

import { TEXTS } from '../../../utils/texts';
import theme from '../../../../theme/muiTheme';
import { GroupResource } from '../../../../api/types';
import { SelectOption } from '../../../../formConfig/types';
import { RequestMethod } from '../../../../globalTypes/types';
import { FieldSavingStatus } from '../../../../callbacks/types';
import { savingIndicator } from '../../../../utils/globalTexts';
import { SnackbarEvent } from '../../../../components/types/types';
import { getSelectOptions } from '../../../utils/createGroupOptions';
import { DEFAULT_FIELD_SAVING_STATUS } from '../../../utils/constants';
import { initSelectedGroups } from '../../../utils/stateInitialization';
import { Dropdown } from '../../../../components/common/dropdown/Dropdown';
import { useManageAgentGroups } from '../../../hooks/useManageAgentGroups';
import { ButtonVariant, SnackbarVariant } from '../../../../theme/muiTypes';
import { StyledGroupBadge, StyledGroupName } from './StyledManageAgentGroups';
import { CustomButton } from '../../../../components/common/customButton/CustomButton';
import { ReactComponent as SearchIcon } from '../../../../design/icons/search-icon.svg';
import { CustomSnackbar } from '../../../../components/common/customSnackbar/CustomSnackbar';
import { EditableDropdown } from '../../../../components/common/editableDropdown/EditableDropdown';

const { AGENT_GROUP_SEARCH_PLACEHOLDER } = TEXTS;
interface ManageAgentGroupsProps {
  groups: GroupResource[];
  agentId: string;
  domainId: string;
  ccqNames: string[];
  queueUuids: string[];
}

const ManageAgentGroups: FC<ManageAgentGroupsProps> = ({ groups, agentId, ccqNames, domainId, queueUuids }) => {
  const [editMode, setEditMode] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [fieldSavingStatus, setFieldSavingStatus] = useState<FieldSavingStatus>(DEFAULT_FIELD_SAVING_STATUS);
  const [selectedGroups, setSelectedGroups] = useState<SelectOption[]>(() => initSelectedGroups(queueUuids, groups));

  const showBadge = selectedGroups.length > 1;
  const open = Boolean(anchorEl);

  const updateAgentGroup = useManageAgentGroups();
  const allGroupOptions = useMemo(() => getSelectOptions(groups), [groups]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setEditMode(false);
  }, []);

  const handleChangeMode = useCallback(() => {
    setEditMode(!editMode);
  }, [editMode]);

  const handleCloseDropdown = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleCloseSnackbar = useCallback((event: SnackbarEvent, reason: SnackbarCloseReason) => {
    if (reason !== 'clickaway') {
      setFieldSavingStatus((prevStatus) => ({ ...prevStatus, isOpen: false }));
    }
  }, []);

  const handleAddItem = useCallback(
    async (event: MouseEvent<Element>, item: SelectOption) => {
      const selectedGroupId = item.value;
      const result = await updateAgentGroup({
        method: RequestMethod.POST,
        domainId,
        agentId,
        groupId: selectedGroupId,
      });
      if (result?.success) {
        const updatedSelectedItems = [...selectedGroups, item];
        setSelectedGroups(updatedSelectedItems);
        setFieldSavingStatus({ isOpen: true, key: agentId, variant: SnackbarVariant.success });
      } else {
        setFieldSavingStatus({ isOpen: true, key: agentId, variant: SnackbarVariant.error });
      }
    },
    [agentId, domainId, selectedGroups, updateAgentGroup]
  );

  const handleRemoveItem = useCallback(
    async (groupToRemove: SelectOption) => {
      const result = await updateAgentGroup({
        method: RequestMethod.DELETE,
        domainId,
        agentId,
        groupId: groupToRemove.value,
      });
      if (result?.success) {
        setSelectedGroups((prevSelectedGroups) =>
          prevSelectedGroups.filter((selectedGroup) => selectedGroup.value !== groupToRemove.value)
        );
        setFieldSavingStatus({ isOpen: true, key: agentId, variant: SnackbarVariant.success });
      } else {
        setFieldSavingStatus({ isOpen: true, key: agentId, variant: SnackbarVariant.error });
      }
    },
    [agentId, domainId, updateAgentGroup]
  );

  return (
    <ThemeProvider theme={theme}>
      <CustomButton disableRipple className='noSpace label-badge' onClick={handleClick} variant={ButtonVariant.text}>
        <StyledGroupName>{ccqNames[0]}</StyledGroupName>
        {showBadge && <StyledGroupBadge className='groupBadge'>{`${selectedGroups.length - 1}+`}</StyledGroupBadge>}
      </CustomButton>
      <Dropdown menuArrow open={open} anchorEl={anchorEl} onClose={handleCloseDropdown}>
        <EditableDropdown
          inputIcon={<SearchIcon />}
          placeholder={AGENT_GROUP_SEARCH_PLACEHOLDER}
          editMode={editMode}
          onChangeMode={handleChangeMode}
          onlyUnselected
          items={allGroupOptions}
          className='wrap chips'
          selectedItems={selectedGroups}
          onAddItem={handleAddItem}
          onRemoveItem={handleRemoveItem}
        />
      </Dropdown>
      <CustomSnackbar
        keyId={agentId}
        isOpen={fieldSavingStatus.isOpen}
        variant={fieldSavingStatus.variant}
        regularText={savingIndicator[fieldSavingStatus.variant].regularText}
        highlightedText={savingIndicator[fieldSavingStatus.variant].highlightedText}
        onClose={handleCloseSnackbar}
      />
    </ThemeProvider>
  );
};

export default memo(ManageAgentGroups);
