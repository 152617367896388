import { Icon, IconProps } from './icon';

export const ArrowDropDown = ({...props}: IconProps) => (
  <Icon 
    viewBox={`0 0 ${props.size} ${props.size}`}
    fill="currentColor"
    {...props}
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M7 10L12 15L17 10H7Z" fill="#181D24"/>
  </Icon>
);

