import { Route, Routes } from "react-router-dom";
import { PrivateRoute } from "../private-route";
import { useEffect } from "react";
import { DigitalAgentPage } from "./digital-agent-page/digital-agent-page";

export function DigitalAgentRouter() {
  
  useEffect(() => {
    // Open a new blank window and render an iframe in it
    window.open('/digital-agent-message', '_blank');
  }, []);

  return (
    <Routes>
      <Route path='*' element={
        <PrivateRoute><DigitalAgentPage /></PrivateRoute>
      }/>
    </Routes>
  )
}