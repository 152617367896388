import { DialogStateReturn } from 'reakit';
import { Callee, JobTitle } from '../../../api/dialers';
import { Dialog } from '../../../design/dialog/dialog';
import { HorizontalPartition } from '../../../design/partition/horizontal-partition';
import { Input } from '../../../design/form/input/input';
import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { Button } from '../../../design/button';
import { API } from 'aws-amplify';
import { Table } from '../../../design/table/table';
import styles from './dialogs.module.scss';
import { ErrorDialog } from '../../../design/dialog/error-dialog/error-dialog';
import { Column } from '../../../design/table/types';
import { ButtonWithDropdown } from '../../../design/button-with-dropdown/button-with-dropdown';
import { MoreHorizontalIcon } from '../../../design/icons';
import { JobTitleActionsMenu } from '../JobTitleActionsMenu';
import { dialogTexts } from '../../utils/texts';
import { getProcessedValue, isSameValue } from '../../utils/utils';

const INITIAL_STATE: JobTitle = { job_title_id: 0, description: '' };

interface JobTitlesDialogProps {
  callees: Callee[] | undefined;
  dialog: DialogStateReturn;
  jobTitlesLoading: boolean;
  jobTitleMutate: () => void;
  jobTitles: JobTitle[] | undefined;
}

export const JobTitlesDialog = ({
  dialog,
  callees,
  jobTitles,
  jobTitleMutate,
  jobTitlesLoading,
}: JobTitlesDialogProps) => {
  const [role, setRole] = useState<JobTitle>(INITIAL_STATE);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  const editModeRef = useRef<boolean>(false);
  const inputRoleRef = useRef<HTMLInputElement>(null);

  const {
    ROLE_TEXT,
    CANCEL_LABEL,
    CONFIRM_LABEL,
    CODE_ROLE_TEXT,
    CREATE_ROLE_TEXT,
    UPDATE_ROLE_TEXT,
    ROLE_DIALOG_TITLE,
    ADD_NEW_ROLE_TEXT,
    EDIT_EXISTING_ROLE_TEXT,
    FAILED_TO_SAVE_MESSAGE,
    ROLE_EMPTY_ERROR_MESSAGE,
    BAD_REQUEST_MAIN_MESSAGE,
    ROLE_DELETE_ERROR_MESSAGE,
    ROLE_ALREADY_EXISTS_ERROR_MESSAGE,
  } = dialogTexts;

  const labelText = editModeRef.current ? EDIT_EXISTING_ROLE_TEXT : ADD_NEW_ROLE_TEXT;
  const buttonText = editModeRef.current ? UPDATE_ROLE_TEXT : CREATE_ROLE_TEXT;

  const getRoleData = (job: JobTitle) => {
    const { job_title_id, description } = job;
    return {
      job_title_id,
      description: description,
      id: String(job_title_id),
      menu: (
        <ButtonWithDropdown
          menuClassName={styles.dropdownMenu}
          className={styles.dropDownWrapper}
          label={<MoreHorizontalIcon size={24} />}
          buttonClassName={styles.jobTitleActions}
          collapseOnClickMenu
          isLocateRelatively
        >
          <JobTitleActionsMenu
            onEditJobTitle={() => handleClickEditMode(job)}
            onDeleteJobTitle={() => handleClickDeleteRole(job)}
          />
        </ButtonWithDropdown>
      ),
    };
  };

  const tableData = (jobTitles || []).map((job) => getRoleData(job));

  const handleClose = () => {
    editModeRef.current = false;
    setRole(INITIAL_STATE);
    setErrorMsg(undefined);
  };

  const generateRoleId = useCallback((): number => {
    const max =
      jobTitles
        ?.map((job) => job.job_title_id)
        ?.reduce((acc, curr) => {
          return curr > acc ? curr : acc;
        }, 0) || 3000;
    return parseInt(`${max! + 1}`);
  }, [jobTitles]);

  const newGeneratedId = useMemo(() => generateRoleId(), [generateRoleId]);

  const handleClickEditMode = (selectedJob: JobTitle) => {
    setRole(selectedJob);
    editModeRef.current = true;
    inputRoleRef.current?.focus();
  };

  const handleCancelEditMode = () => {
    setRole(INITIAL_STATE);
    editModeRef.current = false;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { job_title_id } = role;
    const newTitle = e.target.value;

    if (editModeRef.current || (job_title_id !== 0 && newTitle.length)) {
      setRole({ job_title_id, description: newTitle });
      return;
    }
    if (newTitle.length && job_title_id === 0) {
      setRole({ job_title_id: newGeneratedId, description: newTitle });
      return;
    }
    if (newTitle.length === 0 && job_title_id !== 0) {
      setRole({ job_title_id: 0, description: newTitle });
    }
  };

  const handleSaveRole = () => {
    if (editModeRef.current) {
      handleUpdateRole();
    } else {
      handleCreateRole();
    }
  };

  const handleCreateRole = async () => {
    if (role.description.length === 0) {
      setErrorMsg(BAD_REQUEST_MAIN_MESSAGE);
      return;
    }

    try {
      setLoading(true);
      await API.post(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, '/job-title', {
        body: role,
      });
      jobTitleMutate();
    } catch (err: any) {
      setErrorMsg(FAILED_TO_SAVE_MESSAGE);
      console.error(err.response);
    } finally {
      setRole({ job_title_id: 0, description: '' });
      setLoading(false);
    }
  };

  const handleUpdateRole = async () => {
    const { job_title_id: submittedId, description: submittedTitle } = role;
    const processedTitle = getProcessedValue(submittedTitle);

    const empty = !processedTitle.length;
    const alreadyExist = jobTitles?.some((job) => isSameValue(job.description, processedTitle));
    const sameValue = jobTitles?.some(
      (job) => isSameValue(job.description, processedTitle) && job.job_title_id === submittedId
    );

    if (sameValue) {
      editModeRef.current = false;
      setRole(INITIAL_STATE);
      return;
    }
    if (empty) {
      setErrorMsg(ROLE_EMPTY_ERROR_MESSAGE);
      return;
    }
    if (alreadyExist) {
      setErrorMsg(ROLE_ALREADY_EXISTS_ERROR_MESSAGE);
      return;
    }

    try {
      setLoading(true);
      const job_title = submittedTitle.trim();
      await API.put(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, `/job-title?job_title_id=${submittedId}`, {
        body: { job_title_id: submittedId, description: job_title },
      });
      jobTitleMutate();
    } catch (err: any) {
      setErrorMsg(FAILED_TO_SAVE_MESSAGE);
      console.error(err.response?.status);
    } finally {
      setLoading(false);
      editModeRef.current = false;
      setRole(INITIAL_STATE);
    }
  };

  const handleClickDeleteRole = async (job: JobTitle) => {
    const deleteJobId = job.job_title_id;
    const isRoleInUse = callees?.some((callee) => callee.job_title_id === deleteJobId);

    if (!isRoleInUse) {
      try {
        setLoading(true);
        await API.del(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, `/job-title?job_title_id=${deleteJobId}`, {
          body: { job_title_id: deleteJobId },
        });
        jobTitleMutate();
      } catch (err: any) {
        setErrorMsg(FAILED_TO_SAVE_MESSAGE);
        console.error(err.response?.status);
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMsg(ROLE_DELETE_ERROR_MESSAGE);
    }
  };

  const columns: Array<Column<typeof tableData[0], keyof typeof tableData[0]>> = [
    {
      key: 'description',
      header: ROLE_TEXT,
      className: styles.jobTitleDescription,
    },
    {
      key: 'id',
      header: CODE_ROLE_TEXT,
      className: styles.jobTitleId,
    },
    {
      key: 'menu',
      header: '',
      className: styles.actionsMenu,
    },
  ];

  if (errorMsg) {
    const secondaryMessage = BAD_REQUEST_MAIN_MESSAGE ? ROLE_EMPTY_ERROR_MESSAGE : undefined;
    return (
      <ErrorDialog dialog={dialog} mainMessage={errorMsg} secondaryMessage={secondaryMessage} onClose={handleClose} />
    );
  }

  return (
    <>
      <Dialog<JobTitle>
        {...dialog}
        variant='message'
        header={ROLE_DIALOG_TITLE}
        submitLabel={CONFIRM_LABEL}
        cancelLabel={CANCEL_LABEL}
        hideOnClickOutside={false}
        onClose={handleClose}
        aria-label={ROLE_DIALOG_TITLE}
      >
        <label>{labelText}</label>
        <section className={styles.jobTitlesDialogBody}>
          <div>
            <div className={styles.addJobTitle}>
              <div>
                <label>{ROLE_TEXT}</label>
                <Input ref={inputRoleRef} name='jobTitleDescription' onChange={handleChange} value={role.description} />
              </div>
              <div className={styles.formField}>
                <label>{CODE_ROLE_TEXT}</label>
                <Input name='jobTitleId' value={role.job_title_id} disabled />
              </div>
            </div>
          </div>
          <div className={styles.actionButtonsGroup}>
            {editModeRef.current && (
              <Button variant='ghost' loading={jobTitlesLoading} onClick={handleCancelEditMode}>
                {CANCEL_LABEL}
              </Button>
            )}
            <Button variant='primary' loading={loading} onClick={handleSaveRole}>
              {buttonText}
            </Button>
          </div>
          <HorizontalPartition />
          <Table columns={columns} data={tableData} />
        </section>
      </Dialog>
    </>
  );
};
