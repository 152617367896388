import { IndexRouteProps, LayoutRouteProps, Navigate, PathRouteProps, Route } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authenticatedState } from "./state";

export function PrivateRoute(props: PrivateRouteProps) {

  const authenticated = useRecoilValue(authenticatedState);

  if (!authenticated) {
    return <Navigate to="/auth" />;
  }

  return (
    <>
      {props.children}
    </>
  );
}

type PrivateRouteProps = PathRouteProps | LayoutRouteProps | IndexRouteProps;