import { useEffect, useState } from 'react';
import { IPracticomWebSocket } from './ipracticom-web-socket';

export function useWebSocket(domainUuid: string, userUuid: string, selectedGroups: string[]): { isConnected: boolean } {
  const [isConnected, setIsConnected] = useState<boolean>(false);

  useEffect(() => {
    // random string for unique userUuid
    const randString = String(Date.now());

    // Create a new WebSocket instance
    const websocket = new IPracticomWebSocket({
      domainUuid,
      userUuid: `${userUuid}_${randString}`,
      groupUuids: selectedGroups,
    });

    // Initialize the WebSocket connection
    websocket
      .initialize()
      .then((success) => {
        setIsConnected(success);
      })
      .catch((err) => setIsConnected(false));

    return () => {
      // Cleanup function to terminate the WebSocket when the component unmounts or dependencies change
      websocket.terminate('useWebSocket - Cleanup');
      setIsConnected(false);
    };
  }, [domainUuid, userUuid, selectedGroups]);

  return {
    isConnected,
  };
}
