import {
  AppLayout,
  AppLayoutContainer,
  AppLayoutHeader,
  AppLayoutTitle,
  useMediaQuery,
} from "../../layouts/app-layout/app-layout";
import { Page } from "../../page";
import styles from "./sms-billing-page.module.scss";
import { useBilling } from "../../api/use-billing";
import { Column, SearchLocation } from "../../design/table/types";
import { SmsBilling, SourceAppType } from "../../api/types";
import { Table } from "../../design/table/table";
import moment from "moment";
import { DateRange } from "../../design/date-range/date-range";
import { Reset } from "../../design/icons";
import { mobileQuery } from "../../theme/media";
import { DEFAULT_NUM_OF_MONTHS } from "../../calls-history/constants/constants";
import { Input } from "../../design/form/input/input";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useURLParams } from "../../app/use-url-params";
import { Search } from "../../design/search/search";
import { useRecoilState } from "recoil";
import { useMe } from "../../api/use-me";
import { Spinner } from "../../design/spinner/spinner";
import { Button } from "../../design/button";
import { exportSmsBillingReport } from "../../api/export-report";
const EXPORT = "ייצוא לאקסל";

export default function SmsBillingPage() {
  const isMobile = useMediaQuery(mobileQuery);
  const { data: user } = useMe();
  const params = useParams<any>()["*"]?.replace(/^\/|\/$/g, "");
  const url = useURLParams({ path: "/sms-billing", params });
  const { pathname } = useLocation();
  const isExact = pathname === "/sms-billing";

  const [dateRange, setDateRange] = useState({ from: url.urlParams.from, to: url.urlParams.to });
  const [fromTime, setFromTime] = useState<string | undefined>(url.urlParams.fromTime);
  const [toTime, setToTime] = useState<string | undefined>(url.urlParams.toTime);

  const [focusedInput, setFocusedInput] = useState<"startDate" | "endDate" | null>(null);
  const [searchString, setSearchString] = useState<string>("");
  const [downloading, setDownloading] = useState<boolean>(false);

  const filters = useMemo(
    () =>
      Object.fromEntries([
        ...Object.entries({ fromDate: url.urlParams.from, toDate: url.urlParams.to }),
        ["fromTime", url.urlParams.fromTime],
        ["toTime", url.urlParams.toTime],
        // ['search', searchString]
      ]),
    [url.urlParams.fromTime, url.urlParams.toTime, url.urlParams.from, url.urlParams.to]
  );

  const { data, loading } = useBilling<SmsBilling>(filters, "sms", user?.domain.id);

  useEffect(() => {
    if (isExact || !url.urlParams.from || !url.urlParams.to) {
      const range = {
        from: moment().subtract(1, "week").format("YYYY-MM-DD").toString(),
        to: moment().format("YYYY-MM-DD").toString(),
      };
      setDateRange(range);
      url.setURLParams(range);

      if (!url.urlParams.fromTime && !url.urlParams.toTime) {
        setFromTime("00:00");
        setToTime("23:59");
        url.setURLParams({ fromTime: "00:00", toTime: "23:59" });
      }
    }
  }, [
    url.urlParams.from,
    url.urlParams.to,
    url.urlParams.fromTime,
    url.urlParams.toTime,
    url.setURLParams,
  ]);

  const searchFilter = (item: SmsBilling) => {
    return (
      String(item.id || "")
        .toLowerCase()
        .includes(searchString.toLowerCase()) ||
      (item.sourceApp || "").toLowerCase().includes(searchString.toLowerCase()) ||
      (item.profileName || "").toLowerCase().includes(searchString.toLowerCase()) ||
      (item.smsFrom || "").toLowerCase().includes(searchString.toLowerCase()) ||
      (item.smsTo || "").toLowerCase().includes(searchString.toLowerCase()) ||
      (item.smsText || "").toLowerCase().includes(searchString.toLowerCase()) ||
      String(item.smsCountForBilling || "").includes(searchString.toLowerCase()) ||
      String(item.createTime || "").includes(searchString.toLowerCase()) ||
      (item.billingMonth || "").includes(searchString.toLowerCase())
    );
  };

  const handleFromTimeChange = (event: any) => {
    if (!event.target.value) return;
    setFromTime(event.target.value);
  };

  const handleToTimeChange = (event: any) => {
    if (!event.target.value) return;
    setToTime(event.target.value);
  };

  const filteredData = data.filter(searchFilter);
  const dataForTable = filteredData.map((billingItem) => ({
    ...billingItem,
    id: `${billingItem.id}`,
    sourceApp: getSourceApp(billingItem.sourceApp),
    billingMonth: billingItem.billingMonth || "*2024-02",
  }));

  const columns: Array<Column<typeof dataForTable[0], keyof typeof dataForTable[0]>> = [
    {
      key: "id",
      header: "מזהה",
      className: styles.billingId,
    },
    {
      key: "sourceApp",
      header: "תוכנה שולחת",
      className: styles.sourceApp,
    },
    {
      key: "profileName",
      header: "שם פרופיל",
      className: styles.profileName,
    },
    {
      key: "smsFrom",
      header: "מקור",
      className: styles.smsFrom,
    },
    {
      key: "smsTo",
      header: "מספר יעד",
      className: styles.smsTo,
    },
    {
      key: "smsText",
      header: "נוסח שנשלח",
      className: styles.smsText,
    },
    {
      key: "smsCountForBilling",
      header: "יחידות חיוב",
      className: styles.smsCountForBilling,
    },
    {
      key: "createTime",
      header: "זמן",
      className: styles.createTime,
      sort: (a, b) => (a.createTime > b.createTime ? 1 : -1),
      render(row) {
        return (
          <div>
            <span>
              {moment(row.createTime).format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")
                ? "היום"
                : moment(row.createTime).isBetween(moment().subtract(7, "d"), moment())
                ? moment(row.createTime).format("dddd")
                : moment(row.createTime).format("DD.MM.YYYY")}
            </span>{" "}
            &nbsp;
            <span>{moment(row.createTime).format("HH:mm")}</span>
          </div>
        );
      },
    },
    {
      key: "billingMonth",
      header: "תאריך חיוב",
      className: styles.billingMonth,
    },
  ];

  const totalSmsCount = filteredData.length;
  const totalSmsBillingUnits = filteredData.reduce((acc, curr) => acc + curr.smsCountForBilling, 0);
  const TOTAL_SMS_TEXT = `סה"כ הודעות סמס שנשלחו: ${totalSmsCount}`;
  const TOTAL_BILLING_UNITS_TEXT = `סה"כ יחידות חיוב: ${totalSmsBillingUnits}`;

  const handleExportRequest = async () => {
    try {
      setDownloading(true);
      await exportSmsBillingReport(filters);
    } catch (error) {
      console.error(error);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <Page name='Sms Billing'>
      <AppLayout>
        <AppLayoutContainer>
          <AppLayoutHeader className={styles.headerContainer}>
            <AppLayoutTitle className={styles.title}>
              פירוט חיובים - סמס
              <Button
                loading={downloading}
                variant='link'
                className={styles.exportToExcelBtn}
                onClick={handleExportRequest}
              >
                {EXPORT}
              </Button>
            </AppLayoutTitle>

            {totalSmsCount + totalSmsBillingUnits ? (
              <b>
                {TOTAL_SMS_TEXT}
                <br />
                {TOTAL_BILLING_UNITS_TEXT}
              </b>
            ) : (
              <b></b>
            )}
          </AppLayoutHeader>

          <section className={styles.searchSection}>
            <Search
              className={styles.search}
              placeholder='חיפוש...'
              onChange={(e) => setSearchString(e.target.value)}
            />
            <div className={styles.dateSelector}>
              <DateRange
                keepFocusOnInput={true}
                customArrowIcon={<Reset size={16} />}
                displayFormat='DD MMMM'
                numberOfMonths={isMobile ? 1 : DEFAULT_NUM_OF_MONTHS}
                keepOpenOnDateSelect
                noBorder
                renderCalendarInfo={() => (
                  <div className={styles.dateRangeFooter}>
                    <div className={styles.timeFilters}>
                      <span className={styles.fromTimeSelector}>
                        <Input value={fromTime} onChange={handleFromTimeChange} type='time' />
                      </span>
                      <span className={styles.toTimeSelector}>
                        <Input value={toTime} onChange={handleToTimeChange} type='time' />
                      </span>
                    </div>
                    <button
                      type='button'
                      disabled={!dateRange.from || !dateRange.to}
                      className={classNames(styles.filterFormButton, styles.primary)}
                      onClick={() => {
                        setFocusedInput(null);
                        url.setURLParams({ fromTime, toTime, ...dateRange });
                      }}
                      style={{
                        marginBlockEnd: "1rem",
                        marginInlineEnd: isMobile ? "" : "1.5rem",
                        fontSize: isMobile ? "0.875rem" : "",
                      }}
                    >
                      עדכון
                    </button>
                  </div>
                )}
                showDefaultInputIcon
                minimumNights={0}
                startDate={moment(dateRange.from)}
                startDatePlaceholderText='מתאריך'
                startDateId='smsBillingReportStartDate'
                endDate={moment(dateRange.to)}
                endDatePlaceholderText='עד תאריך'
                endDateId='smsBillingReportEndDate'
                focusedInput={focusedInput || null}
                onFocusChange={(input) => setFocusedInput(input)}
                isOutsideRange={(day) => {
                  return day.startOf("day").isAfter(moment());
                }}
                onDatesChange={({ startDate, endDate }) => {
                  setDateRange({
                    from: startDate?.format("YYYY-MM-DD").toString(),
                    to:
                      endDate?.format("YYYY-MM-DD").toString() ||
                      startDate?.format("YYYY-MM-DD").toString(),
                  });
                }}
              />
            </div>
          </section>

          {loading ? (
            <Spinner />
          ) : (
            <Table showHeaders={true} data={dataForTable} columns={columns} />
          )}
        </AppLayoutContainer>
      </AppLayout>
    </Page>
  );
}

function getSourceApp(src: SourceAppType): string {
  switch (src) {
    case "Dialplan":
      return "מרכזייה";
    case "PractiDialer":
      return "חייגן";
    default:
      return "מקור לא מוכר";
  }
}
