import { SelectHTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './select.module.scss';

export const Select = ({ className, ...props }: SelectHTMLAttributes<HTMLSelectElement>) => {

  return (
    <select className={classNames(styles.root, className)} {...props} />
  );
}