import { Icon, IconProps } from './icon';

export const ChevronDown = ({...props}: IconProps) => (
  <Icon 
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <path d="M16.59 8.59003L12 13.17L7.41 8.59003L6 10L12 16L18 10L16.59 8.59003Z"></path>
  </Icon>
);
