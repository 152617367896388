import React, { useState } from 'react';
import { useDestinations } from '../../../api/use-destinations';
import { useMe } from '../../../api/use-me';
import styles from './business-hours.module.scss';

export function BusinessHours({ activeActionDestination, timeCondition, onChange }: BusinessHoursProps) {
  const user = useMe();
  const destinations = useDestinations(user?.data);
  const [activeConditionId, setActiveConditionId] = useState<string>(timeCondition.conditionalActions[0]?.id);

  const handleChange = (condition: Condition) => {
    setActiveConditionId(condition.id);
    if (onChange) {
      onChange(condition);
    }
  }

  if (!destinations.data) {
    <div>טוען...</div>;
  }

  return (
    <ul className={styles.businessHours}>
      {
        timeCondition.conditionalActions.map((condition) => {
          if (condition.action.type !== "transfer") {
            return null;
          }

          const dayRange = condition.range.weekday
            ? `${weekdayToString(condition.range.weekday[0])}${condition.range.weekday[1] !== condition.range.weekday[0] ? `-${weekdayToString(condition.range.weekday[1])}` : ''}`
            : null;
          const timeRange = condition.range.minuteOfDay
            ? `${minuteOfDayToString(condition.range.minuteOfDay[0])}–${minuteOfDayToString(condition.range.minuteOfDay[1])}`
            : null;

          const destination = condition.action.destination;

          return (
            <li key={`${timeCondition.id}-${condition.priority}`}>
              <label className={styles.condition}>
                <input
                  type="radio"
                  name="condition"
                  className={styles.radio}
                  value={condition.priority}
                  onChange={() => handleChange(condition)}
                  checked={activeConditionId === condition.id}
                />
                <div className={styles.item}>
                  <div className={styles.range}>
                    {dayRange && <span className={styles.dayRange}>{dayRange}</span>}
                    {timeRange && <span className={styles.timeRange}>{timeRange}</span>}
                  </div>
                  {destinations.data && destinations.data[destination] && (
                    <div className={styles.destination}>
                      <span className={styles.destinationType}>{destinationTypeToHebrew(destinations.data[destination].type)}</span>
                      <span className={styles.destinationInfo}>{destinations.data[destination].name} ({destinations.data[destination].number})</span>
                    </div>
                  )}
                </div>
              </label>
            </li>
          )
        })
      }
      <li>
        <label className={styles.condition}>
          <input
            type="radio"
            name="condition"
            className={styles.radio}
            value="closed"
            onChange={e => handleChange(timeCondition.defaultAction as unknown as Condition)}
          />
          <div className={styles.item}>
            <div className={styles.range}>
              <span className={styles.dayRange}>מחוץ לשעות הפעילות</span>
            </div>
            {destinations.data && destinations.data[timeCondition.defaultAction.destination] && (
              <div className={styles.destination}>
                <span className={styles.destinationInfo}>{destinations.data[timeCondition.defaultAction.destination].name}</span>
              </div>
            )}
          </div>
        </label>
      </li>
    </ul>
  );
}

function weekdayToString(weekday: number) {
  switch (weekday) {
    case 0: return 'ראשון';
    case 1: return 'שני';
    case 2: return 'שלישי';
    case 3: return 'רביעי';
    case 4: return 'חמישי';
    case 5: return 'שישי';
    case 6: return 'שבת';
    default: return '';
  }
}

function destinationTypeToHebrew(type: string) {
  switch (type) {
    case 'IVR': return 'תפריט קולי';
    case 'TimeCondition': return 'שעות פעילות';
    case 'Extension': return 'שלוחה';
    case 'Group': return 'קבוצה';
    default: return '';
  }
}

function minuteOfDayToString(minuteOfDay: number) {
  const hour = Math.floor(minuteOfDay / 60);
  const minute = Math.round(minuteOfDay % 60);

  return `${hour < 10 ? '0' : ''}${hour || '0'}:${minute < 10 ? '0' : ''}${minute || '0'}`
}

interface BusinessHoursProps {
  timeCondition: TimeConditionResource;
  onChange?: (condition: Condition) => void;
  activeActionDestination?: string;
}

export interface TimeConditionResource {
  id: string;
  name: string;
  number: string;
  conditionalActions: Condition[];
  defaultAction: Action;
}

interface Condition {
  id: string;
  priority: number;
  range: Range;
  action: Action;
}

interface Range {
  weekday?: [number, number] // 0-5
  minuteOfDay?: [number, number],
}

interface Action {
  type: "transfer";
  [key: string]: any;
}