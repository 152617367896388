import { GroupResource } from '../../api/types';

export function toGroupNumbersArray(groupUuids: string[], groups: GroupResource[]): string[] {

  // filter out empty group uuid's or unknown group uuid's
  const goodGroupUuids = groupUuids.filter(groupUuid =>
    groupUuid !== '' && // not empty
    groups.find(g => g.id === groupUuid) !== undefined // and found within the domain's groups
  );

  if (goodGroupUuids.length === 0) {
    return [];
  }

  return goodGroupUuids.map((uuid) => {
    const group = groups.find((g) => g.id === uuid);
    if (!group) {
      throw new Error(`toGroupNumbers()::Group not found (${uuid})`);
    }
    if (!group.number) {
      throw new Error(`Group (${uuid}) has no number`);
    }
    return group.number;
  });
}

export function toGroupUuidsArray(groupNumbers: string[], groups: GroupResource[]): string[] {

  // filter out empty group numbers or unknown group numbers
  const goodGroupNumbers = groupNumbers.filter(groupNumberStr => 
    groupNumberStr !== "" && // not empty
    groups.find(g => g.number === groupNumberStr) !== undefined // and found within the domain's groups
  );

  if (goodGroupNumbers.length === 0) {
    return [];
  }

  return goodGroupNumbers.map((numberStr) => {
    const group = groups.find((g) => g.number === numberStr);
    if (!group) {
      throw new Error(`toGroupUuids()::Group not found (${numberStr})`);
    }
    return group.id;
  });
}
