import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import { AutoSuggestInput } from "../../design/form/input/auto-suggest-input";
import { ArrowLeft } from "../../design/icons";
import { Spinner } from "../../design/spinner/spinner";
import useDomains, { DomainAttributes } from "../../api/use-domains";
import styles from "./domain-search-input.module.scss";

export function DomainSearchInput({ onSelect }: DomainSearchInputProps) {
  const [searchValue, setSearchValue] = useState<string>("");
  const domains = useDomains(searchValue);
  const suggestions = domains.domains.sort();
  const loading = searchValue.length > 0 && domains.loading;
  const error = domains.error;
  const empty = !domains.error && suggestions.length === 0 && searchValue.length > 0;

  const inputProps = {
    onChange: (e: any, autosuggestParams: any) => {
      if (["click", "enter"].includes(autosuggestParams.method)) {
        const domain = domains.domains.find(
          (domain) => domain.id === autosuggestParams.newValue
        ) as DomainAttributes;
        onSelect(domain);
      }
    },
    placeholder: "שם הלקוח או מספר דומיין",
    value: searchValue,
  };

  return (
    <>
      <Autosuggest
        suggestions={searchValue.length > 0 ? suggestions : []}
        renderSuggestion={Suggestion}
        getSuggestionValue={(domain) => domain.id}
        onSuggestionsFetchRequested={({ value }) => setSearchValue(value)}
        onSuggestionsClearRequested={() => setSearchValue("")}
        renderInputComponent={AutoSuggestInput}
        inputProps={inputProps}
        theme={styles}
      />
      {error && <div style={{ padding: "1rem .5rem" }}>שגיאה - נסה/י שוב מאוחר יותר</div>}
      {loading && (
        <div style={{ padding: "1rem .25rem" }}>
          <Spinner />
        </div>
      )}
      {!loading && empty && <div style={{ padding: "1rem .5rem" }}>אין תוצאות</div>}
    </>
  );
}

function Suggestion(domain: DomainAttributes) {
  const shortDomainName = domain.name.split(".")[0];
  return (
    <div className={styles.suggestionContent}>
      <div>
        {shortDomainName} - {domain.ownerName}
      </div>
      <ArrowLeft size={24} className={styles.suggestionContentArrow} />
    </div>
  );
}

export interface DomainSearchInputProps {
  onSelect: (domain: DomainAttributes) => void;
}
