import * as yup from "yup";
import { useEffect, useState } from "react";
import { Checkbox } from "../../../design/checkbox/checkbox";
import { Field } from "../../../design/form/field";
import { Input } from "../../../design/form/input/input";
import styles from "./event-form.module.scss";
import { GeneralError } from "../../../design/general-error/general-error";
import { AudioFileField } from "../../../design/form/audio-file-field/audio-file-field";
import { AudioFileInput } from "../../../design/form/audio-file-input/audio-file-input";
import { DateInput } from "../../../design/form/date-input/date-input";
import moment from "moment";
import { DateField } from "../../../design/form/field/date-field";
import { Select } from "../../../design/form/select";
import { useFormikContext } from "formik";

export function EventForm({ error, closedMessageUrl }: EventFormProps) {
  const { values, initialValues, setFieldValue } = useFormikContext<EventFormValues>();
  const [closed, setClosed] = useState(initialValues.closed);
  const endDateGreaterMessage = "תאריך סיום האירוע חייב להיות מאוחר או שווה לתאריך תחילת האירוע";
  const eventExceedsLengthMessage = "משך אירוע חייב להיות לכל היותר 30 ימים";

  useEffect(() => {
    setClosed(initialValues.closed);
  }, [initialValues.closed]);

  return (
    <>
      <fieldset className={styles.fieldset}>
        <Field name='id'>
          <Input type='hidden' />
        </Field>
        <Field name='name' label='שם האירוע'>
          <Input />
        </Field>
        <div className={styles.range}>
          <DateField name='startDate' label='מתאריך'>
            <DateInput
              calendarDialogTitle='תאריך תחילת האירוע'
              dateToDisplayValue={(date) => moment(date).format("DD/MM/YYYY")}
            />
          </DateField>
          <DateField name='endDate' label='עד תאריך'>
            <DateInput
              calendarDialogTitle='תאריך סיום האירוע'
              dateToDisplayValue={(date) => moment(date).format("DD/MM/YYYY")}
            />
          </DateField>
        </div>
        <Field name='closed' onChange={(e) => setClosed(e.target.checked)}>
          <Checkbox
            title='סגורים כל היום'
            // defaultChecked={initialValues.closed}
          />
        </Field>
      </fieldset>
      {!closed && (
        <fieldset className={styles.fieldset}>
          <header className={styles.legend}>שעות פתיחה</header>
          <div className={styles.range}>
            <Field name='openAt' label='משעה'>
              <Input type='time' />
            </Field>
            <Field name='closeAt' label='עד שעה'>
              <Input type='time' />
            </Field>
          </div>
        </fieldset>
      )}
      <div className={styles.fieldset}>
        <header className={styles.legend}>הקלטת סגור</header>
        <Field name='recording' label='הקלטה לחג'>
          <Select>
            <option></option>
            <option value='rosh-hashanah'>ראש השנה</option>
            <option value='kippur'>יום כיפור</option>
            <option value='sukkot'>סוכות</option>
            <option value='passover'>פסח</option>
            <option value='independence-day'>יום העצמאות</option>
            <option value='shavuot'>חג שבועות</option>
            <option value='closed'>סגור גנרי</option>
          </Select>
        </Field>
        <AudioFileField name='file' onChange={() => setFieldValue("recording", "")}>
          <AudioFileInput
            // key={values.recording || 'eventRecording'}
            label='העלאת קובץ הקלטה חדש'
            description='ניתן להעלות קבצי אודיו עד 5MB'
            placeholderFilename='הקלטה נוכחית'
            placeholderFileUrl={values.recording ? recordings[values.recording] : closedMessageUrl}
            resetButtonLabel='הסרת הקלטה'
            replaceButtonLabel='העלאת הקלטה חדשה'
          />
        </AudioFileField>
      </div>
      {moment(values.startDate) > moment(values.endDate) && (
        <GeneralError error={endDateGreaterMessage} />
      )}
      {moment(values.endDate).diff(moment(values.startDate), "days") > 30 && (
        <GeneralError error={eventExceedsLengthMessage} />
      )}
      {error && error !== endDateGreaterMessage && error !== eventExceedsLengthMessage && (
        <GeneralError error={error} />
      )}
    </>
  );
}

const recordings: { [key: string]: string } = {
  "rosh-hashanah": "https://recordings-files.s3.eu-west-1.amazonaws.com/rosh-hashanah.wav",
  kippur: "https://recordings-files.s3.eu-west-1.amazonaws.com/kippur.wav",
  sukkot: "https://recordings-files.s3.eu-west-1.amazonaws.com/sukkot.wav",
  passover: "https://recordings-files.s3.eu-west-1.amazonaws.com/passover.wav",
  "independence-day": "https://recordings-files.s3.eu-west-1.amazonaws.com/independence-day.wav",
  shavuot: "https://recordings-files.s3.eu-west-1.amazonaws.com/shavuot.wav",
  closed: "https://recordings-files.s3.eu-west-1.amazonaws.com/closed.wav",
};

const filetypeWhitelist = [
  "audio/aac",
  "audio/wav",
  "audio/mpeg",
  "audio/wav",
  "audio/ogg",
  "audio/opus",
  "audio/webm",
  "video/webm",
  "audio/m4a",
  "audio/x-m4a",
];

// const schema = yup.object({
//   name: yup
//     .string()
//     .min(3, "השם קצר מדי, לפחות 3 תווים")
//     .max(20, "השם ארוך מדי, מקסימום 20 תווים")
//     .required("שכחת למלא את שם האירוע"),
//   startDate: yup.date()
//     .required("מתי האירוע מתחיל?"),
//   endDate: yup.date()
//     .required("מתי האירוע מסתיים?"),
//   closed: yup.boolean().required(),
//   openAt: yup.string().when("closed", {
//     is: false,
//     then: yup
//       .string()
//       .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/gm, {
//         message: "הקלד/י שעה בפורמט שש:דד, למשל: 08:30",
//       })
//       .required("מתי פותחים?")
//       .typeError("מתי פותחים?"),
//     otherwise: yup.string().nullable(),
//   }),
//   closeAt: yup.string().when("closed", {
//     is: false,
//     then: yup
//       .string()
//       .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/gm, {
//         message: "וודא/י שהכנסת שעה בפורמט הנכון, למשל: 08:30",
//       })
//       .required("מתי סוגרים?")
//       .typeError("מתי סוגרים?"),
//     otherwise: yup.string().nullable(),
//   }),
//   file: yup
//     .mixed()
//     .test(
//       "FILE_SIZE",
//       "גודל קובץ מירבי הוא 5MB",
//       (value) => !value || (value && value.size <= 5242880)
//     )
//     .test(
//       "FILE_FORMAT",
//       "אנחנו לא תומכים בסוג הקובץ שהעלת",
//       (value) => {
//         return !value || (value && filetypeWhitelist.includes(value.type))
//       }
//     )
//     .test('file', 'שכחת להעלות הקלטת סגור, או לבחור הקלטה גנרית', (file, context) => {
//       const { id, recording } = context.parent;
//       const fileExists = file !== null;
//       const recordingExists = Boolean(recording && recording.length > 0);
//       if (id) {
//         return true;
//       }

//       if (fileExists) {
//         return true;
//       }

//       return recordingExists;
//     }),
//   recording: yup.string()
// })

interface EventFormProps {
  error?: string;
  closedMessageUrl?: string;
}

export interface EventFormValues {
  id?: string;
  name: string;
  startDate: string;
  endDate: string;
  closed: boolean;
  openAt: string;
  closeAt: string;
  file: File | null;
  recording: string;
}
