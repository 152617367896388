import { ActionType, DumpMessage, Message } from "../../interfaces";

export enum EventType {
  AGENT = 'Agent',
  COUNTERS = 'COUNTERS',
  TIME_SERIES = 'TIME_SERIES',
  CONNECTION = 'CONNECTION',
  GROUP = 'GROUP',
  CALL = 'Call',
  DIALER_REQUEST = 'DialerRequest'
};


// Pub-sub mechanism (`event` object) should be refactored into a class
const event = {

  list: new Map<EventType, ((args: Message) => void)[]>(),

  on(eventType: EventType, eventAction: (args: Message) => void) {
    this.list.has(eventType) || this.list.set(eventType, []);
    if (this.list.get(eventType)) {
      this.list.get(eventType)!.push(eventAction);
    }
    return this;
  },

  onDump(message: DumpMessage) {
    // clear previous data
    this.emit(EventType.AGENT, { objectType: EventType.AGENT, action: ActionType.CLEAR, data: undefined });
    this.emit(EventType.CALL , { objectType: EventType.CALL , action: ActionType.CLEAR, data: undefined });
    
    // emit new data
    message?.data?.forEach(data => {
      // Extract data *array* from DumpObject
      const { objectType, objects } = data;

      // Loop over data points and emit a corresponding event
      for (const realObject of objects) {
        // Construct a Message object for each data point ([d1, d2, ..., dn])
        const newMessage: Message<any /* This module does not care for the data type */> = {
          action: ActionType.POST,
          objectType: objectType,
          data: realObject
        }
        this.emit(objectType as EventType, newMessage);
      }
    });
  },

  emit(eventType: EventType, args: Message) {
    this.list.get(eventType) &&
      this.list.get(eventType)!.forEach((cb) => {
        cb(args);
      });
  }
};

export default event;