import * as yup from 'yup';
import { useState } from 'react';
import { Form, Formik } from 'formik';
import { useDialogState } from 'reakit';
import { useNavigate, useParams } from 'react-router-dom';

import Menu from './menu/menu';
import { Page } from '../../page';
import { useMe } from '../../api/use-me';
import { getHandler } from './handle-save';
import { useCallees } from '../use-callees';
import { Button } from '../../design/button';
import { Card } from '../../design/card/card';
import { useJobTitles } from '../use-job-titles';
import { Check } from '../../design/icons/check';
import { PermissionGroup } from '../../api/types';
import { useDialGroup } from '../use-dial-groups';
import styles from './dialer-settings.module.scss';
import { useDialerEvents } from '../use-dialer-event';
import { Spinner } from '../../design/spinner/spinner';
import { useURLParams } from '../../app/use-url-params';
import EventDialog from '../dialer-dialog/event-dialog';
import { ChevronUp } from '../../design/icons/chevron-up';
import { ChevronDown } from '../../design/icons/chevron-down';
import { DialerEvent, DialerEventType } from '../../api/dialers';
import CancelEventDialog from '../dialer-dialog/cancel-event-dialog';
import { CancelDialerSection } from './admin-settings/default-settings-form';
import RadioGroup, { RadioButton } from '../../design/radio-group/radio-group';
import { HorizontalPartition } from '../../design/partition/horizontal-partition';
import { AppLayout, AppLayoutContainer } from '../../layouts/app-layout/app-layout';
import { ButtonWithDropdown } from '../../design/button-with-dropdown/button-with-dropdown';
import { DEFAULT_CALLEE_RESPONSE_OPTS } from './components/callee-response-table/callee-response-table';
import {
  CalleesSection,
  Checker,
  ConditionalInputField,
  DialGroupActivationCode,
  DialerConfigurationForm,
  DialerSettingsForm,
  EditDialGroupName,
  EditDialGroupStatus,
  LaunchersSection,
  Section,
  VisibilityProps,
} from './components';

const ALLOWED_GROUPS = [PermissionGroup.Support];
const sections: Section[] = [
  { id: '1', name: 'כללי', description: '' },
  { id: '2', name: 'קבוצת קריאה', description: 'כאן ניתן להגדיר את פעולות קבוצת הקריאה' },
  {
    id: '3',
    name: 'סוג הפעלה',
    description: 'כאן ניתן להגדיר באיזו דרך תופעל קבוצת הקריאה. ניתן לבחור בתצורה טלפונית, באמצעות סמס, או בשניהם.',
  },
  { id: '4', name: 'ביטול קריאה', description: '' },
  {
    id: '5',
    name: 'מפעילים',
    description: 'כאן ניתן לצפות בסיכום המפעילים לקבוצת הקריאה הנוכחית וכן לעבור לעריכת מפעילים',
  },
  {
    id: '6',
    name: 'כוננים',
    description:
      'סיכום כוננים מקושרים לקבוצת קריאה זו לפי קטגוריית תפקידים, ניתן ללחוץ על הקישור למעבר לציפיה ועריכה של הכוננים',
  },
  { id: '7', name: 'דוחות', description: '' },
  // { id: '8', name: 'חיובים', description: '' }
];

const dialerValidationSchema = yup.object().shape({
  business_number_for_sms: yup
    .string()
    .max(11, 'המזהה ארוך מדי')
    .min(4, 'המזהה קצר מדי')
    .matches(/^[A-Za-z]*$/g, 'אותיות אנגליות בלבד'),
});

export default function SettingsPage() {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const user = useMe();
  const params = useParams<any>()['*']?.replace(/^\/|\/$/g, '');
  const url = useURLParams({ path: `/emergency-dialer/settings/`, params });
  const dialerId = parseInt(url.urlParams?.['dialers']);

  const [selectedSection, setSelectedSection] = useState<{ id: string; name: string }>(sections[0]);
  const { data: dialGroup, loading: dialGroupLoading, mutate } = useDialGroup(dialerId);
  const dial_config =
    dialGroup?.interact_by_phone && dialGroup?.interact_by_sms
      ? 'phone_sms'
      : dialGroup?.interact_by_phone
      ? 'phone'
      : 'sms';

  /** Wait until selectedDialGroup has a value */
  if (dialGroupLoading || user.loading) {
    return <Spinner />;
  }
  if (!dialGroup) {
    return (
      <AppLayout>
        <AppLayoutContainer>
          <Spinner />
        </AppLayoutContainer>
      </AppLayout>
    );
  }

  const callee_responses = dialGroup?.callee_responses.length
    ? dialGroup?.callee_responses
    : DEFAULT_CALLEE_RESPONSE_OPTS;

  const INITIAL_VALUES = {
    /* Dialer General Section */
    name: dialGroup?.name,
    enabled: dialGroup?.enabled ? 'enabled' : 'disabled',
    launch_code: dialGroup?.launch_code,

    /* Dialer Settings Section */
    priority: dialGroup?.priority,
    cust_max_attempts: dialGroup?.cust_max_attempts,
    cust_dial_interval: dialGroup?.cust_dial_interval,
    max_event_age: dialGroup?.max_event_age,

    /* Dialer Config Section */
    dialer_config: dial_config,

    /* Cancel Type Section */
    default_event_cancel_type: dialGroup?.default_event_cancel_type,

    cust_prompt_file_url: dialGroup?.cust_prompt_file_url,
    cust_prompt_file_name: dialGroup?.cust_prompt_file_name,
    cust_cancelation_prompt_file_url: dialGroup?.cust_cancelation_prompt_file_url,
    cust_cancelation_prompt_file_name: dialGroup?.cust_cancelation_prompt_file_name,
    business_number: dialGroup?.business_number,
    business_number_for_sms: dialGroup?.business_number_for_sms,
    callee_sms_text: dialGroup.callee_sms_text,
    /* Launchers Section */
    dialer_name_file_name: dialGroup?.dialer_name_file_name,
    dialer_name_file_url: dialGroup?.dialer_name_file_url,

    /* Reports Section */
    send_realtime_emails: dialGroup?.send_realtime_emails ? 'enabled' : 'disabled',
    realtime_recipients: dialGroup?.realtime_recipients,

    send_summary_emails: dialGroup?.send_summary_emails ? 'enabled' : 'disabled',
    summary_recipients: dialGroup?.summary_recipients,

    callee_responses,

    minutes_billing_code: dialGroup?.minutes_billing_code,
    sms_billing_code: dialGroup?.sms_billing_code,
  };

  const handleOnBlur = getHandler(mutate, user.data!, dialerId);

  const handleScroll = () => {
    const MAX = Number.MAX_VALUE;
    const SOME_CONSTANT = 4; // Divide
    const sectionsHeights = sections.map((section) => {
      const el = document.getElementById(section.id)!;
      const elRect = el.getBoundingClientRect();
      return { ...section, top: elRect.top + elRect.height / SOME_CONSTANT };
    });

    const highlightedSection = sectionsHeights.reduce(
      (min, curr) => {
        if (curr.top < 0) return min;
        return curr.top < min.top ? curr : min;
      },
      { ...sections[0], top: MAX }
    );

    const { top, ...section } = highlightedSection;
    setSelectedSection(section);
  };

  return (
    <Page name='Dialer Settings Page'>
      <AppLayout className={styles.layout} sectionClassName={styles.sectionClassName} isFullScreen>
        <Menu
          sections={sections}
          selectedSection={selectedSection}
          dialerId={dialGroup.dialer_id}
          dialerName={dialGroup.name}
        />

        <Formik initialValues={INITIAL_VALUES} validationSchema={dialerValidationSchema} onSubmit={() => {}}>
          <Form
            className={styles.pageContent}
            onScroll={handleScroll}
            onChange={(e: any) => {
              const fieldType = e.target.type;
              if (fieldType !== 'text') {
                handleOnBlur(e.target.name, e.target.files?.[0] || e.target.value);
              }
            }}
            onBlur={(e: any) => {
              const fieldType = e.target.type;
              if (fieldType === 'text') {
                handleOnBlur(e.target.name, e.target.files?.[0] || e.target.value);
              }
            }}
          >
            <ActionButtons
              isAdmin={isAdmin}
              setIsAdmin={setIsAdmin}
              dialerId={dialGroup.dialer_id}
              dialerName={dialGroup.name}
            />

            <Section section={sections[0]}>
              <GeneralSettings isAdmin={isAdmin} save={handleOnBlur} />
            </Section>

            <Section section={sections[1]}>
              <DialerSettings isAdmin={isAdmin} save={handleOnBlur} />
            </Section>

            <Section section={sections[2]}>
              <DialerConfiguration isAdmin={isAdmin} save={handleOnBlur} write />
            </Section>

            <Section section={sections[3]}>
              <Card className={styles.card}>
                <CancelDialerSection />
              </Card>
            </Section>

            <Section section={sections[4]}>
              <LaunchersInfo dialerId={dialerId} />
            </Section>

            <Section section={sections[5]}>
              <CalleesInfo dialerId={dialerId} />
            </Section>

            <Section section={sections[6]}>
              <ReportsSettings isAdmin={isAdmin} />
            </Section>

            {/* <Section section={sections[7]}>
              <BillingSection isAdmin={isAdmin} />
            </Section> */}
          </Form>
        </Formik>
      </AppLayout>
    </Page>
  );
}

/* Dialer Settings Section Components */
const GeneralSettings = ({ isAdmin }: VisibilityProps) => {
  return (
    <Card className={styles.card}>
      <div className={styles.cardContent}>
        <EditDialGroupName isAdmin={isAdmin} write={true} />
        <DialGroupActivationCode />

        <HorizontalPartition />

        <EditDialGroupStatus isAdmin={isAdmin} write={true} />
      </div>
    </Card>
  );
};

const DialerSettings = ({ isAdmin }: VisibilityProps) => {
  return (
    <Card className={styles.card}>
      <DialerSettingsForm isAdmin={isAdmin} />
    </Card>
  );
};

const DialerConfiguration = ({ isAdmin, ...props }: VisibilityProps) => {
  return (
    <Card className={styles.configCard}>
      <DialerConfigurationForm isAdmin={isAdmin} save={props.save} write={props.write} />
    </Card>
  );
};

const LaunchersInfo = ({ dialerId }: { dialerId: number }) => {
  return (
    <Card className={styles.card}>
      <div className={styles.cardContent}>
        <LaunchersSection dialerId={dialerId} />
      </div>
    </Card>
  );
};

const CalleesInfo = ({ dialerId }: { dialerId: number }) => {
  const { data: callees } = useCallees();
  const { data: allJobTitles } = useJobTitles();

  const calleeList = (callees || []).filter((callee) => callee.enabled);
  const calleesByDialerId = calleeList.filter((callee) => callee.dial_groups && callee.dial_groups.includes(dialerId));

  const jobTitles = allJobTitles
    ?.filter((job) => calleesByDialerId?.some((callee) => callee.job_title_id === job.job_title_id))
    ?.map((job) => ({
      job,
      count: calleesByDialerId?.filter((c) => job.job_title_id == c.job_title_id).length || 0,
    }));

  return (
    <Card className={styles.card}>
      <CalleesSection dialerId={dialerId} jobTitles={jobTitles} />
    </Card>
  );
};

const ReportsSettings = ({ isAdmin }: VisibilityProps) => {
  const realtimeReportButtons: RadioButton[] = [
    { id: 'realtime_enabled', label: 'פעיל', value: 'enabled' },
    { id: 'realtime_disabled', label: 'לא פעיל', value: 'disabled' },
  ];
  const reportButtons: RadioButton[] = [
    { id: 'summary_enabled', label: 'פעיל', value: 'enabled' },
    { id: 'summary_disabled', label: 'לא פעיל', value: 'disabled' },
  ];

  return (
    <Card className={styles.card}>
      <div className={styles.cardContent}>
        <Checker isAdmin={isAdmin}>
          <RadioGroup
            groupId='send_realtime_emails'
            groupTitle='שליחת עדכון במייל בזמן אמת'
            radioButtons={realtimeReportButtons}
            groupTitleClassName={styles.strong}
          />
        </Checker>

        <Checker isAdmin={isAdmin}>
          <ConditionalInputField
            fieldName={'realtime_recipients'}
            fieldDescription={'יעדי שליחת עדכון בזמן אמת'}
            placeholder='mail@mail.com, lamail@mailit.co.il'
            write
            isAdmin={isAdmin}
          />
        </Checker>

        <HorizontalPartition />

        <Checker isAdmin={isAdmin}>
          <RadioGroup
            groupId='send_summary_emails'
            groupTitle='שליחת דוח פעילות מסכם'
            radioButtons={reportButtons}
            groupTitleClassName={styles.strong}
            disabled={false}
          />
        </Checker>
        <Checker isAdmin={isAdmin}>
          <ConditionalInputField
            fieldName={'summary_recipients'}
            fieldDescription={'יעדי שליחת דוח פעילות מסכם'}
            placeholder='mail@mail.com, lamail@mailit.co.il'
            write
            isAdmin={isAdmin}
          />
        </Checker>
      </div>
    </Card>
  );
};

const BillingSection = ({ isAdmin }: VisibilityProps) => {
  return (
    <Card className={styles.card}>
      <div className={styles.cardContent}>
        <Checker isAdmin={isAdmin}>
          <ConditionalInputField
            fieldName={'billing_minutes'}
            fieldDescription={'כמות דקות בחבילת דקות'}
            placeholder='02:45:09'
            isAdmin={isAdmin}
          />
        </Checker>

        <Checker isAdmin={isAdmin}>
          <ConditionalInputField
            fieldName={'billing_reminders_amount'}
            fieldDescription={'כמות תזכורות בו זמנית'}
            placeholder='#'
            isAdmin={isAdmin}
          />
        </Checker>

        <Checker isAdmin={isAdmin}>
          <ConditionalInputField
            fieldName={'billing_sms_amount'}
            fieldDescription={'כמות סמס בחבילה'}
            placeholder='#'
            isAdmin={isAdmin}
          />
        </Checker>

        <HorizontalPartition />

        <Checker isAdmin={isAdmin}>
          <ConditionalInputField
            fieldName={'minutes_billing_code'}
            fieldDescription={'קוד חיוב דקות קבוצת קריאה'}
            isAdmin={isAdmin}
          />
        </Checker>

        <Checker isAdmin={isAdmin}>
          <ConditionalInputField fieldName={'sms_billing_code'} fieldDescription={'קוד חיוב סמס'} isAdmin={isAdmin} />
        </Checker>
      </div>
    </Card>
  );
};

const ActionButtons = ({ isAdmin, setIsAdmin, dialerId, dialerName }: ActionButtonsProps) => {
  const user = useMe();
  const [eventType, setEventType] = useState<DialerEventType>('drill');
  const emitEventDialog = useDialogState();
  const { data: allEvents, mutate: mutateEvents } = useDialerEvents();
  const activeEvent = allEvents?.find((event) => event.dialer_id === dialerId && event.event_status === 'active');
  const isActive = activeEvent !== undefined;
  const isRealAdmin = ALLOWED_GROUPS.some((group) => user.data?.groups.includes(group));

  return (
    <div className={styles.topButtonsContainer}>
      {
        // This feature is currently disabled until user groups and types will be thoroughly specified and planned.
        false ? (
          <ButtonWithDropdown
            menuClassName={styles.toggleViewButton}
            buttonClassName={styles.btnWithDropdown}
            label={isAdmin ? 'מנהל מערכת' : 'משתמש'}
            expandedBadge={<ChevronUp />}
            collapsedBadge={<ChevronDown />}
          >
            <div className={styles.menuItem} onClick={() => setIsAdmin(true)}>
              מנהל מערכת {isAdmin && <Check />}
            </div>
            <div className={styles.menuItem} onClick={() => setIsAdmin(false)}>
              משתמש {!isAdmin && <Check />}
            </div>
          </ButtonWithDropdown>
        ) : (
          <div></div>
        )
      }
      {isActive ? (
        <ActiveEventButtons
          activeEvent={activeEvent}
          dialerId={dialerId}
          dialerName={dialerName}
          mutateEvents={mutateEvents}
        />
      ) : (
        <div className={styles.actionButtons}>
          <Button
            onClick={() => {
              setEventType('drill');
              emitEventDialog.show();
            }}
          >
            הפעלת תרגיל
          </Button>
          <Button
            onClick={() => {
              setEventType('emergency');
              emitEventDialog.show();
            }}
            variant='caution'
          >
            הפעלת אירוע אמת
          </Button>
          <EventDialog
            dialog={emitEventDialog}
            eventType={eventType}
            dialerId={dialerId}
            dialerName={dialerName}
            mutateEvents={mutateEvents}
          />
        </div>
      )}
    </div>
  );
};

const ActiveEventButtons = ({
  activeEvent,
  dialerId,
  dialerName,
  mutateEvents,
}: {
  activeEvent: DialerEvent;
  dialerId: number;
  dialerName: string;
  mutateEvents: () => void;
}) => {
  const navigate = useNavigate();
  const params = useParams<any>()['*']?.replace(/^\/|\/$/g, '');
  const emitEventDialog = useDialogState();
  const cancelEventDialog = useDialogState();
  const [eventType, setEventType] = useState<DialerEventType>('emergency');

  switch (activeEvent.event_type) {
    case 'emergency': {
      return (
        <div className={styles.actionButtons}>
          <Button
            variant='primary'
            onClick={() => {
              navigate(`/emergency-dialer/event/${activeEvent.event_id}`, {
                state: { urlParams: params },
              });
            }}
          >
            צפייה ביומן אירוע
          </Button>
          <Button onClick={() => cancelEventDialog.show()}>ביטול אירוע</Button>
          <CancelEventDialog dialog={cancelEventDialog} activeEvent={activeEvent} mutateEvents={mutateEvents} />
        </div>
      );
    }
    case 'drill': {
      return (
        <div className={styles.actionButtons}>
          <Button
            onClick={() => {
              cancelEventDialog.show();
            }}
          >
            ביטול תרגיל
          </Button>
          <Button
            onClick={() => {
              setEventType('emergency');
              emitEventDialog.show();
            }}
            variant='caution'
          >
            הפעלת אירוע אמת
          </Button>
          <EventDialog
            dialog={emitEventDialog}
            eventType={eventType}
            dialerId={dialerId}
            dialerName={dialerName}
            mutateEvents={mutateEvents}
          />
          <CancelEventDialog dialog={cancelEventDialog} activeEvent={activeEvent} mutateEvents={mutateEvents} />
        </div>
      );
    }
    case 'cancelation': {
      return (
        <div className={styles.actionButtons}>
          <Button
            onClick={() => {
              cancelEventDialog.show();
            }}
          >
            עצירת אירוע ביטול
          </Button>
          <Button
            onClick={() => {
              setEventType('drill');
              emitEventDialog.show();
            }}
          >
            הפעלת תרגיל
          </Button>
          <Button
            onClick={() => {
              setEventType('emergency');
              emitEventDialog.show();
            }}
            variant='caution'
          >
            הפעלת אירוע אמת
          </Button>
          <EventDialog
            dialog={emitEventDialog}
            eventType={eventType}
            dialerId={dialerId}
            dialerName={dialerName}
            mutateEvents={mutateEvents}
          />
          <CancelEventDialog dialog={cancelEventDialog} activeEvent={activeEvent} mutateEvents={mutateEvents} />
        </div>
      );
    }
  }
};

interface ActionButtonsProps {
  isAdmin: boolean;
  setIsAdmin: React.Dispatch<React.SetStateAction<boolean>>;
  dialerId: number;
  dialerName: string;
}
