import { Input } from "../form/input/input";
import { ArrowLeft, ArrowRight } from "../icons";
import styles from "./pagination.module.scss";

export const Pagination = ({
  max = 1,
  min = 1,
  onJump,
  onNext,
  onPageChange,
  onPrev,
  page,
  ...props
}: PaginationProps) => {
  return (
    <div className={styles.pagination}>
      <Input
        className={styles.jumpTo}
        type='text'
        value={page}
        onChange={(e) => {
          const value = parseInt(e.target.value);
          if (!isNaN(value) && value >= min && value <= max) {
            onPageChange(value);
            if (onJump) onJump(value);
          }
        }}
      />
      <div className={styles.separator}>מתוך</div>
      <span className={styles.total}>{max === 0 ? 1 : max}</span>
      <div className={styles.navigation}>
        <button
          disabled={page === min}
          className={styles.arrow}
          onClick={() => {
            onPageChange(page - 1);
            if (onPrev) onPrev(page - 1);
          }}
        >
          <ArrowRight size={24} />
        </button>
        <button
          disabled={page >= max}
          className={styles.arrow}
          onClick={() => {
            onPageChange(page + 1);
            if (onNext) onNext(page - 1);
          }}
        >
          <ArrowLeft size={24} />
        </button>
      </div>
    </div>
  );
};

export interface PaginationProps {
  className?: string;
  page: number;
  max: number;
  min?: number;
  onPageChange: (page: number) => void;
  onNext?: (page: number) => void;
  onPrev?: (page: number) => void;
  onJump?: (page: number) => void;
}
