import { useState } from 'react';
import { Dialog } from '../../../design/dialog/dialog';
import { DialogStateReturn } from "reakit/ts";
import { DialerRequest } from '../../types';
import { buttonTextStrings } from '../../../utils/strings/string-translator';
import { useMe } from '../../../api/use-me';
import { API } from 'aws-amplify';

export const CancelDialog = ({ dialog, requests, onConfirm }: CancelDialogProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const me = useMe(false);
  const domainName = me.data?.domain.name;

  const handleConfirm = async () => {
    try {
      setLoading(true);
      const requestSpecification = `/patch_requests?domain_name=${domainName}`;
      await API.patch(
        process.env.REACT_APP_PROCESS_DIALER_API_NAME!,
        requestSpecification,
        {
          body: {
            requests: requests.map(request => ({ dialer_id: request.dialer_id, request_uuid: request.request_uuid })),
            enabled: false
          }
        }
      )
      setLoading(false);
      onConfirm();
      dialog.hide?.();
    } catch (error: any) {
      console.error(error);
      setError(true);
    }
  }

  if (error) {
    console.error(error);
    return (
      <Dialog {...dialog}
        variant="message"
        header="לא הצלחנו לבטל את הבקשות מסיבה לא ידועה"
        onClose={() => window.location.reload()}
        submitLabel="טען/י מחדש"
        loading={loading}
        aria-label="שגיאה">
        <div>
          נסה/י לטעון מחדש. אם הבעיה חוזרת, אנא פנה/י לתמיכה הטכנית <br />
          <a href="mailto:support@ip-com.co.il">support@ip-com.co.il</a> או
          בטלפון: <a href="tel:03-657-6576">03-657-6576</a>
        </div>
      </Dialog>
    )
  }

  return domainName ? (
    <Dialog {...dialog}
      variant="caution"
      header={`ביטול בקשות שיחה חוזרת (${requests.length} בקשות סומנו)`}
      onSubmit={handleConfirm}
      submitLabel={buttonTextStrings.get("cancel-dial-requests")}
      aria-label="ביטול בקשה">
      האם תרצה לבטל את בקשות החיוג?<br />
      לאחר הביטול לא יבוצעו ניסיונות חיוג למספרים שסומנו
    </Dialog >
  ) : (<></>)
}

interface CancelDialogProps {
  dialog: DialogStateReturn,
  requests: DialerRequest[],
  onConfirm: () => void
}