import { memo } from 'react';
import { Typography } from '@mui/material';

import { FormSection } from '../types';
import { TypographyVariant } from '../../theme/muiTypes';
import { CUSTOMER_DIALING_SETTINGS_TEXTS } from '../utils/texts';
import { useFormFieldRenderer } from '../../hooks/useFormFieldRenderer';
import { CustomCard } from '../../components/common/customCard/CustomCard';
import { useCallbackFieldsConfig } from '../hooks/useCallbackFieldsConfig';
import { CustomDivider } from '../../components/common/customDivider/CustomDivider';
import { StyledCardsContainer, StyledFormContainer } from './StyledCallbackDialerSettings';

const { CUSTOMER_DIALING_TITLE, CUSTOMER_DIALING_SUB_TITLE } = CUSTOMER_DIALING_SETTINGS_TEXTS;

export const CustomerDialingSettings = memo(({ section, apiConfig }: FormSection) => {
  const {
    cust_prompt,
    cust_dial_prefix,
    cust_max_ringing,
    cust_min_duration,
    cust_max_attempts,
    cust_approval_key,
    cust_dial_interval,
  } = useCallbackFieldsConfig();
  const renderFormField = useFormFieldRenderer(apiConfig);

  return (
    <StyledFormContainer id={section.id}>
      <Typography variant={TypographyVariant.h1}>{CUSTOMER_DIALING_TITLE}</Typography>
      <Typography variant={TypographyVariant.regularText2}>{CUSTOMER_DIALING_SUB_TITLE}</Typography>
      <StyledCardsContainer className='general-settings'>
        <CustomCard className='column'>
          {renderFormField(cust_dial_prefix)}
          {renderFormField(cust_max_attempts)}
          {renderFormField(cust_dial_interval)}
          {renderFormField(cust_max_ringing)}
          {renderFormField(cust_min_duration)}
          {renderFormField(cust_approval_key)}
          <CustomDivider hidden={!cust_approval_key.uiConfig.hasPermission} />
          {renderFormField(cust_prompt)}
        </CustomCard>
      </StyledCardsContainer>
    </StyledFormContainer>
  );
});

CustomerDialingSettings.displayName = 'CustomerDialingSettings';
