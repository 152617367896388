import { useContext, useState } from 'react';
import { Dialog } from '../../design/dialog/dialog';
import { activeGroupContext } from '../active-group-context';
import { useMe } from '../../api/use-me';
import { parsePhoneNumber } from 'libphonenumber-js';
import { API } from 'aws-amplify';
import { useGroups } from '../../api/use-groups';
import mixpanel from 'mixpanel-browser';
import { safeParsePhoneNumber } from '../../utils/strings/parse-phone';

export function GroupCloseDialog(props: any) {
  const user = useMe();
  const [loading, setLoading] = useState(false);
  const [activeGroup, setActiveGroup] = useContext(activeGroupContext);
  const forwardDestination = activeGroup?.forwardDestination;
  const { mutate } = useGroups(user?.data?.domain.id);

  const handleConfirm = async () => {
    try {
      setLoading(true);
      const body = {
        number: activeGroup?.number,
        forwardDestination: activeGroup?.forwardDestination,
        forwardEnabled: false
      }
      await API.patch(
        process.env.REACT_APP_PHP_SERVICES_NAME!,
        `/crud/dom/${user?.data?.domain.id}/RingGroup/${activeGroup?.number}`,
        { body });
      mixpanel.track('Delete Forwarding Group', {
        'Destination Type': activeGroup?.name
      });
      await mutate();
      props.dialog.hide();
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const handleClose = () => {
    setActiveGroup(undefined);
  }
  const header = `ביטול העברת שיחות (${forwardDestination ? safeParsePhoneNumber(forwardDestination, "IL") : ""})`
  return activeGroup ? (
    <Dialog {...props.dialog}
      variant="caution"
      header={header}
      onSubmit={handleConfirm}
      submitLabel="ביטול העברה"
      onClose={handleClose}
      cancelLabel="להשאיר העברה"
      loading={loading}
      aria-label="ביטול העברת שיחה">
      <p>
        {`כל השיחות הנכנסות יגיעו לקבוצה ${activeGroup?.name}`}
      </p>
    </Dialog >
  ) : <></>;
}
