import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useMe } from '../api/use-me';
import { Spinner } from '../design/spinner/spinner';
import { AppLayout, AppLayoutContainer, AppLayoutHeader, AppLayoutTitle } from '../layouts/app-layout/app-layout';
import { Page } from '../page';
import { ChangeCustomerForm } from './components/change-customer-form/change-customer-form';
import { UserManagement } from './components/user-management/user-management';
import styles from './client-settings-page.module.scss';
import { activeUserContext } from './active-user-context';
import { SelfManageSettings } from './components/self-service-settings/self-manage-settings';
import { User } from '../api/use-users';
import { PermissionGroup } from '../api/types';

const ALLOWED_GROUPS = [PermissionGroup.Support];

export const ClientSettingsPage = () => {
  const user = useMe(true);
  const [reloading, setReloading] = useState(false);
  const authorized = ALLOWED_GROUPS.some((group) => user?.data?.groups.includes(group));
  const activeUserState = useState<Omit<User, 'domains'>>();

  if (user?.loading) {
    return (
      <AppLayout>
        <AppLayoutContainer>
          <Spinner />
        </AppLayoutContainer>
      </AppLayout>
    );
  }

  if (!authorized) {
    return <Navigate to='/' />;
  }

  if (reloading) {
    return (
      <AppLayout>
        <AppLayoutContainer>
          <div className={styles.reloading}>
            <h1 className={styles.reloadingTitle}>מחליף לקוח...</h1>
            <Spinner />
          </div>
        </AppLayoutContainer>
      </AppLayout>
    );
  }

  return (
    <activeUserContext.Provider value={activeUserState}>
      <Page name='ClientSettings'>
        <AppLayout>
          <AppLayoutContainer>
            <AppLayoutHeader>
              <AppLayoutTitle>הגדרות לקוח</AppLayoutTitle>
            </AppLayoutHeader>
            <div className={styles.pageWrapper}>
              <div className={styles.userSettings}>
                <ChangeCustomerForm onSubmit={() => setReloading(true)} />
                <UserManagement />
              </div>
              <div className={styles.selfManagement}>
                <SelfManageSettings />
              </div>
            </div>
          </AppLayoutContainer>
        </AppLayout>
      </Page>
    </activeUserContext.Provider>
  );
};
