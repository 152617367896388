import { DialogStateReturn } from 'reakit';
import { Dialog } from '../../../design/dialog/dialog';
import { FileUploadField } from '../../../design/form/field/file-upload-field';
import { API } from 'aws-amplify';
import { FileInput } from '../../../design/form/upload-field/file-upload-field';
import { HorizontalPartition } from '../../../design/partition/horizontal-partition';
import styles from './dialogs.module.scss';
import { Button } from '../../../design/button';
import { useState } from 'react';
import { ErrorDialog } from '../../../design/dialog/error-dialog/error-dialog';

const DIALOG_LABEL = 'טעינת רשימת מפעילים מקובץ אקסל';
const SUBMIT_LABEL = 'טעינת קובץ';
const CANCEL_LABEL = 'ביטול';
const PROMPT = 'תהליך טעינת קובץ אקסל ימחק את הרשומות הקודמות ויגדיר חדשות';
const DOWNLOAD_EXCEL_LABEL = 'הורדת תבנית אקסל';
const failedToLoadMessage = 'לא הצלחנו לטעון את הנתונים שלך מסיבה לא ידועה'

export const LoadExcelDialog = ({ dialog, launchersMutate }: LoadExcelDialogProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const handleClose = () => {
    setError(false);
    dialog.hide()
  };

  const handleSubmit = async (values: any) => {
    if (values.file) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('file', values.file);
        await API.post(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, '/launcher/load', {
          body: formData
        });
        launchersMutate();
        handleClose();
      } catch (err) {
        setError(true);
        console.error(err);
      } finally {
        setLoading(false);
      }
    } else {
      console.error('No file was selected');
    }
  }

  const handleDownloadExcel = async () => {
    const fileUrl = 'https://excel-template-files.s3.eu-west-1.amazonaws.com/Launchers.xlsx';
    window.open(fileUrl, 'download');
  }

  if (error) {
    return (
      <ErrorDialog dialog={dialog} mainMessage={failedToLoadMessage} onClose={handleClose} />
    )
  }

  return (
    <Dialog {...dialog}
      variant="form"
      header={DIALOG_LABEL}
      onSubmit={handleSubmit}
      submitLabel={SUBMIT_LABEL}
      cancelLabel={CANCEL_LABEL}
      hideOnClickOutside={false}
      onClose={handleClose}
      aria-label={DIALOG_LABEL}
      loading={loading}
    >

      <section className={styles.loadExcelSection}>
        <div className={styles.downloadExcel}>
          <div>{PROMPT}</div>
          <Button
            variant='primary'
            onClick={handleDownloadExcel}
          >
            {DOWNLOAD_EXCEL_LABEL}
          </Button>
        </div>

        <HorizontalPartition className={styles.dialogPartition} />

        <div className={styles.fileUploadField}>
          <FileUploadField name="file">
            <FileInput
              label={'העלאת רשימת מפעילים'}
            />
          </FileUploadField>
        </div>
      </section>
    </Dialog >
  );
};

interface LoadExcelDialogProps {
  launchersMutate: () => void;
  dialog: DialogStateReturn;
}