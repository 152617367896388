import { API } from "aws-amplify";
import useSWR from "swr";
import { DialerRequest, RequestsFetcherReturnType } from "./types";
import { useMe } from "../api/use-me";

export function useCallbackRequests(filters?: { [key: string]: string }) {

  const paramsWhitelist = [
    "agent_group_numbers",
    "dialer_ids",
    "from_date",
    "to_date",
    "from_age", // minutes
    "to_age",    // minutes
    "return_number", // the server removes non-digit and adds % at start and end. searches also on caller_number
    "attempts",
    "page",
    "status_per_day",
  ];

  // get only params that are present in whitelist
  const filteredParams = Object.entries(filters || {}).filter(([param]) => paramsWhitelist.includes(param));

  const requestSpecification = '/select_requests?';
  const { data: user } = useMe();
  const domainUuid = user?.domain.id;

  // reduce all params to a single string, param values are comma (,) separated and params are ampersand (&) separated
  // (eg. "domain_uuid=abc123&agent_group_numbers=400,500")
  const queryString = filteredParams.map(([param, value]) => `${param}=${value}`).join('&') + `&domain_uuid=${domainUuid}`;

  const { data, error, mutate } = useSWR(() => filters?.from_date && filters?.to_date && domainUuid ? requestSpecification + queryString : null, requestFetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  data?.data.forEach(dialerRequest => {
    if (dialerRequest.end_date) {
      dialerRequest.end_date = new Date(dialerRequest.end_date);
    }
    if (dialerRequest.start_date) {
      dialerRequest.start_date = new Date(dialerRequest.start_date);
    }
    if (dialerRequest.expire_date) {
      dialerRequest.expire_date = new Date(dialerRequest.expire_date);
    }
    if (dialerRequest.last_attempt_date) {
      dialerRequest.last_attempt_date = new Date(dialerRequest.last_attempt_date);
    }
  })

  return {
    requests: data?.data,
    meta: data?.meta,
    error: error,
    loading: !error && !data,
    mutate: mutate
  }
}

export function useCallbackRequest(uuid?: string, domainUuid?: string) {
  const queryString = uuid && domainUuid ? `domain_uuid=${domainUuid}&request_uuid=${uuid}` : undefined;
  const { data, error, isLoading } = useSWR('/select_request?' + queryString, queryString ? async (path) => {
    const res = await API.get(process.env.REACT_APP_PROCESS_DIALER_API_NAME!, path, {});
    return { data: res.payload }
  } : null);

  const request: DialerRequest = data && {
    ...data?.data,
    end_date: (data?.data.end_date ? new Date(data?.data.end_date) : undefined),
    start_date: (data?.data.start_date ? new Date(data?.data.start_date) : undefined),
    expire_date: (data?.data.expire_date ? new Date(data?.data.expire_date) : undefined),
    last_attempt_date: (data?.data.last_attempt_date ? new Date(data?.data.last_attempt_date) : undefined),
  }
  return { request: request, error, isLoading }
}

const requestFetcher = async (path: string) => {
  const res = await API.get(
    process.env.REACT_APP_PROCESS_DIALER_API_NAME!,
    path,
    {},
  );

  return ({
    data: res.payload.data,
    meta: res.payload.meta
  }) as RequestsFetcherReturnType
}
