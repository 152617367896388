import { useAudio } from "react-use";

export const AudioPlayer = ({ src, onPlay, className }: { onPlay?: () => void; src: string, className?: string }) => {
    const [audio] = useAudio({
      src,
      autoPlay: false,
      controls: true,
      className,
      onPlay,
    });
  
    return audio;
};