import { secureFetch } from '../../auth/server-auth';
import { RequestMethod } from '../../globalTypes/types';
import { getAgentSwrKey } from '../utils/getSwrKey';

export interface AgentRequestOptions {
  method: RequestMethod;
  domainId: string;
  agentId: string | number;
  groupId?: string | number;
}

const BASE_URL = process.env.REACT_APP_MONITOR_AGENT_GROUP_URL!;

export const useManageAgentGroups = () => {
  const updateAgentGroup = async ({ method, domainId, agentId, groupId }: AgentRequestOptions) => {
    const url = getAgentSwrKey({ baseURL: BASE_URL, domainId, agentId, groupId });

    let result;
    try {
      const { res } = await secureFetch(url, { method });
      result = await res.json();
    } catch (err) {
      console.error('Error on saving agent to the group: ', err);
      result = err;
    } finally {
      return result;
    }
  };

  return updateAgentGroup;
};
