import { API } from 'aws-amplify';
import { DEFAULT_DIALER, DEFAULT_DIALER_ID } from '../use-dial-groups';
import { getDefaultSettings } from '../use-global-settings';
import { UserResource } from '../../api/types';

export const getHandler = (
  mutate: () => Promise<void>,
  user: UserResource,
  dialerId?: number
): ((fieldName: string, fieldValue: any) => void) => {
  return async (fieldName: string, fieldValue: any) => {
    if (!fieldName) {
      return;
    }
    if (fieldName.startsWith('callee_responses.')) {
      /* Saving an array field is handled within the component itself (CalleeResponseTable) */
      console.debug('CalleeResponseTable component handles this situation');
      return;
    }
    let updateBody;
    try {
      let value =
        fieldValue === 'true' || fieldValue === 'enabled'
          ? true
          : fieldValue === 'false' || fieldValue === 'disabled'
          ? false
          : fieldValue;

      if (fieldName === 'dialer_config') {
        updateBody = parseDialerConfig(value);
      } else if (fieldName.includes('file')) {
        if (value) {
          const formData = new FormData();
          formData.append('file', value);
          /* Whether dialerId is defined or not, file uploads are done through the following lambda function: */
          const res = await API.post('emergencyDialerApi', `/dialer/recording${generateQueryString(fieldName)}`, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            body: formData,
          });
          const realFileUrl = res.data.recordingUrl;
          const discFileName = fieldValue.name.split('.').at(0);
          updateBody = Object.fromEntries([
            [fieldName, realFileUrl],
            [fieldName.replace('_url', '_name'), discFileName],
          ]);
        } else {
          updateBody = Object.fromEntries([
            [fieldName, null],
            [fieldName.replace('_url', '_name'), null],
          ]);
        }
      } else if (fieldName === 'business_numbers') {
        updateBody = { business_numbers: value };
      } else {
        updateBody = Object.fromEntries([[fieldName, value]]);
      }
      await API.patch(
        process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!,
        dialerId ? `/emergency-dialer?dialer_id=${dialerId}` : '/global-dialer-settings',
        {
          body: updateBody,
        }
      );
    } catch (error: any) {
      console.error(error);
      if (error.response?.status === 404 && dialerId === DEFAULT_DIALER_ID) {
        await API.post(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, `/emergency-dialer?dialer_id=${dialerId}`, {
          body: Object.assign(DEFAULT_DIALER, updateBody),
        });
      } else if (error.response?.status === 404 && !dialerId) {
        await API.post(process.env.REACT_APP_EMERGENCY_DIALER_API_NAME!, `/global-dialer-settings`, {
          body: Object.assign(getDefaultSettings(user), updateBody),
        });
      }
    } finally {
      await mutate();
    }
  };
};

const parseDialerConfig = (
  value: 'phone_sms' | 'phone' | 'sms'
): { interact_by_phone: boolean; interact_by_sms: boolean } => {
  let interact_by_phone: boolean = false;
  let interact_by_sms: boolean = false;

  if (value === 'phone_sms') {
    interact_by_phone = interact_by_sms = true;
  } else if (value === 'phone') {
    interact_by_phone = true;
    interact_by_sms = false;
  } else if (value === 'sms') {
    interact_by_phone = false;
    interact_by_sms = true;
  }
  return { interact_by_phone, interact_by_sms };
};

const generateQueryString = (fieldName: string): string => {
  return `?file_name=${fieldName.replace('_file_name', '').replace('_file_url', '')}`;
};
