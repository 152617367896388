import classNames from 'classnames';
import React, { ChangeEvent, InputHTMLAttributes, ReactChild } from 'react';
import styles from './segmented-control.module.scss';

export const SegmentedControl = ({className, ...props}: SegmentedControlProps) => (
  <div className={classNames(styles.segmentedControl, className)}>
    {/* {
      Children.map(props.children, (child, index) => {
        if(isValidElement(child)) {
          return cloneElement(child, {
            name: props.name,
            onChange: props.onChange,
            defaultChecked: props.defaultValue && props.defaultValue === child.props.value,
            checked: props.value && props.value === child.props.value,
          })
        }

      })
    } */}
    {props.children}
  </div>
);

export const SegmentedControlOption = ({children, className, ...props}: SegmentedControlOptionProps) => (
  <label className={styles.option}>
    <input
      type="radio" 
      {...props}
    />
    <div
      className={classNames(styles.content, className)}
    >
      {children}
    </div>
  </label>
);

export interface SegmentedControlProps {
  children: ReactChild | ReactChild[];
  className?: string;
  defaultValue?: string;
  name?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

export interface SegmentedControlOptionProps extends InputHTMLAttributes<HTMLInputElement> {

}