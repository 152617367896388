import React from 'react';
import { Icon, IconProps } from './icon';

export const PlayOutlined = ({...props}: IconProps) => (
  <Icon 
    viewBox="0 0 96 96"
    fill="currentColor"
    {...props}
  >
    <path fillRule="evenodd" d="M28,16 L86,48 L28,80 L28,16 Z M33.999,26.162 L33.999,69.837 L73.579,48 L33.999,26.162 Z"/>
  </Icon>
);




