import { memo } from 'react';
import { Typography } from '@mui/material';

import { FormSection } from '../types';
import { GENERAL_SETTINGS_TEXTS } from '../utils/texts';
import { TypographyVariant } from '../../theme/muiTypes';
import { useFormFieldRenderer } from '../../hooks/useFormFieldRenderer';
import { CustomCard } from '../../components/common/customCard/CustomCard';
import { useCallbackFieldsConfig } from '../hooks/useCallbackFieldsConfig';
import { CustomDivider } from '../../components/common/customDivider/CustomDivider';
import { StyledCardsContainer, StyledFormContainer } from './StyledCallbackDialerSettings';

const { GENERAL_TITLE } = GENERAL_SETTINGS_TEXTS;

export const GeneralSettings = memo(({ section, apiConfig }: FormSection) => {
  const {
    enabled,
    speaker,
    priority,
    language,
    blacklist,
    dialer_id,
    max_active,
    calls_recorded,
    max_request_age,
    business_number,
    business_number_for_sms,
    international_calls_permitted,
  } = useCallbackFieldsConfig();
  const renderFormField = useFormFieldRenderer(apiConfig);

  return (
    <StyledFormContainer id={section.id}>
      <Typography variant={TypographyVariant.h1}>{GENERAL_TITLE}</Typography>
      <StyledCardsContainer className='general-settings'>
        <CustomCard className='column'>
          {renderFormField(dialer_id)}
          <CustomDivider hidden={!dialer_id.uiConfig.hasPermission} />
          {renderFormField(enabled)}
          <CustomDivider hidden={!enabled.uiConfig.hasPermission} />
          {renderFormField(language)}
          {renderFormField(speaker)}
          <CustomDivider hidden={!speaker.uiConfig.hasPermission} />
          {renderFormField(calls_recorded)}
          <CustomDivider hidden={!calls_recorded.uiConfig.hasPermission} />
          {renderFormField(international_calls_permitted)}
          <CustomDivider hidden={!international_calls_permitted.uiConfig.hasPermission} />
          {renderFormField(blacklist)}
          <CustomDivider hidden={!blacklist.uiConfig.hasPermission} />
          {renderFormField(priority)}
          <CustomDivider hidden={!priority.uiConfig.hasPermission} />
          {renderFormField(max_active)}
          <CustomDivider hidden={!max_active.uiConfig.hasPermission} />
          {renderFormField(max_request_age)}
          <CustomDivider hidden={!max_request_age.uiConfig.hasPermission} />
          {renderFormField(business_number)}
          {renderFormField(business_number_for_sms)}
        </CustomCard>
      </StyledCardsContainer>
    </StyledFormContainer>
  );
});

GeneralSettings.displayName = 'GeneralSettings';
