import { API } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDialogState } from 'reakit';
import { useBusinessNumberResource } from '../../api/use-business-number';
import { CallForwardDialog } from '../call-forward-dialog/call-forward-dialog';
import { ClosedModeDialog } from '../closed-mode-dialog/closed-mode-dialog';
import styles from './number-action-bar.module.scss';
import { useMe } from '../../api/use-me';
import ButtonBar, { Button } from '../../design/button-bar/button-bar';

export function NumberActionBar() {

  const params = useParams<{ number: string }>();
  const user = useMe();
  const number = useBusinessNumberResource(params.number!);
  const activeRootDestination = number.data?.RootDestinationCollection
    .find(rootDestination => rootDestination.id === number.data?.activeRootDestinationId)
  const callForwardDialog = useDialogState();
  const closedModeDialog = useDialogState();
  const [loadingAction, setLoadingAction] = useState<string>();


  const handleOpenAnswer = async () => {
    try {
      setLoadingAction('open');
      const defaultDestination = number.data?.RootDestinationCollection
        .find(rootDestination => rootDestination.type === "default");

      const body = {
        destinationNumber: defaultDestination?.destinationNumber
      }
      await API.put(process.env.REACT_APP_WEB_API_NAME!, `/domains/${user?.data?.domain.id}/business-numbers/${number.data?.number}/root-destination`, { body });
      mixpanel.track('Toggle Answering', {
        'Receive Calls?': true
      })
      await number.mutate();
    } catch (error) {

    } finally {
      setLoadingAction(undefined);
    }
  }

  const handleCancelCallForward = async () => {
    try {
      setLoadingAction('cancelForwarding');
      await API.del(process.env.REACT_APP_WEB_API_NAME!, `/domains/${user?.data?.domain.id}/business-numbers/${number.data?.number}/call-forwarding`, {});
      mixpanel.track('Cancel Call Forwarding', {
        'Destination Type': 'Business Phone',
      })
      await number.mutate();
    } catch (error) {

    } finally {
      setLoadingAction(undefined);
    }
  }

  const dialogs = [
    <CallForwardDialog dialog={callForwardDialog} number={params.number} />,
    <ClosedModeDialog dialog={closedModeDialog} number={params.number} />
  ];

  const close: Button = {
    id: "close",
    className: styles.action,
    label: "סגירת מענה",
    onClick: () => closedModeDialog.show(),
  };

  const forward: Button = {
    id: "forward",
    className: styles.action,
    label: "העברת שיחות",
    onClick: () => callForwardDialog.show()
  };

  const forwardAgain = { ...forward, label: "העברת שיחות למספר אחר" };

  const openAnswer: Button = {
    id: "back2normal",
    variant: "primary",
    className: styles.action,
    loading: loadingAction === "open",
    label: "חזרה למענה רגיל",
    onClick: () => handleOpenAnswer()
  }

  const cancelForward: Button = {
    ...openAnswer,
    id: "cancelForward",
    loading: loadingAction === "cancelForwarding",
    onClick: () => handleCancelCallForward()
  };

  const buttons = {
    normal: [close, forward],
    closed: [openAnswer, forward],
    forwarded: [cancelForward, forwardAgain, close]
  }

  if (number.data?.forwardIncomingCallsTo) {
    return (
      <>
        <ButtonBar buttons={buttons.forwarded} className={styles.actionBar} />
        {dialogs.map((dialog, index) => <div key={index}>{dialog}</div>)}
      </>
    )
  }

  return activeRootDestination && activeRootDestination.type === "closed" ? (
    <>
      <ButtonBar buttons={buttons.closed} className={styles.actionBar} />
      {dialogs.map((dialog, index) => <div key={index}>{dialog}</div>)}
    </>
  ) : (
    <>
      <ButtonBar buttons={buttons.normal} className={styles.actionBar} />
      {dialogs.map((dialog, index) => <div key={index}>{dialog}</div>)}
    </>
  );
}
