import { Link } from "react-router-dom";
import { Button } from "../design/button";
import { AppLayout, AppLayoutContainer, AppLayoutHeader, AppLayoutTitle } from "../layouts/app-layout/app-layout";
import { Page } from "../page";
import styles from "./bot-list-page.module.scss";
import { BotList } from "./bot-list/bot-list";
import { v4 as uuidv4 } from 'uuid';

const TITLE = "הבוטים שלי";
const NEW_BOT_BUTTON_LABEL = "צור בוט חדש"

export const BotListPage = () => {

  return (
    <Page name="WhatsApp Bots">
      <AppLayout>
        <AppLayoutContainer>
          <AppLayoutHeader className={styles.header}>
            <AppLayoutTitle>{TITLE}</AppLayoutTitle>
            <Link to={`/whatsapp-bots/${uuidv4()}/edit`}>
              <Button variant="primary" className={styles.newBotButton}>{NEW_BOT_BUTTON_LABEL}</Button>
            </Link>
          </AppLayoutHeader>
          <BotList />
        </AppLayoutContainer>
      </AppLayout>
    </Page>
  );
}
