import { DEFAULT_DIALER } from './constants';
import { CallbackDialer } from '../../api/dialers';
import { CallbackFieldName, FieldName } from '../types';
import { FileField, FileFormat, FORMAT_TYPE_MAPPING, InputFileMode } from '../../globalTypes/types';

export const getFileFieldValue = (formFieldName: CallbackFieldName, data: CallbackDialer): FileField => {
  const dataFileName = `${formFieldName}_file_name` as FieldName;
  const dataFileUrl = `${formFieldName}_file_url` as FieldName;
  const file_name = data[dataFileName] as string;
  const file_url = data[dataFileUrl] as string;

  const idxOfLastDot = file_url.lastIndexOf('.');
  const idxOfLastSlash = file_url.lastIndexOf('/');
  const file_format = file_url.slice(idxOfLastDot + 1, idxOfLastSlash) as FileFormat;
  const file_type = FORMAT_TYPE_MAPPING[file_format];
  const hasDefaultValue = file_name === DEFAULT_DIALER[dataFileName] && file_url === DEFAULT_DIALER[dataFileUrl];
  const hasValue = file_name && file_url;
  const file_mode = hasDefaultValue || !hasValue ? InputFileMode.ADD : InputFileMode.EDIT;

  return {
    file: null,
    file_name,
    file_url,
    file_mode,
    file_type,
    file_format,
    supported_formats: [],
  };
};
