import { HTMLAttributes, useRef } from 'react';
import classNames from 'classnames';
import { DialogBackdrop, Dialog as ReakitDialog, DialogProps as ReakitDialogProps } from 'reakit/Dialog';
import { Button } from '../button';
import { ArrowRight, CloseIcon } from '../icons';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import styles from './dialog.module.scss';

const SUBMIT_LABEL = 'עדכון';
const CANCEL_LABEL = 'ביטול';
const FINISHED_LABEL = 'סיימתי';
const CLOSE_ICON_SIZE = 32;
const CLOSE_LINK_ARROW_SIZE = 16;

/**
 *  @param variant - "message" | "form" | "caution" | "info"
 *  Required. The variant will dictate the overall shape of the dialog. **Important** - if `message` is chosen, onClose will run when clicking primary button.
 *  @param header - Required. A React Component that will render as the title of the dialog.
 *  @param onClose - Optional. A callback function that will be called when the dialog is closed and when clicking the primary button in `message` variant.
 *  @param onSubmit - Optional. A callback function that will be called when clicking the primary button in the `form` and `caution` variants.
 *  @param submitLabel - Optional. A string that will appear as the label on the primary button.
 *  @param cancelLabel - Optional. A string that will appear as the label on the cancel button. Meaningless in `message`.
 *  @param initialValues - Optional. Object containing the initial values for the form, if `form` variant was chosen. Meaningless in other variants.
 *  @param validationSchema - Optional. A validation scheme in Yup format. Only has any meaning in the `form` variant.
 */
export const Dialog = <T extends Record<string, any>>({
  children,
  header,
  className,
  onClose,
  onSubmit,
  initialValues,
  validationSchema,
  submitLabel,
  cancelLabel,
  loading,
  submitBtnDisabled = false,
  ...props
}: DialogProps<T>) => {
  const formRef = useRef<FormikProps<T>>(null!);

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    if (props.hide) {
      props.hide();
    }
  };

  const saveInputs = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleSubmit = (values: T) => {
    if (onSubmit) {
      onSubmit(values);
    }
  };

  return (
    <DialogBackdrop {...props} className={classNames(className, styles.backdrop)}>
      <ReakitDialog className={classNames(className, styles.dialog)} {...props}>
        <DialogTitle>
          <CloseDialog handleClose={handleClose} />
          {header}
        </DialogTitle>
        <div className={styles.formContent}>
          <Formik
            initialValues={initialValues || ({} as T)}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            innerRef={formRef}
            enableReinitialize={true}
          >
            <Form>{children}</Form>
          </Formik>
        </div>
        {props.variant !== 'info' && (
          <DialogActions>
            <DialogFooter
              variant={props.variant}
              onClose={handleClose}
              onSubmit={saveInputs}
              loading={loading}
              submitLabel={submitLabel}
              cancelLabel={cancelLabel}
              submitBtnDisabled={submitBtnDisabled}
            />
          </DialogActions>
        )}
      </ReakitDialog>
    </DialogBackdrop>
  );
};

export function DialogTitle({ children, className, ...props }: HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h2 className={classNames(className, styles.title)} {...props}>
      {children}
    </h2>
  );
}

export function DialogActions({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={classNames(styles.actions, className)} {...props}>
      {children}
    </div>
  );
}

const CloseDialog = ({ handleClose }: { handleClose: () => void }) => (
  <>
    <Button className={styles.closeIcon} variant='ghost' onClick={handleClose}>
      <CloseIcon size={CLOSE_ICON_SIZE} />
    </Button>
    <Button variant='link' onClick={handleClose} className={styles.closeLink}>
      <ArrowRight size={CLOSE_LINK_ARROW_SIZE} />
      חזרה
    </Button>
  </>
);

const DialogFooter = ({
  variant,
  onSubmit,
  onClose,
  submitLabel,
  cancelLabel,
  loading,
  submitBtnDisabled,
}: DialogFooterProps) => {
  switch (variant) {
    case 'info':
      return <></>;
    case 'message':
      return (
        <Button variant='primary' type='reset' onClick={onClose}>
          {submitLabel || FINISHED_LABEL}
        </Button>
      );
    case 'caution':
      return (
        <>
          <Button type='reset' onClick={onClose}>
            {cancelLabel || CANCEL_LABEL}
          </Button>
          <Button type='button' loading={loading} variant='caution' onClick={onSubmit}>
            {submitLabel || SUBMIT_LABEL}
          </Button>
        </>
      );
  }

  return (
    <>
      <Button variant='ghost' onClick={onClose}>
        {cancelLabel || CANCEL_LABEL}
      </Button>
      <Button variant='primary' type='submit' disabled={submitBtnDisabled} onClick={onSubmit} loading={loading}>
        {submitLabel || SUBMIT_LABEL}
      </Button>
    </>
  );
};

interface DialogFooterProps {
  variant: DialogVariants;
  onSubmit: () => void;
  onClose: () => void;
  submitLabel?: string;
  cancelLabel?: string;
  loading?: boolean;
  submitBtnDisabled?: boolean;
}

/**
 *
 */
export interface DialogProps<T extends Record<string, any>> extends Omit<ReakitDialogProps, 'onSubmit'> {
  variant: DialogVariants;
  onClose?: () => void;
  onSubmit?: (values: T) => void;
  submitLabel?: string;
  cancelLabel?: string;
  header: React.ReactNode;
  initialValues?: T;
  validationSchema?: Yup.AnySchema;
  loading?: boolean;
  submitBtnDisabled?: boolean;
}
type DialogVariants = 'message' | 'form' | 'caution' | 'info';
