import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { MouseEvent, memo, useEffect, useState } from 'react';
import { Handle, Position, useNodeId, useStore } from 'reactflow';

import { texts } from '../utils/texts';
import { Button } from '../../design/button';
import { Plus } from '../../design/icons/plus';
import styles from './custom-node.module.scss';
import { Remove } from '../../design/icons/remove';
import { Input } from '../../design/form/input/input';
import { NodeActionsMenu } from './node-actions-menu';
import { ROOT_NODE_TEXT_LIMIT } from '../utils/constants';
import { Checkbox } from '../../design/checkbox/checkbox';
import { updateMenuStyle } from '../utils/attachmentUtils';
import { AttachmentType, MenuItem, NodeData, Option } from '../utils/types';
import { TextArea } from '../../design/form/textarea/textarea';
import { attachmentMenuConfig } from '../utils/attachmentMenuConfig';
import { CustomMenu } from '../../components/common/customMenu/CustomMenu';
import { connectionNodeIdSelector, deepCopyArray, isRoot } from '../utils/utils';
import { ButtonWithDropdown } from '../../design/button-with-dropdown/button-with-dropdown';

const { CHECKBOX_LABEL, ROOT_NODE_TITLE, ROOT_NODE_PLACEHOLDER, NODE_INPUT_PLACEHOLDER, ADD_OPTIONS_BUTTON_LABEL } =
  texts;

export interface CustomNodeProps {
  data: NodeData;
}

export const CustomNode = memo(({ data }: CustomNodeProps) => {
  const [title, setTitle] = useState<string>();
  const [options, setOptions] = useState<Option[]>([]);
  const [messageContent, setMessageContent] = useState<string>();
  const [mainMenuCheckbox, setMainMenuCheckbox] = useState<boolean>(false);
  const [anchorAttachment, setAnchorAttachment] = useState<null | HTMLElement>(null);

  const nodeId = useNodeId();
  const edges = useStore((store) => store.edges);
  const connectionNodeId = useStore(connectionNodeIdSelector);
  const isTarget = connectionNodeId && connectionNodeId !== nodeId;

  const isPortConnected = (portId: string) => {
    return edges.some((e) => e.sourceHandle === portId);
  };

  useEffect(() => {
    if (data.options) {
      setOptions(deepCopyArray(data.options));
    }
    if (data.messageContent) {
      setMessageContent(data.messageContent);
    }
    if (data.title) {
      setTitle(data.title);
    }
    if (data.mainMenuCheckbox) {
      setMainMenuCheckbox(data.mainMenuCheckbox);
    }
  }, [data]);

  const handleAddOption = () => {
    if (options.length >= 9) {
      return;
    }
    const newOptions: Option[] = deepCopyArray(options);
    newOptions.push({ portId: uuidv4(), data: '', userInput: `${newOptions.length + 1}` });
    setOptions(newOptions);
    data.options = newOptions;
  };

  const handleRemove = (portId: string) => {
    const newOptions = deepCopyArray(options)
      .filter((opt) => opt.portId !== portId)
      .map((opt, idx) => ({
        ...opt,
        userInput: `${idx + 1}`,
      }));
    setOptions(newOptions);
    data.options = newOptions;
    data.onRemoveOption(portId);
  };

  const handleEditOption = (index: number, newText: string) => {
    const newOptions = deepCopyArray(options);
    newOptions[index].data = newText;
    setOptions(newOptions);
    data.options = newOptions;
  };

  const handleEditMessageContent = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value;
    const isRootNode = isRoot(nodeId!);
    const isRootNodeLimitErr = isRootNode && newText.trim().length > ROOT_NODE_TEXT_LIMIT;
    if (!isRootNodeLimitErr) {
      setMessageContent(newText);
      data.messageContent = newText;
    }
  };

  const handleMouseEnter = (id: string) => {
    const optionInput = document.getElementById(`${id}-input`);
    const optionBtn = document.getElementById(`${id}-btn`);

    optionInput?.classList.add(styles.expanded);
    optionBtn?.classList.add(styles.expanded);
  };

  const handleMouseLeave = (id: string) => {
    const optionInput = document.getElementById(`${id}-input`);
    const optionBtn = document.getElementById(`${id}-btn`);

    optionInput?.classList.remove(styles.expanded);
    optionBtn?.classList.remove(styles.expanded);
  };

  const handleClickAttachmentMenu = (event: MouseEvent<HTMLElement>) => {
    updateMenuStyle(data);
    setAnchorAttachment(event.currentTarget);
  };

  const handleCloseAttachmentMenu = () => {
    setAnchorAttachment(null);
  };

  const handleSelectAttachmentMenuItem = (newSelectedMenuItem: MenuItem) => {
    data.onSelectAttachmentMenuItem(newSelectedMenuItem);
    setAnchorAttachment(null);
  };

  const getMenuItems = () => {
    const isRootNode = isRoot(nodeId!);
    if (!isRootNode) {
      return attachmentMenuConfig;
    }

    return attachmentMenuConfig.filter((menuItem) => menuItem.value !== AttachmentType.Location);
  };

  return (
    <div className={styles.node}>
      {!isRoot(nodeId!) && (
        /* Input port */
        <Handle
          id={nodeId + '-input'}
          type='target'
          position={Position.Right}
          style={{
            top: '14px !important',
            right: -6,
            zIndex: 1,
            backgroundColor: isTarget ? '#51D5A5' : 'white',
            transition: isTarget ? 'background-color 0.8s' : 'none',
          }}
          className={classNames(styles.portConnectable, styles.port)}
        />
      )}

      {/* Header */}
      <div className={styles.header}>
        <span style={{ marginInlineStart: '4px', fontSize: 22 }}>{isRoot(nodeId!) ? ROOT_NODE_TITLE : title}</span>
        <ButtonWithDropdown label='...' buttonClassName={styles.menuButton} isParentAbsolute={true}>
          <NodeActionsMenu
            isRoot={isRoot(nodeId!)}
            onDuplicateClick={() => data.onDuplicateNodeClick(nodeId!)}
            onRemoveClick={() => data.onRemoveNodeClick(nodeId!)}
          />
        </ButtonWithDropdown>
      </div>

      <div className={styles.body} style={{ paddingBlockEnd: `${isRoot(nodeId!) ? '20px' : '0'}` }}>
        <TextArea
          placeholder={isRoot(nodeId!) ? ROOT_NODE_PLACEHOLDER : NODE_INPUT_PLACEHOLDER}
          onChange={handleEditMessageContent}
          minRows={1}
          maxRows={999}
          className={styles.messageContent}
          value={messageContent || ''}
        />
        <div className={styles.messageActions}>
          <Button variant='icon' onClick={handleClickAttachmentMenu}>
            <AttachFileIcon fontSize='medium' />
          </Button>
          <CustomMenu
            menuArrow
            open={Boolean(anchorAttachment)}
            anchorEl={anchorAttachment}
            menuItems={getMenuItems()}
            onClose={handleCloseAttachmentMenu}
            onSelect={handleSelectAttachmentMenuItem}
          />
        </div>
        <div className={styles.optionsContainer}>
          {options?.map((option, index) => {
            const isConnected = isPortConnected(option.portId);
            return (
              <div
                /* Option container */
                className={styles.option}
                key={option.portId}
              >
                <div
                  className={styles.optionData}
                  onMouseEnter={() => handleMouseEnter(option.portId)}
                  onMouseLeave={() => handleMouseLeave(option.portId)}
                >
                  <div className={styles.rowIndex}>
                    <span className={styles.optionIndex}>{index + 1}.</span>
                    <Button
                      onClick={() => handleRemove(option.portId)}
                      id={`${option.portId}-btn`}
                      className={styles.deleteButton}
                    >
                      <Remove size={24} className={styles.deleteIcon} />
                    </Button>
                  </div>

                  <Input
                    className={styles.hadshanutInput}
                    onClick={(e) => handleMouseLeave(option.portId)}
                    id={`${option.portId}-input`}
                    type='text'
                    onChange={(e) => handleEditOption(index, e.target.value)}
                    placeholder={NODE_INPUT_PLACEHOLDER}
                    value={option.data}
                  />
                </div>

                <div className={styles.dottedLink} />

                <Handle
                  id={`${option.portId}`}
                  type='source'
                  position={Position.Left}
                  style={{ left: -8 }}
                  className={classNames(styles.port, isConnected ? styles.portActive : styles.portConnectable)}
                />
              </div>
            );
          })}
        </div>
        {/* Bottom node area */}
        {options?.length < 9 &&
          (options.length > 0 ? (
            <Button className={styles.plusButton} onClick={handleAddOption} style={{ marginBlockEnd: '16px' }}>
              <Plus size={16} />
            </Button>
          ) : (
            <span className={styles.noOptions} style={{ marginBlockEnd: '16px' }}>
              <Button className={styles.plusButton} onClick={handleAddOption}>
                <Plus size={16} />
              </Button>
              <Button variant='link' onClick={handleAddOption}>
                {ADD_OPTIONS_BUTTON_LABEL}
              </Button>
            </span>
          ))}
        {!isRoot(nodeId!) ? undefined : (
          <>
            <span className={styles.horizontalPartition} />
            <Checkbox
              onChange={() => {
                data.mainMenuCheckbox = !mainMenuCheckbox;
                setMainMenuCheckbox(!mainMenuCheckbox);
              }}
              title={CHECKBOX_LABEL}
              style={{ paddingBlockEnd: '200px' }}
            />
          </>
        )}
      </div>
    </div>
  );
});
