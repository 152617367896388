import { useReactFlow, useViewport } from "reactflow";
import ButtonBar, { Button } from "../../design/button-bar/button-bar"
import { PlayButton } from "../../design/icons/play-button";
import { Plus } from "../../design/icons/plus";
import { Save } from "../../design/icons/save";
import { API } from "aws-amplify";
import { WhatsAppBot } from "../utils/types";
import { useState } from "react";
import styles from "./button-bar.module.scss"
import { useLocation } from "react-router-dom";
import { DialogStateReturn } from "reakit";

const TEST_BOT_TOOLTIP = "בדוק את התפריט";
const SAVE_DRAFT_TOOLTIP = "שמור כטיוטה";
const ADD_OPTION_TOOLTIP = "הוסף אפשרות";
const PUBLISH_BOT_LABEL = "פרסום בוט";
const OFFSET = 100;

export const BotButtonBar = (props: BotButtonBarProps) => {

  const flowInstance = useReactFlow();
  const [saving, setSaving] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const location = useLocation();
  const [playing, setPlaying] = useState(false);
  const disabled = saving || publishing || playing;
  const { x, y, zoom } = useViewport();

  const handleSaveDraft = async () => {
    setSaving(true);
    location.state = { published: false };
    const flowObj = flowInstance.toObject();

    const bot = {
      ...flowObj,
      enabled: true,
      business_number: props.botNumber,
      name: props.botName,
      id: props.botId
    }

    try {
      if (props.isNew) {
        // Create a new bot
        await API.post(process.env.REACT_APP_WHATSAPP_BOT_API_NAME!, '/create-ivr', { body: bot });
      }
      else {
        // Existing bot, update it
        await API.put(process.env.REACT_APP_WHATSAPP_BOT_API_NAME!, '/update-ivr', { body: bot });
      }
    }
    catch (err: any) {
      console.error(err);
    }
    finally {
      await props.refresh();
      setSaving(false);
      props.onSave();
    }
  }

  const handlePublish = async () => {
    setPublishing(true);
    await handleSaveDraft();

    try {
      await API.get(process.env.REACT_APP_WHATSAPP_BOT_API_NAME!, `/publish-ivr/${props.botId}`, {});
    }
    catch (err: any) {
      console.error(err);
    }
    finally {
      await props.refresh();
      setPublishing(false);
    }
  }

  const handlePlayBot = async () => {
    setPlaying(true);
    await handleSaveDraft();
    props.playButtonDialog.show();
    setPlaying(false);
  }

  const handleRequestTemplate = async () => {
    await handleSaveDraft();
    props.requestTemplateDialog.show();
  }

  const roundButton = { disabled: disabled, className: styles.roundButton };

  const buttons: Button[] = [
    {
      id: "approve-template",
      loading: saving,
      variant: "regular",
      label: 'אישור תבנית בMeta',
      onClick: handleRequestTemplate
    },
    {
      ...roundButton,
      id: "play",
      onClick: handlePlayBot,
      title: TEST_BOT_TOOLTIP,
      label: <PlayButton size={20} />
    },
    {
      ...roundButton,
      id: "save",
      loading: saving && !publishing && !playing,
      title: SAVE_DRAFT_TOOLTIP,
      label: <Save size={20} />,
      onClick: handleSaveDraft,
    },
    {
      ...roundButton,
      id: "addNode",
      title: ADD_OPTION_TOOLTIP,
      label: <Plus size={20} />,
      onClick: () => props.onAddNode((-x + OFFSET) / zoom, (-y + OFFSET) / zoom),
    },
    {
      id: "publish",
      loading: publishing,
      variant: "primary",
      label: PUBLISH_BOT_LABEL,
      onClick: handlePublish,
    }
  ]

  return (
    <ButtonBar buttons={buttons} className={styles.buttonBar} />
  )
}

interface BotButtonBarProps {
  onAddNode: (x: number, y: number) => void;
  refresh: () => Promise<WhatsAppBot | undefined>;
  onSave: () => void;
  playButtonDialog: DialogStateReturn;
  requestTemplateDialog: DialogStateReturn;
  botName: string;
  botNumber?: string;
  botId: string;
  isNew: boolean;
}