import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppLayout, AppLayoutContainer, AppLayoutHeader, AppLayoutBreadcrumbs, AppLayoutBreadcrumbLink, AppLayoutTitle } from '../../layouts/app-layout/app-layout';
import { Page } from '../../page';
import { InvitationForm, InvitationFormValues } from '../../invitations/invitation-form';
import styles from './new-invitation-page.module.scss';

export function NewInvitationPage() {

  const navigate = useNavigate();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (values: InvitationFormValues) => {
    try {
      setLoading(true);
      const apiUrl = process.env.REACT_APP_INVITATION_API_URL;
      if(!apiUrl) {
        throw new Error('חלה שגיאה')
      }
      const response = await fetch(apiUrl, {
        method: 'POST',
        body: JSON.stringify(values)
      });
      if(!response.ok) {
        throw await response.json();
      }
      const data = await response.json();
      navigate(`/invitations/${data.id}`);
    } catch (error: any) {
      setLoading(false);
      switch(error.code) {
        case 'WrongCredentials':
          setError('שם משתמש או סיסמה לא נכונים');
          break;
        default:
          setError('לא הצלחנו ליצור הזמנה')
          break;
      }
    }
  }

  return (
    <Page name="New Invitation">
      <AppLayout>
        <AppLayoutContainer>
          <AppLayoutHeader>
            <AppLayoutBreadcrumbs>
              <AppLayoutBreadcrumbLink to="/invitations">ניהול הזמנות</AppLayoutBreadcrumbLink>
            </AppLayoutBreadcrumbs>
            <AppLayoutTitle>הזמנה חדשה</AppLayoutTitle>
          </AppLayoutHeader>
          <section className={styles.form}>
            <InvitationForm
              initialValues={{
                domainId: '',
                name: '',
                organizationName: '',
              }}
              onSubmit={handleSubmit}
              error={error}
              loading={loading}
            />
          </section>
        </AppLayoutContainer>
      </AppLayout>
    </Page>
  )
}