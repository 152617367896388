import { FieldValues } from 'react-hook-form';
import { ChangeEvent, FocusEvent } from 'react';
import { ControllerState } from '../callbacks/types';

export type DynamicField = Record<'id', string>;
export type FieldKey = keyof DynamicField;
export type FieldValue = File | string | boolean | number | string[] | null;
export type FormElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

export enum ComponentType {
  Info = 'info',
  Select = 'select',
  ReadOnly = 'readOnly',
  TextInput = 'textInput',
  DynamicField = 'dynamicField',
  RadioInput = 'radioInput',
  FileInput = 'fileInput',
}

export type SelectOption = {
  value: number | string;
  label: number | string;
};

export type RadioOption = {
  value: number | string | boolean;
  label: number | string;
};

export type FormEvent =
  | ChangeEvent<HTMLInputElement>
  | ChangeEvent<FormElement>
  | FocusEvent<HTMLInputElement, Element>
  | FocusEvent<HTMLTextAreaElement, Element>
  | FocusEvent<HTMLInputElement | HTMLTextAreaElement, FormElement>;

export type HandleFieldEvent<TFieldValues extends FieldValues> = (
  event: FormEvent,
  controllerState: ControllerState<TFieldValues>
) => void;
