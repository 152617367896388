import { Icon, IconProps } from './icon';

export const ChevronUp = ({...props}: IconProps) => (
  <Icon 
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <path d="M12 8L6 14L7.41 15.41L12 10.83L16.59 15.41L18 14L12 8Z"></path>
  </Icon>
);
