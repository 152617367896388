import { FC } from 'react';

import { StyledMenu, StyledMenuItem } from './StyledCustomMenu';
import { MenuItem } from '../../../whatsapp-bot/utils/types';

export interface CustomMenuProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  menuItems: MenuItem[];
  menuArrow?: boolean;
  onClose: () => void;
  onSelect: (menuItem: MenuItem) => void;
}

export const CustomMenu: FC<CustomMenuProps> = ({
  open,
  menuItems,
  menuArrow = false,
  anchorEl,
  onClose,
  onSelect,
}) => {
  return (
    <StyledMenu
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      menuArrow={menuArrow}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {menuItems.map((menuItem, index) => {
        const icon = menuItem.icon;
        const disabled = !!menuItem.disabled;

        return (
          <StyledMenuItem key={index} disabled={disabled} onClick={() => onSelect(menuItem)}>
            <span>{menuItem.label}</span>
            {icon ?? null}
          </StyledMenuItem>
        );
      })}
    </StyledMenu>
  );
};
