import styles from './call-list-item.module.scss';
import { Checkbox } from '../../../../../design/checkbox/checkbox';

export const Header = ({ editState, allChecked, onChange }: HeaderProps) => {

  return (
    <div className={styles.rootHeader}>
      <span className={editState ? styles.editSectionDisplay : styles.editSectionHidden} >
        <Checkbox checked={allChecked} onChange={() => onChange(!allChecked)} />
      </span>
      <span className={styles.received} style={{ color: "#181d24" }}>
        התקבלה
      </span>
      <span className={styles.phoneBack} style={{ color: "#181d24" }}>
        טלפון לחזרה
      </span>
      <span className={styles.status} style={{ color: "#181d24" }}>
        סטטוס
      </span>
      <span className={styles.waiting} style={{ color: "#181d24" }}>
        המתנה
      </span>
      <span className={styles.source} style={{ color: "#181d24" }}>
        מקור הבקשה
      </span >
      <span className={styles.group} style={{ color: "#181d24" }}>
        קבוצה
      </span>
    </div>
  )
}

interface HeaderProps {
  editState: boolean
  allChecked: boolean
  onChange: (checked: boolean) => void
}