import { API } from "aws-amplify";
import useSWR from "swr";
import { WhatsAppBot } from "../whatsapp-bot/utils/types";

const SWR_CONFIG = { revalidateOnFocus: false, revalidateOnReconnect: false };

export const useWhatsAppBots = () => {

  const { data, error, mutate } = useSWR<WhatsAppBot[]>(`/read-ivrs`, fetcher, SWR_CONFIG);

  return {
    data,
    error,
    loading: !error && !data,
    mutate
  }
}

export const useWhatsAppBot = (botUUid: string, published?: boolean) => {

  const { data, error, mutate } = useSWR<WhatsAppBot>(botUUid ? `/read-ivr/${botUUid}${published ? '?published=true' : ''}` : null, fetcher, SWR_CONFIG);

  return {
    data,
    error,
    loading: !error && !data,
    mutate
  }
}

const fetcher = async (path: string) => {
  const res = await API.get(process.env.REACT_APP_WHATSAPP_BOT_API_NAME!, path, {});
  return res;
}