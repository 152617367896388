import { useState } from 'react';
import classNames from 'classnames';
import { Navigate } from 'react-router-dom';

import { Page } from '../../../page';
import { useMe } from '../../../api/use-me';
import { getHandler } from '../handle-save';
import { Button } from '../../../design/button';
import styles from './admin-settings.module.scss';
import { PermissionGroup } from '../../../api/types';
import { Spinner } from '../../../design/spinner/spinner';
import { GlobalSettingsForm } from './global-settings-form';
import { useGlobalSettings } from '../../use-global-settings';
import ButtonBar from '../../../design/button-bar/button-bar';
import { SelectedButton } from '../../../monitor/common/icons';
import { DefaultSettingsForm, DialConfig } from './default-settings-form';
import { DEFAULT_DIALER_ID, useDefaultDialer } from '../../use-dial-groups';
import { HorizontalPartition } from '../../../design/partition/horizontal-partition';
import { AppLayout, AppLayoutContainer } from '../../../layouts/app-layout/app-layout';
import { DEFAULT_CALLEE_RESPONSE_OPTS } from '../components/callee-response-table/callee-response-table';

const ALLOWED_GROUPS = [PermissionGroup.Support];

export default function AdminSettingsPage() {
  const user = useMe();
  const authorized = ALLOWED_GROUPS.some((group) => user?.data?.groups.includes(group));
  const [currentTab, setCurrentTab] = useState<TabId>('default-settings');

  const {
    data: dialGroup,
    loading: dialGroupLoading,
    error: defaultDialerError,
    mutate: mutateDefaultSettings,
  } = useDefaultDialer();
  const {
    data: globalSettings,
    loading: globalSettingsLoading,
    error: globalDialerError,
    mutate: mutateGlobalSettings,
  } = useGlobalSettings();
  const error = defaultDialerError || globalDialerError;

  if (error) {
    return (
      <Page name='Dialer Settings Error'>
        <AppLayout>
          <div className={styles.generalErrorMessage}>
            <div className={styles.title}>לא הצלחנו לטעון את העמוד מסיבה לא ידועה</div>
            <div className={styles.content}>
              נסה/י לטעון מחדש. אם הבעיה חוזרת, אנא פנה/י לתמיכה הטכנית <br />
              <a href='mailto:support@ip-com.co.il'>support@ip-com.co.il</a> או בטלפון:{' '}
              <a href='tel:03-657-6576'>03-657-6576</a>
            </div>
            <Button className={styles.reload} onClick={() => window.location.reload()}>
              טען/י מחדש
            </Button>
          </div>
        </AppLayout>
      </Page>
    );
  }

  if (user?.loading || dialGroupLoading) {
    return (
      <AppLayout>
        <AppLayoutContainer>
          <Spinner />
        </AppLayoutContainer>
      </AppLayout>
    );
  }

  if (!authorized) {
    return <Navigate to='/' />;
  }

  if (dialGroupLoading || globalSettingsLoading) {
    return <Spinner />;
  }

  const handleSaveDefaultSettings = getHandler(mutateDefaultSettings, user.data!, DEFAULT_DIALER_ID);
  const handleSaveGlobalSettings = getHandler(mutateGlobalSettings, user.data!);

  const dial_config: DialConfig =
    dialGroup?.interact_by_phone && dialGroup?.interact_by_sms
      ? 'phone_sms'
      : dialGroup?.interact_by_phone
      ? 'phone'
      : 'sms';
  const callee_responses = dialGroup?.callee_responses?.length
    ? dialGroup?.callee_responses
    : DEFAULT_CALLEE_RESPONSE_OPTS;
  const initialDefaultValues = { ...dialGroup!, callee_responses, dialer_config: dial_config };

  const tabs: TabButton[] = [
    {
      id: 'default-settings',
      label: 'הגדרות ברירת מחדל',
      tableComponent: (
        <DefaultSettingsForm handleSave={handleSaveDefaultSettings} initialValues={initialDefaultValues} />
      ),
    },
    {
      id: 'global-settings',
      label: 'הגדרות כלליות',
      tableComponent: <GlobalSettingsForm handleSave={handleSaveGlobalSettings} initialValues={globalSettings} />,
    },
  ];

  const isSelected = (id: string) => {
    return id === currentTab;
  };

  const buttons = tabs.map((btn) => {
    const selected = isSelected(btn.id);
    return {
      id: btn.id,
      component: (
        <div className={styles.buttonContainer}>
          <div className={classNames(styles.button, { [styles.selected]: selected })}>{`${btn.label}`}</div>
          <div className={styles.selectedTabUnderline}>{selected ? <SelectedButton width={60} /> : undefined}</div>
        </div>
      ),
    };
  });

  return (
    <Page name='Dialer Settings Page'>
      <AppLayout className={styles.layout} sectionClassName={styles.sectionClassName} isFullScreen>
        <div className={styles.formContainer}>
          <div className={styles.myButtonBar}>
            <ButtonBar
              toggleBar
              className={styles.buttonBarStyle}
              onToggle={(id) => setCurrentTab(id as TabId)}
              buttons={buttons}
              selectedButtonId={currentTab || tabs[0].id}
            />
            <HorizontalPartition />
          </div>
          {tabs.map((tab) => (
            <VisibilityWrapper key={tab.id} visible={isSelected(tab.id)}>
              {tab.tableComponent}
            </VisibilityWrapper>
          ))}
        </div>
      </AppLayout>
    </Page>
  );
}

interface TabButton {
  id: TabId;
  label: string;
  tableComponent: JSX.Element;
}

type TabId = 'default-settings' | 'global-settings';

export const VisibilityWrapper = ({ children, visible }: { children: React.ReactNode; visible: boolean }) => {
  return <div style={{ display: visible ? 'block' : 'none' }}> {children} </div>;
};
