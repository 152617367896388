import React from 'react';
import { Spinner } from '../../../design/button/button';
import { BusinessNumberResource, DestinationResource } from '../../../api/types';
import { useBusinessNumberEventResource } from '../../../api/use-business-number';
import { CallflowNode, CallflowNodeContent, CallflowNodeTitle } from '../callflow-node/callflow-node';
import { DestinationNode } from '../destination-node';
import styles from './event-callflow.module.scss';
import { EventVisual } from './event-visual';

export function EventCallflow(props: EventCallflowProps) {

  const event = useBusinessNumberEventResource(props.number.number, props.eventId);

  if(event.error) {
    return <div>חלה שגיאה בטעינת מסלול השיחה</div>
  }

  if (event.data) {
    return (
      <>
        <CallflowNode className={styles.node}>
          <CallflowNodeContent>
            <EventVisual className={styles.visual} />
            <CallflowNodeTitle>{event.data.name}</CallflowNodeTitle>
            {!event.data.openDestinationNumber && 'סגור כל היום'}
          </CallflowNodeContent>
        </CallflowNode>
        <DestinationNode
          destination={event.data.openDestinationNumber ? props.rootDestination.number : event.data.closedDestinationNumber}
        />
      </>
    )
  }

  return <Spinner />;

}

export interface EventCallflowProps {
  eventId: string;
  number: BusinessNumberResource;
  rootDestination: DestinationResource;
}

