import { useState } from "react";
import { GroupResource } from "../api/types";
import { activeGroupContext } from "./active-group-context";
import { GroupsListPage } from "./groups-list-page";


export const Groups = () => {
    const activeGroupState = useState<GroupResource>()
    return (

        <activeGroupContext.Provider value={activeGroupState}>
            <GroupsListPage />
        </activeGroupContext.Provider>
    )
}
