import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, styled } from '@mui/material';

export const StyledCustomRadio = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  rowGap: theme.spacing(4),
}));

export const StyledRadioContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: theme.spacing(4),
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));

const radioProps: PropertyKey[] = ['hasPermission'];

export const StyledRadio = styled(Radio, {
  shouldForwardProp: (prop) => !radioProps.includes(prop),
})(({ theme }) => ({
  padding: 0,
  '& .MuiSvgIcon-root': {
    fill: theme.palette.grey[200], // Outer circle color
  },

  '&.Mui-checked': {
    '.MuiSvgIcon-root:nth-of-type(2)': {
      fill: theme.palette.primary.main, // Inner circle color when checked
    },
  },
}));

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  rowGap: theme.spacing(4),
}));

export const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  fontSize: theme.fontSizes.main,
  fontWeight: theme.fontWeights.semiBold,
  color: theme.texts.primary,
  lineHeight: theme.lineHeights.main,
}));

export const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  rowGap: theme.spacing(4),
}));

const formControlLabelProps: PropertyKey[] = ['hasPermission'];

export const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => !formControlLabelProps.includes(prop),
})(({ theme }) => ({
  margin: 0,
  columnGap: theme.spacing(4),
  padding: `${theme.spacing(1)} 0 ${theme.spacing(1)} ${theme.spacing(2)}`,

  '& .MuiTypography-root': {
    fontSize: theme.fontSizes.large,
    fontWeight: theme.fontWeights.regular,
    color: theme.texts.primary,
    lineHeight: theme.lineHeights.xlarge,
  },
}));
