import { Icon, IconProps } from "./icon";


export const View = ({...props}: IconProps) => (
  <Icon 
    viewBox="0 0 18 12"
    fill="currentColor"
    {...props}
  >
    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 8.8125C9.8875 8.8125 10.6406 8.50313 11.2594 7.88437C11.8781 7.26562 12.1875 6.5125 12.1875 5.625C12.1875 4.7375 11.8781 3.98438 11.2594 3.36563C10.6406 2.74688 9.8875 2.4375 9 2.4375C8.1125 2.4375 7.35938 2.74688 6.74063 3.36563C6.12188 3.98438 5.8125 4.7375 5.8125 5.625C5.8125 6.5125 6.12188 7.26562 6.74063 7.88437C7.35938 8.50313 8.1125 8.8125 9 8.8125ZM9 7.725C8.4125 7.725 7.91563 7.52188 7.50937 7.11563C7.10312 6.70937 6.9 6.2125 6.9 5.625C6.9 5.0375 7.10312 4.54063 7.50937 4.13437C7.91563 3.72812 8.4125 3.525 9 3.525C9.5875 3.525 10.0844 3.72812 10.4906 4.13437C10.8969 4.54063 11.1 5.0375 11.1 5.625C11.1 6.2125 10.8969 6.70937 10.4906 7.11563C10.0844 7.52188 9.5875 7.725 9 7.725ZM9 11.25C7.175 11.25 5.525 10.7312 4.05 9.69375C2.575 8.65625 1.475 7.3 0.75 5.625C1.475 3.95 2.575 2.59375 4.05 1.55625C5.525 0.51875 7.175 0 9 0C10.825 0 12.475 0.51875 13.95 1.55625C15.425 2.59375 16.525 3.95 17.25 5.625C16.525 7.3 15.425 8.65625 13.95 9.69375C12.475 10.7312 10.825 11.25 9 11.25ZM9 10.125C10.5125 10.125 11.9031 9.71562 13.1719 8.89688C14.4406 8.07812 15.4062 6.9875 16.0687 5.625C15.4062 4.2625 14.4406 3.17188 13.1719 2.35312C11.9031 1.53437 10.5125 1.125 9 1.125C7.4875 1.125 6.09688 1.53437 4.82812 2.35312C3.55938 3.17188 2.5875 4.2625 1.9125 5.625C2.5875 6.9875 3.55938 8.07812 4.82812 8.89688C6.09688 9.71562 7.4875 10.125 9 10.125Z" fill="#0075DB"/>
    </svg>
  </Icon>
)

