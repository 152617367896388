import { KeyedMutator } from 'swr';

import { BusinessNumberResource, UserResource } from '../../api/types';

interface BusinessNumbers {
  error: any;
  loading: boolean;
  isLoading: boolean;
  isValidating: boolean;
  data: BusinessNumberResource[] | undefined;
  mutate: KeyedMutator<BusinessNumberResource[]>;
}

export const getBusinessNumber = (businessNumbers: BusinessNumbers | undefined, user: UserResource | undefined) => {
  const selfManagedNumbers =
    businessNumbers?.data &&
    businessNumbers?.data.filter((businessNumber) =>
      user?.domain.config.selfManagedNumbers.includes(businessNumber.id)
    );

  const business_number = selfManagedNumbers && selfManagedNumbers[0] && selfManagedNumbers[0].number;

  return business_number;
};
