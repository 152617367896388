import styles from './attempt-info.module.scss';
import { CalleeResponse, DialerAttempt, DialerRequest } from '../../../../api/dialers';
import { getDigitTimeDiff, getDigitTimeFromMillis } from '../../../../utils/time/time';
import { FormattedDate } from '../../../../utils/time/formatted-date';
import classNames from 'classnames';
import { parsePhoneNumber } from 'libphonenumber-js';
import { safeParsePhoneNumber } from '../../../../utils/strings/parse-phone';

const AttemptInfo = (props: AttemptInfoProps) => {
  const { attempt, requestWaitTime, attempts, attemptNum, request, sms_interaction } = props;
  const attemptAnswered = attempt.attempt_status === "answered";
  const calleeAnsweredYes = attemptAnswered && attempt.completion_code === 'success';

  // If attempt was answered, call duration will be the time between answer and call end, if indeed ended, or now, if call is still ongoing
  // Otherwise, call duration is undefined
  const callDuration = attemptAnswered ? getDigitTimeDiff(new Date(attempt.answer_date!), new Date(attempt.end_date ?? Date.now())) : undefined;
  const THRESHOLD = (sms_interaction ? 0 : 1);

  return (
    <div className={attemptNum > THRESHOLD ? styles.progress_step : styles.progress_step__no_step}>
      <div className={styles.progress_step_marker} />
      <div className={styles.date}>
        <span className={styles.simple_label}>
          <FormattedDate dateStr={attempt.start_date.toString()} /> {new Date(attempt.start_date).toLocaleTimeString('he-IL', {hour: '2-digit', minute: '2-digit'})}
        </span>
      </div>
      <div className={attemptNum > THRESHOLD ? styles.attempt_details : styles.first_attempt}>

        <div className={styles.dial_attempt}>נסיון חיוג {attemptNum} מתוך {attempts}</div>

        <span className={classNames(styles.call_answer_status, calleeAnsweredYes ? styles.answer_label : styles.noanswer_label)}>{attemptStatusStrings.get(attempt.attempt_status)}</span>
        <span style={{fontWeight: '700'}}>{safeParsePhoneNumber(attempt.destination, 'IL')}</span>

        {
          attempt.attempt_status === 'answered' && request.response_key ? <span className={styles.simple_label}>תגובה</span> : <span className={styles.simple_label}>ללא תגובה</span>
        }
        {
          attempt.attempt_status === 'answered' && request.response_key ? <span className={styles.values}>{request.response_type === 'approve' ? 'הגעה' : 'אי - הגעה'}</span> : undefined
        }

        {/* Answered Dialer Attempt? -> show dial time */}
        {
          attempt.attempt_status === 'answered' && <span className={styles.simple_label}> משך שיחה </span>
        }
        {
          attempt.attempt_status === 'answered' && <span className={styles.values}>{callDuration}</span>
        }
      </div>
    </div>
  )
}

interface AttemptInfoProps {
  attempt: DialerAttempt;
  requestWaitTime: string;
  attempts: number;
  attemptNum: number;
  request: DialerRequest;
  sms_interaction: boolean;
}

export default AttemptInfo;

const attemptStatusStrings = new Map<string, string>([
  ["answered", "נענה"],
  ["dialing", "מחייג"],
  ["failed", "אין תגובה"]
]);

export const SmsInfo = (props: SmsInfoProps) => {
  const { request } = props;
  return (
    <div className={styles.progress_step__no_step}>
      <div className={styles.progress_step_marker} />
      <div className={styles.date}>
        <span className={styles.simple_label}>
          <FormattedDate dateStr={request.start_date.toString()} />
        </span>
      </div>
      <div className={styles.attempt_details}>

        <span><span className={styles.simple_label}>סמס</span>&nbsp; נשלח</span>
        <span className={styles.values}></span>

        <span className={classNames(styles.call_answer_status, request.response_media ? styles.answer_label : styles.noanswer_label)}>
          {request.response_media === 'sms' ? 'נענה' : 'אין תגובה'}
        </span>
        <span>{request.callee_sms_phone}</span>


      </div>
    </div>
  )
}

interface SmsInfoProps {
  request: DialerRequest;
}