import { CallForwarding } from "../../../design/icons"
import { Remove } from "../../../design/icons/remove"
import { Edit } from "../../../design/icons/edit"
import styles from "./extension-menu.module.scss"

export const ExtensionMenu = ({ isForwarded, onEditNameClick, onForwardClick, onCancelForwardClick }: ExtensionMenuProps) => (
  <div>
    <div onClick={onEditNameClick} className={styles.menuAction} style={isForwarded ? { border: "0px" } : {}}>
      עריכת שם שלוחה
      <Edit size={24} />
    </div>
    <div onClick={onForwardClick} className={styles.menuAction}>
      העברת שיחות
      <CallForwarding size={24} />
    </div>
    {
      isForwarded && 
      <div onClick={onCancelForwardClick} className={styles.menuAction} style={{ border: "0px" }}>
          <span className={styles.cancelAction}>
            ביטול העברת שיחות             
          </span>
        <Remove size={24} />
      </div>
    }
  </div >
)

interface ExtensionMenuProps {
  isForwarded: boolean,
  onEditNameClick: () => void,
  onForwardClick: () => void,
  onCancelForwardClick: () => void,
}