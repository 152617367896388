import { PermissionLevel } from '../../api/types';
import { CallbackDialer } from '../../api/dialers';
import { getUIFieldValue } from './getUIFieldValue';
import { ComponentType } from '../../formConfig/types';
import { setFieldUIPermission } from './setFieldUIPermission';
import { CALLBACK_FORM_FIELDS_CONFIG } from '../formConfig/callbackDialerFormConfig';
import { CallbackFieldConfig, CallbackFieldName, FieldName, ICallbackDialerForm } from '../types';

export const processCallbackDataToForm = (data: CallbackDialer, userPermissionLevel: PermissionLevel | undefined) => {
  if (!data || !userPermissionLevel) return;

  // Field names of the callback dialer form
  const fieldNameList: CallbackFieldName[] = Object.keys(CALLBACK_FORM_FIELDS_CONFIG) as CallbackFieldName[];

  // Config values of the form fields from configuration
  const fieldValueList: CallbackFieldConfig[] = Object.values(CALLBACK_FORM_FIELDS_CONFIG);

  // Config values of the dynamic fields
  const dynamicFieldValueList = fieldValueList.filter(
    (fieldValue) => fieldValue.componentType === ComponentType.DynamicField
  );

  // Field names that are used as dynamic fields
  const dynamicFieldNameList = dynamicFieldValueList.map((dynamicFieldValue) => dynamicFieldValue.name);

  let callbackFormData: Partial<ICallbackDialerForm> = {};

  fieldNameList.forEach((fieldName) => {
    setFieldUIPermission(fieldName, userPermissionLevel);
    if (fieldName in data) {
      if (dynamicFieldNameList.includes(fieldName)) {
        const fieldKey = dynamicFieldValueList.find((dynamicFieldValue) => dynamicFieldValue.name === fieldName)
          ?.fieldKey!;
        const dynamicFieldData = (data[fieldName as FieldName] as string[]) || [];
        const dynamicFormFieldValues = dynamicFieldData.map((fieldValue) => ({ [fieldKey]: fieldValue }));
        callbackFormData = { ...callbackFormData, [fieldName]: dynamicFormFieldValues };
      } else {
        callbackFormData = { ...callbackFormData, [fieldName]: data[fieldName as FieldName] ?? '' };
      }
    } else {
      const uiFieldValue = getUIFieldValue(fieldName, userPermissionLevel, data);
      callbackFormData = { ...callbackFormData, ...uiFieldValue };
    }
  });

  return callbackFormData as ICallbackDialerForm;
};
