import * as yup from "yup";
import { Section } from "../components";
import { Card } from "../../../design/card/card";
import styles from "./admin-settings.module.scss";
import { Field } from "../../../design/form/field";
import { Select } from "../../../design/form/select";
import { ChangeEvent, useState } from "react";
import { DialerEventType, DialerGlobalSettingsWithFiles } from "../../../api/dialers";
import { Input } from "../../../design/form/input/input";
import { AudioFileField } from "../../../design/form/audio-file-field/audio-file-field";
import { AudioFileInput } from "../../../design/form/audio-file-input/audio-file-input";
import { Form, Formik, FormikProps, useField } from "formik";
import { HorizontalPartition } from "../../../design/partition/horizontal-partition";
import { ISRAELI_PHONE_REGEXP } from "../../../utils/strings/string-translator";

const globalSettingsFormSchema: yup.Schema = yup.object().shape({
  business_numbers: yup
    .array()
    .of(yup.string().matches(ISRAELI_PHONE_REGEXP, "מספר הטלפון לא תקין").nullable().optional()),
});

interface GlobalSettingsFormProps {
  handleSave: (fieldName: string, value: any) => void;
  initialValues: DialerGlobalSettingsWithFiles | undefined;
}

const sections: Section[] = [
  { id: "1", name: "חייגן", description: "כאן ניתן להגדיר את פעולות קבוצת הקריאה" },
  { id: "2", name: "קבצי הקלטה לפי סוג אירוע", description: "" },
  { id: "3", name: "קבצי הקלטה כלליים למפעילים", description: "הקלטות תפריט קולי" },
];

export const GlobalSettingsForm = ({ handleSave, initialValues }: GlobalSettingsFormProps) => {
  if (initialValues === undefined) {
    return <></>;
  }

  const handleOnBlur = async (e: any, formik: FormikProps<DialerGlobalSettingsWithFiles>) => {
    const currentErrors = await formik.validateForm();
    const fieldName = e.target.name;
    const fieldIndex = fieldName.slice(-1);
    if (e.target.name.includes("business_numbers")) {
      const isValid = !currentErrors.business_numbers?.[fieldIndex];
      const isSameValue =
        formik.values.business_numbers[fieldIndex] === initialValues.business_numbers[fieldIndex];
      if (isValid && !isSameValue) {
        let updatedBusinessNumbers = [...initialValues.business_numbers];
        updatedBusinessNumbers[fieldIndex] = formik.values.business_numbers[fieldIndex];
        handleSave("business_numbers", updatedBusinessNumbers);
      }
      return;
    }
    handleSave(e.target.name, e.target.files?.[0] || e.target.value);
  };

  return (
    <Formik
      validationSchema={globalSettingsFormSchema}
      initialValues={initialValues}
      onSubmit={() => {}}
      validateOnChange={false}
      validateOnBlur={true}
      validateOnMount={true}
    >
      {(formik: any) => {
        return (
          <Form
            className={styles.pageContent}
            onChange={(e: any) => {
              const fieldType = e.target.type;
              if (fieldType !== "text") {
                handleSave(e.target.name, e.target.files?.[0] || e.target.value);
              }
            }}
            onBlur={(e: any) => handleOnBlur(e, formik)}
          >
            <div className={styles.pageContent}>
              <Section section={sections[0]}>
                <Card className={styles.card}>
                  <DialerGlobalSettings />
                </Card>
              </Section>

              <Section section={sections[1]}>
                <Card className={styles.card}>
                  <TypeSensitiveRecordings handleSave={handleSave} />
                </Card>
              </Section>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const DialerGlobalSettings = () => {
  const CODE_ATTEMPTS_OPTS = [2, 3, 4, 5];
  const initialValues = ["", "", "", "", "", ""];

  return (
    <div className={styles.dialerSettingsBody}>
      <div>
        <label className={styles.strong}>{"מספר נסיונות להקשת קוד הפעלת קריאה"}</label>
        <Field name={"launch_code_max_attempts"}>
          <Select name={"launch_code_max_attempts"} multiple={false}>
            {CODE_ATTEMPTS_OPTS.map((option) => (
              <option key={option} value={option}>
                {" "}
                {option}{" "}
              </option>
            ))}
          </Select>
        </Field>
      </div>
      <>
        <label className={styles.strong}>{"מספרים מזהים לשיחה יוצאת מקבוצת קריאה"}</label>
        <Form className={styles.businessNumbersContainer}>
          {initialValues.map((_, index) => (
            <NumberField key={index} label={index + 1} fieldName={`business_numbers.${index}`} />
          ))}
        </Form>
      </>
    </div>
  );
};

const TypeSensitiveRecordings = ({
  handleSave,
}: {
  handleSave: (fieldName: string, value: any) => void;
}) => {
  const [eventType, setEventType] = useState<DialerEventType | "real">("real");
  const translatedEventType =
    eventType === "emergency"
      ? "קריאת אמת"
      : eventType === "drill"
      ? "קריאת תרגיל"
      : eventType === "cancelation"
      ? "ביטול"
      : "";

  return (
    <div className={styles.dialerSettingsBody}>
      <Select
        onChange={(e) => setEventType(e.target.value as "real" | "drill" | "cancelation")}
        value={eventType}
      >
        <option value={"real"}>קריאת אמת</option>
        <option value={"drill"}>קריאת תרגיל</option>
        <option value={"cancelation"}>ביטול אירוע</option>
      </Select>
      <HorizontalPartition />
      <div style={{ fontSize: 40 }}>כוננים</div>
      {
        <div className={styles.dialerConfigBody}>
          <div>
            <label className={styles.strong}>טקסט לפתיח סמס{translatedEventType}</label>
            <Field name={`${eventType}_prefix_sms_text`}>
              <Input placeholder={`לדוגמה: ${translatedEventType} ${translatedEventType}`} />
            </Field>
          </div>
          {calleesTypeSensitiveFileFields.map((fileField) => (
            <TypedFileField
              eventType={eventType}
              fieldName={fileField.name}
              handleSave={handleSave}
              title={`${fileField.description} ב${translatedEventType}`}
              key={fileField.name}
            />
          ))}
        </div>
      }
    </div>
  );
};

export const FileField = ({ fieldName, handleSave, title }: FileFieldProps) => {
  const fileName = useField(`${fieldName}_file_name`)[1].value;
  const fileUrl = useField(`${fieldName}_file_url`)[1].value;
  return (
    <div className={styles.audioFileSection}>
      <label className={styles.strong}>{title}</label>
      <div style={{ width: "70%", alignSelf: "center" }}>
        <AudioFileField
          name={`${fieldName}_file_name`}
          onReset={() => handleSave(`${fieldName}_file_url`, null)}
        >
          <AudioFileInput
            name={`${fieldName}_file_url`}
            label={"העלאת קובץ הקלטה חדש"}
            placeholderFileUrl={fileUrl || undefined}
            placeholderFilename={fileUrl ? fileName : undefined}
            description={"ניתן להעלות קבצי אודיו עד 5MB"}
          />
        </AudioFileField>
      </div>
    </div>
  );
};

const TypedFileField = ({ fieldName, handleSave, title, eventType }: FileFieldProps) => {
  let dbFieldPrefix = eventType === "emergency" ? "real" : eventType;

  return (
    <FileField
      fieldName={`trauma_${dbFieldPrefix}_${fieldName}`}
      handleSave={handleSave}
      title={title}
    />
  );
};

interface FileFieldProps {
  fieldName: string;
  eventType?: DialerEventType | "real";
  title?: string;
  handleSave: (fieldName: string, value: any) => void;
}

const calleesTypeSensitiveFileFields: { name: string; description: string }[] = [
  {
    name: "prefix",
    description: "קובץ קולי להשמעת הודעה מקדימה",
  },
];

interface NumberFieldProps {
  label: number;
  fieldName: string;
  onBlur?: (event: ChangeEvent<any>) => void;
}

export const NumberField = (props: NumberFieldProps) => {
  return (
    <div className={styles.formField}>
      <label>{`מספר טלפון ${props.label}`}</label>
      <Field name={props.fieldName} onBlur={props.onBlur}>
        <Input name={props.fieldName} {...props} />
      </Field>
    </div>
  );
};
