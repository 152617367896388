import { DialogStateReturn } from "reakit";
import { Dialog } from "../dialog";
import styles from "./error-dialog.module.scss";
import { ErrorIcon } from "../../icons/error-icon";

export const ErrorDialog = ({
  dialog,
  mainMessage,
  secondaryMessage,
  onClose,
  contactSupport = true,
  showSecondaryMessage = true,
}: ErrorDialogProps) => {
  return (
    <Dialog aria-label="error-dialog" variant='message' header='אופס...' submitLabel='אוקיי' {...dialog} onClose={onClose}>
      <div className={styles.dialogBody}>
        <div className={styles.errorCircle}>
          <ErrorIcon size={40} />
        </div>
        <div className={styles.message}>
          {mainMessage ?? DEFAULT_MAIN_MESSAGE}
          <br />
          {showSecondaryMessage && (secondaryMessage ?? DEFAULT_SECONDARY_MESSAGE)}
          <br />
          {contactSupport && (
            <div>
              אם הבעיה חוזרת, אנא פנה לתמיכה הטכנית:
              <br />
              <a href='mailto:support@ip-com.co.il'>support@ip-com.co.il</a> או בטלפון:{" "}
              <a href='tel:03-657-6576'>03-657-6576</a>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

const DEFAULT_MAIN_MESSAGE = "לא הצלחנו לבצע את הפעולה מסיבה לא ידועה.";
const DEFAULT_SECONDARY_MESSAGE = "נסה/י לטעון את העמוד מחדש.";

interface ErrorDialogProps {
  dialog: DialogStateReturn;
  mainMessage?: string | undefined;
  secondaryMessage?: string | undefined;
  contactSupport?: boolean | undefined;
  showSecondaryMessage?: boolean | undefined;
  onClose: () => void;
}
